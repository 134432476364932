import settings from './settings'
import { getAdoptedOption, getXYpad, getXYFromCenter } from './helpers'
import { arePartners } from './tree-calibrate'
import Cookie from 'js-cookie'
import { over } from 'lodash'

class NodeConnector {

  matrix = {}
  lines = []
  curvedLines = []
  adoptedLines = []

  constructor(paper, matrix, nodeLocations, family_tree) {
    this.paper = paper
    this.matrix = matrix
    this.nodeLocations = nodeLocations
    this.family_tree = family_tree
  }

  isBrokenLine(profile) {
    let adopted_options = getAdoptedOption(profile);
    return (adopted_options !== undefined && 'adopted_type' in adopted_options && adopted_options.adopted_type == 'in');
  }

  redrawLines(){
    for(let line of this.lines){
      line.line.strokeColor = settings.strokeColorPrinting
    }

    for(let curve of this.curvedLines){
        curve.strokeColor = settings.strokeColorPrinting
    }
  }

  drawLine(start, end, brokenLine = false, siblings = [], partnerToChildrenLine = false) {
    var line = new this.paper.Path()
    line.strokeColor = settings.connector_line_color
    line.strokeWidth = settings.connector_line_strokeWidth
    line.add(new this.paper.Point(start.x, start.y))
    line.add(new this.paper.Point(end.x, end.y))

    var lineType = 'vertical'

    if(start.x === end.x) {
      lineType = 'vertical'
    }

    if(start.y === end.y){
      lineType = 'horizontal'
    }

    if(brokenLine) {
      line.strokeColor = settings.connector_adopted_in_color;
      line.dashArray = settings.connector_adopted_in_dash;
      this.adoptedLines.push(line)
    }


    let line_object = {
      lineType,
      line,
      siblings,
      partnerToChildrenLine
    }

    this.lines.push(line_object);

    return line;
  }

  clearLine(start, end) {
    var line = new this.paper.Path()
    line.strokeColor = settings.chart_bg_color;
    line.strokeWidth = settings.connector_line_strokeWidth + 3
    line.add(new this.paper.Point(start.x, start.y - 1))
    line.add(new this.paper.Point(end.x, end.y))
  }

  drawCurveLine(start, end, center_curve) {

    var from = new this.paper.Point(start.x, start.y)
    var center = new this.paper.Point(center_curve.x, center_curve.y)
    var to = new this.paper.Point(end.x, end.y)
    var curveLine = new this.paper.Path.Arc(from, center, to);
    curveLine.strokeColor = settings.connector_line_color
    curveLine.strokeWidth = settings.connector_line_strokeWidth

    this.curvedLines.push(curveLine)
    return curveLine

  }

  renderHooksFlux(){

    let horizontal_lines = this.lines.filter(line => line.lineType === 'horizontal')
    let vertical_lines = this.lines.filter(line => line.lineType === 'vertical')

    Number.prototype.between = function(a, b) {
      var min = Math.min.apply(Math, [a, b]),
        max = Math.max.apply(Math, [a, b]);
      return this > min && this < max;
    };

    for(let hor_line of horizontal_lines){
      let overlapping_hor_lines = horizontal_lines.filter(line => {
        if(line.line.firstSegment.point.y === hor_line.line.firstSegment.point.y &&
          (line.line.firstSegment.point.x.between(hor_line.line.firstSegment.point.x, hor_line.line.lastSegment.point.x) || 
          line.line.lastSegment.point.x.between(hor_line.line.firstSegment.point.x, hor_line.line.lastSegment.point.x))){
            if (hor_line.siblings.length > 0 && line.siblings.length > 0) {
              let same_siblings = hor_line.siblings.filter((hor) => line.siblings.find((line_2) => {
                let hor_id = null
                let line_2_id = null
                if (hor.id !== null && hor.id !== undefined) {
                  hor_id = hor.id
                }
                else {
                  hor_id = hor.data.profile.id
                }
                if (line_2.id !== null && line_2.id !== undefined) {
                  line_2_id = line_2.id
                }
                else {
                  line_2_id = line_2.data.profile.id
                }
                if (hor_id === line_2_id) {
                  return true
                }
                else{
                  return false
                }
              }))
              if (hor_line.siblings.length === same_siblings.length && line.siblings.length === same_siblings.length) {
                return false
              }
              else {
                return true
              }
            }
        }
        else{
          return false;
        }
      })

      if(overlapping_hor_lines.length > 0){
        let lines_to_adjust = this.lines.filter(line => {
          let same_siblings = line.siblings.filter((sib1) => hor_line.siblings.find((sib2) => {
            let line_1_id = null
            let line_2_id = null
            if (sib1.id !== null && sib1.id !== undefined) {
              line_1_id = sib1.id
            }
            else {
              line_1_id = sib1.data.profile.id
            }
            if (sib2.id !== null && sib2.id !== undefined) {
              line_2_id = sib2.id
            }
            else {
              line_2_id = sib2.data.profile.id
            }
            if (line_1_id === line_2_id) {
              return true
            }
            else{
              return false
            }
          }))
          if(same_siblings.length === line.siblings.length && same_siblings.length === hor_line.siblings.length){
            return true;
          }
          else{
            return false;
          }
        })
        for(let line of lines_to_adjust){
          if(line.lineType === 'horizontal'){
            line.line.firstSegment.point.y += 20
            line.line.lastSegment.point.y += 20
          }
          else{
            if(line.partnerToChildrenLine){
              if(line.line.firstSegment.point.y < line.line.lastSegment.point.y){
                line.line.lastSegment.point.y += 20
              }
              else{
                line.line.firstSegment.point.y += 20
              }
            }
            else{
              if(line.line.firstSegment.point.y < line.line.lastSegment.point.y){
                line.line.firstSegment.point.y += 20
              }
              else{
                line.line.lastSegment.point.y += 20
              }
            }
          }
        }
      }
    }

    //get intersections
    for(let hor_line of horizontal_lines){
      for(let ver_line of vertical_lines){
        let intersections = (hor_line.line).getIntersections(ver_line.line)

        if (intersections.length > 0) {

          intersections = intersections.filter(intersection => {
            if (Math.floor(hor_line.line.firstSegment.point.x) !== Math.floor(intersection.point.x)
              && Math.floor(hor_line.line.lastSegment.point.x) !== Math.floor(intersection.point.x)) {
              if (hor_line.siblings.length > 0 && ver_line.siblings.length > 0) {
                let same_siblings = hor_line.siblings.filter((hor) => ver_line.siblings.find((ver) => {
                  let hor_id = null
                  let ver_id = null
                  if (hor.id !== null && hor.id !== undefined) {
                    hor_id = hor.id
                  }
                  else {
                    hor_id = hor.data.profile.id
                  }
                  if (ver.id !== null && ver.id !== undefined) {
                    ver_id = ver.id
                  }
                  else {
                    ver_id = ver.data.profile.id
                  }
                  if (hor_id === ver_id) {
                    return true
                  }
                  else{
                    return false
                  }
                }))
                if (hor_line.siblings.length === same_siblings.length && ver_line.siblings.length === same_siblings.length) {
                  return false
                }
                else {
                  return true
                }
              }
              else{
                return false
              }
            }
            else {
              return false
            }
          }

          )

          // if(intersections.length > 0){
          //   console.log(hor_line)
          //   console.log(ver_line)
          //   var mask = this.paper.Path.Circle({
          //     fillColor: 'blue',
          //     center: [intersections[0].point.x, intersections[0].point.y],
          //     radius: settings.person_width / 8,
          //     strokeColor: settings.fillColor
          //   })
          // }
        }

        for(let intersection of intersections){
          let loc = {
            centerX: intersection.point.x,
            y: intersection.point.y
          }
          this.drawHook(loc)
        }

      }
    }
  }

  drawHook(loc) {
    let height = 20;
    let y_offset = height / 2;
    let start = {
      x: loc.centerX,
      y: loc.y - y_offset,
    }
    let center_curve = {
      x: loc.centerX + 2,
      y: loc.y - y_offset
    }
    let end = {
      x: loc.centerX,
      y: loc.y + height - y_offset
    }

    // Draw the hook
    this.drawCurveLine(start, end, center_curve);

    // Clear vertical straight line
    let half_height = height / 2;
    this.clearLine({x: start.x, y: start.y + 2}, {x: start.x, y: start.y + half_height - 1});
    this.clearLine({x: start.x, y: start.y + half_height + 2}, {x: start.x, y: start.y + height - 1});
  }

  getPartnerLineCenterX(partner_direction) {
    let center = settings.matrix_box_width / 2;
    if(partner_direction === 'to_left') {
      return settings.matrix_box_width + center; // move to the next column
    } else {
      return center * -1;
    }
  }

  getPartnerLineCenterXFlux(x1, x2, children_positions = [], partner_direction = 'to_left', slot_top_x, adjustment, spouse, partner){
    Number.prototype.between = function(a, b) {
      var min = Math.min.apply(Math, [a, b]),
        max = Math.max.apply(Math, [a, b]);
      return this > min && this < max;
    };

    let center = settings.matrix_box_width / 2;
    let diff = (x1 - x2) / 2

    if(children_positions.length === 1){
      if(children_positions[0].position.x.between(x1, x2)){
        if(partner_direction === 'to_left'){
          if(slot_top_x + settings.matrix_box_width + center === children_positions[0].position.x + adjustment){
            return settings.matrix_box_width + center;
          }
          else{
            let to_add = (children_positions[0].position.x + adjustment) - (slot_top_x + settings.matrix_box_width + center)
            return settings.matrix_box_width + center + to_add;
          }
        }
        else{
          if(slot_top_x + (center * -1) === children_positions[0].position.x + adjustment){
            return center * -1;
          }
          else{
            let to_reduce = (slot_top_x + (center * -1)) - (children_positions[0].position.x + adjustment)
            return center * -1 - to_reduce;
          }
        }
      }
    }

    return center + diff
  }

  drawConsanguineousPartnerLineCurve(left_person, right_person, partner_direction = 'to_right', partner_index = 0, marital_status) {
    let slot_left = this.matrix.getSlotAt(left_person.pos.row, left_person.pos.column)
    let slot_right = this.matrix.getSlotAt(right_person.pos.row, right_person.pos.column)
    let slot_center = this.matrix.getSlotAt(left_person.pos.row - 1, left_person.pos.column)

    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      slot_left.x = this.nodeLocations.find(node => node.id === left_person.data.profile.id).position.x
      slot_left.y = this.nodeLocations.find(node => node.id === left_person.data.profile.id).position.y

      if(partner_direction === 'to_right'){
        slot_right.x = this.nodeLocations.find(node => node.id === right_person.data.profile.id).position.x - settings.person_width/2
      } else{
        slot_right.x = this.nodeLocations.find(node => node.id === right_person.data.profile.id).position.x + settings.person_width/2
      }
      slot_right.y = this.nodeLocations.find(node => node.id === right_person.data.profile.id).position.y

      slot_center.x = this.nodeLocations.find(node => node.id === left_person.data.profile.id).position.x
      slot_center.y = this.nodeLocations.find(node => node.id === left_person.data.profile.id).position.y - 55
    }


    var node_width = settings.person_width;
    var node_center = settings.person_width / 2;
    let partner_line_center_x = this.getPartnerLineCenterX(partner_direction);
//

    let x_padding = (settings.matrix_box_width - node_width) / 2;

    let go_up = 1;
    let go_down = -3;
    let move_right = 12;

    var line1_start = {
      x: slot_right.x + x_padding,
      y: slot_right.y + node_center + go_up
    }

    var line1_end = {
      x: slot_right.x + partner_line_center_x,
      y: slot_right.y + node_center + go_up
    }

    var line2_start = {
      x: slot_right.x + x_padding,
      y: slot_right.y + node_center + go_down
    }

    var line2_end = {
      x: slot_right.x + partner_line_center_x,
      y: slot_right.y + node_center + go_down
    }
    this.drawLine(line1_start, line1_end)
    this.drawLine(line2_start, line2_end)

    // This is the other partner
    var start_curve1 = {
      x: slot_right.x + partner_line_center_x,
      y: slot_right.y + node_center + go_up
    }

    // This is the main partner
    var end_curve1 = {
      x: slot_left.x + node_width - partner_line_center_x,
      y: slot_left.y - 2 + go_up
    }

    // This is the other partner
    var start_curve2 = {
      x: slot_right.x + partner_line_center_x,
      y: slot_right.y + node_center + go_down
    }

    // This is the main partner
    var end_curve2 = {
      x: slot_left.x + node_width - partner_line_center_x + move_right,
      y: slot_left.y - 2 + go_down
    }

    // This is the center of the curve line
    let matrix_box_height = settings.matrix_box_height
    let center_x1 = start_curve1.x + (end_curve1.x - start_curve1.x) / 2
    // let center_y_offset = matrix_box_height - (8 * partner_index)
    let center_y_offset1 = matrix_box_height - (14 * (partner_index + .2))
    let center_y1 = slot_center.y + center_y_offset1
    // Make sure the center y dont go outside the boundary
    if(center_y1 < slot_center.y ) {
      center_y1 = slot_center.y + 5
    }
    var center_curve1 = {
      x: center_x1,
      y: center_y1
    }

    // This is the center of the curve line
    let center_x2 = start_curve2.x + (end_curve2.x - start_curve2.x) / 2
    let center_y_offset2 = matrix_box_height - (14 * (partner_index + .2))
    let center_y2 = slot_center.y + center_y_offset2
    // Make sure the center y dont go outside the boundary
    if(center_y2 < slot_center.y ) {
      center_y2 = slot_center.y + 5
    }
    var center_curve2 = {
      x: center_x2,
      y: center_y2
    }

    let curveLine1 = this.drawCurveLine(start_curve1, end_curve1, center_curve1)
    let curveLine2 = this.drawCurveLine(start_curve2, end_curve2, center_curve2)

    // Create slanting line to curve lines
    var main_partner = {
      x: slot_left.x + node_width - 1,
      y: slot_left.y + node_center - 4
    }
    this.drawLine(main_partner, end_curve1).sendToBack();


    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      let people = Object.values(this.family_tree.profile)
      let children = people.filter(person => (person.father_id === left_person.data.profile.id && person.mother_id === right_person.data.profile.id) || (person.father_id === right_person.data.profile.id && person.mother_id === left_person.data.profile.id))
      
      if (children.length > 0) {
        let { x_pad, y_pad } = getXYpad();
        let node_center = settings.person_width / 2
  
        let slot_top_x = 0;
  
        let children_positions = this.nodeLocations.filter((node) => children.find((child) => node.id === child.id));
  
        let x1 = 0;
        let x2 = 0;
        if(slot_right.x > slot_left.x){
          x1 = slot_right.x
          x2 = slot_left.x
          slot_top_x = this.nodeLocations.find(node => node.id === right_person.data.profile.id).position.x
        }
        else{
          x1 = slot_left.x
          x2 = slot_right.x
          slot_top_x = this.nodeLocations.find(node => node.id === left_person.data.profile.id).position.x
        }
  
        // console.log(x1, x2, slot_top_x)
        // console.log(x1, x2, this.nodeLocations.find(node => node.id === right_person.data.profile.id).position.x)
  
        if (children.length > 0) {
          let x_offset = this.getPartnerLineCenterXFlux(x1, x2, children_positions, partner_direction, slot_top_x, (node_center + x_pad), children[0].mother_id, children[0].father_id)
  
          // console.log(x_offset)
          let start = {
            x: slot_left.x + x_offset,
            y: center_y1 - 20
          }
          let end = {
            x: slot_left.x + x_offset,
            y: center_y1 + 50
          }
  
          if (start.x.between(start_curve1.x, end_curve1.x)) {
            let line = this.drawLine(start, end, false, children, true)
            let intersections = (curveLine1).getIntersections(line)
            if (intersections.length > 0) {
              let clear_start = {
                x: intersections[0].point.x,
                y: intersections[0].point.y
              }

              this.clearLine(clear_start, start)
            }
          }
        }
      }
    }


    // draw slash through relationship line based on marital_status
    if(marital_status === 'separated') {
      let begin = {
        x: line1_end.x - 5,
        y: partner_direction === 'to_right' ? line1_end.y + 9 : line1_end.y - 9
      }

      let finish = {
        x: line1_end.x + 5,
        y: partner_direction === 'to_right' ? line1_end.y - 9 : line1_end.y + 9
      }

      this.drawLine(begin, finish);

    } else if (marital_status === "divorced") {

      let start_1 = {
        x: line1_end.x - 7,
        y: partner_direction === 'to_right' ? line1_end.y + 9 : line1_end.y - 9
      }

      let end_1 = {
        x: line1_end.x + 3,
        y: partner_direction === 'to_right' ? line1_end.y - 9 : line1_end.y + 9
      }

      let start_2 = {
        x: line1_end.x - 3,
        y: partner_direction === 'to_right' ? line1_end.y + 9 : line1_end.y - 9
      }

      let end_2 = {
        x: line1_end.x + 7,
        y: partner_direction === 'to_right' ? line1_end.y - 9 : line1_end.y + 9
      }

      this.drawLine(start_1, end_1);
      this.drawLine(start_2, end_2);
    }
  }

  drawNonConsanguineousPartnerLineCurve(left_person, right_person, partner_direction = 'to_right', partner_index = 0, marital_status) {
    let slot_left = this.matrix.getSlotAt(left_person.pos.row, left_person.pos.column)
    let slot_right = this.matrix.getSlotAt(right_person.pos.row, right_person.pos.column)
    let slot_center = this.matrix.getSlotAt(left_person.pos.row - 1, left_person.pos.column)

    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      slot_left.x = this.nodeLocations.find(node => node.id === left_person.data.profile.id).position.x
      slot_left.y = this.nodeLocations.find(node => node.id === left_person.data.profile.id).position.y

      if(partner_direction === 'to_right'){
        slot_right.x = this.nodeLocations.find(node => node.id === right_person.data.profile.id).position.x - settings.person_width/2
      } else{
        slot_right.x = this.nodeLocations.find(node => node.id === right_person.data.profile.id).position.x + settings.person_width/2
      }

      slot_right.y = this.nodeLocations.find(node => node.id === right_person.data.profile.id).position.y

      slot_center.x = this.nodeLocations.find(node => node.id === left_person.data.profile.id).position.x
      slot_center.y = this.nodeLocations.find(node => node.id === left_person.data.profile.id).position.y - 55
    }

    var node_width = settings.person_width;
    var node_center = settings.person_width / 2;
    let partner_line_center_x = this.getPartnerLineCenterX(partner_direction);
    var start = {
      x: slot_right.x + settings.matrix_box_width / 2,
      y: slot_right.y + node_center
    }

    var end = {
      x: slot_right.x + partner_line_center_x,
      y: slot_right.y + node_center
    }
    this.drawLine(start, end)

    // This is the other partner
    var start_curve = {
      x: slot_right.x + partner_line_center_x,
      y: slot_right.y + node_center
    }

    // This is the main partner
    var end_curve = {
      x: slot_left.x + node_width - partner_line_center_x,
      y: slot_left.y - 2
    }

    // This is the center of the curve line
    let matrix_box_height = settings.matrix_box_height
    let center_x = start_curve.x + (end_curve.x - start_curve.x) / 2
    let center_y_offset = matrix_box_height - (14 * (partner_index + .2))
    let center_y = slot_center.y + center_y_offset
    // Make sure the center y dont go outside the boundary
    if(center_y < slot_center.y ) {
      center_y = slot_center.y + 5
    }
    var center_curve = {
      x: center_x,
      y: center_y
    }

    let curveLine = this.drawCurveLine(start_curve, end_curve, center_curve)

    // Create slanting line to curve lines
    var main_partner = {
      x: slot_left.x + node_width - 1,
      y: slot_left.y + node_center - 4
    }
    this.drawLine(main_partner, end_curve).sendToBack();

    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      Number.prototype.between = function(a, b) {
        var min = Math.min.apply(Math, [a, b]),
          max = Math.max.apply(Math, [a, b]);
        return this > min && this < max;
      };
      
      let people = Object.values(this.family_tree.profile)
      let children = people.filter(person => (person.father_id === left_person.data.profile.id && person.mother_id === right_person.data.profile.id) || (person.father_id === right_person.data.profile.id && person.mother_id === left_person.data.profile.id))
      
      if (children.length > 0) {
        let { x_pad, y_pad } = getXYpad();
        let node_center = settings.person_width / 2
  
        let slot_top_x = 0;
  
        let children_positions = this.nodeLocations.filter((node) => children.find((child) => node.id === child.id));
  
        let x1 = 0;
        let x2 = 0;
        if(slot_right.x > slot_left.x){
          x1 = slot_right.x
          x2 = slot_left.x
          slot_top_x = this.nodeLocations.find(node => node.id === right_person.data.profile.id).position.x
        }
        else{
          x1 = slot_left.x
          x2 = slot_right.x
          slot_top_x = this.nodeLocations.find(node => node.id === left_person.data.profile.id).position.x
        }
  
        // console.log(x1, x2, slot_top_x)
        // console.log(x1, x2, this.nodeLocations.find(node => node.id === right_person.data.profile.id).position.x)
  
        if (children.length > 0) {
          let x_offset = this.getPartnerLineCenterXFlux(x1, x2, children_positions, partner_direction, slot_top_x, (node_center + x_pad), children[0].mother_id, children[0].father_id)
  
          // console.log(x_offset)
          let start = {
            x: slot_left.x + x_offset,
            y: center_y - 20
          }
          let end = {
            x: slot_left.x + x_offset,
            y: center_y + 50
          }

          if (start.x.between(start_curve.x, end_curve.x)) {
            let line = this.drawLine(start, end, false, children, true)
            let intersections = (curveLine).getIntersections(line)
            if (intersections.length > 0) {
              let clear_start = {
                x: intersections[0].point.x,
                y: intersections[0].point.y
              }

              this.clearLine(clear_start, start)
            }
          }
        }
      }
    }

    // draw slash through relationship line based on marital_status
    if(marital_status === 'separated') {
      let begin = {
        x: end.x - 5,
        y: partner_direction === 'to_right' ? end.y + 9 : end.y - 9
      }

      let finish = {
        x: end.x + 5,
        y: partner_direction === 'to_right' ? end.y - 9 : end.y + 9
      }

      this.drawLine(begin, finish);

    } else if (marital_status === "divorced") {

      let start_1 = {
        x: end.x - 7,
        y: partner_direction === 'to_right' ? end.y + 9 : end.y - 9
      }

      let end_1 = {
        x: end.x + 3,
        y: partner_direction === 'to_right' ? end.y - 9 : end.y + 9
      }

      let start_2 = {
        x: end.x - 3,
        y: partner_direction === 'to_right' ? end.y + 9 : end.y - 9
      }

      let end_2 = {
        x: end.x + 7,
        y: partner_direction === 'to_right' ? end.y - 9 : end.y + 9
      }

      this.drawLine(start_1, end_1);
      this.drawLine(start_2, end_2);
    }
  }

  drawClosestPartnerLineCurve(left_person, right_person, partner_direction = 'to_right', partner_index = 0, marital_status, consanguineous){
      let slot_left = this.nodeLocations.find(node => node.id === left_person.data.profile.id).position

      let slot_right = this.nodeLocations.find(node => node.id === right_person.data.profile.id).position

      if(slot_left.x > slot_right.x){
        let temp = slot_left
        slot_left = slot_right
        slot_right = temp
      }

      let slot_center = {
        x: (slot_left.x + slot_right.x) / 2,
        y: slot_left.y
      }

      let start_curve = {
        x: slot_left.x + 45,
        y: slot_left.y + (settings.person_width/2)
      }

      let end_curve = {
        x: slot_right.x + 10,
        y: slot_right.y + (settings.person_width/2)
      }

      let center_curve = {
        x: slot_center.x,
        y: slot_center.y - 15
      }

      let curveLine = this.drawCurveLine(start_curve, end_curve, center_curve)
      if(consanguineous){
        start_curve = {
          x: slot_left.x + 45,
          y: slot_left.y + (settings.person_width/2) - 5
        }
  
        end_curve = {
          x: slot_right.x + 10,
          y: slot_right.y + (settings.person_width/2) - 5
        }
  
        center_curve = {
          x: slot_center.x,
          y: slot_center.y - 20
        }
        curveLine = this.drawCurveLine(start_curve, end_curve, center_curve)
      }

      let people = Object.values(this.family_tree.profile)
      let children = people.filter(person => (person.father_id === left_person.data.profile.id && person.mother_id === right_person.data.profile.id) || (person.father_id === right_person.data.profile.id && person.mother_id === left_person.data.profile.id))

      
      if (children.length > 0) {
        let { x_pad, y_pad } = getXYpad();
        let node_center = settings.person_width / 2

        let slot_top_x = 0;

        let children_positions = this.nodeLocations.filter((node) => children.find((child) => node.id === child.id));

        let x1 = 0;
        let x2 = 0;
        if(slot_right.x > slot_left.x){
          x1 = slot_right.x
          x2 = slot_left.x
          slot_top_x = this.nodeLocations.find(node => node.id === right_person.data.profile.id).position.x
        }
        else{
          x1 = slot_left.x
          x2 = slot_right.x
          slot_top_x = this.nodeLocations.find(node => node.id === left_person.data.profile.id).position.x
        }

        if (children.length > 0) {
          let x_offset = this.getPartnerLineCenterXFlux(x1, x2, children_positions, partner_direction, slot_top_x, (node_center + x_pad), children[0].mother_id, children[0].father_id)

          let start = {
            x: slot_left.x + x_offset,
            y: slot_center.y - 15
          }
          let end = {
            x: slot_left.x + x_offset,
            y: slot_center.y + 30
          }

          let line = this.drawLine(start, end, false, children, true)
          let intersections = (curveLine).getIntersections(line)
          if (intersections.length > 0) {
            let clear_start = {
              x: intersections[0].point.x,
              y: intersections[0].point.y
            }

            this.clearLine(clear_start, start)
          }
        }
      }

      // for(let child of children){
      //   let child_pos = this.nodeLocations.find(node => node.id === child.id).position
      //   let start = {
      //     x: child_pos.x + settings.person_width/2 + x_pad,
      //     y: slot_center.y - 15
      //   }
      //   let end = {
      //     x: child_pos.x + settings.person_width/2 + x_pad,
      //     y: slot_center.y + 30
      //   }
      //   let line = this.drawLine(start, end, false, children, true)
      //   let intersections = (curveLine).getIntersections(line)
      //   if(intersections.length > 0){
      //     let clear_start = {
      //       x: intersections[0].point.x,
      //       y: intersections[0].point.y
      //     }
          
      //     this.clearLine(clear_start, start)
      //   }
      // }

      if(marital_status === 'separated') {
        let begin = {
          x: center_curve.x - 5,
          y: partner_direction === 'to_right' ? center_curve.y + 9 : center_curve.y - 9
        }
  
        let finish = {
          x: center_curve.x + 5,
          y: partner_direction === 'to_right' ? center_curve.y - 9 : center_curve.y + 9
        }
  
        this.drawLine(begin, finish);
  
      } else if (marital_status === "divorced") {
  
        let start_1 = {
          x: center_curve.x - 7,
          y: partner_direction === 'to_right' ? center_curve.y + 9 : center_curve.y - 9
        }
  
        let end_1 = {
          x: center_curve.x + 3,
          y: partner_direction === 'to_right' ? center_curve.y - 9 : center_curve.y + 9
        }
  
        let start_2 = {
          x: center_curve.x - 3,
          y: partner_direction === 'to_right' ? center_curve.y + 9 : center_curve.y - 9
        }
  
        let end_2 = {
          x: center_curve.x + 7,
          y: partner_direction === 'to_right' ? center_curve.y - 9 : center_curve.y + 9
        }
  
        this.drawLine(start_1, end_1);
        this.drawLine(start_2, end_2);
      }

  }

  drawPartnerLineCurve(left_person, right_person, partner_direction = 'to_right', partner_index = 0, consanguineous = false, marital_status, closest = false) {

    if(closest){
      this.drawClosestPartnerLineCurve(left_person, right_person, partner_direction, partner_index, marital_status, consanguineous)
    }
    else{
      if (consanguineous) {
        this.drawConsanguineousPartnerLineCurve(left_person, right_person, partner_direction, partner_index, marital_status);
      } else {
        this.drawNonConsanguineousPartnerLineCurve(left_person, right_person, partner_direction, partner_index, marital_status);
      }
    }


    this.drawPartnerToChildrenLine(right_person, partner_direction)

  }

  drawPartnerLine(person, partner, partner_direction='to_right', partner_index = 0, consanguineous = false, marital_status = 'married', partners) {

    Number.prototype.between = function(a, b) {
      var min = Math.min.apply(Math, [a, b]),
        max = Math.max.apply(Math, [a, b]);
      return this > min && this < max;
    };

    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      let closest = partners
      if(partner_direction === 'to_right'){
        closest = partners.reduce((prev, curr) => prev.loc.x < curr.loc.x ? prev : curr)
      }
      else{
        closest = partners.reduce((prev, curr) => prev.loc.x > curr.loc.x ? prev : curr)
      }

      if(closest.data.profile.id === partner.data.profile.id){
        let person_pos = this.nodeLocations.find(node => node.id === person.data.profile.id).position
        let closest_pos = this.nodeLocations.find(node => node.id === partner.data.profile.id).position
        let nodes_between_person_and_partner = this.nodeLocations.filter(node => person_pos.y === node.position.y && node.position.x.between(person_pos.x, closest_pos.x));
        if(nodes_between_person_and_partner.length > 0){

          this.drawPartnerLineCurve(person, partner, partner_direction, partner_index, consanguineous, marital_status, true)
        }
        else{
          this.drawPartnerLineStraight(person, partner, partner_direction, consanguineous, marital_status)
        }
      }
      else{
        this.drawPartnerLineCurve(person, partner, partner_direction, partner_index, consanguineous, marital_status)
      }
    }
    else{
      if(partner_index === 0) {
        this.drawPartnerLineStraight(person, partner, partner_direction, consanguineous, marital_status)
      } else {
        this.drawPartnerLineCurve(person, partner, partner_direction, partner_index, consanguineous, marital_status)
      }
    }
  }

  drawPartnerLineStraight(person, partner, partner_direction='to_right', consanguineous = false, marital_status) {
    // Default to right
    let left_person = person;
    let right_person = partner;

    // Flip by direction
    if(partner_direction === 'to_left') {
      left_person = partner;
      right_person = person;
    }

    let slot_left = this.matrix.getSlotAt(left_person.pos.row, left_person.pos.column)
    let slot_right = this.matrix.getSlotAt(right_person.pos.row, right_person.pos.column)

    let cytoscape = Cookie.get("famgenix_active_smartdraw") == 'flux';
    if(cytoscape){
      slot_left.x = this.nodeLocations.find(node => node.id === left_person.data.profile.id).position.x
      slot_left.y = this.nodeLocations.find(node => node.id === left_person.data.profile.id).position.y
      slot_right.x = this.nodeLocations.find(node => node.id === right_person.data.profile.id).position.x
      slot_right.y = this.nodeLocations.find(node => node.id === right_person.data.profile.id).position.y
    }

    let slot_left_x = slot_left.x
    let slot_left_y = slot_left.y
    let slot_right_x = slot_right.x
    let slot_right_y = slot_right.y


    if(cytoscape){
      slot_left_x = this.nodeLocations.find(node => node.id === left_person.data.profile.id).position.x
      slot_left_y = this.nodeLocations.find(node => node.id === left_person.data.profile.id).position.y
      slot_right_x = this.nodeLocations.find(node => node.id === right_person.data.profile.id).position.x
      slot_right_y = this.nodeLocations.find(node => node.id === right_person.data.profile.id).position.y
    }

    let {x_pad, y_pad} = getXYpad();
    var node_width = settings.person_width;
    var node_center = settings.person_width / 2;

    if (consanguineous) {
      let go_up = 1;
      let go_down = -3;

      var line1_start = {
        x: cytoscape ? slot_left_x + node_width + x_pad : slot_left.x + node_width + x_pad,
        y: cytoscape ? slot_left_y + node_center + go_up + y_pad : slot_left.y + node_center + go_up + y_pad
      }

      var line1_end = {
        x: cytoscape ? slot_right_x + x_pad : slot_right.x + x_pad,
        y: cytoscape ? slot_right_y + node_center + go_up + y_pad : slot_right.y + node_center + go_up + y_pad
      }

      var line2_start = {
        x: cytoscape ? slot_left_x + node_width + x_pad : slot_left.x + node_width + x_pad,
        y: cytoscape ? slot_left_y + node_center + go_down + y_pad : slot_left.y + node_center + go_down + y_pad
      }

      var line2_end = {
        x: cytoscape ? slot_right_x + x_pad : slot_right.x + x_pad,
        y: cytoscape ? slot_right_y + node_center + go_down + y_pad : slot_right.y + node_center + go_down + y_pad
      }

      this.drawLine(line1_start, line1_end)
      this.drawLine(line2_start, line2_end)
    } else {
      var start = {
        x: cytoscape ? slot_left_x + node_width + x_pad : slot_left.x + node_width + x_pad,
        y: cytoscape ? slot_left_y + node_center + y_pad : slot_left.y + node_center + y_pad
      }

      var end = {
        x: cytoscape ? slot_right_x + x_pad : slot_right.x + x_pad,
        y: cytoscape ? slot_right_y + node_center + y_pad : slot_right.y + node_center + y_pad
      }
      // if(!left_person.rkey.includes("464")) this.drawLine(start, end);
      this.drawLine(start, end)
    }

    // draw slash through relationship line based on marital_status
    let center = {
      x: cytoscape ? (slot_left_x + node_width + x_pad * 2 + slot_right_x) / 2 : (slot_left.x + node_width + x_pad * 2 + slot_right.x) / 2,
      y: cytoscape ? slot_left_y + node_center + y_pad : slot_left.y + node_center + y_pad
    }

    if(marital_status === 'separated') {

      let start = {
        x: center.x - 5,
        y: center.y + 9
      }

      let end = {
        x: center.x + 5,
        y: center.y - 9
      }

      this.drawLine(start, end);

    } else if (marital_status === "divorced") {

      let start_1 = {
        x: center.x - 7,
        y: center.y + 9
      }

      let end_1 = {
        x: center.x + 3,
        y: center.y - 9
      }

      let start_2 = {
        x: center.x - 3,
        y: center.y + 9
      }

      let end_2 = {
        x: center.x + 7,
        y: center.y - 9
      }

      this.drawLine(start_1, end_1);
      this.drawLine(start_2, end_2);
    }

    // this.drawPartnerToChildrenLine(right_person, partner_direction)
    this.drawPartnerToChildrenLine(partner, partner_direction)
  }

  drawPartnerToChildrenLine(partner, partner_direction) {

    // Do not draw line if no children
    if('children' in partner && partner.children.length == 0) return;

    let slot_top = this.matrix.getSlotAt(partner.pos.row, partner.pos.column);

    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      slot_top.x = this.nodeLocations.find(node => node.id === partner.data.profile.id).position.x
      slot_top.y = this.nodeLocations.find(node => node.id === partner.data.profile.id).position.y
    }

    let children = partner.children
    let first_child = partner.children[0];
    let last_child = children[partner.children.length - 1];

    let people = Object.values(this.family_tree.profile)
    let father = people.find(person => person.id === first_child.data.profile.father_id)
    let mother = people.find(person => person.id === first_child.data.profile.mother_id)

    let bottom_row = first_child.pos.row - settings.matrix_row_space;
    let slot_bottom = this.matrix.getSlotAt(bottom_row, partner.pos.column)

    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){

      let first_child_pos = this.nodeLocations.find(node => node.id === first_child.data.profile.id).position
      let last_child_pos = this.nodeLocations.find(node => node.id === last_child.data.profile.id).position
      let partner_pos = this.nodeLocations.find(node => node.id === partner.data.profile.id).position

      let spouse_pos = null;

      if(partner.data.profile.id === father.id){
        spouse_pos = this.nodeLocations.find(node => node.id === mother.id).position
      }
      else{
        spouse_pos = this.nodeLocations.find(node => node.id === father.id).position
      }

      let nonSiblingPeopleOverlap = this.nodeLocations.filter((node) => !children.find((child) => node.id === child.data.profile.id));

      let children_positions = this.nodeLocations.filter((node) => children.find((child) => node.id === child.data.profile.id));
      let children_xs = [...new Set(children_positions.map(item => item.position.x))];
      let children_min_x = Math.min(...children_xs)
      let children_max_x = Math.max(...children_xs)

      let parents_positions = [partner_pos, spouse_pos]
      let parents_xs = [...new Set(parents_positions.map(item => item.x))];
      let parents_min_x = Math.min(...parents_xs)
      let parents_max_x = Math.max(...parents_xs)
 
      nonSiblingPeopleOverlap = nonSiblingPeopleOverlap.filter(node => node.position.y === first_child_pos.y)

      nonSiblingPeopleOverlap = nonSiblingPeopleOverlap.filter(node => (node.position.x > children_min_x && node.position.x < parents_max_x) || (node.position.x < children_max_x && node.position.x > parents_min_x))

      let children_obj = people.filter((person) => children.find((child) => person.id === child.data.profile.id))
      let partners_of_children = [];
      for (let child of children_obj){
        partners_of_children = partners_of_children.concat(child.partners)
      }
      nonSiblingPeopleOverlap = nonSiblingPeopleOverlap.filter((node) => !partners_of_children.find((person) => node.id === person.id))

      let father_siblings = people.filter(person => person.father_id === father.father_id && person.mother_id === father.mother_id && person.id !== father.id && person.father_id && person.mother_id)
      let mother_siblings = people.filter(person => person.father_id === mother.father_id && person.mother_id === mother.mother_id && person.id !== mother.id && person.father_id && person.mother_id)

      let cousins_of_children = [];
      for (let sibling of father_siblings) {
        for (let partner of sibling.partners) {
          let cousins = people.filter(person => (person.mother_id === sibling.id && person.father_id === partner.id) || (person.mother_id === partner.id && person.father_id === sibling.id))
          cousins_of_children = cousins_of_children.concat(cousins)
        }
      }

      for (let sibling of mother_siblings) {
        for (let partner of sibling.partners) {
          let cousins = people.filter(person => (person.mother_id === sibling.id && person.father_id === partner.id) || (person.mother_id === partner.id && person.father_id === sibling.id))
          cousins_of_children = cousins_of_children.concat(cousins)
        }
      }

      nonSiblingPeopleOverlap = nonSiblingPeopleOverlap.filter((node) => cousins_of_children.find((person) => node.id === person.id))

      slot_bottom.x = this.nodeLocations.find(node => node.id === partner.data.profile.id).position.x
      slot_bottom.y = (nonSiblingPeopleOverlap.length > 0) ? this.nodeLocations.find(node => node.id === first_child.data.profile.id).position.y - 90 : this.nodeLocations.find(node => node.id === first_child.data.profile.id).position.y - 110

      let locations = [];
      for(let child of partner.children){
        let location = this.nodeLocations.find(node => node.id === child.data.profile.id)
        locations.push(location)
      }

      let xs = [...new Set(locations.map(item => item.position.x))];

      let minX = Math.min(...xs)

      let minY = (nonSiblingPeopleOverlap.length > 0) ? locations.find(node => node.position.x === minX).position.y - 35 : locations.find(node => node.position.x === minX).position.y - 55

      let maxX = Math.max(...xs)

      let maxY = (nonSiblingPeopleOverlap.length > 0) ? locations.find(node => node.position.x === maxX).position.y - 35 : locations.find(node => node.position.x === maxX).position.y - 55


      let {x_pad, y_pad} = getXYpad();
      let node_center = settings.person_width / 2
      //use getPartnerLineCenterXFlux only if straight partnerline, and if closestPartnerLineCurve
      let x_offset =  Cookie.get("famgenix_active_smartdraw") == 'flux' ? this.getPartnerLineCenterXFlux(spouse_pos.x, partner_pos.x, children_positions, partner_direction, slot_top.x, (node_center + x_pad), mother.id, father.id) : this.getPartnerLineCenterX(partner_direction);
      let node_width = settings.person_width

      let start = {
        x: minX + node_center + x_pad,
        y: minY + y_pad
      };

      let end = {
        x: slot_bottom.x + x_offset,
        y: slot_bottom.y + settings.matrix_box_height,
      };

      this.drawLine(start, end, false, children)

      start = {
        x: maxX + node_center + x_pad,
        y: maxY + y_pad
      };

      end = {
        x: slot_bottom.x + x_offset,
        y: slot_bottom.y + settings.matrix_box_height,
      };

      this.drawLine(start, end, false, children)

      // let row_offset = settings.connector_sibling_line_height_by_row;
      // let slot_bottom = this.matrix.getSlotAt(partner.pos.row + row_offset, partner.pos.column)

      start = {
        x: slot_top.x + x_offset,
        y: slot_top.y + node_width / 2
      }

      end = {
        x: slot_bottom.x + x_offset,
        y: slot_bottom.y + settings.matrix_box_height,
      }

      // centerfix
      // if('even_column' in partner.pos && partner.pos.even_column == false) end.x = end.x - this.matrix.getSlotHalfWidth()

      this.drawLine(start, end, false, children, true)

      

      let min_x_id = children_positions.find(node => node.position.x === children_min_x).id
      let min_x_child = children.find(child => child.data.profile.id === min_x_id)

      let max_x_id = children_positions.find(node => node.position.x === children_max_x).id
      let max_x_child = children.find(child => child.data.profile.id === max_x_id)

      if(min_x_child.data.profile.twin_set && children_min_x + node_center + x_pad <= end.x){
        let twins = children.filter(child => child.data.profile.twin_set === min_x_child.data.profile.twin_set)
        let twins_locs = this.nodeLocations.filter((node) => twins.find((person) => node.id === person.data.profile.id))
        twins_locs = twins_locs.sort(function (a, b) {
          return a.position.x - b.position.x;
        });
        let center_x = (twins_locs[twins_locs.length - 1].position.x - twins_locs[0].position.x) / 2 + twins_locs[0].position.x
        let twins_center_loc = {
          x: center_x + node_center + x_pad,
          y: slot_bottom.y + settings.matrix_box_height
        }

        let min_x_child_loc = {
          x: minX + node_center + x_pad,
          y: slot_bottom.y + settings.matrix_box_height
        }
        

        let center_loc = {...twins_center_loc};

        let min_x_child_loc_2 = {
          x: minX + node_center + x_pad,
          y: slot_bottom.y + settings.matrix_box_height * 2
        } 
  

        if(center_loc.x <= end.x){
          this.clearLine(min_x_child_loc, center_loc);
          this.drawLine(min_x_child_loc_2, center_loc)
        }
      }

      if(max_x_child.data.profile.twin_set && children_max_x + node_center + x_pad >= end.x){
        let twins = children.filter(child => child.data.profile.twin_set === max_x_child.data.profile.twin_set)
        let twins_locs = this.nodeLocations.filter((node) => twins.find((person) => node.id === person.data.profile.id))
        twins_locs = twins_locs.sort(function (a, b) {
          return a.position.x - b.position.x;
        });
        let center_x = (twins_locs[twins_locs.length - 1].position.x - twins_locs[0].position.x) / 2 + twins_locs[0].position.x
        let twins_center_loc = {
          x: center_x + node_center + x_pad,
          y: slot_bottom.y + settings.matrix_box_height
        }

        let max_x_child_loc = {
          x: maxX + node_center + x_pad,
          y: slot_bottom.y + settings.matrix_box_height
        }

        let center_loc = {...twins_center_loc};

        let max_x_child_loc_2 = {
          x: maxX + node_center + x_pad,
          y: slot_bottom.y + settings.matrix_box_height * 2
        } 
  

        if(center_loc.x >= end.x){
          this.clearLine(center_loc, max_x_child_loc);
          this.drawLine(center_loc, max_x_child_loc_2)
        }
      }

    }
    else{
      let x_offset =  this.getPartnerLineCenterX(partner_direction)
      let node_width = settings.person_width

      let start = {
        x: slot_top.x + x_offset,
        y: slot_top.y + node_width / 2
      }

      let end = {
        x: slot_bottom.x + x_offset,
        y: slot_bottom.y + settings.matrix_box_height,
      }

      // centerfix
      // if('even_column' in partner.pos && partner.pos.even_column == false) end.x = end.x - this.matrix.getSlotHalfWidth()

      this.drawLine(start, end)
    }

  }

  drawSiblingVerticalLine(person) {
    let {x_pad, y_pad} = getXYpad();
    // If a twin, do not draw vertical line
    let { profile } = person.data;
    if(profile.twin_set !== null && profile.twin_set !== undefined) return

    let row_offset = settings.connector_sibling_line_height_by_row

    let people = Object.values(this.family_tree.profile)
    let father = people.find(p => p.id === profile.father_id)
    let mother = people.find(p => p.id === profile.mother_id)

    let children = people.filter(p => p.father_id === father.id && p.mother_id === mother.id)

    let slot_top = this.matrix.getSlotAt(person.pos.row - row_offset, person.pos.column)
    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){

      let children_locs = this.nodeLocations.filter((node) => children.find(child => node.id === child.id))

      let minX = children_locs.reduce(function (prev, curr) {
        return prev.position.x < curr.position.x ? prev : curr;
      });
      let maxX = children_locs.reduce(function(prev, curr) {
        return prev.position.x > curr.position.x ? prev : curr;
      });

      let first_child_pos = minX.position
      let last_child_pos = maxX.position
      let spouse_pos = null;
      let partner_pos = null;

      let father_pos = this.nodeLocations.find(node => node.id === father.id).position
      let mother_pos = this.nodeLocations.find(node => node.id === mother.id).position


      if(father_pos.x > mother_pos.x){
        partner_pos = father_pos
        spouse_pos = mother_pos
      }
      else if(mother_pos.x > father_pos.x){
        partner_pos = mother_pos
        spouse_pos = father_pos
      }

      //don't delete this comment, might be a good reference for future problems
      // let has_one_child = children.length == 1
      // let on_line_end = partner_pos.x > last_child_pos.x
      // let on_line_start = partner_pos.x < first_child_pos.x

      // if(has_one_child || on_line_end) {
      //   partner_pos = mother_pos
      // } else if(on_line_start) {
      //   partner_pos = father_pos
      // }


      let nonSiblingPeopleOverlap = this.nodeLocations.filter((node) => !children.find((child) => node.id === child.id));

      let children_positions = this.nodeLocations.filter((node) => children.find((child) => node.id === child.id));
      let children_xs = [...new Set(children_positions.map(item => item.position.x))];
      let children_min_x = Math.min(...children_xs)
      let children_max_x = Math.max(...children_xs)

      let parents_positions = [partner_pos, spouse_pos]
      let parents_xs = [...new Set(parents_positions.map(item => item.x))];
      let parents_min_x = Math.min(...parents_xs)
      let parents_max_x = Math.max(...parents_xs)

      nonSiblingPeopleOverlap = nonSiblingPeopleOverlap.filter(node => node.position.y === first_child_pos.y)

      nonSiblingPeopleOverlap = nonSiblingPeopleOverlap.filter(node => (node.position.x > children_min_x && node.position.x < parents_max_x) || (node.position.x < children_max_x && node.position.x > parents_min_x))

      let children_obj = people.filter((person) => children.find((child) => person.id === child.id))
      let partners_of_children = [];
      for (let child of children_obj){
        partners_of_children = partners_of_children.concat(child.partners)
      }
      nonSiblingPeopleOverlap = nonSiblingPeopleOverlap.filter((node) => !partners_of_children.find((person) => node.id === person.id))
      
      let father_siblings = people.filter(person => person.father_id === father.father_id && person.mother_id === father.mother_id && person.id !== father.id && person.father_id && person.mother_id)
      let mother_siblings = people.filter(person => person.father_id === mother.father_id && person.mother_id === mother.mother_id && person.id !== mother.id && person.father_id && person.mother_id)

      let cousins_of_children = [];
      for (let sibling of father_siblings) {
        for (let partner of sibling.partners) {
          let cousins = people.filter(person => (person.mother_id === sibling.id && person.father_id === partner.id) || (person.mother_id === partner.id && person.father_id === sibling.id))
          cousins_of_children = cousins_of_children.concat(cousins)
        }
      }

      for (let sibling of mother_siblings) {
        for (let partner of sibling.partners) {
          let cousins = people.filter(person => (person.mother_id === sibling.id && person.father_id === partner.id) || (person.mother_id === partner.id && person.father_id === sibling.id))
          cousins_of_children = cousins_of_children.concat(cousins)
        }
      }

      nonSiblingPeopleOverlap = nonSiblingPeopleOverlap.filter((node) => cousins_of_children.find((person) => node.id === person.id))
      
      
      slot_top.x = this.nodeLocations.find(node => node.id === person.data.profile.id).position.x
      slot_top.y = (nonSiblingPeopleOverlap.length > 0) ? this.nodeLocations.find(node => node.id === person.data.profile.id).position.y - 35 : this.nodeLocations.find(node => node.id === person.data.profile.id).position.y - 55
    }

    let slot_bottom = this.matrix.getSlotAt(person.pos.row, person.pos.column)
    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      slot_bottom.x = this.nodeLocations.find(node => node.id === person.data.profile.id).position.x
      slot_bottom.y = this.nodeLocations.find(node => node.id === person.data.profile.id).position.y
    }

    var node_center = settings.person_width / 2

    var start = {
      x: slot_top.x + node_center + x_pad,
      y: slot_top.y + y_pad
    }

    var end = {
      x: slot_bottom.x + node_center + x_pad,
      y: slot_bottom.y + y_pad,
    }

    let brokenLine = this.isBrokenLine(profile);
    this.drawLine(start, end, brokenLine, children);

  }

  drawSiblingHorizontalLine(partner, partner_direction = 'to_right'){
    if(partner.children.length == 0) return

    let children = partner.children;

    let {x_pad, y_pad} = getXYpad();

    let first_child = children[0];
    let last_child = children[partner.children.length - 1];

    let people = Object.values(this.family_tree.profile)
    let father = people.find(person => person.id === first_child.data.profile.father_id)
    let mother = people.find(person => person.id === first_child.data.profile.mother_id)

    // let row = partner.pos.row + settings.matrix_row_space;
    let row = first_child.pos.row - settings.connector_sibling_line_height_by_row;

    let slot_partner = this.matrix.getSlotAt(partner.pos.row, partner.pos.column);
    let slot_left = this.matrix.getSlotAt(row, first_child.pos.column);
    let slot_right = this.matrix.getSlotAt(row, last_child.pos.column);

    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      let first_child_pos = this.nodeLocations.find(node => node.id === first_child.data.profile.id).position
      let last_child_pos = this.nodeLocations.find(node => node.id === last_child.data.profile.id).position
      let partner_pos = this.nodeLocations.find(node => node.id === partner.data.profile.id).position

      let spouse_pos = null;

      if(partner.data.profile.id === father.id){
        spouse_pos = this.nodeLocations.find(node => node.id === mother.id).position
      }
      else{
        spouse_pos = this.nodeLocations.find(node => node.id === father.id).position
      }

      let nonSiblingPeopleOverlap = this.nodeLocations.filter((node) => !children.find((child) => node.id === child.data.profile.id));

      let children_positions = this.nodeLocations.filter((node) => children.find((child) => node.id === child.data.profile.id));
      let children_xs = [...new Set(children_positions.map(item => item.position.x))];
      let children_min_x = Math.min(...children_xs)
      let children_max_x = Math.max(...children_xs)

      let parents_positions = [partner_pos, spouse_pos]
      let parents_xs = [...new Set(parents_positions.map(item => item.x))];
      let parents_min_x = Math.min(...parents_xs)
      let parents_max_x = Math.max(...parents_xs)

      nonSiblingPeopleOverlap = nonSiblingPeopleOverlap.filter(node => node.position.y === first_child_pos.y)

      nonSiblingPeopleOverlap = nonSiblingPeopleOverlap.filter(node => (node.position.x > children_min_x && node.position.x < parents_max_x) || (node.position.x < children_max_x && node.position.x > parents_min_x))

      let people = Object.values(this.family_tree.profile)
      let children_obj = people.filter((person) => children.find((child) => person.id === child.data.profile.id))
      let partners_of_children = [];
      for (let child of children_obj){
        partners_of_children = partners_of_children.concat(child.partners)
      }

      nonSiblingPeopleOverlap = nonSiblingPeopleOverlap.filter((node) => !partners_of_children.find((person) => node.id === person.id))

      let father_siblings = people.filter(person => person.father_id === father.father_id && person.mother_id === father.mother_id && person.id !== father.id && person.father_id && person.mother_id)
      let mother_siblings = people.filter(person => person.father_id === mother.father_id && person.mother_id === mother.mother_id && person.id !== mother.id && person.father_id && person.mother_id)


      let cousins_of_children = [];
      for (let sibling of father_siblings) {
        for (let partner of sibling.partners) {
          let cousins = people.filter(person => (person.mother_id === sibling.id && person.father_id === partner.id) || (person.mother_id === partner.id && person.father_id === sibling.id))
          // console.log(cousins)
          cousins_of_children = cousins_of_children.concat(cousins)
        }
      }

      for (let sibling of mother_siblings) {
        for (let partner of sibling.partners) {
          let cousins = people.filter(person => (person.mother_id === sibling.id && person.father_id === partner.id) || (person.mother_id === partner.id && person.father_id === sibling.id))
          // console.log(cousins)
          cousins_of_children = cousins_of_children.concat(cousins)
        }
      }

      nonSiblingPeopleOverlap = nonSiblingPeopleOverlap.filter((node) => cousins_of_children.find((person) => node.id === person.id))


      slot_partner.x = this.nodeLocations.find(node => node.id === partner.data.profile.id).position.x
      slot_partner.y = this.nodeLocations.find(node => node.id === partner.data.profile.id).position.y

      slot_left.x = this.nodeLocations.find(node => node.id === first_child.data.profile.id).position.x
      slot_left.y = (nonSiblingPeopleOverlap.length > 0) ? this.nodeLocations.find(node => node.id === first_child.data.profile.id).position.y - 35 : this.nodeLocations.find(node => node.id === first_child.data.profile.id).position.y - 55

      slot_right.x = this.nodeLocations.find(node => node.id === last_child.data.profile.id).position.x
      slot_right.y = (nonSiblingPeopleOverlap.length > 0) ? this.nodeLocations.find(node => node.id === first_child.data.profile.id).position.y - 35 : this.nodeLocations.find(node => node.id === first_child.data.profile.id).position.y - 55
    }

    var node_center = settings.person_width / 2;

    var start = {
      x: slot_left.x + node_center + x_pad,
      y: slot_left.y + y_pad
    };

    var end = {
      x: slot_right.x + node_center + x_pad,
      y: slot_right.y + y_pad
    };

    // Reposition the start or end of line
    // Adjust when parent is outside the children boundary
    

    if(Cookie.get("famgenix_active_smartdraw") != 'flux'){
      let partner_line_center_x = this.getPartnerLineCenterX(partner_direction);
      let has_one_child = children.length == 1
      let on_line_end = partner.pos.column > last_child.pos.column
      let on_line_start = partner.pos.column < first_child.pos.column
      if(has_one_child || on_line_end) {
        end.x = slot_partner.x + partner_line_center_x
      } else if(on_line_start) {
        start.x = slot_partner.x + partner_line_center_x
      }
    }

    this.drawLine(start, end, false, children)
  }

  drawProbandAndSiblingsHorizontalLine(mother, proband, siblings) {
    let parent = {
      ...mother,
      children: [
        ...siblings, proband
      ]
    }

    this.drawSiblingHorizontalLine(parent)
  }

  /*
  | - - - - - - - - - - - - - - - - - - - -
  | Handle drawing of Twin Lines
  |
  */

  drawTwinLine(nodes, partner = null, direction = null){
    if(nodes.length < 2) return;

    let sets = this.getTwinSets(nodes)
    let start_node = nodes[0];
    let end_node = nodes[nodes.length - 1];
    let boundary_nodes = { start_node, end_node }

    for(var key in sets) {
      var set = sets[key]
      this.drawTwinMemberLine(set.members, boundary_nodes);
    }

    // 3 children all twins are not connected to parent
    // Add temporary horizontal connecting line
    let set_keys = Object.keys(sets)
    if(set_keys.length == 1 && partner !== null) {
      var key = set_keys[0]
      var twin_members =  sets[key].members
      if(nodes.length == twin_members.length) {
        // this.drawTwinLineToPartner(twin_members, partner, direction)
      }
    }
  }

  drawTwinLineToPartner(members, partner, partner_direction) {

    let first_twin = members[0]
    let last_twin = members[members.length - 1]
    let first_slot = this.matrix.getSlotAt(first_twin.pos.row, first_twin.pos.column);
    let last_slot = this.matrix.getSlotAt(last_twin.pos.row, last_twin.pos.column);

    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      first_slot.x = this.nodeLocations.find(node => node.id === first_twin.data.profile.id).position.x
      first_slot.y = this.nodeLocations.find(node => node.id === first_twin.data.profile.id).position.y

      last_slot.x = this.nodeLocations.find(node => node.id === last_twin.data.profile.id).position.x
      last_slot.y = this.nodeLocations.find(node => node.id === last_twin.data.profile.id).position.y
    }
    //Calculate the center between first twin and last twin
    let center_x = (last_slot.x - first_slot.x) / 2 + first_slot.x
    let row_offset = settings.connector_sibling_line_height_by_row
    let horizontal_top_slot = this.matrix.getSlotAt(first_twin.pos.row - row_offset, first_twin.pos.column)

    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      horizontal_top_slot.x = this.nodeLocations.find(node => node.id === first_twin.data.profile.id).position.x
      horizontal_top_slot.y = this.nodeLocations.find(node => node.id === first_twin.data.profile.id).position.y - 55
    }

    var node_center = settings.person_width / 2
    var twins_center_loc = {
      x: center_x + node_center,
      y: horizontal_top_slot.y - 1
    }

    // Calculate partner line tail loc
    let slot_bottom = this.matrix.getSlotAt(partner.pos.row + row_offset, partner.pos.column)

    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      slot_bottom.x = this.nodeLocations.find(node => node.id === partner.data.profile.id).position.x
      slot_bottom.y = this.nodeLocations.find(node => node.id === partner.data.profile.id).position.y + 55
    }
    let x_offset =  Cookie.get("famgenix_active_smartdraw") == 'flux' ? this.getPartnerLineCenterXFlux(first_slot.x, last_slot.x) : this.getPartnerLineCenterX(partner_direction);
    var partner_line_loc = {
      x: slot_bottom.x + x_offset,
      y: slot_bottom.y + settings.matrix_box_height - 1,
    }

    this.drawLine(twins_center_loc, partner_line_loc)
  }

  drawTwinMemberLine(members, boundary_nodes) {
    if(members.length < 2) return;

    let {x_pad, y_pad} = getXYpad();

    let first_twin = members[0]
    let last_twin = members[members.length - 1]
    let first_slot = this.matrix.getSlotAt(first_twin.pos.row, first_twin.pos.column);
    let last_slot = this.matrix.getSlotAt(last_twin.pos.row, last_twin.pos.column);

    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      first_slot.x = this.nodeLocations.find(node => node.id === first_twin.data.profile.id).position.x
      first_slot.y = this.nodeLocations.find(node => node.id === first_twin.data.profile.id).position.y

      last_slot.x = this.nodeLocations.find(node => node.id === last_twin.data.profile.id).position.x
      last_slot.y = this.nodeLocations.find(node => node.id === last_twin.data.profile.id).position.y
    }

    //Calculate the center between first twin and last twin
    let center_x = (last_slot.x - first_slot.x) / 2 + first_slot.x
    let row_offset = settings.connector_sibling_line_height_by_row
    let horizontal_top_slot = this.matrix.getSlotAt(first_twin.pos.row - row_offset, first_twin.pos.column)

    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      horizontal_top_slot.x = this.nodeLocations.find(node => node.id === first_twin.data.profile.id).position.x
      horizontal_top_slot.y = this.nodeLocations.find(node => node.id === first_twin.data.profile.id).position.y - 55
    }

    let nonSiblingPeopleOverlap = [];
    let people = Object.values(this.family_tree.profile)
    let children = people.filter(person => (person.father_id === first_twin.data.profile.father_id && person.mother_id === first_twin.data.profile.mother_id) || (person.father_id === first_twin.data.profile.mother_id && person.mother_id === first_twin.data.profile.father_id))
    let father = people.find(person => person.id === first_twin.data.profile.father_id)
    let mother = people.find(person => person.id === first_twin.data.profile.mother_id)

    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      let spouse_pos = this.nodeLocations.find(node => node.id === father.id).position
      let partner_pos = this.nodeLocations.find(node => node.id === mother.id).position
      nonSiblingPeopleOverlap = this.nodeLocations.filter((node) => !children.find((child) => node.id === child.id));
      
      let first_child_pos = this.nodeLocations.find(node => node.id === first_twin.data.profile.id).position
      let children_positions = this.nodeLocations.filter((node) => children.find((child) => node.id === child.id));
      let children_xs = [...new Set(children_positions.map(item => item.position.x))];
      let children_min_x = Math.min(...children_xs)
      let children_max_x = Math.max(...children_xs)
  
      let parents_positions = [partner_pos, spouse_pos]
      let parents_xs = [...new Set(parents_positions.map(item => item.x))];
      let parents_min_x = Math.min(...parents_xs)
      let parents_max_x = Math.max(...parents_xs)
  
      nonSiblingPeopleOverlap = nonSiblingPeopleOverlap.filter(node => node.position.y === first_child_pos.y)
  
      nonSiblingPeopleOverlap = nonSiblingPeopleOverlap.filter(node => (node.position.x > children_min_x && node.position.x < parents_max_x) || (node.position.x < children_max_x && node.position.x > parents_min_x))
  
      

      let children_obj = people.filter((person) => children.find((child) => person.id === child.id))
      let partners_of_children = [];
      for (let child of children_obj){
        partners_of_children = partners_of_children.concat(child.partners)
      }
      nonSiblingPeopleOverlap = nonSiblingPeopleOverlap.filter((node) => !partners_of_children.find((person) => node.id === person.id))
  
      let father_siblings = people.filter(person => person.father_id === father.father_id && person.mother_id === father.mother_id && person.id !== father.id && person.father_id && person.mother_id)
      let mother_siblings = people.filter(person => person.father_id === mother.father_id && person.mother_id === mother.mother_id && person.id !== mother.id && person.father_id && person.mother_id)
  

      let cousins_of_children = [];
      for (let sibling of father_siblings) {
        for (let partner of sibling.partners) {
          let cousins = people.filter(person => (person.mother_id === sibling.id && person.father_id === partner.id) || (person.mother_id === partner.id && person.father_id === sibling.id))
          cousins_of_children = cousins_of_children.concat(cousins)
        }
      }
  
      for (let sibling of mother_siblings) {
        for (let partner of sibling.partners) {
          let cousins = people.filter(person => (person.mother_id === sibling.id && person.father_id === partner.id) || (person.mother_id === partner.id && person.father_id === sibling.id))
          cousins_of_children = cousins_of_children.concat(cousins)
        }
      }
  
      nonSiblingPeopleOverlap = nonSiblingPeopleOverlap.filter((node) => cousins_of_children.find((person) => node.id === person.id))
    }

    var node_center = settings.person_width / 2
    var twins_center_loc = {
      x: center_x + node_center + x_pad,
      y: nonSiblingPeopleOverlap.length > 0 ? horizontal_top_slot.y + y_pad + 20 : horizontal_top_slot.y + y_pad
    }

    // Adjust Edges of sibling line (left and right edge)
    if(first_twin.rkey === boundary_nodes.start_node.rkey) {
      this.adjustLeftEdgeSiblingLine(first_twin, twins_center_loc);
    }
    if(last_twin.rkey === boundary_nodes.end_node.rkey) {
      this.adjustRightEdgeSiblingLine(last_twin, twins_center_loc);
    }

    // Draw slanting twin line (applicable for both fraternal and identical)
    for(var i=0; i<members.length; i++) {
      var member = members[i];
      var slot = this.matrix.getSlotAt(member.pos.row, member.pos.column);
      if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
        slot.x = this.nodeLocations.find(node => node.id === member.data.profile.id).position.x
        slot.y = this.nodeLocations.find(node => node.id === member.data.profile.id).position.y
      }
      var twin_loc = {
        x: slot.x + node_center + x_pad,
        y: slot.y + y_pad
      }
      this.drawLine(twins_center_loc, twin_loc)
    }

    // Draw line connecting identical twin
    var margin_top = horizontal_top_slot.y + 30
    var identical_line = {
      from: {
          x: first_slot.x + node_center + x_pad,
          y:  margin_top + y_pad
      },
      to: {
          x: last_slot.x + node_center + x_pad,
          y:  margin_top + y_pad
      }
    }
    this.drawLineForIdenticalTwin(members, twins_center_loc, identical_line)
  }

  drawLineForIdenticalTwin(members, twins_center_loc, identical_line) {
    var node_center = settings.person_width / 2
    let {x_pad, y_pad} = getXYpad();

    // Draw Identical twin line
    // This will add horizontal line connecting identical twin
    var first_twin_line = null, first_twin_line_index = null;
    var last_twin_line = null;
    for(var i=0; i<members.length; i++) {
      var member = members[i];

      var twin_type = member.data.profile.twin_type;
      var slot = this.matrix.getSlotAt(member.pos.row, member.pos.column);
      if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
        slot.x = this.nodeLocations.find(node => node.id === member.data.profile.id).position.x
        slot.y = this.nodeLocations.find(node => node.id === member.data.profile.id).position.y
      }
      var twin_loc = {
        x: slot.x + node_center + x_pad,
        y: slot.y + y_pad
      }

      if(first_twin_line === null && twin_type == 'identical') {
        first_twin_line_index = i;
        first_twin_line = {
          from: twins_center_loc,
          to: twin_loc
        };
      }

      if(i > first_twin_line_index && twin_type == 'identical') {
        last_twin_line = {
          from: twins_center_loc,
          to: twin_loc
        }
      }
    }


    if(first_twin_line === null && last_twin_line === null) return;

    // Calculate the connecting line for identical twin
    var identical_start = this.getIntersection(first_twin_line, identical_line);
    var identical_end = this.getIntersection(last_twin_line, identical_line);

    // Draw the actual connecting line for identical twin
    this.drawLine(identical_start, identical_end);
  }

  getIntersection(line1, line2)
  {
      let x1 = line1.from.x;
      let y1 = line1.from.y;
      let x2 = line1.to.x;
      let y2 = line1.to.y;

      let x3 = line2.from.x;
      let y3 = line2.from.y;
      let x4 = line2.to.x;
      let y4 = line2.to.y;

      var ua, ub, denom = (y4 - y3)*(x2 - x1) - (x4 - x3)*(y2 - y1);
      if (denom == 0) {
          return null;
      }
      ua = ((x4 - x3)*(y1 - y3) - (y4 - y3)*(x1 - x3))/denom;
      ub = ((x2 - x1)*(y1 - y3) - (y2 - y1)*(x1 - x3))/denom;
      return {
          x: x1 + ua * (x2 - x1),
          y: y1 + ua * (y2 - y1)
      };
  }

  adjustLeftEdgeSiblingLine(first_twin, twins_center_loc) {
    let center_loc = {...twins_center_loc};
    center_loc.x -=1; // adjust for the width of vertical line

    var first_twin_slot = this.matrix.getSlotAt(first_twin.pos.row, first_twin.pos.column);
    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      first_twin_slot.x = this.nodeLocations.find(node => node.id === first_twin.data.profile.id).position.x
      first_twin_slot.y = this.nodeLocations.find(node => node.id === first_twin.data.profile.id).position.y
    }
    var left_edge = {
      x: first_twin_slot.x,
      y: twins_center_loc.y
    }

    this.clearLine(left_edge, center_loc);
  }

  adjustRightEdgeSiblingLine(last_twin, twins_center_loc) {
    let {x_pad, y_pad} = getXYpad();

    let center_loc = {...twins_center_loc};
    center_loc.x +=1; // adjust for the width of vertical line

    var last_twin_slot = this.matrix.getSlotAt(last_twin.pos.row, last_twin.pos.column);
    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      last_twin_slot.x = this.nodeLocations.find(node => node.id === last_twin.data.profile.id).position.x
      last_twin_slot.y = this.nodeLocations.find(node => node.id === last_twin.data.profile.id).position.y
    }
    var right_edge = {
      x: last_twin_slot.x + x_pad + settings.person_width / 2,
      y: twins_center_loc.y
    }

    this.clearLine(center_loc, right_edge);
  }

  getLeftMostTwin(nodes) {
    for(var node of nodes) {
      var { profile } = node.data
      if(profile.twin_set !== null) return node;
    }
    return null;
  }

  getRightMostTwin(nodes) {
    let right_most = null;
    for(var node of nodes) {
      var { profile } = node.data
      if(profile.twin_set !== null) right_most = node;
    }
    return right_most;
  }

  getTwinSets(nodes) {
    let sets = {}
    // Arrage twin into sets
    let left_most_twin_node = null;
    for(var node of nodes) {
      var { profile } = node.data
      if(profile.twin_set === null) continue;

      if(left_most_twin_node === null) left_most_twin_node = node

      if(profile.twin_set in sets) {
        sets[profile.twin_set].members.push(node)
      } else {
        sets[profile.twin_set] = {members: [node]}
      }
    }

    return sets;
  }

  /*
  |
  | Ends: Handle drawing of Twin Lines
  | - - - - - - - - - - - - - - - - - - - -
  */

}

export default NodeConnector
