import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";

import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css';

import ErrorSummary from './error-summary'
import ActivityIndicator from './activity-indicator'

import * as patient_actions from '../store/patient/actions';
import * as model_health_history from '../model-states/m-health-history'
import ModalConfirmDelete from './modal-confirm-delete';

import geneResults from '../assets/json/gene-result.json'
import geneStatus from '../assets/json/gene-status.json'

import genetic_testing_api from '../api/genetic-testing-api'
import * as static_list_actions from '../store/static_list/actions'
import ControlSelectGene from './control-select-gene';
import { components } from "react-select";
import umls_api from "../api/umls-api";
import { cloneDeep, isNaN } from "lodash";


class Expire extends Component {
  constructor(props) {
    super(props);

    this.state = {visible: true};
    this.timer = null;
  }

  componentDidMount() {
    this.setTimer();
    this.mounted = true;
  }

  setTimer() {
    // clear any existing timer
    this.timer = (this.timer != null) ? clearTimeout(this.timer) : null;

    // hide after `delay` milliseconds
    this.timer = setTimeout(function(){
      if (this.mounted) {
        this.setState({visible: false});
        this.timer = null;
      }
    }.bind(this), this.props.delay);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this.mounted = false;
  }

  render() {
    if (this.state.visible) {
      return (<div>{this.props.children}</div>);
    }

    return (<span />);
  }
}

class MenuList extends Component {
  constructor(props) {
    super(props);
    this.unsureChosen = this.unsureChosen.bind(this);
  }

  queryUMLS() {
    // trigger event which queries UMLS endpoint
    const isIE = false || !!document.documentMode;
    var event
    if (isIE) {
      event = new CustomEvent('query-umls');
    } else {
      event = new Event('query-umls');
    }
    window.dispatchEvent(event);
  }

  queryMoreUMLS() {
    // trigger event which loads more UMLS results
    const isIE = false || !!document.documentMode;
    var event
    if (isIE) {
      event =  new CustomEvent('query-more-umls');
  } else {
      event = new Event('query-more-umls');
    }
    window.dispatchEvent(event);
  }

  unsureChosen(){
    let unsure = {value:'unsure', label: 'Unsure'}
    this.props.setValue(unsure)

   }

  render() {
    //React select filters options when searching, we want unsure to be able to be searched and also displayed all the time so added an option
    //of unsure to the options that include umls and also initial options that will allow it to be searched if no umls results
    let search_value = this.props.selectProps.inputValue
    if (Array.isArray(this.props.children)) {
      return (
        <React.Fragment>
          <components.MenuList {...this.props}>
            {this.props.children}
            <components.Option {...this.props}>
              <a id="unsureChoice" onClick={this.unsureChosen}>Unsure</a>
            </components.Option>
            <components.Option {...this.props}>
            {search_value.length >= 1 && (
              <a onClick={this.queryMoreUMLS}>Load More</a>
            )}
            </components.Option>
          </components.MenuList>
        </React.Fragment>
      );

    } else {

      this.queryUMLS();
      return (
        <React.Fragment>
          <components.MenuList {...this.props}>
            {(search_value.length == 0) && (
              <components.NoOptionsMessage {...this.props}>
                Search Genes
              </components.NoOptionsMessage>
            )}
            {(search_value.length > 0) && (
              <components.Option {...this.props}>
                  {(search_value.length >= 1) && (
                      <a id="unsureChoice" onClick={this.unsureChosen}>Unsure</a>
                  )}
              </components.Option>
            )}
              <Expire delay={5000}>
                <ActivityIndicator />
              </Expire>
          </components.MenuList>
        </React.Fragment>
      );

    }
  }
}


class ModalGeneTest extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      loading_delete: false,
      errorMessages: [],
      rkey: null,
      id: null,
      gene: null,
      gene_id: null,
      result: null,
      resultLabel: null,
      status: null,
      variant: '',
      type: 'mutation_search',
      openModalConfirmDelete: false,
      modalConfirmDeleteHash: null,
      static_selected_gene: null,

      genetic_testings_options: [],
      search_val: "",
    }

    this.previous_search_val = "";
    this.umls_results_start = 0;

    this.handleChange = this.handleChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.queryUMLS = this.queryUMLS.bind(this);
    this.searchUMLS = this.searchUMLS.bind(this);
    this.loadUmlsResults = this.loadUmlsResults.bind(this);
    this.queryMoreUMLS = this.queryMoreUMLS.bind(this);
    this.isGeneFromUMLS = this.isGeneFromUMLS.bind(this);

  }

  async componentDidMount() {

    if (this.props.geneDetail) {
      await this.setStaticGeneItem(this.props.geneDetail.gene_id, this.props.geneDetail.gene);
      let result = this.props.geneDetail.result ? this.getLabelResult(this.props.geneDetail.result) : ""
      this.setState({
        rkey: this.props.geneDetail.rkey,
        id: this.props.geneDetail.id,
        gene: this.props.geneDetail.gene,
        umls_id: this.props.geneDetail.umls_id,
        umls_name: this.props.geneDetail.umls_name,
        gene_id: this.props.geneDetail.gene_id,
        result: result.value,
        variant: this.props.geneDetail.variant,
        status: this.props.geneDetail.status,
        type: this.props.geneDetail.type
      })
    } else {
      let gene_detail = model_health_history.createHistoryGene()
      this.setState({ rkey: gene_detail.rkey })
    }

    this.mounted = true;

    window.addEventListener('query-umls', this.queryUMLS, false);
    window.addEventListener('query-more-umls', this.queryMoreUMLS, false);
  }

  async componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('query-umls', this.queryUMLS, false);
    window.removeEventListener('query-more-umls', this.queryMoreUMLS, false);
  }

  queryUMLS(event) {
    const search_val = this.state.search_val;
    if (typeof(search_val) !== "string"
        || search_val.trim() === "") {
          return;
        }

    const trimmed_search_value = search_val.trim();
    if(trimmed_search_value.length <= 1) {
      return;
    }

    if (trimmed_search_value !== this.previous_search_val) {

      // using this value out of state because is used in triggering UMLS
      // we don't want to have the delay of updating redux state, we want it set immediately
      this.previous_search_val = trimmed_search_value;
      this.umls_results_start = 0;
      this.searchUMLS(trimmed_search_value, this.umls_results_start);

    }
  }

  async searchUMLS(term, start) {
    const payload = {term: term, start: start};
    let search_results = null;
    this.setState({umlsLoading: true})
    try {

      search_results = await umls_api.search_umls_gene(payload);
      console.log(search_results)
      this.umls_results_start += 10;
      if (search_results) {

        // sort by name alphabetically
        search_results.ConceptList = search_results.ConceptList.sort((a, b) => a.Name.localeCompare(b.Name))

        this.loadUmlsResults(search_results);
      }

    } catch (err) {
      // something went wrong with UMLS search
      console.log(err);
    }
    this.setState({umlsLoading: false})
  }

  loadUmlsResults(search_results) {
    let genetic_testing_optons = cloneDeep(this.state.genetic_testings_options);
    let existing_umls = [];
    let had_existing_umls = false;

    for(let i=0; i<genetic_testing_optons.length; i++) {
      if (genetic_testing_optons[i].label === "UMLS") {
        existing_umls = genetic_testing_optons[i].options;
        had_existing_umls = true;
        break;
      }
    }

    let existing_cui_dictionary = {};
    for (let i=0; i<existing_umls.length; i++) {
      existing_cui_dictionary[existing_umls[i].value] = existing_umls[i].value;
    }

    const concept_list = search_results["ConceptList"];
    if (Array.isArray(concept_list) && concept_list.length > 0) {

      for (let i=0; i<concept_list.length; i++) {
        const c = concept_list[i];
        const cui = c["CUI"];
        const name = c["Name"];
        const preferred_name = c["PreferredName"];

        if (cui in existing_cui_dictionary) {
          for (let x=0; x<existing_umls.length; x++) {
            if (existing_umls[x].value === cui) {
              existing_umls[x].label = name;
              break;
            }
          }
        } else {

          existing_umls.push({
            value: cui,
            label: name
          });

        }
      }
    }

    existing_umls.sort((a,b)=>{
        if (a.label.length < b.label.length) return -1
        if (a.label.length > b.label.length) return 1
        return 0
    })

    if (had_existing_umls) {

      for(let i=0; i<genetic_testing_optons.length; i++) {
        if (genetic_testing_optons[i].label === "UMLS") {
          genetic_testing_optons[i].options = existing_umls;
          break;
        }
      }

    } else {
      genetic_testing_optons.push({
        label: "UMLS",
        options: existing_umls
      });
    }

    this.setState({ genetic_testings_options: genetic_testing_optons });
  }

  queryMoreUMLS(event) {
    const search_val = this.state.search_val;
    if (typeof(search_val) !== "string"
        || search_val.trim() === "") {
          return;
        }

    const trimmed_search_value = search_val.trim();
    if(trimmed_search_value.length <=1) {
      return;
    }

    if (trimmed_search_value !== this.previous_search_val) {

      // using this value out of state because is used in triggering UMLS
      // we don't want to have the delay of updating redux state, we want it set immediately
      this.previous_search_val = trimmed_search_value;
      this.umls_results_start = 0;
      this.searchUMLS(trimmed_search_value, this.umls_results_start);

    } else {
      this.searchUMLS(trimmed_search_value, this.umls_results_start);
    }
  }


  isGeneFromUMLS(gene_id) {
    gene_id = gene_id + "";
    const is_from_umls = gene_id.substr(0,1) === "C";
    return is_from_umls
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSearchInputChange(search_val, action) {
    this.setState({ search_val: search_val });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  validateDropDown(key) {
    return (this.state[key] !== null)
  }

  validate() {
    let errorMessages = []
    if (this.state.gene_id === null) {
      errorMessages.push('Gene is required')
    }

    // if (this.state.result === null) {
    //   errorMessages.push('Result is required')
    // }

    // if (this.state.variant.trim().length == 0) {
    //   errorMessages.push('Variant is required')
    // }

    // if (this.state.status === null) {
    //   errorMessages.push('Status is required')
    // }

    this.setState({ errorMessages })
    return errorMessages.length == 0
  }

  async onClickSave() {
    try {

      this.setState({ errorMessages: [], loading: true })

      let isValid = this.validate()
      if (!isValid) return

      // Save member genetic testing to API
      let gene_payload = {};
      const is_from_umls = this.isGeneFromUMLS(this.state.gene_id);
      // const prev_disease_umls = this.isDiseaseFromUmls(this.state.prev_gene_)
      if(is_from_umls || this.state.gene_id =='unsure'){
        gene_payload = {
          member_id: this.props.profile.id,
          umls_id: this.state.gene_id,
          umls_name: this.state.static_selected_gene.gene_name,
          result: this.state.result,
          variant: this.state.variant,
          status: this.state.status,
          type: this.state.type,
      }
        }else{
            gene_payload = {
            member_id: this.props.profile.id,
            gene: this.state.gene,
            gene_id: this.state.gene_id,
            result: this.state.result,
            variant: this.state.variant,
            status: this.state.status,
            type: this.state.type,
          }
      }
      let new_gene = null;
      var data =  null;
      if(this.state.id == null) {
        data = await genetic_testing_api.post_member_genetic_testing(gene_payload)
        new_gene = true
      } else {
        data = await genetic_testing_api.patch_member_genetic_testing_id(this.state.id, gene_payload)
        new_gene = false
      }

      // Save to Redux
      let history_gene_detail = {}

      if(is_from_umls || this.state.gene_id =='unsure'){
        history_gene_detail={
          rkey: this.state.rkey,
          id: data.id,
          member_id: this.props.profile.id,
          gene:  this.state.static_selected_gene.gene_name,
          gene_id:  this.state.gene_id,
          umls_id: this.state.gene_id,
          umls_name: this.state.static_selected_gene.gene_name,
          result: this.state.result,
          variant: this.state.variant,
          status: this.state.status,
          type: this.state.type,
        }
      }else{
        history_gene_detail={
          member_id: this.props.profile.id,
          gene: this.state.gene,
          gene_id: this.state.gene_id,
          result: this.state.result,
          variant: this.state.variant,
          status: this.state.status,
          type: this.state.type,
        }
      }

      let genetics_payload = { ownerRkey: this.props.patientRkey, history_gene_detail };
      this.props.dispatch(patient_actions.save_history_gene_detail(genetics_payload));

      // ***** TODO: account for umls genes
      if(new_gene){
        if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
          let genes = cloneDeep(this.props.getPedigreeData().getGenes(this.props.patientRkey))
          let profile_proband = cloneDeep(this.props.getPedigreeData().getProfile(this.props.patientRkey))
          let new_gene_to_be_pushed = cloneDeep(history_gene_detail)
          genes.push(new_gene_to_be_pushed)
          profile_proband.genetic_testing = genes;
          await this.props.getPedigreeData().setGenes(this.props.patientRkey, genes)
          await this.props.getPedigreeData().setProfile(this.props.patientRkey, profile_proband)
        }
      }
      else{
        if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
          let genes = cloneDeep(this.props.getPedigreeData().getGenes(this.props.patientRkey))
          let profile_proband = cloneDeep(this.props.getPedigreeData().getProfile(this.props.patientRkey))
          for(let gene of genes){
            if(gene.id === history_gene_detail.id){
              gene.gene = history_gene_detail.gene
              gene.gene_id = history_gene_detail.gene_id
              gene.gene_id_id = history_gene_detail.gene_id
              gene.member_id_id = history_gene_detail.member_id
              gene.result = history_gene_detail.result
              gene.rkey = history_gene_detail.rkey
              gene.status = history_gene_detail.status
              gene.type = history_gene_detail.type
              gene.umls_id = history_gene_detail.umls_id
              gene.umls_name = history_gene_detail.umls_name
              gene.variant = history_gene_detail.variant
            }
          }
          profile_proband.genetic_testing = genes;
          await this.props.getPedigreeData().setGenes(this.props.patientRkey, genes)
          await this.props.getPedigreeData().setProfile(this.props.patientRkey, profile_proband)
        }
      }


      this.props.onSave({rkey: this.props.patientRkey, gene: history_gene_detail})
    } catch (error) {
      this.setState({ errorMessages: [error.message] })
    } finally {
      this.setState({ loading: false })
    }
  }

  handleSelectChange(item) {
    this.setState({ ...item })
  }

  handleSelectGene(gene){
    let gene_id = (gene !== null) ? gene.value : null
    let gene_name = (gene !== null) ? gene.label : null
    this.setState({ gene_id, gene_name, gene});
    this.setStaticGeneItem(gene_id, gene_name);
  }

  setStaticGeneItem(gene_id, gene_name) {
  let static_selected_gene={
      id: gene_id,
      gene_name: gene_name
  }
  this.setState({ static_selected_gene: static_selected_gene });
}

  onClickOpenModalConfirmDelete() {
    this.setState({
      openModalConfirmDelete: true,
      modalConfirmDeleteHash: new Date().getTime()
    });
  }

  async onClickDelete() {
    try {
      this.setState({ errorMessages: [], loading_delete: true })

      // delete genetic testing
      if(this.state.id !== null) {
        await genetic_testing_api.delete_member_genetic_testing_id(this.state.id)
      }

      if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
        let genes = cloneDeep(this.props.getPedigreeData().getGenes(this.props.patientRkey))
        let profile_proband = cloneDeep(this.props.getPedigreeData().getProfile(this.props.patientRkey))
        genes = genes.filter(gene => gene.id !== this.state.id)
        profile_proband.genetic_testing = genes;
        await this.props.getPedigreeData().setGenes(this.props.patientRkey, genes)
        await this.props.getPedigreeData().setProfile(this.props.patientRkey, profile_proband)
      }
      else{
        this.props.dispatch(patient_actions.delete_history_gene_detail({
          ownerRkey: this.props.patientRkey,
          gene_detail_rkey: this.state.rkey
        }));
      }



      if('onDelete' in this.props) {
        this.props.onDelete({
          rkey: this.props.patientRkey,
          gene_rkey: this.state.rkey
        });
      } else {
        this.props.onClose();
      }

    } catch (error) {
      this.setState({ errorMessages: [error.message] })
    } finally {
      this.setState({ loading_delete: false })
    }
  }


  getGeneLabel(id) {
    let genetic_testings = this.props.static_list.genetic_testings;
    if (genetic_testings.length == 0) return '';
    var gene = genetic_testings.find(item => item.id == id)

    if (typeof (gene) === 'undefined') return ''
    return gene.gene;

  }

  getLabelResult(result) {

    if (geneResults.length == 0) return '';
    let gene_result = geneResults.find(item => item.value == result || item.label == result)

    if (typeof (result) === 'undefined') return ''
    return gene_result
  }

  getLabelStatus(id) {
    if (geneStatus.length == 0) return '';
    var status = geneStatus.find(item => item.value == id || item.value == id)

    if (typeof (status) === 'undefined') return ''
    return status.label;
  }

  render() {
    let static_selected_gene = this.state.static_selected_gene
    let gene_result = this.getLabelResult(this.state.result)
    let gene = (static_selected_gene) ? {value: static_selected_gene.id, label: static_selected_gene.gene_name} : null;
    let result = gene_result ? { value: gene_result.value, label:  gene_result.label}: null;
    let status = { value: this.state.status, label: this.getLabelStatus(this.state.status)};

    return ReactDOM.createPortal(
      <React.Fragment>

        {this.state.openModalConfirmDelete &&
          <ModalConfirmDelete
            title="Delete Gene"
            message="Do you want to delete this gene?"
            isOpen={this.state.openModalConfirmDelete}
            onCancel={() => this.setState({ openModalConfirmDelete: false })}
            onOk={() => this.onClickDelete()}
            loading={this.state.loading_delete}
            errorMessages={this.state.errorMessages}
          />
        }

        <div style={{ display: 'block', zIndex: 1040 }} className="modal fade in" role="dialog">
          <div onClick={(e) => e.stopPropagation()} className="modal-dialog modal-xl" role="document">

            <div className="modal-content">
              <div className="modal-header">
                <button
                  onClick={() => this.props.onClose()}
                  type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="fa fa-close"></i>
                </button>
                <h4 className="modal-title text-white text-capitalize">{this.props.title}</h4>
              </div>

              <div className="modal-body">

                <ErrorSummary
                  transparent={true}
                  errorMessages={this.state.errorMessages}
                />

                <div className="row">
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Gene</label>
                          <div>
                            <ControlSelectGene
                              options={this.state.genetic_testings_options}
                              onChange={(item) => this.handleSelectGene(item)}
                              value={gene}
                              isLoading={this.state.umlsLoading}
                              components={{ MenuList, DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                              inputValue={this.state.search_val}
                              onInputChange={(input_val, action) => this.handleSearchInputChange(input_val, action)}
                              placeholder="&nbsp;"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Result</label>
                          <div className="custom-select">

                            <Dropdown
                              options={[
                                { value: '', label: " " },
                                ...geneResults
                              ]}
                              onChange={(item) => {
                                let result = (item) ? item.value : null;
                                this.handleSelectChange({ result })
                              }}
                              value={result}
                              placeholder="&nbsp;"
                            />

                          </div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Variant</label>
                          <input
                            name="variant"
                            value={this.state.variant}
                            onChange={this.handleChange}
                            type="text" class="form-control" />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Status</label>
                          <div className="custom-select">
                            <Dropdown
                              options={[
                                { value: '', label: " " },
                                ...geneStatus
                              ]}
                              onChange={(item) => {
                                let status = (item) ? item.value : null;
                                this.handleSelectChange({ status })
                              }}
                              value={status}
                              placeholder="&nbsp;"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Variant-Specific
                              <span className='i-tooltip-right'>
                                <i className="fa fa-info-circle"></i>
                                <span className="i-tooltiptext-right">
                                  Tested for a specific variant of the mutation. For example,
                                  when another family member has already tested positive.
                                </span>
                              </span>
                          </label>
                          <div className="checkbox">
                            <label className="switch">
                              <input
                                name="variant-specific"
                                id="variant-specific"
                                onChange={() => {
                                  let type = (this.state.type === 'direct_gene_test') ? 'mutation_search' : 'direct_gene_test';
                                  this.setState({type});
                                }}
                                checked={this.state.type === 'direct_gene_test'}
                                type="checkbox" />
                              <span className="slider round"></span>
                            </label>
                          </div>
                          </div>
                        </div>

                    </div>
                  </div>

                  <div className="col-md-1 col-xs-1">
                    <div className="form-group">
                      <label></label>
                      {this.props.geneDetail &&
                        <a className="remove-row" onClick={() => this.onClickOpenModalConfirmDelete()}><i className="fa fa-trash"></i> </a>
                      }
                    </div>
                  </div>

                </div>

              </div>
              <div className="modal-footer">
                <button type="button"
                  onClick={() => this.props.onCancel()}
                  className="btn btn-light-outline no-margin-right" data-dismiss="modal">Cancel</button>
                <button type="button"
                  onClick={() => this.onClickSave()}
                  className="btn btn-dark " data-toggle="modal" data-dismiss="modal">Save</button>

                <ActivityIndicator loading={this.state.loading} modal={true} />

              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
      ,
      document.body
    );
  }

}

const redux_state = state => ({
  // patient: state.patient
  static_list: state.static_list
});

const redux_actions = dispatch => ({
  dispatch: (action) => dispatch(action)
});

export default connect(redux_state, redux_actions)(ModalGeneTest);
