import React, { Component } from "react";
import FamilyTreeSibling from "./sibling";
import ModalTwins from "../modal-twins";
import FamilyTreeProband from "./proband";

class FamilyTreeProbandSiblings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalTwin: false,
      modalTwinHash: new Date().getTime(),
      selected_sibling: null
    };
    this.onClickOpenModalTwin = this.onClickOpenModalTwin.bind(this);
  }

  onClickOpenModalTwin(sibling_id) {
    this.setState({
      openModalTwin: true,
      modalTwinHash: new Date().getTime(),
      selected_sibling: sibling_id
    });
  }

  render() {

    for(let sibling of this.props.proband_siblings) {
      sibling.gender = (sibling.gender === null) ? 'u' : sibling.gender
    }

    return (
      <React.Fragment>
        {this.state.openModalTwin && (
          <ModalTwins
            selected_sibling_id={this.state.selected_sibling}
            key={this.state.modalTwinHash}
            twinFor="siblings"
            maleLabel="Brother"
            femaleLabel="Sister"
            otherLabel="Sibling"
            dispatch={this.props.dispatch}
            patient={this.props.patient}
            fatherId={this.props.patient.patient.father.id}
            motherId={this.props.patient.patient.mother.id}
            familyId={this.props.patient.patient.familyId}
            onClose={() => this.setState({ openModalTwin: false })}
            proband_on_siblings={true}
          />
        )}

        <FamilyTreeProband
          key={this.props.patient.patient.hash_key}
          ownerRkey="proband"
          onClickTwin={this.onClickOpenModalTwin}
          patient={this.props.patient}
          dispatch={this.props.dispatch}
          deleteMember={this.props.deleteMember}
        />

        {this.props.proband_siblings
          .filter(i => i.gender.toUpperCase() === "M")
          .map((sibling, index) => {
            return (
              <FamilyTreeSibling
                key={sibling.rkey}
                collapsed={true}
                label={"Brother #" + (index + 1)}
                sibling={sibling}
                parent_side="both"
                onClickTwin={this.onClickOpenModalTwin}
                patient={this.props.patient}
                dispatch={this.props.dispatch}
                fatherId={this.props.patient.patient.father.id}
                motherId={this.props.patient.patient.mother.id}
                deleteMember={this.props.deleteMember}
              />
            );
          })}

        {this.props.proband_siblings
          .filter(i => i.gender.toUpperCase() === "F")
          .map((sibling, index) => {
            return (
              <FamilyTreeSibling
                key={sibling.rkey}
                collapsed={true}
                label={"Sister #" + (index + 1)}
                sibling={sibling}
                parent_side="both"
                patient={this.props.patient}
                onClickTwin={this.onClickOpenModalTwin}
                dispatch={this.props.dispatch}
                fatherId={this.props.patient.patient.father.id}
                motherId={this.props.patient.patient.mother.id}
                deleteMember={this.props.deleteMember}
              />
            );
          })}

        {this.props.proband_siblings
          .filter(i => i.gender.toUpperCase() === "U")
          .map((sibling, index) => {
            return (
              <FamilyTreeSibling
                key={sibling.rkey}
                collapsed={true}
                label={"Sibling #" + (index + 1)}
                sibling={sibling}
                parent_side="both"
                patient={this.props.patient}
                onClickTwin={this.onClickOpenModalTwin}
                dispatch={this.props.dispatch}
                deleteMember={this.props.deleteMember}
              />
            );
          })}
      </React.Fragment>
    );
  }
}

export default FamilyTreeProbandSiblings;
