import React, { Component } from 'react';
import { connect } from "react-redux";
import Cookie from 'js-cookie';

import BasePage from "./pages/p-base-page.js";

import './assets/production/css/animate.css';
import './assets/production/css/bootstrap.css';
import './assets/production/css/et-line-icons.css';
import './assets/production/css/font-awesome.min.css';
import './assets/production/css/style.css';
import './assets/production/css/responsive.css';
import './assets/production/css/bootstrap-popover-x.css'

import './assets/build/css/jquery.dataTables.min.css';
import './assets/build/css/famhis.css';
import './assets/build/css/famhis-custom.css';
import './assets/css/dev-styles.css';
import './assets/css/bem-presets.css';

import 'icheck/skins/all.css';

import 'react-activity/dist/react-activity.css';

import { BrowserRouter as Router } from "react-router-dom";
import RouteList from "./route-list";

import sdk from "./api/sdk";
import * as action_auth from './store/session/actions'
import helper from "./helpers/index"

import ActivityIndicator from "./components/activity-indicator";
import api_auth from './api/authentication';
import { save_risk_settings } from './store/patient/actions';
import {set_cookie} from "./helpers/helper-cookies";
import {
  save_login_data
} from './helpers/helper-login';

const app_alert_banner = "app-alert-banner";
const TIMEOUT_COOKIE_NAME = "TIMEOUT_TIMESTAMP";

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }

    this.timer = null;

    this.resetTimer = this.resetTimer.bind(this);
    this.checkOnlineStatus = this.checkOnlineStatus.bind(this);
    this.checkTimeoutTimestamp = this.checkTimeoutTimestamp.bind(this);

    // this is for opening multiple tabs it keeps the user signed in
    let tokens = localStorage.getItem('famgenix_app_tokens');
    let extra_data = localStorage.getItem('extra_data');
    if (tokens !== null && typeof (tokens) !== 'undefined') {
      localStorage.removeItem('famgenix_app_tokens');
      localStorage.removeItem('extra_data');
      sessionStorage.setItem('famgenix_app_tokens', tokens);
      sessionStorage.setItem('extra_data', extra_data);
    }
  }

  async componentDidMount() {
    Cookie.remove(TIMEOUT_COOKIE_NAME);

    let render_patient_pedigree = localStorage.getItem('render-patient-pedigree')
    render_patient_pedigree = render_patient_pedigree == 'true'

    let is_emr_session = sessionStorage.getItem('is_emr_session')
    is_emr_session = is_emr_session == 'true' ? true : false

    if(!render_patient_pedigree && !is_emr_session){
      await this.reloadCredentials();
    }

    let survey = window.location.pathname.search(/survey/);
    if(survey !== 1 && !render_patient_pedigree) {
      document.body.addEventListener('mousemove', () => this.resetTimer()); // catches mouse movements
      document.body.addEventListener('mousedown', () => this.resetTimer()); // catches mouse movements
      document.body.addEventListener('click', () => this.resetTimer());  // catches mouse clicks
      document.body.addEventListener('scroll', () => this.resetTimer()); // catches scrolling
      document.body.addEventListener('keypress', () => this.resetTimer()); // catches keyboard actions
    }

    window.onbeforeunload = (event) => {
      if (typeof event == 'undefined') {
        event = window.event;
      }
      if (event) {
        this.logout();
      }
    };

    /* This event listener was causing a logout on browser refresh also so comment out */
    //window.addEventListener('beforeunload', (e) => this.onExit(e)); //catches window unload event

    if (!render_patient_pedigree) {
      setInterval(this.checkOnlineStatus, 2000);
      setInterval(this.checkTimeoutTimestamp, 5000);
    }
  }

  checkOnlineStatus() {
    const online = window.navigator.onLine;
    const alert_banner = document.getElementById(app_alert_banner);
    alert_banner.innerHTML = "No Internet Connection, please check your Wi-Fi or VPN";
    if (alert_banner) {
      if (online) {
        alert_banner.style.display = "none";
      } else {
        alert_banner.style.display = "block";
      }
    }
  }

  checkTimeoutTimestamp() {
    const default_logout_time = 900000;
    const timed_logout = this.props.session.user.auto_logout_time ? (this.props.session.user.auto_logout_time*60*1000) : default_logout_time;

    let timestamp = Cookie.get(TIMEOUT_COOKIE_NAME);
    if (timestamp) {
      timestamp = new Date(timestamp);

      const now = new Date();

      if ((now - timestamp) > timed_logout) {// time is in milliseconds (1000 is 1 second)
        const response = api_auth.logoutRemoveSessionStorage();
        if (response) {
          // set flag in local storage and use in login page
          localStorage.setItem("timedLogout", true);
        }
      }
    } else {
      timestamp = new Date();
      set_cookie(TIMEOUT_COOKIE_NAME, timestamp.toUTCString());
    }

    // console.log("******TIMESTAMP******");
    // console.log(timestamp);
  }

  // onExit(event) {
  //   /* Cancel the event */
  //   // event.preventDefault();
  //
  //   /* Chrome requires returnValue to be set */
  //   // event.returnValue = '';
  //   this.logout();
  // }

  logout() {
    api_auth.logout();
  }

  resetTimer() {
    let timestamp = new Date();
    set_cookie(TIMEOUT_COOKIE_NAME, timestamp.toUTCString());
  }

  async reloadCredentials() {
    try {

      // Retrieve and check if cookie exists
      let payload = sdk.tokens_from_cookie();

      // Need another endpoint to re-validate the current token
      this.setState({loading: true});
      await helper.delay(2);

      save_login_data(this.props.session.user, payload, this.props.dispatch);
      this.props.dispatch(action_auth.authenticated(true));
      this.setState({loading: false});


    } catch (err) {
      console.log(err)
    }
  }

  render() {

    if(this.state.loading) return <div className="app-loading-wraper"><ActivityIndicator loading={true} /></div>

    return (
      <Router>
        <BasePage>
          <div id={app_alert_banner} className="alert alert-danger offline-banner" role="alert">No Internet Connection, please check your Wi-Fi or VPN</div>
          <RouteList authenticated={this.props.session.authenticated} user={this.props.session.user} />
        </BasePage>
      </Router>
    )

  }
}

const redux_state = state => ({
  session: state.session
});

const redux_actions = dispatch => ({
  dispatch: (action) => dispatch(action)
});

export default connect(redux_state, redux_actions)(App);
