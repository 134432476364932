import React, { Component } from 'react';
import SelectDefault from './control-select-default'

import ErrorSummary from './error-summary'
import { cloneDeep } from 'lodash'
import { radix_bases } from '../helpers/helper-number-bases';
import skip_logic_map from '../assets/json/diseases-skip-logic-map.json';

// const keys_mapping = {
//   msi: 'SL026',
//   mlh1_ihc: 'SL027',
//   msh2_ihc: 'SL028',
//   msh6: 'SL029',
//   pms2: 'SL030',
//   mhl1_hyper: 'SL031'
// }

const keys_mapping = skip_logic_map.uterine;

const msi_options = [
  { value: '', label: ' ' },
  { value: 'unknown', label: 'Unknown' },
  { value: 'msi-high', label: 'MSI-High' },
  { value: 'msi-low', label: 'MSI-Low' },
  { value: 'msi-mss', label: 'MSS' },
]

const normal_abnormal_options = [
  { value: '', label: ' ' },
  { value: 'unknown', label: 'Unknown' },
  { value: 'normal', label: 'Normal' },
  { value: 'abnormal', label: 'Abnormal' },
]

const normal_hyper_options = [
  { value: '', label: ' ' },
  { value: 'unknown', label: 'Unknown' },
  { value: 'normal', label: 'Normal' },
  { value: 'hypermethylated', label: 'Hypermethylated' },
]

class SkipLogicUterineForm extends Component {

  constructor(props) {
    super(props)

    let inputs = cloneDeep(keys_mapping);
    for(var key in inputs) {
      inputs[key] = null
    }

    this.state = {
      ...inputs,
      errorMessages: []
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.skip_logics.length != this.props.skip_logics.length) {

      for (var key in keys_mapping) {

        var val = this.getValue(key);
        if (val === null) continue;

        if (key.includes("age")) {
          if (!isNaN(parseInt(val, radix_bases.base10))) {
            await this.setState({ [key]: val, [key.replace("_age", "")]: "y" });
          }
        } else {
          await this.setState({ [key]: val });
        }
      }

      // Reconstruct payload
      var skipLogicPayload = this.buildSkipLogicPayload();
      this.props.onUpdate(this.state, skipLogicPayload, true);

    }
  }

  getValue(key) {
    if(!(key in keys_mapping)) return null

    let code = keys_mapping[key]
    let data = this.props.skip_logics.find(item => item.skip_logic_code == code)

    if(typeof(data) === 'undefined') return null
    return data.answer
  }

  /*
  [
    {skip_logic_id, answer}
  ]
  */
  buildSkipLogicPayload() {
    let inputs = this.state;
    let mapping = keys_mapping;
    let skipLogicPayloads = []
    for(var key in mapping) {
      var static_skip_logic_item = this.props.static_skip_logic.find(item => item.skip_logic_code == mapping[key])
      if(typeof(static_skip_logic_item) === 'undefined') {
        throw new Error('Skip Logic code not found');
      }

      var input = inputs[key]
      var answer = input !== null && typeof(input) === 'object' ? input.value : input
      if(answer !== null && answer.trim() !== '') {
        skipLogicPayloads.push({
          skip_logic_code: static_skip_logic_item.skip_logic_code,
          skip_logic_id: static_skip_logic_item.id,
          answer
        })
      }
    }

    return skipLogicPayloads;
  }

  async onChange(data){
    try {
      this.setState({errorMessages: []})
      await this.setState({...this.state, ...data});

      var skipLogicPayload = this.buildSkipLogicPayload();
      this.props.onUpdate(this.state, skipLogicPayload);
    } catch (error) {
      this.setState({errorMessages: [error.message]})
    }
  }

  render() {
    return (
      <div className="panel-form-2 sl-content uterine">

        <ErrorSummary errorMessages={this.state.errorMessages} />

        <div className="row">

          <div className="col-md-3">
            <div className="form-group">
              <label>MSI</label>
              <div className="custom-select">
                  <SelectDefault
                    name="msi"
                    options={msi_options}
                    value={this.state.msi}
                    onChange={(msi) => this.onChange({ msi })}
                  />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>MLH1 IHC</label>
              <div className="custom-select">
                  <SelectDefault
                    name="mlh1_ihc"
                    options={normal_abnormal_options}
                    value={this.state.mlh1_ihc}
                    onChange={(mlh1_ihc) => this.onChange({ mlh1_ihc })}
                  />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>MSH2 IHC</label>
              <div className="custom-select">
                  <SelectDefault
                    name="msh2_ihc"
                    options={normal_abnormal_options}
                    value={this.state.msh2_ihc}
                    onChange={(msh2_ihc) => this.onChange({ msh2_ihc })}
                  />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label>MSH6 IHC</label>
              <div className="custom-select">
                  <SelectDefault
                    name="msh6"
                    options={normal_abnormal_options}
                    value={this.state.msh6}
                    onChange={(msh6) => this.onChange({ msh6 })}
                  />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>PMS2 IHC</label>
              <div className="custom-select">
                  <SelectDefault
                    name="pms2"
                    options={normal_abnormal_options}
                    value={this.state.pms2}
                    onChange={(pms2) => this.onChange({ pms2 })}
                  />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>MLH1 Hypermethylation</label>
              <div className="custom-select">
                  <SelectDefault
                    name="mhl1_hyper"
                    options={normal_hyper_options}
                    value={this.state.mhl1_hyper}
                    onChange={(mhl1_hyper) => this.onChange({ mhl1_hyper })}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default SkipLogicUterineForm