import React, { Component } from 'react';
import InputCancer from './input-cancer';
import InputAndOr from './input-andor';
import { fromJS, remove, updateIn } from 'immutable'
import lodash from 'lodash'
import { createUUID } from '../react-flow-pedigree/utils';

let filterItem = { andOr: 'and', cancerType: '', relationalOperator: 'any_age', age: '' }
// let filterSet = [filterItem]
let filterSet = {andOr: 'and', items: [filterItem]}
/*
| - - - - - - - - - - - - - - -
| Data Structure
| - - - - - - - - - - - - - - -

  filterSets = [
    {
      andOr: 'and',
      items: [
        { andOr: '', cancerType: '', relationalOperator: '', age: '' },
        { andOr: '', cancerType: '', relationalOperator: '', age: '' }
      ],
    },
    {
      andOr: 'and',
      items: [
        { andOr: '', cancerType: '', relationalOperator: '', age: '' }
      ],
    },
  ]

| - - - - - - - - - - - - - - -
*/
class FilterCancer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterSets: [],
      includeUMLS: false,
      checkBoxDisabled: false
    }
    this.handleOpenClick = this.handleOpenClick.bind(this)
    this.handleAddSet = this.handleAddSet.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.onChangeCheckBox = this.onChangeCheckBox.bind(this)
    this.checkUMLSCheckboxDisable = this.checkUMLSCheckboxDisable.bind(this);
    this.umlsChecked = this.umlsChecked.bind(this);
    this.loadReportSetFilters = this.loadReportSetFilters.bind(this)
  }

  loadReportSetFilters(filterSets){
    if(filterSets.disease_filters.length === 0){
      let clean = [filterSet]
      this.setState({ filterSets: clean })
    }
    else{
      let newFilterSets = filterSets.disease_filters;
      this.setState({ filterSets: newFilterSets })
    }
  }

  isActive() {
    let clean = [filterSet]
    let filterSets = this.state.filterSets
    return lodash.isEqual(clean, filterSets) === false;
  }

  componentDidMount() {
    this.handleAddSet()
  }

  handleOpenClick() {
    if (this.props.open) {
      this.props.onOpen('')
      this.props.handleIsActiveChange('disease', this.isActive());
      this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets));
      this.props.changeDiseaseFilters(lodash.cloneDeep(this.state.filterSets), this.state.includeUMLS ,this.isActive())
      this.props.handleIncludeUMLS(this.state.includeUMLS)

    } else {
      this.props.onOpen('cancer')
      this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets));
      this.props.handleIncludeUMLS(this.state.includeUMLS)
    }
  }

  handleAddSet() {
    let filterSets = this.state.filterSets
    let newFilterSet = Object.assign({}, filterSet)
    filterSets.push(newFilterSet)
    this.setState({filterSets: filterSets}, () => this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets)))
  }

  handleAddItem(seletedSetIndex) {
    let filterSets = this.state.filterSets
    filterSets = fromJS(filterSets)
    filterSets = updateIn(filterSets, [seletedSetIndex, 'items'], items => items.push(filterItem))
    filterSets = filterSets.toJS()
    this.setState({filterSets }, () => this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets)))
  }

  handleDeleteItem(setIndex, itemIndex) {
    let filterSets = this.state.filterSets
    filterSets = fromJS(filterSets)

    // Remove item from set
    filterSets = updateIn(filterSets, [setIndex, 'items'], items => remove(items, itemIndex))

    // Remove empty set
    if(filterSets.get(setIndex).get('items').size == 0) filterSets = remove(filterSets, setIndex)

    filterSets = filterSets.toJS()
    this.setState({filterSets }, () => this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets)))

    if(setIndex === 0 && itemIndex === 0 && filterSets.length === 0) {
      this.handleFilterChange(setIndex, itemIndex, filterItem)
    }
  }

  onChangeCheckBox(event) {
    const name = event.target.name
    const checked = event.target.checked
    this.setState({[name]: checked}, () => {this.props.handleIncludeUMLS(checked)})
  }

  handleFilterChange(setIndex, itemIndex, item) {
    let filterSets = this.state.filterSets
    filterSets = fromJS(filterSets)
    filterSets = updateIn(filterSets, [setIndex, 'items'], items => updateIn(items, [itemIndex], _ => item))
    filterSets = filterSets.toJS()
    this.setState({filterSets: filterSets }, () => this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets)))
    this.props.handleIncludeUMLS(this.state.includeUMLS)
  }

  handleChangeSet(andOr, setIndex) {
    let filterSets = this.state.filterSets
    filterSets = fromJS(filterSets)
    filterSets = updateIn(filterSets, [setIndex, 'andOr'], _ => andOr)
    filterSets = filterSets.toJS()
    this.setState({filterSets: filterSets }, () => this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets)))
    this.props.handleIncludeUMLS(this.state.includeUMLS)
  }

  isDiseaseFromUmls(disease_id) {
    disease_id = disease_id + "";
    const is_from_umls = disease_id.substr(0,1) === "C";
    return is_from_umls
  }

  checkUMLSCheckboxDisable(){
    let umls_disabled_options = ['any_other','no_other','no_UMLS','any_UMLS', 'any_disease', 'no_disease', 'any_other_conditions', 'no_other_conditions']
    let disable = false
    this.state.filterSets.forEach(item=>{
      item.items.forEach(type=>{
        if (this.isDiseaseFromUmls(type.cancerType) || umls_disabled_options.indexOf(type.cancerType) >= 0){
          disable = true
        }
      })
    })
    return disable
  }
  umlsChecked(){
    let umls_checked = ['any_UMLS', 'no_UMLS']
    let checked = false
    this.state.filterSets.forEach(item=>{
      item.items.forEach(type=>{
        if (this.isDiseaseFromUmls(type.cancerType) || umls_checked.indexOf(type.cancerType) >= 0 || this.state.includeUMLS){
          checked = true
        }
      })
    })
    return checked
  }


  render() {
    let btnClass = this.isActive() ? 'btn-purple' : 'btn-light-outline';
    let disable_umls = this.checkUMLSCheckboxDisable()
    let umlsChecked = this.umlsChecked()
    let showDeleteButtonOnSetOne = false;

    if(this.state.filterSets.length === 1 && this.isActive()){
      showDeleteButtonOnSetOne = true;
    }

    return (
      <React.Fragment >
        <div className="popover-query-wrapper">

          <button
            onClick={this.handleOpenClick}
            className={"btn btn-xs " + btnClass} >Disease</button>

          <div id="cancerPop"
            style={{ display: (this.props.open ? 'block' : 'none') }}
            className="popover popover-wide popover-x popover-default popover-custom kv-popover-active in bottom popover-query">

            <div className="arrow popover-query__arrow"></div>

            <h5 className="popover-header popover-title">
              <span
                onClick={this.handleOpenClick}
                className="close pull-right">&times;
              </span>
              Disease Filter
            </h5>

            <div className="popover-body popover-content">

              {this.state.filterSets.map((set, setIndex) => {
                return (<FilterSet
                        key={createUUID()}
                        set={set}
                        totalSets={this.state.filterSets.length}
                        setIndex={setIndex}
                        showDeleteButtonOnSetOne={showDeleteButtonOnSetOne}
                        onChangeSet={(andOr) => this.handleChangeSet(andOr, setIndex)}
                        onChangeItem={(item, itemIndex) => this.handleFilterChange(setIndex, itemIndex, item)}
                        onAddItemClick={() => this.handleAddItem(setIndex)}
                        onDeleteItemClick={(itemIndex) => this.handleDeleteItem(setIndex, itemIndex)}
                      />)
              })}

              <div className="margin-two">
                <a onClick={this.handleAddSet} className="btn btn-teal btn-xs">Add Set</a>
                  <div className="checkbox-query">
                    <label className="include-UMLS">
                      <input
                        key={createUUID()}
                        type="checkbox"
                        disabled={disable_umls}
                        id="includeUMLS"
                        name="includeUMLS"
                        onChange={this.onChangeCheckBox}
                        checked={umlsChecked}
                        className="include-umls-checkbox" />
                        <span className='includeUMLS-title' >Include UMLS conditions</span>
                    </label>
                  </div>
                  <span className='i-tooltip-query'>
                    <a className='column-query min-width--inherit'>
                      <i className="fa fa-info-circle" aria-hidden="true"></i>
                    </a>
                    <span className="i-tooltiptext-query">
                      Include any conditions from UMLS that match the disease you selected.
                      This will also include sub-types of the selected disease.
                    </span>
                  </span>
              </div>

            </div>

          </div>
        </div>
      </React.Fragment>
    )
  }
}

/*
Functional Components
*/

const FilterSet = (props) => {
  return (
    <React.Fragment>

      {props.setIndex > 0 &&
        <div className="row margin-two">
          <InputAndOr
            value={props.set.andOr}
            onChange={(e) => props.onChangeSet(e.target.value) }
          />
        </div>
      }

      <div className="panel-form-3">

        {props.set.items.map((item, itemIndex) => {
          const showDeleteButton = () => {
            if(itemIndex === 0 && props.setIndex === 0 && props.totalSets === 1){
              return props.showDeleteButtonOnSetOne;
            }
            else{
              return props.set.items.length > 1 || props.totalSets > 1
            }
          }

          return (
            <div key={createUUID()} className={"row margin-one " + (itemIndex == 0 ? 'padding-left--15' : '')}>

              <InputCancer
                key={createUUID()}
                item={item}
                itemIndex={itemIndex}
                setIndex={props.setIndex}
                onAddItemClick={() => props.onAddItemClick()}
                onDeleteItemClick={() => props.onDeleteItemClick(itemIndex)}
                onChange={(data) => props.onChangeItem(data, itemIndex)}
                showDeleteButton={showDeleteButton()}
                showAddButton={itemIndex == props.set.items.length - 1}
              />

            </div>
          )

        })}

      </div>

    </React.Fragment>
  )
}

/* Functional Components */

export default FilterCancer;
