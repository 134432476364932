import React, { Component } from 'react';
import { connect } from "react-redux";
import helpers from '../helpers/index';
import NavTop from '../components/nav-top';
import ResultList from '../components/query/result-list';
import ControlSelectDiseases from '../components/control-select-diseases';
import * as helper_search from "../helpers/helper-search";
import userAccountApi from '../api/user-account-api';
import * as action_auth from '../store/session/actions';
import DOMPurify from 'dompurify';

class QueryPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      queryProbands: () => {},
      release_notifications: []
    };

    this.openChangelogInNewTab = this.openChangelogInNewTab.bind(this);
    this.closeReleaseNotificationModal = this.closeReleaseNotificationModal.bind(this);
    this.setReleaseNotifications = this.setReleaseNotifications.bind(this);
    this.setShowAdvancedFilters = this.setShowAdvancedFilters.bind(this);

    this.advancedFiltersRef = React.createRef();
    this.resultListRef = React.createRef();
    this.buttonRef = React.createRef();
  }

  componentDidMount() {
    helpers.setSiteTitle('Query');
    const setReleaseNotifications = this.setReleaseNotifications;
    userAccountApi.get_release_notifications()
    .then((response) => {

      const release_notifications = response.release_notifications;
      setReleaseNotifications(release_notifications);

    }).catch((error) => {
      console.log(error);
    })
  }

  componentWillUnmount() {
    // update redux search with new search text
    helper_search.updateSearchText(this.props.dispatch, "");
  }

  openChangelogInNewTab(url) {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  async closeReleaseNotificationModal() {
    // set the variable for release_notification equal to current version
    let update_version = await userAccountApi.update_release_version();
    this.setReleaseNotifications([]);
  }

  setReleaseNotifications(notifications) {
    this.setState({release_notifications: notifications});
  }

  setQueryProbands(queryProbands){
    this.setState({queryProbands: queryProbands})
  }

  refreshList = () => {
    this.state.queryProbands();
  }

  setShowAdvancedFilters(show_advanced_filters){
    this.resultListRef.current.setShowAdvancedFilters(show_advanced_filters);
  }

  render() {
    let release_notification_modal = null;
    if (this.state.release_notifications.length > 0) {
      let rn = this.state.release_notifications[0];

      var clean_title = DOMPurify.sanitize(rn.title);
      var clean_body = DOMPurify.sanitize(rn.body);

      const title = (<div dangerouslySetInnerHTML={{ __html: clean_title }} />);
      const body = (<div dangerouslySetInnerHTML={{ __html: clean_body }} />);

      release_notification_modal = (
        <div
          style={{ display: 'block', paddingLeft: '12px' }}
          className="modal fade in"
          role="dialog"
          aria-labelledby="release-notification"
          id="release-notification"
        >
          <div className="modal-dialog release-modal" role="document">
            <div className="modal-header">
              <button onClick={() => this.closeReleaseNotificationModal()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Update Notification</h4>
            </div>
            <div className="modal-body">
              {body}
            </div>
            <div className="modal-footer modal-background">
              <button
                onClick={() => this.closeReleaseNotificationModal()}
                type="button" className="btn btn-light no-margin-right" data-dismiss="modal">Close</button>
              <button
                onClick={() => this.openChangelogInNewTab('https://famgenix.com/changelog/')}
                type="button" className="btn btn-dark">View Changelog</button>
            </div>
          </div>
        </div>

      );
    }

    return (
      <React.Fragment>
        <NavTop
          setShowAdvancedFilters={this.setShowAdvancedFilters}
          history={this.props.history}
          refreshList={() => this.refreshList()}
          page='search-results'
        />

        <section className="wow fadeIn section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">

                {release_notification_modal}

                <ResultList
                  ref={this.resultListRef}
                  setQueryProbands={(queryProbands) => this.setQueryProbands(queryProbands)}
                  history={this.props.history}
                  location={this.props.location}
                />

              </div>
            </div>
          </div>
        </section>

      </React.Fragment>
    );
  }
}

const redux_actions = dispatch => ({
  dispatch: action => dispatch(action)
});

export default connect(
  null,
  redux_actions
)(QueryPage);
