import React, { Component } from 'react';
import SelectPositiveNegative from './control-select-postive-negative'
import RadioYesNo from './control-radio-yes-no'
import ErrorSummary from './error-summary'
import { cloneDeep } from 'lodash'
import { radix_bases } from '../helpers/helper-number-bases';

import skip_logic_map from '../assets/json/diseases-skip-logic-map.json';

// const keys_mapping = {
//   more_than_ten: 'SL013',
//   hamartamatous: 'SL014',
// }

const keys_mapping = skip_logic_map.colorectal_polyps;

class SkipLogicColorectalPolypsForm extends Component {

  constructor(props) {
    super(props)
    let inputs = cloneDeep(keys_mapping);
    for(var key in inputs) {
      inputs[key] = null
    }

    this.state = {
      ...inputs,
      errorMessages: []
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.skip_logics.length != this.props.skip_logics.length) {

      for (var key in keys_mapping) {

        var val = this.getValue(key);
        if (val === null) continue;

        if (key.includes("age")) {
          if (!isNaN(parseInt(val, radix_bases.base10))) {
            await this.setState({ [key]: val, [key.replace("_age", "")]: "y" });
          }
        } else {
          await this.setState({ [key]: val });
        }
      }

      // Reconstruct payload
      var skipLogicPayload = this.buildSkipLogicPayload();
      this.props.onUpdate(this.state, skipLogicPayload, true);

    }
  }

  getValue(key) {
    if(!(key in keys_mapping)) return null

    let code = keys_mapping[key]
    let data = this.props.skip_logics.find(item => item.skip_logic_code == code)

    if(typeof(data) === 'undefined') return null
    return data.answer
  }

  /*
  [
    {skip_logic_id, answer}
  ]
  */
  buildSkipLogicPayload() {
    let inputs = this.state;
    let mapping = keys_mapping;
    let skipLogicPayloads = []
    for(var key in mapping) {
      var static_skip_logic_item = this.props.static_skip_logic.find(item => item.skip_logic_code == mapping[key])
      if(typeof(static_skip_logic_item) === 'undefined') {
        throw new Error('Skip Logic code not found');
      }

      var input = inputs[key]
      var answer = input !== null && typeof(input) === 'object' ? input.value : input
      if(answer !== null && answer.trim() !== '') {
        skipLogicPayloads.push({
          skip_logic_code: static_skip_logic_item.skip_logic_code,
          skip_logic_id: static_skip_logic_item.id,
          answer
        })
      }
    }

    return skipLogicPayloads;
  }

  async onChange(data) {
    // await this.setState({ ...this.state, ...data })
    // this.props.onUpdate(this.state)
    try {
      this.setState({errorMessages: []})
      await this.setState({...this.state, ...data});

      var skipLogicPayload = this.buildSkipLogicPayload();
      this.props.onUpdate(this.state, skipLogicPayload);
    } catch (error) {
      this.setState({errorMessages: [error.message]})
    }
  }

  render() {
    return (
      <div className="panel-form-2 sl-content colorectal-polyps">
        <ErrorSummary errorMessages={this.state.errorMessages} />
        <div className="form-horizontal">

							<div className="form-group">
								<label className="control-label col-md-2 col-sm-3 col-xs-12">More than 10?</label>
								<div className="col-md-3 col-sm-9 col-xs-12">


                  <RadioYesNo
                    name="more_than_ten"
                    value={this.state.more_than_ten}
                    onChange={(more_than_ten) => this.onChange({more_than_ten})}
                  />


								</div>
							</div>

							<div className="form-group">
								<label className="control-label col-md-2 col-sm-3 col-xs-12">Hamartamatous?</label>
								<div className="col-md-3 col-sm-9 col-xs-12">

                  <RadioYesNo
                    name="hamartamatous"
                    value={this.state.hamartamatous}
                    onChange={(hamartamatous) => this.onChange({hamartamatous})}
                  />

								</div>
							</div>


					</div>
				</div>
    )
  }

}

export default SkipLogicColorectalPolypsForm