import React, { Component } from 'react';

import familyApi from '../../api/family-api';

class RelationshipDropdownMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consanguineous: false,
            relationship_data: ''
        };

        this.onOptionClick = this.onOptionClick.bind(this)
    }

    componentDidMount(){
        let relationship_rkey = this.props.data.id.slice(2).slice(0, -2)

        //need to get the actual relationship_data
        let people = Object.values(this.props.data.getPedigreeData().profiles)
        let peopleWithRelationshipRkey = people.filter(person => person.relationship_ids.find(relationship => relationship.rkey == relationship_rkey))
        let relationship_data = JSON.parse(JSON.stringify(peopleWithRelationshipRkey[0].relationship_ids.find(relationship => relationship.rkey == relationship_rkey)))
        
        this.setState({ relationship_data })
    }

    async onOptionClick(option){
        let relationship_data = this.state.relationship_data;

        if(option !== 'consanguineous'){
            relationship_data.marital_status = option;
        }
        else{
            relationship_data.is_parent_blood_related = !relationship_data.is_parent_blood_related;
        }
        await familyApi.patch_partner_relationship_partnerid(relationship_data.id, relationship_data)

        //update the relationship_data on pedigree data store
        let relationship_rkey = relationship_data.rkey
        let people = Object.values(this.props.data.getPedigreeData().profiles)
        let peopleWithRelationshipRkey = people.filter(person => person.relationship_ids.find(relationship => relationship.rkey == relationship_rkey))

        console.log(peopleWithRelationshipRkey)
        for(let person of peopleWithRelationshipRkey){
            person.relationship_data = JSON.parse(JSON.stringify(relationship_data))
            let relationship_ids = JSON.parse(JSON.stringify(person.relationship_ids))

            for(let relation of relationship_ids){
                if(relation.rkey === relationship_rkey){
                    if(option !== 'consanguineous'){
                        relation.marital_status = relationship_data.marital_status
                    }
                    else{
                        relation.is_parent_blood_related = relationship_data.is_parent_blood_related;
                        // if(!person.is_proband){
                        //     if(relationship_data.is_parent_blood_related === true){
                        //         person.is_blood_related_to_proband = true
                        //     }
                        //     else{
    
                        //     }
                        //     await familyApi.patch_member_memberid(person.id, person)
                        // }
                    }
                }
            }

            person.relationship_ids = JSON.parse(JSON.stringify(relationship_ids))

            this.props.data.getPedigreeData().setProfile(person.rkey, person)
        }

        console.log(this.props.data.getPedigreeData())

        await this.props.data.reRenderPedigree();
    }

    render() {
        const show_dropdown = this.props.showDropdown ? 'open' : '';
        
        const dropdown_menu_styles = {
            fontFamily: 'sans-serif',
            fontSize: 12,
            backgroundColor: '#ffffff'
        };

        const dropdown_label = Object.assign({}, dropdown_menu_styles, { fontWeight: 'bold', textAlign: 'justify', padding: '3px 20px', whiteSpace: 'nowrap' });

        return (
            <div className={"dropdown " + show_dropdown} style={{ left: '5px' }}>

                <ul className="dropdown-menu" aria-labelledby="dropdown_children_dom">
                    <li style={dropdown_label}>Edit Relationship</li>

                    <li onClick={() => this.onOptionClick('married')} style={dropdown_menu_styles}>
                        <a>Married</a>
                    </li>

                    <li onClick={() => this.onOptionClick('separated')} style={dropdown_menu_styles}>
                        <a>Separated</a>
                    </li>

                    <li onClick={() => this.onOptionClick('divorced')} style={dropdown_menu_styles}>
                        <a>Divorced</a>
                    </li>

                    <li onClick={() => this.onOptionClick('other')} style={dropdown_menu_styles}>
                        <a>Other</a>
                    </li>

                    <li onClick={() => this.onOptionClick('consanguineous')} style={dropdown_menu_styles}>
                        <a>{!this.state.relationship_data.is_parent_blood_related ? 'Consanguineous' : 'Non-Consanguineous'}</a>
                    </li>
                </ul>

            </div>)
    }
}

export default RelationshipDropdownMenu;
