import React, { Component } from "react";
import ReactDOM from "react-dom";

class ModalError extends Component {
  render() {
    return ReactDOM.createPortal(
      <div
        onClick={() => this.props.onClose()}
        style={{ zIndex: 9999, display: "block" }}
        className="modal fade in"
        role="dialog"
      >
        <div
          onClick={e => e.stopPropagation()}
          className="modal-dialog modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={() => this.props.onClose()}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-close" />
              </button>
              <h4 className="modal-title text-white text-capitalize">{"Oops!"}</h4>
            </div>

            <div className="modal-body">
              <div className="modal-confirm">

                { this.props.message ? this.props.message : <div>Something went wrong. <br/>Please try again later.</div> }

                <br />
                <div className="buttons">
                  <button
                    onClick={() => this.props.onClose()}
                    className="btn btn-light btn-sm"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }
}

export default ModalError;
