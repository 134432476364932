import React, { Component } from 'react';
import helper_sidebar from '../../../helpers/helper-family-history-sidebar';
import PatientDiseaseView from '../../patient-disease-view';
import PatientGeneTestView from '../../patient-gene-test-view';
import MemberPersonalInfo from './member-personal-info';
import NccnCriteria from './nccn-criteria';
import NccnCriteriaText from './nccn-criteria-text';
import { createUUID } from '../../react-flow-pedigree/utils';

class FamilyHistorySidebar extends Component {

  constructor(props) {
    super(props);
    this.disableEditForReadOnly = this.disableEditForReadOnly.bind(this);
  }

  getProfile() {
    if (this.props.person) {
      return helper_sidebar.getProfile(this.props.patient, this.props.person)
    }
    return null
  }

  getHistory() {
    if (this.props.person) {
      return helper_sidebar.getHistory(this.props.patient, this.props.person)
    }
    return null
  }

  handlePersonalInfoSave(data) {
    this.props.onInfoAndHistoryUpdate(data);
  }

  disableEditForReadOnly(){
    if(this.props.readOnlyUser && this.props.readOnlyUser == true){
      return false
    }
    return true
  }

  render() {

    let sidebar_active = this.props.showSideBar ? 'active' : ''
    let close_button_style = this.props.showSideBar ? { display: 'block' } : { display: 'none' }
    let profile = this.getProfile()
    let proband_last_name = "";
    if(this.props.person && this.props.person.data.profile.is_proband) {
      proband_last_name = " " + profile.last_name;
    }
    let history = this.getHistory()

    let frag0 = null;
    let frag1 = null;
    let frag2 = null;
    let frag3 = null;
    let frag4 = null;
    let frag5 = null;
    let frag6 = null;
    let frag7 = null;
    if(profile && profile.is_proband) {
      let list1 = [];
      let list2 = [];
      let list3 = [];
      let list4 = [];
      let list5 = [];
      let list6 = [];

      let list7 = [];
      let list7_category_text = null;

      let len = this.props.patient.risk_criteria.length;
      for(let i=0; i<len; i++) {
        let risk_factor = this.props.patient.risk_criteria[i];

        if(risk_factor.condition[0] === "Breast/Ovarian/Other") {
          list1.push(<li key={i}>
            {risk_factor.clinician_risk_factors_main_text}
          </li>);
        }

        if(risk_factor.condition[0] === "Breast/Ovarian/Colorectal") {
          list2.push(<li key={i}>
            {risk_factor.clinician_risk_factors_main_text}
          </li>);
        }

        if(risk_factor.condition[0] === "Breast/Ovarian") {
          list3.push(<li key={i}>
            {risk_factor.clinician_risk_factors_main_text}
          </li>);
        }

        if(risk_factor.condition[0] === "Colorectal/Other") {
          list4.push(<li key={i}>
            {risk_factor.clinician_risk_factors_main_text}
          </li>);
        }

        if(risk_factor.condition[0] === "Colorectal") {
          list5.push(<li key={i}>
            {risk_factor.clinician_risk_factors_main_text}
          </li>);
        }

        if(risk_factor.condition[0] === "Other") {
          list6.push(<li key={i}>
            {risk_factor.clinician_risk_factors_main_text}
          </li>);
        }

        if(risk_factor.condition[0] === "Gene") {
          list7_category_text = risk_factor.condition[1];
          list7.push(<li key={i}>
            {risk_factor.clinician_risk_factors_main_text}
          </li>);
        }
      }

      if(len === 0) {
        frag0 = <React.Fragment><p>Assessed criteria not met</p></React.Fragment>
      }

      if(list1.length > 0) {
        frag1 = (<NccnCriteria
                  header={"Breast/Ovarian/Other"}
                  criteria={list1}
                />);
      }

      if(list2.length > 0) {
        frag2 = (<NccnCriteria
                  header={"Breast/Ovarian/Colorectal"}
                  criteria={list2}
                />);
      }

      if(list3.length > 0) {
        frag3 = (<NccnCriteria
                  header={"Breast/Ovarian"}
                  criteria={list3}
                />);
      }

      if(list4.length > 0) {
      frag4 = (<NccnCriteria
                 header={"Colorectal/Other"}
                 criteria={list4}
               />);
      }

      if(list5.length > 0) {
        frag5 = (<NccnCriteria
                   header={"Colorectal"}
                   criteria={list5}
                 />);
      }

      if(list6.length > 0) {
        frag6 = (<NccnCriteria
                   header={"Other"}
                   criteria={list6}
                 />);
      }

      if(list7.length > 0) {
        frag7 = (<NccnCriteria
                  header={list7_category_text}
                  criteria={list7}
                />);
      }
    }
    //sidebar postion none and height 100% are the default when a user is not read only, update these
    //values to adjust so side bar takes up whole page if read only user
    let sidebarPostion = 'none'

    let member_info_component = null;
    if(this.props.readOnlyUser && this.props.readOnlyUser == true){
      sidebarPostion = 'fixed';
      member_info_component = (
        <div className="panel-summary">
          {profile && (
            <React.Fragment>
              <h4>{profile.first_name}{proband_last_name}</h4>
            </React.Fragment>)
          }

          {profile && (
            <React.Fragment>
              <p><span>Age: </span>{profile.age} y/o</p>
              <p><span>Deceased: </span>{profile.is_dead ? 'Yes' : 'No'}</p>
              {profile.is_dead &&
                <p><span>Cause of death: </span>{profile.cause_of_death && profile.cause_of_death.trim() == '' ? 'Unknown' : profile.cause_of_death}</p>
              }
              <p><span>Notes: </span>{profile.note == '' ? 'None' : profile.note}</p>
            </React.Fragment>)
          }

        </div>
      )
    } else {
      /*Using unique key to force component to re-render if props change*/
      if (this.props.person) {
        member_info_component = (
          <MemberPersonalInfo
            key={createUUID()}
            memberid={this.props.memberid}
            person={this.props.person}
            onOk={(data) => this.handlePersonalInfoSave(data)}
            onDelete={() => this.props.onDelete()}
            onDeleteCheck={() => this.props.onDeleteCheck()}
          />
        );
      }
    }

    return (
      <React.Fragment>

        <nav id="sidebar" style={{position: sidebarPostion}} className={sidebar_active}>

          <div className="dismiss" style={close_button_style} onClick={() => this.props.onCloseClick()} >
            <i className="fa fa-close"></i>
          </div>

          <div className="sidebar-header">

            {member_info_component}

            <div className="row">
              <div className="col-md-12">

                {profile && profile.is_proband && <React.Fragment>
                  <hr className="margin-five" />
                  <div className="form-header-2">
                    <div className="row vcenter">
                      <div className="col-lg-12 ">
                        <h3 className="title">Criteria
                          <span className='i-tooltip-right-nccn'>
                            <i className="fa fa-info-circle"></i>
                            <NccnCriteriaText riskCriteriaLength={this.props.patient.risk_criteria.length} />
                          </span>
                        </h3>

                      </div>
                    </div>
                  </div>
                  <div className="criteria">
                    {frag0}
                    {frag1}
                    {frag2}
                    {frag3}
                    {frag4}
                    {frag5}
                    {frag6}
                    {frag7}
                  </div>
                  </React.Fragment>
                }

                <hr className="margin-five" />

                {profile &&
                  <PatientDiseaseView
                    sideBar={true}
                    patientRkey={profile.rkey}
                    profile={profile}
                    onDiseaseUpdate={() => this.props.onDiseaseUpdate()}
                    read_only={this.props.readOnlyUser}
                  />
                }

                <hr className="margin-five" />

                {profile &&
                  <PatientGeneTestView
                    sideBar={true}
                    patientRkey={profile.rkey}
                    profile={profile}
                    onGeneTestUpdate={() => this.props.onGeneTestUpdate()}
                    read_only={this.props.readOnlyUser}
                  />
                }

                <br /><br /><br />


              </div>
            </div>
          </div>
        </nav>
      </React.Fragment>
    )
  }
}

export default FamilyHistorySidebar;
