import Matrix from './matrix'
import NodeLocator from './node-locator'
import NodeConnector from './node-connector'
import People from './people'
import Cookie from 'js-cookie'
import settings from './settings'

class MatrixPeople {

  family_tree = {}
  family_nodes = {}
  offset = { row: 0, column: 0 };
  rendered_nodes = {};
  nodeLocations = [];

  constructor(paper, family_tree = {}, options = {}) {

    this.family_tree = family_tree;

    this.matrix = new Matrix();

    this.people = new People(paper, options, this.family_tree);

    this.node_connector = new NodeConnector(paper, this.matrix, options.nodeLocations, family_tree);

    this.node_locator = new NodeLocator(family_tree);

    this.offset = { row: 0, column: 0 };

    this.nodeLocations = options.nodeLocations
  }

  getContentSize() {
    return {
      height: this.matrix.getHeight(),
      width: this.matrix.getWidth()
    }
  }

  clearAll(){
    return this.people.clearAll();
  }

  clearMenuWhenOutside(event, dragging) {
    return this.people.clearMenuWhenOutside(event.point, dragging)
  }

  clearMenuButton(){
    this.people.clearMenuButton();
  }

  isInside(event) {
    return this.people.isInside(event.point)
  }

  updatePersonProfile(node, profile) {
    this.people.updatePersonProfile(node, profile)
  }

  updatePersonHealth(node, health) {
    this.people.updatePersonHealth(node, health)
  }

  refreshNode(node) {
    this.people.refreshNode(node)
  }

  deleteNode(node) {
    this.people.deleteNode(node)
  }

  toggleViewStatus(value) {
    for (var person of this.people.persons) {
      if ('labels' in person && person.labels.length > 0) {
        localStorage.setItem('deidentified', value === 'deidentified');
      }
    }
  }

  toggleLegend(showLegend, showNotes) {
    let { history_diseases } = this.family_tree;
    let pedigree_notes = this.family_tree.family_pedigree_notes.pedigree_notes[0]    
    this.people.renderLegend(showLegend, history_diseases, showNotes, pedigree_notes);
  }

  ascendingFunc( a, b ) {
    if ( a.level < b.level ){
      return -1;
    }
    if ( a.level > b.level ){
      return 1;
    }
    return 0;
  }

  toggleAncestry(showAncestry) {
    let mother_node = this.people.persons.filter(person => person.data.profile.id === this.family_tree.patient.mother.id)
    let father_node = this.people.persons.filter(person => person.data.profile.id === this.family_tree.patient.father.id)
    let parent_related_persons = Object.values(this.family_tree.profile).filter(person => person.level <= this.family_tree.patient.mother.level)
    parent_related_persons = parent_related_persons.filter(person => person.is_blood_related_to_proband === true)
    let parent_related_nodes = []
    for (let related_person of parent_related_persons) {
      parent_related_nodes.push(this.people.persons.find(person => person.data.profile.id === related_person.id))
    }

    let most_top_y = Object.values(this.family_tree.profile).filter(person => person.level <= 1)
    most_top_y = most_top_y.sort(this.ascendingFunc)
    most_top_y = this.people.persons.find(person => person.data.profile.id === most_top_y[0].id)
    most_top_y = most_top_y.highlight.position.y;

    let most_right_related_node = mother_node[0]
    let most_left_related_node = father_node[0]
    for (let i = 0; i < parent_related_nodes.length; i++) {
      if(parent_related_nodes[i]){
        if (parent_related_nodes[i].highlight.position.x < most_left_related_node.highlight.position.x) {
          most_left_related_node = parent_related_nodes[i]
        }
        else if (parent_related_nodes[i].highlight.position.x > most_right_related_node.highlight.position.x) {
          most_right_related_node = parent_related_nodes[i]
        }
      }
    }

    //mother details
    let maternal_ancestry = this.family_tree.patient.mother.ancestry
    let mother_node_position = mother_node[0].highlight.position
    let most_right_related_node_position = most_right_related_node.highlight.position

    //father details
    let paternal_ancestry = this.family_tree.patient.father.ancestry
    let father_node_position = father_node[0].highlight.position
    let most_left_related_node_position = most_left_related_node.highlight.position

    this.people.renderAncestry(
      showAncestry,
      maternal_ancestry,
      paternal_ancestry,
      mother_node_position,
      father_node_position,
      most_right_related_node_position,
      most_left_related_node_position,
      most_top_y
    )
  }

  renderChart() {
    this.node_locator.build()

    this.family_nodes = this.node_locator.getFamilyNodes();
    this.offset = this.node_locator.getOffset();
    let max_cols = this.node_locator.getColumnCount()
    let max_rows = this.node_locator.getRowCount()

    max_rows += this.offset.row;
    max_cols += this.offset.column;

    this.matrix.build(max_cols, max_rows, this.offset);

    this.renderNodes();

    //comment out hooks when using new smartdraw
    if(Cookie.get("famgenix_active_smartdraw") != 'flux'){
      this.renderHooks();
    }
    else{
      this.node_connector.renderHooksFlux()
    }
  }

  clearChart() {
    this.people.clear();
  }

  /*
  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  Render Nodes
  */

  renderNodes() {

    let { node_tree } = this.family_nodes;
    let { family_nodes } = node_tree;
    for (let rkey in family_nodes) {
      let node = family_nodes[rkey];
      let { data, pos, node_type } = node;
      let { column, row } = pos;
      let currentSlot = this.matrix.getSlotAt(row, column);
      let { gender } = data.profile;
      this.people.drawPersonAt(currentSlot, {
        gender: gender,
        data: data,
        node_type: node_type
      });
      this.rendered_nodes[rkey] = true;
      this.renderPartners(node);
    }
  }

  renderHooks() {
    let { intersections } = this.family_nodes.node_tree;
    for(let pos of intersections) {
      let { row, column } = pos;
      let currentSlot = this.matrix.getSlotAt(row, column);
      this.node_connector.drawHook(currentSlot);
    }
  }

  sortPartners = (partners) => {

    // Segregate partners by direction
    let left_partners = partners.filter(partner => partner.pos.direction === -1);
    let right_partners = partners.filter(partner => partner.pos.direction === 1);

    // Sort Partners
    let left_partners_sorted = left_partners.sort((a, b) => {
      if (a.pos.column < b.pos.column) return 1;
      if (a.pos.column > b.pos.column) return -1;
      return 0;
    });

    let right_partners_sorted = right_partners.sort((a, b) => {
      if (a.pos.column > b.pos.column) return 1;
      if (a.pos.column < b.pos.column) return -1;
      return 0;
    });

    // Index the Partners
    left_partners = left_partners_sorted.map((partner, index) => ({ ...partner, index }));
    right_partners = right_partners_sorted.map((partner, index) => ({ ...partner, index }));

    return [...left_partners, ...right_partners];
  }

  sortChildrenFlux(children){
    for(let child of children){
      child.loc_flux = this.nodeLocations.find((node) => node.id === child.data.profile.id).position
    }

    children = children.sort(function (a, b) {
      return a.loc_flux.x - b.loc_flux.x;
    });

    return children
  }

  renderPartners(person, option = {}) {
    if (person.partners.length == 0) return

    let partners = person.partners;
    let partners_sorted = this.sortPartners(partners);

    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      let partners_on_right = [];
      let partners_on_left = [];
      let person_person = this.people.persons.find(p => p.data.profile.id === person.data.profile.id)
      for (let partner of partners_sorted) {

        // Get position in matrix
        let { column, row } = partner.pos;
        let currentSlot = this.matrix.getSlotAt(row, column);

        // Draw
        let gender = ''
        if(person.data.profile.gender === null || person.data.profile.gender === 'u'){
          gender = 'u'
        } else {
          gender = (person.data.profile.gender.toLowerCase() == 'm') ? 'f' : 'm';
        }
        this.people.drawPersonAt(currentSlot, {
          gender,
          bloodRelative: false,
          data: partner.data,
          node_type: partner.node_type
        });

        let partner_person = this.people.persons.find(p => p.data.profile.id === partner.data.profile.id)
        var direction = 'to_right'
        if(partner_person.loc.x > person_person.loc.x){
          direction = 'to_right'
          partner.loc = partner_person.loc
          partner.new_direction = direction
          partners_on_right.push(partner_person)
        }
        else{
          direction = 'to_left'
          partner.loc = partner_person.loc
          partner.new_direction = direction
          partners_on_left.push(partner_person)
        }

        // Draw Sibling Horizontal Line
        partner.children = this.sortChildren(partner.children);
        this.node_connector.drawSiblingHorizontalLine(partner, direction);

        this.renderChildren(partner, option);

        // Draw siblig lines
        partner.children = this.sortChildrenFlux(partner.children)
        this.node_connector.drawTwinLine(partner.children, partner, direction);
      }


      let partners_on_right_sorted = partners_sorted.filter(element => partners_on_right.find(el => el.data.profile.id === element.data.profile.id))

      let partners_on_left_sorted = partners_sorted.filter(element => partners_on_left.find(el => el.data.profile.id === element.data.profile.id))

      partners_on_right_sorted = partners_on_right_sorted.sort(function (a, b) {
        return a.loc.x - b.loc.x;
      });

      partners_on_left_sorted = partners_on_left_sorted.sort(function (a, b) {
        return b.loc.x - a.loc.x;
      });

      for(let partner of partners_on_right_sorted){
        let consanguineous = partner.data.profile.relationship_data ? partner.data.profile.relationship_data.is_parent_blood_related : true;
        let marital_status = partner.data.profile.relationship_data ? partner.data.profile.relationship_data.marital_status : 'married';
        this.node_connector.drawPartnerLine(person, partner, partner.new_direction, partner.index, consanguineous, marital_status, partners_on_right);
      }

      for(let partner of partners_on_left_sorted){
        let consanguineous = partner.data.profile.relationship_data ? partner.data.profile.relationship_data.is_parent_blood_related : true;
        let marital_status = partner.data.profile.relationship_data ? partner.data.profile.relationship_data.marital_status : 'married';
        this.node_connector.drawPartnerLine(person, partner, partner.new_direction, partner.index, consanguineous, marital_status, partners_on_left);
      }
    }
    else {
      for (let partner of partners_sorted) {
        var direction = partner.pos.direction === 1 ? 'to_right' : 'to_left';
        let consanguineous = partner.data.profile.relationship_data.is_parent_blood_related;
        let marital_status = partner.data.profile.relationship_data.marital_status;
        this.node_connector.drawPartnerLine(person, partner, direction, partner.index, consanguineous, marital_status);

        // Get position in matrix
        let { column, row } = partner.pos;
        let currentSlot = this.matrix.getSlotAt(row, column);

        // Draw
        let gender = ''
        if(person.data.profile.gender === null || person.data.profile.gender === 'u'){
          gender = 'u'
        } else {
          gender = (person.data.profile.gender.toLowerCase() == 'm') ? 'f' : 'm';
        }
        this.people.drawPersonAt(currentSlot, {
          gender,
          bloodRelative: false,
          data: partner.data,
          node_type: partner.node_type
        });

        // Draw Sibling Horizontal Line
        partner.children = this.sortChildren(partner.children);
        this.node_connector.drawSiblingHorizontalLine(partner, direction);

        this.renderChildren(partner, option);

        // Draw siblig lines
        this.node_connector.drawTwinLine(partner.children, partner, direction);
      }
    }

  }

  // fontFontSizeChangeTest(font, fontSize){
  //   this.people.fontFontSizeChangeTest(font, fontSize);
  // }

  redrawSubtext(){
    this.people.redrawSubtext()
    this.node_connector.redrawLines()
  }

  sortChildren(children) {
    return children.sort((a, b) => {
      if (a.pos.column > b.pos.column) return 1;
      if (a.pos.column < b.pos.column) return -1;
      return 0;
    })
  }

  renderChildren(person, option = {}) {
    let children = person.children;
    for (let child of children) {
      // Get position in matrix
      let { column, row } = child.pos;
      let currentSlot = this.matrix.getSlotAt(row, column);

      // Get Gender and Caption
      let gender = child.data.profile.gender;
      let is_root = child.partners.length == 0;
      this.people.drawPersonAt(currentSlot, {
        gender,
        is_root,
        data: child.data,
        node_type: child.node_type
      });

      // Draw Sibling Vertical Line
      this.node_connector.drawSiblingVerticalLine(child);

      this.renderPartners(child);
    }
  }

  isLegendDragging() {
    return this.people.isLegendDragging();
  }

  getLinesForMaternal(){
    return this.people.getLinesForMaternal();
  }

  getLinesForPaternal(){
    return this.people.getLinesForPaternal();
  }

  /*
  End: Render Nodes
  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  */

}

export default MatrixPeople
