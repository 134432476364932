import settings from './settings';
import TreeBuilder from "./tree-builder";
import Cookie from 'js-cookie';
import {
  sortPointers,
  formatIdtoRkey,
  rkeyToId,
  get_root_ancestors,
  searchAndUpdate,
  searchNode,
} from "./helpers";
import {
  getMaxOverlapCount,
  sortNodesByLevel,
  getTreeRkeys,
  columnOverlapNodes,
  getIntersectingDescendants,
  sortNodesByColumn,
  getPartnersAndDescendantsRkey,
  getTreeRkeys_OLD,
  overlappingNodes
} from "./tree-calibrate";

export default class TreeCrawler {
  row_space = settings.matrix_row_space
  column_in_between = settings.column_in_between;

  root_pos = undefined;
  pointers_top = {};
  pointers_immediate = {};
  crawled_nodes = {};
  calibrated_rkeys = {};
  family_nodes = {};
  profiles = {};
  edges = {
    row: 0,
    column: 0,
    row_bottom: 0,
    column_right: 0,
  }

  rerun=false;
  rerun_crawled_nodes = {};

  crawled_line_nodes = [];
  intersections = [];

  constructor(family_tree = {}) {
    this.profiles = family_tree.profile;
    this.partners = family_tree.partners;
    this.pointers_top = {};
    this.pointers_immediate = {};
    this.crawled_nodes = {};
    this.family_nodes = {};
    this.builder = new TreeBuilder(family_tree);
    this.crawled_line_nodes = [];
    this.intersections = [];
  }

  crawl_ancestors(sort_value, level, row, rkey, immediate_partner_rkey, immediate_child_rkey) {
    let profile = this.profiles[rkey];
    if(!profile) return
    var pointer = { rkey: profile.rkey, profile, gender: profile.gender, level, immediate_partner_rkey, immediate_child_rkey,
      row,
      sort_value
    };

    this.pointers_immediate[pointer.rkey] = pointer;
    if(profile.hasOwnProperty('mother_id') === false) {
      this.pointers_top[profile.rkey] = pointer;
    }

    let next_row = row - this.row_space - 1;
    let next_level = level - 1;
    let next_sort_value = "";
    if(profile.hasOwnProperty('mother_id')) {
      var mother_rkey = formatIdtoRkey(profile.mother_id);
      var partner_rkey = formatIdtoRkey(profile.father_id);
      var child_rkey = profile.rkey;
      next_sort_value = sort_value + '_' + 2;
      this.crawl_ancestors(next_sort_value, next_level, next_row, mother_rkey, partner_rkey, child_rkey); // Only the mother contains child_rkey
    }

    if(profile.hasOwnProperty('father_id')) {
      var father_rkey = formatIdtoRkey(profile.father_id);
      var partner_rkey = formatIdtoRkey(profile.mother_id);
      var child_rkey = profile.rkey;
      next_sort_value = sort_value + '_' + 1;
      this.crawl_ancestors(next_sort_value, next_level, next_row, father_rkey, partner_rkey);
    }

    return;
  }

  get_immediate_child_node(mother_pointer, pos_self) {

    var child_rkey = mother_pointer.immediate_child_rkey;
    if(child_rkey === undefined) return undefined;

    let immediate_pointer = this.pointers_immediate[child_rkey];
    /*
    to left: spouse gender == m
    to right: spouse gender == f
    */
    var direction_partner = immediate_pointer.gender === "m" ? -1 : 1;

    let exists_in_tree =  this.crawled_nodes.hasOwnProperty(child_rkey);
    var bounds = undefined;
    if(exists_in_tree) {
      let existing_node = {...this.crawled_nodes[child_rkey]};
      pos_self = existing_node.pos;

      let pos_partner = {...existing_node.pos};
      var edge = this.get_edge(existing_node.bounds, direction_partner);
      pos_partner.column = edge;
      var partners = this.get_partners_nodes(child_rkey, pos_partner, immediate_pointer.immediate_partner_rkey);

      bounds = this.get_bounds(pos_self, direction_partner, partners);
      bounds = this.merge_bounds(bounds, existing_node.bounds);

      existing_node.bounds = bounds;
      existing_node.partners = partners;
      existing_node.node_type = "immediate_son_daughter";
      return existing_node;
    }

    var node = this.get_person_node(child_rkey, pos_self, "immediate_son_daughter");
    this.crawled_nodes[node.rkey] = {...node};
    return {...node};
  }

  get_children_nodes(partner_rkey, partner_direction=1, pos_start, exclude_child_rkey=undefined) {
    let pos_self = {...pos_start};
    let child_direction = partner_direction;

    let children_nodes = [];
    // let children = this.builder.get_sons_daughters(partner_rkey);
    let children_raw = this.builder.get_sons_daughters(partner_rkey).map( item => this.profiles[item.rkey]);

    // Make sure the twins is closer to the immediate child
    let sort_direction = partner_direction === 1 ? 'left' : 'right';
    let children = this.builder.sort_profiles_with_twins(children_raw, sort_direction);


    let immediate_child_pointer = undefined;
    if(exclude_child_rkey) {
      children = children.filter(c => c.rkey !== exclude_child_rkey);
      immediate_child_pointer = this.pointers_immediate[exclude_child_rkey];

      /*
      to left: other children when immediate child has gender == m
      to right: default
      */
      child_direction = (immediate_child_pointer && immediate_child_pointer.gender === "m") ? -1 : 1;
    }

    // Update direction
    pos_self.direction = child_direction;
    // let nodes = Object.values(this.crawled_nodes)
    // let proband_node = nodes.find(node => node.data.profile.is_proband === true);

    for(let child of children) {
      var node = this.get_person_node(child.rkey, {...pos_self}, 'son_daughter');
      children_nodes.push(node);
      // if(rkeyToId(partner_rkey)===808) console.log('node', node);
      pos_self.column = this.get_edge(node.bounds, child_direction);
      // let twins_above_proband = node.data.profile.twin_set !== null && node.data.profile.level < proband_node.data.profile.level;
      // if(twins_above_proband && node.data.profile.side === 'paternal'){
      //   node.pos.column = this.get_edge_twins(node.bounds, 1, proband_node.data.profile.mother_id);
      // }
      // else if (twins_above_proband && node.data.profile.side === 'maternal'){
      //   node.pos.column = this.get_edge_twins(node.bounds, -1, proband_node.data.profile.mother_id);
      // }
    }

    return children_nodes;
  }


  get_immediate_partner_node(spouse_pointer, pos_start) {
    let pos_self = {...pos_start};
    let partner_rkey = spouse_pointer.immediate_partner_rkey;
    let node_exists =  this.crawled_nodes.hasOwnProperty(partner_rkey);
    if(node_exists) {

      let node = {...this.crawled_nodes[partner_rkey]};

      // make sure to include children key
      if(!node.hasOwnProperty('children')) node.children = [];

      return node;
    }

    let partner_pointer = this.pointers_immediate[partner_rkey];
    let profile = partner_pointer.profile;

    /*
    to right: spouse gender == m
    to left: spouse gender == f
    */
    let direction_self = spouse_pointer.gender === "m" ? 1 : -1;
    pos_self.direction = direction_self;
    pos_self.column += (this.column_in_between + 1) * direction_self;
    // - - - -

    /*
    | Children
    |
    */
    let pos_children = {...pos_self};
    delete pos_children.direction; // detach direction
    let children = [];

    // Immediate Child
    /*
    to below: the first child in the tree
    */
    pos_children.row += (this.row_space + 1);
    // - - - -

    let mother_pointer = spouse_pointer.gender === "f" ? spouse_pointer : partner_pointer;
    let ic = this.get_immediate_child_node(mother_pointer, {...pos_children});

    if (ic) {
      children.push(ic);
      var direction_children = ic.gender === "m" ? -1 : 1;

      let ic_copy_bounds = Object.assign({}, ic.bounds);
      if (direction_children === -1) {
        // when adding sibling to the left add a buffer column in between
        ic_copy_bounds.column_start -= 1;
      }

      // Calculate siblings next position
      if(ic.data.profile.level === 1 ) {
        if(ic.partners.length === 1) {
          pos_children.column = ic.pos.column + (direction_children * (1 + this.column_in_between)); // next position plus gap
        } else {
          pos_children.column = this.get_edge(ic_copy_bounds, direction_children);
        }
      } else {
        // Uncles/Aunts and below
        pos_children.column = this.get_edge(ic_copy_bounds, direction_children);
      }

      let other_children = this.get_children_nodes(mother_pointer.rkey, pos_self.direction, { ...pos_children }, ic.rkey);

      children = children.concat(other_children);
    }

    // if(profile.id===808) console.log(partner_rkey, children);

    var bounds = this.get_bounds(pos_self, direction_self, children);

    // Adjust partner position to the center of the children
    // Make sure to adjust the immediate spouse when bubble up
    var bounds_calibrated = this.calibrate_partner_bounds(bounds, pos_self.direction, children.length);
    var pos_calibrated = this.calibrate_partner_pos(pos_self, bounds_calibrated, children);

    // Another Adjustment for immediate partner pos when child is one
    // Avoid overlapping, will create zigzag line
    // Move immediate partner column close to the immediate child
    // let ic_partner_has_ancestors = this.immediate_child_partner_has_ancestor(ic.rkey);

    if(
      ic
      && ic.data.profile.is_proband === false
      && children.length === 1
      // && ic_partner_has_ancestors
    ) {
      var direction = ic.gender === "m" ? -1 : 1;
      if(direction === -1) {
        pos_calibrated.column = ic.pos.column;
      } else {
        pos_calibrated.column = ic.pos.column + 2;
      }
    }
    // - - - -

    let node = {
      rkey: partner_rkey,
      gender: profile.gender,
      node_type: 'immediate_partner',
      bounds: bounds_calibrated,
      pos: pos_calibrated,
      children,
      data: {
        profile: profile,
        health: this.builder.get_health_data(partner_rkey)
      }
    }
    this.crawled_nodes[node.rkey] = {...node};
    this.update_edges(node.pos);
    // if(rkeyToId(partner_rkey) === 808) console.log(node);

    return {...node};
  }

  get_edge(bounds, direction) {
    let { column_start, column_end } = bounds;
    let edge = undefined;
    if(direction === -1) {
      edge = column_start; // Get the leftmost edge
      edge -= 1; // move 1 to the left
    } else {
      edge = column_end; // Get the rightmost edge
      edge += 1; // move 1 to the right
    }
    return edge;
  }

  // get_edge_twins(bounds, direction, mother_id){
  //   let { column_start, column_end} = bounds;
  //   let edge = undefined;
  //   let siblings = this.builder.get_sons_daughters(formatIdtoRkey(mother_id));
  //   if(siblings.length % 2 === 0) {
  //     if(direction === -1) {
  //       edge = column_start; // Get the leftmost edge
  //       edge -=2; // move 1 to the left
  //     } else {
  //       edge = column_end; // Get the rightmost edge
  //       edge += 2; // move 1 to the right
  //     }
  //   }
  //   else{
  //     if(direction === -1) {
  //       edge = column_start; // Get the leftmost edge
  //       edge -=1; // move 1 to the left
  //     } else {
  //       edge = column_end; // Get the rightmost edge
  //       edge += 1; // move 1 to the right
  //     }
  //   }
  //   return edge;
  // }


  get_children_center_column(children, direction) {
    if(children.length > 1) {
      let sorted_children = [...children];
      sorted_children = sorted_children.sort((a, b) => {
        if(a.pos.column > b.pos.column) return 1;
        if(a.pos.column < b.pos.column) return -1;
        return 0;
      });
      let first_child = sorted_children[0];
      let last_child = sorted_children[sorted_children.length-1];
      let column_count  = last_child.pos.column - first_child.pos.column;
      column_count = Math.abs(column_count) + 1; // include self
      let center = Math.floor(column_count / 2);
      var new_column = first_child.pos.column + center + ( 1 * direction);
      return new_column;
    }
    return undefined;
  }

  get_partners_nodes(spouse_rkey, pos_start, exclude_immediate_partner_rkey=undefined) {
    let pos_self = {...pos_start};
    let pos_update = {...pos_start};
    let direction_partner = pos_start.direction;
    let partners_nodes = [];

    let partners = this.builder.get_partners(spouse_rkey);
    if(partners.length == 0) return [];

    let immediate_spouse = undefined;
    if(exclude_immediate_partner_rkey) {
      partners = partners.filter(p => p.rkey !== exclude_immediate_partner_rkey);

      // Get immediate spouse, when exclude_immediate_partner_rkey is provided
      immediate_spouse = this.pointers_immediate[spouse_rkey];
    }

    if(immediate_spouse) {
      /*
      Use direction by immediate spouse gender
      to left: spouse gender == m
      to right: spouse gender == f
      */
      direction_partner = immediate_spouse && immediate_spouse.gender === "m" ? -1 : 1;
    }

    /*
    first child
    move 1 row below
    */
    let pos_children = { ...pos_self };
    pos_children.row += this.row_space + 1;

    pos_self.direction = direction_partner;
    pos_update.direction = direction_partner;

    let partner_index=0;
    for(let partner of partners) {

      /*
      Move Children closer to the parent(spouse)
      Apply to first partner of non-immediate spouse
      */
      if(partner_index === 0 && immediate_spouse === undefined) {
        pos_children = this.calibrate_first_child_pos(partner.rkey, pos_children);
      }

      let profile = this.profiles[partner.rkey];
      let children = this.get_children_nodes(partner.rkey, pos_self.direction, {...pos_children});
      var bounds = this.get_bounds(pos_self, direction_partner, children);

      // Adjust partner position to the center of the children
      // var pos_calibrated = this.calibrate_partner_pos(pos_self, bounds.column_count, children);
      var bounds_calibrated = this.calibrate_partner_bounds(bounds, direction_partner, children.length);
      var pos_calibrated = this.calibrate_partner_pos(pos_self, bounds_calibrated, children);

      let node = {
        rkey: profile.rkey,
        gender: profile.gender,
        node_type: 'partner',
        pos: {...pos_calibrated},
        bounds: bounds_calibrated,
        children,
        data: {
          profile: profile,
          health: this.builder.get_health_data(profile.rkey)
        }
      }
      this.update_edges(node.pos);
      partners_nodes.push(node);

      this.crawled_nodes[node.rkey] = {...node};

      pos_update.column = this.get_edge(node.bounds, direction_partner);
      pos_self = {...pos_update};
      pos_children.column = pos_self.column;
      partner_index++;
    }

    return partners_nodes;

  }

  get_person_node(rkey, pos_start, type=undefined) {
    let pos_self = {...pos_start};
    let pos_partner = {...pos_self};
    let bounds = undefined;
    let partners = [];
    let children = [];
    let profile = this.profiles[rkey];
    let direction_partner = 1; // default
    let node_type = type ? type : 'immediate_ancestor';

    /*
    | - - - - - - - - - - -
    |
    | Root Person: Prevent crawl descendant
    |
    */

    // Don't crawl descendants if root person
    // The person passed as parameter on crawl method (starting point from the bottom)
    if(this.is_root_person(rkey)) {
      bounds = this.get_bounds(pos_self, pos_self.direction, partners);
      var node = {
        rkey,
        gender: profile.gender,
        node_type,
        pos: pos_self,
        bounds: bounds,
        partners: partners,
        children,
        data: {
          profile: profile,
          health: this.builder.get_health_data(rkey)
        }
      }

      this.crawled_nodes[node.rkey] = {...node};
      return {...node};
    };

    /*
    | - - - - - - - - - - -
    |
    | Crawl Descendants
    |
    */

    /*
    Check if the node (person) is already crawled
    Only immediate partners already exists
    This is usually the Top Most Immediate partner
    When a person is crawled, it only means that it is an immediate partner of an existing spouse
    */
    let self_is_crawled =  this.crawled_nodes.hasOwnProperty(rkey);
    let self_as_immediate_partner = this.crawled_nodes[rkey] ? {...this.crawled_nodes[rkey]} : undefined;

    /*
    | Partners
    |
    */

    // Immediate Pointer always has Immediate Partner
    let immediate_pointer = this.pointers_immediate[rkey];
    if(immediate_pointer) {
      /*
      to left: spouse gender == m
      to right: spouse gender == f
      */
      direction_partner = immediate_pointer && immediate_pointer.gender === "m" ? -1 : 1;
    } else {
      direction_partner = pos_partner.direction;
    }
    pos_partner.direction = direction_partner;

    // Proband will default go to the right
    if(profile.is_proband)  {
      direction_partner = 1;
      pos_self.direction = 1;
      pos_partner.direction = 1;
    }
    // - - -

    let immediate_partner = undefined;
    let immediate_partner_rkey = immediate_pointer ? immediate_pointer.immediate_partner_rkey : undefined;
    if(self_as_immediate_partner === undefined && immediate_partner_rkey) {
      immediate_partner = this.get_immediate_partner_node(immediate_pointer, pos_self);


      /*
      This is executed when crawl is re run.
      Partner ancestor nodes does not exists on first run
      It adjust the partner using it's ancestors
      Run only for non-grandparent node
      Grandparent level is 1,0,-1 ... -n
      */
      if(this.rerun && profile.level > 1) {
        immediate_partner = this.update_immediate_partner_with_ancestor(immediate_partner);
      }

      // - - - - - - - - -

      partners.push(immediate_partner);

      // Move immediate spouse position to closer to its immediate partner
      var spouse_direction = immediate_pointer.gender === "m" ? -1 : 1;
      pos_self = this.calibrate_immediate_spouse_pos(pos_self, immediate_partner, spouse_direction);
    }

    /*
    Get other partners next position
    This is the default way to get next position
    */
    if(self_as_immediate_partner) {
      // Investigate, looks like not doing anything. maybe due to calibrate methods
      var self_edge = this.get_edge(self_as_immediate_partner.bounds, direction_partner);
      pos_partner.column = self_edge + ( 1  * direction_partner);
    } else {
      pos_partner.column += (this.column_in_between + 1) * direction_partner;
    }

    /*
      If there is an immediate partner
      Adjust next position of other partner
      Make sure it will not overlap with the bounds of immediate partner
      Get the edge of immediate partner and use as starting point of the next partner
    */
    if(immediate_partner) {
      var immediate_partner_edge = this.get_edge(immediate_partner.bounds, direction_partner);
      if(pos_partner.direction === -1 ) {
        if(immediate_partner_edge < pos_partner.column ) {
          pos_partner.column = immediate_partner_edge;
        }
      } else {
        if(immediate_partner_edge > pos_partner.column ) {
          pos_partner.column = immediate_partner_edge;
        }
      }
    }
    /*
    | - - - - - - - - - - - - - -
    */

    var other_partners = this.get_partners_nodes(rkey, pos_partner, immediate_partner_rkey);
    partners = partners.concat(other_partners);

    bounds = this.get_bounds(pos_self, pos_self.direction, partners, 'partners');

    if(self_is_crawled) {
      bounds = this.merge_bounds(bounds, self_as_immediate_partner.bounds);
      if(self_as_immediate_partner.hasOwnProperty('children')) {
        children = self_as_immediate_partner.children;
      }
      if(self_as_immediate_partner.hasOwnProperty('partners')) {
        partners = [...partners, ...self_as_immediate_partner.partners];
      }
      this.updateNodeInTree({rkey: self_as_immediate_partner.rkey, bounds, children, partners});
    }

    var node = {
      rkey,
      gender: profile.gender,
      node_type,
      pos: pos_self,
      bounds: bounds,
      partners: partners,
      children,
      data: {
        profile: profile,
        health: this.builder.get_health_data(rkey)
      }
    }

    /*
    Calibrate proband pos and bounds
    */
    if(profile.is_proband) {
      node = this.calibrate_proband(node);
    }
    // - - - - -

    this.crawled_nodes[node.rkey] = {...node};
    this.update_edges(node.pos);

    return {...node};
  }

  crawl(root_rkey, level=1, start_pos={row: 0, column: 0}) {

    // Abort if no ancestor
    let profile = this.profiles[root_rkey];
    if(profile.hasOwnProperty('mother_id') === false) return {};

    // Initialize
    this.pointers_top = {};
    this.pointers_immediate = {};
    this.crawled_nodes = {};
    this.family_nodes = {};

    // Save root person, do not crawl root_person desecendants
    this.root_pos = {...start_pos};
    this.root_rkey = root_rkey;

    // Get proband ancestors first
    let sort_value = profile.gender === "m" ? 1 : 2;
    this.crawl_ancestors(sort_value, level, start_pos.row, profile.rkey);

    // Once the ancestors are loaded to memory, crawl the whole tree
    this.crawl_tree();

    // Rerun to adjust partner ancestor's width
    this.rerun = true;
    this.family_nodes = {};
    this.rerun_crawled_nodes = Object.assign(this.crawled_nodes);
    this.crawled_nodes = {};
    this.crawl_tree();

    this.check_overlaps();
    // this.check_overlaps_old();

    // Get out only pos field of the intersections
    let intersections = this.intersections.map(item => item.pos);
    return {
      family_nodes: this.family_nodes,
      top_left_offset: this.get_offset(),
      edges: this.edges,
      intersections
    }

  }

  crawl_tree() {

    var sorted_pointers_top = sortPointers(this.pointers_top);
    var prev_node = undefined;
    for(let pointer of sorted_pointers_top) {
        var pos = this.get_position(pointer, prev_node);
        var node = this.get_person_node(pointer.rkey, pos);
        this.family_nodes[node.rkey] = node;
        prev_node={...node};
    }

    return {
      family_nodes: this.family_nodes,
      top_left_offset: this.get_offset(),
      edges: this.edges,
    }

  }


  get_offset() {
    let { row, column } = this.edges;
    return {
      row: row > 0 ? row : Math.abs(row),
      column: column > 0 ? column : Math.abs(column)
    }
  }

  /*
  | - - - - - - - - - - - - - - -
  | Utilities
  |
  */
  get_position(pointer, prev_node) {

    let pos = { row: undefined, column: undefined}

    // First descendant crawl
    if(Object.keys(this.crawled_nodes).length === 0) {
      pos.row = pointer.row; // use the row from ancestor crawl
      pos.column = this.root_pos.column; // use column set by the grand parent call (node_locator)
      this.update_edges(pos);
      return pos;
    }

    let node = this.crawled_nodes[pointer.rkey];
    if(node) {
      pos = node.pos;
      this.update_edges(pos);
      return pos;
    }

    // Add another logic, if there is no crawl  node, use the prev node last column count
    if(prev_node) {
      pos.column = prev_node.bounds.column_end + 1;
      pos.row = pointer.row;
      this.update_edges(pos);
      return pos;
    }

    throw new Error("Cannot determine position. The pointer does not exist from existing nodes.");
  }

  update_edges(pos) {
    let { row, column } = pos;
    if(row < this.edges.row) this.edges.row = row;
    if(column < this.edges.column) this.edges.column = column;

    if(row > this.edges.row_bottom) this.edges.row_bottom = row;
    if(column > this.edges.column_right) this.edges.column_right = column;
  }

  is_root_person(rkey) {
    // No root anymore
    return false;
    // return rkey === this.root_rkey;
  }

  get_next_column(bounds, direction=1) {
    // next column
    let next_column_count = 1;

    let { column_start, column_end} = bounds;
    let column = direction === 1 ? column_end : column_start
    return column + (next_column_count * direction);
  }

  get_bounds(pos_owner, direction = 1, partners_or_children, relation = 'children') {
    var bounds = undefined;
    let pos_self = {...pos_owner};
    var column_start = undefined;
    var column_end = undefined;
    var column_count = 0;

    if(partners_or_children.length === 0) {

      column_start = pos_self.column;
      column_end = pos_self.column;
      if(direction == 1) {
        column_end += this.column_in_between; // Add space to right
      } else {
        column_start -= this.column_in_between; // Add space to left
      }

    } else if( relation === 'partners') {

      column_start = pos_self.column;
      column_end = pos_self.column;
      for(let partner of partners_or_children) {
        bounds = partner.bounds;
        if(bounds.column_start < column_start) column_start = bounds.column_start;
        if(bounds.column_end > column_end ) column_end = bounds.column_end;
      }

    } else {

      var first_child = partners_or_children[0];
      bounds = first_child.bounds;
      column_start = bounds.column_start;
      column_end = bounds.column_end;

      for(let i=1; i<partners_or_children.length; i++) {
        var child = partners_or_children[i];
        bounds = child.bounds;
        if(bounds.column_start < column_start) column_start = bounds.column_start;
        if(bounds.column_end > column_end ) column_end = bounds.column_end;
      }

    }

    column_count = Math.abs(column_end - column_start);
    column_count += 1; // include self

    // Update column edge
    if(column_start < this.edges.column) this.edges.column = column_start;
    if(column_end > this.edges.column_right) this.edges.column_right = column_end;

    return { column_start, column_end, column_count};
  }

  merge_bounds(bounds_a, bounds_b) {
    let column_start = bounds_a.column_start;
    let column_end = bounds_a.column_end;
    let column_count = 0;
    if(bounds_b.column_start < column_start) column_start = bounds_b.column_start;
    if(bounds_b.column_end > column_end) column_end = bounds_b.column_end;
    column_count = column_end - column_start;
    column_count += 1; // include self

    // Update column edge
    if(column_start < this.edges.column) this.edges.column = column_start;
    if(column_end > this.edges.column_right) this.edges.column_right = column_end;

    return { column_start, column_end, column_count};
  }

  updateNodeInTree(node_update) {
    for(let key in this.family_nodes) {
      this.family_nodes[key] = searchAndUpdate(this.family_nodes[key], node_update);
    }
  }

  calibrate_partner_pos(pos_self, bounds, children) {

    /*
    1 = going to right
    -1 = going to left
    */

    let { direction } = pos_self;
    let { column_count } = bounds;
    let edge = this.get_edge(bounds, direction);
    let center = Math.ceil(column_count / 2);
    let new_column = edge + (center * direction * -1);

    if(children.length === 0) {
      // Move 1 column toward the center
      new_column = new_column + (-1 * direction);
    } else if(children.length === 1) {
      // Align to the only child
      new_column = children[0].pos.column;
      // Move 1 column away from center
      new_column = new_column + (1 * direction);
    } else {
      // Move to the center of the children
      new_column = this.get_children_center_column(children, direction);
    }

    return {...pos_self, column: new_column};
  }

  calibrate_partner_bounds(bounds, direction, children_count) {
    /*
    Make sure bounds is minimum of 3 colums
    */

    let { column_start, column_end, column_count } = bounds;
    let start = column_start;
    let end = column_end;
    let count = column_count;

    if(children_count === 1 && column_count === 2) {
      count++;
      if(direction === -1) {
        start--;
      } else {
        end++
      }
    }

    return {
      column_start: start,
      column_end: end,
      column_count: count
    }
  }

  calibrate_immediate_spouse_pos(pos_self, immediate_partner, spouse_direction) {
    let pos = {...pos_self};
    pos.column = immediate_partner.pos.column;
    pos.column += (1 + this.column_in_between) * spouse_direction;
    return pos;
  }

  calibrate_first_child_pos(partner_rkey, pos_children) {
    let children = this.builder.get_sons_daughters(partner_rkey);
    let pos_self = {...pos_children};
    if(children.length > 0) {
      /*
      1 child, move 1
      2 or more child, move 2
      */
      var count = children.length === 1 ? 1 : 2;
      pos_self.column += (count * pos_self.direction * -1);
    }
    return pos_self;
  }

  calibrate_immediate_child_pos(node) {
    let pos_self = {...node.pos};
    let child_pointer = this.pointers_immediate[node.rkey];
    let { immediate_partner_rkey } = child_pointer;
    var direction_immediate_child = child_pointer.gender === "m" ? -1 : 1;

    // if no partner
    if(immediate_partner_rkey === undefined)  {
      return pos_self;
    }

    let partner_profile = this.profiles[immediate_partner_rkey];

    // check if has ancestor
    if(!partner_profile.hasOwnProperty('father_id')) {
      return pos_self;
    }

    pos_self.column += (direction_immediate_child * 1);
    return pos_self;

  }

  calibrate_proband(source_node) {
    let node = {...source_node};
    let { profile } = node.data;

    if(node.partners.length === 0) {
      node.pos.column++;
      node.bounds.column_end+=1;
      node.bounds.column_count+=1;

      let siblings = this.builder.get_sons_daughters(formatIdtoRkey(profile.mother_id));
      siblings = siblings.filter(item => item.rkey !== node.rkey);
      if(siblings.length == 0) node.bounds.column_end++;
    }

    return node;
  }

  immediate_child_partner_has_ancestor(rkey) {
    let child_pointer = this.pointers_immediate[rkey];
    let { immediate_partner_rkey } = child_pointer;
    if(immediate_partner_rkey === undefined)  {
      return false;
    }

    let partner_profile = this.profiles[immediate_partner_rkey];
    return partner_profile.hasOwnProperty('father_id');
  }

  update_immediate_partner_with_ancestor(immediate_partner){
    let ancestor_bounds = undefined;
    let self = {...immediate_partner};

    let partner_pointer = this.pointers_immediate[self.rkey];
    let { father_id } = partner_pointer.profile;

    // Do not execute for grandparents and above
    // Grand parents level is 1,0,-1...-n
    let father_profile = this.profiles[formatIdtoRkey(father_id)];
    if(father_profile.level <= 1) return self;

    if(father_id) {
      ancestor_bounds = this.get_ancestor_bounds(partner_pointer);
    }

    if(ancestor_bounds){
      let merge_direction = self.gender === "m" ? 'left' : 'right';
      let updated_bounds =  this.merge_bounds(self.bounds, ancestor_bounds, merge_direction);
      self.bounds = updated_bounds
      this.updateNodeInTree({rkey: self.rkey, bounds: self.bounds});
    }

    return self;
  }

  get_ancestor_bounds(immediate_pointer){

    let { father_id, mother_id } = immediate_pointer.profile;
    let self_node = this.rerun_crawled_nodes[immediate_pointer.rkey];

    if(father_id === undefined) return self_node.bounds;

    let father_pointer =  this.pointers_immediate[formatIdtoRkey(father_id)]
    let paternal_bounds = this.get_ancestor_bounds(father_pointer);

    let mother_pointer =  this.pointers_immediate[formatIdtoRkey(mother_id)]
    let maternal_bounds = this.get_ancestor_bounds(mother_pointer);

    let bounds = this.merge_bounds(paternal_bounds, maternal_bounds);
    return bounds;
  }

  move_ancestor_tree(parent_rkey, root_ancestor_rkeys, parent_side='paternal') {
    var parent_node = this.locateNodeFromTree(parent_rkey);

    var overlap_count = 0;
    var count = 0;
    for(let rkey of root_ancestor_rkeys) {
      let current_node = this.family_nodes[rkey];
      if (current_node !== null && current_node !== undefined){
        count = getMaxOverlapCount(current_node, parent_node, parent_side);
      }
      if(Math.abs(count) > Math.abs(overlap_count)) overlap_count = count;
    }

    if(Math.abs(overlap_count) === 0 ) return;
    // if(Math.abs(overlap_count) === 0 ) overlap_count=-1;

    for(let rkey of root_ancestor_rkeys) {
      let current_node = this.family_nodes[rkey];
      if(current_node === undefined) continue; // Skip if does not exist family_node structure
      this.move_tree(current_node, parent_rkey, overlap_count);
    }
  }

  locateNodeFromTree(rkey) {
    let found = undefined;
    for(let key in this.family_nodes) {
      found = searchNode(this.family_nodes[key], rkey);
      if(found) return found;
    }

    return undefined;
  }

  move_tree(current_node, parent_rkey, column_count) {
    // if(current_node === undefined) console.log("undefined ========");

    let crawled = this.crawled_nodes[current_node.rkey];
    if(crawled.moved === undefined) {
      current_node.moved = true;
      current_node.pos.column += column_count;
      current_node.bounds.column_start+=column_count;
      current_node.bounds.column_end+=column_count;
      this.update_edges(current_node.pos);
      this.crawled_nodes[current_node.rkey] = {...current_node};
    } else {
      current_node.moved = true;
      current_node.pos.column = crawled.pos.column;
      current_node.bounds.column_start = crawled.bounds.column_start;
      current_node.bounds.column_end = crawled.bounds.column_end;
      this.crawled_nodes[current_node.rkey] = {...current_node};
    }

    let { children, partners } = current_node;
    if(children) {
      for(let child of children) {
        if(child.rkey === parent_rkey) continue;
        // console.log("children");
        this.move_tree(child, parent_rkey, column_count);
      }
    }

    if(partners) {
      for(let partner of partners) {
        this.move_tree(partner, parent_rkey, column_count);
      }
    }
  }

  check_overlaps() {
    this.check_overlapping_columns();
    this.check_intersecting_lines();
  }

  check_overlapping_columns() {
    let nodes_by_level = sortNodesByLevel(this.crawled_nodes);
    let overlaps = columnOverlapNodes(nodes_by_level); // New

    let loop_count=0;
    while(overlaps) {
      // Safety check to break perpetual loop
      if(loop_count++>settings.overlap_loop_limit) return;

      this.calibrate_column_overlaps(overlaps);
      var next_overlaps = columnOverlapNodes(nodes_by_level);

      if(next_overlaps === undefined) break;

      // Safety check to avoid perpetual loop;
      if(next_overlaps.side_a.rkey === overlaps.side_a.rkey
        && next_overlaps.side_b.rkey === overlaps.side_b.rkey
      ) break;

      overlaps = next_overlaps;
    }

  };

  calibrate_column_overlaps(overlaps) {

    let { side_a, side_b} = overlaps;
    let side_a_tree_rkeys = getTreeRkeys(side_a.data.profile.rkey, this.crawled_nodes);
    let side_b_tree_rkeys = getTreeRkeys(side_b.data.profile.rkey, this.crawled_nodes);

    let intersecting_descendant_rkeys = getIntersectingDescendants(side_a_tree_rkeys, side_b_tree_rkeys, this.crawled_nodes);

    if(intersecting_descendant_rkeys) {
      let {a_rkey, b_rkey} = intersecting_descendant_rkeys;
      let paternal_side_rkey = a_rkey;
      let maternal_side_rkey = b_rkey;
      if(this.crawled_nodes[b_rkey].gender === 'm') {
        paternal_side_rkey = b_rkey;
        maternal_side_rkey = a_rkey;
      }
      this.fix_column_overlaps(paternal_side_rkey, maternal_side_rkey);
    }
  }

  fix_column_overlaps(paternal_side_rkey, maternal_side_rkey) {
    this.reset_moved_flag();

    let paternal_root_ancestor_rkeys = get_root_ancestors(paternal_side_rkey, this.profiles);
    this.move_ancestor_tree(paternal_side_rkey, paternal_root_ancestor_rkeys, 'paternal');

    let maternal_root_ancestor_rkeys = get_root_ancestors(maternal_side_rkey, this.profiles);
    this.move_ancestor_tree(maternal_side_rkey, maternal_root_ancestor_rkeys, 'maternal');
  }

  reset_moved_flag() {
    for(let rkey of Object.keys(this.crawled_nodes)) {
      this.crawled_nodes[rkey].moved = undefined;
    }
  };

  check_intersecting_lines() {

    let horizontal_lines = this.get_horizontal_lines();
    let vertical_lines = this.get_vertical_lines();
    this.intersections = this.get_intersections(horizontal_lines, vertical_lines);

    // console.log('horizontal_lines',horizontal_lines);
    // console.log('vertical_lines',vertical_lines);
    // console.log('intersections', intersections);
  }

  get_intersections(horizontal_lines, vertical_lines) {
    if(horizontal_lines.length === 0 || vertical_lines === 0) return [];

    let intersections = [];
    for(let h of horizontal_lines) {
      for(let v of vertical_lines) {
        if(this.node_intersects(h, v)) {
          let row = h.coord.left.row; // horizontal line determine the row
          let column = v.coord.top.column; // vertical line determine the column
          intersections.push({
            horizontal: h,
            vertical: v,
            pos: {row, column}
          });
        }
      }
    }

    return intersections;
  }

  node_intersects(horizontal, vertical) {
    // Do not test own children
    let parent_id = vertical.parent.data.profile.id;
    let { father_id, mother_id } = horizontal.start.data.profile;
    if(father_id === parent_id || mother_id === parent_id) return false;
    // - - - - -

    let { left, right } = horizontal.coord;
    let { top, bottom } = vertical.coord;

    var column_intersects = top.column >= left.column && top.column <= right.column;
    var row_intersects = left.row >= top.row && left.row <= bottom.row;

    let intersects = column_intersects && row_intersects

    return intersects;
  }

  get_siblings_partners_descendants_rkeys(node) {
    let rkeys = [];
    rkeys.push(node.rkey);

    var overlaps_sibling_rkeys = this.get_siblings_rkey(node);
    rkeys = [...rkeys, ...overlaps_sibling_rkeys];
    for(var sibling_rkey of overlaps_sibling_rkeys) {
      // Get tree of sibling
      var sibling_result = getPartnersAndDescendantsRkey(sibling_rkey, this.crawled_nodes);
      rkeys = [...rkeys, ...sibling_result];
    }

    let immediate_spouse_rkey = this.get_immediate_spouse_rkey(node);
    if(immediate_spouse_rkey) {
      rkeys.push(immediate_spouse_rkey);

      // Get tree of spouse
      var spouse_result = getPartnersAndDescendantsRkey(immediate_spouse_rkey, this.crawled_nodes);
      rkeys = [...rkeys, ...spouse_result];

      var immediate_spouse = this.crawled_nodes[immediate_spouse_rkey];
      var spouse_sibling_rkeys = this.get_siblings_rkey(immediate_spouse);
      rkeys = [...rkeys, ...spouse_sibling_rkeys];
      for(var sibling_rkey of spouse_sibling_rkeys) {
        // Get tree of spouse's sibling
        var spouse_sibling_result = getPartnersAndDescendantsRkey(sibling_rkey, this.crawled_nodes);
        rkeys = [...rkeys, ...spouse_sibling_result];
      }
    }

    return [...new Set(rkeys)];
  }

  get_immediate_spouse_rkey(node) {
    var partners = node.partners;
    if(partners) {
      for(var partner of partners) {
        if(partner.node_type === 'immediate_partner') return partner.rkey;
      }
    }

    partners = this.builder.get_partners(node.rkey);
    let immediate_rkeys = Object.keys(this.pointers_immediate);
    if(partners) {
      for(var partner of partners) {
        if(immediate_rkeys.includes(partner.rkey)) return partner.rkey;
      }
    }

    return undefined;
  }

  get_siblings_rkey(node) {
    let { father_id, mother_id } = node.data.profile;
    let siblings_rkeys = [];
    for(var sibling of Object.values(this.crawled_nodes)) {
      if(sibling.rkey === node.rkey) continue;

      let sibling_father_id = sibling.data.profile.father_id;
      let sibling_by_father = sibling_father_id !== undefined && sibling_father_id === father_id;

      let sibling_mother_id = sibling.data.profile.mother_id;
      let sibling_by_mother = sibling_mother_id !== undefined && sibling_mother_id === mother_id;

      if(sibling_by_father || sibling_by_mother) {
        siblings_rkeys.push(sibling.rkey);
      }
    }

    return siblings_rkeys;
  }

  fix_overlap_row(row_overlap_node) {

    let { node, overlaps_with } = row_overlap_node;
    let overlaps_rkeys = this.get_siblings_partners_descendants_rkeys(overlaps_with);

    this.reset_moved_flag();
    var family_rkeys = Object.keys(this.family_nodes);

    for(var rkey of family_rkeys) {
      let current_node = this.family_nodes[rkey];
      this.lift_tree(current_node, overlaps_rkeys);
    }
  }

  lift_tree(the_node, overlaps_rkeys) {
    var current_node = {...the_node};

    // Do not move nodes from overlap tree
    if(overlaps_rkeys.includes(current_node.rkey)) return;

    let crawled = this.crawled_nodes[current_node.rkey];

    if(crawled.moved === undefined) {
      current_node.moved = true;
      let new_row = current_node.pos.row - this.row_space -1;
      current_node.pos.row = new_row;
      this.update_edges(current_node.pos);
      this.crawled_nodes[current_node.rkey] = {...current_node};
    } else {
      current_node.moved = true;
      current_node.pos.row = crawled.pos.row;
      this.crawled_nodes[current_node.rkey] = {...current_node};
    }

    let { children, partners } = current_node;
    if(children) {
      for(let child of children) {
        this.lift_tree(child, overlaps_rkeys);
      }
    }

    if(partners) {
      for(let partner of partners) {
        this.lift_tree(partner, overlaps_rkeys);
      }
    }
  }

  get_vertical_lines() {

    this.crawled_line_nodes = [];
    var vertical_lines = [];

    for(var node of Object.values(this.family_nodes)) {
      vertical_lines = [
        ...vertical_lines,
        ...this.recurse_vertical_lines(node)
      ]
    }

    return vertical_lines;
  }

  simpleStringify (object){
    // stringify an object, avoiding circular structures
    var simpleObject = {};
    for (var prop in object ){
        if (!object.hasOwnProperty(prop)){
            continue;
        }
        if (typeof(object[prop]) == 'object'){
            continue;
        }
        if (typeof(object[prop]) == 'function'){
            continue;
        }
        simpleObject[prop] = object[prop];
    }
    return JSON.stringify(simpleObject); // returns cleaned up JSON
  };

  recurse_vertical_lines(node) {
    if(this.crawled_line_nodes.includes(node.rkey)) return [];

    var vertical_lines = [];
    let node_copy = JSON.parse(this.simpleStringify(node));
    var { children, partners } = node_copy;

    if(children && children.length > 0) {
      var coord = {
        top: {
          row: node_copy.pos.row,
          column: node_copy.pos.column - 1 // line column
        },
        bottom: {
          row: node_copy.pos.row + 3, // Child row
          column: node_copy.pos.column - 1 // line column
        }
      };

      vertical_lines.push({
        parent: node_copy,
        coord
      });

      // make sure this will be crawled once
      this.crawled_line_nodes.push(node_copy.rkey);
    }

    if(children) {
      for(var child  of children) {
        vertical_lines = [
          ...vertical_lines,
          ...this.recurse_vertical_lines(child)
        ]
      }

    }

    if(partners) {
      for(let partner of partners) {
        vertical_lines = [
          ...vertical_lines,
          ...this.recurse_vertical_lines(partner)
        ]
      }
    }

    return vertical_lines;
  }

  get_horizontal_lines() {
    this.crawled_line_nodes = [];

    var horizontal_lines = [];

    for(var node of Object.values(this.family_nodes)) {
      horizontal_lines = [
        ...horizontal_lines,
        ...this.recurse_horizontal_lines(node)
      ];

      let { partners } = node;
      for(let partner of partners) {
        horizontal_lines = [
          ...horizontal_lines,
          ...this.recurse_horizontal_lines(partner)
        ];
      }
    }

    return horizontal_lines;
  }

  recurse_horizontal_lines(node) {
    if(this.crawled_line_nodes.includes(node.rkey)) return [];

    let horizontal_lines = [];
    let { children } = node;
    if(children.length > 1) {
      let sorted_children = sortNodesByColumn(children);
      var first = sorted_children[0];
      var last = sorted_children[sorted_children.length-1];

      var coord = {
        left: {
          row: first.pos.row - 1, // line row
          column: first.pos.column

        },
        right: {
          row: last.pos.row - 1,  // line row
          column: last.pos.column
        }
      };

      horizontal_lines.push({
        start: first,
        end: last,
        coord
      })

      // make sure this will be crawled once
      this.crawled_line_nodes.push(node.rkey);
    }

    for(let child of children) {
      horizontal_lines = [
          ...horizontal_lines,
          ...this.recurse_horizontal_lines(child)
      ];
    }

    return horizontal_lines;
  }

  /*
  | - - - - - - - - - - - - - - -
  | TEMPORARY - OLD CODE, Will be removed soon
  | - - - - - - - - - - - - - - -
  */

  check_overlaps_old() {

    let nodes_by_level = sortNodesByLevel(this.crawled_nodes);
    let overlaps = overlappingNodes(nodes_by_level, this.partners);

    while(overlaps) {

      this.calibrate_tree_overlaps(overlaps);

      var next_overlaps = overlappingNodes(nodes_by_level, this.partners);
      if(next_overlaps === undefined) break;

      // Safety check to avoid perpetual loop;
      if(next_overlaps.side_a.rkey === overlaps.side_a.rkey
        && next_overlaps.side_b.rkey === overlaps.side_b.rkey
      ) break;

      overlaps = next_overlaps;
    }
  };

  calibrate_tree_overlaps(overlaps) {

    let { side_a, side_b} = overlaps;
    let side_a_tree_rkeys = getTreeRkeys_OLD(side_a.data.profile.rkey, this.crawled_nodes);
    let side_b_tree_rkeys = getTreeRkeys_OLD(side_b.data.profile.rkey, this.crawled_nodes);

    let intersecting_descendant_rkeys = getIntersectingDescendants(side_a_tree_rkeys, side_b_tree_rkeys, this.crawled_nodes);

    if(intersecting_descendant_rkeys) {
      let {a_rkey, b_rkey} = intersecting_descendant_rkeys;
      let paternal_side_rkey = a_rkey;
      let maternal_side_rkey = b_rkey;
      if(this.crawled_nodes[b_rkey].gender === 'm') {
        paternal_side_rkey = b_rkey;
        maternal_side_rkey = a_rkey;
      }
      this.fix_overlaps(paternal_side_rkey, maternal_side_rkey);
    }
  }

  fix_overlaps(paternal_side_rkey, maternal_side_rkey) {
    this.reset_moved_flag();

    let paternal_root_ancestor_rkeys = get_root_ancestors(paternal_side_rkey, this.profiles);
    this.move_ancestor_tree(paternal_side_rkey, paternal_root_ancestor_rkeys, 'paternal');

    let maternal_root_ancestor_rkeys = get_root_ancestors(maternal_side_rkey, this.profiles);
    this.move_ancestor_tree(maternal_side_rkey, maternal_root_ancestor_rkeys, 'maternal');
  }

  wordWrapCounter(txt, max){
    var lines = [];
    var space = -1;
    function cut() {
      for (var i = 0; i < txt.length; i++) {
        (txt[i] == ' ') && (space = i);
        if (i >= max) {
          (space == -1 || txt[i] == ' ') && (space = i);
          if (space > 0) { lines.push(txt.slice((txt[0] == ' ' ? 1 : 0), space)); }
          txt = txt.slice(txt[0] == ' ' ? (space + 1) : space);
          space = -1;
          break;
        }
      } check();
    }
    function check() { if (txt.length <= max) { lines.push(txt[0] == ' ' ? txt.slice(1) : txt); txt = ''; } else if (txt.length) { cut(); } return; }
    check();
    return lines.length;
  }

  getGeneticTestingString(profile){
    let content = '';
    let nonNegative = [];
    if (profile !== null) {
      nonNegative = profile.filter(geneTest => geneTest.result !== "n")
    }

    if (nonNegative.length !== 0){
      for(let gene of nonNegative){
        if(gene.result === "p") {
          gene.result = "Pathogenic"
        }
        else if(gene.result === "lp"){
          gene.result = "Likely Pathogenic"
        }
        else if(gene.result === "ln"){
          gene.result = "Likely Benign"
        }
        else if(gene.result === "u"){
          gene.result = "Unsure"
        }
        else if(gene.result === "vus"){
          gene.result = "VUS"
        }
        content += gene.gene + " - " + gene.result + ", "
      }
      content = content.slice(0, -2)
    }
    else if (nonNegative.length === 0 && profile !== null && profile.length !== 0){
      content = "Negative genetic testing"
    }
    else{
      content = '';
    }
    return content;
  }

  move_down_nodes_below_nodes_with_subtexts(row_bottom){
    let displayGeneticTesting = Cookie.get('famgenix_pedigree_genetic_testing')
    let displayGeneticTestingBool = displayGeneticTesting == 'true'
    let displayNotes = Cookie.get('famgenix_pedigree_notes')
    let displayNotesBool = displayNotes == 'true'
    let nodes = Object.values(this.crawled_nodes)
    let nodesWithDiseasesOrGeneTests = nodes.filter(node => node.data.health.history_diseases !== null || node.data.health.history_gene_tests !== null || node.data.profile.note)
    nodesWithDiseasesOrGeneTests.map(node => {
      let count = 1;
      let diseasesLength = 0;
      let geneTestsLineCount = 0;
      let noteLinesCount = 0;
      let causeOfDeathLinesCount = 0;
      let maxChar = Cookie.get("famgenix_active_smartdraw") == 'flux' ? 30 : 15;
      if(node.data.health.history_diseases){
        diseasesLength = Object.keys(node.data.health.history_diseases).length;
        if (diseasesLength >= 3){
          diseasesLength = 3;
        }
      }
      if(displayGeneticTestingBool && (node.data.health.history_gene_tests && node.data.health.history_gene_tests.length !== 0)){
        // geneTestsLength = Object.keys(node.data.health.history_gene_tests).length;
        geneTestsLineCount = this.wordWrapCounter(this.getGeneticTestingString(node.data.health.history_gene_tests), maxChar);
      }
      if(displayNotesBool && node.data.profile.note){ //add condition if notes is active on toolbar
        noteLinesCount = this.wordWrapCounter(node.data.profile.note, maxChar);
      }
      if(node.data.profile.is_dead && node.data.profile.cause_of_death){
        causeOfDeathLinesCount = this.wordWrapCounter(node.data.profile.cause_of_death, maxChar)
      }
      count = count + diseasesLength + geneTestsLineCount + noteLinesCount + causeOfDeathLinesCount;
      node.labelLinesCount = count;
    });
    nodesWithDiseasesOrGeneTests.sort(function(a, b) {
      return b.labelLinesCount - a.labelLinesCount;
    });
    var name_map = new Map(nodesWithDiseasesOrGeneTests.slice().reverse().map(node => [node.data.profile.level, node]));
    var unique_nodes = [...name_map.values()];

    unique_nodes.sort(function(a, b) {
      return a.data.profile.level - b.data.profile.level;
    });

    let totalRowsAdded = 0;

    for (let unique_node of unique_nodes){
      let nodesBelowNode = nodes.filter(node => node.pos.row > unique_node.pos.row )
      let rowAdded = false;
      for (let node of nodesBelowNode){
          let rowsToAdd = 0;
          if (unique_node.labelLinesCount >= 2 && unique_node.labelLinesCount <= 4 ){
            rowsToAdd += 0;
          }
          else if(unique_node.labelLinesCount >= 5 && unique_node.labelLinesCount <= 9){
            rowsToAdd += 1;
          }
          else if(unique_node.labelLinesCount >= 10){
            rowsToAdd += Math.ceil(unique_node.labelLinesCount/5);
          }
          this.crawled_nodes[node.rkey].pos.row += rowsToAdd;
          if(!rowAdded){
            rowAdded = true;
            totalRowsAdded += rowsToAdd;
          }
      }

    }

    //get most top left node
    let mostTopLeftNode = nodes.reduce(function(prev, curr) {
      return prev.pos.row < curr.pos.row ? prev : curr;
    });

    mostTopLeftNode = nodes.reduce(function(prev, curr) {
      return prev.pos.column < curr.pos.column ? prev : curr;
    });


    //add total rows added by subtext to row_bottom to extend the pedigree space below
    return {
      row_bottom: row_bottom + totalRowsAdded,
      mostTopLeftNode: mostTopLeftNode,
    }
  }


  /* END TEMPORARY  - - - - - - */

}
