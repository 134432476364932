import React, { Component } from 'react';

import ActivityIndicator from "../activity-indicator";
import ErrorSummary from "../error-summary";
import api_auth from '../../api/authentication';
import helpers from '../../helpers/index';
import LinkExpired from './link-expired'
import * as action_auth from '../../store/session/actions';

class PasswordResetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_password: '',
      new_password_confirmation: '',
      loading: false,
      errorMessages: []
    };
    this.handleChange  = this.handleChange.bind(this);
    this.handleSubmit  = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    this.mounted = true;
}
  async componentWillUnmount() {
    this.mounted = false;
  }

  handleChange(event) {
    const value = event.target.value;
    const name  = event.target.name;
    this.setState({ [name]: value });
  }

  validate() {
    let options = {
      new_password: {
        labelName: 'Password',
        validations: { required: true }
      },
      new_password_confirmation: {
        labelName: 'Password Confirmation',
        validations: { required: true }
      }
    };

    let validations = helpers.validateInputs(this.state, options);
    this.setState({ errorMessages: validations.summary });

    return validations.isValid;

  }

  async handleSubmit(event) {
    event.preventDefault();

    try {
      this.setState({loading: true, errorMessages: []});

      let isValid = this.validate();
      if(!isValid) return;

      await api_auth.update_clinician_password(this.props.uid64, this.props.token, this.state.new_password, this.state.new_password_confirmation, this.props.initialCreation);
      this.props.onChangePasswordSuccess();

    } catch (error) {
      let parsedError = api_auth.parse_error_message(error);
      let capitalizedMessage = []
      let errorMessages = parsedError.message.split('. ')

      if(errorMessages.length > 1){
      capitalizedMessage = errorMessages.map((txt)=>{
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase().concat('. '); 
      })
      }else{
        capitalizedMessage = parsedError.message
      }
      this.setState({errorMessages: [capitalizedMessage]});
      this.props.onChangePasswordFail();

    } finally {

      if(this.mounted) {
        this.setState({ loading: false });
      }

    }

  }

  render() {
    let form_text = this.props.initialCreation ? 'Set Password' : 'Change Password';
    let reset_type = this.props.initialCreation ? 'Create Password' : 'Reset Password';
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
          <div className="form-group">
            <p style={{fontSize: '17px'}}>
              <b>{reset_type}</b>
            </p>
                <p>
                  Minimum of 12 characters, one uppercase letter,
                  one lowercase letter, one number, and one special character.
                </p>
              </div>
            <input type="password"
                  className="form-control input-lg"
                  placeholder="Password"
                  required=""
                  id="new_password"
                  name="new_password"
                  onChange={this.handleChange} />
          </div>
          <div className="form-group">
            <input type="password"
                  className="form-control input-lg"
                  placeholder="Password Confirmation"
                  required=""
                  id="new_password_confirmation"
                  name="new_password_confirmation"
                  onChange={this.handleChange} />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-teal btn-block btn-lg no-margin-top" disabled={this.props.disabled}>{form_text}</button>
          </div>
        </form>
        <ErrorSummary errorMessages={this.state.errorMessages} />
        <ActivityIndicator loading={this.state.loading} />

      </React.Fragment>

    );
  }
}

export default PasswordResetForm;
