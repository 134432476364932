import React, { Component } from 'react';
import ModalTwins from '../modal-twins'
import { connect } from "react-redux";
// @ts-ignore
import { cloneDeep } from 'lodash'
import familyApi from '../../api/family-api';
import { TwinConnectorNodeName } from './pedigree-constants';

interface State {
    twin_data: any;
    openModalTwin: boolean;
    modalTwinHash: number;
}

interface Props {
    data: any;
    showDropdown: boolean;
    dispatch: any;
    patient: any;
}

class TwinTypeDropDownMenu extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            twin_data: [],
            openModalTwin: false,
            modalTwinHash: new Date().getTime(),
        };

        this.onOptionClick = this.onOptionClick.bind(this)
    }

    componentDidMount(){
        let twin_id = parseInt(this.props.data.id.replace(TwinConnectorNodeName+'-', ''))

        let people = Object.values(this.props.data.getPedigreeData().profiles)
        let twin_set = people.filter((person:any) => person.twin_id == twin_id)

        this.setState({ twin_data: twin_set })
    }

    onClickOpenModalTwin() {
        this.setState({
            openModalTwin: true,
            modalTwinHash: new Date().getTime(),
        })
    }

    getTwinFromApiResponse(data: any, id: number) {
        if (data.length == 0) return null
        let members = data[0].members
        if (members.length == 0) return null
        let member = members.find((item: any) => item.id == id)
        if (typeof (member) == 'undefined') return null
        return member;
    }

    async onOptionClick(option: string){
        let twin_data = cloneDeep(this.state.twin_data);

        if(option !== 'combination'){
            for (let twin of twin_data){
                twin.twin_type = option
                //update the twin_type on pedigree data store
                await this.props.data.getPedigreeData().setProfile(twin.rkey, twin)
            }



            let payload = this.buildTwinPayload();
            let data = await familyApi.post_edit_twin_type(payload);
            for (let twin of twin_data){
                let member_data = this.getTwinFromApiResponse(data, twin.id)
                twin.twin_id = member_data.twin_id
                twin.twin_id_id = member_data.twin_id
                twin.twin_set = member_data.twin_set
                //update the twin_id and twin_set on pedigree data store
                await this.props.data.getPedigreeData().setProfile(twin.rkey, twin)
            }
            this.setState({ twin_data })

            await this.props.data.reRenderPedigree();
        }
        else{
            this.onClickOpenModalTwin()
        }
    }

    buildTwinPayload() {

        var twinsProfileRkeys = this.state.twin_data.map((twin: any) => twin.rkey);

        var twins = this.buildTwinsBySet(twinsProfileRkeys);


        return {
            father_id: this.state.twin_data[0].father_id,
            mother_id: this.state.twin_data[0].mother_id,
            family_id: this.state.twin_data[0].family_id,
            twins: twins
        };

    }

    buildTwinsBySet(twinsProfileRkeys: any) {
        let twins = [];
        //Append the rest of the members
        for (var rkey of twinsProfileRkeys) {
          var profile = this.props.data.getPedigreeData().profiles[rkey];
          if(profile.twin_type == null) continue;

          var twin_set = (profile.twin_set == null) ? 1 : profile.twin_set;

          let twinIndex: number = twins.findIndex(twin => twin.set == twin_set);
          if (twinIndex == -1) {
            // Create new twin set and add the member into it
            twins.push({
              set: twin_set,
              members: [
                {
                  rkey: profile.rkey,
                  member_id: profile.id,
                  twin_type: profile.twin_type.toLowerCase()
                }
              ]
            });
          } else {
            // Add new twin member
            var twinMembers:any = cloneDeep(twins[twinIndex]);
            twinMembers.members.push({
              rkey: profile.rkey,
              member_id: profile.id,
              twin_type: profile.twin_type.toLowerCase()
            });
            twins[twinIndex] = twinMembers;
          }
        }
        return twins;
      }

    render() {

        const show_dropdown = this.props.showDropdown ? 'open' : '';
        let people = Object.values(this.props.data.getPedigreeData().profiles)
        let siblings: any[] = []
        if (this.state.twin_data.length > 0){
            siblings = people.filter((person: any) => (person.mother_id == this.state.twin_data[0].mother_id && person.father_id == this.state.twin_data[0].father_id) ||
            (person.mother_id == this.state.twin_data[0].father_id && person.father_id == this.state.twin_data[0].mother_id))
        }

        let proband_on_siblings = JSON.parse(JSON.stringify(siblings)).find((sibling: any) => sibling.id == this.props.data.getPedigreeData().proband.id)
        if(proband_on_siblings){
            siblings = siblings.filter(sibling => sibling.id != this.props.data.getPedigreeData().proband.id)
        }

        const dropdown_menu_styles = {
            fontFamily: 'sans-serif',
            fontSize: 12,
            backgroundColor: '#ffffff'
        };

        const dropdown_label = Object.assign({}, dropdown_menu_styles, { fontWeight: 'bold', textAlign: 'justify', padding: '3px 20px', whiteSpace: 'nowrap' });

        return (
            <div className={"dropdown " + show_dropdown} style={{ left: '5px' }}>

                <ul className="dropdown-menu" aria-labelledby="dropdown_children_dom">
                    {/* @ts-ignore */}
                    <li style={dropdown_label}>Edit Twin Type</li>

                    <li onClick={() => this.onOptionClick('fraternal')} style={dropdown_menu_styles}>
                        <a>Fraternal</a>
                    </li>

                    <li onClick={() => this.onOptionClick('identical')} style={dropdown_menu_styles}>
                        <a>Identical</a>
                    </li>

                    <li onClick={() => this.onOptionClick('not sure')} style={dropdown_menu_styles}>
                        <a>Not Sure</a>
                    </li>

                    {this.state.twin_data.length > 2 &&
                    <li onClick={() => this.onOptionClick('combination')} style={dropdown_menu_styles}>
                        <a>Combination</a>
                    </li>
                    }
                </ul>


                {this.state.openModalTwin && (
                    <ModalTwins
                        selected_sibling_id={proband_on_siblings ? this.props.data.getPedigreeData().proband.twin_id == this.state.twin_data[0].twin_id ? this.props.data.getPedigreeData().proband.id : this.state.twin_data[0].id : this.state.twin_data[0].id}
                        key={this.state.modalTwinHash}
                        twinFor="siblings"
                        maleLabel="Brother"
                        femaleLabel="Sister"
                        otherLabel="Sibling"
                        dispatch={this.props.dispatch}
                        patient={this.props.data.getPedigreeData().proband}
                        profiles={this.props.data.getPedigreeData().profiles}
                        fatherId={this.state.twin_data[0].father_id}
                        motherId={this.state.twin_data[0].mother_id}
                        familyId={this.state.twin_data[0].family_id}
                        siblings={siblings}
                        onClose={() => {
                            this.setState({ openModalTwin: false })
                        } }
                        openedFromPedigree={true}
                        reRenderPedigree={this.props.data.reRenderPedigree}
                        getPedigreeData={this.props.data.getPedigreeData}
                        proband_on_siblings={proband_on_siblings !== null && proband_on_siblings !== undefined}
                    />
                )}
            </div>

            )
    }
}

const redux_state = (state: any) => ({
    session: state.session,
    patient: state.patient
});

const redux_actions = (dispatch: any) => ({
    dispatch: (action: any) => dispatch(action)
});

export default connect(redux_state, redux_actions)(TwinTypeDropDownMenu);
