import settings from './settings';
export const getAdoptedOption = (profile) => {
  let { adopted_in, adopted_out } = profile;
  if(adopted_in) {
    return {
      adopted: true,
      adopted_type: 'in'
    };
  }

  if(adopted_out) {
    return {
      adopted: true,
      adopted_type: 'out'
    };
  }

  return undefined;
}

export const getCenter = option => {
  let box_center = settings.matrix_box_width / 2;
  let padding = (settings.matrix_box_width - settings.person_width) / 2;
  return option.loc.add([box_center, box_center - padding]);
}

export const getXYFromCenter = option => {
  let padding = (settings.matrix_box_width - settings.person_width) / 2;
  return {
    x_loc: option.loc.x + padding,
    y_loc: option.loc.y + 0,
  };
}

export const getXYpad = () => {
  let padding = (settings.matrix_box_width - settings.person_width) / 2;
  return {
    x_pad: padding,
    y_pad: 0
  }
}

export const sortPointers = pointers => {
  // // sort by level. ascending
  let sorted_pointers_top = Object.values(pointers).sort( (a,b) => {
    if(a.level > b.level) return 1;
    if(a.level < b.level) return -1;
    return 0;
  });

  // then sort by sort_value (male is always on the left)
  sorted_pointers_top = sorted_pointers_top.sort( (a,b) => {
    if(a.level === b.level) {
      if(a.sort_value > b.sort_value) return 1;
      if(a.sort_value < b.sort_value) return -1;
      return 0;
    }

    return 0;
  })

  return sorted_pointers_top;
}

export const formatIdtoRkey = (id) => {
  return "apimem-" + id;
}

export const rkeyToId = (rkey="") => {
  return parseInt(rkey.replace('apimem-', ''));
}

export const get_root_ancestors = (rkey, profiles) => {
  let root_ancestor_rkeys= [];
  let profile = profiles[rkey];
  if(!profile) return []
  let { father_id, mother_id } = profile;
  if(father_id === undefined && mother_id === undefined) {
    root_ancestor_rkeys.push(rkey);
  } else {
    let father_rkey = formatIdtoRkey(father_id);
    let mother_rkey = formatIdtoRkey(mother_id);
    root_ancestor_rkeys = root_ancestor_rkeys.concat(get_root_ancestors(father_rkey, profiles));
    root_ancestor_rkeys = root_ancestor_rkeys.concat(get_root_ancestors(mother_rkey, profiles));
  }
  return root_ancestor_rkeys;
}

export const searchAndUpdate = (current, update) => {
  if(current.rkey === update.rkey) {
    if(update.hasOwnProperty('children')) {
      current.children = update.children;
    }

    if(update.hasOwnProperty('partners')) {
      current.partners = update.partners;
    }

    current.bounds = update.bounds
    return current;
  }

  let partners = current.partners;
  if(partners) {
    for(var i=0; i < partners.length; i++) {
      partners[i] = searchAndUpdate(partners[i], update);
    }
    current.partners = partners;
  }

  let children = current.children;
  if(children) {
    for(var i=0; i < children.length; i++) {
      children[i] = searchAndUpdate(children[i], update);
    }
    current.children = children;
  }

  return current;
}

export const searchNode = (current, rkey) => {
  if(current.rkey === rkey) {
    return current;
  }

  let partners = current.partners;
  if(partners) {
    for(var i=0; i < partners.length; i++) {
      var found = searchNode(partners[i], rkey);
      if(found) return found;
    }
  }

  let children = current.children;
  if(children) {
    for(var i=0; i < children.length; i++) {
      var found = searchNode(children[i], rkey);
      if(found) return found;
    }
  }

  return undefined;
}