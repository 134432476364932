import React, { Component } from 'react';

import helpers from '../helpers/index';
import NavTop from '../components/nav-top';
import StaffTable from '../components/sub-accounts/staff-table';

class SubAccountStaffPage extends Component {

  constructor(props) {
    super(props);
    this.state = {};

    this.fetchData = this.fetchData.bind(this);
    //TODO: check account type to make sure the user has proper permissions for this page
    // User must have:   admin === true && accountType === 'SUB-GROUP'
  }

  componentDidMount() {
    helpers.setSiteTitle('Staff');
    this.fetchData();
  }

  fetchData() {
    // TODO: make the api call for staff members
    // TODO: save the returned list to redux
  }

  render() {
    return (
      <React.Fragment>

        <NavTop history={this.props.history} />

        <section className="wow fadeIn section animated" style={{visibility: 'visible', animationName: 'fadeIn'}}>
          <div className="container">
            <StaffTable showFooter={false} showFilter={false} headerBtnCssClass="btn btn-light btn-xs" />
          </div>
        </section>


      </React.Fragment>
    );
  }
}

export default SubAccountStaffPage;
