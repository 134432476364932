export default class Smartdraw2_FamilyBuilder {

  old_family_nodes = {}
  profiles = {}
  individuals = {}
  proband_id = 0;

  constructor(old_family_nodes = {}, profiles = {}, proband_id) {
    this.old_family_nodes = old_family_nodes;
    this.profiles = profiles;
    this.proband_id = proband_id;
  }

  // fill this.individuals with all individuals
  build_individuals_list() {

    let old_family_nodes = this.old_family_nodes;

    for(var key in old_family_nodes) {
      let node = old_family_nodes[key];
      
      // feed to individuals store
      this.fillNodeData(node);
      if (!(node.id in this.individuals)) {
        this.individuals[node.id] = node;
      } else {
        let new_relatives_merged = this.merge_relatives(node, this.individuals[node.id]);
        if (!new_relatives_merged)
          continue;
      }

      // traverse down thru children and partners
      if (node.children.length > 0 ) {
        this.collect_individuals_recursive(node.children);
      }
      if (node.partners.length > 0 ) {
        this.collect_individuals_recursive(node.partners);
      }

    }

    // fix situations in which we face 2 different object instances of the same individual, e.g. one assigned in some place as a partner and other assigned as a child
    this.fix_individuals_references();
  }
  
  merge_relatives(from_ind, to_ind) {
    let existing_children = {};
    let children_were_added = false;
    
    let existing_partners = {};
    let partners_were_added = false;
    
    // collect current children
    for(var i=0; to_ind.children && i<to_ind.children.length; i++) {
      let child = to_ind.children[i];
      let childid = parseInt(child.rkey.replace('apimem-', ''));
      existing_children[childid] = true;
    }
    
    // collect current partners
    for(var i=0; to_ind.partners && i<to_ind.partners.length; i++) {
      let partner = to_ind.partners[i];
      let partnerid = parseInt(partner.rkey.replace('apimem-', ''));
      existing_partners[partnerid] = true;
    }
    
    // check if children from the new node should be added
    for(var i=0; from_ind.children && i<from_ind.children.length; i++) {
      let child = from_ind.children[i];
      let childid = parseInt(child.rkey.replace('apimem-', ''));
    
      if (!(childid in existing_children)) {
        if (!to_ind.children) to_ind.children = [];
        to_ind.children.push(child);
        children_were_added = true;
      }
    }
    
    // check if partners from the new node should be added
    for(var i=0; from_ind.partners && i<from_ind.partners.length; i++) {
      let partner = from_ind.partners[i];
      let partnerid = parseInt(partner.rkey.replace('apimem-', ''));
    
      if (!(partnerid in existing_partners)) {
        if (!to_ind.partners) to_ind.partners = [];
        to_ind.partners.push(partner);
        partners_were_added = true;
      }
    }
    
    return children_were_added || partners_were_added;
  }

  fix_individuals_references() {
    for(var id in this.individuals) {
      let node = this.individuals[id];

      if (node.children) {
        for(var i=0; i<node.children.length; i++) {
          let child = node.children[i];
          let childid = parseInt(child.rkey.replace('apimem-', ''));
          node.children[i] = this.individuals[childid];
        }
      }

      if (node.partners) {
        for(var j=0; j<node.partners.length; j++) {
          let partner = node.partners[j];
          let partnerid = parseInt(partner.rkey.replace('apimem-', ''));
          node.partners[j] = this.individuals[partnerid];
        }
      }

    }

  }

  collect_individuals_recursive(nodes = []) {
    
    for(var node of nodes) {
      
      // feed to individuals store
      this.fillNodeData(node);
      if (!(node.id in this.individuals)) {
        this.individuals[node.id] = node; 
      } else {
        let new_relatives_merged = this.merge_relatives(node, this.individuals[node.id]);
        if (!new_relatives_merged)
          continue;
      }

      // traverse down thru children and partners
      if (node.children && node.children.length > 0 ) {
        this.collect_individuals_recursive(node.children);
      }
      if (node.partners && node.partners.length > 0 ) {
        this.collect_individuals_recursive(node.partners);
      }

    }
  }

  // set node's id, father id and mother id
  fillNodeData(node) {
    if (!node.id) {
      node['id'] = parseInt(node.rkey.replace('apimem-', ''));
    }
    let profile = this.profiles[node.rkey];
    node['father_id'] = profile.father_id;
    node['mother_id'] = profile.mother_id;
    node.pos.direction = null;
  }


  build_family() {
    let node = null;
    // connect father and mother
    for(var id in this.individuals) {
      node = this.individuals[id];
      
      if (node['father_id'] > 0) {
        let father = this.individuals[node['father_id']];
        node['father'] = father;

        // fill father's children
        this.add_child_to_parent(father, node);
      }
      if (node['mother_id'] > 0) {
        let mother = this.individuals[node['mother_id']];
        node['mother'] = mother;

        // fill mother's children
        this.add_child_to_parent(mother, node);
      }
    }

    // fill the siblings lists for each individual
    for(var id in this.individuals) {
      node = this.individuals[id];
      node['siblings'] = {};
      node['full_siblings'] = {};
      node['half_siblings'] = {};
    }
    for(var id in this.individuals) {
      let parent = this.individuals[id];
      
      for(var child of parent.children) {
        
        for(var sibling of parent.children) {
          
          if (child.id != sibling.id) {
            if (!child['siblings']) {
              child['siblings'] = {};
            }
            (child['siblings'])[sibling.id] = sibling;
          }
        }

      }
    }
    // split into full-siblings and half-siblings
    for(var id in this.individuals) {
      node = this.individuals[id];
      let my_mother_id = node.mother_id; // FIXME: what if in the future we want to handle full-siblings in a situation where we allow drawing just on parent? in such case father_id or mother_id would not be filled. The code below would have to be modified to handle such cases. 
      let my_father_id = node.father_id;
      
      for(var sib_id in node.siblings) {
        let sibling = node.siblings[sib_id];
        if (sibling.father_id == my_father_id && sibling.mother_id == my_mother_id) {
          if (!node['full_siblings']) {
            node['full_siblings'] = {};
          }
          (node['full_siblings'])[sibling.id] = sibling;
        } else {
          if (!node['half_siblings']) {
            node['half_siblings'] = {};
          }
          (node['half_siblings'])[sibling.id] = sibling;
        }
      }
    }
    
    // fill the partner link if missing
    for(var id in this.individuals) {
      node = this.individuals[id];
      
      if(!node.partners) node.partners = [];

      node.partners.forEach(partner => {
        
        if(!partner.partners) partner.partners = [];
        
        let partner_exists = false;
        partner.partners.forEach(other_partner => {
          if (other_partner.id == node.id) {
            partner_exists = true;
          }
        });
        if (!partner_exists) {
          partner.partners.push(node)
        }
      });
    }
  }

  add_child_to_parent(parent, child_to_add) {
    let present_already = false;
    for(var child of parent.children) {
      if (child.id == child_to_add.id) {
        present_already = true;
      }
    }
    if (!present_already) {
      parent.children.push(child_to_add);
    }
  }

  get_proband_with_complete_family() {
    return this.individuals[this.proband_id];
  }

}