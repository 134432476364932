import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';

import Node_Line_Store from './Node_Line_Store';

import RelationshipDropdownMenu from './relationship-dropdown-menu';

import TwinTypeDropDownMenu from './twin-type-dropdown-menu';

export default memo(({ data, xPos, yPos, type, selected, isConnectable, sourcePosition, targetPosition }) => {

	data.datastore.addNode(data.id, Node_Line_Store.CONNECTOR, xPos, yPos);

  let showRelationshipDropdown = selected && data.nodesSelectedCount <= 1 && !data.isNodeDragging && data.nodeClickCount > 1 && data.isPartnerConnectorNode
  let showTwinTypeDropdown = selected && data.nodesSelectedCount <= 1 && data.nodeClickCount > 1 && data.isTwinConnectorNode

	let node_build = (
    <>
    <rect x={1} y={1} className='person-connector-node' />
    {showRelationshipDropdown && <RelationshipDropdownMenu showDropdown={showRelationshipDropdown} data={data}/>}
    {showTwinTypeDropdown && <TwinTypeDropDownMenu showDropdown={showTwinTypeDropdown} data={data}/>}
    </>
  );
  // let node_build = []

	return (
    <>
      <Handle type="target" isConnectable={isConnectable} />
      {node_build}
      <Handle type="source" isConnectable={isConnectable} />
    </>
  );
});
