import React, { Component } from 'react';
import PatientDiseaseView from './patient-disease-view';
import PatientGeneTestView from './patient-gene-test-view';
import MemberInfo from './member-info';
import NccnCriteria from '../patient-profile/family-history/nccn-criteria';
import NccnCriteriaText from '../patient-profile/family-history/nccn-criteria-text';

import ModalCriteriaSource from '../modal-criteria-source'

import ProgenyArchive from './progeny-archived-data/progeny-archive';

import { showPersonID } from './pedigree-constants';

import $ from 'jquery'
import { createUUID } from '../react-flow-pedigree/utils';

import ModalCopyLinkPedigree from '../modal-copy-link-pedigree.tsx';

class PedigreeSidebar extends Component {

  constructor(props) {
    super(props);
    this.memberInfoRef = React.createRef();

    this.state = {
      show_criteria_settings: false,
      show_create_or_link_pedigree_modal: false
    }

    this.handlePersonalInfoSave = this.handlePersonalInfoSave.bind(this);
    this.toggleShowCriteriaSettings = this.toggleShowCriteriaSettings.bind(this);
  }

  async handlePersonalInfoSave(data, updateSubtextMargin) {
    let pedigree_data = await this.props.onInfoAndHistoryUpdate(data);
    this.updateSubtextMargin(pedigree_data);
  }

  getMemberInfoAge(){
    return this.memberInfoRef.current.state.profile.age
  }

  toggleShowCriteriaSettings(){
    this.setState({ show_criteria_settings: !this.state.show_criteria_settings })
  }

  filterRiskCriteriaFromSettings(){
    let risk_factor = this.props.getPedigreeData().getRiskCriteria()

    const criteria_source_category_settings = [];
    for (let i=0; i<this.props.user.criteria_source_category_settings.length; i++) {
      const id = this.props.user.criteria_source_category_settings[i].criteria_source_category_id;
      criteria_source_category_settings.push(id);
    }

    risk_factor = risk_factor.filter(criteria => {
      for (let criteria_source_category_id of criteria.criteria_source_category_ids) {
        if (criteria_source_category_settings.includes(criteria_source_category_id)) {
          return true;
        }
      }
      return false;
    });

    return risk_factor
  }

  generateRiskFactorString(risk_factor){
    let risk_factor_string = risk_factor.clinician_risk_factors_main_text

    let criteria_source_category_string = '';

    for(let criteria_source_category_id of risk_factor.criteria_source_category_ids){
      let category_string = '';

      for (let z=0; z<this.props.user.criteria_source_categories.length; z++) {
        let cat_id = this.props.user.criteria_source_categories[z].id;
        if (criteria_source_category_id === cat_id) {
          category_string = this.props.user.criteria_source_categories[z].abbreviation;
          criteria_source_category_string += category_string + ', '
        }
      }
    }
    criteria_source_category_string = criteria_source_category_string.slice(0, -2)

    //add the parentheses
    criteria_source_category_string = `(${criteria_source_category_string})`

    risk_factor_string = risk_factor_string + " " + criteria_source_category_string

    return risk_factor_string;
  }

  updateSubtextMargin(pedigree_data){

    let subtextElement = document.getElementById(`domSubtext${this.props.node.data.profile.id}`)
    if(pedigree_data){
      // this is for updating the subtext node when clicked outside instead of another node,
      // this way, subtext would be updated when getting the width for calculating appropriate left value
      let data = pedigree_data.nodes.find(element => element.id == this.props.node.data.profile.id).data
      if(data){
        const subtext = showPersonID ? (data.subtext) ? data.profile.id + '\n' + data.subtext.data.label : data.profile.id : (data.subtext) ? data.subtext.data.label : null;
        subtextElement.childNodes[1] ? subtextElement.childNodes[1].innerText = subtext : subtextElement.childNodes[0].innerText = subtext
      }
    }

    let subTextNodeWidth = $(`#domSubtext${this.props.node.data.profile.id}`).width()
    if(subtextElement){

      let left = (110 - subTextNodeWidth) * (1/2)
      subtextElement.style.left = `${left}px`
    }
  }

  render() {
    const profile = this.props.node.data.profile;

    let sidebar_active = this.props.showSideBar ? 'active' : '';
    let close_button_style = this.props.showSideBar ? { display: 'block' } : { display: 'none' };

    let proband_last_name = "";
    let frag0 = null;
    let frag1 = null;
    let frag2 = null;
    let frag3 = null;
    let frag4 = null;
    let frag5 = null;
    let frag6 = null;
    let frag7 = null;
    if(profile && profile.is_proband) {
      proband_last_name = " " + profile.last_name;

      let list1 = [];
      let list2 = [];
      let list3 = [];
      let list4 = [];
      let list5 = [];
      let list6 = [];

      let list7 = [];
      let list7_category_text = null;

      let risk_factors = this.filterRiskCriteriaFromSettings();

      let len = risk_factors.length;
      for(let i=0; i<len; i++) {
        let risk_factor = risk_factors[i];

        let risk_factor_string = this.generateRiskFactorString(risk_factor)

        if(risk_factor.condition[0] === "Breast/Ovarian/Other") {
          list1.push(<li key={i}>
            {risk_factor_string}
          </li>);
        }

        if(risk_factor.condition[0] === "Breast/Ovarian/Colorectal") {
          list2.push(<li key={i}>
            {risk_factor_string}
          </li>);
        }

        if(risk_factor.condition[0] === "Breast/Ovarian") {
          list3.push(<li key={i}>
            {risk_factor_string}
          </li>);
        }

        if(risk_factor.condition[0] === "Colorectal/Other") {
          list4.push(<li key={i}>
            {risk_factor_string}
          </li>);
        }

        if(risk_factor.condition[0] === "Colorectal") {
          list5.push(<li key={i}>
            {risk_factor_string}
          </li>);
        }

        if(risk_factor.condition[0] === "Other") {
          list6.push(<li key={i}>
            {risk_factor_string}
          </li>);
        }

        if(risk_factor.condition[0] === "Gene") {
          list7_category_text = risk_factor.condition[1];
          list7.push(<li key={i}>
            {risk_factor_string}
          </li>);
        }
      }

      if(len === 0) {
        frag0 = <React.Fragment><p>Assessed criteria not met</p></React.Fragment>
      }

      if(list1.length > 0) {
        frag1 = (<NccnCriteria
                  header={"Breast/Ovarian/Other"}
                  criteria={list1}
                />);
      }

      if(list2.length > 0) {
        frag2 = (<NccnCriteria
                  header={"Breast/Ovarian/Colorectal"}
                  criteria={list2}
                />);
      }

      if(list3.length > 0) {
        frag3 = (<NccnCriteria
                  header={"Breast/Ovarian"}
                  criteria={list3}
                />);
      }

      if(list4.length > 0) {
      frag4 = (<NccnCriteria
                 header={"Colorectal/Other"}
                 criteria={list4}
               />);
      }

      if(list5.length > 0) {
        frag5 = (<NccnCriteria
                   header={"Colorectal"}
                   criteria={list5}
                 />);
      }

      if(list6.length > 0) {
        frag6 = (<NccnCriteria
                   header={"Other"}
                   criteria={list6}
                 />);
      }

      if(list7.length > 0) {
        frag7 = (<NccnCriteria
                  header={list7_category_text}
                  criteria={list7}
                />);
      }
    }
    //sidebar postion none and height 100% are the default when a user is not read only, update these
    //values to adjust so side bar takes up whole page if read only user
    let sidebarPostion = 'none'

    let member_info_component = null;
    if (this.props.readOnlyUser) {
      sidebarPostion = 'fixed';
      member_info_component = (
        <div className="panel-summary">
          {/* {profile && (
            <React.Fragment>
              <h4>{profile.first_name}{proband_last_name}</h4>
            </React.Fragment>)
          } */}

          {profile && (
            <React.Fragment>
              <p><span>Age: </span>{profile.age} y/o</p>
              <p><span>Deceased: </span>{profile.is_dead ? 'Yes' : 'No'}</p>
              {profile.is_dead &&
                <p><span>Cause of death: </span>{profile.cause_of_death && profile.cause_of_death.trim() == '' ? 'Unknown' : profile.cause_of_death}</p>
              }
              <p><span>Notes: </span>{profile.note === '' ? 'None' : profile.note}</p>
            </React.Fragment>)
          }

        </div>
      )
    } else {
      /*Using unique key to force component to re-render if props change*/
      if (this.props.node) {
        member_info_component = (
          <MemberInfo
            key={createUUID()}
            ref={this.memberInfoRef}
            getPedigreeData={this.props.getPedigreeData}
            probandId={this.props.probandId}
            node={this.props.node}
            onOk={(data, updateSubtextMargin) => this.handlePersonalInfoSave(data, updateSubtextMargin)}
            reRenderTopBarFunction={() => this.props.reRenderTopBarFunction()}
            updateSubtextMargin={() => this.updateSubtextMargin()}
            openShowCreateOrLinkPedigreeModal={() => this.setState({show_create_or_link_pedigree_modal: true})}
            history={this.props.history}
            reRenderPedigree={() => this.props.reRenderPedigree()}
            navigateToRecord={(member_id) => this.props.navigateToRecord(member_id)}
          />
        );
      }
    }

    const padding = 15; // this aligns it to the bottom of the pedigree toolbar
    const scrollPadding = 100; // this is accounting for a portion of the top three bars heights
    const top = this.props.readOnlyUser ? '0px' : document.body.clientHeight - this.props.dimensions.height - padding;
    const height = this.props.readOnlyUser ? '100%' :  this.props.dimensions.height - scrollPadding;

    const progeny_archive_data = this.props.getPedigreeData().getProgenyArchiveData();

    return (
      <React.Fragment>

        <nav id="sidebar" style={{position: sidebarPostion, right: '0px', top: `${top}px`, height: height}} className={sidebar_active}>

          <div className="dismiss" style={close_button_style} onClick={() => this.props.onCloseClick()} >
            <i className="fa fa-close"></i>
          </div>

          {this.state.show_criteria_settings && (
            <ModalCriteriaSource
              onCancel={() => this.toggleShowCriteriaSettings()}
              isOpen={this.state.show_criteria_settings} title="Criteria Source"
              getPedigreeData={this.props.getPedigreeData}
            />
          )}

          {this.state.show_create_or_link_pedigree_modal && <ModalCopyLinkPedigree
            title="Patient Record"
            onClose={() => this.setState({show_create_or_link_pedigree_modal: false})}
            member={this.props.node.data.profile}
            getPedigreeData={this.props.getPedigreeData}
            navigateToRecord={(member_id) => this.props.navigateToRecord(member_id)}
            reRenderPedigree={() => this.props.reRenderPedigree()}
            updateSubtextMargin={() => this.updateSubtextMargin()}
          />
          }

          <div className="sidebar-header">

            {member_info_component}

            <div className="row">
              <div className="col-md-12">

                {profile && profile.is_proband && <React.Fragment>
                  <hr className="margin-five" />
                  <div className="form-header-2">
                    <div className="row vcenter">
                      <div className="col-lg-12 ">
                        <h3 className="title">Criteria
                          <span className='i-tooltip-right-nccn'>
                            <i className="fa fa-info-circle"></i>
                              <NccnCriteriaText riskCriteriaLength={this.props.getPedigreeData().getRiskCriteria().length} />
                          </span>
                          <a onClick={() => this.toggleShowCriteriaSettings()}><i className="fa fa-cog"></i></a>
                        </h3>

                      </div>
                    </div>
                  </div>
                  <div className="criteria">
                    {frag0}
                    {frag1}
                    {frag2}
                    {frag3}
                    {frag4}
                    {frag5}
                    {frag6}
                    {frag7}
                  </div>
                  </React.Fragment>
                }

                <hr className="margin-five" />

                {profile &&
                  <PatientDiseaseView
                    sideBar={true}
                    patientRkey={profile.rkey}
                    profile={profile}
                    onDiseaseUpdate={(data) => this.props.onDiseaseUpdate(data)}
                    onDiseaseDelete={(data) => this.props.onDiseaseDelete(data)}
                    read_only={this.props.readOnlyUser}
                    getPedigreeData={this.props.getPedigreeData}
                    getMemberInfoAge={() => this.getMemberInfoAge()}
                    updateSubtextMargin={() => this.updateSubtextMargin()}
                  />
                }

                <hr className="margin-five" />

                {profile &&
                  <PatientGeneTestView
                    sideBar={true}
                    patientRkey={profile.rkey}
                    profile={profile}
                    onGeneTestUpdate={(data) => this.props.onGeneTestUpdate(data)}
                    onGeneTestDelete={(data) => this.props.onGeneTestDelete(data)}
                    read_only={this.props.readOnlyUser}
                    getPedigreeData={this.props.getPedigreeData}
                    updateSubtextMargin={() => this.updateSubtextMargin()}
                  />
                }

                <hr className="margin-five" />

                {(progeny_archive_data && !this.props.readOnlyUser) &&(
                  <ProgenyArchive
                    viewArchiveDataTable={this.props.viewProgenyArchiveTable}
                    progenyArchiveData={progeny_archive_data}
                    memberId={profile.id}
                    memberUid={profile.uuid_code}
                    getPedigreeData={this.props.getPedigreeData}
                  />
                )}

                <br /><br /><br />


              </div>
            </div>
          </div>
        </nav>
      </React.Fragment>
    )
  }
}

export default PedigreeSidebar;
