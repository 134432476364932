import React, { Component } from 'react'
import { connect } from "react-redux"

import { debounce } from 'lodash'

import { log_error } from '../../helpers/helper-error'

import ModalConfirmDelete from '../modal-confirm-delete'
import ModalDeceased from '../modal-deceased'

import ModalDiseasesForm from '../modal-diseases-form'
import ModalDiseasesView from '../modal-diseases-view'

import ModalGeneTestForm from '../modal-gene-test-form'
import ModalGeneTestView from '../modal-gene-test-view'

import ModalPopUpBlockerAlert from './../modal-popup-blocker-alert'

import * as helper_health_history from '../../helpers/helper-health-history'

import * as model from '../../model-states/m-health-history'
import * as helper_family_tree from '../../helpers/helper-family-tree'

import family_api from '../../api/family-api';

import disease_api from '../../api/disease-api';
import genetic_api from '../../api/genetic-testing-api';
import moment from 'moment'
import { radix_bases } from '../../helpers/helper-number-bases';
import {
  ageOptions,
  stringToAge,
  yearBirthToAgeString,
  updateYear,
  ageStringToAgeInt } from '../../helpers/age-utils'

class HealthInputs extends Component {

  constructor(props) {
    super(props)
    this.state = {

      loading_disease: false,
      loading_genetic: false,

      openModalConfirmDelete: false,
      modalConfirmDeleteHash: new Date().getTime(),

      openModalDeceased: false,
      modalModalDeceasedHash: new Date().getTime(),

      showModalDiseaseForm: false,
      showModalDiseaseView: false,

      showModalGeneTestForm: false,
      showModalGeneTestView: false,

      openCannotDeleteModal: ["none", false],

      profile: null

    }

    this.timer = null

    this.updateDeceasedAPI = debounce(this.updateDeceasedAPI, 250)
    this.updateNote = debounce(this.updateNote, 250)
    // this.getAgeData = this.getAgeData.bind(this);
    // this.normalizeAge = this.normalizeAge.bind(this);

  }

  componentDidUpdate(prevPros) {
    if (typeof (this.props.profile) !== 'undefined' && this.props.profile !== null && this.state.profile === null) {
      this.setState({ profile: this.props.profile })
      this.fetchMemberDiseases(this.props.profile)
      this.fetchMemberGeneticTestings(this.props.profile)
    }
  }

  async fetchMemberDiseases(profile) {
    try {
      this.setState({ loading_disease: true, errorMessages: [] })
      let data = await disease_api.get_member_id_diseases(profile.id)

      helper_health_history.saveHistoryDiseasesToRedux(this.props.dispatch, profile.rkey, data)

    } catch (error) {
      console.log(error)
      this.setState({ errorMessages: [error.message] })
    } finally {
      this.setState({ loading_disease: false })
    }
  }

  async fetchMemberGeneticTestings(profile) {
    try {
      this.setState({ loading_genetic: true, errorMessages: [] })
      let data = await genetic_api.get_members_id_genetic_testing(profile.id)
      helper_health_history.saveHistoryGenesToRedux(this.props.dispatch, profile.rkey, data)

    } catch (error) {
      console.log(error)
      this.setState({ errorMessages: [error.message] })
    } finally {
      this.setState({ loading_genetic: false })
    }
  }

  async onClickOpenModalConfirmDelete() {
    let canDelete = await this.handleCheckDelete();
    if (canDelete){
      this.setState({
        openModalConfirmDelete: true,
        modalConfirmDeleteHash: new Date().getTime()
      })
    }
  }

  onChange(field, value) {
    let ownerRkey = this.props.ownerRkey
    let history_detail = { [field]: value }
    helper_health_history.saveHistoryDetailToRedux(this.props.dispatch, ownerRkey, history_detail)
  }

  getValue(field) {
    let history = this.props.patient.history
    if (this.props.ownerRkey in history) {
      let history_detail = history[this.props.ownerRkey]
      return (field in history_detail) ? history_detail[field] : null
    }

    return null
  }

  saveDiseases(diseases) {
    let ownerRkey = this.props.ownerRkey
    helper_health_history.saveHistoryDiseasesToRedux(this.props.dispatch, ownerRkey, diseases)
    this.setState({ openModalDisease: false })
  }

  async onDeleteOk() {
    if ('onDeleteConfirmed' in this.props) {
      await this.props.onDeleteConfirmed()
    }
    this.setState({ openModalConfirmDelete: false })
  }

  showDeleteButton() {
    if ('canDelete' in this.props && this.props.canDelete == true) return true

    return false
  }

  hasDiseases() {
    let ownerRkey = this.props.ownerRkey
    let diseases = this.props.patient.history_diseases
    if (!(ownerRkey in diseases)) return false
    return diseases[ownerRkey].length > 0
  }

  hasGenes() {
    let ownerRkey = this.props.ownerRkey
    let genes = this.props.patient.history_genes
    if (!(ownerRkey in genes)) return false
    return genes[ownerRkey].length > 0
  }

  openModalDeceased() {
    this.setState({
      openModalDeceased: true,
      modalModalDeceasedHash: new Date().getTime(),
    })
  }

  onModalDeceasedSaved(data) {
    // let dob = this.getDeceasedData('dob');
    // let year_of_birth = data.year_of_birth

    // if(year_of_birth.trim() == '') {
    //   dob = null
    // } else {
    //   dob = year_of_birth + (dob ? moment(dob).format('-MM-DD') : '-1-1');
    // }

    this.saveDeceasedData({ 'age': data.age, 'cause_of_death': data.cause_of_death, yob: data.yob })
  }

  handleAddDisease() {
    this.setState({ showModalDiseaseForm: true })
  }

  handleViewDisease() {
    this.setState({ showModalDiseaseView: true })
  }

  handleAddGeneTest() {
    this.setState({ showModalGeneTestForm: true })
  }

  handleViewGeneTest() {
    this.setState({ showModalGeneTestView: true })
  }

  getDeceasedData(key) {
    if (this.state.profile == null) return null
    return this.state.profile[key]
  }

  onChangeDeceasedData(key, value) {
    let payload = { [key]: value };

    // Calculate yob from age
    if (!this.getDeceasedData('is_dead')) {
      if (key === 'age') {
        if (value !== '' && !isNaN(parseInt(value, radix_bases.base10))) {
          payload['yob'] = moment().year() - parseInt(value, radix_bases.base10);
        }
      }
    }

    this.saveDeceasedData(payload);

  }

  async saveDeceasedData(payload) {

    try {

      if (this.state.profile == null) return null

      if (Number(payload.age) > 130) return

      let profile = this.state.profile

      // Save to Redux
      let data = {
        rkey: profile.rkey,
        ...payload
      }
      helper_family_tree.saveProfileToRedux(this.props.dispatch, data)

      // Save to local state
      profile = { ...profile, ...payload }
      this.setState({ profile })

      // Save to API
      await this.updateDeceasedAPI(profile.id, payload)

    } catch (error) {
      this.props.onError(error)
    }

  }

  async updateDeceasedAPI(member_id, payload) {
    try {
      //Save to API
      await family_api.patch_member_memberid(member_id, payload)
    } catch (error) {
      throw error
    }

  }

  async onChangeNote(note) {
    try {
      let profile = { ...this.state.profile, note }
      this.setState({ profile })

      let payload = { rkey: this.props.profile.rkey, note }
      helper_family_tree.saveProfileToRedux(this.props.dispatch, payload)
      this.updateNote(note)
    } catch (error) {
      log_error(this.constructor.name, error)
    }
  }

  async updateNote(note) {
    try {
      this.setState({ errorMessages: [] })

      let member = this.props.profile;
      await family_api.patch_member_memberid(member.id, {
        note
      })
    } catch (error) {
      this.setState({ errorMessages: [error.message] })
    }
  }

  async handleCheckDelete() {
    try {
      let profile = this.props.profile;
      if (profile) {
        await family_api.check_delete_member_memberid(profile.id);
        return true;
      }
    } catch (error) {
      this.setState({ openCannotDeleteModal: [error.type, true] });
      return false;
    }
  }

  closeCannotDeleteModal() {
    this.setState({ openCannotDeleteModal: ["none", false] })
  }

  getAgeData(){
    let age = ''
    if (this.state.profile == null) return null
    age =  this.state.profile['age_string'] ? this.state.profile['age_string'] :  this.state.profile['age'] ? this.state.profile['age'] : ''  

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.normalizeAge(age)
      clearTimeout(this.timer);
    }, 900);

    return age
  }


  
  normalizeAge(age){
    let ageString = ''
    let convertedAge = ''
    if(age){
       let ageKey = ''
       let ageOption = age.replace(/\d+/g, '').trim();
       let ageInt = parseInt(age, radix_bases.base10)
       
       for(let key in ageOptions){
         if(ageOption.startsWith(ageOptions[key])){
           ageKey = key
         }
       }
       ageString = (ageInt && ageKey!== '') ?  `${ageInt}${ageKey}` : ageInt ? `${ageInt}` : ''
       convertedAge = stringToAge(ageInt, ageKey)
    }
    
    let payload = {
      ['age_string']: ageString,
      ['age']: convertedAge
    }
    this.saveDeceasedData(payload);
 }

  changeAgeData(value){

    let payload = { 
      ['age_string']: value,
      ['age']: value
    };


  // Calculate yob from age
  if (!this.getDeceasedData('is_dead')) {
      if (value !== '' && !isNaN(parseInt(value, radix_bases.base10))) {
        payload['yob'] = moment().year() - parseInt(value, radix_bases.base10);
      }

  }
  this.saveDeceasedData(payload);

}

  render() {

    return <React.Fragment>

      <td>

        {this.state.openModalDeceased &&
          <ModalDeceased
            patient={this.props.patient}
            profile={this.props.profile}
            yob={this.getDeceasedData('yob')}
            dob={this.getDeceasedData('dob')}
            age={this.getDeceasedData('age')}
            age_string={this.getDeceasedData('age_string')}
            cause_of_death={this.getDeceasedData('cause_of_death')}
            onChange={(field, value) => this.onChangeDeceasedData(field, value)}
            isOpen={this.state.openModalDeceased}
            onClose={() => this.setState({ openModalDeceased: false })}
            onOk={(data) => this.onModalDeceasedSaved(data)}
          />
        }

        {this.state.openModalConfirmDelete &&
          <ModalConfirmDelete
            errorMessages={this.props.errorMessages}
            loading={this.props.loading}
            isOpen={this.state.openModalConfirmDelete}
            onCancel={() => this.setState({ openModalConfirmDelete: false })}
            onOk={() => this.onDeleteOk()}
          />
        }

        {this.state.showModalDiseaseForm &&
          <ModalDiseasesForm
            title={'Add Disease'}
            patientRkey={this.props.ownerRkey}
            profile={this.props.profile}
            onClose={() => this.setState({ showModalDiseaseForm: false })}
            onCancel={() => this.setState({ showModalDiseaseForm: false })}
            onSave={(data) => this.setState({ showModalDiseaseForm: false })}
          />
        }

        {this.state.showModalDiseaseView &&
          <ModalDiseasesView
            title={'Diseases'}
            patientRkey={this.props.ownerRkey}
            profile={this.props.profile}
            onClose={() => this.setState({ showModalDiseaseView: false })}
          />
        }

        {this.state.showModalGeneTestForm &&
          <ModalGeneTestForm
            title={'Add Gene'}
            patientRkey={this.props.ownerRkey}
            profile={this.props.profile}
            onClose={() => this.setState({ showModalGeneTestForm: false })}
            onCancel={() => this.setState({ showModalGeneTestForm: false })}
            onSave={(data) => this.setState({ showModalGeneTestForm: false })}
          />
        }

        {this.state.showModalGeneTestView &&
          <ModalGeneTestView
            title={'Gene'}
            patientRkey={this.props.ownerRkey}
            profile={this.props.profile}
            onClose={() => this.setState({ showModalGeneTestView: false })}
          />
        }

        {this.state.openCannotDeleteModal[1] && (
          <ModalPopUpBlockerAlert
            loading={false}
            errorMessages={[]}
            title="Delete Details"
            message={this.state.openCannotDeleteModal[0] === "children" ? "This family member cannot be deleted because they have children. Please delete the children first." : "This family member cannot be deleted because they have partner(s). Please delete the partner(s) first."}
            isOpen={this.state.openCannotDeleteModal[1]}
            onOk={() => this.closeCannotDeleteModal()}
            person={(this.state.selectedNode) ? this.state.selectedNode.data.profile : null}
          />
        )}

        <label class="switch no-margin-bottom">
          <input
            onChange={(e) => {
              this.onChangeDeceasedData('is_dead', e.target.checked)
              if (e.target.checked) {
                this.openModalDeceased()
              }
            }}
            checked={this.getDeceasedData('is_dead')}
            type="checkbox" data-toggle="modal" />
          <span class="slider round"></span>
        </label>
      </td>
      <td>
        <input
          onChange={(e) => this.changeAgeData(e.target.value)}
          value={this.getAgeData()}
          type="text" class="form-control normal-input-text family-history__input-age" placeholder="0" />
      </td>
      <td>

        {this.hasDiseases() === false &&
          <button
            onClick={() => this.handleAddDisease()}
            className="btn btn-light btn-xs table-btn-add">
            <i className="fa fa-plus"></i> Add
          </button>
        }

        {this.hasDiseases() &&
          <button
            onClick={() => this.handleViewDisease()}
            type="button" className="btn btn-teal btn-xs table-btn-edit">
            <i class="fa fa-eye"></i> View
          </button>
        }

      </td>
      <td>

        {this.hasGenes() === false &&
          <button
            onClick={() => this.handleAddGeneTest()}
            className="btn btn-light btn-xs table-btn-add">
            <i className="fa fa-plus"></i> Add
          </button>
        }

        {this.hasGenes() &&
          <button
            onClick={() => this.handleViewGeneTest()}
            type="button" className="btn btn-teal btn-xs table-btn-edit">
            <i class="fa fa-eye"></i> View
          </button>
        }

      </td>
      <td>
        {/* <input
            onChange={(e) => this.onChangeDeceasedData('cause_of_death', e.target.value)}
            value={this.getDeceasedData('cause_of_death')}
            type="text" class="form-control normal-input-text" placeholder="" /> */}
        <input
          // onChange={(e) => this.onChangeDeceasedData('cause_of_death', e.target.value)}
          // value={this.state.note}
          onChange={(e) => this.onChangeNote(e.target.value)}
          value={this.state.profile && this.state.profile.note}
          type="text" class="form-control normal-input-text" placeholder="" />
      </td>

      <td>

        {this.showDeleteButton() &&
          <a onClick={() => this.onClickOpenModalConfirmDelete()}
            class="btn table-btn-add btn-xs"><i class="fa fa-trash"></i></a>
        }

        {/* { 'collapsible' in this.props && this.props.collapsible &&
          <a onClick={(e) => this.props.onCollapse(e)}
            className="float--right">
            { this.props.for != 'son_daughter' &&
              <i className={"fa fa-" + (this.props.collapsed ? "minus-square" : "plus-square")}></i> }

            { this.props.for == 'son_daughter' &&
              <i className={"fa fa-" + (this.props.collapsed ? "expand" : "compress")}></i> }

          </a>
        } */}

      </td>

    </React.Fragment>

  }
}


const redux_state = state => ({
  ...state
})

const redux_actions = dispatch => ({
  dispatch: (action) => dispatch(action)
})

export default connect(redux_state, redux_actions)(HealthInputs);
