import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import helpers from "../helpers/index";
import NavTop from "../components/nav-top";
import PatientDataTabs from "../components/patient-profile/patient-data-tabs";
import RiskAssessmentTabs from "../components/patient-profile/risk-assessment-tabs";
import FamilyHistoryTabs from "../components/patient-profile/family-history-tabs";
import PatientUpdates from "../components/patient-profile/patient-updates";

import ActivityIndicator from "../components/activity-indicator";
import route_helper from "../route-helper";

import * as helper_family_tree from "../helpers/helper-family-tree";
import * as helper_error from "../helpers/helper-error";
import notifications_api from "../api/notifications-api";
import { delay } from '../helpers';
import { filterNotificationUpdates } from "../helpers/helper-notifications";
import * as patient_actions from '../store/patient/actions';

import genetic_testing_api from '../api/genetic-testing-api'
import { initialize_genetic_testings } from '../store/static_list/actions'

class PatientProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPatientDataTab: false,
      showFamilyHistoryTab: false,
      showRiskAssesmentTab: false,
      showPatientUpdates: false,
      populating_proband: true,
      personalDataOpen: false,
      notifications: []
    };

    this.handlePDTabClicked = this.handlePDTabClicked.bind(this);
    this.handleFHTabClicked = this.handleFHTabClicked.bind(this);
    this.handleRATabClicked = this.handleRATabClicked.bind(this);
    this.handleNotificationAction = this.handleNotificationAction.bind(this);
    this.fetchNotificationsByPatient = this.fetchNotificationsByPatient.bind(this);
    this.fetchConnectionDate =  this.fetchConnectionDate.bind(this)
    this.fetchFamilyPedigreeNotes = this.fetchFamilyPedigreeNotes.bind(this);
    this.viewPatient = this.viewPatient.bind(this);
    this.bulkReadNotifications = this.bulkReadNotifications.bind(this);
  }

  async componentDidMount() {
    /****************************/
    // added this here otherwise it loads when you open genetic test view and
    // cause a refresh flash
    try {
      let data = await genetic_testing_api.get_genetic_testing();
      const genetic_testings = data.sort((a, b) => {
        if(a.gene < b.gene) return -1;
        if(a.gene > b.gene) return 1;
        return 0;
      });
      this.props.dispatch(initialize_genetic_testings(genetic_testings));
    }
    catch(e){ console.log(e.message); }
    /****************************/

    try {
      helpers.setSiteTitle("Patient Profile");

      await this.setState({ populating_proband: true });

      /* Build proband data */
      await this.fetchProbandTree();
      await helpers.delay(0.5);

      if (this.props.location.hash === "#family-history" || this.props.location.hash === "") {
        this.setState({showFamilyHistoryTab: true });
      } else if (this.props.location.hash == "#patient-updates") {
        this.setState({ showPatientUpdates: true });
      }

      // Initial check
      this.fetchNotificationsByPatient();

    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ populating_proband: false });
    }
  }

  componentWillUnmount() {
    window.localStorage.removeItem("famgenix_latestcenter")
    window.localStorage.removeItem("selectedNode");
    window.localStorage.removeItem("famgenix_legend_center");
    window.localStorage.removeItem("refreshDoneOriginalToFlux")
    window.localStorage.removeItem("refreshDoneFluxToOriginal")
  }

  handlePDTabClicked(event) {
    this.setState({
      showPatientDataTab: true,
      showFamilyHistoryTab: false,
      showRiskAssesmentTab: false,
      showPatientUpdates: false,
    });
  }

  handleFHTabClicked(event) {
    this.setState({
      showPatientDataTab: false,
      showFamilyHistoryTab: true,
      showRiskAssesmentTab: false,
      showPatientUpdates: false,
    });
  }

  handlePatientUpdatesClick(event) {
    this.setState({
      showPatientDataTab: false,
      showFamilyHistoryTab: false,
      showRiskAssesmentTab: false,
      showPatientUpdates: true,
    });
  }

  handleRATabClicked(event) {
    this.setState({
      showPatientDataTab: false,
      showFamilyHistoryTab: false,
      showRiskAssesmentTab: true,
      showPatientUpdates: false,
    });
  }

  async fetchNotificationsByPatient() {
    let user = this.props.session.user;
    let proband = this.props.patient.patient;
    try {
      let { id, uuid } = proband;

      // when redux is resetting, skip notification calls
      if(id == null || uuid == null) return;

      let notifications = await notifications_api.post_clinician_notification(
        user.clinician_id,
        proband.id,
        proband.uuid
      );
      this.setState({ notifications });

      let pending_notis = filterNotificationUpdates(notifications, 'pending');
      this.markAllPendingAsRead(pending_notis);

    } catch (error) {
      helper_error.log_error(error);
    }
  }

  async fetchHiddenDiseaseColors(dispatch, proband_id, clinician_id, organization_id){
    await helper_family_tree.fetchFamilyTreeHiddenDiseaseColors(dispatch, clinician_id, proband_id, organization_id)
  }

  async fetchFamilyPedigreeNotes(dispatch, family_id){
    await helper_family_tree.fetchFamilyTreePedigreeNotes(dispatch, family_id)
  }

  async fetchProbandTree(dispatch, memberid, forAddParent, fetchHiddenDiseaseColors, clinician_id, organization_id, fetchFamilyPedigreeNotes) {
    if (forAddParent){
      var patient_tree = await helper_family_tree.populateProband(
        dispatch,
        memberid
      );
      var proband = patient_tree.proband;
      await fetchHiddenDiseaseColors(dispatch, proband.id, clinician_id, organization_id)
      await fetchFamilyPedigreeNotes(dispatch, proband.family_id)
    }
    else{
      var patient_tree = await helper_family_tree.populateProband(
        this.props.dispatch,
        this.props.match.params.member_id
      );
      var proband = patient_tree.proband;
      await this.fetchHiddenDiseaseColors(this.props.dispatch, proband.id, this.props.session.user.clinician_id, this.props.session.user.organization_id)
      await this.fetchFamilyPedigreeNotes(this.props.dispatch, proband.family_id)
      this.fetchConnectionDate(proband);
    }
  }

  async fetchConnectionDate(proband) {
    try {
      let user = this.props.session.user;
      let connection_date = await notifications_api.get_connection_date(user.clinician_id, proband.family_id);
      this.props.dispatch(patient_actions.save_patient({...proband, connection_date}));
    } catch (error) {
      console.log(error);
    }
  }

  async handleNotificationAction() {
    try {
      await this.fetchNotificationsByPatient();
      await this.fetchProbandTree();
    } catch (error) {
      console.log(error);
    }
  }

  async markAllPendingAsRead(pending_notifications) {
    if(pending_notifications.length >0){
      try {
        let { clinician_id } = this.props.session.user;
        if(pending_notifications) this.bulkReadNotifications(pending_notifications, clinician_id)
      } catch (error) {
        console.log(error);
      }
    }
  }

  async bulkReadNotifications(pendingNotifications, clinician_id){
    try{
      await notifications_api.post_notification_bulk_read(pendingNotifications, clinician_id)
    }catch(error){
      console.log(error)
    }
  }

  async setNotificationAsRead(notification_id, clinician_id) {
    try {
      await notifications_api.post_notification_notificationid_read(notification_id, clinician_id);
    } catch (error) {
      console.log(error);
    }
  }

  viewPatient() {
    let url = route_helper.pedigree.pedigree_root.replace(":member_id", this.props.patient.patient.id) + "/#family-history";
    this.props.history.push(url);
  }

  render() {
    const pd_tab_active = this.state.showPatientDataTab ? "active" : "";
    const fh_tab_active = this.state.showFamilyHistoryTab ? "active" : "";
    const ra_tab_active = this.state.showRiskAssesmentTab ? "active" : "";

    const proband = this.props.patient.patient;
    let gender = 'Unknown';
    if(proband.gender && proband.gender.toLowerCase() === 'm') {
      gender = 'Male';
    } else if(proband.gender && proband.gender.toLowerCase() === 'f') {
      gender = 'Female';
    }
    let gender_identity = null;
    if (proband.gender_identity == 'female') {
      gender_identity = 'Female';
    } else if (proband.gender_identity == 'male') {
      gender_identity = 'Male';
    } else if (proband.gender_identity == 'non-binary') {
      gender_identity = 'Non-Binary';
    }
    let assigned_birth = null;
    if (gender == 'Male') {
      assigned_birth = '(AMAB)';
    } else if (gender == 'Female') {
      assigned_birth = '(AFAB)';
    } else if (gender == 'Unknown') {
      assigned_birth = '(AUAB)';
    }

    let { notifications } = this.state;
    let notification_updates = filterNotificationUpdates(notifications);
    let pending_notis = filterNotificationUpdates(notifications, 'pending');
    let paddingTop = this.props.session.user.read_only == false ? '50px' : '0px';
    return (
      <React.Fragment>
        <NavTop history={this.props.history} onPedigree={true} personalDataOpen={this.state.personalDataOpen}/>

        {this.state.populating_proband == true && (
          <ActivityIndicator loading={true} pedigree={true} />
        )}

        {this.state.populating_proband == false && (
          <section style={{paddingTop: paddingTop}} className="wow fadeIn wrap animated">
            {this.props.session.user.read_only == false && (
              <div className="patient-nav">
                <div className="container-fluid">
                <div className="row">
                  <div className="col-md-3">
                    <div className="patient-details">
                      <p>
                        <span className="name">{proband.first_name} {proband.last_name}</span>{" "}
                        <span className="number">#{proband.patient_id}: </span>{" "}
                        <span>{(gender_identity && gender_identity !== gender) ? gender_identity : gender} {(gender_identity && gender_identity !== gender) ? assigned_birth : ""}, {proband.age}</span>
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 nav--center">
                    <ul className="nav nav-pills center-pills">
                      <li className={pd_tab_active}>
                        <button
                          className="btn-link"
                          onClick={this.handlePDTabClicked}
                        >
                          Patient Data
                        </button>
                      </li>
                      <li className={fh_tab_active}>
                        <button
                          id="pedigree_link"
                          className="btn-link"
                          onClick={() => this.handleFHTabClicked()}
                        >
                          Family History
                        </button>
                      </li>
                      <li className={ra_tab_active}>
                        <button
                          className="btn-link"
                          onClick={this.handleRATabClicked}
                        >
                          Risk Assessment
                        </button>
                      </li>
                    </ul>
                  </div>

                  <div className="col-md-3">
                    <div className="patient-details center-pills">
                      <p className="pr-10">
                        <Link to="#patient-updates" className="patient-details--update" onClick={(e) => this.handlePatientUpdatesClick(e)}>
                          Updates&nbsp;
                          {pending_notis.length > 0 && (
                          <span className="badge badge-success name">
                            {pending_notis.length}
                          </span>
                          )}
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>

                </div>
              </div>
            )}
            {this.props.session.user.read_only == true && (
              <div>
                <div className="patient-details-emr">
                  <p>
                    <span className="name">{proband.first_name} {proband.last_name}, </span>{" "}
                    <span>{proband.age}</span>
                  </p>
                </div>
              </div>
             )}

            {this.state.showPatientDataTab &&
              <PatientDataTabs
                dispatch={this.props.dispatch}
                session={this.props.session}
                proband_id={this.props.patient.patient.id}
              />
            }

            {this.state.showFamilyHistoryTab && this.props.patient.patient.id != null && (
              <FamilyHistoryTabs
                patient={this.props.patient}
                dispatch={this.props.dispatch}
                memberid={this.props.match.params.member_id}
                fetchProbandTree={this.fetchProbandTree}
                fetchHiddenDiseaseColors={this.fetchHiddenDiseaseColors}
                fethPedigreeNotes={this.fetchFamilyPedigreeNotes}
                session={this.props.session}
                read_only={this.props.session.user.read_only}
                organization_id={this.props.session.user.organization_id}
                clinician_id={this.props.session.user.clinician_id}
              />
            )}

            {this.state.showRiskAssesmentTab && <RiskAssessmentTabs session={this.props.session}/>}

            {this.state.showPatientUpdates && <PatientUpdates onNotificationAction={this.handleNotificationAction} notificationUpdates={notification_updates} />}

          </section>
        )}


      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {...state};
};

export default connect(mapStateToProps)(PatientProfilePage);
