import React, { Component } from 'react';
import InputGene from './input-gene';
import InputAndOr from './input-andor';
import { fromJS, remove, updateIn } from 'immutable'
import lodash from 'lodash'
import { createUUID } from '../react-flow-pedigree/utils';

let filterItem = { andOr: 'and', gene: '', result: 'any' , Variant: ''}
// let filterSet = [filterItem]
let filterSet = {andOr: 'and', items: [filterItem]}
/*
| - - - - - - - - - - - - - - -
| Data Structure
| - - - - - - - - - - - - - - -

  filterSets = [
    {
      andOr: 'and',
      items: [
        { andOr: 'and', gene: '', result: '' },
        { andOr: 'and', gene: '', result: '' }
      ],
    },
    {
      andOr: 'and',
      items: [
        { andOr: 'and', gene: '', result: '' }
      ],
    },
  ]

| - - - - - - - - - - - - - - -
*/

class FilterGene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterSets: []
    }
    this.handleOpenClick = this.handleOpenClick.bind(this)
    this.handleAddSet = this.handleAddSet.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.loadReportSetFilters = this.loadReportSetFilters.bind(this)
  }

  componentDidMount() {
    this.handleAddSet()
  }

  loadReportSetFilters(filterSets){
    if(filterSets.gene_filters.length === 0){
      let clean = [filterSet]
      this.setState({ filterSets: clean })
    }
    else{
      let newFilterSets = filterSets.gene_filters;
      this.setState({ filterSets: newFilterSets })
    }
  }

  isActive() {
    let clean = [filterSet]
    let filterSets = this.state.filterSets
    return lodash.isEqual(clean, filterSets) === false
  }

  handleOpenClick() {
    if (this.props.open) {
      this.props.onOpen('')
      this.props.handleIsActiveChange('gene', this.isActive());
      this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets));
      this.props.changeGeneFilters(lodash.cloneDeep(this.state.filterSets), this.isActive())
    }else {
      this.props.onOpen('gene')
      this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets));
    }
  }

  handleAddSet() {
    let filterSets = this.state.filterSets
    let newFilterSet = Object.assign({}, filterSet)
    filterSets.push(newFilterSet)
    this.setState({filterSets: filterSets}, () => this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets)));
  }

  handleAddItem(selectedSetIndex) {
    let filterSets = this.state.filterSets
    filterSets = fromJS(filterSets)
    filterSets = updateIn(filterSets, [selectedSetIndex, 'items'], items => items.push(filterItem))
    filterSets = filterSets.toJS()
    this.setState({filterSets }, () => this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets)))
  }

  handleDeleteItem(setIndex, itemIndex) {
    let filterSets = this.state.filterSets
    filterSets = fromJS(filterSets)

    // Remove item from set
    filterSets = updateIn(filterSets, [setIndex, 'items'], items => remove(items, itemIndex))

    // Remove empty set
    if(filterSets.get(setIndex).get('items').size == 0) filterSets = remove(filterSets, setIndex)

    filterSets = filterSets.toJS()
    this.setState({filterSets }, () => this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets)))

    if(setIndex === 0 && itemIndex === 0 && filterSets.length === 0) {
      this.handleFilterChange(setIndex, itemIndex, filterItem)
    }
  }

  handleFilterChange(setIndex, itemIndex, item) {
    let filterSets = this.state.filterSets
    filterSets = fromJS(filterSets)
    filterSets = updateIn(filterSets, [setIndex, 'items'], items => updateIn(items, [itemIndex], _ => item))
    filterSets = filterSets.toJS()
    this.setState({filterSets: filterSets }, () => this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets)))
  }

  handleChangeSet(andOr, setIndex) {
    let filterSets = this.state.filterSets
    filterSets = fromJS(filterSets)
    filterSets = updateIn(filterSets, [setIndex, 'andOr'], _ => andOr)
    filterSets = filterSets.toJS()
    this.setState({filterSets: filterSets }, () => this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets)))
  }


  render() {
    let btnClass = this.isActive() ? 'btn-purple' : 'btn-light-outline';

    let showDeleteButtonOnSetOne = false;

    if(this.state.filterSets.length === 1 && this.isActive()){
      showDeleteButtonOnSetOne = true;
    }

    return (
      <React.Fragment >
        <div className="popover-query-wrapper">

          <button
            onClick={this.handleOpenClick}
            className={"btn btn-xs " + btnClass} >Genetic Testing</button>

          <div id="genePop"
            style={{ display: (this.props.open ? 'block' : 'none') }}
            className="popover popover-wide popover-x popover-default popover-custom kv-popover-active in bottom popover-query">

            <div className="arrow popover-query__arrow"></div>
            <h5 className="popover-header popover-title">
                <span
                  onClick={this.handleOpenClick}
                  className="close pull-right">&times;</span>Genetic Testing Filter </h5>
            <div className="popover-body popover-content">

                {this.state.filterSets.map((set, setIndex) => {
                  return <FilterSet
                          key={createUUID()}
                          set={set}
                          totalSets={this.state.filterSets.length}
                          setIndex={setIndex}
                          showDeleteButtonOnSetOne={showDeleteButtonOnSetOne}
                          onChangeSet={(andOr) => this.handleChangeSet(andOr, setIndex)}
                          onChangeItem={(item, itemIndex) => this.handleFilterChange(setIndex, itemIndex, item)}
                          onAddItemClick={() => this.handleAddItem(setIndex)}
                          onDeleteItemClick={(itemIndex) => this.handleDeleteItem(setIndex, itemIndex)}
                        />
                })}

                <div className="margin-two">
                  <a onClick={this.handleAddSet} className="btn btn-teal btn-xs">Add Set</a>
                </div>

            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}


/*
Functional Components
*/

const FilterSet = (props) => {
  return (
    <React.Fragment>

      {props.setIndex > 0 &&
        <div className="row margin-two">
          <InputAndOr
            value={props.set.andOr}
            onChange={(e) => props.onChangeSet(e.target.value) }
          />
        </div>
      }

      <div className="panel-form-3">

        {props.set.items.map((item, itemIndex) => {
          const showDeleteButton = () => {
            if(itemIndex === 0 && props.setIndex === 0 && props.totalSets === 1){
              return props.showDeleteButtonOnSetOne;
            }
            else{
              return props.set.items.length > 1 || props.totalSets > 1
            }
          }
          return (
            <div key={createUUID()} className={"row margin-one " + (itemIndex == 0 ? 'padding-left--15' : '')}>

              <InputGene
                key={createUUID()}
                item={item}
                itemIndex={itemIndex}
                setIndex={props.setIndex}
                onAddItemClick={() => props.onAddItemClick()}
                onDeleteItemClick={() => props.onDeleteItemClick(itemIndex)}
                onChange={(data) => props.onChangeItem(data, itemIndex)}
                showDeleteButton={showDeleteButton()}
                showAddButton={itemIndex == props.set.items.length - 1}
              />

            </div>
          )

        })}

      </div>

    </React.Fragment>
  )
}

/* Functional Components */


export default FilterGene;
