import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux"

import helpers from '../../../helpers/index';
import helper_sidebar from '../../../helpers/helper-family-history-sidebar'
import * as helper_family_tree from '../../../helpers/helper-family-tree'
import family_api from '../../../api/family-api';
import ErrorSummary from '../../error-summary';
import moment from 'moment';
import { radix_bases } from '../../../helpers/helper-number-bases';
import disease_api from '../../../api/disease-api'
import settings from "../../../configs/settings";
import * as patient_actions from '../../../store/patient/actions';
import Select from "react-select";
import { cloneDeep } from "lodash";

class MemberPersonalInfo extends Component {

  constructor(props) {
    super(props)

    this.state = {
      profile: null,
      enablePregnancy: true,
      adopted: null,
      canDelete: true,
      showMore: false,
      genderError: false,
      errorMessages: [],
    };

    this.pending_changes = false;

    this.handleOkClick = this.handleOkClick.bind(this);
    this.handleProfileChange = this.handleProfileChange.bind(this);
    this.toggleShowMore = this.toggleShowMore.bind(this);

    this.getYearOfBirth = this.getYearOfBirth.bind(this);
    this.getProfileValue = this.getProfileValue.bind(this);
    this.validate = this.validate.bind(this);
    this.saveParentAdopted = this.saveParentAdopted.bind(this);
    this.onChange = this.onChange.bind(this);
    this.enablePregnancy = this.enablePregnancy.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.autoSaveProfile = this.autoSaveProfile.bind(this);
    this.setPendingChanges = this.setPendingChanges.bind(this);
    this.getPendingChanges = this.getPendingChanges.bind(this);
  }

  componentDidMount() {
    let profile = cloneDeep(helper_sidebar.getProfile(this.props.patient, this.props.person));
    if (profile) {
      profile.yob = this.getYearOfBirth(profile);
      let enablePregnancy = this.enablePregnancy();

      let adopted = null;
      if(profile.adopted_in) {
        adopted = settings.adopted_status_options[0].value;
      } else if (profile.adopted_out) {
        adopted = settings.adopted_status_options[1].value;
      }

      this.setState({profile, adopted, enablePregnancy});
    }

    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside(event) {
    const domNode = ReactDOM.findDOMNode(this);
    if(!domNode || !domNode.contains(event.target)) {
      // save profile data only if anything has changed
      this.autoSaveProfile();
    }
  }

  autoSaveProfile() {
    // auto save profile data only if anything has changed
    if (this.getPendingChanges()) {
      this.handleOkClick();
    }
  }

  setPendingChanges(pending_changes) {
    this.pending_changes = pending_changes;
  }

  getPendingChanges() {
    return this.pending_changes;
  }

  getYearOfBirth(profile) {
    if(profile.yob !== null) return profile.yob

    if(profile.dob !== null) return moment(this.props.dob).year()

    if(!profile.is_dead) {
      if(profile.age !== null || profile.age != '0') {
        let age = parseInt(profile.age, radix_bases.base10)
        let current_year = moment().year()
        return current_year - age
      }
    }

    return ''
  }

  getProfileValue(field) {
    if(this.state.profile && field in this.state.profile) {
      return this.state.profile[field]
    }

    return ''
  }

  handleProfileChange(e) {
    let profile = cloneDeep(this.state.profile);
    if (profile) {
      let node_type = this.props.person.node_type;

      if(e.target.type == 'checkbox') {
        profile[e.target.name] = e.target.checked
      } else {
        profile[e.target.name] = e.target.value
      }

      if(e.target.name === 'is_dead' && e.target.checked === false) {
        if (this.props.person.data.profile.id === this.props.patient.patient.id) {
          profile['age'] = moment().diff(profile['dob'], "years");
        } else {
          profile['age'] = moment().year() - parseInt(profile['yob'], radix_bases.base10);
        }
      }

      // Calculate age from yob
      if(e.target.name == 'yob') {
        let yob =  e.target.value;

        // Check and make sure age + yob is not greater than current year
        if(this.getProfileValue('is_dead')) {
          let age = profile['age'];
          if(yob !== '') {
            let year = moment().year();
            if(parseInt(age, radix_bases.base10) + parseInt(yob, radix_bases.base10) > parseInt(year, radix_bases.base10)) profile['age'] = '';
          }

        } else {

          if(yob !== '' && yob.length == 4) {
            let age = moment().year() - parseInt(yob, radix_bases.base10)
            if(age > 0) profile['age'] = age
          }
        }
      }

      // Calculate yob (and dob if proband) from age
      if(e.target.name == 'age') {
        let age =  e.target.value;

        // Check and make sure age + yob is not greater than current year
        if(this.getProfileValue('is_dead')) {
          let yob = profile['yob'];
          if(age !== '') {
            let year = moment().year();
            if(parseInt(age, radix_bases.base10) + parseInt(yob, radix_bases.base10) > parseInt(year, radix_bases.base10)) {
              profile['dob'] = '';
              profile['yob'] = '';
            }
          }

        } else {

          if(age !== '' && !isNaN(parseInt(age, radix_bases.base10))) {
            let year = moment().year() - parseInt(age, radix_bases.base10);
            let dob = profile['dob']
            if(dob !== undefined && dob !== null && dob !== "") {
              if(moment().dayOfYear() < moment(dob).dayOfYear()) year -= 1;
              profile['dob'] = year.toString() + moment(dob).format('-MM-DD');
            }
            profile['yob'] = year;
          }
        }
      }

      // Calculate age from dob
      if(e.target.name == 'dob') {
        let dob =  e.target.value;

        // Check and make sure age + yob is not greater than current year
        if(this.getProfileValue('is_dead')) {
          let age = profile['age'];
          if(dob !== '') {
            let year = moment().year();
            let yob = year - moment().diff(dob, 'years');
            if(parseInt(age, radix_bases.base10) + parseInt(yob, radix_bases.base10) > parseInt(year, radix_bases.base10)) {
              profile['age'] = '';
              profile['yob'] = moment(dob).year();
            }
          }

        } else {

          if(dob !== '') {
            let age = moment().diff(dob, "years");
            if(age >= 0) profile['age'] = age;
            profile['yob'] = moment(dob).year();
          }
        }
      }

      this.setPendingChanges(true);
      this.setState({profile});
    }
  }

  async handleOkClick() {
    try {
      this.setState({ errorMessages: [] });
      await this.validate();

      // Build payload and save to API
      let gender = this.state.profile.gender ? this.state.profile.gender.toLowerCase() : null;
      if(gender === 'u') gender = null;

      let adopted_in = false;
      let adopted_out = false;
      let adopted = this.state.adopted;
      if(adopted != null) {
        adopted_in = adopted === settings.adopted_status_options[0].value;
        adopted_out = adopted === settings.adopted_status_options[1].value;
      }

      let payload = {
        first_name: this.state.profile.first_name,
        age: this.state.profile.age,
        is_dead: this.state.profile.is_dead,
        cause_of_death: this.state.profile.cause_of_death,
        note: this.state.profile.note,
        adopted_in: adopted_in,
        adopted_out: adopted_out,
        gender: gender,
        carrier: this.state.profile.carrier,
        pregnancy: this.state.profile.pregnancy
      };

      if(this.state.profile.id == this.props.memberid){
        let payloadPatient = { dataKey: 'gender', data: gender };
        this.props.dispatch(patient_actions.save_patient_data(payloadPatient));
      }

      if(this.props.person.data.profile.id === this.props.patient.patient.id) {
        payload['last_name'] = this.state.profile.last_name;
        payload['dob'] = this.state.profile.dob;
      }
      payload['yob'] = this.state.profile.yob;

      let fields = ['yob', 'dob', 'age'];
      for(let field of fields) {
        if(payload[field] === '' || payload[field] === undefined) {
          payload[field] = null;
        }
      }

      if(payload['dob'] === null && payload['age'] === null) {
        payload['yob'] = null;
      }

      helper_family_tree.saveProfileToRedux(this.props.dispatch, this.state.profile)
      if(this.props.person.data.profile.id === this.props.patient.patient.id) helper_family_tree.savePatientToRedux(this.props.dispatch, this.state.profile);

      await family_api.patch_member_memberid(this.state.profile.id, payload)

      this.setPendingChanges(false);
      await this.saveParentAdopted(this.state.profile);

      let data = Object.assign({}, this.state)
      this.props.onOk(data)
    } catch (error) {
      helpers.logger(error)
      this.setState({ errorMessages: [error.message] })
    }
  }

  async validate() {
    let profile = this.state.profile;
    if (profile) {
      let node_type = this.props.person.node_type;

      let age = this.state.profile.age;
      let diseases = await disease_api.get_member_id_diseases(profile.id)

      for(let disease of diseases) {
        if(age !== null && age !== '' && age !== undefined && parseInt(age, radix_bases.base10) < parseInt(disease.age_diagnosed, radix_bases.base10)) throw new Error('The current age/age at death cannot be less than the age of diagnosis entered for this person’s disease.')
      }

      if(parseInt(age, radix_bases.base10) > 130) throw new Error('Age must be less than 130.')

      if(profile.relationship_data){
        let children = await family_api.get_members_memberid_children(profile.relationship_data.mother_id, profile.relationship_data.father_id)
        let childrenWithYob = children.filter(child => (child.yob !== null && child.yob !== undefined && !isNaN(child.yob)))
        if(childrenWithYob.length !== 0){
          if(profile.is_dead && profile.yob && profile.age){
            let highestYobAmongChildren = childrenWithYob.sort((a, b) => Number(b.yob) - Number(a.yob));
            highestYobAmongChildren = highestYobAmongChildren[0]
            if(Number(profile.age) + Number(profile.yob) < Number(highestYobAmongChildren.yob)){
              throw new Error("Deceased date is before child/children's date of birth.")
            }
          }
        }
      }

      if(profile.mother_id !== undefined){
        let parents = await family_api.get_member_memberid_parents(profile.id)
        if(parents.mother.is_dead && parents.mother.yob && parents.mother.age && profile.yob){
          if(Number(profile.yob) > Number(parents.mother.yob) + Number(parents.mother.age)){
            throw new Error("Birth date is after mother's deceased date.")
          }
        }
      }

      //needed: is_dead, age, yob of current node
      //                 age and yob of children
      //profile.mother_id is undefined if current node has no mother

      // Validate YOB
      if(this.props.person.data.profile.id !== this.props.patient.patient.id) {
        let yob = profile.yob
        if(yob !== null && yob !== undefined && yob !== '' && !isNaN(parseInt(yob, radix_bases.base10))) {
          yob = yob.toString().trim();
          if(yob && yob.length !== 4) {
            throw new Error('Invalid year of birth (year must be 4 characters).');
          }

          if(parseInt(yob, radix_bases.base10) > moment().year()) {
            throw new Error('Invalid year of birth (future year is not allowed).');
          }
        }
      }

      if(this.state.profile.note && this.state.profile.note.length > 1000){
        throw new Error('Notes cannot contain more than 1000 characters')
      }
    }
  }

  async saveParentAdopted(profile) {
    try {
      let patient = this.props.patient.patient;
      let { father, mother} = patient;
      let payload = {
        rkey: patient.rkey
      }

      if(profile.id == father.id) {
        payload = {...payload, father_adopted: profile.adopted_out};
        helper_family_tree.saveProfileToRedux(this.props.dispatch, payload);
        helper_family_tree.savePatientToRedux(this.props.dispatch, payload);
        await family_api.patch_member_memberid(patient.id, payload);
      }

      if(profile.id == mother.id) {
        payload = {...payload, mother_adopted: profile.adopted_out};
        helper_family_tree.saveProfileToRedux(this.props.dispatch, payload);
        helper_family_tree.savePatientToRedux(this.props.dispatch, payload);
        await family_api.patch_member_memberid(patient.id, payload);
      }

      if(profile.id == patient.id) {
        payload = {...payload, adopted_out: profile.adopted_out};
        helper_family_tree.saveProfileToRedux(this.props.dispatch, payload);
        helper_family_tree.savePatientToRedux(this.props.dispatch, payload);
        await family_api.patch_member_memberid(patient.id, payload);
      }

    } catch (err) {
      console.log(err)
    }
  }

  onChange(key, value) {
    let profile = cloneDeep(this.state.profile);
    if (profile) {
      if (key === 'adopted') {
        const adopted_in = value === settings.adopted_status_options[0].value;
        const adopted_out = value === settings.adopted_status_options[1].value;
        profile['adopted_in'] = adopted_in;
        profile['adopted_out'] = adopted_out;
        this.setState({ profile: profile, [key]: value });
      } else {
        profile[key] = value;
        this.setState({ profile: profile });
      }
      this.setPendingChanges(true);
    }
  }

  async handleDeleteClick() {
    let canDelete = await this.props.onDeleteCheck();
    this.setState({ canDelete }, () => {
      if('onDelete' in this.props && this.state.canDelete) this.props.onDelete()
    });
  }

  enablePregnancy() {
    let node_type = this.props.person.node_type
    if(node_type.includes('father') || node_type.includes('mother') || node_type.includes('partner')) {
      return false
    } else {
      let profileFromPedigree = this.props.person.data.profile
      let partners = helper_family_tree.getPartnersFromRedux(this.props.patient, profileFromPedigree.rkey)
      return partners.length === 0
    }
  }

  handleGenderChange(item) {
    let profile = cloneDeep(this.state.profile);
    if (profile) {
      let node_types = ['mother', 'father', 'paternal_grandmother', 'paternal_grandfather', 'maternal_grandmother', 'maternal_grandfather', 'partner'];

      let node_type = this.props.person.node_type;
      let bad_node = node_types.includes(node_type);
      let partners = (node_type === 'proband') ? this.props.patient.partners['proband'] : this.props.patient.partners[this.state.profile.rkey];

      if(( partners && partners.length > 0 ) || bad_node) {
        this.setState({genderError: true})
      } else {
        let value = item ? item.value : null;
        profile.gender = value;
        this.setState({ profile: profile, genderError: false })
      }
      this.setPendingChanges(true);
    }
  }

  toggleShowMore(show_more) {
    this.setState({ showMore: !this.state.showMore });
  }

  render() {
    let gender_options = [
      settings.app_constants.patientData.genders.male,
      settings.app_constants.patientData.genders.female,
      settings.app_constants.patientData.genders.unknown
    ];

    let adopted = helpers.getSelectedOption(
      settings.adopted_status_options,
      this.state.adopted
    );

    let gender = helpers.getSelectedOption(gender_options, null);
    let pregnancy = false;
    let carrier = false;
    if (this.state.profile) {
      const g = (this.state.profile.gender) ? this.state.profile.gender.toUpperCase() : null;
      gender = helpers.getSelectedOption(gender_options, g);
      pregnancy = this.state.profile.pregnancy;
      carrier = this.state.profile.carrier;
    }

    let show_more_label = (<React.Fragment>Show More <i className="fa fa-chevron-down"></i></React.Fragment>);
    if (this.state.showMore) {
      show_more_label = (<React.Fragment>Show Less <i className="fa fa-chevron-up"></i></React.Fragment>)
    }

    return (
      <div className="form-horizontal">

        <ErrorSummary errorMessages={this.state.errorMessages} />

        <div className="form-group">
          <label htmlFor="first_name" className="col-sm-4 control-label">First Name</label>
          <div className="col-sm-8">
            <input
              name='first_name'
              onChange={this.handleProfileChange}
              type="text" className="form-control normal-input-text" value={this.getProfileValue('first_name')} />
          </div>
        </div>

        <div className="form-group">
          {this.getProfileValue('is_dead') ? (<label htmlFor="age" className="col-sm-4 control-label">Age at Death</label>) : (<label htmlFor="age" className="col-sm-4 control-label">Age</label>)}
          <div className="col-sm-8">
            <input
              name="age"
              onChange={this.handleProfileChange}
              value={this.getProfileValue('age')}
              type="text" className="form-control normal-input-text" />
          </div>
        </div>

        {this.props.person.data.profile.id === this.props.patient.patient.id && (
          <div className="form-group">
            <label htmlFor="dob" className="col-sm-4 control-label">Date of Birth</label>
            <div className="col-sm-8">
              <input
                name="dob" onChange={this.handleProfileChange} value={this.getProfileValue('dob')}
                style={{ lineHeight: 1.42857143 }} type="date" className="form-control" placeholder="MM/DD/YYYY" />
            </div>
          </div>
        )}

        {this.props.person.data.profile.id !== this.props.patient.patient.id && (
          <div className="form-group">
            <label htmlFor="yob" className="col-sm-4 control-label">Year of Birth</label>
            <div className="col-sm-8">
              <input
                name="yob" onChange={this.handleProfileChange} value={this.getProfileValue('yob')}
                type="number" className="form-control normal-input-text" />
            </div>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="is_dead" className="col-sm-4 control-label">Deceased?</label>

          <div className="col-sm-8">
            <div className="checkbox">
              <label className="switch">
                <input
                  name="is_dead"
                  onChange={this.handleProfileChange}
                  checked={this.getProfileValue('is_dead')}
                  type="checkbox"/>
                <span className="slider round"></span>
              </label>
            </div>
          </div>

        </div>

        {this.getProfileValue('is_dead') && (
          <div className="form-group">
            <label htmlFor="cause_of_death" className="col-sm-4 control-label">Cause of Death</label>
            <div className="col-sm-8">
              <input
                name="cause_of_death"
                onChange={this.handleProfileChange}
                value={this.getProfileValue('cause_of_death')}
                type="text" className="form-control normal-input-text" />
            </div>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="note" className="col-sm-4 control-label">Notes</label>
          <div className="col-sm-8">
            <textarea
              name="note"
              onChange={this.handleProfileChange}
              value={this.getProfileValue('note')}
              className="form-control"  />
          </div>
        </div>

        {this.state.showMore && (
          <React.Fragment>
            <div className="form-group">
              <label htmlFor="gender" className="col-sm-4 control-label">Biological Sex</label>

              <div className="col-sm-8">
                <Select
                  value={gender}
                  name="gender"
                  onChange={item => this.handleGenderChange(item)}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable={true}
                  placeholder=""
                  options={gender_options}
                />
              </div>
              {this.state.genderError && (
                <div className="validation-inline__error-text validation-warning">
                  You cannot change the sex assigned at birth of pedigree members who have partners.
                </div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="adopted" className="col-sm-4 control-label">Adopted</label>
              <div className="col-sm-8">
                <div className="custom-select">
                  <Select
                    value={adopted}
                    onChange={item => {
                      let value = item ? item.value : null;
                      this.onChange("adopted", value);
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isClearable={true}
                    placeholder=""
                    options={settings.adopted_status_options}
                  />
                </div>
              </div>
            </div>

            {this.state.enablePregnancy && (
              <div className="form-group no-margin-bottom">
                <label htmlFor="pregnancy" className="col-sm-4 control-label">
                  Pregnancy?
                </label>
                <div className="col-sm-8">
                  <div className="checkbox no-margin-top">
                    <label className="switch">
                      <input
                        onChange={e => {
                          this.onChange("pregnancy", e.target.checked);
                        }}
                        checked={pregnancy}
                        type="checkbox"
                        data-toggle="modal"
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            )}

            <div className="form-group no-margin-bottom">
              <label  htmlFor="carrier" className="col-sm-4 control-label">
                Carrier?
              </label>
              <div className="col-sm-8">
                <div className="checkbox no-margin-top">
                  <label className="switch">
                    <input
                      onChange={e => {
                        this.onChange("carrier", e.target.checked);
                      }}
                      checked={carrier}
                      type="checkbox"
                      data-toggle="modal"
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="col-sm-12">
                <a href="#" className="btn btn-light-outline btn-xs"
                  onClick={() => this.handleDeleteClick()}
                >
                  Delete family member?
                </a>
              </div>
            </div>

          </React.Fragment>
        )}

        <div className="margin-three-top">
          <a onClick={this.toggleShowMore} className="link">{show_more_label}</a>
        </div>
      </div>
    );
  }

}


const redux_state = state => ({
  ...state
})

const redux_actions = dispatch => ({
  dispatch: (action) => dispatch(action)
})

export default connect(redux_state, redux_actions)(MemberPersonalInfo)
