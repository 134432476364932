import React, { Component } from "react";
import settings from '../../configs/settings'
import DatePicker from 'react-date-picker';
import account_api from '../../api/user-account-api';

import "custom-event-polyfill";

// @ts-ignore
import Select from "react-select";


interface Props {
    org: any;
    setOrgLicenseState: any;
}

interface State {
    pending_changes: boolean;
    specialty: string;
    key_contacts: string;
    notes: string;
    client_type: string;
    risk_models: RiskModels;
    customizations: Customizations;
    integration_api: IntegrationAPI;
    white_label_options: WhiteLabelOptions
    contract_start_date: string;
    renewal_date: string;
}

interface RiskModels {
    boadicea: boolean;
    tyrer_cuzick: boolean;
    gail: boolean;
    claus: boolean;
    brcapro: boolean;
    mmrpro: boolean;
    pancpro: boolean;
    melapro: boolean;
}

interface Customizations {
    disease_list: boolean;
    surveys: boolean;
}

interface IntegrationAPI {
    auto_invite: boolean;
    emr: boolean;
    xml_export: boolean;
    sso: boolean;
    other: boolean;
    other_val: ''
}

interface WhiteLabelOptions {
    terms_and_conditions: boolean;
    support_web_page: boolean;
    automatic_opt_in_patients: boolean;
    automatic_opt_in_family_members: boolean;
    hide_clinicians: boolean;
    hide_risk: boolean;
    genetic_resources: boolean;
}

class LicensingTab extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            pending_changes: false,
            contract_start_date: this.props.org.contract_start_date,
            renewal_date: this.props.org.renewal_date,
            specialty: this.props.org.specialty,
            key_contacts: this.props.org.key_contacts,
            notes: this.props.org.notes,
            client_type: this.props.org.client_type,
            risk_models: {
                boadicea: this.props.org.license.boadicea,
                tyrer_cuzick: this.props.org.license.tyrer_cuzick,
                gail: this.props.org.license.gail,
                claus: this.props.org.license.claus,
                brcapro: this.props.org.license.brcapro,
                mmrpro: this.props.org.license.mmrpro,
                pancpro: this.props.org.license.pancpro,
                melapro: this.props.org.license.melapro,
            },
            customizations: {
                disease_list: this.props.org.customizations.disease_list,
                surveys: this.props.org.customizations.surveys
            },
            integration_api: {
                auto_invite: this.props.org.integration_api.auto_invite,
                emr: this.props.org.integration_api.emr,
                xml_export: this.props.org.integration_api.xml_export,
                sso: this.props.org.integration_api.sso,
                other: this.props.org.integration_api.other,
                other_val: this.props.org.integration_api.other_val_string,
            },
            white_label_options: {
                terms_and_conditions: this.props.org.white_label_options.terms_and_conditions,
                support_web_page: this.props.org.white_label_options.support_web_page,
                automatic_opt_in_patients: this.props.org.white_label_options.automatic_opt_in_patients,
                automatic_opt_in_family_members: this.props.org.white_label_options.automatic_opt_in_family_members,
                hide_clinicians: this.props.org.white_label_options.hide_clinicians,
                hide_risk: this.props.org.white_label_options.hide_risk,
                genetic_resources: this.props.org.white_label_options.genetic_resources,
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeContractStartDate = this.handleChangeContractStartDate.bind(this);
        this.handleChangeRenewalDate = this.handleChangeRenewalDate.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
    }

    async componentDidMount() {
      console.log(this.props.org)
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) {
        if (event.target.name == 'other_val'){
          // @ts-ignore
          this.setState({ integration_api: {...this.state.integration_api, other_val: event.target.value} });
          return;
        }
        // @ts-ignore
        this.setState({ [event.target.name]: event.target.value, pending_changes: true });
    }

    handleCheckboxChange(event: any, option_type: string) {
        // @ts-ignore
        console.log(this.state[option_type], event.target.name, event.target.checked)
        // @ts-ignore
        this.setState({[option_type]: {...this.state[option_type], [event.target.name]: event.target.checked}, pending_changes: true});
    }


    handleChangeContractStartDate(event: any){
        if(event == null) {
          this.setState({ contract_start_date: '' })
        } else {
          let fullYear = event.getFullYear()
          let month = event.getMonth() + 1;
          if (month < 10) {
            month = `0${month}`;
          }

          let day = event.getDate();
          if (day < 10) {
            day = `0${day}`;
          }

          const string_date = fullYear + "-" + month + "-" + day;
          this.setState({ contract_start_date: string_date, pending_changes: true })
        }

    }

    handleChangeRenewalDate(event: any){
        if(event == null) {
          this.setState({ renewal_date: '' })
        } else {
          let fullYear = event.getFullYear()
          let month = event.getMonth() + 1;
          if (month < 10) {
            month = `0${month}`;
          }

          let day = event.getDate();
          if (day < 10) {
            day = `0${day}`;
          }

          const string_date = fullYear + "-" + month + "-" + day;
          this.setState({ renewal_date: string_date, pending_changes: true })
        }
    }

    async handleClickSave(event: React.MouseEvent<HTMLElement>) {
      event.preventDefault();
      console.log(this.state)

      try{
        let payload = {
          org_id: this.props.org.id,
          contract_start_date: this.state.contract_start_date,
          renewal_date: this.state.renewal_date,
          specialty: this.state.specialty,
          key_contacts: this.state.key_contacts,
          notes: this.state.notes,
          client_type: this.state.client_type,
          risk_models: this.state.risk_models,
          customizations: this.state.customizations,
          integration_api: this.state.integration_api,
          white_label_options: this.state.white_label_options
        }

        await account_api.save_license_details(payload);
        this.props.setOrgLicenseState(payload)
        this.setState({ pending_changes: false })
      }
      catch(e){
        console.log(e)
      }
    }

    render() {

        let client_type_options = [
            settings.app_constants.clientTypes.white_label_vpc,
            settings.app_constants.clientTypes.white_label_multi_tenant,
            settings.app_constants.clientTypes.standard
        ];

        let client_type_val = null;
        switch(this.state.client_type) {
          case 'white_label_vpc': { client_type_val = settings.app_constants.clientTypes.white_label_vpc; break; }
          case 'white_label_multi_tenant': { client_type_val = settings.app_constants.clientTypes.white_label_multi_tenant; break; }
          case 'standard': { client_type_val = settings.app_constants.clientTypes.standard; break; }
          default: { break; }
        }

        let contract_start_date = '';
        if (this.state.contract_start_date) {
          try {
            // @ts-ignore
            contract_start_date = new Date(this.state.contract_start_date + "T12:00:00");
          } catch (error) {
            contract_start_date = '';
          }
        }

        let renewal_date = '';
        if (this.state.renewal_date) {
          try {
            // @ts-ignore
            renewal_date = new Date(this.state.renewal_date + "T12:00:00");
          } catch (error) {
            renewal_date = '';
          }
        }

        let minDate = new Date('1800-01-01' + "T12:00:00")

        return <div>
            <form className="form-horizontal">
                <div className="form-group">
                    <label htmlFor="dob" className="col-sm-2 control-label">Contract Start Date</label>
                    <div className="col-sm-2" style={{ width: 155 }}>
                        <DatePicker
                            name="contract_start_date"
                            onChange={this.handleChangeContractStartDate}
                            // @ts-ignore
                            value={contract_start_date}
                            className="form-control"
                            calendarIcon={null}
                            clearIcon={null}
                            dayPlaceholder={"dd"}
                            monthPlaceholder={"mm"}
                            yearPlaceholder={"yyyy"}
                            disableCalendar={true}
                            showLeadingZeros={true}
                            minDate={minDate}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="dob" className="col-sm-2 control-label">Renewal Date</label>
                    <div className="col-sm-2" style={{ width: 155 }}>
                        <DatePicker
                            name="renewal_date"
                            onChange={this.handleChangeRenewalDate}
                            // @ts-ignore
                            value={renewal_date}
                            className="form-control"
                            calendarIcon={null}
                            clearIcon={null}
                            dayPlaceholder={"dd"}
                            monthPlaceholder={"mm"}
                            yearPlaceholder={"yyyy"}
                            disableCalendar={true}
                            showLeadingZeros={true}
                            minDate={minDate}
                        />
                    </div>
                </div>

                {/* specialty */}
                <div className="form-group">
                    <label
                        className="col-sm-2 control-label"
                        htmlFor="licensing-specialty"
                    >
                        Specialty
                    </label>
                    <div className="col-sm-9">
                        <input
                            type="text"
                            onChange={this.handleChange}
                            className="form-control"
                            name="specialty"
                            value={this.state.specialty}
                            id="licensing-specialty"
                        />
                    </div>
                </div>

                {/* key contacts */}
                <div className="form-group">
                    <label
                        className="col-sm-2 control-label"
                        htmlFor="licensing-specialty"
                    >
                        Key Contacts
                    </label>
                    <div className="col-sm-9">
                        <input
                            type="text"
                            onChange={this.handleChange}
                            className="form-control"
                            name="key_contacts"
                            value={this.state.key_contacts}
                            id="licensing-specialty"
                        />
                    </div>
                </div>

                {/* Notes */}
                <div className="form-group">
                    <label htmlFor="licensing-notes" className="col-sm-2 control-label">Notes</label>
                    <div className="col-sm-9">
                        <textarea
                            id='licensing-notes'
                            name="notes"
                            onChange={this.handleChange}
                            value={this.state.notes}
                            className="form-control" />
                    </div>
                </div>

                {/* Client Type */}
                <div className="form-group">
                  <label className="control-label col-sm-2">Client Type</label>
                  <div className="col-sm-9">
                    <Select
                      name="org_type"
                      id="org_type"
                      onChange={(item: any) => {
                        this.setState({ client_type: (item ? item.value : null ), pending_changes: true });
                      }}
                      value={client_type_val}
                      className='react-select-container'
                      classNamePrefix="react-select"
                      isClearable={false}
                      placeholder=""
                      options={client_type_options}
                    />
                  </div>
                </div>

                {/* Risk Models */}
                <div className="form-group">
                  <label className="control-label col-sm-2">Risk Models</label>
                  <div className="col-sm-9">
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="boadicea" id="boadicea" onChange={(e) => this.handleCheckboxChange(e, "risk_models")} checked={this.state.risk_models.boadicea} />	BOADICEA
                      </label>
                    </div>

                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="tyrer_cuzick" id="tyrer_cuzick" onChange={(e) => this.handleCheckboxChange(e, "risk_models")} checked={this.state.risk_models.tyrer_cuzick} /> TYRER-CUZICK
                      </label>
                    </div>

                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="gail" id="gail" onChange={(e) => this.handleCheckboxChange(e, "risk_models")} checked={this.state.risk_models.gail} /> GAIL
                      </label>
                    </div>

                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="claus" id="claus" onChange={(e) => this.handleCheckboxChange(e, "risk_models")} checked={this.state.risk_models.claus} /> CLAUS
                      </label>
                    </div>

                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="brcapro" id="brcapro" onChange={(e) => this.handleCheckboxChange(e, "risk_models")} checked={this.state.risk_models.brcapro} /> BRCAPRO
                      </label>
                    </div>

                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="mmrpro" id="mmrpro" onChange={(e) => this.handleCheckboxChange(e, "risk_models")} checked={this.state.risk_models.mmrpro} /> MMRPRO
                      </label>
                    </div>

                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="pancpro" id="pancpro" onChange={(e) => this.handleCheckboxChange(e, "risk_models")} checked={this.state.risk_models.pancpro} /> PANCPRO
                      </label>
                    </div>

                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="melapro" id="melapro" onChange={(e) => this.handleCheckboxChange(e, "risk_models")} checked={this.state.risk_models.melapro} /> MELAPRO
                      </label>
                    </div>
                  </div>
                </div>

                {/* Customizations */}
                <div className="form-group">
                  <label className="control-label col-sm-2">Customizations</label>
                  <div className="col-sm-9">
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="disease_list" id="disease_list" onChange={(e) => this.handleCheckboxChange(e, "customizations")} checked={this.state.customizations.disease_list} />	DISEASE LIST
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="surveys" id="surveys" onChange={(e) => this.handleCheckboxChange(e, "customizations")} checked={this.state.customizations.surveys} /> SURVEY(S)
                      </label>
                    </div>
                  </div>
                </div>

                {/* Integration/API */}
                <div className="form-group">
                  <label className="control-label col-sm-2">Integration/API</label>
                  <div className="col-sm-9">
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="auto_invite" id="auto_invite" onChange={(e) => this.handleCheckboxChange(e, "integration_api")} checked={this.state.integration_api.auto_invite} />	AUTO INVITE
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="emr" id="emr" onChange={(e) => this.handleCheckboxChange(e, "integration_api")} checked={this.state.integration_api.emr} /> EMR
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="xml_export" id="xml_export" onChange={(e) => this.handleCheckboxChange(e, "integration_api")} checked={this.state.integration_api.xml_export} /> XML EXPORT
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="sso" id="sso" onChange={(e) => this.handleCheckboxChange(e, "integration_api")} checked={this.state.integration_api.sso} /> SSO
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="other" id="other" onChange={(e) => this.handleCheckboxChange(e, "integration_api")} checked={this.state.integration_api.other} /> OTHER
                      </label>
                    </div>
                  </div>
                </div>

                {this.state.integration_api.other &&
                <div className="form-group">
                    <label
                        className="col-sm-2 control-label"
                        htmlFor="api_integration_api"
                    >
                    </label>
                    <div className="col-sm-9">
                        <input
                            type="text"
                            onChange={this.handleChange}
                            className="form-control"
                            name="other_val"
                            value={this.state.integration_api.other_val}
                            id="api_integration_api"
                        />
                    </div>
                </div>}

                {/* White Label Options */}
                {(this.state.client_type == 'white_label_vpc' || this.state.client_type == 'white_label_multi_tenant') &&
                <div className="form-group">
                  <label className="control-label col-sm-2">White Label Options</label>
                  <div className="col-sm-9">
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="terms_and_conditions" id="terms_and_conditions" onChange={(e) => this.handleCheckboxChange(e, "white_label_options")} checked={this.state.white_label_options.terms_and_conditions} />	TERMS AND CONDITIONS
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="support_web_page" id="support_web_page" onChange={(e) => this.handleCheckboxChange(e, "white_label_options")} checked={this.state.white_label_options.support_web_page} /> SUPPORT WEB PAGE
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="automatic_opt_in_patients" id="automatic_opt_in_patients" onChange={(e) => this.handleCheckboxChange(e, "white_label_options")} checked={this.state.white_label_options.automatic_opt_in_patients} /> AUTOMATIC OPT-IN - PATIENTS
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="automatic_opt_in_family_members" id="automatic_opt_in_family_members" onChange={(e) => this.handleCheckboxChange(e, "white_label_options")} checked={this.state.white_label_options.automatic_opt_in_family_members} /> AUTOMATIC OPT-IN - FAMILY MEMBERS
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="hide_clinicians" id="hide_clinicians" onChange={(e) => this.handleCheckboxChange(e, "white_label_options")} checked={this.state.white_label_options.hide_clinicians} /> HIDE CLINICIANS
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="hide_risk" id="hide_risk" onChange={(e) => this.handleCheckboxChange(e, "white_label_options")} checked={this.state.white_label_options.hide_risk} /> HIDE RISK
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="genetic_resources" id="genetic_resources" onChange={(e) => this.handleCheckboxChange(e, "white_label_options")} checked={this.state.white_label_options.genetic_resources} /> GENETIC RESOURCES
                      </label>
                    </div>
                  </div>
                </div>}

                <div className='modal-footer'>
                  <button type="submit" className="btn btn-dark" onClick={(event) => this.handleClickSave(event)} disabled={!this.state.pending_changes}>Save</button>
                </div>
            </form>
        </div>
    }
}

export default LicensingTab;
