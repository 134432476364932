import { groupBy } from "lodash";
import { cloneElement, useCallback } from "react";

class SpousesGroup {

  id = 0;

  main_ind = null;
  left_partners = [];
  right_partners = [];
  individuals = {};

  constructor(id, main_ind) {
    this.id = id;
    this.main_ind = main_ind;
  }

  assign_group_members(all_spouse_group_members, individuals_2_partners_groups) {

    for(var member of all_spouse_group_members) {
      this.individuals[member.id] = member;

      if (!individuals_2_partners_groups[member.id]) individuals_2_partners_groups[member.id] = [];

      (individuals_2_partners_groups[member.id]).push(this);
      
      member["spouses_group_id"] = this.id;
    }
  }
}


class LockGroup {

  id = 0;

  whole_tree_reference = null;
  all_lock_groups_reference = null;

  level_from = 999;
  level_to = 0;

  levels = {};
  individuals = {};

  edges = {}

  constructor(id, whole_tree) {
    this.id = id;
    this.whole_tree_reference = whole_tree;
    this.all_lock_groups_reference = this.whole_tree_reference.lock_groups;
  }

  get_level_from() {
    let levels_array = Object.keys(this.levels);
    return levels_array[0];
  }

  get_level_to() {
    let levels_array = Object.keys(this.levels);
    return levels_array[levels_array.length - 1];
  }

  add_individual(ind, merging_from_other_group = false) {
    if (!this.individuals[ind.id]) {
      this.individuals[ind.id] = ind;
    } else {
      return;
    }

    let ind_level = ind.grid_level;
    if (ind_level > this.level_to) {
      this.level_to = ind_level;
    }
    if (ind_level < this.level_from) {
      this.level_from = ind_level;
    }

    if (!this.levels[ind_level]) this.levels[ind_level] = [];
    this.levels[ind_level].push(ind);


    if (!this.edges[ind_level]) this.edges[ind_level] = {"r": ind, "l": ind};

    if (ind.grid_pos > this.edges[ind_level].r.grid_pos) {
      this.edges[ind_level].r = ind
    }
    if (ind.grid_pos < this.edges[ind_level].l.grid_pos) {
      this.edges[ind_level].l = ind
    }

    if (ind["pos_locked"] && ind.lock_group != this.id) { // if it was locked already in some other smaller sub-lock-group -> let's import that smaller group into this one
      if (!merging_from_other_group) { // unless we are already in the process merging the other group
        this.merge_other_group_in(ind.lock_group);
      }
    }

    ind["pos_locked"] = true;
    ind["lock_group"] = this.id;
  }

  can_group_be_moved_right() {

    for(var level in this.edges) {

      let right_edge_ind = this.edges[level].r;

      let first_cell_to_right = this.whole_tree_reference.grid[level][right_edge_ind.grid_pos + .5];
      let second_cell_to_right = this.whole_tree_reference.grid[level][right_edge_ind.grid_pos + 1];

      if (first_cell_to_right != null || second_cell_to_right != null) {
        return false;
      }
    }
    return true;
  }

  movegroup_right(make_space_first = true) {

    if (!this.can_group_be_moved_right()) return false;

    if (make_space_first) {

      for(var level in this.edges) {

        let left_edge_ind = this.edges[level].l;
        this.whole_tree_reference.moveright_all_inds_after_this(left_edge_ind, this);
        this.whole_tree_reference.move_ind_right(left_edge_ind, this);
      }

    } else {

      for(var level in this.levels) {

        let level_w_correct_order = this.get_levels_inds_w_order_corrected(this.levels[level]);

        for(let i=level_w_correct_order.length-1; i>=0; i--) {
          let ind_to_move = level_w_correct_order[i];
          this.whole_tree_reference.move_ind_right(ind_to_move, this);
        }
      }
    }

    return true;
  }

  can_group_be_moved_left() {

    for(var level in this.edges) {

      let left_edge_ind = this.edges[level].l;

      let first_cell_to_left = this.whole_tree_reference.grid[level][left_edge_ind.grid_pos - .5];
      let second_cell_to_left = this.whole_tree_reference.grid[level][left_edge_ind.grid_pos - 1];

      if (first_cell_to_left != null || second_cell_to_left != null) {
        return false;
      }
    }
    return true;
  }

  movegroup_left(make_space_first = true) {

    if (!this.can_group_be_moved_left()) return false;

    if (make_space_first) {

      for(var level in this.edges) {

        let right_edge_ind = this.edges[level].r;
        this.whole_tree_reference.moveleft_all_inds_before_this(right_edge_ind, this);
        this.whole_tree_reference.move_ind_left(right_edge_ind, this);
      }

    } else {

      for(var level in this.levels) {

        let level_w_correct_order = this.get_levels_inds_w_order_corrected(this.levels[level]);

        for(let i=0; i<level_w_correct_order.length; i++) {
          let ind_to_move = level_w_correct_order[i];
          this.whole_tree_reference.move_ind_left(ind_to_move, this);
        }
      }
    }

    return true;
  }

  merge_other_group_in(group_id) {
    let othergroup = this.all_lock_groups_reference[group_id];

    for(var id in othergroup.individuals) {
      let otherind = othergroup.individuals[id];

      this.add_individual(otherind, true);
    }
  }

  get_levels_inds_w_order_corrected(level) {

    let new_order = [];

    let min_pos = 9999;
    let max_pos = -9999;
    let i = 0;
    let ind = null;

    for (i=0; i<level.length; i++) {
     ind = level[i];
      if (ind.grid_pos < min_pos) {
        min_pos = ind.grid_pos;
      }
      if (ind.grid_pos > max_pos) {
        max_pos = ind.grid_pos;
      }
    }

    for (let pos=min_pos; pos<=max_pos; pos=pos+.5) {
      for (i=0; i<level.length; i++) {

        ind = level[i];
        if (ind.grid_pos == pos) {
          new_order.push(ind);
        }
      }
    }

    return new_order;
  }

}


export default class Smartdraw2_Smartdraw {

  proband = null;
  top_left_ancestor = null;
  initial_levels_assignments = null;
  initial_pass_was_done = false;
  levels = {};

  nodes_added_already = {};

  current = null;
  current_level = 1;

  individuals = {};

  constructor(proband_w_family) {
    this.proband = proband_w_family;
  }





  ///////////////////////////////////////
  ///////////// PHASE 1: LINK TO LEVELS
  ///////////////////////////////////////

  crawl_and_assign_to_levels() {
    if (!this.top_left_ancestor) {
      this.find_top_left_ancestor();
    }

    // CRAWL ==DOWN==
    this.crawl_recursive_down(this.top_left_ancestor);
    
    // CRAWL ==UP== thru the other grand parents that were not added yet
    // TOFIX: MAYBE MOVE TO A SEPARATE FUNCTION
    for (let i=0; i<this.right_side_parents_to_temporarily_skip_adding.length; i++) {
      let staged_parent = this.right_side_parents_to_temporarily_skip_adding[i];
      this.current_level = staged_parent.staged_level;
      this.crawl_recursive_up(staged_parent);
    }
    this.right_side_parents_to_temporarily_skip_adding = [];
    // END - CLEAN UP, MAYBE MOVE TO A SEPARATE FUNCTION
    
    // CLEAN UP, MAYBE MOVE TO A SEPARATE FUNCTION
    // after the 1st pass of the crawl_recursive() that was just to identify the initial positions for individuals we can reset all veriables and run again
    this.initial_levels_assignments = this.levels;
    this.initial_pass_was_done = true;
    this.levels = {};
    this.nodes_added_already = {};
    this.partners_groups = {};
    this.individuals_2_partners_groups = {};
    this.inds_currently_being_processed = {};
    this.grid = {};
    this.lock_groups = [];
    for(var levelid in this.initial_levels_assignments) {
      for(var ind of this.initial_levels_assignments[levelid]) {
        delete ind.spouses_group_id;
        delete ind.pos_locked;
        delete ind.lock_group;
        delete ind.staged_level;
        delete ind.level;
      }
    }
    this.current_level = 1;
    // END - CLEAN UP, MAYBE MOVE TO A SEPARATE FUNCTION
    
    // CRAWL ==DOWN==
    // run again, this time with all initial positioning identified and stored in this.initial_levels_assignments and available to the logic of the 2nd, more redined, pass
    this.crawl_recursive_down(this.top_left_ancestor);

    // CRAWL ==UP== thru the other grand parents that were not added yet
    // TOFIX: MAYBE MOVE TO A SEPARATE FUNCTION
    for (let j=0; j<this.right_side_parents_to_temporarily_skip_adding.length; j++) {
      let staged_parent = this.right_side_parents_to_temporarily_skip_adding[j];
      this.current_level = staged_parent.staged_level;
      this.crawl_recursive_up(staged_parent);
    }
    this.right_side_parents_to_temporarily_skip_adding = [];
    // END - CLEAN UP, MAYBE MOVE TO A SEPARATE FUNCTION
    
    this.standardize_levels();
  }

  crawl_recursive_down(node, this_ind_relation = null) {

    if (!node || this.was_node_already_added(node) || this.main_spouses_to_temporarily_skip_adding[node.id]) {
      return;
    } else {
      
      this.inds_currently_being_processed[node.id] = true; // mark this ind as currently being processed so that we don't get to it from its children as parents and schedule them for up-direction crawling
      
      // SPOUSES on the left
      this.main_spouses_to_temporarily_skip_adding[node.id] = node; // temporarily prevent the main parent form being added
      let partners_group = this.order_partners_group(node, this_ind_relation);
      partners_group.left_partners.forEach(partner => {
        this.inds_currently_being_processed[partner.id] = true; // mark partners as currently being processed so that we don't get to them in a second from their children as parents and schedule them for up-direction crawling
        this.crawl_recursive_down(partner, "partner");
      });
      partners_group.right_partners.forEach(partner => { this.inds_currently_being_processed[partner.id] = true; }); // mark partners as currently being processed so that we don't get to them in a second from their children as parents and schedule them for up-direction crawling
      // this.crawl_recursive_down(partners_group.main_ind, "partner");
      
      // THIS INDIVIDUAL
      if (this.main_spouses_to_temporarily_skip_adding[node.id]) { // having spouses on the left added we can now add the main partner/parent
        delete this.main_spouses_to_temporarily_skip_adding[node.id];
        this.add_node_to_current_level(node);
      }
      if (node.pos.direction == null) node.pos.direction = 1;

      //FIXME - doesn't solve top level parents with extra spouses
      if (this.are_simple_two_parents(node)) { // if it's just a pair of two simple parents then place the right parent next to the right parent right away
        this.crawl_recursive_down(node.partners[0], "partner");
      }

      // CHILDREN
      this.current_level++;
      let sorted_children = this.get_sorted_siblings(node.children);
      for(var child of sorted_children) {
        this.crawl_recursive_down(child, "child");
      }
      this.current_level--;

      // SPOUSES on the right
      partners_group.right_partners.forEach(partner => {
        this.crawl_recursive_down(partner, "partner");
      });

      // SIBLINGS
      let sorted_sibs = this.get_sorted_siblings(node.full_siblings);
      for(var sibling of sorted_sibs) {
        this.crawl_recursive_down(sibling, "sibling");
      }

      // PARENTS
      this.current_level--;
      this.stage_parent(node.father);
      this.stage_parent(node.mother);
      this.current_level++;

    }
  }
  
  stage_parent(node) {
    if (node && !this.was_node_already_added(node) && !this.main_spouses_to_temporarily_skip_adding[node.id]) {
      if (!node.level && !node.staged_level && !this.inds_currently_being_processed[node.id]) {
        this.right_side_parents_to_temporarily_skip_adding.push(node); 
        node["staged_level"] = this.current_level;
      }
    }
  }
  
  crawl_recursive_up(node, this_ind_relation = null) {

    if (!node || this.was_node_already_added(node) || this.main_spouses_to_temporarily_skip_adding[node.id]) {
      return;
    } else {
      
      this.inds_currently_being_processed[node.id] = true; // mark this ind as currently being processed so that we don't get to it from its children as parents and schedule them for up-direction crawling
      
      // PARENTS (only if crawling up the tree, not down)
      this.current_level--;
      this.crawl_recursive_up(node.father, "parent_father");
      this.crawl_recursive_up(node.mother, "parent_mother");
      this.current_level++;
      
      // SIBLINGS
      if (this_ind_relation != "partner") {
        let sorted_sibs = this.get_sorted_siblings(node.full_siblings);
        for(var sibling of sorted_sibs) {
          if (!this.inds_currently_being_processed[sibling.id]) {
            this.crawl_recursive_up(sibling, "sibling");
          }
        }
      }

      // SPOUSES on the left
      this.main_spouses_to_temporarily_skip_adding[node.id] = node; // temporarily prevent the main parent form being added
      let partners_group = this.order_partners_group(node, this_ind_relation);
      partners_group.left_partners.forEach(partner => {
        this.inds_currently_being_processed[partner.id] = true; // mark partners as currently being processed so that we don't get to them in a second from their children as parents and schedule them for up-direction crawling
        this.crawl_recursive_up(partner, "partner");
      });
      partners_group.right_partners.forEach(partner => { this.inds_currently_being_processed[partner.id] = true; }); // mark partners as currently being processed so that we don't get to them in a second from their children as parents and schedule them for up-direction crawling
      // this.crawl_recursive_up(partners_group.main_ind, "partner");
      
      // THIS INDIVIDUAL
      if (this.main_spouses_to_temporarily_skip_adding[node.id]) { // having spouses on the left added we can now add the main partner/parent
        delete this.main_spouses_to_temporarily_skip_adding[node.id];
        this.add_node_to_current_level(node);
      }
      if (node.pos.direction == null) node.pos.direction = 1;

      //FIXME - doesn't solve top level parents with extra spouses
      if (this.are_simple_two_parents(node)) { // if it's just a pair of two simple parents then place the right parent next to the right parent right away
        this.crawl_recursive_up(node.partners[0], "partner");
      }

      // SPOUSES on the right
      partners_group.right_partners.forEach(partner => {
        this.crawl_recursive_up(partner, "partner");
      });

      // SIBLINGS
      if (this_ind_relation == "partner") {
        let sorted_sibs = this.get_sorted_siblings(node.full_siblings);
        for(var sibling of sorted_sibs) {
          if (!this.inds_currently_being_processed[sibling.id]) {
            this.crawl_recursive_up(sibling, "sibling");
          }
        }
      }

    }
  }

  are_simple_two_parents(node) {
    if (node.partners.length == 1
        && node.partners[0].partners.length == 1
        && node.id == node.partners[0].partners[0].id)
    {
      return true;
    }
    return false;
  }

  partners_groups = {}
  individuals_2_partners_groups = {}
  main_spouses_to_temporarily_skip_adding = {}
  inds_currently_being_processed = {}
  right_side_parents_to_temporarily_skip_adding = []

  order_partners_group(node) {
    if (node.partners.length == 0) return new SpousesGroup(null, node);
    if (node.partners.length == 1 && this.main_spouses_to_temporarily_skip_adding[node.partners[0].id]) return new SpousesGroup(null, node);

    // handle sub-groups of partners that were not added to any group yet
    if (this.individuals_2_partners_groups[node.id]) {
      
      let all_were_given_groups = true;
      for (let i=0; i<node.partners.length; i++) {
        if (!node.partners[i].spouses_group_id) 
          all_were_given_groups = false;
      }
      if (all_were_given_groups) return this.individuals_2_partners_groups[node.id][0];
    }
    
    let final_order = [];

    let left_side = [];
    let main_left = null;
    let main_right = null;
    let right_side = [];

    let left_spouses_ordered = null;
    let right_spouses_ordered = null;

    if (node.gender == "m" || node.gender == "u" || node.gender == null) {
      main_left = node; // force father left
      left_spouses_ordered = this.get_spouses_ordered(main_left);
      main_right = left_spouses_ordered.pop();
      right_spouses_ordered = this.get_spouses_ordered(main_right).reverse();

      if (this.initial_pass_was_done) {
        left_spouses_ordered = this.conditionally_swap_left_partners_right(left_spouses_ordered, main_left, main_right, right_spouses_ordered);
        right_spouses_ordered = this.conditionally_swap_right_partners_left(left_spouses_ordered, main_left, main_right, right_spouses_ordered);
      }
      
      left_side = left_spouses_ordered;
      right_side = [main_right];
      right_side = right_side.concat(right_spouses_ordered);
    }
    if (node.gender == "f") {
      main_right = node; // assign mother right
      right_spouses_ordered = this.get_spouses_ordered(main_right);
      main_left = right_spouses_ordered.pop();
      right_spouses_ordered = right_spouses_ordered.reverse();
      left_spouses_ordered = this.get_spouses_ordered(main_left);

      if (this.initial_pass_was_done) {
        left_spouses_ordered = this.conditionally_swap_left_partners_right(left_spouses_ordered, main_left, main_right, right_spouses_ordered);
        right_spouses_ordered = this.conditionally_swap_right_partners_left(left_spouses_ordered, main_left, main_right, right_spouses_ordered);
      }
      
      left_side = left_spouses_ordered;
      left_side = left_side.concat([main_left]);
      right_side = right_spouses_ordered;
    }

    final_order = final_order.concat(left_spouses_ordered);
    final_order = final_order.concat([main_left]);
    final_order = final_order.concat([main_right]);
    final_order = final_order.concat(right_spouses_ordered);
    
    let new_left_side = [];
    let new_right_side = [];

    left_side.forEach(partner => {
      if (partner.pos.direction == null)
        partner.pos.direction = -1;
      if (!partner.spouses_group_id) 
        new_left_side.push(partner);
    });

    if (main_left.pos.direction == null) main_left.pos.direction = 0;
    if (main_right.pos.direction == null) main_right.pos.direction = 1;

    right_side.forEach(partner => {
      if (partner.pos.direction == null)
        partner.pos.direction = 1;
      if (!partner.spouses_group_id) 
        new_right_side.push(partner);
    });

    let partners_group = new SpousesGroup(Object.keys(this.partners_groups).length + 1, node);
    partners_group.left_partners = new_left_side;
    partners_group.right_partners = new_right_side;
    partners_group.assign_group_members(final_order, this.individuals_2_partners_groups);

    this.partners_groups[partners_group.id] = partners_group;

    return partners_group;
  }

  conditionally_swap_left_partners_right(left_spouses_ordered, main_left, main_right, right_spouses_ordered) {
    
    let partners_to_move_right = [];
    let new_left_spouses_ordered = [];
    
    // check the extra partners on the left side
    for (let index=0; index<left_spouses_ordered.length; index++) {
      
      let ind = left_spouses_ordered[index];
      if (ind.children.length == 0) {
        new_left_spouses_ordered.push(ind);
        continue;
      }
      
      let group_a = [];
      let group_b = [];
      
      let decision_move_to_right = false;
      let level_size = this.initial_levels_assignments[this.current_level].length;
      let collect = false;
      
      // get all people on the LEFT except subject's other (non-main) partners -> collect all their children (group A)
      for (let i=level_size-1; i>=0; i--) {
        
        let ind_on_left = this.initial_levels_assignments[this.current_level][i];
        
        if (collect && (!this.are_partners(ind_on_left, ind) || ind_on_left.id == main_left.id || ind_on_left.id == main_right.id)) {
          group_a = group_a.concat(ind_on_left.children);
        }
        
        if (ind_on_left.id == ind.id) collect = true;
      }
      
      collect = false;
      // get all people on the RIGHT except subject's other (non-main) partners -> collect all their children (group B)
      for (let i=0; i<level_size; i++) {
        
        let ind_on_right = this.initial_levels_assignments[this.current_level][i];
        
        if (collect && (!this.are_partners(ind_on_right, ind) || ind_on_right.id == main_left.id || ind_on_right.id == main_right.id)) {
          group_b = group_b.concat(ind_on_right.children);
        }
        
        if (ind_on_right.id == ind.id) collect = true;
      }
      
      // check if any child from group A is partner of any child from group B
      for (let i=0; i<group_a.length; i++) {
        for (let j=0; j<group_b.length; j++) {
          if (this.are_partners(group_a[i], group_b[j])) 
            // if you find such pair -> mark this ind (subject) to be placed as the last ind in the RIGHT side partners (also so that it later gets marked as POS.DIRECTION = 1
            decision_move_to_right = true;
        }
      }
      
      if (decision_move_to_right) {
        partners_to_move_right.push(ind);
      } else {
        new_left_spouses_ordered.push(ind);
      }
    }
    
    // push individuals collected in partners_to_move_right to the right side
    if (partners_to_move_right.length == 0) return new_left_spouses_ordered;
    for (let i=0; i<partners_to_move_right.length; i++) {
      let in_relation_to_this_ind = (right_spouses_ordered.length>0) ? right_spouses_ordered[right_spouses_ordered.length-1] : main_right;
      this.update_initial_levels_array(partners_to_move_right[i], "move_to_cell_after_this_ind", in_relation_to_this_ind);
      
      right_spouses_ordered.push(partners_to_move_right[i]);
      partners_to_move_right[i].pos.direction = 1;
    }
    
    return new_left_spouses_ordered;
  }

  conditionally_swap_right_partners_left(left_spouses_ordered, main_left, main_right, right_spouses_ordered) {
    
    let partners_to_move_left = [];
    let new_right_spouses_ordered = [];
    
    // check the extra partners on the left side
    for (let index=0; index<right_spouses_ordered.length; index++) {
      
      let ind = right_spouses_ordered[index];
      if (ind.children.length == 0) {
        new_right_spouses_ordered.push(ind);
        continue;
      }
      
      let group_a = [];
      let group_b = [];
      
      let decision_move_to_left = false;
      let level_size = this.initial_levels_assignments[this.current_level].length;
      let collect = false;
      
      // get all people on the LEFT except subject's other (non-main) partners -> collect all their children (group A)
      for (let i=level_size-1; i>=0; i--) {
        
        let ind_on_left = this.initial_levels_assignments[this.current_level][i];
        
        if (collect && (!this.are_partners(ind_on_left, ind) || ind_on_left.id == main_left.id || ind_on_left.id == main_right.id)) {
          group_a = group_a.concat(ind_on_left.children);
        }
        
        if (ind_on_left.id == ind.id) collect = true;
      }
      
      collect = false;
      // get all people on the RIGHT except subject's other (non-main) partners -> collect all their children (group B)
      for (let i=0; i<level_size; i++) {
        
        let ind_on_right = this.initial_levels_assignments[this.current_level][i];
        
        if (collect && (!this.are_partners(ind_on_right, ind) || ind_on_right.id == main_left.id || ind_on_right.id == main_right.id)) {
          group_b = group_b.concat(ind_on_right.children);
        }
        
        if (ind_on_right.id == ind.id) collect = true;
      }
      
      // check if any child from group A is partner of any child from group B
      for (let i=0; i<group_a.length; i++) {
        for (let j=0; j<group_b.length; j++) {
          if (this.are_partners(group_a[i], group_b[j])) 
            // if you find such pair -> mark this ind (subject) to be placed as the last ind in the RIGHT side partners (also so that it later gets marked as POS.DIRECTION = 1
            decision_move_to_left = true;
        }
      }
      
      if (decision_move_to_left) {
        partners_to_move_left.push(ind);
      } else {
        new_right_spouses_ordered.push(ind);
      }
    }
    
    // push individuals collected in partners_to_move_right to the right side
    if (partners_to_move_left.length == 0) return new_right_spouses_ordered;
    for (let i=0; i<partners_to_move_left.length; i++) {
      let in_relation_to_this_ind = (left_spouses_ordered.length>0) ? left_spouses_ordered[0] : main_left;
      this.update_initial_levels_array(partners_to_move_left[i], "move_to_cell_before_this_ind", in_relation_to_this_ind);
      
      left_spouses_ordered.unshift(partners_to_move_left[i]);
      partners_to_move_left[i].pos.direction = -1;
    }
    
    return new_right_spouses_ordered;
  }
  
  update_initial_levels_array(ind_to_move, relation, in_relation_to_this_ind) {
    let levels_new_order = [];
    let level = this.initial_levels_assignments[this.current_level];
    
    for (let i=0; i<level.length; i++) {
      
      if (level[i].id == in_relation_to_this_ind.id) {
        
        if (relation == "move_to_cell_before_this_ind") {
          levels_new_order.push(ind_to_move);
          levels_new_order.push(in_relation_to_this_ind);
        }
        if (relation == "move_to_cell_after_this_ind") {
          levels_new_order.push(in_relation_to_this_ind);
          levels_new_order.push(ind_to_move);
        }
        
      } else 
      if (level[i].id == ind_to_move.id) {
        continue;
      } else {
        levels_new_order.push(level[i]);
      }
    }
    
    this.initial_levels_assignments[this.current_level] = levels_new_order;
  }
  
  are_partners(ind1, ind2) {
    for (let i=0; i<ind1.partners.length; i++) {
      if (ind1.partners[i].id == ind2.id) 
        return true;
    }
    return false;
  }

  get_spouses_ordered(node) {

    let final_order = [];
    let partners = {};

    let amount_of_kids_w_spouse = {};
    let kids_max = 0;

    node.partners.forEach(partner => {
      partners[partner.id] = partner;
      // let amount_of_shared_children = this.get_shared_children(node, partner).length;
      let size_of_shared_children_subtree = this.get_size_of_shared_children_subtree(node, partner);
      amount_of_kids_w_spouse[partner.id] = size_of_shared_children_subtree;

      if (size_of_shared_children_subtree > kids_max) kids_max = size_of_shared_children_subtree;
    });

    for (let i = 0; i <= kids_max; i++) {
      for(var spouse_id in amount_of_kids_w_spouse) {
        let spouse = partners[spouse_id];
        let amount = amount_of_kids_w_spouse[spouse_id];

        if (i == amount && !this.main_spouses_to_temporarily_skip_adding[spouse_id]) {
          final_order.push(spouse);
        }
      }
    }

    return final_order;
  }

  is_inds_child(ind, possible_child) {
    for(var child of ind.children) {
      if (child.id == possible_child.id) {
        return true;
      }
    }
    return false;
  }

  get_shared_children(ind1, ind2) {
    let shared_children = [];

    let ind1_children = {};
    for(var child1 of ind1.children) {
      ind1_children[child1.id] = child1;
    }

    for(var child2 of ind2.children) {
      if (ind1_children[child2.id]) {
        shared_children.push(child2);
      }
    }

    return shared_children;
  }

  get_size_of_shared_children_subtree(ind1, ind2) {
    let shared_children_subtree_size = 0;

    let ind1_children = {};
    for(var child1 of ind1.children) {
      ind1_children[child1.id] = child1;
    }

    for(var child2 of ind2.children) {
      if (ind1_children[child2.id]) {
        // shared_children.push(child2);
        shared_children_subtree_size = shared_children_subtree_size + this.get_subtree_size_recursive(child2);
      }
    }

    return shared_children_subtree_size;
  }

  get_sorted_siblings(siblings) {
    let sorted_sibs = [];
    let max_subtree_size = 0;
    let sibling = null;

    for(var sib_id in siblings) {
      sibling = siblings[sib_id];
      if (!sibling["subtree_size"]) {
        let size =  this.get_subtree_size_recursive(sibling);
        sibling["subtree_size"] = size;
        if (size > max_subtree_size) max_subtree_size = size;

        if (this.ind_belongs_to_other_main_subtree(sibling)) {
          sibling["belongs_to_other_main_subtree"] = true;
        }
      } else {
        if (sibling["subtree_size"] > max_subtree_size) max_subtree_size = sibling["subtree_size"];
      }
    }

    for(var sib_id in siblings) { // smaller sub-families
      sibling = siblings[sib_id];

      if (sibling.partners && sibling.partners.length > 0 && sibling.subtree_size < max_subtree_size && !sibling.belongs_to_other_main_subtree) {
        sorted_sibs.push(sibling);
      }
    }

    for(var sib_id in siblings) { // no sub-families
      sibling = siblings[sib_id];

      if ((!sibling.partners || (sibling.partners && sibling.partners.length == 0)) && !sibling.belongs_to_other_main_subtree) {
        sorted_sibs.push(sibling);
      }
    }

    for(var sib_id in siblings) { // one of the equaly biggest sub-families but not the one that goes up to the 1st level ancestors (e.g. grandparents)
      sibling = siblings[sib_id];

      if (sibling.partners && sibling.partners.length > 0 && sibling.subtree_size == max_subtree_size && !sibling.belongs_to_other_main_subtree) {
        sorted_sibs.push(sibling);
      }
    }

    for(var sib_id in siblings) { // the biggest sub-family that connects up to the other pair of 1st level ancestors (e.g. grandparents)
      sibling = siblings[sib_id];

      if (sibling.belongs_to_other_main_subtree) {
        sorted_sibs.push(sibling);
      }
    }

    return sorted_sibs;
  }

  ind_belongs_to_other_main_subtree(ind) {
    for(let i=0; i<ind.partners.length; i++) {
      let spouse = ind.partners[i];
      if (spouse.father_id && spouse.father_id > 0 && spouse.mother_id && spouse.mother_id > 0)
        return true;
    }

    return false;
  }

  get_subtree_size_recursive(ind, relation = "", current_size = 0) { // FIXME: simplified method of counting an aproximated size of a subtree, might miss some inds in case of some less standard relationships (e.g. individual's spouses with their own other spouses, children etc.)
    let new_size = current_size +1;

    if (relation == "spouse") return new_size;

    for(let i=0; i<ind.partners.length; i++) {
      let spouse = ind.partners[i];
      new_size = this.get_subtree_size_recursive(spouse, "spouse", new_size);
    }

    for(let i=0; i<ind.children.length; i++) {
      let child = ind.children[i];
      new_size = this.get_subtree_size_recursive(child, "child", new_size);
    }

    return new_size;
  }

  standardize_levels() {
    let new_levels = {}

    let lowest_index = 1;
    for(var index in this.levels) {
      if (index < lowest_index) lowest_index = index;
    }

    let i = lowest_index;
    let i_new = 1;
    while (this.levels[i]) {

      new_levels[i_new] = this.levels[i];
      this.log_level(new_levels[i_new]);
      i++;
      i_new++;
    }

    this.levels = new_levels;
  }

  log_level(level) {
    let row = "";
    for(var ind of level) {
      row = row + " | " + ind.id;
    }
    //console.log(row);
  }


  add_node_to_current_level(node) {

    if (!this.levels[this.current_level]) {
      this.levels[this.current_level] = [];
    }
    this.levels[this.current_level].push(node);
    node['level'] = this.current_level;

    this.nodes_added_already[node.id] = true;
  }

  was_node_already_added(node) {

    if (this.nodes_added_already[node.id]) {
      return true;
    }
    return false;
  }

  find_top_left_ancestor() {
    let father = this.proband;
    while (father.father_id > 0) {
      father = father.father;
    }
    this.top_left_ancestor = father;
  }





  ///////////////////////////////////////////////////////////////////////
  ///////////// PHASE 2: ADD HORIZONTAL SPACING BETWEEN INDIVIDUALS
  ///////////////////////////////////////////////////////////////////////

  number_of_generations = 0;

  temporary_max_positions_horizontally = 500 // FIXME: would a JS object of 2000 properties cause any issues? It's from -500, -499.5, -499, ... , 499, 499.5, 500

  // 2D grid with 1/2 steps horizontally
  grid = {};

  lock_groups = []


  space_individuals_out() {
    let starttime = Date.now();

    // start from the bottom
    this.current_level = this.number_of_generations = Object.keys(this.levels).length;
    let current_ind = null;
    this.prepare_grid(this.levels);
let count = 1;

    for (; this.current_level > 0; this.current_level--) { // go from bottom level to the top

      for (let i=0; i<(this.levels[this.current_level]).length; i++) { // go from left to right

        current_ind = this.levels[this.current_level][i];
//  if (count ==1) return;
        this.center_parents(current_ind);
// console.log("center parents of this ind: id: "+current_ind.id+ "(count: "+count+")");

count++;
      }

    }
  }

  prepare_grid(levels) {

    let max = this.temporary_max_positions_horizontally;

    for (let level = 1; level <= this.number_of_generations; level++) {

      this.grid[level] = {};

      for (let pos = max * (-1); pos <= max; pos = pos + .5) {

        (this.grid[level])[pos] = null;
      }

      for (let i = 0; i < levels[level].length; i++) {

        (this.grid[level])[i+1] = levels[level][i]; // assign individual to grid

        (levels[level][i])["grid_level"] = level; // mark the generation on the individual
        (levels[level][i])["grid_pos"] = i+1; // mark the grid position on the individual

        this.individuals[levels[level][i].id] = levels[level][i];
      }

    }

  }

  center_parents(current_ind) {
    let father = this.get_inds_father(current_ind);
    let mother = this.get_inds_mother(current_ind);

    if (!father && !mother) return; // individual with no parents - likely a non-blood related member like someone's spouse - skip positioning of this person's parents as there are none

    let most_left = current_ind.grid_pos; // most left positioned sibling
    let most_right = current_ind.grid_pos; // most right positioned sibling

    for(var sibid in current_ind.full_siblings) {
      let sibling = current_ind.full_siblings[sibid];

      if (sibling.grid_pos > most_right) most_right = sibling.grid_pos;
      if (sibling.grid_pos < most_left ) most_left  = sibling.grid_pos;
    }

    // identify the target center point of the parent relationship
    let parents_center = most_left + (most_right - most_left) / 2;
    let left_parent_target_pos  = parents_center - .5;
    let right_parent_target_pos = parents_center + .5;

    let left_parent  = father;
    let right_parent = mother;

    if (father.grid_pos > mother.grid_pos) {
      left_parent  = mother;
      right_parent = father;
    }

    // moving parents left
    if (left_parent_target_pos < left_parent.grid_pos) {

      this.move_parents_left(left_parent, left_parent_target_pos, right_parent, right_parent_target_pos);

    // moving parents right
    } else if (right_parent_target_pos > right_parent.grid_pos) {

      this.move_parents_right(left_parent, left_parent_target_pos, right_parent, right_parent_target_pos);
    }

    let grp_id = this.get_new_lock_group_id();
    this.lock_parents(grp_id, left_parent, right_parent);
    this.lock_current_ind(grp_id, current_ind);
    this.lock_siblings_and_their_spouses(grp_id, current_ind.full_siblings);

  }

  get_inds_mother(ind) {
    if (!ind || !(ind.mother_id) || ind.mother_id <= 0 || !(this.individuals[ind.mother_id])) return null;

    return this.individuals[ind.mother_id];
  }

  get_inds_father(ind) {
    if (!ind || !(ind.father_id) || ind.father_id <= 0 || !(this.individuals[ind.father_id])) return null;

    return this.individuals[ind.father_id];
  }

  get_new_lock_group_id() {
    let new_lock_index = this.lock_groups.length;
    this.lock_groups.push(new LockGroup(new_lock_index, this))
    return new_lock_index;
  }

  lock_parents(grp_id, left_parent, right_parent) {
    let lockgroup = this.lock_groups[grp_id];

    lockgroup.add_individual(left_parent);
    lockgroup.add_individual(right_parent);
  }

  lock_current_ind(grp_id, current_ind) {
    let lockgroup = this.lock_groups[grp_id];

    lockgroup.add_individual(current_ind);
  }

  lock_siblings_and_their_spouses(grp_id, full_siblings) {
    let lockgroup = this.lock_groups[grp_id];

    for (var sibid in full_siblings) {
      let sibling = full_siblings[sibid];

      lockgroup.add_individual(sibling);

      sibling.partners.forEach(partner => {
        lockgroup.add_individual(partner);
      });
    }

  }

  steps_inds_moved = {};
  groups_moved_already = {};

  move_parents_left(left_parent, left_parent_target_pos, right_parent, right_parent_target_pos) {

    while( ( left_parent.grid_pos > left_parent_target_pos && !left_parent["pos_locked"] )
           ||
           ( right_parent.grid_pos > right_parent_target_pos && !right_parent["pos_locked"] ) ) {

      this.steps_inds_moved = {};
      this.groups_moved_already = {};

      if (this.is_pos_on_left_busy(left_parent)) {
        let move_result = this.moveleft_all_inds_before_this(left_parent);
        if (!move_result) break; // err  // FIXME: should it be a 'continue' here instead of a 'break'?
      }

      if (left_parent.grid_pos > left_parent_target_pos && !left_parent["pos_locked"]) {
        let left_parent_moved = this.move_ind_left(left_parent);
        if (!left_parent_moved) break; // err  // FIXME: should it be a 'continue' here instead of a 'break'?
      }

      let move_result = this.move_left_all_inds_between(left_parent, right_parent);
      if (!move_result) break; // err  // FIXME: should it be a 'continue' here instead of a 'break'?

      if (right_parent.grid_pos > right_parent_target_pos && !right_parent["pos_locked"]) {
        let right_parent_moved = this.move_ind_left(right_parent);
        if (!right_parent_moved) break; // err  // FIXME: should it be a 'continue' here instead of a 'break'?
      }

      //if (left_parent.level > 1 || right_parent.level > 1) // FIXME: this condition is probably not needed, most likely TO DELETE later on
      this.moveleft_all_inds_after_this(right_parent);
    }
  }

  move_parents_right(left_parent, left_parent_target_pos, right_parent, right_parent_target_pos) {

    while( ( left_parent.grid_pos < left_parent_target_pos && !left_parent["pos_locked"] )
           ||
           ( right_parent.grid_pos < right_parent_target_pos && !right_parent["pos_locked"] ) ) {

      this.steps_inds_moved = {};
      this.groups_moved_already = {};

      if (this.is_pos_on_right_busy(right_parent)) {
        let move_result = this.moveright_all_inds_after_this(right_parent);
        if (!move_result) break; // err
      }

      if (right_parent.grid_pos < right_parent_target_pos && !right_parent["pos_locked"]) {
        let right_parent_moved = this.move_ind_right(right_parent);
        if (!right_parent_moved) break; // err
      }

      let move_result = this.move_right_all_inds_between(left_parent, right_parent);
      if (!move_result) break; // err

      if (left_parent.grid_pos < left_parent_target_pos && !left_parent["pos_locked"]) {
        let left_parent_moved = this.move_ind_right(left_parent);
        if (!left_parent_moved) break; // err
      }

      // TEMPORARILY REMOVED - MIGHT NOT BE NEEDED IN THE FUTURE AT ALL BECAUSE WE MOVE PEOPLE MOSTLY TO LEFT AND THEN NEED TO PULL BUT IF WE MORE TO RIGHT IT'S USUALLY A RESULT OF THE LEFT SIDE BEING ALREADY ALIGNED SO NO NEED TO PULL
      // this.moveright_all_inds_before_this(left_parent);
    }

  }

  move_left_all_inds_between(ind_left, ind_right) {
    let level = ind_left.grid_level;
    let inds_to_move = [];
    let ind_to_move = null;

    let left_pos = ind_left.grid_pos;
    let right_pos = ind_right.grid_pos;

    if (left_pos + .5 == right_pos) return true;
    if (left_pos + 1  == right_pos && this.grid[level][left_pos+.5] == null) return true;

    for (let pos=left_pos+.5; pos < right_pos; pos=pos+.5) {
      ind_to_move = this.grid[level][pos];
      if (ind_to_move != null) {
        inds_to_move.push(ind_to_move);
      }
    }
    for (let i=0; i<inds_to_move.length; i++) {
      ind_to_move = inds_to_move[i];
      let move_result = this.move_ind_left(ind_to_move);
      if (!move_result) return false; // err
    }
    return true;
  }

  move_right_all_inds_between(ind_left, ind_right) {
    let level = ind_left.grid_level;
    let inds_to_move = [];
    let ind_to_move = null;

    let left_pos = ind_left.grid_pos;
    let right_pos = ind_right.grid_pos;

    if (left_pos + .5 == right_pos) return true;
    if (left_pos + 1  == right_pos && this.grid[level][left_pos+.5] == null) return true;

    for (let pos=right_pos-.5; pos > left_pos; pos=pos-.5) {

      ind_to_move = this.grid[level][pos];
      if (ind_to_move != null) {
        inds_to_move.push(ind_to_move);
      }
    }
    for (let i=0; i<inds_to_move.length; i++) {
      ind_to_move = inds_to_move[i];
      let move_result = this.move_ind_right(ind_to_move);
      if (!move_result) return false; // err
    }
    return true;
  }

  is_pos_on_left_busy(ind) {
    let prev_cell_1 = this.grid[ ind.grid_level ][ ind.grid_pos - .5 ];
    let prev_cell_2 = this.grid[ ind.grid_level ][ ind.grid_pos - 1 ];

    if (prev_cell_1 == null && prev_cell_2 == null) {
      return false;
    }
    return true;
  }

  is_pos_on_right_busy(ind) {
    let next_cell_1 = this.grid[ ind.grid_level ][ ind.grid_pos + .5 ];
    let next_cell_2 = this.grid[ ind.grid_level ][ ind.grid_pos + 1 ];

    if (next_cell_1 == null && next_cell_2 == null) {
      return false;
    }
    return true;
  }

  moveleft_all_inds_before_this(ind, group_being_moved = null) {
    let level = ind.grid_level;

    let levels_most_left_index = this.get_most_left_ind_index(level);
    let ind_to_move = (this.levels[level])[levels_most_left_index];

    if (ind_to_move.id == ind.id) return true; // in case there's really no one else to move

    for (let i=levels_most_left_index; ind_to_move.id != ind.id; i++) {

      let was_move_successful = true;
      if (!ind_to_move.pos_locked || !this.groups_moved_already[ind_to_move.lock_group]) { // unless the current ind has already been moved with it's group, e.g. because of being the 2nd parent
        was_move_successful = this.move_ind_left(ind_to_move, group_being_moved, true);
      }

      if (!was_move_successful) return false; // err

      if (ind_to_move.pos_locked && group_being_moved == null && !this.groups_moved_already[ind_to_move.lock_group]) {
        this.groups_moved_already[ind_to_move.lock_group] = true;
      }

      ind_to_move = (this.levels[level])[i+1];
    }
    return true;
  }

  moveleft_all_inds_after_this(ind, group_being_moved = null) {
    let level = ind.grid_level;

    let levels_most_right_index = this.get_most_right_ind_index(level);
    let most_right_ind = (this.levels[level])[levels_most_right_index];

    if (most_right_ind.id == ind.id) return true; // in case there's really no one else to move

    let ind_to_move_index = this.get_this_inds_index(ind) + 1;
    let ind_to_move = (this.levels[level])[ind_to_move_index];

    for (let i=ind_to_move_index; i <= levels_most_right_index; i++) {

      let was_move_successful = true;
      if (!ind_to_move.pos_locked || !this.groups_moved_already[ind_to_move.lock_group]) { // unless the current ind has already been moved with it's group, e.g. because of being the 2nd parent
        was_move_successful = this.move_ind_left(ind_to_move, group_being_moved, true);
      }

      if (!was_move_successful) return false; // err

      if (ind_to_move.pos_locked && group_being_moved == null && !this.groups_moved_already[ind_to_move.lock_group]) {
        this.groups_moved_already[ind_to_move.lock_group] = true;
      }

      ind_to_move = (this.levels[level])[i+1];
    }
    return true;
  }

  moveright_all_inds_before_this(ind, group_being_moved = null) {
    let level = ind.grid_level;

    let levels_most_left_index = this.get_most_left_ind_index(level);
    let most_left_ind = (this.levels[level])[levels_most_left_index];

    if (most_left_ind.id == ind.id) return true; // in case there's really no one else to move

    let ind_to_move_index = this.get_this_inds_index(ind) - 1;
    let ind_to_move = (this.levels[level])[ind_to_move_index];

    for (let i=ind_to_move_index; i >= levels_most_left_index; i--) {

      let was_move_successful = true;
      if (!ind_to_move.pos_locked || !this.groups_moved_already[ind_to_move.lock_group]) { // unless the current ind has already been moved with it's group, e.g. because of being the 2nd parent
        was_move_successful = this.move_ind_right(ind_to_move, group_being_moved, true);
      }

      if (!was_move_successful) return false; // err

      if (ind_to_move.pos_locked && group_being_moved == null && !this.groups_moved_already[ind_to_move.lock_group]) {
        this.groups_moved_already[ind_to_move.lock_group] = true;
      }

      ind_to_move = (this.levels[level])[i-1];
    }
    return true;
  }

  moveright_all_inds_after_this(ind, group_being_moved = null) {
    let level = ind.grid_level;

    let levels_most_right_index = this.get_most_right_ind_index(level);
    let ind_to_move = (this.levels[level])[levels_most_right_index];

    if (ind_to_move.id == ind.id) return true; // in case there's really no one else to move

    for (let i=levels_most_right_index; ind_to_move.id != ind.id; i--) {

      let was_move_successful = true;

      if (!ind_to_move.pos_locked || !this.groups_moved_already[ind_to_move.lock_group]) { // unless the current ind has already been moved with it's group, e.g. because of being the 2nd parent
        was_move_successful = this.move_ind_right(ind_to_move, group_being_moved, true);
      }

      if (!was_move_successful) return false; // err

      if (ind_to_move.pos_locked && group_being_moved == null && !this.groups_moved_already[ind_to_move.lock_group]) {
        this.groups_moved_already[ind_to_move.lock_group] = true;
      }

      ind_to_move = (this.levels[level])[i-1];
    }
    return true;
  }


  get_this_inds_index(ind) {
    let level = ind.grid_level;
    let whole_level = this.levels[level];

    for (let i = 0; i < whole_level.length; i++) {
      if (whole_level[i].id == ind.id) {
        return i;
      }
    }
  }


  get_most_right_ind_index(level) {
    let most_right_ind = this.levels[level][0];
    let whole_level = this.levels[level];
    let index = 0

    for (let i = 0; i < whole_level.length; i++) {
      if (whole_level[i].grid_pos > most_right_ind.grid_pos) {
        most_right_ind = whole_level[i];
        index = i;
      }
    }

    return index;
  }

  get_most_left_ind_index(level) {
    let most_left_ind = this.levels[level][0];
    let whole_level = this.levels[level];
    let index = 0

    for (let i = 0; i < whole_level.length; i++) {
      if (whole_level[i].grid_pos < most_left_ind.grid_pos) {
        most_left_ind = whole_level[i];
        index = i;
      }
    }

    return index;
  }

  move_ind_left(ind_to_move, group_being_moved = null, row_of_inds_being_moved = false) {
    if (this.steps_inds_moved[ind_to_move.id]) return false;

    // if the individual is not locked and can be moved. Or we're in the process of moving the hole group already
    if ( ! ind_to_move.pos_locked || (group_being_moved != null && ind_to_move.lock_group == group_being_moved.id) ) {

      // check if we can move to the position to the left, whether the two cells to the left are busy
      let prev_cell1 = this.grid[ ind_to_move.grid_level ][ ind_to_move.grid_pos - .5 ];
      let prev_cell2 = this.grid[ ind_to_move.grid_level ][ ind_to_move.grid_pos - 1 ];
      if (prev_cell1 == null && prev_cell2 == null) {

        this.grid[ ind_to_move.grid_level ][ ind_to_move.grid_pos - .5 ] = ind_to_move;
        this.grid[ ind_to_move.grid_level ][ ind_to_move.grid_pos ] = null;
        ind_to_move.grid_pos = ind_to_move.grid_pos - .5;

        //this.steps_inds_moved[ind_to_move.id] = true;
        return true;
      }

      // if we can't move - the cell is busy
      else {
        console.log("!!WARNING: move_ind_left(): couldn't move individual of id="+(ind_to_move.id)+" left. One of two neighbour cells on the left is busy.");
        return false;
      }

    } else { // if locked

      let lockgroup = this.lock_groups[ind_to_move.lock_group];
      let move_result = lockgroup.movegroup_left(!row_of_inds_being_moved);

      return move_result;
    }
  }

  move_ind_right(ind_to_move, group_being_moved = null, row_of_inds_being_moved = false) {
    if (this.steps_inds_moved[ind_to_move.id]) return false;

    // if the individual is not locked and can be moved. Or we're in the process of moving the hole group already
    if ( ! ind_to_move.pos_locked || (group_being_moved != null && ind_to_move.lock_group == group_being_moved.id) ) {

      // check if we can move to the position to the right, whether the two cells to the right are busy
      let next_cell1 = this.grid[ ind_to_move.grid_level ][ ind_to_move.grid_pos + .5 ];
      let next_cell2 = this.grid[ ind_to_move.grid_level ][ ind_to_move.grid_pos + 1 ];
      if (next_cell1 == null && next_cell2 == null) {

        this.grid[ ind_to_move.grid_level ][ ind_to_move.grid_pos + .5 ] = ind_to_move;
        this.grid[ ind_to_move.grid_level ][ ind_to_move.grid_pos ] = null;
        ind_to_move.grid_pos = ind_to_move.grid_pos + .5;

        //this.steps_inds_moved[ind_to_move.id] = true;
        return true;
      }

      // if we can't move - the cell is busy
      else {
        console.log("!!WARNING: move_ind_right(): couldn't move individual of id="+(ind_to_move.id)+" right. One of two neighbour cells on the right is busy.");
        return false;
      }

    } else { // if locked

      let lockgroup = this.lock_groups[ind_to_move.lock_group];
      let move_result = lockgroup.movegroup_right(!row_of_inds_being_moved);

      return move_result;
    }

  }







  // re-map the positioning of individuals from the new smartdraw grid to the old column-based nomenclature
  apply_new_positioning() {

    // console.dir(this.lock_groups);
    // console.dir(this.steps_inds_moved);
    // console.dir(this.groups_moved_already);

    let old_min_pos = 9999;
    let new_min_pos = 9999;

    for (var level in this.levels) {
      for (let i=0; i<this.levels[level].length; i++) {

        let ind = this.levels[level][i];

        if (ind.pos.column < old_min_pos) {
          old_min_pos = ind.pos.column;
        }
        if (ind.grid_pos < new_min_pos) {
          new_min_pos = ind.grid_pos;
        }
      }
    }

    for (var level in this.levels) {
      for (let i=0; i<this.levels[level].length; i++) {

        let ind = this.levels[level][i];

        ind.pos.column = ( (ind.grid_pos - new_min_pos) * 2 ) + old_min_pos;
        // if (ind.pos.column > 20) ind.pos.column = 19;
        // console.log("Ind ID: "+ind.id + ", pos.column: "+ind.pos.column);
      }
    }

  }

}
