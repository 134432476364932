import React, { Component } from "react";

class NotificationOnboardingComplete extends Component {
  render() {
    return (
      <div className="tab-pane fade in active">
        <div className=" margin-four-bottom">
          <a href="#" className="link">
            Dismiss all
          </a>
        </div>
        <div className="notif-item">
          <a href="#" className="del">
            <i className="fa fa-trash"></i>
          </a>
          <p>
            <strong>Peter Jones</strong>
          </p>
          <p>
            <span className="date">June 12, 2019</span>{" "}
            <span className="margin-two-right time">3:49 PM</span>
          </p>
        </div>
        <div className="notif-item">
          <a href="#" className="del">
            <i className="fa fa-trash"></i>
          </a>
          <p>
            <strong>Peter Jones</strong>
          </p>
          <p>
            <span className="date">June 12, 2019</span>{" "}
            <span className="margin-two-right time">3:49 PM</span>
          </p>
        </div>
        <div className="notif-item">
          <a href="#" className="del">
            <i className="fa fa-trash"></i>
          </a>
          <p>
            <strong>Peter Jones</strong>
          </p>
          <p>
            <span className="date">June 12, 2019</span>{" "}
            <span className="margin-two-right time">3:49 PM</span>
          </p>
        </div>
        <div className="notif-item">
          <a href="#" className="del">
            <i className="fa fa-trash"></i>
          </a>
          <p>
            <strong>Peter Jones</strong>
          </p>
          <p>
            <span className="date">June 12, 2019</span>{" "}
            <span className="margin-two-right time">3:49 PM</span>
          </p>
        </div>
        <div className="notif-item">
          <a href="#" className="del">
            <i className="fa fa-trash"></i>
          </a>
          <p>
            <strong>Peter Jones</strong>
          </p>
          <p>
            <span className="date">June 12, 2019</span>{" "}
            <span className="margin-two-right time">3:49 PM</span>
          </p>
        </div>
        <div className=" margin-four-bottom">
          <a href="#" className="link">
            Show More <i className="fa fa-angle-down"></i>
          </a>
        </div>
      </div>
    );
  }
}

export default NotificationOnboardingComplete;
