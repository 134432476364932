import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import route_helper from '../../route-helper';
import {
  MinZoom,
  MaxZoom,
  DefaultZoom,
  PartnerOptions,
  ViewStatusOptions,
  CookieKeys
} from './pedigree-constants';
import {set_cookie} from "../../helpers/helper-cookies";

class PedigreeToolbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alwaysShrink: false,
      showActionMenu: false,
      actionMenuHasFocus: false,
      showZoomControl: false,
      zoomMenuHasFocus: false,
      showViewsMenu: false,
      viewMenuHasFocus: false,
      showLegend: false,
      showAncestry: false,
      showGeneticTesting: true,
      showNotes: true,
      showPedigreeNotes: false,
      viewStatus: ViewStatusOptions.IDENTIFIED,
      showSubtextFontTestsMenu: false,
      showFontMontserrat10: false,
      showFontRoboto11: false,
      showPartnersDropdown: false,
      subtextFontTestsMenuHasFocus: false,
      partnersHasFocus: false,
      showAllPartners: false,
      showAffectedPartners: false,
      showBlackAndWhite: false,
      hidePartners: false,
      zoom: DefaultZoom,
      showProgenyArchivedData: false
    }

    this.handleShrinkChanged = this.handleShrinkChanged.bind(this);
    this.handleActionMenuClicked = this.handleActionMenuClicked.bind(this);
    this.handleZoomControlClicked = this.handleZoomControlClicked.bind(this);
    this.handleBodyClicked = this.handleBodyClicked.bind(this);
    this.handleViewsMenuClicked = this.handleViewsMenuClicked.bind(this);
    this.toggleLegend =  this.toggleLegend.bind(this);
    this.togglePedigreeNotes = this.togglePedigreeNotes.bind(this);
    this.toggleAncestry = this.toggleAncestry.bind(this);
    this.setMenuFocus = this.setMenuFocus.bind(this);
    this.disbaleReadOnly = this.disbaleReadOnly.bind(this);
    this.toggleProgenyArchivedData = this.toggleProgenyArchivedData.bind(this);

    this.updateZoomValue = this.updateZoomValue.bind(this);
    this.calculateZoomPercent = this.calculateZoomPercent.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleBodyClicked);

    const viewStatus = this.props.cookies.viewStatus;

    const displayLegend = this.props.cookies.displayLegend;
    const displayLegendBool = displayLegend === 'true';

    const displayPedigreeNotes = this.props.cookies.displayPedigreeNotes;
    const displayPedigreeNotesBool = displayPedigreeNotes === 'true';

    let displayAncestry = this.props.cookies.displayAncestry;
    const displayAncestryBool = displayAncestry === 'true';

    let displayGeneticTesting = this.props.cookies.displayGeneticTesting;
    const displayGeneticTestingBool = displayGeneticTesting === 'true';

    let displayBlackAndWhite = this.props.cookies.displayBlackAndWhite;
    const displayBlackAndWhiteBool = displayBlackAndWhite === 'true';

    let showNotes = this.props.cookies.showNotes;
    const showNotesBool = showNotes === 'true';

    let alwaysShrink = this.props.cookies.alwaysShrink;
    const alwaysShrinksBool = alwaysShrink === "1";

    let partnersOption = this.props.cookies.partnersOption;

    let displayProgenyArchiveData = this.props.cookies.displayProgenyArchiveData;
    const showProgenyArchivedData = displayProgenyArchiveData === 'true';

    this.setState({
      viewStatus: viewStatus,
      showLegend: displayLegendBool,
      showAncestry: displayAncestryBool,
      showGeneticTesting: displayGeneticTestingBool,
      showBlackAndWhite: displayBlackAndWhiteBool,
      showNotes: showNotesBool,
      showPedigreeNotes: displayPedigreeNotesBool,
      alwaysShrink: alwaysShrinksBool,
      showAllPartners: partnersOption === PartnerOptions.SHOW_ALL_PARTNERS,
      showAffectedPartners: partnersOption === PartnerOptions.SHOW_AFFECTED_PARTNERS,
      hidePartners: partnersOption === PartnerOptions.HIDE_PARTNERS,
      showProgenyArchivedData: showProgenyArchivedData
    });

    this.props.onLoad(this.updateZoomValue);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleBodyClicked);
  }

  handleBodyClicked(e) {
    let skippedIds = ['zoom-control-panel', 'actions-menu-panel', 'drawing-control-panel', 'views-control-panel'];

    let paths = e.path
    if(typeof(paths) === "undefined") return
    let shouldSkip = paths.filter(item => skippedIds.includes(item.id)).length > 0
    if(shouldSkip) return

    this.hideAllSubMenus();
  }

  handleActionMenuClicked(e) {
    this.hideAllSubMenus();
    this.setState({ showActionMenu: true });
  }

  handleZoomControlClicked(e) {
    this.hideAllSubMenus();
    this.setState({ showZoomControl: true });
  }

  handleViewsMenuClicked(e) {
    this.hideAllSubMenus();
    this.setState({ showViewsMenu: true });
  }

  handlePartnersMenuHovered(e) {
    this.setState({ showPartnersDropdown: true });
  }

  handlePartnersMenuMouseLeft(e){
    this.setState({ showPartnersDropdown: false });
  }

  hideAllSubMenus() {
    this.setState({
      showViewsMenu: false,
      showActionMenu: false,
      showZoomControl: false,
      showPartnersDropdown: false
    });
  }

  setMenuFocus(key, val) {
    if(key === 'zoom') {
      this.setState({ zoomMenuHasFocus: val, showZoomControl: val });
    } else if(key === 'action') {
      this.setState({ actionMenuHasFocus: val, showActionMenu: val });
    } else if(key === 'views') {
      this.setState({ viewMenuHasFocus: val, showViewsMenu: val });
    } else if(key === 'subtext_font_tests'){
      this.setState({ subtextFontTestsMenuHasFocus: val, showSubtextFontTestsMenu: val })
    } else if(key === 'partners'){
      this.setState({ partnersHasFocus: val, partnersHasFocus: val })
    }
  }

  onClickViewItem(viewStatus) {
    viewStatus = (this.state.viewStatus === ViewStatusOptions.IDENTIFIED) ? ViewStatusOptions.DEIDENTIFIED : ViewStatusOptions.IDENTIFIED;
    this.setState({viewStatus});
    this.props.onToggleViewClick(viewStatus);
  }

  toggleLegend(showLegend) {
    this.setState({showLegend});
    this.props.onToggleLegend(showLegend);
  }

  togglePedigreeNotes(showPedigreeNotes){
    this.setState({showPedigreeNotes});
    this.props.onTogglePedigreeNotes(showPedigreeNotes);
  }

  toggleAncestry(showAncestry) {
    this.setState({showAncestry});
    this.props.onToggleAncestry(showAncestry);
  }

  toggleBlackAndWhite(showBlackAndWhite) {
    this.setState({showBlackAndWhite});
    this.props.onToggleBlackAndWhite(showBlackAndWhite);
  }

  toggleGeneticTesting(showGeneticTesting) {
    this.setState({showGeneticTesting});
    this.props.onToggleGeneticTesting(showGeneticTesting);
  }

  toggleNotes(showNotes){
    this.setState({showNotes});
    this.props.onToggleNotes(showNotes);
  }

  disbaleReadOnly(){
    if(this.props.readOnlyUser && this.props.readOnlyUser === true) {
      return false
    }
    return true
  }

  updateZoomValue(zoom) {
    this.setState({zoom: zoom});
  }

  calculateZoomPercent() {
    const raw_percent = this.state.zoom / DefaultZoom;
    const percent = raw_percent.toFixed(2);

    return Math.round(percent * 100);
  }

  handleShrinkChanged(e) {
    const checked = e.target.checked;
    this.setState({alwaysShrink: checked});
    this.props.onShrinkCheck(checked);
  }

  async handlePartnersOptionClicked(option){
    let sameOption = (this.state.showAllPartners && option === PartnerOptions.SHOW_ALL_PARTNERS) || (this.state.showAffectedPartners && option === PartnerOptions.SHOW_AFFECTED_PARTNERS) || (this.state.hidePartners && option === PartnerOptions.HIDE_PARTNERS)

    if (option === PartnerOptions.SHOW_ALL_PARTNERS){
      this.setState({showAffectedPartners: false})
      this.setState({hidePartners: false})
      this.setState({showAllPartners: true})
    }
    else if (option === PartnerOptions.SHOW_AFFECTED_PARTNERS){
      this.setState({showAllPartners: false})
      this.setState({hidePartners: false})
      this.setState({showAffectedPartners: true})
    }
    else {
      this.setState({showAllPartners: false})
      this.setState({showAffectedPartners: false})
      this.setState({hidePartners: true})
    }
    set_cookie(CookieKeys.PARTNER_OPTION, option)

    // if (sameOption){
    //   await this.props.reRenderPedigree();
    // }
    // else{
    //   await this.props.redrawToOriginalPositions();
    // }

    let collapse = false
    if (option === PartnerOptions.SHOW_ALL_PARTNERS){
      await this.props.reRenderPedigree(collapse);
    }
    else if(option === PartnerOptions.SHOW_AFFECTED_PARTNERS || option === PartnerOptions.HIDE_PARTNERS){
      collapse = true;
      await this.props.reRenderPedigree(collapse);
    }

  }

  toggleProgenyArchivedData(showProgenyArchivedData) {
    this.setState({showProgenyArchivedData});
    this.props.onToggleShowProgenyArchivedData(showProgenyArchivedData);
  }

  render() {
    const show_action_menu = this.state.showActionMenu ? 'open' : '';
    const show_zoom_controls = this.state.showZoomControl ? 'open' : '';
    const show_views_menu = this.state.showViewsMenu ? 'open' : '';
    const show_partners_dropdown_menu = this.state.showPartnersDropdown ? 'open' : '';
    const show_subtext_font_tests_menu = this.state.showSubtextFontTestsMenu ? 'open' : '';

    const table_view_url = route_helper.family.family_history.replace(':member_id', this.props.probandMemberId);

    return (
      <div className="patient-fam patient-fam-tools-xxx">
      {this.disbaleReadOnly() &&(
        <ul className="nav nav-pills">
          {!this.props.renderPatientPedigree &&
          <li id='views-control-panel' role="presentation" className={"dropdown " + show_views_menu} >
            <a onClick={this.handleViewsMenuClicked} className="dropdown-toggle action-menu">
              <i className="fa fa-desktop"></i> Views
            </a>
            <ul
              onMouseEnter={() => this.setMenuFocus('views', true)}
              onMouseLeave={() => this.setMenuFocus('views', false)}
              className="dropdown-menu">
              <li className={(this.state.viewStatus == ViewStatusOptions.IDENTIFIED) ? 'view-active': ''}>
                <a onClick={() => this.onClickViewItem(ViewStatusOptions.IDENTIFIED)}>
                  <i className={(this.state.viewStatus == ViewStatusOptions.IDENTIFIED) ? "fa fa-check" : 'view-unchecked'}></i>
                    Names
                </a>
              </li>
              <li className={this.state.showNotes ? 'view-active': ''}>
                <a onClick={() => {
                    this.toggleNotes(!this.state.showNotes)
                  }}>
                  <i className={this.state.showNotes ? "fa fa-check" : 'view-unchecked'}></i>
                  Notes
                </a>
              </li>
              <li className={this.state.showPedigreeNotes ? 'view-active': ''}>
                <a onClick={() => {
                    this.togglePedigreeNotes(!this.state.showPedigreeNotes)
                  }}>
                  <i className={this.state.showPedigreeNotes ? "fa fa-check" : 'view-unchecked'}></i>
                  Pedigree Notes
                </a>
              </li>
              <li className={this.state.showGeneticTesting ? 'view-active': ''}>
                <a onClick={() => {
                    this.toggleGeneticTesting(!this.state.showGeneticTesting)
                  }}>
                  <i className={this.state.showGeneticTesting ? "fa fa-check" : 'view-unchecked'}></i>
                  Genetic Testing
                </a>
              </li>
              <li className={this.state.showLegend ? 'view-active': ''}>
                <a onClick={() => {
                    this.toggleLegend(!this.state.showLegend)
                  }}>
                  <i className={this.state.showLegend ? "fa fa-check" : 'view-unchecked'}></i>
                  Legend
                </a>
              </li>
              <li className={this.state.showBlackAndWhite ? 'view-active': ''}>
                <a onClick={() => {
                    this.toggleBlackAndWhite(!this.state.showBlackAndWhite)
                  }}>
                  <i className={this.state.showBlackAndWhite ? "fa fa-check" : 'view-unchecked'}></i>
                  Black/White
                </a>
              </li>
              <li className={this.state.showAncestry ? 'view-active': ''}>
                <a onClick={() => {
                    this.toggleAncestry(!this.state.showAncestry)
                  }}>
                  <i className={this.state.showAncestry ? "fa fa-check" : 'view-unchecked'}></i>
                  Ancestry
                </a>
              </li>
              <li id='partners-control-panel' role="presentation" className={"dropdown " + show_partners_dropdown_menu} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <a onMouseEnter={() => this.handlePartnersMenuHovered()} className="dropdown-toggle action-menu" style={{position: 'absolute', left: 25}}>
                  Partners
                </a>
                  <ul
                    onMouseEnter={() => {
                      this.setMenuFocus('partners', true)
                    }}
                    onMouseLeave={() => {
                      this.setMenuFocus('partners', false)
                      this.handlePartnersMenuMouseLeft()
                    }}
                    className="dropdown-menu" style={{position: 'absolute', transform: 'translate3d(140px, 0px, 0px)', top: 0, left: 0, willChange: 'transform'}}>
                    <li>
                      <i className={this.state.showAllPartners ? "fa fa-check" : 'view-unchecked'}></i>
                      <a onClick={() => this.handlePartnersOptionClicked(PartnerOptions.SHOW_ALL_PARTNERS)}>Show all partners</a>
                    </li>
                    <li>
                      <i className={this.state.showAffectedPartners ? "fa fa-check" : 'view-unchecked'}></i>
                      <a onClick={() => this.handlePartnersOptionClicked(PartnerOptions.SHOW_AFFECTED_PARTNERS)}>Show affected partners</a>
                    </li>
                    <li>
                      <i className={this.state.hidePartners ? "fa fa-check" : 'view-unchecked'}></i>
                      <a onClick={() => this.handlePartnersOptionClicked(PartnerOptions.HIDE_PARTNERS)}>Hide partners</a>
                    </li>
                  </ul>
                <i style={{position: 'absolute', right: 10}} className="fa fa-angle-right"></i>
              </li>
              {this.props.showProgenyArchivedDataOption && (
                <li className={this.state.showProgenyArchivedData ? 'view-active': ''}>
                  <a onClick={() => {
                      this.toggleProgenyArchivedData(!this.state.showProgenyArchivedData)
                    }}>
                    <i className={this.state.showProgenyArchivedData ? "fa fa-check" : 'view-unchecked'}></i>
                    Archived Data
                  </a>
                </li>
              )}
              <li>
                <Link to={table_view_url}>
                  <i className='view-unchecked'></i>
                  <span>Table View</span>
                </Link>
              </li>
            </ul>

          </li>}
          {!this.props.renderPatientPedigree &&
          <li id='actions-menu-panel' role="presentation" className={"dropdown " + show_action_menu} >
            <a onClick={this.handleActionMenuClicked} className="dropdown-toggle action-menu">
              <i className="fa fa-navicon"></i> Actions
            </a>
            <ul
              onMouseEnter={() => this.setMenuFocus('action', true)}
              onMouseLeave={() => this.setMenuFocus('action', false)}
              className="dropdown-menu"
            >
              <li>
                <a onClick={() => this.props.onPDFFromApi(true)}>
                  Print Pedigree (Fit to One Page)</a></li>
              <li>
                <a onClick={() => this.props.onPDFFromApi(false)}>
                  Print Pedigree (Split Across Multiple Pages)</a></li>
              <li>
                <a onClick={() => this.props.onSaveImageClick()}>
                  Save as image</a></li>
            </ul>
          </li>}

          <li id="zoom-control-panel" role="presentation" className={"dropdown " + show_zoom_controls} >

            <a onClick={this.handleZoomControlClicked} className="dropdown-toggle">
              <i onClick={() => this.props.onZoomOutClick()} className="fa fa-search-minus text-white"></i>
              {this.calculateZoomPercent()}%
              <i onClick={() => this.props.onZoomInClick()} className="fa fa-search-plus text-white"></i>
            </a>

            <ul
              onMouseEnter={() => this.setMenuFocus('zoom', true)}
              onMouseLeave={() => this.setMenuFocus('zoom', false)}
              className="dropdown-menu ">
              <li>
                <div className="zoom-controls">
                  <div className="margin-one">
                    <div className='btn-spacer'>
                      <button
                        onClick={() => this.props.onFitToScreenClick()}
                        type="button"
                        className="btn btn-gray-l btn-small btn-block"
                      >
                        Fit to Screen
                      </button>
                    </div>
                  </div>
                  <div className="margin-one">
                    <div className='btn-spacer'>
                      <button
                        onClick={() => this.props.onActualSizeClick()}
                        type="button"
                        className="btn btn-gray-l btn-small btn-block"
                      >
                        Actual Size
                      </button>
                    </div>
                  </div>
                  <label className="checkbox-inline">
                    <input type="checkbox"
                      checked={this.state.alwaysShrink}
                      onChange={this.handleShrinkChanged}
                    /> Always Shrink to Fit
							    </label>
                </div>
              </li>
            </ul>

          </li>

          <li id='redraw-button'>
            <a onClick={async() => await this.props.redrawToOriginalPositions()}>
              <i style={{marginRight: 0}} className="fa fa-repeat"></i> Redraw
            </a>
          </li>

          {this.props.renderPatientPedigree && (
            <li id='legend-button'>
              <a onClick={() => this.toggleLegend(!this.state.showLegend)}>
                <i style={{marginRight: 0}} className="fa fa-list-ul"></i> Legend
              </a>
            </li>
          )}

        </ul>
      )}

      {!this.disbaleReadOnly() &&(
        <ul className="nav nav-pills">
          <li id='views-control-panel' role="presentation" className={"dropdown " + show_views_menu} >
            <a onClick={this.handleViewsMenuClicked} className="dropdown-toggle action-menu">
              <i className="fa fa-desktop"></i> Views
            </a>
            <ul
              onMouseEnter={() => this.setMenuFocus('views', true)}
              onMouseLeave={() => this.setMenuFocus('views', false)}
              className="dropdown-menu">

              <li className={this.state.showPedigreeNotes ? 'view-active': ''}>
                <a onClick={() => {
                    this.togglePedigreeNotes(!this.state.showPedigreeNotes)
                  }}>
                  <i className={this.state.showPedigreeNotes ? "fa fa-check" : 'view-unchecked'}></i>
                  Pedigree Notes
                </a>
              </li>

              <li className={this.state.showLegend ? 'view-active': ''}>
                <a onClick={() => {
                    this.toggleLegend(!this.state.showLegend)
                  }}>
                  <i className={this.state.showLegend ? "fa fa-check" : 'view-unchecked'}></i>
                  Legend
                </a>
              </li>
              <li id='partners-control-panel' role="presentation" className={"dropdown " + show_partners_dropdown_menu} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <a onMouseEnter={() => this.handlePartnersMenuHovered()} className="dropdown-toggle action-menu" style={{position: 'absolute', left: 25}}>
                  Partners
                </a>
                  <ul
                    onMouseEnter={() => {
                      this.setMenuFocus('partners', true)
                    }}
                    onMouseLeave={() => {
                      this.setMenuFocus('partners', false)
                      this.handlePartnersMenuMouseLeft()
                    }}
                    className="dropdown-menu" style={{position: 'absolute', transform: 'translate3d(140px, 0px, 0px)', top: 0, left: 0, willChange: 'transform'}}>
                    <li>
                      <i className={this.state.showAllPartners ? "fa fa-check" : 'view-unchecked'}></i>
                      <a onClick={() => this.handlePartnersOptionClicked(PartnerOptions.SHOW_ALL_PARTNERS)}>Show all partners</a>
                    </li>
                    <li>
                      <i className={this.state.showAffectedPartners ? "fa fa-check" : 'view-unchecked'}></i>
                      <a onClick={() => this.handlePartnersOptionClicked(PartnerOptions.SHOW_AFFECTED_PARTNERS)}>Show affected partners</a>
                    </li>
                    <li>
                      <i className={this.state.hidePartners ? "fa fa-check" : 'view-unchecked'}></i>
                      <a onClick={() => this.handlePartnersOptionClicked(PartnerOptions.HIDE_PARTNERS)}>Hide partners</a>
                    </li>
                  </ul>
                <i style={{position: 'absolute', right: 10}} className="fa fa-angle-right"></i>
              </li>
              {this.props.showProgenyArchivedDataOption && (
                <li className={this.state.showProgenyArchivedData ? 'view-active': ''}>
                  <a onClick={() => {
                      this.toggleProgenyArchivedData(!this.state.showProgenyArchivedData)
                    }}>
                    <i className={this.state.showProgenyArchivedData ? "fa fa-check" : 'view-unchecked'}></i>
                    Archived Data
                  </a>
                </li>
              )}

            </ul>

          </li>

          <li id="zoom-control-panel" role="presentation" className={"dropdown " + show_zoom_controls} >

            <a onClick={this.handleZoomControlClicked} className="dropdown-toggle">
              <i onClick={() => this.props.onZoomOutClick()} className="fa fa-search-minus text-white"></i>
              {this.calculateZoomPercent()}%
              <i onClick={() => this.props.onZoomInClick()} className="fa fa-search-plus text-white"></i>
            </a>

            <ul
              onMouseEnter={() => this.setMenuFocus('zoom', true)}
              onMouseLeave={() => this.setMenuFocus('zoom', false)}
              className="dropdown-menu ">
              <li>
                <div className="zoom-controls">
                  <div className="margin-one">
                    <div className='btn-spacer'>
                      <button
                        onClick={() => this.props.onFitToScreenClick()}
                        type="button"
                        className="btn btn-gray-l btn-small btn-block"
                      >
                        Fit to Screen
                      </button>
                    </div>
                  </div>
                  <div className="margin-one">
                    <div className='btn-spacer'>
                      <button
                        onClick={() => this.props.onActualSizeClick()}
                        type="button"
                        className="btn btn-gray-l btn-small btn-block"
                      >
                        Actual Size
                      </button>
                    </div>
                  </div>
                  <label className="checkbox-inline">
                    <input type="checkbox"
                      checked={this.state.alwaysShrink}
                      onChange={this.handleShrinkChanged}
                    /> Always Shrink to Fit
							    </label>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      )}
      </div>
    );

  }
}

export default PedigreeToolbar
