import { user_save } from '../store/session/actions';
import { save_risk_settings } from '../store/patient/actions';

const save_login_data = (user: object, login_data: object, dispatch: any) => {
  const clone = Object.assign({}, user);
  const is_admin = (login_data.account.access_type === 'admin') ? true : false;
  const is_read_only = (login_data.account.access_type === 'read_only') ? true : false;
  const auto_logout_time = login_data.account.auto_logout_time ? login_data.account.auto_logout_time : 15;
  dispatch(user_save({
    clinician_id: login_data.organization.clinician_id,
    account_id: login_data.account.account_id,
    staff_id: login_data.account.staff_id,
    user_id: login_data.profile.user_id,
    first_name: login_data.profile.first_name,
    last_name: login_data.profile.last_name,
    email: login_data.profile.email,
    username: login_data.profile.username,
    admin:  is_admin,
    account_type: login_data.organization.org_type,
    organization_id: login_data.organization.id,
    org_clinicians: login_data.organization.clinicians,
    risk_model_years: login_data.organization.risk_model_years,
    dial_code: login_data.organization.dial_code,
    read_only: is_read_only,
    criteria_source_category_settings: login_data.criteria_source_category_settings,
    criteria_source_categories: login_data.criteria_source_categories,
    emr_always_read_only: login_data.organization.emr_always_read_only,
    login_type: login_data.account.login_type,
    auto_logout_time: auto_logout_time,
    default_partner_view: login_data.organization.default_partner_view
  }));

  dispatch(save_risk_settings(login_data.risk_setting));
};

export {
	save_login_data
};
