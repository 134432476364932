import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { cloneDeep } from 'lodash'

import helpers from '../helpers/index';
import route_helper from '../route-helper';
import NavTop from '../components/nav-top';
import ValidationErrorSummary from '../components/validation-error-summary';
import Select from 'react-select';
import { RadioGroup, Radio, CheckboxGroup, Checkbox } from 'react-ui-icheck';
import settings from '../configs/settings';
import account_api from '../api/user-account-api';
import file_api from '../api/file-api';
import axios from 'axios';
import IntlTelInput from 'react-intl-tel-input';
import * as countryCodes from '../components/patient-profile/patient-data-tabs/dial-code'

import mime_types from '../configs/mime_types';

const types = ["trial_org"];

class NewAccountPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      orgs: null,
      validations: null,
      org: {
        organizationName: '',
        superOrgId: null,
        clinicianCode: '',
        locked: false,
        accountType: null,
        unitSystem: '',
        dialingCode: '',
        license: {
          boadicea: false,
          gail: true,
          claus: true,
          tyrer_cuzick: false,
          brcapro: false,
          mmrpro: false,
          pancpro: false,
          melapro: false
        },
        passwordResetTime: {value: 90, label: '90 Days'},
        autoLogoutTime: {value: 15, label: '15 minutes'},
        orgLogo: null,
        show_gender_identity: false,
        race_ethnicity_display: settings.app_constants.ashkanaziAndRace,
        fiveYearChecked: false,
        tenYearChecked: false,
        number_of_licenses: null,
        trial_expiration: null,
        default_partner_view: settings.app_constants.defaultPartnerViewOptions.show_all_partners,
      },
      from_super_org_page: false,
      is_trial_org: false,
      validCode: true,
      uniqueName: true,
      surveys: [],
      survey_options: [],
    };

    this.onChange = this.onChange.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onSaveAccount = this.onSaveAccount.bind(this);
    this.validate = this.validate.bind(this);
    this.getAccountType = this.getAccountType.bind(this);
    this.isBlank = this.isBlank.bind(this);
    this.constructAndPostOrgs = this.constructAndPostOrgs.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.saveLogoUrl = this.saveLogoUrl.bind(this);
    this.routeToOrgDetails = this.routeToOrgDetails.bind(this);
    this.fileInputLabel = React.createRef();
  }

  async componentDidMount() {
    // TODO: create endpoint that pulls only super orgs
    const orgs = await account_api.get_orgs();

    this.setState({orgs: orgs});
    helpers.setSiteTitle('New Account');

    let survey_options = await account_api.get_surveys();
    survey_options = survey_options.map((survey) => {
      return {value: survey.id, label: survey.name}
    });
    this.setState({survey_options: survey_options});

    const provided_state = this.props.location.state;
    if(provided_state && provided_state.superOrgId) {
      this.setState({
        org: {
          ...this.state.org,
          superOrgId: provided_state.superOrgId,
          accountType: settings.app_constants.subOrg
        },
        from_super_org_page: true
      });
    }
  }

  async onChange(event) {
    let name  = event.target.name;
    if(name === 'claus' || name === 'gail') return;
    let data = '';
    if (event.target.type === 'checkbox') {
      data = event.target.checked;
    } else {
      data = event.target.value;
    }
    let org = cloneDeep(this.state.org)
    if (name === 'boadicea' || name === 'tyrer_cuzick'
        || name === 'brcapro' || name === 'mmrpro'
        || name === 'pancpro' || name === 'melapro') {
          org.license[name] = data;
    } else {
      org[name] = data;
    }
    this.setState({org}, () => console.log(this.state.org));

    await this.changeValidations(name, data);
  }

  async changeValidations(name, data) {
    // Code validation
    if(name === 'clinicianCode') {
      try {
        await account_api.validate_org_code({[name]: data});
        this.setState({validCode: true});
      } catch(error){
        console.log(error);
        this.setState({validCode: false});
      }
    }

    // Org Validation
    if(name === 'organizationName') {
      try {
        let response = await account_api.validate_org_name({[name]: data});
        if(response && response.message) this.setState({uniqueName: false});
        else this.setState({uniqueName: true});
      } catch (error) {
        console.log(error);
      }
    }
  }

  onFilesAdded(event) {
    const file = event.target.files[0];
    if(file !== null && file !== undefined && file !== '') {
      if (mime_types.VALID_LOGO_MIME_TYPES.includes(file.type)) {
        this.fileInputLabel.current.innerText = "File: " + event.target.value.substr(12, event.target.value.length);
        let org = cloneDeep(this.state.org);
        org.orgLogo = file;
        this.setState({org});
      }
    }
  }

  async routeToOrgDetails(org_type, org_id) {
    if(org_type === settings.app_constants.subOrg.value) {
      this.props.history.push(route_helper.administrative.fh_sub_account.replace(':org_id', org_id));
    } else {
      this.props.history.push(route_helper.administrative.fh_super_account.replace(':org_id', org_id));
    }
  }

  getRiskModelYears = (check1, check2) => {
    if (check1 && check2) {
      return 'both'
    } else if (check2) {
      return 'ten-year'
    } else {
      return 'five-year'
    }
  }

  async onSaveAccount(event) {
    // check and make sure the user has chosen the required fields before letting them save a document
      // do not delete newLogo its used in s OnClickSave in Ancestor
      const riskModelYears = this.getRiskModelYears(this.state.org.fiveYearChecked, this.state.org.tenYearChecked)
      this.state.org.risk_model_years = riskModelYears;
    const isValid = this.validate();
    if (!isValid) return;

    await this.constructAndPostOrgs(this.state.org);
  }

  async constructAndPostOrgs(account) {
    const org_payload = {
      org_name: account.organizationName,
      org_type: account.accountType.value,
      units: account.unitSystem,
      dial_code: account.dialingCode,
      password_reset_timeframe: account.passwordResetTime.value,
      auto_logout_time: account.autoLogoutTime.value,
      logo: null,
      code: account.clinicianCode,
      parent_org: account.superOrgId,
      boadicea: account.license.boadicea,
      tyrer_cuzick: account.license.tyrer_cuzick,
      gail: account.license.gail,
      claus: account.license.claus,
      brcapro: account.license.brcapro,
      mmrpro: account.license.mmrpro,
      pancpro: account.license.pancpro,
      melapro: account.license.melapro,
      show_gender_identity: account.show_gender_identity,
      race_ethnicity_display: account.race_ethnicity_display.value,
      risk_model_years: account.risk_model_years,
      // risk_model_five_years: account.fiveYearChecked,
      // risk_model_ten_years: account.tenYearChecked,
      number_of_licenses: account.number_of_licenses,
      trial_expiration: account.trial_expiration,
      default_partner_view: account.default_partner_view.value
    }
    const data = await account_api.post_new_organization(org_payload);
    const org = data.org;

    // add new survey org
    const clin_id = data.clinician_code.id;
    const survey_ids = this.state.surveys.map((survey) => {
      return survey.value
    });

    if (survey_ids.length > 0) {
      const survey_orgs = await account_api.save_org_surveys(clin_id, survey_ids);
    }

    if(account.orgLogo) {
      await this.uploadFiles(account.orgLogo, org);
    } else {
      this.routeToOrgDetails(this.getAccountType(account.accountType), org.id);
    }
  }

  async uploadFiles(org_logo, org) {
    // Send data to ap server for presigned url
    // uplaod to S3
    // if successful save the logo url to the orgs url field
    // if unsuccessful then delete the file record that was created in the DB

    let response = await file_api.upload_org_logo({
      original_filename: org_logo.name,
      custom_filename: org_logo.name,
      mime_type: org_logo.type,
      file_size: org_logo.size,
      file_note: null,
      file_category: null
    });

    if ('data' in response) {

      const aws_s3 = response.data.s3;
      const public_url = aws_s3.url + aws_s3.fields.key;
      const formData = new FormData();
      for (const key in aws_s3.fields) {
        formData.append(key, aws_s3.fields[key]);
      }
      formData.append("file", org_logo, org_logo.name);

      axios.post(aws_s3.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',

          // setting this to blank because somewhere
          // (i think in survey api) the default Authorization header
          // is being set and interferring with this request
          'Authorization': ''
        }
      })
      .then((response) => {
        // success
        this.saveLogoUrl(public_url, org);
      })
      .catch((error) => {
        // delete the record in s3files table in db because the post to S3 failed
        // we do not want a lingering record not pointing to an actual S3 file
        file_api.delete_user_file_from_db(response.data.file_record.id);
        this.routeToOrgDetails(org.org_type, org.id);
        return null;
      });
    }

    return null;
  }

  async saveLogoUrl(public_url, org) {
    if(public_url) {
      // send saved data to the server
      const json = {
        org_name: org.org_name,
        org_type: org.org_type,
        units: org.units,
        dial_code: org.dial_code,
        password_reset_timeframe: org.password_reset_timeframe,
        logo: public_url,
        is_locked: org.is_locked,
        parent_org: org.parent_org,
        race_ethnicity_display: org.race_ethnicity_display
      };
      const org_data = await account_api.patch_organization(org.id, json);
    }
    this.routeToOrgDetails(org.org_type, org.id);
  }

  validate() {
    this.setState({ validations: null });

    let options = {
      accountType: {
        labelName: 'Account Type',
        validations: {
          required: true
        }
      },
      organizationName: {
        labelName: 'Company Name',
        validations: {
          required: true
        }
      },
      clinicianCode: {
        labelName: 'Clinician Code',
        validations: {
          required: true
        }
      },
      passwordResetTime: {
        labelName: 'Password Reset Time',
        validations: {
          required: true
        }
      },
      autoLogoutTime: {
        labelName: 'Auto-Logout Time',
        validations: {
          required: true
        }
      },
      unitSystem: {
        labelName: 'Units',
        validations: {
          required: true
        }
      },
      race_ethnicity_display:{
        labelName: 'Race/Ethnicity Screen',
        validations: {
          required: true
        }
      },
      default_partner_view:{
        labelName: 'Default partner view',
        validations: {
          required: true
        }
      }
    };

    if (types.includes(this.state.org.accountType.value)) {
      options = {
        ...options,
        trial_expiration: {
          labelName: 'Trial Expiration',
          validations: {
            required: true
          }
        }
      };
    }

    let validations = helpers.validateInputs(this.state.org, options);
    this.setState({ validations: validations });
    return validations.isValid;
  }

  getAccountType(account_type) {
    if(account_type !== null && account_type !== undefined) {return account_type.value;}
    return null;
  }

  isBlank(val) {
    if(val !== null && val !== undefined && typeof(val) === 'string' && val.trim() !== '') {return false;}
    return true;
  }

  render() {
    let { fiveYearChecked, tenYearChecked } = this.state.org;
    const new_sub_org = (this.getAccountType(this.state.org.accountType) === settings.app_constants.subOrg.value) ? true : false;
    let org_fragment = (<div className="form-group">
      <label htmlFor="organizationName" className="control-label col-md-4 col-sm-3 col-xs-12">Company Name</label>
      <div className="col-md-8 col-sm-9 col-xs-12">
        <input name="organizationName" id="organizationName" onChange={this.onChange} value={this.state.org.organizationName} type="text" className="form-control normal-input-text" />
        {!this.state.uniqueName && (
          <div className="validation-inline__error-text validation-warning">
            Warning: This Company Name is already in use.
          </div>
        )}
      </div>
    </div>);

    if(new_sub_org) {
      let val = '';
      let super_org_options = [];
      const super_orgs = this.state.orgs.filter((org) => org.org_type === "super_org");

      if(this.state.from_super_org_page) {
        const super_org = super_orgs.find((org) => this.state.org.superOrgId === org.id);
        if(super_org) {
          val = {value: super_org.id, label: super_org.org_name};
          super_org_options = [
            {value: super_org.id, label: super_org.org_name}
          ];
        }
      } else {
        super_org_options = super_orgs.map((org) => {
          if(this.state.org.superOrgId === org.id) {
            val = {value: org.id, label: org.org_name};
          }

          return {value: org.id, label: org.org_name};
        });
      }

      org_fragment = (<React.Fragment>
        <div className="form-group">
          <label htmlFor="superOrgId" className="control-label col-md-4 col-sm-3 col-xs-12">Super Org</label>
          <div className="col-md-8 col-sm-9 col-xs-12">
            <Select
              name="superOrgId"
              id="superOrgId"
              onChange={(item) => {
                let org = cloneDeep(this.state.org);
                org.superOrgId = (item !== null && item !== undefined) ? item.value : null;
                this.setState({org});
              }}
              value={val}
              className='react-select-container'
              classNamePrefix="react-select"
              isClearable={!this.state.from_super_org_page}
              placeholder=""
              options={super_org_options}
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="subOrgName" className="control-label col-md-4 col-sm-3 col-xs-12">Company Name</label>
          <div className="col-md-8 col-sm-9 col-xs-12">
            <input name="organizationName" id="organizationName" onChange={this.onChange} value={this.state.org.organizationName} type="text" className="form-control normal-input-text" />
            {!this.state.uniqueName && (
              <div className="validation-inline__error-text validation-warning">
                Warning: This Company Name is already in use.
              </div>
            )}
          </div>
        </div>
      </React.Fragment>);
    }

    let account_type_options = [
      settings.app_constants.superOrg,
      settings.app_constants.subOrg,
      settings.app_constants.standardOrg,
      settings.app_constants.trialOrg,
      settings.app_constants.freeOrg,
      settings.app_constants.studentOrg
    ];

    let race_ethnicity_options = [
      settings.app_constants.ashkanaziAndRace,
      settings.app_constants.ashkanaziOnly,
      settings.app_constants.hideAshkanaziAndRace
    ]

    let default_partner_view_options = [
      settings.app_constants.defaultPartnerViewOptions.show_all_partners,
      settings.app_constants.defaultPartnerViewOptions.show_affected_partners,
      settings.app_constants.defaultPartnerViewOptions.hide_partners
    ]

    if(this.state.from_super_org_page) {
      account_type_options = [
        settings.app_constants.subOrg
      ];
    }

    let save_disabled = (this.state.validCode) ? "" : " disabled"

    return (
      <React.Fragment>

        <NavTop history={this.props.history} />

        <section className="wow fadeIn section animated" style={{paddingBottom: "0px", visibility: "visible", animationName: "fadeIn"}}>
        	<div className="container">
        		<div className="section-header">
        			<div className="row vcenter">
        				<div className="col-md-6 col-xs-12">
        					<h2>New Account Details</h2>
        				</div>
        				<div className="col-md-4 col-md-offset-2 justify-right hidden"></div>
        			</div>
        		</div>

            <div className="form-horizontal">
              <ValidationErrorSummary
                validations={this.state.validations}
              />

        			<div className="row">
        				<div className="col-md-12">
        					<div className="padding-left-right">
        						<div className="form-group">
        							<label className="control-label col-md-4 col-sm-3 col-xs-12">Account Type</label>
        							<div className="col-md-8 col-sm-9 col-xs-12">
                        <Select
                          name="accountType"
                          id="accountType"
                          onChange={(item) => {
                            let is_trial_org = false;
                            if (item) {
                              if (types.includes(item.value)) {
                                is_trial_org = true;
                              }
                            }
                            let org = cloneDeep(this.state.org);
                            org.accountType = item;
                            this.setState({org: org, is_trial_org: is_trial_org});
                          }}
                          value={this.state.org.accountType}
                          className='react-select-container'
                          classNamePrefix="react-select"
                          isClearable={!this.state.from_super_org_page}
                          placeholder=""
                          options={account_type_options}
                        />
        							</div>
        						</div>

      					    {org_fragment}

        						<div className="form-group">
        							<label htmlFor="clinicianCode" className="control-label col-md-4 col-sm-3 col-xs-12">Clinician Code</label>
        							<div className="col-md-8 col-sm-9 col-xs-12">
        								<input name="clinicianCode" id="clinicianCode" onChange={this.onChange} value={this.state.org.clinicianCode} type="text" className="form-control normal-input-text" />
                        {!this.state.validCode && (
                        <div className="validation-inline__error-text validation-warning">
                          <i className='fa fa-times validation-inline__error-text'></i>
                          This Clinician Code is already in use.
                        </div>
                        )}
        							</div>
        						</div>

                    <div className="form-group">
                      <div className="col-md-4 col-sm-3 col-xs-12">
                        <label htmlFor="surveys" className="control-label">Surveys</label>
                      </div>
                      <div className="col-md-8 col-sm-9 col-xs-12 ">
                        <Select
                          value={this.state.surveys}
                          name="surveys"
                          onChange={(item) => {
                            this.setState({ surveys: (item ? item : null ) });
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          isClearable={true}
                          isMulti={true}
                          placeholder=""
                          options={this.state.survey_options}
                        />
                      </div>
                    </div>

                    <div className="form-group">
        							<label htmlFor="clinicianCode" className="control-label col-md-4 col-sm-3 col-xs-12">Number of Licenses</label>
        							<div className="col-md-3 col-sm-9 col-xs-12">
        								<input name="number_of_licenses" id="number_of_licenses" onChange={this.onChange} value={this.state.number_of_licenses} type="number" className="form-control normal-input-text" />
        							</div>
        						</div>

                    {this.state.is_trial_org &&(
                      <div className="form-group">
        							  <label className="control-label col-md-4 col-sm-3 col-xs-12">Trial Expiration</label>
        							  <div className="col-md-3 col-sm-9 col-xs-12">
        							  	<input name="trial_expiration" id="trial_expiration" onChange={this.onChange} value={this.state.org.trial_expiration} type="date" className="form-control normal-input-text" />
        							  </div>
        						  </div>
                    )}

        						<div className="form-group">
        							<label className="control-label col-md-4 col-sm-3 col-xs-12">License Details</label>
        							<div className="col-md-8 col-sm-9 col-xs-12">
        								<div className="checkbox">
        									<label>
        										<input type="checkbox" name="boadicea" id="boadicea" onChange={this.onChange} checked={this.state.org.license.boadicea} />	BOADICEA
        									</label>
        								</div>

        								<div className="checkbox">
        									<label>
        										<input type="checkbox" name="tyrer_cuzick" id="tyrer_cuzick" onChange={this.onChange} checked={this.state.org.license.tyrer_cuzick} /> TYRER-CUZICK
        									</label>
        								</div>

        								<div className="checkbox">
        									<label>
        										<input type="checkbox" name="gail" id="gail" onChange={this.onChange} checked={this.state.org.license.gail} /> GAIL
        							   	</label>
        								</div>

        								<div className="checkbox">
        									<label>
        										<input type="checkbox" name="claus" id="claus" onChange={this.onChange} checked={this.state.org.license.claus} /> CLAUS
        								  </label>
        								</div>

                        <div className="checkbox">
        									<label>
        										<input type="checkbox" name="brcapro" id="brcapro" onChange={this.onChange} checked={this.state.org.license.brcapro} /> BRCAPRO
        								  </label>
        								</div>

                        <div className="checkbox">
        									<label>
        										<input type="checkbox" name="mmrpro" id="mmrpro" onChange={this.onChange} checked={this.state.org.license.mmrpro} /> MMRPRO
        								  </label>
        								</div>

                        <div className="checkbox">
        									<label>
        										<input type="checkbox" name="pancpro" id="pancpro" onChange={this.onChange} checked={this.state.org.license.pancpro} /> PANCPRO
        								  </label>
        								</div>

                        <div className="checkbox">
        									<label>
        										<input type="checkbox" name="melapro" id="melapro" onChange={this.onChange} checked={this.state.org.license.melapro} /> MELAPRO
        								  </label>
        								</div>
        							</div>
        						</div>

                    <div className="form-group">
        							<label className="control-label col-md-4 col-sm-3 col-xs-12">Risk Model Years</label>
        							<div className="col-md-8 col-sm-9 col-xs-12">

                        <CheckboxGroup
                          checkboxWrapClassName="form-check form-check-inline p-0"
                          checkboxWrapTag="div"
                          className="d-flex align-items-center overflow-auto text-nowrap"
                        >

                          <Checkbox
                            checkboxClassName="icheckbox_flat-green check-box-positioning"
                            checked={fiveYearChecked}
                            label="&nbsp;5 year"
                            onChange={(e, value) => {
                              let org = cloneDeep(this.state.org);
                              org.fiveYearChecked = value;
                              this.setState({org});
                            }}
                          />

                          <Checkbox
                            checkboxClass="icheckbox_flat-green"
                            checked={tenYearChecked}
                            label="&nbsp;10 year"
                            onChange={(e, value) => {
                              let org = cloneDeep(this.state.org);
                              org.tenYearChecked = value;
                              this.setState({org});
                            }}
                          />
                        </CheckboxGroup>
        							</div>
        						</div>

                    <div className="form-group">
        							<label className="control-label col-md-4 col-sm-3 col-xs-12">Prompt all for gender identity</label>
                      <div className="col-md-8 col-sm-9 col-xs-12">
        								<div className="checkbox">
                        <label>
        										<input type="checkbox" name="show_gender_identity" id="show_gender_identity" onChange={this.onChange} checked={this.state.show_gender_identity} />Show gender identity
                            and pronoun questions on same screen as biological sex
        									</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
        							<label className="control-label col-md-4 col-sm-3 col-xs-12">Race/Ethnicity Screen</label>
        							  <div className="col-md-8 col-sm-9 col-xs-12" style={{zIndex: 3}}>
                          <Select
                            name="race_ethnicity_options"
                            id="raceEthnicityOptions"
                            onChange={(item) => {
                              let org = cloneDeep(this.state.org);
                              org.race_ethnicity_display = item;
                              this.setState({org});
                            }}
                            value={this.state.org.race_ethnicity_display}
                            className='react-select-container'
                            classNamePrefix="react-select"
                            isClearable={true}
                            placeholder=""
                            options={race_ethnicity_options}
                          />
        							  </div>
        						</div>

                    <div className="form-group" >
        							<label className="control-label col-md-4 col-sm-3 col-xs-12">Default partner view</label>
        							  <div className="col-md-8 col-sm-9 col-xs-12" style={{zIndex: 2}}>
                          <Select
                            name="default_partner_view_options"
                            id="defaultPartnerViewOptions"
                            onChange={(item) => {
                              let org = cloneDeep(this.state.org);
                              org.default_partner_view = item;
                              this.setState({org});
                            }}
                            value={this.state.org.default_partner_view}
                            className='react-select-container'
                            classNamePrefix="react-select"
                            isClearable={true}
                            placeholder=""
                            options={default_partner_view_options}
                          />
        							  </div>
        						</div>

        						<div className="form-group">
        							<label className="control-label col-md-4 col-sm-3 col-xs-12">Units</label>
        							<div className="col-md-8 col-sm-9 col-xs-12">
                        <RadioGroup
                          name="unitSystem"
                          radioWrapClassName="radio radio-group"
                          radioWrapTag="div"
                          value={this.state.org.unitSystem}
                          onChange={(e, value) => {
                            let org = cloneDeep(this.state.org);
                            org.unitSystem = value;
                            this.setState({org});
                          }}
                        >

                          <Radio
                            value='metric'
                            radioClass="iradio_flat-green"
                            label="&nbsp;Metric"
                          />

                          <Radio
                            value="us_standard"
                            radioClass="iradio_flat-green"
                            label="&nbsp;US Standard"
                          />

                        </RadioGroup>
        							</div>
        						</div>

                    <div className="form-group">
        							<label className="control-label col-md-4 col-sm-3 col-xs-12">Default Dialing Code</label>
        							<div className="col-md-8 col-sm-9 col-xs-12 org_dial" style={{zIndex: 1}}>
                        <IntlTelInput
                        onlyCountries={countryCodes.iso2}
                        name="phone_number"
                        id="phone_number"
                        format
                        autoHideDialCode={false}
                        separateDialCode={true}
                        inputClassName="phone-input"
                        defaultCountry="us"
                        onSelectFlag={(num, country) => {
                          let org = cloneDeep(this.state.org)
                          org.dialingCode = country.iso2
                          this.setState({org})
                        }}
                      />
                      </div>
                    </div>
        						<div className="form-group">
        							<label className="control-label col-md-4 col-sm-3 col-xs-12">Customer Logo </label>
        							<div className="col-md-8 col-sm-9 col-xs-12">
        								<div className="row">
        									<div className="col-sm-9">
        										<span className="control-fileupload">
                              <label htmlFor="orgLogo" className="text-left" ref={this.fileInputLabel}>Please choose a file on your computer.</label>
                              <input name="orgLogo" id="orgLogo" type="file"  accept="image/png, image/jpeg"  onChange={this.onFilesAdded} />
        			              </span>
        									</div>
        								</div>
        							</div>
        						</div>

                    <div className="form-group">
        							<label className="control-label col-md-4 col-sm-3 col-xs-12">Password Reset Time</label>
        							<div className="col-md-8 col-sm-9 col-xs-12">
                        <Select
                          name="passwordResetTime"
                          id="passwordResetTime"
                          onChange={(item) => {
                            let org = cloneDeep(this.state.org);
                            org.passwordResetTime = item;
                            this.setState({org});
                          }}
                          value={this.state.org.passwordResetTime}
                          className='react-select-container'
                          classNamePrefix="react-select"
                          isClearable={true}
                          placeholder=""
                          options={[
                            {value: 30, label: '30 Days'},
                            {value: 60, label: '60 Days'},
                            {value: 90, label: '90 Days'},
                            {value: 120, label: '120 Days'},
                            {value: 150, label: '150 Days'},
                            {value: 180, label: '180 Days'},
                          ]}
                        />
        							</div>
        						</div>

                    <div className="form-group">
                      <label className="control-label col-md-4 col-sm-3 col-xs-12">Auto-Logout Time</label>
                      <div className="col-md-8 col-sm-9 col-xs-12">
                        <Select
                          name="autoLogoutTime"
                          id="autoLogoutTime"
                          onChange={(item) => {
                            let org = cloneDeep(this.state.org);
                            org.autoLogoutTime = item;
                            this.setState({org});
                          }}
                          value={this.state.org.autoLogoutTime}
                          className='react-select-container'
                          classNamePrefix="react-select"
                          isClearable={true}
                          placeholder=""
                          options={[
                            {value: 15, label: '15 Minutes'},
                            {value: 30, label: '30 Minutes'},
                            {value: 45, label: '45 Minutes'},
                            {value: 60, label: '1 Hour'},
                            {value: 180, label: '3 Hours'},
                            {value: 480, label: '8 Hours'}
                          ]}
                        />
                      </div>
                    </div>

        					</div>
        				</div>
        			</div>
            </div>

        		<div className="section-footer clearfix margin-bottom-seven">
        			<hr />
        			<button onClick={this.onSaveAccount} className={"btn btn-dark" + save_disabled}><i className="fa fa-arrow-right"></i>Save</button>
        			<Link to={route_helper.administrative.fh_dashboard} className="btn btn-light"><i className="fa fa-arrow-left"></i>Back to accounts</Link>
        		</div>
        	</div>
        </section>



      </React.Fragment>

    );
  }
}

export default NewAccountPage;
