import React, { Component } from 'react';
import lodash from 'lodash';
import { createUUID } from '../react-flow-pedigree/utils';

let init_state = {
  dob_operator: '=',
  dob_from: null,
  dob_to: null,
  age_operator: '=',
  age: null,
  age_to: null ,
  // risk_operator: 'between',
  // risk: '',
  gender: null,
  clinician: null
};

class FilterMore extends Component {
  constructor(props) {
    super(props);

    this.state = init_state;
    this.handleOpenClick = this.handleOpenClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.loadReportSetFilters = this.loadReportSetFilters.bind(this)
  }

  isActive() {
    let active = lodash.isEqual(this.state, init_state) === false;
    //For regex, regex pattern need to be a string, no '/' on beginning and end, and all '\' should be doubled '\\'
    let dateRegex = '^(((0?[1-9]|1[012])/(0?[1-9]|1\\d|2[0-8])|(0?[13456789]|1[012])/(29|30)|(0?[13578]|1[02])/31)/(19|[2-9]\\d)\\d{2}|0?2/29/((19|[2-9]\\d)(0[48]|[2468][048]|[13579][26])|(([2468][048]|[3579][26])00)))$'
    let ageRegex = '^\\d+$'
    let dateRegexF = new RegExp(dateRegex)
    let ageRegexF = new RegExp(ageRegex)

    let datesAreValid = this.state.dob_operator === 'between' ? dateRegexF.test(String(this.state.dob_from)) && dateRegexF.test(String(this.state.dob_to)) : dateRegexF.test(String(this.state.dob_from))
    let agesAreValid = this.state.age_operator === 'between' ? ageRegexF.test(String(this.state.age)) && ageRegexF.test(String(this.state.age_to)) : ageRegexF.test(String(this.state.age))
    let genderIsValid = this.state.gender === 'm' || this.state.gender === 'f' || this.state.gender === 'unknown'

    if (active && (datesAreValid || (agesAreValid && Number(this.state.age) < 130) || genderIsValid) || this.state.clinician) return true;

    return false;
  }

  loadReportSetFilters(filterSets) {
    this.setState({
      dob_operator: filterSets.dob_operator,
      dob_from: filterSets.dob_from,
      dob_to: filterSets.dob_to,
      age_operator: filterSets.age_operator,
      age: filterSets.age,
      age_to: filterSets.age_to,
      gender: filterSets.gender,
      clinician: filterSets.clinician
    })
  }

  handleOpenClick() {
    // Toggle
    if(this.props.open) {
      this.props.onOpen('');
      this.props.handleIsActiveChange('more_filters', this.isActive());
      this.props.setMoreFilterState(lodash.cloneDeep(this.state));
      this.props.changeMoreFilters(lodash.cloneDeep(this.state), this.isActive())
    } else {
      this.props.onOpen('more');
      this.props.setMoreFilterState(lodash.cloneDeep(this.state));
    }
  }

  handleChange(e) {
    //setState callback function
    this.setState({[e.target.name]: e.target.value}, () => {
      this.props.setMoreFilterState(lodash.cloneDeep(this.state))
    });
  }

  render() {

    let styleAND = { paddingTop: 28 }
    let btnClass = this.isActive() ? 'btn-purple' : 'btn-light-outline';

    return (
      <React.Fragment>
        <div className="popover-query-wrapper">

          <button
            onClick={this.handleOpenClick}
            className={"btn btn-xs " + btnClass} >More Filters</button>

          <div id="morePop"
            style={{ display: (this.props.open ? 'block' : 'none') }}
            className="popover popover-medium popover-x popover-default popover-custom kv-popover-active in bottom popover-query">

            <div className="arrow popover-query__arrow"></div>
            <h5 className="popover-header popover-title">
              <span onClick={this.handleOpenClick}
               className="close pull-right">&times;</span>
              Select
            </h5>

            <div className="popover-body popover-content">

              <div className="row margin-four-bottom">

                <div className="col-md-3 col-xs-12 no-padding-right">
                  <label>Date of Birth</label>
                  <select
                    name="dob_operator"
                    placeholder="Operator"
                    value={this.state.dob_operator}
                    onChange={this.handleChange}
                    className="form-control input-sm no-padding-top no-padding-bottom">
                    <option default value="=">=</option>
                    <option value="<=">≤</option>
                    <option value="<">&lt;</option>
                    <option value=">">&gt;</option>
                    <option value=">=">≥</option>
                    <option value="between">Between</option>
                  </select>
                </div>
                <div className="col-md-3 col-xs-12 no-padding-right">
                  <label>&nbsp;</label>
                  <input
                    name="dob_from"
                    value={this.state.dob_from}
                    onChange={this.handleChange}
                    type="text" className="form-control" placeholder="00/00/0000" />
                </div>
                {this.state.dob_operator === 'between' && (
                  <>
                    <div className="col-md-1 col-xs-12 no-padding-right" style={styleAND}>
                      <span>and</span>
                    </div>
                    <div className="col-md-3 col-xs-12 no-padding-right">
                      <label>&nbsp;</label>
                      <input
                        name="dob_to"
                        value={this.state.dob_to}
                        onChange={this.handleChange}
                        type="text" className="form-control" placeholder="00/00/0000" />
                    </div>
                  </>
                )}
              </div>



              <div className="row margin-four-bottom">
                <div className="col-md-3 col-xs-12 no-padding-right">
                  <label>Age</label>
                  <select
                    name="age_operator"
                    value={this.state.age_operator}
                    onChange={this.handleChange}
                    className="form-control input-sm no-padding-top no-padding-bottom">
                     <option value="=">=</option>
                    <option value="<=">≤</option>
                    <option value="<">&lt;</option>
                    <option value=">">&gt;</option>
                    <option value=">=">≥</option>
                    <option value="between">Between</option>
                  </select>
                </div>
                <div className="col-md-3 col-xs-12 no-padding-right">
                  <label>&nbsp;</label>
                  <input
                    name="age"
                    value={this.state.age}
                    onChange={this.handleChange}
                    type="text" className="form-control" placeholder="00" />
                </div>
                {this.state.age_operator === 'between' && (
                  <>
                    <div className="col-md-1 col-xs-12 no-padding-right" style={styleAND}>
                      <span>and</span>
                    </div>
                    <div className="col-md-3 col-xs-12 no-padding-right">
                      <label>&nbsp;</label>
                      <input
                        name="age_to"
                        value={this.state.age_to}
                        onChange={this.handleChange}
                        type="text" className="form-control" placeholder="00" />
                    </div>
                  </>
                )}
              </div>

              {/* <div className="row margin-four-bottom">
                <div className="col-md-3 col-xs-12 no-padding-right">
                  <label>Lifetime Risk</label>
                  <select
                    name="risk_operator"
                    value={this.props.risk_operator}
                    onChange={this.handleChange}
                    className="form-control input-sm no-padding-top no-padding-bottom">
                    <option value="between">Between</option>
                    <option value="less_or_equal">≤</option>
                    <option value="less">&lt;</option>
                    <option value="equal">=</option>
                    <option value="greater">></option>
                    <option value="greater_or_equal">≥</option>
                  </select>
                </div>
                <div className="col-md-3 col-xs-12 no-padding-right">
                  <label>&nbsp;</label>
                  <input
                    name="risk"
                    value={this.props.risk}
                    onChange={this.handleChange}
                    type="text" className="form-control" placeholder="00" />
                </div>
              </div> */}

              <div className="row margin-four-bottom">
                <div className="col-md-3 col-xs-12 no-padding-right">
                  <label>Sex at Birth</label>
                  <select
                    name="gender"
                    value={this.state.gender}
                    onChange={this.handleChange}
                    className="form-control input-sm no-padding-top no-padding-bottom">
                    <option default> </option>
                    <option value="m">Male</option>
                    <option value="f">Female</option>
                    <option value="unknown">Unknown</option>
                  </select>
                </div>
              </div>

              <div className="row margin-four-bottom">
                <div className="col-md-3 col-xs-12 no-padding-right">
                  <label>Clinician</label>
                  <select
                    name="clinician"
                    value={this.state.clinician}
                    onChange={this.handleChange}
                    className="form-control input-sm no-padding-top no-padding-bottom">
                    <option default> </option>
                    {this.props.clinicians && this.props.clinicians.map(clinician => {
                      return <option key={createUUID()} value={clinician.user_id}>{`${clinician.first_name} ${clinician.last_name}`}</option>
                    })}
                  </select>
                </div>
              </div>

            </div>

          </div>
        </div>
      </React.Fragment>



    )
  }
}


export default FilterMore
