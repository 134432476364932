import React, { Component } from "react";
import { connect } from "react-redux";

import helpers from "../helpers/index";
import NavTop from "../components/nav-top";
import PatientPersonalData from "../components/patient-profile/patient-data-tabs/patient-personal-data";
import * as helper_family_tree from "../helpers/helper-family-tree";

import PatientDiseaseView from "../components/patient-disease-view";
import PatientGeneTestView from "../components/patient-gene-test-view";
import ErrorSummary from "../components/error-summary";
import ActivityIndicator from "../components/activity-indicator";
import family_api from "../api/family-api";
import disease_api from "../api/disease-api"
import { cloneDeep } from "lodash";

import moment from "moment";

class PatientInformationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessages: [],
    };

    this.onClickSave = this.onClickSave.bind(this);
  }

  async componentDidMount() {

    helpers.setSiteTitle('Edit Patient Information')

    try {

      if(this.props.patient.id === null) {
        this.setState({loading: true})
        await helper_family_tree.populateProband(this.props.dispatch, this.props.match.params.member_id)
      }

    } catch (error) {
        console.log(error)
    } finally {
        this.setState({loading: false})
    }
  }

  async validate() {
    this.setState({ validations: null });

    let options = {
      patient_id: {
        labelName: "Patient ID",
        validations: { required: true }
      },
      first_name: {
        labelName: "First Name",
        validations: { required: true }
      },
      gender: {
        labelName: "Biological Sex",
        validations: { required: true }
      }
      // phone: {
      //   labelName: 'Phone',
      //   validations: {
      //     required: true,
      //     custom: [{
      //       test: () => { return this.state.phone_valid },
      //       error_text: "* Invalid",
      //       error_summary: "Phone number is invalid",
      //     }]
      //   }
      // }
    };

    let validations = helpers.validateInputs(this.props.patient, options);

    if(this.props.patient.id) {
      let age = moment().diff(this.props.patient.dob, "years");
      let diseases = await disease_api.get_member_id_diseases(this.props.patient.id)

      for(let disease of diseases) {
        if(age !== null
          && age !== undefined
          && age !== ''
          && age < disease.age_diagnosed)
            throw new Error('The current age/age at death cannot be less than the age of diagnosis entered for this person’s disease.')
      }
    }

    this.setState({ errorMessages: validations.summary });
    return validations.isValid;
  }

  buildPayload() {
    let input = this.props.patient;

    let gender = input.gender.toLowerCase()
    gender = gender === 'u' ? null : gender

    let gender_identity = input.gender_identity
    gender_identity = gender_identity ? gender_identity.toLowerCase() : 'u'
    gender_identity = gender_identity == 'u' ? null : gender_identity

    let payload = {
      clinician_id: this.props.clinician_id,
      first_name: input.first_name,
      middle_name: input.middle_name,
      last_name: input.last_name,
      gender: gender,
      patient_id: input.patient_id,
      gender_identity: gender_identity,
      pronoun: input.pronoun === null ? "" : input.pronoun,
      other_pronoun: input.other_pronoun === null ? "" : input.other_pronoun,
      email: input.email,
      phone_number: input.phone_number,
      ethnicity: input.ethnicity === null ? "" : input.ethnicity,
      ashkenazi: input.ashkenazi === true,
      father_adopted: input.father_adopted === true,
      mother_adopted: input.mother_adopted === true,
      adopted_out: input.adopted_out
    };

    if(input.dob !== null) {
      let age = moment().diff(input.dob, "years");
      payload['dob'] = input.dob
      payload['age'] = age
    }

    return payload;
  }

  async saveProband() {
    let payload = this.buildPayload();

    let proband = cloneDeep(this.props.patient);

    let member = await family_api.patch_member_memberid(proband.id, payload);

    helper_family_tree.savePatientToRedux(this.props.dispatch, {
      ...proband,
      ...member
    });
    helper_family_tree.saveProfileToRedux(this.props.dispatch, {
      rkey: proband.rkey,
      ...member
    });
    return member;
  }

  async onClickSave() {
    try {
      this.setState({ loading: true, errorMessages: [] });

      let valid = await this.validate();
      if (!valid) return;

      let proband = await this.saveProband();
      await this.parentAPISave();
      this.props.history.push("/family-tree/" + proband.id);
    } catch (error) {
      helpers.logger(error);
      this.setState({ errorMessages: [error.message] });
    } finally {
      this.setState({ loading: false });
    }
  }

  async parentAPISave() {
    try {
      let { father, mother} = this.props.patient;

      mother["ancestry"] = mother.ancestry === null  ? null : mother.ancestry.map((item) => item.value);
      father["ancestry"] = father.ancestry === null ? null : father.ancestry.map((item) => item.value);
      mother["adopted_out"] = this.props.patient.mother_adopted
      father["adopted_out"] = this.props.patient.father_adopted

      await family_api.patch_member_memberid(father.id, father);
      await family_api.patch_member_memberid(mother.id, mother);

    } catch (err) {
      console.log(err);
    }
  }

  getProfile() {
    return helper_family_tree.getProfileFromRedux(
      this.props.tree,
      this.props.patient.rkey
    );
  }

  render() {
    return (
      <React.Fragment>
        <NavTop history={this.props.history} />

        <section className="wow fadeIn section">
          <div className="container">
            <div className="section-header">
              <div className="row vcenter">
                <div className="col-md-6 col-xs-12">
                  <h2>Edit Patient Information</h2>
                </div>
                <div className="col-md-4 col-md-offset-2 justify-right hidden" />
              </div>
            </div>

            <PatientPersonalData
              patient_id={this.props.match.params.member_id}
              isNewPatient={false}
            />

            <ErrorSummary errorMessages={this.state.errorMessages} />

            <div  className="gene-disease-container">
              <PatientDiseaseView
                patientRkey={this.props.patientRkey}
                profile={this.getProfile()}
              />

              <PatientGeneTestView
                patientRkey={this.props.patientRkey}
                profile={this.getProfile()}
              />
            </div>

            <ErrorSummary errorMessages={this.state.errorMessages} />

            <div className="section-footer">
              {/* <hr /> */}
              <button
                onClick={() => this.onClickSave()}
                disabled={this.state.loading}
                className={
                  "btn btn-dark btn-sm " +
                  (this.state.loading ? "disabled" : "")
                }
              >
                <i className="fa fa-arrow-right text-white" />Save and Continue
              </button>

              <ActivityIndicator loading={this.state.loading} modal={true} />
            </div>
          </div>
        </section>

      </React.Fragment>
    );
  }
}

const redux_state = state => ({
  tree: state.patient,
  patient: state.patient.patient,
  patientRkey: state.patient.patient.rkey,
  clinician_id: state.session.user.clinician_id
});

const redux_actions = dispatch => ({
  dispatch: action => dispatch(action)
});

export default connect(
  redux_state,
  redux_actions
)(PatientInformationPage);
