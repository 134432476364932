import axios from 'axios';

class SurveyAPI {

  constructor() {
    this.api_handler = axios.create({
      baseURL: process.env.REACT_APP_ROOT_API_URL
    });
  }

  getParams(payload) {
    let params = Object.assign({}, payload);
    return params;
  }

  setAuthHeader(headers) {
    if (headers) {
      this.api_handler.defaults.headers.common["Authorization"] = "Bearer " + headers.accessToken;
    }
  }

  /*
  * payload = {
  *   clinician_id,
  *   survey_id,
  *   proband_id
  * }
  *
  * headers = {
  *   accessToken
  * }
  */
  getSurvey(payload, headers) {
    this.setAuthHeader(headers);

    return new Promise((resolve, reject) => {
      this.api_handler.get("/survey/get_survey/", {params: this.getParams(payload)})
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          console.log(`Error ${error.response.status}: SurveyAPI.getSurvey()`);
          return reject(error);
        });
    });
  }

  /*
  * payload = {
  *   clinician_id
  * }
  *
  * headers = {
  *   accessToken
  * }
  */
  getClinicianSurveys(payload, headers) {
    this.setAuthHeader(headers);

    return new Promise((resolve, reject) => {
      this.api_handler.get("/survey/get_clinician_surveys/", {params: this.getParams(payload)})
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          console.log(`Error ${error.response.status}: SurveyAPI.getClinicianSurveys()`);
          return reject(error);
        });
    });
  }

  /*
  * payload = {
  *   clinician_id,
  *   proband_id
  * }
  *
  * headers = {
  *   accessToken
  * }
  */
  getSurveyAnswersAsClinician(payload, headers) {
    this.setAuthHeader(headers);

    return new Promise((resolve, reject) => {
      this.api_handler.get("/completed_survey_answer/get_survey_answers_as_clinician/", {params: this.getParams(payload)})
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          console.log(`Error ${error.response.status}: SurveyAPI.getSurveyAnswersAsClinician()`);
          return reject(error);
        });
    });
  }

  /*
  * payload = {
  *   clinician_id,
  *   proband_id
  * }
  *
  * headers = {
  *   accessToken
  * }
  */
  getRiskFactorSurveyAnswersAsClinician(payload, headers) {
    this.setAuthHeader(headers);

    return new Promise((resolve, reject) => {
      this.api_handler.get("/completed_survey_answer/get_risk_factor_survey_answers_as_clinician/", {params: this.getParams(payload)})
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          console.log(`Error ${error.response.status}: SurveyAPI.getRiskFactorSurveyAnswersAsClinician()`);
          return reject(error);
        });
    });
  }

  /*
  * payload = {
  *   member_id: null,
  *   master_question_id: null,
  *   master_question_choice_id: null,
  *   answer: null
  * }
  *
  * headers = {
  *   accessToken
  * }
  */
  createCompletedSurveyAnswer(payload, headers) {
    this.setAuthHeader(headers);

    return new Promise((resolve, reject) => {
      this.api_handler.post("/completed_survey_answer/create_completed_survey_answer/", this.getParams(payload))
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          console.log(`Error ${error.response.status}: SurveyAPI.createCompletedSurveyAnswer()`);
          return reject(error);
        });
    });
  }

  /*
  * payload = {
  *   member_id: null,
  *   master_question_id: null,
  *   master_question_choice_id: null,
  *   answer: null
  * }
  *
  * headers = {
  *   accessToken
  * }
  */
  updateCompletedSurveyAnswer(payload, headers) {
    this.setAuthHeader(headers);

    return new Promise((resolve, reject) => {
      this.api_handler.post("/completed_survey_answer/update_completed_survey_answer/", this.getParams(payload))
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          console.log(`Error ${error.response.status}: SurveyAPI.updateCompletedSurveyAnswer()`);
          return reject(error);
        });
    });
  }

  /*
  * payload = {
  *   id: 1
  * }
  *
  * headers = {
  *   accessToken
  * }
  */
  deleteCompletedSurveyAnswer(payload, headers) {
    this.setAuthHeader(headers);

    return new Promise((resolve, reject) => {
      this.api_handler.post("/completed_survey_answer/delete_completed_survey_answer/", this.getParams(payload))
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          console.log(`Error ${error.response.status}: SurveyAPI.deleteCompletedSurveyAnswer()`);
          return reject(error);
        });
    });
  }

  /*
  * payload = {
  *   member_id: null,
  *   master_question_uuid: null,
  *   choice_value: null
  * }
  *
  * headers = {
  *   accessToken
  * }
  */
  saveRiskFactorAnswerAsClinician(payload, headers) {
    this.setAuthHeader(headers);

    return new Promise((resolve, reject) => {
      this.api_handler.post("/completed_survey_answer/save_risk_factor_answer_as_clinician/", this.getParams(payload))
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          console.log(`Error ${error.response.status}: SurveyAPI.saveRiskFactorAnswerAsClinician()`);
          return reject(error);
        });
    });
  }

  /*
  * payload = {
  *   member_id: null,
  *   master_question_uuid: null,
  *   choice_value: null
  * }
  *
  * headers = {
  *   accessToken
  * }
  */
  deleteRiskFactorAnswerAsClinician(payload, headers) {
    this.setAuthHeader(headers);

    return new Promise((resolve, reject) => {
      this.api_handler.post("/completed_survey_answer/delete_risk_factor_answer_as_clinician/", this.getParams(payload))
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          console.log(`Error ${error.response.status}: SurveyAPI.deleteRiskFactorAnswerAsClinician()`);
          return reject(error);
        });
    });
  }

  /*
  * payload = {
  *   survey_id: null
  *   member_id: null,
  *   status: null,
  *   page_num: null
  * }
  *
  * headers = {
  *   accessToken
  * }
  */
  saveCompletedSurvey(payload, headers) {
    this.setAuthHeader(headers);

    return new Promise((resolve, reject) => {
      this.api_handler.post("/completed_survey/save_completed_survey/", this.getParams(payload))
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          console.log(`Error ${error.response.status}: SurveyAPI.saveCompletedSurvey()`);
          return reject(error);
        });
    });
  }

  /*
  * payload = {
  *
  * }
  *
  * headers = {
  *   accessToken
  * }
  */
  sendProbandCompletedSurveyNotification(payload, headers) {
    if (headers) {
      this.api_handler.defaults.headers.common["Authorization"] = "Bearer " + headers.accessToken
    }

    return new Promise((resolve, reject) => {
      this.api_handler.post("/member/send_proband_completed_survey_notification/", this.getParams(payload))
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          console.log(`Error ${error.response.status}: SurveyAPI.sendProbandCompletedSurveyNotification()`);
          return reject(error);
        });
    });
  }

  /*
  * payload = {
  *
  * }
  *
  * headers = {
  *   accessToken
  * }
  */
  sendNotificationToProbandForSurveyAnswer(payload, headers) {
    if (headers) {
      this.api_handler.defaults.headers.common["Authorization"] = "Bearer " + headers.accessToken
    }

    return new Promise((resolve, reject) => {
      this.api_handler.post("/member/send_notification_to_proband_for_updating_survey_answer/", this.getParams(payload))
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          console.log(`Error ${error.response.status}: SurveyAPI.sendNotificationToProbandForSurveyAnswer()`);
          return reject(error);
        });
    });
  }

  /*
  * payload = {
  *
  * }
  *
  * headers = {
  *   accessToken
  * }
  */
  sendNotificationToClinicianForSurveyAnswer(payload, headers) {
    if (headers) {
      this.api_handler.defaults.headers.common["Authorization"] = "Bearer " + headers.accessToken
    }

    return new Promise((resolve, reject) => {
      this.api_handler.post("/member/send_notification_to_clinicians_for_updating_survey_answer/", this.getParams(payload))
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          console.log(`Error ${error.response.status}: SurveyAPI.sendNotificationToClinicianForSurveyAnswer()`);
          return reject(error);
        });
    });
  }
}

export default SurveyAPI;
