import diseasesSkipLogicJson from '../assets/json/diseases-skip-logic.json'
import * as model from '../model-states/m-health-history'

const getProfile = (family_tree, person ) => {
  let { data } = person

  if(data.profile.rkey in family_tree.profile) {
    return family_tree.profile[data.profile.rkey]
  }
  return null

}


const getHistory = (family_tree, person) => {
  let histories = family_tree.history
  let rkey = person.data.profile.rkey
  if (rkey in histories) {
    return histories[rkey]
  }

  return model.createHistory()
}

const getDiseases = (family_tree, person) => {
  let history_diseases = family_tree.history_diseases
  let rkey = person.data.profile.rkey
  if (rkey in history_diseases) {
    return history_diseases[rkey]
  }

  return null
}

const getSkipLogic = (item) => {

  // No disease is selected, return empty
  if (item.disease_type == null) return []

  // Selected diseases does not have skip logic, return empty
  if (!(item.disease_type.value in diseasesSkipLogicJson)) return []

  let skipLogicItems = diseasesSkipLogicJson[item.disease_type.value].items

  if (item.skipLogic == '') {
    // Skip logic is not yet selected, return empty
    return []
  } else {
    // Selection has been made, return only the selected one
    return skipLogicItems.filter(skip_logic_item => skip_logic_item.value == item.skipLogic)
  }

}

const getGeneticTests = (family_tree, person) => {
  let rkey = person.data.profile.rkey
  let geneticTests = family_tree.history_genes
  if (rkey in geneticTests) {
    return geneticTests[rkey]
  }

  return []
}

export default {
  getProfile,
  getHistory,
  getDiseases,
  getSkipLogic,
  getGeneticTests
}