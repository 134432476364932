import paper from 'paper'
import ScrollBars from './scrollbars'
import MatrixPeople from './matrix-people'
import settings from './settings';
import Cookie from 'js-cookie'

import * as helper_family_tree from '../../helpers/helper-family-tree';

class Pedigree {

  pedigree_canvas = null
  matrix_people = null
  scrollbars = null
  dragging = false;
  lastCenter = null;
  moveToCenterExecuted = false;

  height = 500
  width = 0 // dynamically determine
  options = {}
  zoomValue = 1
  zoomFactor = 0.1

  timerID = null
  counter = 0

  constructor(canvas_id, family_tree = {}, options = {}) {

    // this.height = 500
    // this.width = window.innerWidth * 0.9
    this.width = window.innerWidth
    this.options = options

    if ('height' in options) this.height = options.height
    if ('width' in options) this.width = options.width

    this.height = this.options.readOnly === true ? (window.innerHeight *.95) : this.height;
    this.pedigree_canvas = document.getElementById(canvas_id);
    this.pedigree_canvas.width = this.width
    this.pedigree_canvas.height = this.height

    paper.setup(this.pedigree_canvas)

    this.addMouseEvents()

    // render
    paper.view.draw()

    // declare matrix people
    let people = Object.values(family_tree.profile)
    let proband = people.find(p => p.is_proband)

    helper_family_tree.updateFamilyTree(family_tree, proband, true)
    this.matrix_people = new MatrixPeople(paper, family_tree, options)

  }

  setToLastCenter(latestCenter) {
    document.onload = paper.view.setCenter(latestCenter)
  }

  getLatestCenter() {
    return paper.view.center;
  }

  updatePersonProfile(node, profile) {
    this.matrix_people.updatePersonProfile(node, profile)
  }

  updatePersonHealth(node, health) {
    this.matrix_people.updatePersonHealth(node, health)
  }

  refreshNode(node) {
    this.matrix_people.refreshNode(node);
  }

  deleteNode(node) {
    this.matrix_people.deleteNode(node);
  }

  prepareForPrint() {
    this.matrix_people.redrawSubtext()
  }

  toggleViewStatus(value) {
    this.matrix_people.toggleViewStatus(value)
  }

  toggleLegend(showLegend, showNotes) {
    this.matrix_people.toggleLegend(showLegend, showNotes)
  }

  toggleAncestry(showAncestry) {
    this.matrix_people.toggleAncestry(showAncestry)
  }

  clearAll(){
    this.matrix_people.clearAll();
  }

  clearMenuButton(){
    this.matrix_people.clearMenuButton();
  }

  scrollBarInit() {

    let self = this

    let items = paper.project.getItems({
      type: 'layer'
    });
    let pedigreeSize = items[0].bounds;
    let contentSize = Cookie.get("famgenix_active_smartdraw") == 'flux' ? pedigreeSize : this.matrix_people.getContentSize()

    this.scrollbars = new ScrollBars({
      pedigreeZoomValue: this.zoomValue,
      pedigreeHeight: this.height,
      pedigreeWidth: this.width,
      contentSizeHeight: Cookie.get("famgenix_active_smartdraw") == 'flux' ? contentSize.height + 500 : contentSize.height,
      contentSizeWidth: Cookie.get("famgenix_active_smartdraw") == 'flux' ? contentSize.width + 500 : contentSize.width,
      onVerticalScroll: function (step) {
        self.scrollVerticalBy(step)
      },
      onHorizontalScroll: function (step) {
        self.scrollHorizontalBy(step)
      }
    })

    this.scrollbars.draw()
  }

  addMouseEvents() {
    // Create a Tool so we can listen for events
    let self = this
    var toolPan = new paper.Tool()
    toolPan.activate()

    // scrolling by dragging
    toolPan.onMouseDrag = function (event) {
      self.dragScroll(event)
    }


    toolPan.onMouseMove = (event) => {
      // this.matrix_people.clearMenuWhenOutside(event)
    }

    toolPan.onMouseDown = (event) => {
      let self = this
      requestAnimationFrame(function () {
        self.startGrab()
      })
    }

    toolPan.onMouseUp = (event) => {
      let outside = this.matrix_people.clearMenuWhenOutside(event, this.dragging)
      if (!this.dragging && outside && 'onOutsideClick' in this.options) {
        this.options.onOutsideClick();
      }
      this.stopGrab()
    }

    // TODO: add wheel event listener to scroll divs not the window
    this.pedigree_canvas.addEventListener('wheel', function (event) {
      self.scrollVerticalByMouseScroll(event.deltaY)
    });
    // window.addEventListener('wheel', function (event) {
    //   self.scrollVerticalByMouseScroll(event.deltaY)
    // });

  }

  stopGrab() {
    cancelAnimationFrame(this.timerID);
    this.counter = 0

    if (document.body.style.cursor == "grab") {
      document.body.style.cursor = "default"
    }
    this.dragging = false;
  }

  startGrab() {
    let self = this
    if (this.counter < 7) {
      this.counter++;
      this.timerID = requestAnimationFrame(function () {
        self.startGrab()
      });
    } else {
      document.body.style.cursor = "grab"
    }
  }

  dragScroll(event) {
    // scroll the View by the difference between mousedown and mouseup
    let dragLeg = this.matrix_people.isLegendDragging()
    if (!dragLeg) {
      var delta = event.downPoint.subtract(event.point)
      let items = paper.project.getItems({
        type: 'layer'
      });
      let pedigreeSize = items[0].bounds;
      var contentSize = Cookie.get("famgenix_active_smartdraw") == 'flux' ? pedigreeSize : this.matrix_people.getContentSize() // People space
      var viewTopLeft = paper.view.bounds.topLeft // Container Top Boundary
      var viewTopRight = paper.view.bounds.topRight // Container Right Boundary
      var viewBottomLeft = paper.view.bounds.bottomLeft // Container Bottom Boundary
      this.dragging = true;


      /* Limit horizontal scrolling (x) */
      // Left boundary
      if ((delta.x + viewTopLeft.x) <= 0) {
        if (viewTopLeft.x > 0) {
          delta.x = viewTopLeft.x * -2
          if (window.screen.width >= 1600 && !this.moveToCenterExecuted) {
            this.moveToCenter();
            this.moveToCenterExecuted =  true;
          }
        } else {
          delta.x = 0
        }
      }


      // Right Boundary: scroll to right
      let contentWidth = Cookie.get("famgenix_active_smartdraw") == 'flux' ? contentSize.width + 500 : contentSize.width
      if ((delta.x + viewTopRight.x) >= contentWidth) {
        if (contentSize.width > viewTopRight.x) {
          delta.x = contentSize.width - viewTopRight.x
        } else {
          delta.x = 0
        }
      }

      /* Limit vertical scrolling (y) */
      // Top Boundary
      if ((delta.y + viewTopLeft.y) <= 0) {
        if (viewTopLeft.y > 0) {
          delta.y = viewTopLeft.y * -2
          // fix for CLIN-724
          // if(contentSize.width >= 4000 && paper.view.zoom <= 0.40){
          //   this.moveToCenter();
          // }
        } else {
          delta.y = 0
        }
      }




      //REFERENCE FOR INCREASING SCROLL LIMIT

      // /* Limit vertical scrolling (y) */
      // // Top Boundary
      // if ((delta.y + viewTopLeft.y) <= -200) {
      //   if (viewTopLeft.y > 0) {
      //     delta.y = viewTopLeft.y * -2 -200
      //     // fix for CLIN-724
      //     // if(contentSize.width >= 4000 && paper.view.zoom <= 0.40){
      //     //   this.moveToCenter();
      //     // }
      //   } else {
      //     delta.y = 0
      //   }
      // }


      //should depend on maximum number of ancestry and contentsize width

      let linesForMaternal = this.matrix_people.getLinesForMaternal();
      let linesForPaternal = this.matrix_people.getLinesForPaternal();

      let maxLines = Math.max(linesForMaternal, linesForPaternal);

      // Bottom Boundary
      if (this.options.showAncestry && Cookie.get("famgenix_active_smartdraw") != 'flux') {
        if ((delta.y + viewBottomLeft.y) >= contentSize.height + (maxLines * 13)) {
          if (contentSize.height + 200 > viewBottomLeft.y) {
            delta.y = contentSize.height - viewBottomLeft.y + (maxLines * 13)
          } else {
            delta.y = 0
          }
        }
      }
      else {
        let contentHeight = Cookie.get("famgenix_active_smartdraw") == 'flux' ? contentSize.height + 500 : contentSize.height
        if ((delta.y + viewBottomLeft.y) >= contentHeight) {
          if (contentSize.height > viewBottomLeft.y) {
            delta.y = contentSize.height - viewBottomLeft.y
          } else {
            delta.y = 0
          }
        }
      }

      paper.view.scrollBy(delta)

      this.scrollbars.syncPos(paper.view.bounds.topLeft)
    }
  }

  getLinesForMaternal(){
    this.matrix_people.getLinesForMaternal();
  }

  getLinesForPaternal(){
    this.matrix_people.getLinesForPaternal();
  }

  scrollVerticalBy(step) {
    var delta = new paper.Point(0, step)
    delta = delta.subtract(paper.view.bounds.topLeft)
    delta.x = 0 // Make sure horizontal does not move
    paper.view.scrollBy(delta)
  }

  scrollVerticalByMouseScroll(step) {
    step = step > 0 ? step = 100 : step = -100;
    var delta = new paper.Point(0, step)

    let items = paper.project.getItems({
      type: 'layer'
    });
    let pedigreeSize = items[0].bounds;
    var contentSize = Cookie.get("famgenix_active_smartdraw") == 'flux' ? pedigreeSize : this.matrix_people.getContentSize() // People space
    var viewTopLeft = paper.view.bounds.topLeft // Container Top Boundary
    var viewTopRight = paper.view.bounds.topRight // Container Right Boundary
    var viewBottomLeft = paper.view.bounds.bottomLeft // Container Bottom Boundary
    this.dragging = true;

    // Top Boundary
    if ((delta.y + viewTopLeft.y) <= 0) {
      if (viewTopLeft.y > 0) {
        delta.y = viewTopLeft.y * -2
        // fix for CLIN-724
        // if(contentSize.width >= 4000 && paper.view.zoom <= 0.40){
        //   this.moveToCenter();
        // }
      } else {
        delta.y = 0
      }
    }

    let linesForMaternal = this.matrix_people.getLinesForMaternal();
    let linesForPaternal = this.matrix_people.getLinesForPaternal();

    let maxLines = Math.max(linesForMaternal, linesForPaternal);

      // Bottom Boundary
      if (this.options.showAncestry && Cookie.get("famgenix_active_smartdraw") != 'flux') {
        if ((delta.y + viewBottomLeft.y) >= contentSize.height + (maxLines * 13)) {
          if (contentSize.height + 200 > viewBottomLeft.y) {
            delta.y = contentSize.height - viewBottomLeft.y + (maxLines * 13)
          } else {
            delta.y = 0
          }
        }
      }
      else {
        let contentHeight = Cookie.get("famgenix_active_smartdraw") == 'flux' ? contentSize.bottom + 100 : contentSize.height
        if ((delta.y + viewBottomLeft.y) >= contentHeight) {
          if (contentSize.height > viewBottomLeft.y) {
            delta.y = contentSize.height - viewBottomLeft.y
          } else {
            delta.y = 0
          }
        }
      }

    delta.x = 0 // Make sure horizontal does not move

    paper.view.scrollBy(delta)
    this.scrollbars.syncPos(paper.view.bounds.topLeft)
  }

  scrollHorizontalBy(step) {
    var delta = new paper.Point(step, 0)
    delta = delta.subtract(paper.view.bounds.topLeft)
    delta.y = 0 // Make sure vertical does not move
    paper.view.scrollBy(delta)
  }

  resizeCanvas() {
    this.pedigree_canvas.height = this.pedigree_canvas.height * paper.view.zoom
    this.pedigree_canvas.width = this.pedigree_canvas.width * paper.view.zoom
  }

  zoomIn() {
    paper.view.scale(1 + this.zoomFactor)
    this.scrollbars.zoom(paper.view.zoom)
    this.scrollbars.syncPos(paper.view.bounds.topLeft)
    return paper.view.zoom
  }

  zoomOut(showSideBar) {
    if (paper.view.zoom > this.minimumZoomValue()) {
      paper.view.scale(1 - this.zoomFactor)
      this.scrollbars.zoom(paper.view.zoom)
      this.scrollbars.syncPos(paper.view.bounds.topLeft)
    } else {
      paper.view.zoom = this.minimumZoomValue(showSideBar)
      paper.view.scrollBy(paper.view.bounds.topLeft.multiply(-1))
      this.moveToCenter()
      this.moveToCenterExecuted = true;
    }
    return paper.view.zoom
  }

  minimumZoomValue(showSideBar) {
    let items = paper.project.getItems({
      type: 'layer'
    });
    let pedigreeSize = items[0].bounds;
    let contentSize = Cookie.get("famgenix_active_smartdraw") == 'flux' ? pedigreeSize : this.matrix_people.getContentSize()

    let linesForMaternal = this.matrix_people.getLinesForMaternal();
    let linesForPaternal = this.matrix_people.getLinesForPaternal();
    let maxLines = Math.max(linesForMaternal, linesForPaternal);
    let contentSize_height = Cookie.get("famgenix_active_smartdraw") == 'flux' ? contentSize.height + 300 : contentSize.height
    let contentSize_width = Cookie.get("famgenix_active_smartdraw") == 'flux' ? contentSize.width + 300 : contentSize.width
    let zoom = 0
    if (contentSize_height / contentSize_width >= 0.52 && !showSideBar) {
      zoom = this.options.showAncestry ? this.height / (contentSize_height + (maxLines * 13)) : this.height / (contentSize_height)
    } else if (contentSize.height / contentSize.width >= 0.52 && showSideBar) {
      zoom = this.options.showAncestry ? this.height / (contentSize_height + (maxLines * 13)) : this.height / (contentSize_height)
    } else if (showSideBar) {
      zoom = this.options.showAncestry ? (this.width / contentSize_width) - (this.width / ((contentSize_width * 5) + (maxLines*20))) : (this.width / contentSize_width) - (this.width / (contentSize_width * 5))
    }
    else {
      zoom = this.options.showAncestry ? this.width / (contentSize_width + (maxLines*20)) - 0.02 : this.width / contentSize_width - 0.02
    }
    return zoom
  }

  fitToScreen(showSideBar) {
    let items = paper.project.getItems({
      type: 'layer'
    });
    let pedigreeSize = items[0].bounds;
    paper.view.zoom = this.minimumZoomValue(showSideBar)
    let topLeft = Cookie.get("famgenix_active_smartdraw") == 'flux' ? items[0].bounds.topLeft : paper.view.bounds.topLeft
    paper.view.scrollBy(paper.view.bounds.topLeft.multiply(-1))

    if (!showSideBar) {
      this.moveToCenter();
    }
    else{

      let items = paper.project.getItems({
        type: 'layer'
      });
      let pedigreeWidth = items[0].bounds.width;
      let pedigreeHeight = items[0].bounds.height;

      if((pedigreeHeight - pedigreeWidth) >= 400){
        this.moveToCenter();
        paper.view.scrollBy(topLeft.multiply(-0.25))
      }

    }
    if (this.width >= 951) this.scrollVerticalBy(35)
    this.scrollbars.zoom(paper.view.zoom)
    this.scrollbars.syncPos(topLeft)
    return paper.view.zoom
  }

  // fontFontSizeChangeTest(font, fontSize){
  //   this.matrix_people.fontFontSizeChangeTest(font, fontSize);
  // }

  moveToCenter() {
    // paper.view.scrollBy(paper.view.bounds.topLeft.multiply(-1))
    let items = paper.project.getItems({
      type: 'layer'
    });
    let pedigreeSize = items[0].bounds;
    let zoom = paper.view.zoom
    //commenting out incase of future use with flux printing
    //let contentSize = Cookie.get("famgenix_active_smartdraw") == 'flux' ? pedigreeSize : this.matrix_people.getContentSize()
    let contentSize = this.matrix_people.getContentSize()

    let viewSize = paper.view.size

    let topLeft = new paper.Point(0, 0)

    let height_space = viewSize.height - contentSize.height
    if (height_space > 0) {
      // Convert content height to div height
      let div_height = contentSize.height * zoom
      let div_height_ratio = div_height / this.height
      let vertical_padding = viewSize.height * (1 - div_height_ratio) / 2
      let y = vertical_padding > 0 ? vertical_padding * -1 : vertical_padding
      topLeft = topLeft.add(0, y)
    }

    let width_space = viewSize.width - contentSize.width
    if (width_space > 0) {
      // Convert content width to div width
      let div_width = contentSize.width * zoom
      let div_width_ratio = div_width / this.width
      let horizontal_padding = viewSize.width * (1 - div_width_ratio) / 2
      let x = horizontal_padding > 0 ? horizontal_padding * -1 : horizontal_padding
      topLeft = topLeft.add(x, 0)
    }
    paper.view.scrollBy(topLeft)
  }

  setToOriginal() {
    paper.view.zoom = 1
    paper.view.scrollBy(paper.view.bounds.topLeft.multiply(-1))
    this.scrollbars.zoom(paper.view.zoom)
    this.scrollbars.syncPos(paper.view.bounds.topLeft)
    return paper.view.zoom
  }

  drawChart() {

    this.matrix_people.renderChart()
    this.scrollBarInit()

    let self = this
    setTimeout(function () {
      self.setToOriginal()
    }, 100)

  }

  clearChart() {
    this.matrix_people.clearChart()
  }

  toSVG() {
    let bg = this.drawTempBG();
    let svgImageData = "data:image/svg+xml;utf8," + encodeURIComponent(paper.project.exportSVG({ asString: true }));
    bg.remove()
    return svgImageData
  }

  drawTempBG() {
    var rect = new paper.Path.Rectangle({
      point: [0, -500],
      size: [paper.view.size.width, paper.view.size.height + 500],
      strokeColor: settings.chart_bg_color_for_printing,
      selected: true
    });
    rect.sendToBack();
    rect.fillColor = settings.chart_bg_color_for_printing;
    return rect
  }

  nodeCountUnclesAunts() {
    return this.matrix_people.nodeCountUnclesAunts();
  }

  getBoundingClientRect() {
    if (this.pedigree_canvas) {
      const rect = this.pedigree_canvas.getBoundingClientRect();
      return rect;
    }
    return null;
  }

}

export default Pedigree
