import React, { Component } from "react";
import { connect } from "react-redux";
import images from "../configs/images";
import { Link } from "react-router-dom";
import NavSearch from './nav-search'

import api_auth from "../api/authentication";
import route_helper from "../route-helper";
import settings from "../configs/settings";
import * as helper_search from "../helpers/helper-search";

import NotificationSlider from "./notifications/slider";
import ModalResetPassword from './user-profile/modal-reset-password';

import user_account_api from "../api/user-account-api";
import notifications_api from "../api/notifications-api";

class NavTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSettings: false,
      menuHasFocus: false,
      showSearch: false,
      searchHasFocus: false,

      showHamburgerMenu: false,
      showNotifications: false,

      showPasswordModal: false,
      passwordErrorMessage: null,

      resetPasswordHelpText: undefined,
      notification_counts: {},
    };

    this.toggleNotifications = this.toggleNotifications.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
    this.setMenuFocus = this.setMenuFocus.bind(this);

    this.toggleSearch = this.toggleSearch.bind(this);
    this.setSearchFocus = this.setSearchFocus.bind(this);

    this.handleLogout = this.handleLogout.bind(this);

    this.getUserAccountType = this.getUserAccountType.bind(this);
    this.isUserAdmin = this.isUserAdmin.bind(this);
    this.showStaffLink = this.showStaffLink.bind(this);

    this.clickHamburgerMenu = this.clickHamburgerMenu.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);

    this.shouldResetPassword = this.shouldResetPassword.bind(this);
    this.closePasswordModal = this.closePasswordModal.bind(this);
    this.onSaveUserPassword = this.onSaveUserPassword.bind(this);
    this.displayPasswordModal  = this.displayPasswordModal.bind(this);

    this.fetchNotificationCounts = this.fetchNotificationCounts.bind(this);
    this.hidereadOnlyNav = this.hidereadOnlyNav.bind(this)

    this.isSuperAdmin = this.isSuperAdmin.bind(this);
  }

  async componentDidMount() {
    // Check every 5 minutes
    this.checkNotificationCounts = setInterval(this.fetchNotificationCounts, 300000);

    // check if user must reset password according to the organization reset policy
    this.checkForResetPassword = setInterval(this.shouldResetPassword, 1000);

    this.fetchNotificationCounts();
  }

  componentWillUnmount() {
    clearInterval(this.checkNotificationCounts);
    clearInterval(this.checkForResetPassword);
  }

  async fetchNotificationCounts() {
    let { user, dispatch } = this.props;

    let display_read_only = 'displayReadOnly' in this.props ? this.props.displayReadOnly : this.props.user.read_only

    if(user.clinician_id && !display_read_only) {
      try {
        const notification_counts = await notifications_api.post_clinician_notification_counts(user.clinician_id);
        this.setState({ notification_counts: notification_counts });

      } catch (error) {
        console.log(error);
      }
    }
  }

  async shouldResetPassword() {
    try {
      const should_reset_password = await user_account_api.should_reset_password();
      if(should_reset_password) {
        if(!this.state.showPasswordModal) {
          this.setState({resetPasswordHelpText: 'Your password has expired.  Please set a new password.'});
          this.displayPasswordModal(null);
        }
      } else {
        this.setState({resetPasswordHelpText: undefined});
        clearInterval(this.checkForResetPassword);
      }
    } catch (error) {
      console.log(error);
      this.setState({resetPasswordHelpText: undefined});
      clearInterval(this.checkForResetPassword);
    }
  }

  closePasswordModal(event) {
    this.setState({ showPasswordModal: false });
  }

  displayPasswordModal(error) {
    this.setState({
      showPasswordModal: true,
      passwordErrorMessage: error,
    });
  }

  async onSaveUserPassword(payload) {
    try {
      // send data to the server, DO NOT SAVE PASSWORD DETAILS IN REDUX
      const message = await user_account_api.post_update_user_password(this.props.user.staff_id, {
        current_password: payload.current_password,
        new_password: payload.new_password,
        confirm_password: payload.confirm_password
      });

      this.setState({ showPasswordModal: false });

    } catch (error) {
      this.displayPasswordModal(error.message);
    }
  }

  toggleSettings(val) {
    if (val === false) {
      setTimeout(() => {
        if (!this.state.menuHasFocus) this.setState({ showSettings: val });
      }, 150);
    } else {
      this.setState({ showSettings: val });
    }
  }

  setMenuFocus(val) {
    this.setState({ menuHasFocus: val, showSettings: val });
  }

  toggleSearch(val) {
    if (val === false) {
      setTimeout(() => {
        if (!this.state.searchHasFocus) this.setState({ showSearch: val });
      }, 250);
    } else {
      this.setState({ showSearch: val });
    }
  }

  setSearchFocus(val) {
    this.setState({ searchHasFocus: val, showSearch: val });
  }

  handleLogout() {
    api_auth.logoutRemoveSessionStorage();
  }

  getUserAccountType(user) {
    if (
      user !== null &&
      user !== undefined &&
      user.account_type !== null &&
      user.account_type !== undefined
    ) {
      return user.account_type;
    }
    return settings.app_constants.standardOrg; // just default to standard account type for now
  }

  isSuperAdmin(user){
    if (
      user !== null &&
      user !== undefined &&
      user.account_type === "super_admin"

    ) {
      return true
    }
    return false; // just default to to false if needed
  }
  isUserAdmin(user) {
    if (
      user !== null &&
      user !== undefined &&
      user.admin !== null &&
      user.admin !== undefined &&
      typeof user.admin === "boolean"
    ) {
      return user.admin;
    }
    return false; // just default to to false if needed
  }

  showStaffLink() {
    return (
      this.getUserAccountType(this.props.user) ===
        settings.app_constants.subOrg.value && this.isUserAdmin(this.props.user)
    );
  }

  showSearch() {
    return (
      this.getUserAccountType(this.props.user) ===
        settings.app_constants.subOrg.value ||
      this.getUserAccountType(this.props.user) ===
        settings.app_constants.standardOrg.value
    );
  }

  clickHamburgerMenu(event) {
    this.setState({ showHamburgerMenu: !this.state.showHamburgerMenu });
  }

  handleSearchSubmit() {
    // update redux search with new search history
    helper_search.saveSearchFilter(
      this.props.dispatch,
      this.props.searchFilter
    );

    if(!this.props.personalDataOpen) {
      let url = route_helper.query.query_root;
      this.props.history.push(url);
    }
  }

  showNavSearch() {
    if(this.props.page && this.props.page === 'search-results' || this.props.user.account_type === 'super_admin') {
      return false;
    }
    return true;
  }

  hidereadOnlyNav(){
    let display_read_only = 'displayReadOnly' in this.props ? this.props.displayReadOnly : this.props.user.read_only
    if(this.props.onPedigree && this.props.onPedigree == true && display_read_only === true){
      return false
    }
    return true
  }

  async toggleNotifications() {
    this.fetchNotificationCounts();
    let showNotifications = !this.state.showNotifications;
    this.setState({ showNotifications });
  }

  // TODO: refactor search into its own compenent to clean up top nav
  render() {
    let hamburger_menu_css = this.state.showHamburgerMenu
      ? "navbar-toggle"
      : "navbar-toggle collapsed";
    let item_menu_css = this.state.showHamburgerMenu
      ? "navbar-collapse collapse in"
      : "navbar-collapse collapse";

    let home_link = "#";
    if (
      this.getUserAccountType(this.props.user) ===
        settings.app_constants.subOrg.value ||
      this.getUserAccountType(this.props.user) ===
        settings.app_constants.standardOrg.value
    ) {
      home_link = route_helper.query.query_root;
    } else if (
      this.getUserAccountType(this.props.user) ===
      settings.app_constants.famhisOrg.value
    ) {
      home_link = route_helper.administrative.fh_dashboard;
    }

    let survey_link = route_helper.survey.survey_list

    let account_link = "#";
    if (this.isUserAdmin(this.props.user) && this.getUserAccountType(this.props.user) === settings.app_constants.famhisOrg.value) {
      account_link = route_helper.administrative.fh_super_account.replace(':org_id', this.props.user.organization_id);
    } else if (this.isUserAdmin(this.props.user) && this.getUserAccountType(this.props.user) === settings.app_constants.superOrg.value ) {
      account_link = route_helper.administrative.clinician_super_account.replace(':org_id', this.props.user.organization_id);
    } else {
      account_link = route_helper.administrative.clinician_sub_account.replace(':org_id', this.props.user.organization_id);
    }

    let notif_count = 0;
    for (const [key, value] of Object.entries(this.state.notification_counts)) {
      notif_count += value;
    }

    // let clinician_requests = filterClinicianRequest(this.props.notifications);
    // let patient_updates = filterNotificationUpdates(this.props.notifications, 'pending');
    //
    // let clinician_requests_pending_count = clinician_requests.filter(item => item.response == null).length;
    // let patient_updates_count = Object.keys(notificationsGroupedByName(patient_updates)).length
    // let disconnected = filterNotificationByType(this.props.notifications, 'proband_disconnected').filter(noti => noti.status == 'pending');
    // disconnected = disconnected.length
    // notif_count = clinician_requests_pending_count + patient_updates_count + disconnected

    let display_read_only = 'displayReadOnly' in this.props ? this.props.displayReadOnly : this.props.user.read_only

    return (
      <React.Fragment>
      {this.hidereadOnlyNav() &&(
        <nav className="navbar navbar-default navbar-fixed-top shrink-nav">
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className={hamburger_menu_css}
                data-toggle="collapse"
                aria-expanded={this.state.showHamburgerMenu}
                onClick={this.clickHamburgerMenu}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              {this.props.setShowAdvancedFilters ?
              <span onClick={() => this.props.setShowAdvancedFilters(false)}>
                <Link
                  id="home_link"
                  to={home_link}
                  className="navbar-brand"
                >
                <img
                  id={"logo"}
                  alt="FamGenix Logo"
                  src={images.famhisLogoRegNew}
                  className="margin-five"
                  style={{ width: "60%" }}
                />

                </Link>
              </span>
              :
              <Link
                id="home_link"
                to={home_link}
                className="navbar-brand"
              >
              <img
                id={"logo"}
                alt="FamGenix Logo"
                src={images.famhisLogoRegNew}
                className="margin-five"
                style={{ width: "60%" }}
                onClick={() => {if ("setShowAdvancedFilters" in this.props) this.props.setShowAdvancedFilters(false)}}
              />

              </Link>}
            </div>

            <div className={item_menu_css}>
              <ul className="nav navbar-nav navbar-right">
                {this.showNavSearch() && (
                <li>
                  <NavSearch history={this.props.history} handleSearchSubmit={this.handleSearchSubmit}/>
                </li>
                )}
                {this.props.setShowAdvancedFilters ?
                <li onClick={() => this.props.setShowAdvancedFilters(false)}>
                  <Link id="home_link2" to={home_link}>
                    <i id="home_icon" className="fa fa-home nav-home" style={{fontSize: "18px"}}></i>
                  </Link>
                </li>
                :
                <li>
                  <Link id="home_link2" to={home_link}>
                    <i id="home_icon" className="fa fa-home nav-home" style={{ fontSize: "18px" }}></i>
                  </Link>
                </li>
                }

                {this.isSuperAdmin(this.props.user) && (
                  <li>
                    <Link id="home_link2" to={survey_link}>
                        <i className="fa fa-file-text-o nav-home" aria-hidden="true"></i>
                    </Link>
                  </li>
              )}

              {(this.hidereadOnlyNav()&& !this.isSuperAdmin(this.props.user)) && (
                  <>
                  <li>
                    <a onClick={() => this.toggleNotifications()}>
                      <i className="fa fa-bell nav-home">
                        {/* data-count is being used in CSS class for badge */}
                        {notif_count > 0 && (
                        <span className="notification-badge" data-count={notif_count}></span>
                        )}
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href='https://famgenix.com/provider-support/' target="_blank" rel="noopener noreferrer">
                      <i style={{fontSize:'20px'}} className="fa fa-question-circle nav-home" aria-hidden="true"></i>
                    </a>
                  </li>
                  </>
                )}

                <li
                  className={
                    "dropdown dropdown--relative " +
                    (this.state.showSettings ? " open profile-dropdown" : "profile-dropdown")
                  }
                >

                  <button
                    onMouseEnter={() => this.toggleSettings(true)}
                    onMouseLeave={() => this.toggleSettings(false)}
                    className="dropdown-button-link dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded={this.state.showSettings ? "true" : "false"}
                    data-hover="dropdown"
                  >
                    <img
                      src={images.navAvatar}
                      alt="navigation settings avatar"
                    />
                  </button>

                  <ul
                    onMouseEnter={() => this.setMenuFocus(true)}
                    onMouseLeave={() => this.setMenuFocus(false)}
                    className="dropdown-menu"
                  >
                    <li>
                      <Link to={route_helper.administrative.user_profile}>
                        <i className="fa fa-user"></i>Profile
                      </Link>
                    </li>
                    {/*this.showStaffLink() && (
                      <li>
                        <Link to={route_helper.administrative.staff}>
                          <i className="fa fa-users"></i>Staff
                        </Link>
                      </li>
                    )*/}
                    {this.isUserAdmin(this.props.user) && (
                    <li>
                      <Link to={{
                        pathname: account_link,
                        state:{
                          fromAccount: true
                        }
                      }}>
                         <i className="fa fa-user"></i>Account
                      </Link>
                    </li>
                     )}
                    <li role="separator" className="divider"></li>
                    <li className="signout">
                      <a href="#" onClick={this.handleLogout}>
                        <i className="fa fa-sign-out"></i>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}

        {this.state.showNotifications &&
          <NotificationSlider
            inSearchResults={this.props.page === 'search-results'}
            refreshList={() => this.props.refreshList()}
            onClose={() => this.toggleNotifications()}
            handleClickOutside={() => this.toggleNotifications()}
            notification_counts={this.state.notification_counts}
            fetchNotificationCounts={() => this.fetchNotificationCounts()}
          />
        }

        {this.state.showPasswordModal &&
          <ModalResetPassword
            onClose={this.closePasswordModal}
            onClickSave={(payload) => this.onSaveUserPassword(payload)}
            errorMessage={this.state.passwordErrorMessage}
            helpText={this.state.resetPasswordHelpText}
          />
        }

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.session.user,
  searchFilter: state.search.searchFilter
});

export default connect(mapStateToProps)(NavTop);
