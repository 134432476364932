import React, { Component } from "react";
import { connect } from "react-redux";
import Cookie from "js-cookie";
import paper from 'paper';
import "../../../assets/css/paper-custom.css";
import Pedigree from "../../../library/pedigree/pedigree";
import pointer_gray from "../../../assets/production/images/pedigree-pointer-gray.png";
import icon_sub_menu_arrow from "../../../assets/production/images/icon-submenu-arrow.png";

import popup_menu_add_gray from "../../../assets/production/images/add-gray.png";
import popup_menu_add_teal from "../../../assets/production/images/add-teal.png";
import popup_menu_edit_gray from "../../../assets/production/images/edit-gray.png";
import popup_menu_edit_teal from "../../../assets/production/images/edit-teal.png";
import popup_menu_delete_gray from "../../../assets/production/images/delete-gray.png"
import popup_menu_delete_teal from "../../../assets/production/images/delete-teal.png"
import legend_eye from "../../../assets/production/images/eye.png"
import legend_eye_slash from "../../../assets/production/images/eye-slash.png"

import settings from "../../../library/pedigree/settings";
import helpers from "../../../helpers/index";
import * as helper_family_tree from "../../../helpers/helper-family-tree";

import FamilyHistorySidebar from "./family-history-sidebar";
import FamilyHistoryToolbar from "./family-history-toolbar";

import { saveAs } from "file-saver";
import ModalConfirmDelete from "../../modal-confirm-delete";
import ModalPopUpBlockerAlert from "../../modal-popup-blocker-alert";
import * as model from "../../../model-states/m-family-tree";
import helper_family_api from "../../../helpers/helper-family-api";
import ActivityIndicator from "../../activity-indicator";
import ErrorSummary from "../../error-summary";
import family_api from "../../../api/family-api";
import risk_api from '../../../api/risk-api';
import { save_risk_criteria as patient_action_save_risk_criteria } from '../../../store/patient/actions';
import { radix_bases } from '../../../helpers/helper-number-bases';

import add_multiple from '../../../assets/production/images/add-multiple.png'

import html2canvas from 'html2canvas'

import jsPDF from 'jspdf';
import "jspdf/dist/polyfills.es.js";
import { exec } from "apexcharts";

import constants from '../../../library/pedigree/constants'

import refineryApi from "../../../api/refinery-api";
import {set_cookie} from "../../../helpers/helper-cookies";
import { createUUID } from '../../react-flow-pedigree/utils';

class FamilyHistoryContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessages: [],
      loading: false,
      zoomFactor: 1.1,
      verticalSliderValue: 50,
      horizontalSliderValue: 50,
      alwaysShrink: false,
      chartInProgress: true,
      zoomValue: 100,
      latestCenter: null,
      isFitToScreen: null,
      movedToCenterExecuted: false,
      printing: false,
      selectedNode: null,
      selectedPerson: null,
      selectedPersonHash: new Date().getTime(),
      showSideBar: false,

      openModalConfirmDelete: false,
      openModalConfirmDeleteNoChildrenInfertilityNode: false,
      openPopupBlockerAlert: ["none", false],
      openCannotDeleteModal: ["none", false],
      modalConfirmDeleteHash: new Date().getTime(),
    };

    this.barWidth = 25;
    this.bar = null;
    this.verticalScrollBar = null;

    this.handleResize = this.handleResize.bind(this);
    this.handleZoomInClick = this.handleZoomInClick.bind(this);
    this.handleZoomOutClick = this.handleZoomOutClick.bind(this);
    this.handleFitToScreenClick = this.handleFitToScreenClick.bind(this);
    this.handleActualSizeClick = this.handleActualSizeClick.bind(this);
    this.handleShrinkCheck = this.handleShrinkCheck.bind(this);

    this.handleNodeClick = this.handleNodeClick.bind(this);
    this.handleSideBarClose = this.handleSideBarClose.bind(this);

    this.handleInfoAndHistoryUpdate = this.handleInfoAndHistoryUpdate.bind(this);
    this.handleDiseaseUpdate = this.handleDiseaseUpdate.bind(this);
    this.handlePrintClick = this.handlePrintClick.bind(this);
    this.handlePrintMultiplePagesClick = this.handlePrintMultiplePagesClick.bind(this);
    this.handleSaveImageClick = this.handleSaveImageClick.bind(this);
    this.handleNodeEditClick = this.handleNodeEditClick.bind(this);
    this.handleDeleteSelectedPerson = this.handleDeleteSelectedPerson.bind(this);
    this.handleToggleViewStatus = this.handleToggleViewStatus.bind(this);
    this.handleToggleLegend = this.handleToggleLegend.bind(this);
    this.handleToggleAncestry = this.handleToggleAncestry.bind(this);
    this.handleToggleGeneticTesting = this.handleToggleGeneticTesting.bind(this);
    this.handleGeneTestUpdate = this.handleGeneTestUpdate.bind(this);
    this.handleCheckDeleteSelectedPerson =  this.handleCheckDeleteSelectedPerson.bind(this);
    this.resizePedigree = this.resizePedigree.bind(this);
    this.refreshPedigree = this.refreshPedigree.bind(this);
    this.refreshPedigreeFlux = this.refreshPedigreeFlux.bind(this);
    this.refreshPedigreeAddChild = this.refreshPedigreeAddChild.bind(this);
    this.isItIE = this.isItIE.bind(this);
    this.refreshPedigreeFlux = this.refreshPedigreeFlux.bind(this)
    this.createPrintHeader = this.createPrintHeader.bind(this);
    this.createPrintFooter = this.createPrintFooter.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true })
    window.addEventListener("resize", this.resizePedigree);
    let alwaysShrink = undefined;
    if (Cookie.get("famgenix_always_shrink") == undefined) {
      set_cookie("famgenix_always_shrink", 0)
    }
    alwaysShrink = Cookie.get("famgenix_always_shrink") == "1"
    this.setState({ alwaysShrink });
    if (this.state.alwaysShrink) {
      this.setState({ isFitToScreen: true })
    }
    else {
      this.setState({ isFitToScreen: false })
    }
    let center = window.localStorage.getItem("famgenix_latestcenter")
    let node = window.localStorage.getItem("selectedNode");
    if (node == null || node == undefined) {
      this.setState({ selectedNode: null })
    }
    else {
      this.setState({ selectedNode: JSON.parse(node) })
    }
    if (this.props.latestCenter !== null) {
      this.setState({ latestCenter: this.props.latestCenter })
    }
    else {
      if (center == null || center == undefined) {
        this.setState({ latestCenter: null })
      }
      else {
        let centerFromLocal = JSON.parse(center);
        centerFromLocal.splice(0, 1);
        this.setState({ latestCenter: centerFromLocal })
      }
    }
    if (Cookie.get("famgenix_active_smartdraw") == undefined) {
      set_cookie("famgenix_active_smartdraw", 'original')
    }
    await helpers.delay(.25)
    this.initializePedigreeChart();
    this.setState({ loading: false })
  }

  componentWillUnmount() {
     window.removeEventListener("resize", this.resizePedigree);
  //   this.pedigree.clearMenuButton();
  }

  async resizePedigree() {
    this.setState({ chartInProgress: true }, async () => {
      await helpers.delay(0.5);
    });
    await this.initializePedigreeChart();
  }

  getShapedConditionsImages() {
    let prefix = settings.shaped_conditions_code_prefix; // bw
    let count = settings.shaped_conditions_images_count; // 10

    //bw-1 to bw-10
    let shaped_conditions_images = [];
    shaped_conditions_images = [...Array(count).keys()].map(i =>
      require(`../../../assets/production/images/symbols/${prefix}-${i +
        1}.png`)
    );

    return shaped_conditions_images;
  }

  async handleResize(WindowSize, event) {
    await this.pedigree.clearAll();
    await this.resizePedigree();
    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      await this.refreshPedigreeFlux(false);
    }
    else{
      await this.refreshPedigree();
    }
  }

  async initializePedigreeChart() {

    await helpers.delay(.25)
    // let redux_tree = await refineryApi.get_redux_tree(this.props.patient.patient.id)
    let redux_tree = await refineryApi.get_redux_tree_deux(this.props.patient.patient.id, {"canvas": "1"})

    let has_half_or_great_uncles_aunts = redux_tree.has_half_or_great_uncles_aunts
    let firstOpenPedigree = localStorage.getItem('refreshDoneOriginalToFlux') === null && localStorage.getItem('refreshDoneFluxToOriginal') === null
    let refreshDoneOriginalToFlux = localStorage.getItem('refreshDoneOriginalToFlux') !== null ? localStorage.getItem('refreshDoneOriginalToFlux') == 'true' : false
    let refreshDoneFluxToOriginal = localStorage.getItem('refreshDoneFluxToOriginal') !== null ? localStorage.getItem('refreshDoneFluxToOriginal') == 'true' : false
    if(has_half_or_great_uncles_aunts) {
      if(!refreshDoneOriginalToFlux){
        if(!firstOpenPedigree){
          localStorage.setItem('refreshDoneOriginalToFlux', true)
          localStorage.setItem('refreshDoneFluxToOriginal', false)
          set_cookie("famgenix_active_smartdraw", 'flux')
          await this.refreshPedigreeFlux(true);
        }
        else{
          localStorage.setItem('refreshDoneOriginalToFlux', true)
          localStorage.setItem('refreshDoneFluxToOriginal', false)
          set_cookie("famgenix_active_smartdraw", 'flux')
        }
      }
    } else {
      if (process.env.REACT_APP_NODE_ENV !== 'development' && !refreshDoneFluxToOriginal) {
        if(!firstOpenPedigree){
          localStorage.setItem('refreshDoneOriginalToFlux', false)
          localStorage.setItem('refreshDoneFluxToOriginal', true)
          set_cookie("famgenix_active_smartdraw", 'original');
          await this.refreshPedigreeFlux(true);
        }
        else{
          localStorage.setItem('refreshDoneOriginalToFlux', false)
          localStorage.setItem('refreshDoneFluxToOriginal', true)
          set_cookie("famgenix_active_smartdraw", 'original');
        }
      }
    }

    let nodeLocations = this.props.nodeLocations

    try{
      if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
        const objectArray = Object.entries(redux_tree.profiles);
        objectArray.forEach(([key, value]) => {
          if(value.twin_set === null){
            value.twin_type = null;
          }
        });


        redux_tree.profiles = Object.fromEntries(objectArray);

        helper_family_tree.updateFamilyTree(redux_tree, this.props.patient.patient, false)

        nodeLocations = await this.props.getNodeLocations(redux_tree.profiles);
      }
    }
    catch(e){
      console.log(e.stack)
    }


    // Other element height depends on width of window
    let otherElementHeight = 0;
    if (window.innerWidth > 0 && window.innerWidth < 989) {
      otherElementHeight = 86;
    } else if (window.innerWidth > 989 && window.innerWidth < 1073) {
      otherElementHeight = 25;
    } else if (window.innerWidth > 1073 && window.innerWidth < 1290) {
      otherElementHeight = 22;
    } else if (window.innerWidth > 1290) {
      otherElementHeight = 0;
    }
    otherElementHeight += 53; // header with logo
    otherElementHeight += 30; // top menu
    otherElementHeight += 42; // toolbar

    let displayAncestry = Cookie.get('famgenix_pedigree_ancestry')
    let displayAncestryBool = displayAncestry == 'true'

    await helpers.delay(0.5);

    let self = this;
    let option = {
      condition_type: "color", //(Math.random() > 0.5) ? 'color' : 'shape'
      height: window.innerHeight - otherElementHeight,
      width: window.innerWidth - 12,
      showAncestry: displayAncestryBool,
      readOnly: this.props.read_only,
      nodeLocations: nodeLocations,
      selectedNodeScroll: this.state.selectedNode,
      render_for_print: this.state.printing,
      onNodeClick: node => self.handleNodeClick(node),
      onNodeEditClick: node => self.handleNodeEditClick(node),
      onOutsideClick: () => self.handleSideBarClose(),
      onPedigreeNotesSave: (pedigreeNotes) => self.onPedigreeNotesSave(pedigreeNotes),
      onLegendEyeClick: (disease_id, save) => self.onLegendEyeClick(disease_id, save),
      menus: {
        parent_menu_items: this.getParentMenuItems(),
        sibling_menu_items: this.getSiblingMenuItems(),
        partner_menu_items: this.getPartnerMenuItems(),
        children_menu_items: this.getChildrenMenuItems()
      }
    };

    // set selected node if there is one and show sidebar
    if(this.state.printing !== false){
      option['render_for_print'] = this.state.printing
    }

    if (this.state.selectedNode !== null) {
      option['selectedNode'] = this.state.selectedNode
    }

    this.pedigree = new Pedigree("paper_canvas", this.props.patient, option);
    this.pedigree.drawChart();

    // Toggle View
    let viewStatus = Cookie.get('famgenix_pedigree_view_status') || 'identified'
    this.pedigree.toggleViewStatus(viewStatus)

    // Display legend?
    let displayLegend = Cookie.get('famgenix_pedigree_legend')
    let displayPedigreeNotes = Cookie.get('famgenix_pedigree_legend_notes')
    let displayPedigreeNotesBool = displayPedigreeNotes == 'true'
    let displayLegendBool = displayLegend == 'true'
    this.pedigree.toggleLegend(displayLegendBool, displayPedigreeNotesBool)

    // Display ancestry?
    //Always display ancestry for read only users
    if(this.props.read_only){
      this.pedigree.toggleAncestry(true)
    }else{
      this.pedigree.toggleAncestry(displayAncestryBool)
    }

    await helpers.delay(0.2);
    if (this.state.alwaysShrink) {
      this.handleFitToScreenClick();
    }

    if (this.state.latestCenter !== null) {
      this.pedigree.setToLastCenter(this.state.latestCenter);
    }

    this.setState({ chartInProgress: false });
  }

  getParentMenuItems() {
    return [
      { text: "Parents", itemClick: node => this.addParent(node) },
      // { text: "Father", itemClick: node => this.addParent(node) },
      // { text: "Mother", itemClick: node => this.addParent(node) },
    ];
  }

  getSiblingMenuItems() {
    return [
      {
        text: "Brother",
        itemClick: node => this.addSibling(node, "brother", 1),
        items: [
          {
            text: "+2",
            itemClick: node => this.addSibling(node, "brother", 2)
          },
          {
            text: "+3",
            itemClick: node => this.addSibling(node, "brother", 3)
          },
          {
            text: "+4",
            itemClick: node => this.addSibling(node, "brother", 4)
          },
          {
            text: "+5",
            itemClick: node => this.addSibling(node, "brother", 5)
          }
        ]
      },
      {
        text: "Sister",
        itemClick: node => this.addSibling(node, "sister", 1),
        items: [
          {
            text: "+2",
            itemClick: node => this.addSibling(node, "sister", 2)
          },
          {
            text: "+3",
            itemClick: node => this.addSibling(node, "sister", 3)
          },
          {
            text: "+4",
            itemClick: node => this.addSibling(node, "sister", 4)
          },
          {
            text: "+5",
            itemClick: node => this.addSibling(node, "sister", 5)
          }
        ]
      },
      {
        text: "Unknown",
        itemClick: node => this.addSibling(node, "unknown", 1),
        items: [
          {
            text: "+2",
            itemClick: node => this.addSibling(node, "unknown", 2)
          },
          {
            text: "+3",
            itemClick: node => this.addSibling(node, "unknown", 3)
          },
          {
            text: "+4",
            itemClick: node => this.addSibling(node, "unknown", 4)
          },
          {
            text: "+5",
            itemClick: node => this.addSibling(node, "unknown", 5)
          }
        ]
      },
      {
        text: "Twin Brother",
        itemClick: node => this.addSibling(node, "twin-brother", 1)
      },
      {
        text: "Twin Sister",
        itemClick: node => this.addSibling(node, "twin-sister", 1)
      },
      {
        text: "Twin Unknown",
        itemClick: node => this.addSibling(node, "twin-unknown", 1)
      }
    ];
  }

  getPartnerMenuItems() {
    return [
      {
        text: "Married",
        itemClick: node => this.addPartner(node, "married"),
      },
      {
        text: "Separated",
        itemClick: node => this.addPartner(node, "separated")
      },
      {
        text: "Divorced",
        itemClick: node => this.addPartner(node, "divorced")
      },
      { text: "Other", itemClick: node => this.addPartner(node, "other") },
      {
        text: "Consanguineous",
        itemClick: node => this.addPartner(node, "consanguineous")
      }
    ];
  }

  getChildrenMenuItems() {
    return [
      {
        text: "Son",
        itemClick: node => this.addChildren(node, "son", 1),
        items: [
          {
            text: "+2",
            itemClick: node => this.addChildren(node, "son", 2)
          },
          {
            text: "+3",
            itemClick: node => this.addChildren(node, "son", 3)
          },
          {
            text: "+4",
            itemClick: node => this.addChildren(node, "son", 4)
          },
          {
            text: "+5",
            itemClick: node => this.addChildren(node, "son", 5)
          }
        ]
      },
      {
        text: "Daughter",
        itemClick: node => this.addChildren(node, "daughter", 1),
        items: [
          {
            text: "+2",
            itemClick: node => this.addChildren(node, "daughter", 2)
          },
          {
            text: "+3",
            itemClick: node => this.addChildren(node, "daughter", 3)
          },
          {
            text: "+4",
            itemClick: node => this.addChildren(node, "daughter", 4)
          },
          {
            text: "+5",
            itemClick: node => this.addChildren(node, "daughter", 5)
          }
        ]
      },
      {
        text: "Unknown",
        itemClick: node => this.addChildren(node, "unknown", 1),
        items: [
          {
            text: "+2",
            itemClick: node => this.addChildren(node, "unknown", 2)
          },
          {
            text: "+3",
            itemClick: node => this.addChildren(node, "unknown", 3)
          },
          {
            text: "+4",
            itemClick: node => this.addChildren(node, "unknown", 4)
          },
          {
            text: "+5",
            itemClick: node => this.addChildren(node, "unknown", 5)
          }
        ]
      },
      {
        text: 'No Children',
        itemClick: (node) => this.addChildren(node, 'no children', 1)
      },
      {
        text: 'Infertility',
        itemClick: (node) => this.addChildren(node, 'infertility', 1)
      },
      {
        text: "Pregnancy",
        itemClick: () => { },
        items: [
          {
            text: "Male Fetus",
            itemClick: node => this.addChildren(node, "male fetus", 1)
          },
          {
            text: "Female Fetus",
            itemClick: node => this.addChildren(node, "female fetus", 1)
          },
          {
            text: "Unknown",
            itemClick: node => this.addChildren(node, "pregnancy-unknown", 1)
          }
        ]
      },
    ];
  }

  create_relationship_data(father, mother) {
    return {
      id: undefined,
      marital_status: null,
      is_parent_blood_related: false,
      blood_relation_type: null,
      mother_id: mother.id,
      father_id: father.id,
    };
  }

  async onPedigreeNotesSave(pedigreeNotes){
    try{
      let payload = {
        family_id: this.props.patient.patient.family_id,
        pedigreeNotes: pedigreeNotes
      }

      const family_id = await helper_family_api.save_family_pedigree_notes(payload)
      await helper_family_tree.fetchFamilyTreePedigreeNotes(this.props.dispatch, family_id)

    }catch(error){
      console.log(error.message)
    }
    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      await this.refreshPedigreeFlux(false);
    }
    else{
      await this.refreshPedigree();
    }
  }

  async onLegendEyeClick(disease_id, save) {
    try{
      let payload = {
        disease_id,
        organization_id: this.props.organization_id,
        family_id: this.props.patient.patient.family_id,
        clinician_id: this.props.clinician_id
      }
      if (save){
        let saved_hidden_disease_color = await helper_family_api.save_hidden_disease_colors(payload)
        await helper_family_tree.addToHiddenDiseaseColors(this.props.dispatch, saved_hidden_disease_color)
      }
      else{
        let removed_hidden_disease_color = await helper_family_api.remove_hidden_disease_colors(payload)
        await helper_family_tree.removeFromHiddenDiseaseColors(this.props.dispatch, removed_hidden_disease_color)
      }
    }

    catch(error){
      console.log(error.message)
    }
    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      await this.refreshPedigreeFlux(false);
    }
    else{
      await this.refreshPedigree();
    }
  }

  async addParent(node) {
    try {

      let profile = node.data.profile;
      let proband_id = this.props.patient.patient.id;
      // Save to API
      let parents = await helper_family_api.create_parents(proband_id, profile.id)

      //Id
      let mother = parents.mother;
      let father = parents.father;
      mother.rkey = `apimem-${mother.id}`;
      father.rkey = `apimem-${father.id}`;

      // Save to profile to redux
      helper_family_tree.saveProfileToRedux(this.props.dispatch, mother);
      helper_family_tree.saveProfileToRedux(this.props.dispatch, father);

      // Append relationship data
      mother.relationship_data = this.create_relationship_data(father, mother);

      // Save partner to redux
      let ownerRkey = father.rkey;
      helper_family_tree.savePartnerToRedux(
        this.props.dispatch,
        ownerRkey,
        mother
      );

      // Update profile
      profile.father_id = father.id;
      profile.mother_id = mother.id;
      helper_family_tree.saveProfileToRedux(this.props.dispatch, profile);

      // Save profile as son/daughter
      helper_family_tree.saveSonDaughterDetailToRedux(
        this.props.dispatch,
        mother.rkey,
        profile
      );

      await this.refreshPedigree();


    } catch (error) {
      this.setState({ errorMessages: [error.message] });
    }
  }

  async updateInfertileNoChildrenValue(profile, type, value){
    try{
      let key = type == 'infertility' ? 'infertile' : 'no_children'
      let people = Object.values(this.props.patient.profile)

      await family_api.patch_member_memberid(profile.id, {[key]: value})
      let profile_data = people.find(p => p.rkey == profile.rkey)
      if(key == 'infertile'){
        profile_data.infertile = value
      }
      else{
        profile_data.no_children = value
      }

      helper_family_tree.saveProfileToRedux(this.props.dispatch, profile_data)

      if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
        await this.refreshPedigreeFlux(false);
      }
      else{
        await this.refreshPedigree();
      }
    }
    catch(e){
      console.log(e.stack)
    }
  }

  async addChildren(node, value, count) {
    try {
      // let skipped = ["infertility", "no children"];
      // if (skipped.includes(value)) throw new Error(`Cannot add ${value}`);

      let node_type = node.node_type;
      let { profile } = node.data;

      // if (profile.level == 5) {
      //   throw new Error(`Adding beyond grandchildren is not allowed.`);
      // }
      let people = Object.values(this.props.patient.profile)

      if (value == 'no children' || value == 'infertility'){
        let updated_profile = people.find(person => person.id == profile.id)
        if (updated_profile.infertile || updated_profile.no_children){
          throw new Error("Cannot add children if parents are infertile or marked no children.");
        }
        await this.updateInfertileNoChildrenValue(profile, value, true)
        return;
      }

      let partner = profile;
      let spouse = this.getSpouse(node_type, profile);
      if (spouse == null) {
        spouse = profile;
        let rkey = spouse.rkey;
        if (!this.props.patient.partners[rkey]) {
          partner = await this.addPartner(node, "married");
        } else if (this.props.patient.partners[rkey].length === 1) {
          partner = this.props.patient.partners[rkey][0];
        } else {
          throw new Error(`Multiple Partners - Please add children to the proper partner`);
        }
      }

      let child_data = {};

      for(let i = 0; i < count; i++){
        let gender = this.getChildGender(value);
        let child = model.createSonDaughter(gender);
        child.pregnancy = false;
        if (["male fetus", "female fetus", "pregnancy-unknown"].includes(value)) {
          child.pregnancy = true;
        }

        child.value = value;

        if (node_type === "immediate_son_daughter" && profile.level === 2) {
          let temp = spouse;
          spouse = partner;
          partner = temp;
        }

        // Save to API
        child_data = await helper_family_api.create_member_as_child(
          spouse,
          partner,
          child,
          profile.side
        );

        child_data.rkey = `apimem-${child_data.id}`;

        // Save to Redux
        helper_family_tree.saveProfileToRedux(this.props.dispatch, child_data);

        if (
          ["proband_parent_other_partner", "mother", "father"].includes(node_type)
        ) {
          helper_family_tree.saveSiblingDetailToRedux(
            this.props.dispatch,
            child_data
          );
        } else if (node_type.includes("grand")) {
          let parent_side = node_type.split("_grand")[0];
          helper_family_tree.saveUncleAuntDetailToRedux(
            this.props.dispatch,
            parent_side,
            child_data
          );
        } else {
          helper_family_tree.saveSonDaughterDetailToRedux(
            this.props.dispatch,
            partner.rkey,
            child_data
          );
        }
      }



      let aunt_uncle_of_proband = false;
      let great_aunt_uncle_of_proband = false;

      let proband = this.props.patient.patient
      let father_id = proband.father_id
      let mother_id = proband.mother_id
      let father = people.find(person => person.id === father_id)
      let mother = people.find(person => person.id === mother_id)
      let paternal_grandfather = people.find(person => person.id === father.father_id)
      let paternal_grandmother = people.find(person => person.id === father.mother_id)
      let maternal_grandfather = people.find(person => person.id === mother.father_id)
      let maternal_grandmother = people.find(person => person.id === mother.mother_id)
      let more_ancestors = false;

      let father_siblings = people.filter(person => person.father_id === paternal_grandfather.id && person.mother_id === paternal_grandmother.id && person.id !== father_id)
      let mother_siblings = people.filter(person => person.father_id === maternal_grandfather.id && person.mother_id === maternal_grandmother.id && person.id !== mother_id)

      let father_sibling = father_siblings.find(sibling => sibling.id === child_data.id)
      let mother_sibling = mother_siblings.find(sibling => sibling.id === child_data.id)

      if(father_sibling || mother_sibling){
        aunt_uncle_of_proband = true;
      }


      let currentTopAncestors = [paternal_grandfather, paternal_grandmother, maternal_grandfather, maternal_grandmother];

      if(paternal_grandfather.father_id || paternal_grandmother.father_id || maternal_grandfather.father_id || maternal_grandmother.father_id){
        more_ancestors = true
      }


      while(more_ancestors){
        for(let ancestor of currentTopAncestors){
          let siblings = people.filter(person => person.father_id === ancestor.father_id && person.mother_id === ancestor.mother_id && person.id !== ancestor.id)
          let great_aunt_uncle = siblings.find(sibling => sibling.id === child_data.id)
          if(great_aunt_uncle){
            great_aunt_uncle_of_proband = true;
            break;
          }
        }
        let newCurrentTopAncestors = [];
        for(let ancestor of currentTopAncestors){
          let father = people.find(person => person.id === ancestor.father_id)
          let mother = people.find(person => person.id === ancestor.mother_id)
          if(father && mother){
            let parents = [father, mother]
            newCurrentTopAncestors = newCurrentTopAncestors.concat(parents);
          }
        }
        currentTopAncestors = newCurrentTopAncestors;
        if(currentTopAncestors.length === 0){
          more_ancestors = false;
        }
      }

      // console.log(paternal_grandfather.id, paternal_grandmother.id, maternal_grandfather, maternal_grandmother)

      if(aunt_uncle_of_proband || great_aunt_uncle_of_proband){
        await this.refreshPedigreeFlux(true)
      }
      else{
        await this.refreshPedigree()
      }

    } catch (error) {
      console.log(error.stack)
      this.setState({ errorMessages: [error.message] });
    }
  }

  getSpouseByNodeType(node_type) {
    let ownerRkey = null;
    switch (node_type) {
      case "father":
        ownerRkey = this.props.patient.patient.mother.rkey;
        break;
      case "mother":
        ownerRkey = this.props.patient.patient.father.rkey;
        break;
      case "paternal_grandfather":
        ownerRkey = this.props.patient.patient.paternal_grandmother.rkey;
        break;
      case "paternal_grandmother":
        ownerRkey = this.props.patient.patient.paternal_grandfather.rkey;
        break;
      case "maternal_grandfather":
        ownerRkey = this.props.patient.patient.maternal_grandmother.rkey;
        break;
      case "maternal_grandmother":
        ownerRkey = this.props.patient.patient.maternal_grandfather.rkey;
        break;
    }

    if (ownerRkey === null) return null;

    return helper_family_tree.getProfileFromRedux(
      this.props.patient,
      ownerRkey
    );
  }

  getSpouse(node_type, profile) {
    let spouse = this.getSpouseByNodeType(node_type);
    if (spouse !== null) return spouse;

    let ownerRkey = helper_family_tree.getPartnerOwnerRkey(
      this.props.patient.partners,
      profile.rkey
    );
    if (ownerRkey == "proband") {
      ownerRkey = this.props.patient.patient.rkey;
    } else if (ownerRkey == "proband_father") {
      ownerRkey = this.props.patient.patient.father.rkey;
    } else if (ownerRkey == "proband_mother") {
      ownerRkey = this.props.patient.patient.mother.rkey;
    }

    return helper_family_tree.getProfileFromRedux(
      this.props.patient,
      ownerRkey
    );
  }

  getChildGender(value) {
    switch (value) {
      case "son":
      case "male fetus":
        return "m";
      case "daughter":
      case "female fetus":
        return "f";
      case "unknown":
      case "pregnancy-unknown":
        return null;
    }

    return null;
  }

  async addPartner(node, value) {
    try {
      let node_type = node.node_type;
      let { profile } = node.data;
      let partner = model.createPartner();
      let parent_side = profile.side;

      partner.relationship_data.is_parent_blood_related =
        value == "consanguineous";
      partner.relationship_data.marital_status =
        value == "consanguineous" ? "other" : value;

      let partner_data = await helper_family_api.create_partner(
        profile,
        partner,
        parent_side
      );
      partner_data.sons_count = 0;
      partner_data.daughters_count = 0;

      partner_data.rkey = `apimem-${partner_data.id}`;

      let ownerRkey = profile.rkey;
      if (["father", "mother"].includes(node_type)) {
        ownerRkey = "proband_" + node_type;
      } else if (node_type == "proband") {
        ownerRkey = "proband";
      }

      helper_family_tree.savePartnerToRedux(
        this.props.dispatch,
        ownerRkey,
        partner_data
      );
      helper_family_tree.saveProfileToRedux(this.props.dispatch, partner_data);

      if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
        await this.refreshPedigreeFlux(false);
      }
      else{
        await this.refreshPedigree();
      }
      return partner_data;
    } catch (error) {
      this.setState({ errorMessages: [error.message] });
      console.log(error.stack)
    }
  }

  async updateTwinsEntry(profile, child_data = null) {
    /*
    Add: if child data is not null
    Delete: if child data is null
    */
    let all_profiles = this.props.patient.profile;
    let { father_id, mother_id } = profile;

    // Fetch twins
    let twins = [];
    for (var rkey in all_profiles) {
      var item = all_profiles[rkey];
      if (
        item.father_id == father_id &&
        item.mother_id == mother_id &&
        item.twin_id !== null
      ) {
        twins.push(item);
      }
    }

    /*
    Append child data if provided,
    otherwise, this operation means delete the selected profile
    */
    if (child_data === null) {
      twins = twins.filter(item => item.id != profile.id);
    } else {
      let profile_is_twin = twins.find(item => item.rkey == profile.rkey);
      if (typeof profile_is_twin === "undefined") {
        twins.push({ ...profile, twin_type: "fraternal" });
        twins.push({ ...child_data, twin_type: "fraternal" });
      } else {
        twins.push({
          ...child_data,
          twin_type: profile.twin_type,
          twin_set: profile.twin_set
        });
      }
    }

    // Build sets
    let twin_sets = {};
    for (var twin of twins) {
      var set = twin.twin_set == null ? "new-set" : twin.twin_set;
      if (twin_sets.hasOwnProperty(set) === false) twin_sets[set] = [];
      twin_sets[set].push({
        member_id: twin.id,
        twin_type: twin.twin_type
      });
    }

    // Build payload
    let twin_set_members = [];
    var set_no = 1;
    for (var key in twin_sets) {
      var set_members = twin_sets[key];
      // member should be atlest 2, otherwise ignore
      if (set_members.length >= 2) {
        twin_set_members.push({
          set: set_no,
          members: twin_sets[key]
        });
        set_no += 1;
      }
    }

    // Save to API
    let payload = {
      father_id: profile.father_id,
      mother_id: profile.mother_id,
      family_id: profile.family_id,
      twins: twin_set_members
    };

    let data = await family_api.post_twin_new(payload);
    // Save to redux the newly added twin
    // It will only return a data array with single item
    if (data.length > 0) {
      let members = data[0].members;
      for (var item of members) {
        var twin_profile = twins.find(twin => twin.id == item.id);
        if (typeof twin_profile !== "undefined") {
          var updates = {
            rkey: twin_profile.rkey,
            twin_id: item.twin_id,
            twin_set: item.twin_id,
            twin_type: item.twin_type
          };
          helper_family_tree.saveProfileToRedux(this.props.dispatch, updates);
        }
      }
    }

    /*
    Check the twin of deleted member
    If there is only one member left, clear twin data for that member
    */
    if (child_data === null) {
      let twins_of_deleted_member = twins.filter(
        item => item.id != profile.id && item.twin_set == profile.twin_set
      );

      if (twins_of_deleted_member.length == 1) {
        let only_member = twins_of_deleted_member[0];
        var only_member_updates = {
          rkey: only_member.rkey,
          twin_set: null,
          twin_id: null,
          twin_type: null
        };
        helper_family_tree.saveProfileToRedux(
          this.props.dispatch,
          only_member_updates
        );
      }
    }

  }

  getSiblingGender(value){
    switch (value) {
      case "brother":
      case "twin-brother":
        return "m";
      case "sister":
      case "twin-sister":
        return "f";
      case "unknown":
      case "twin-unknown":
        return null;
    }

    return null;
  }

  async addSibling(node, value, count) {
    try {
      let node_type = node.node_type;

      let { profile } = node.data;

      for(let i = 0; i < count; i++){
        let gender = this.getSiblingGender(value);
        var child = model.createSonDaughter(gender);
        let parents = await family_api.get_member_memberid_parents(profile.id)
        let father = {
          family_id: profile.family_id,
          level: parents.father.level,
          id: parents.father.id,
          gender: "m"
        };
        let mother = { id: parents.mother.id };

        // Save to API
        let child_data = await helper_family_api.create_member_as_child(
          father,
          mother,
          child,
          profile.side
        );

        child_data.rkey = `apimem-${child_data.id}`;

        // Save to Redux
        helper_family_tree.saveProfileToRedux(this.props.dispatch, child_data);

        // Save if there is twins
        if (value.includes("twin")) {
          await this.updateTwinsEntry(profile, child_data);
        }

        // Save redux to respective dedicated store
        if (node_type.includes("son_daughter") || node_type === "immediate_partner") {
          let partnerRkey = helper_family_tree.getSonDaughterParentPartnerRkey(
            this.props.patient.sons_daughters,
            profile.rkey
          );
          helper_family_tree.saveSonDaughterDetailToRedux(
            this.props.dispatch,
            partnerRkey,
            child_data
          );
        } else if (node_type.includes("sibling") || node_type == 'proband') {
          helper_family_tree.saveSiblingDetailToRedux(
            this.props.dispatch,
            child_data
          );
        } else if (node_type.includes("uncle_aunt")) {
          let parent_side = node_type.replace("_uncle_aunt", '');
          helper_family_tree.saveUncleAuntDetailToRedux(
            this.props.dispatch,
            parent_side,
            child_data
          );
        } else if (node_type == "father") {
          let parent_side = "paternal";
          helper_family_tree.saveUncleAuntDetailToRedux(
            this.props.dispatch,
            parent_side,
            child_data
          );
        } else if (node_type == "mother") {
          let parent_side = "maternal";
          helper_family_tree.saveUncleAuntDetailToRedux(
            this.props.dispatch,
            parent_side,
            child_data
          );
        }
      }

      if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
        await this.refreshPedigreeFlux(true);
      }
      else{
        await this.refreshPedigree();
      }
    } catch (error) {
      this.setState({ errorMessages: [error.message] });
      console.log(error.stack)
    }
  }

  handleNodeEditClick(node) {
    let selectedPerson = {
      data: node.data,
      node_type: node.node_type
    };
    if(node.data.profile.is_no_children_node || node.data.profile.is_infertility_node){
      this.setState({
        selectedNode: node,
        selectedPerson,
        openModalConfirmDeleteNoChildrenInfertilityNode: true
      });
    }
  }

  handleZoomInClick() {
    let items = paper.project.getItems({
      type: 'layer'
    });
    let pedigreeWidth = items[0].bounds.width;
    let pedigreeHeight = items[0].bounds.height;
    let zoom_value = parseInt(this.pedigree.zoomIn() * 100, radix_bases.base10);
    if (((pedigreeWidth - pedigreeHeight) >= 400) && this.state.isFitToScreen && !this.state.movedToCenterExecuted && !this.pedigree.moveToCenterExecuted) {
      this.pedigree.moveToCenter();
      this.setState({ movedToCenterExecuted: true })
      this.pedigree.moveToCenterExecuted = true;
    }
    this.setState({ zoomValue: zoom_value, isFitToScreen: false });
  }

  handleZoomOutClick() {
    let zoom_value = parseInt(this.pedigree.zoomOut(this.state.showSideBar) * 100, radix_bases.base10);
    if (zoom_value > this.pedigree.minimumZoomValue(this.state.showSideBar) * 100) {
      this.setState({ zoomValue: zoom_value });
    }
    else {
      zoom_value = parseInt(this.pedigree.minimumZoomValue(this.state.showSideBar) * 100, radix_bases.base10);
      this.setState({ zoomValue: zoom_value });
    }
  }

  handleFitToScreenClick() {
    let zoom_value = 0
    zoom_value = parseInt(this.pedigree.fitToScreen(this.state.showSideBar) * 100, radix_bases.base10);
    this.setState({ zoomValue: zoom_value, movedToCenterExecuted: false, isFitToScreen: true });
    this.pedigree.moveToCenterExecuted = false;
  }

  handleActualSizeClick() {
    this.pedigree.setToOriginal();
    this.setState({ zoomValue: 100, isFitToScreen: false });
  }

  handleShrinkCheck(e) {
    let alwaysShrink = false;
    if (e.target.checked) {
      set_cookie("famgenix_always_shrink", 1);
      alwaysShrink = true;
      this.handleFitToScreenClick();
      this.setState({ isFitToScreen: true })
    } else {
      set_cookie("famgenix_always_shrink", 0);
      alwaysShrink = false;
      this.handleActualSizeClick();
      this.setState({ isFitToScreen: false })
    }

    this.setState({ alwaysShrink });
  }

  simpleStringify (object){
    // stringify an object, avoiding circular structures
    var simpleObject = {};
    for (var prop in object ){
        if (!object.hasOwnProperty(prop)){
            continue;
        }
        if (typeof(object[prop]) == 'object'){
            continue;
        }
        if (typeof(object[prop]) == 'function'){
            continue;
        }
        simpleObject[prop] = object[prop];
    }
    return JSON.stringify(simpleObject); // returns cleaned up JSON
  };


  async handleNodeClick(node) {
    // TODO: this is a bit of a hack, maybe think on another solution
    // this gives the sidebar enough time to initiate an autosave if needed
    await helpers.delay(0.1);

    let selectedPerson = {
      data: node.data,
      node_type: node.node_type
    };

    const showSidebar = node.data.profile.is_no_children_node || node.data.profile.is_infertility_node ? false : true;
    const showPedigreeScrollbars = !showSidebar;
    this.setState({
      selectedNode: node,
      selectedPerson,
      showSideBar: showSidebar
    });

    if (this.state.isFitToScreen) {
      this.handleFitToScreenClick();
    }
    window.localStorage.setItem("selectedNode", this.simpleStringify(node))
  }

  async handleSideBarClose() {
    // TODO: this is a bit of a hack, maybe think on another solution
    // this gives the sidebar enough time to initiate an autosave if needed
    await helpers.delay(0.1);

    window.localStorage.removeItem("selectedNode");
    this.setState({ showSideBar: false, selectedNode: null });

    if (this.state.isFitToScreen) {
      this.handleFitToScreenClick();
    }
  }

  async refreshPedigree() {
    await helpers.delay(0.2);
    await this.initializePedigreeChart();
  }

  async refreshPedigreeFlux(refresh) {
    await helpers.delay(0.2);
    if(refresh){
      await this.props.onPedigreeRefresh();
    }
    else{
      await this.initializePedigreeChart();
    }
  }

  async refreshPedigreeAddParent() {
    let latestCenter = this.pedigree.getLatestCenter();
    window.localStorage.setItem("famgenix_latestcenter", JSON.stringify(latestCenter))
    this.props.refreshPedigreeAddParent(this.state.selectedNode, this.pedigree.getLatestCenter())
  }
  async refreshPedigreeAddChild() {
    await helpers.delay(0.2);
    this.props.refreshPedigreeAddChild(this.state.selectedNode, this.pedigree.getLatestCenter())
  }
  async handleInfoAndHistoryUpdate(data) {
    try {

      let person = this.state.selectedNode;

      if (person) {
        let relationship = person.node_type;
        let new_profile = data.profile;
        let org = null;

        switch (relationship) {
          case 'proband_sibling': {
            helper_family_tree.saveSiblingDetailToRedux(this.props.dispatch, new_profile);
            break;
          }
          case 'son_daughter': {
            helper_family_tree.saveSonDaughterDetailToRedux(this.props.dispatch, new_profile.parent_rkey, new_profile);
            break;
          }
          case 'paternal_uncle_aunt': {
            helper_family_tree.saveUncleAuntDetailToRedux(this.props.dispatch, 'paternal', new_profile);
            break;
          }
          case 'maternal_uncle_aunt': {
            helper_family_tree.saveUncleAuntDetailToRedux(this.props.dispatch, 'maternal', new_profile);
            break;
          }
          default:
            break;
        }

      }

      // Temporary refresh
      if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
        await this.refreshPedigreeFlux(false);
      }
      else{
        await this.refreshPedigree();
      }

    } catch (error) {
      this.setState({ errorMessages: [error.message] });
    }
  }

  async handleDiseaseUpdate() {
    // Temporary refresh
    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      await this.refreshPedigreeFlux(false);
    }
    else{
      await this.refreshPedigree();
    }

    // ** Individual
    // this.pedigree.updatePersonHealth(this.state.selectedNode, diseases);

    // refresh the risk referral criteria for proband
    /* Run risk as a promise */
    let dispatcher = this.props.dispatch;
    risk_api.post_check_risk(this.props.patient.patient.id).then(risk_criteria_result => {
      dispatcher(patient_action_save_risk_criteria(risk_criteria_result.result));
    }).catch(error => helpers.logger(error));
  }

  async handleGeneTestUpdate() {
    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      await this.refreshPedigreeFlux(false);
    }
    else{
      await this.refreshPedigree();
    }
    // refresh the risk referral criteria for proband
    /* Run risk as a promise */
    let dispatcher = this.props.dispatch;
    risk_api.post_check_risk(this.props.patient.patient.id).then(risk_criteria_result => {
      dispatcher(patient_action_save_risk_criteria(risk_criteria_result.result));
    }).catch(error => helpers.logger(error));
  }

  closePopUpBlockerAlert() {
    this.setState({ openPopupBlockerAlert: ["none", false] });
  }

  closeCannotDeleteModal() {
    this.setState({ openCannotDeleteModal: ["none", false] })
  }

  createPrintHeader(doc, payload){
    let headerStart = 20

    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold', undefined);
    doc.text(headerStart, 13, 'Patient Name: ')


    doc.setFontSize(11)
    doc.setFont('helvetica', 'normal', undefined)
    doc.text(headerStart + 30 , 13, payload.probandFirstName + "," + payload.probandLastName);

    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold', undefined);
    doc.text(headerStart + 90, 13, 'Patient ID: ');

    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal', undefined);
    doc.text(headerStart + 112, 13, payload.probandId);


    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold', undefined);
    doc.text(headerStart + 140, 13, 'DOB: ');

    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal', undefined);
    doc.text(headerStart + 152, 13, payload.probandDob);

    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold', undefined);
    doc.text(headerStart + 175, 13, 'Age: ');

    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal', undefined);
    doc.text(headerStart + 185, 13, payload.probandAge);

  }

  async createPrintFooter(doc, displayLegendBool, displayPedigreeNotesBool){
    const EYE_IMAGE_SLASHED = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABCUlEQVR4nMWSwVGDUBCGP21AOpAOjBVIByYVSC7/GSuQVBDOe4ESKIF0EDuIHSQd5MCPMHBwogd3hpnH291v/9198FeLiCQi8t/m3wNroI6INiKSWwF3VlEAe+ALKIAVkAEvjvsEOqCSdFoADFkBLfDoq52TMOzD562kZtrCYCdXPvg/9VcYvPF9bcUjICIyV0slZa7+BtTAq32dWwHYO+dbQQk8AV1E5JJKV7zY/2B154nicg4YAksASa2ThqprIJkDpkMs6DcBs0FFROOWBttZ5QhwYE7fN4xbOEs62ldZ5bOk4wJgSEo/+Yx+LgAbSe1s1e+SqgXgJ/Nrbei3s70ZMAHlVvPPdgVK7V4Cn84qGgAAAABJRU5ErkJggg==";
    let x = 5
    let y = 178

    if(displayLegendBool || displayPedigreeNotesBool){
      if (displayLegendBool) {
        let eye_images = await document.getElementsByClassName('legend-dom-column-one')
        while(eye_images.length > 0){
          if(eye_images[0].firstChild.currentSrc == EYE_IMAGE_SLASHED){
            eye_images[0].parentNode.removeChild(eye_images[0].nextSibling)
          }
          eye_images[0].parentNode.removeChild(eye_images[0]);
        }
      }

    //Create Footer of pedigree Notes
      if(displayPedigreeNotesBool){
        let notes = document.getElementById(constants.PEDIGREE_NOTES_ELEMENT);
        let notes_container = document.getElementById(constants.PEDIGREE_NOTES_CONTAINER_ELEMENT);

        doc.setFontSize(8.5);
        doc.setFont('helvetica', 'bold', undefined);
        doc.text(200, 180, 'Pedigree Notes: ');

        doc.setFontSize(7.5);
        var splitText = doc.splitTextToSize(notes.value, 90);
        doc.setFont('helvetica', 'normal', undefined);
        doc.text(200, 185, splitText);

        if (notes) notes.remove()
        if (notes_container) notes_container.remove()
    }

      let legendColor =  document.getElementsByClassName('legend-dom-inner-row')
      let diseaseCounter = 0
      let maxLength = 0

      for(let i=0; i<legendColor.length; i++){
        if(diseaseCounter >= 6 && (diseaseCounter % 6 == 0)){
          x += 1.75 * maxLength
          y = 178
        }
        let diseaseName = legendColor[i].childNodes[1].innerText
        if(diseaseName.length >= 50) diseaseName = diseaseName.substring(0,50);

        let splitColors = legendColor[i].childNodes[0].firstChild.style.backgroundColor.match(/\d+/g)
        doc.setDrawColor(0);
        doc.setFontSize(7.5);
        doc.setFont('helvetica', 'bold', undefined);
        doc.text(x + 4, y + 2.5, diseaseName);
        doc.setFillColor(parseInt(splitColors[0]), parseInt(splitColors[1]), parseInt(splitColors[2]));
        doc.roundedRect(x, y, 3, 3, 1, 1, 'FD')
        maxLength =  (diseaseName.length >= 50) ? 50: (diseaseName.length > maxLength) ? diseaseName.length : maxLength

        y += 4
        diseaseCounter +=1

      }
    }
  }

  ////fit to page print
  async handlePrintClick(e) {
    this.setState({printing: true})

    await this.refreshPedigree()
    this.pedigree.prepareForPrint();
    this.prepareForExport();
    this.pedigree.clearAll();
    await helpers.delay(0.1);

    var canvas = document.getElementById("paper_canvas");

    let items = paper.project.getItems({
      type: 'layer'
    });
    let pedigreeWidth = items[0].bounds.width;
    let pedigreeHeight = items[0].bounds.height;

    var doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm'
    });

    //dynamically get width and height of page
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();


    let probandFirstName = this.props.patient.patient.first_name;
    let probandLastName = this.props.patient.patient.last_name;
    let probandId = this.props.patient.patient.patient_id;//this.props.patient.patient.id;
    let probandDob = this.props.patient.patient.dob;
    let probandAge = this.props.patient.patient.age;

    let payload = {
      probandFirstName,
      probandLastName,
      probandId,
      probandDob,
      probandAge
    }

    if (payload.probandFirstName == null || payload.probandFirstName == '') payload.probandFirstName = 'N/A'
    if (payload.probandLastName == null || payload.probandLastName == '') payload.probandLastName = 'N/A'
    if (payload.probandDob == null || payload.probandDob == '')  payload.probandDob = 'N/A'
    if(payload.probandId == null || payload.probandId == '') payload.probandId ='N/A'
    if (payload.probandAge == null || payload.probandAge == '') payload.probandAge = 'N/A'

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;

    if(payload.probandDob != 'N/A'){
      let birthday = new Date(payload.probandDob)
      var dd = String(birthday.getDate()).padStart(2, '0');
      var mm = String(birthday.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = birthday.getFullYear();
      payload.probandDob = mm + '/' + dd + '/' + yyyy;
    }
    //PNG Image
    var imageData = helpers.canvasToImage("paper_canvas", "#ffffff", "pdf", payload);

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const widthRatio = pageWidth / canvas.width;
    const heightRatio = pageHeight / canvas.height;
    const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

    const canvasWidth = canvas.width * ratio;
    const canvasHeight = canvas.height * ratio;

    const marginX = (pageWidth - canvasWidth) / 2;
    const marginY = (pageHeight - canvasHeight) / 2;

    //Rafa note: y position of some pedigree is too close to the top, need fix
    let displayLegend = Cookie.get('famgenix_pedigree_legend')
    let displayLegendBool = displayLegend == 'true'
    let displayPedigreeNotes = Cookie.get('famgenix_pedigree_legend_notes')
    let displayPedigreeNotesBool = displayPedigreeNotes == 'true'
    doc.addImage(imageData, 'PNG',  marginX,(displayLegendBool || displayPedigreeNotesBool) ? marginY - 15 : marginY, canvasWidth, canvasHeight, "a", "FAST");

    this.createPrintHeader(doc, payload)

    doc.text(width - 38, 13, today)

    doc.setProperties({ title: `Pedigree - ${probandFirstName} ${probandLastName}` });

    if(displayLegendBool || displayPedigreeNotesBool) this.createPrintFooter(doc, displayLegendBool, displayPedigreeNotesBool)

    var newWin = window.open('');
    if(!newWin || newWin.closed || typeof(newWin.closed) == 'undefined') {
        // POPUP BLOCKED
        this.setState({ openPopupBlockerAlert: ["print", true] });
    } else {
      newWin.close();
      if (this.isItIE()){
        saveAs(doc.output('blob'), "pedigree.pdf");
      } else {
        let n = await window.open('', '_blank');
        n.location.href = doc.output('bloburl')
      }
      this.setState({printing: false})
      await this.resizePedigree();
    }
  }

  //function for option on printing on multiple pages
  async handlePrintMultiplePagesClick(e) {
    this.prepareForExport();
    this.pedigree.clearAll();
    await helpers.delay(0.1);

    var canvas = document.getElementById("paper_canvas");

    let items = paper.project.getItems({
      type: 'layer'
    });
    let pedigreeWidth = items[0].bounds.width;
    let pedigreeHeight = items[0].bounds.height;

    var doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm'
    });

    let probandFirstName = this.props.patient.patient.first_name;
    let probandLastName = this.props.patient.patient.last_name;
    let probandId = this.props.patient.patient.patient_id;//this.props.patient.patient.id;
    let probandDob = this.props.patient.patient.dob;
    let probandAge = this.props.patient.patient.age;

    let payload = {
      probandFirstName,
      probandLastName,
      probandId,
      probandDob,
      probandAge
    }

    if (payload.probandFirstName == null) payload.probandFirstName = ''
    if (payload.probandLastName == null) payload.probandLastName = ''

    if (payload.probandDob == null) {
      payload.probandDob = ''
    }
    else {
      payload.probandDob = 'DOB: ' + payload.probandDob
    }

    if (payload.probandAge == null) {
      payload.probandAge = ''
    }
    else {
      payload.probandAge = 'Age: ' + payload.probandAge
    }

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;

    var imageData = null;

    let displayLegend = Cookie.get('famgenix_pedigree_legend')
    let displayLegendBool = displayLegend == 'true'
    let displayPedigreeNotes = Cookie.get('famgenix_pedigree_legend_notes')
    let displayPedigreeNotesBool = displayPedigreeNotes == 'true'


    //start
    // let imgWidth = canvas.width * imgHeight / canvas.height;
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();

    let widthRatio = pedigreeWidth * 0.4 / pedigreeWidth;
    let heightRatio = pedigreeHeight * 0.4 / pedigreeHeight;

    let ratio = heightRatio > widthRatio ? widthRatio : heightRatio;

    let imgHeight = pedigreeHeight > pedigreeWidth && pedigreeHeight - pedigreeWidth >= 500 ? pedigreeHeight * (ratio - 0.1) : pedigreeHeight * ratio;
    let imgWidth = pedigreeWidth > pedigreeHeight && pedigreeWidth - pedigreeHeight >= 500 ? pedigreeWidth * (ratio - 0.1) : pedigreeWidth * ratio;

    let positionX = 0;
    let positionXBeginning = positionX;
    let positionY = 0;
    let positionYBeginning = positionY;

    let widthLeft = imgWidth + positionXBeginning;
    let heightLeft = imgHeight + positionYBeginning;

    //PNG Image
    imageData = helpers.canvasToImage("paper_canvas", "#ffffff", "pdf", payload);
    doc.addImage(imageData, 'PNG', positionX, positionY);
    widthLeft -= pageWidth;
    doc.setFontSize(18);
    doc.setFont('helvetica', 'bold', undefined);
    doc.text(13, 13, payload.probandFirstName.toUpperCase() + " " + payload.probandLastName.toUpperCase());

    doc.setFontSize(15);
    doc.setFont('helvetica', 'normal', undefined);
    doc.text(13, 20, 'ID: ' + payload.probandId);

    if (payload.probandDob != '' && payload.probandAge != '') {
      doc.text(13, 27, payload.probandDob + " | " + payload.probandAge);
    }
    else if (payload.probandDob != '' && payload.probandAge == '') {
      doc.text(13, 27, payload.probandDob)
    }
    else if (payload.probandDob == '' && payload.probandAge != '') {
      doc.text(13, 27, payload.probandAge)
    }

    doc.text(pageWidth - 38, 13, today)

    const EYE_IMAGE_SLASHED = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABCUlEQVR4nMWSwVGDUBCGP21AOpAOjBVIByYVSC7/GSuQVBDOe4ESKIF0EDuIHSQd5MCPMHBwogd3hpnH291v/9198FeLiCQi8t/m3wNroI6INiKSWwF3VlEAe+ALKIAVkAEvjvsEOqCSdFoADFkBLfDoq52TMOzD562kZtrCYCdXPvg/9VcYvPF9bcUjICIyV0slZa7+BtTAq32dWwHYO+dbQQk8AV1E5JJKV7zY/2B154nicg4YAksASa2ThqprIJkDpkMs6DcBs0FFROOWBttZ5QhwYE7fN4xbOEs62ldZ5bOk4wJgSEo/+Yx+LgAbSe1s1e+SqgXgJ/Nrbei3s70ZMAHlVvPPdgVK7V4Cn84qGgAAAABJRU5ErkJggg==";

    if(displayLegendBool || displayPedigreeNotesBool){
      if (displayLegendBool) {
        let eye_images = await document.getElementsByClassName('legend-dom-column-one')
        while(eye_images.length > 0){
          if(eye_images[0].firstChild.currentSrc == EYE_IMAGE_SLASHED){
            eye_images[0].parentNode.removeChild(eye_images[0].nextSibling)
          }
          eye_images[0].parentNode.removeChild(eye_images[0]);
        }
      }

      let notes = document.getElementById(constants.PEDIGREE_NOTES_ELEMENT);
      let notes_container = document.getElementById(constants.PEDIGREE_NOTES_CONTAINER_ELEMENT);
      if (notes) notes.remove()
      if (notes_container) {
        const div = document.createElement('div');
        div.setAttribute('style', 'border: 1px solid #d4d4d4; border-radius: 4px; padding: 5px;');
        div.innerHTML = notes.value;
        notes_container.append(div);
      }

      let legendDOM = await document.getElementById(constants.LEGEND_DOM_CONTAINER_ELEMENT)
      let legendWidth = Number(legendDOM.offsetWidth) * ratio
      let legendHeight = Number(legendDOM.offsetHeight) * ratio

      await html2canvas(legendDOM, { scale: 2 }).then(async (legendDOM) => {
        doc.addImage(legendDOM.toDataURL('image/png'), 'PNG', 13, 35, legendWidth * 0.7, legendHeight * 0.7);
      });
    }

    let verticalCount = Math.ceil((imgHeight + positionYBeginning) / pageHeight)

    for (let i = 0; i < verticalCount; i++) {
      while (widthLeft >= 0) {
        positionX -= pageWidth
        doc.addPage();
        doc.addImage(imageData, 'PNG', positionX, positionY);
        widthLeft -= pageWidth;
      }
      widthLeft = imgWidth + positionXBeginning;
      positionX = pageWidth + positionXBeginning;
      heightLeft -= pageHeight;
      positionY -= pageHeight;
    }
    //end
    doc.setProperties({ title: `Pedigree - ${probandFirstName} ${probandLastName}` });

    var newWin = window.open('');
    if(!newWin || newWin.closed || typeof(newWin.closed) == 'undefined') {
        // POPUP BLOCKED
        this.setState({ openPopupBlockerAlert: ["print", true] });
    } else {
      newWin.close();
      if (this.isItIE()){
        saveAs(doc.output('blob'), "pedigree.pdf");
      } else {
        let n = await window.open('', '_blank');
        if (n) n.location.href = doc.output('bloburl')
      }

      await this.resizePedigree();
    }
  }


  // for future reference
  //   async handlePrintMultiplePagesClick2(e) {
  //     window.html2canvas = html2canvas
  //     this.prepareForExport();
  //     await helpers.delay(0.1);

  //     var canvas = document.getElementById("paper_canvas");

  //     var options = {
  //       pagesplit: true
  //  };

  //     html2canvas(canvas, options).then((canvas) => {
  //     var doc = new jsPDF({
  //       orientation: 'landscape',
  //       unit: 'mm'
  //     });



  //       doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0);
  //       doc.save("pedigree.pdf")
  //     });

  //     await this.resizePedigree();
  //   }

  prepareForExport() {
    // Increase size of paper and reduce zoom so that quality of image is better
    // *This results in larger file sizes for pdfs*
    let displayAncestry = Cookie.get('famgenix_pedigree_ancestry')
    let displayAncestryBool = displayAncestry == 'true'
    paper.view.zoom = 1;
    let offset = Cookie.get("famgenix_active_smartdraw") == 'flux' ? 37 : 55
    paper.view.viewSize.width = (this.pedigree.matrix_people.matrix.max_cols) * offset;
    let maternalLines = this.pedigree.matrix_people.getLinesForMaternal();
    let paternalLines = this.pedigree.matrix_people.getLinesForPaternal();
    let maxLines = Math.max(maternalLines, paternalLines)
    if (!displayAncestryBool) {
      paper.view.viewSize.height = (this.pedigree.matrix_people.matrix.max_rows - 2) * 71;
    }
    else {
      paper.view.viewSize.height = (this.pedigree.matrix_people.matrix.max_rows - 2) * (71 + (maxLines * 2));
      var items = paper.project.getItems({
        'constructor': paper.Path
      });
      if (maxLines >= 5) items[0].bounds.top += 40;
    }
    paper.view.scrollBy(paper.view.bounds.topLeft.multiply(-1))
    this.pedigree.moveToCenter()
    if(Cookie.get("famgenix_active_smartdraw") == 'flux' && this.pedigree.width > paper.view.viewSize.width) this.handleFitToScreenClick();
    if (this.pedigree.width >= 951) {
      this.pedigree.scrollHorizontalBy(40)
      this.pedigree.scrollVerticalBy(35);
    }
    this.pedigree.scrollbars.zoom(paper.view.zoom)
    this.pedigree.scrollbars.syncPos(paper.view.bounds.topLeft)
    paper.view.setCenter([paper.view.center.x, paper.view.center.y - 175])
  }

  async handleSaveImageClick(e) {
    this.prepareForExport();
    this.pedigree.clearAll();

    await helpers.delay(0.1);

    let probandFirstName = this.props.patient.patient.first_name;
    let probandLastName = this.props.patient.patient.last_name;
    let probandId = this.props.patient.patient.patient_id;//this.props.patient.patient.id;
    let probandDob = this.props.patient.patient.dob;
    let probandAge = this.props.patient.patient.age;

    let payload = {
      probandFirstName,
      probandLastName,
      probandId,
      probandDob,
      probandAge
    }

    if (payload.probandFirstName == null) payload.probandFirstName = ''
    if (payload.probandLastName == null) payload.probandLastName = ''

    if (payload.probandDob == null) {
      payload.probandDob = ''
    }
    else {
      payload.probandDob = 'DOB: ' + payload.probandDob
    }

    if (payload.probandAge == null) {
      payload.probandAge = ''
    }
    else {
      payload.probandAge = 'Age: ' + payload.probandAge
    }

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;


    var name = new paper.PointText()
    name.fillColor = 'black'
    name.style = { fontSize: 21, fontWeight: "bold" }
    name.content = payload.probandFirstName.toUpperCase() + " " + payload.probandLastName.toUpperCase()
    name.setPosition(130, -70)
    name.bounds.left = 55

    var id = new paper.PointText()
    id.fillColor = 'black'
    id.style = { fontSize: 18 }
    id.content = 'ID: ' + payload.probandId
    id.setPosition(130, -50)
    id.bounds.left = 55

    var dobAge = new paper.PointText()
    dobAge.fillColor = 'black'
    dobAge.style = { fontSize: 18 }
    dobAge.setPosition(130, -25)
    dobAge.bounds.left = 55

    if (payload.probandDob != '' && payload.probandAge != '') {
      dobAge.content = payload.probandDob + " | " + payload.probandAge
    }
    else if (payload.probandDob != '' && payload.probandAge == '') {
      dobAge.content = payload.probandDob
    }
    else if (payload.probandDob == '' && payload.probandAge != '') {
      dobAge.content = payload.probandAge
    }

    var dateToday = new paper.PointText()
    dateToday.fillColor = 'black'
    dateToday.style = { fontSize: 18 }
    dateToday.content = today
    dateToday.setPosition(130, -70)
    dateToday.bounds.right = paper.view.viewSize.width * 0.95

    // doc.setFontSize(15);
    // doc.setFontType("default");
    // doc.text(13, 20, 'ID: ' + payload.probandId);

    // if (payload.probandDob != '' && payload.probandAge != '') {
    //   doc.text(13, 27, payload.probandDob + " | " + payload.probandAge);
    // }
    // else if (payload.probandDob != '' && payload.probandAge == '') {
    //   doc.text(13, 27, payload.probandDob)
    // }
    // else if (payload.probandDob == '' && payload.probandAge != '') {
    //   doc.text(13, 27, payload.probandAge)
    // }

    // doc.text(width - 38, 13, today)

    await helpers.delay(0.1);

    //PNG Image
    var imageData = helpers.canvasToImage("paper_canvas", "#ffffff", "image", payload);
    const contentType = 'image/png';
    const b64Data = imageData.replace("data:image/png;base64,", "");

    const blob = helpers.b64toBlob(b64Data, contentType);

    var newWin = window.open('');
    if(!newWin || newWin.closed || typeof(newWin.closed) == 'undefined') {
        // POPUP BLOCKED
        this.setState({ openPopupBlockerAlert: ["saveimage", true] });
    } else {
      newWin.close();
      saveAs(blob, "pedigree.png");

      await window.removeEventListener("resize", this.resizePedigree);
      await this.resizePedigree();
    }
  }

  async handleToggleViewStatus(value) {
    this.pedigree.toggleViewStatus(value)
    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      await this.refreshPedigreeFlux(false);
    }
    else{
      await this.refreshPedigree();
    }
  }

  async handleToggleLegend(showLegend, showNotes) {
    this.pedigree.toggleLegend(showLegend, showNotes)
    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      await this.refreshPedigreeFlux(false);
    }
    else{
      await this.refreshPedigree();
    }
  }

  async handleToggleAncestry(showAncestry) {
    this.pedigree.toggleAncestry(showAncestry);
    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      await this.refreshPedigreeFlux(false);
    }
    else{
      await this.refreshPedigree();
    }
  }

  async handleToggleGeneticTesting() {
    if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
      await this.refreshPedigreeFlux(false);
    }
    else{
      await this.refreshPedigree();
    }
  }

  getNodeTypeText(node_type) {
    if (node_type == "proband") return "Proband";
    if (["father", "mother"].includes(node_type)) return `Proband's Parent`;
    if (node_type.includes("grand")) return `Proband's Grandparent`;
    return node_type;
  }

  validateDeleteOrFail(node_type) {
    if (
      [
        "proband_sibling",
        "son_daughter",
        "paternal_uncle_aunt",
        "maternal_uncle_aunt",
        "partner",
        "proband_parent_other_partner",
        "proband_half_sibling",
        "immediate_ancestor",
        "immediate_partner"
      ].includes(node_type) == false
    ) {
      let message = this.getNodeTypeText(node_type);
      throw new Error(`Deleting ${message} is not allowed.`);
    }
    return true;
  }

  async handleCheckDeleteSelectedPerson() {
    try {
      let person = this.state.selectedNode;
      if (person) {
        this.validateDeleteOrFail(person.node_type);

        let profile = person.data.profile;

        if(person.node_type === 'immediate_ancestor' || person.node_type === 'immediate_partner'){
          let partners = await family_api.get_member_memberid_partners(profile.id)
          let children = await family_api.get_members_memberid_children(profile.id, partners[0].id)
          if(children.length === 1){
            return true;
          }
          else if(children.length > 1){
            throw new Error('Cannot delete because this person has 2 or more children.')
          }
        }
        else{
          await family_api.check_delete_member_memberid(profile.id);
        }

        return true;
      }
    } catch (error) {
      if(error.message === 'Cannot delete because this person has 2 or more children.'){
        error.type = 'toplevel'
      }
      try {
        let person = this.state.selectedNode;
        if (person) {
          this.validateDeleteOrFail(person.node_type)
        }
      }
      catch (error) {
        this.setState({ errorMessages: [error.message] });
        return false;
      }
      this.setState({ openCannotDeleteModal: [error.type, true] });
      return false;
    }
  }

  async handleDeleteSelectedPerson() {
    try {
      let person = this.state.selectedNode;
      if (person) {
        this.validateDeleteOrFail(person.node_type);

        let profile = person.data.profile;
        let top_level_parents_deleted = false;

        let people = Object.values(this.props.patient.profile)

        //handle deleting of fake nodes
        if ((profile.is_no_children_node || profile.is_infertility_node) && profile.is_fake_node){
          let mother = people.find(p => p.id == profile.mother_id)
          let father = people.find(p => p.id == profile.father_id)
          // delete the fake child node, but not the partner that is not a fake node,
          // and is a record on the database
          if((mother.infertile || mother.no_children) && !mother.is_fake_node){
            // this.deleteFromRedux(profile);
            // make sure to delete all is_no_children_nodes and is_infertility_nodes that has that mother too
            let peopleToDelete = people.filter(person => (person.is_no_children_node || person.is_infertility_node) && person.mother_id == mother.id || person.father_id == mother.id)
            // for(let p of peopleToDelete){
            //   this.deleteFromRedux(p);
            // }
            await this.updateInfertileNoChildrenValue(mother, mother.infertile ? 'infertility' : 'no_children', false)
          }
          else if((father.infertile || father.no_children) && !father.is_fake_node){
            // this.deleteFromRedux(profile);
            // make sure to delete all is_no_children_nodes and is_infertility_nodes that has that father too
            let peopleToDelete = people.filter(person => (person.is_no_children_node || person.is_infertility_node) && person.father_id == father.id || person.mother_id == father.id)
            // for(let p of peopleToDelete){
            //   this.deleteFromRedux(p);
            // }
            await this.updateInfertileNoChildrenValue(father, father.infertile ? 'infertility' : 'no_children', false)
          }

          // when deleting a fake child node, also
          // remove the mother from the pedigree data store if mother is fake node
          if(mother.is_fake_node){
            // this.deleteFromRedux(mother);
          }

          // remove the father from the pedigree data store if father is fake node
          if(father.is_fake_node){
            // this.deleteFromRedux(father);
          }

          if (this.state.showSideBar) {
            this.handleSideBarClose();
          }

          this.setState({
            openModalConfirmDelete: false,
            errorMessages: []
          });

          return;
        }

        if(person.node_type === 'immediate_ancestor' || person.node_type === 'immediate_partner'){
          let partners = await family_api.get_member_memberid_partners(profile.id)
          let children = await family_api.get_members_memberid_children(profile.id, partners[0].id)
          if(children.length === 1){
            await family_api.delete_parents_top_level(children[0].id)
            top_level_parents_deleted = true;
            let partner_data = this.pedigree.matrix_people.people.persons.find(person => person.data.profile.id === partners[0].id)
            this.deleteTopLevelParentsFromRedux(person.data.profile.rkey, partner_data.data.profile.rkey, person.data.profile.id, partner_data.data.profile.id)
          }
          else if (children.length > 1){
            throw new Error('Cannot delete because this person has 2 or more children.')
          }
        }
        else{
          await family_api.delete_member_memberid(profile.id);
        }

        // Make sure to update the twin entry
        await this.updateTwinsEntry(profile);

        // Delete the profile from redux
        this.deleteFromRedux(person);

        if (this.state.showSideBar) {
          this.handleSideBarClose();
        }

        this.setState({
          openModalConfirmDelete: false,
          errorMessages: []
        })

        if(Cookie.get("famgenix_active_smartdraw") == 'flux'){
          await this.refreshPedigreeFlux(false);
        }
        else{
          if(top_level_parents_deleted){
            await this.refreshPedigreeFlux(true);
          }
          else{
            await this.refreshPedigree();
          }
        }

        // Delete only sigle node **
        // this.pedigree.deleteNode(person)

        // Delete entire chart **
        // this.pedigree.clearChart();
        // this.pedigree = null;
        // // await helpers.delay(.5)
        // this.initializePedigreeChart();

        // this.setState({
        //   errorMessages: [],
        //   selectedNode: null,
        //   selectedPerson: null,
        //   showSideBar: false,
        //   openModalConfirmDelete: false
        // });

      }
    } catch (error) {
      this.setState({ errorMessages: [error.message] });
      console.log(error.stack)
    }
  }

  deleteTopLevelParentsFromRedux(person_rkey, partner_rkey, person_id, partner_id){
    helper_family_tree.deleteTopLevelParents(this.props.dispatch, person_rkey, partner_rkey, person_id, partner_id)
    console.log(this.props.patient)
  }

  deleteFromRedux(person) {
    let profile = person.data ? person.data.profile : person

    if(person.node_type == undefined || person.node_type == null){

      let partnerRkey = helper_family_tree.getSonDaughterParentPartnerRkey(
        this.props.patient.sons_daughters,
        profile.rkey
      );
      helper_family_tree.deleteChildren(
        this.props.patient,
        this.props.dispatch,
        partnerRkey,
        profile.rkey
      );


      let ownerRkey = helper_family_tree.getPartnerOwnerRkey(
        this.props.patient.partners,
        profile.rkey
      );
      helper_family_tree.deletePartner(
        this.props.patient,
        this.props.dispatch,
        ownerRkey,
        profile
      );

      return
    }

    switch (person.node_type) {
      case "proband_sibling":
      case "proband_half_sibling":
        helper_family_tree.deleteSibling(
          this.props.dispatch,
          this.props.patient,
          profile
        );
        break;
      case "son_daughter":
        let partnerRkey = helper_family_tree.getSonDaughterParentPartnerRkey(
          this.props.patient.sons_daughters,
          profile.rkey
        );
        helper_family_tree.deleteChildren(
          this.props.patient,
          this.props.dispatch,
          partnerRkey,
          profile.rkey
        );
        break;
      case "paternal_uncle_aunt":
      case "maternal_uncle_aunt":
        let parent_side = person.node_type.replace("_uncle_aunt", "");
        helper_family_tree.deleteUncleAunt(
          this.props.patient,
          this.props.dispatch,
          parent_side,
          profile
        );
        break;
      case "partner":
      case "proband_parent_other_partner":
        let ownerRkey = helper_family_tree.getPartnerOwnerRkey(
          this.props.patient.partners,
          profile.rkey
        );
        helper_family_tree.deletePartner(
          this.props.patient,
          this.props.dispatch,
          ownerRkey,
          profile
        );
        console.log(ownerRkey);
    }
  }

  onClickOpenModalConfirmDelete() {
    this.setState({
      openModalConfirmDelete: true,
      modalConfirmDeleteHash: new Date().getTime()
    });
  }

  isItIE() {
    let user_agent = navigator.userAgent;
    let is_it_ie = user_agent.indexOf("MSIE ") > -1 || user_agent.indexOf("Trident/") > -1;
    return is_it_ie;
  }

  render() {
    let hasSpouses = false;
    if (this.state.selectedNode && this.props.patient.partners["apimem-" + this.state.selectedNode.data.profile.id]) {
      let node_partners = this.props.patient.partners["apimem-" + this.state.selectedNode.data.profile.id];
      if (node_partners.length > 1 || (node_partners[0] && !node_partners[0].is_blood_related_to_proband)) hasSpouses = true;
    }
    let showChart = { display: this.state.chartInProgress ? "none" : "block" };

    let deleteFailMessage = '';

    if(this.state.openCannotDeleteModal[0] === 'toplevel'){
      deleteFailMessage = 'Cannot delete because this person has 2 or more children.'
    }
    else if(this.state.openCannotDeleteModal[0] === 'children'){
      deleteFailMessage = "This family member cannot be deleted because they have children. Please delete the children first."
    }
    else if(this.state.openCannotDeleteModal[0] === 'non_blood_related_partner'){
      deleteFailMessage = "This family member cannot be deleted because they have partner(s). Please delete the partner(s) first."
    }

    return (
      <React.Fragment>
        <FamilyHistoryToolbar
          key={createUUID()}
          // fontFontSizeChangeTest={(font, fontSize) => this.pedigree.fontFontSizeChangeTest(font, fontSize)}
          alwaysShrink={this.state.alwaysShrink}
          zoomValue={this.state.zoomValue}
          onShrinkCheck={this.handleShrinkCheck}
          onZoomInClick={this.handleZoomInClick}
          onZoomOutClick={this.handleZoomOutClick}
          onFitToScreenClick={this.handleFitToScreenClick}
          onActualSizeClick={this.handleActualSizeClick}
          onPrintClick={this.handlePrintClick}
          onPrintMultiplePagesClick={this.handlePrintMultiplePagesClick}
          onSaveImageClick={this.handleSaveImageClick}
          onToggleViewClick={this.handleToggleViewStatus}
          onToggleLegend={this.handleToggleLegend}
          onToggleAncestry={this.handleToggleAncestry}
          onToggleGeneticTesting={() => this.handleToggleGeneticTesting()}
          onRefresh={() => this.refreshPedigreeFlux(true)}
          probandMemberId={this.props.patient.patient.id}
          readOnlyUser={this.props.read_only}
        />

        <ErrorSummary
          errorMessages={this.state.errorMessages}
          modal={true}
          onHide={() => this.setState({ errorMessages: [] })}
        />
        <ActivityIndicator loading={this.state.loading} pedigree={true} />

        <div className="patient-fam-content" style={showChart}>
          <div className="pedigree-content full-width">
            <div id="paper-wrapper" className="paper-wrapper">

              <FamilyHistorySidebar
                key={this.selectedPerson ? this.selectedPerson.data.profile.rkey : ""}
                person={this.state.selectedPerson}
                onInfoAndHistoryUpdate={data => this.handleInfoAndHistoryUpdate(data)}
                onDiseaseUpdate={() => this.handleDiseaseUpdate()}
                onGeneTestUpdate={() => this.handleGeneTestUpdate()}
                onCloseClick={this.handleSideBarClose}
                isFitToScreen={this.state.fitToScreen}
                showSideBar={this.state.showSideBar}
                patient={this.props.patient}
                dispatch={this.props.dispatch}
                readOnlyUser={this.props.read_only}
                memberid={this.props.memberid}
                onDelete={() => this.onClickOpenModalConfirmDelete()}
                onDeleteCheck={() => this.handleCheckDeleteSelectedPerson()}
              />

              <canvas id="paper_canvas" />

              <div className="vertical-wrapper">
                <canvas id="vertical_scrollbar" />
              </div>

              <div
                className="horizontal-wrapper"
                style={{
                  marginRight: settings.scrollbar_thickness + 1,
                  marginTop: settings.scrollbar_thickness * -1
                }}
              >
                <canvas id="horizontal_scrollbar" />
              </div>

              <img
                id="proband_arrow"
                className="hide-element"
                src={pointer_gray}
              />
              <img
                id="sub_menu_arrow"
                className="hide-element"
                src={icon_sub_menu_arrow}
              />
              {(this.props.read_only === false) &&(
                <>
              <img
                id="popup_menu_add_gray"
                className="hide-element"
                src={popup_menu_add_gray}
              />
              <img
                id="popup_menu_add_teal"
                className="hide-element"
                src={popup_menu_add_teal}
              />
              <img
                id="popup_menu_edit_gray"
                className="hide-element"
                src={popup_menu_edit_gray}
              />
              <img
                id="popup_menu_edit_teal"
                className="hide-element"
                src={popup_menu_edit_teal}
              />
              <img
                id="popup_menu_delete_gray"
                className="hide-element"
                src={popup_menu_delete_gray}
              />
              <img
                id="popup_menu_delete_teal"
                className="hide-element"
                src={popup_menu_delete_teal}
              />
              <img
                id="legend-eye"
                className="hide-element"
                src={legend_eye}
              />
              <img
                id="legend-eye-slash"
                className="hide-element"
                src={legend_eye_slash}
              />
              <img
                id="add-multiple"
                className="hide-element"
                src={add_multiple}
              />
                </>
              )}
              <div className="shaped-conditions-images hide-element">
                {this.getShapedConditionsImages().map((img_src, index) => (
                  <img key={index} id={`bw-${index + 1}`} src={img_src} />
                ))}
              </div>
            </div>
          </div>
        </div>

        {this.state.openModalConfirmDelete && (
          <ModalConfirmDelete
            loading={false}
            errorMessages={[]}
            isOpen={this.state.openModalConfirmDelete}
            onCancel={() =>
              this.setState({
                openModalConfirmDelete: false,
                errorMessages: []
              })
            }
            onOk={() => this.handleDeleteSelectedPerson()}
            person={(this.state.selectedNode) ? this.state.selectedNode.data.profile : null}
            hasSpouses={hasSpouses}
          />
        )}

        {this.state.openModalConfirmDeleteNoChildrenInfertilityNode && (
          <ModalConfirmDelete
            loading={false}
            errorMessages={[]}
            isOpen={this.state.openModalConfirmDeleteNoChildrenInfertilityNode}
            message={this.state.selectedNode.data.profile.is_no_children_node ? "Are you sure you want to remove No Children?" : "Are you sure you want to remove Infertility?"}
            onCancel={() =>
              this.setState({
                openModalConfirmDeleteNoChildrenInfertilityNode: false,
                errorMessages: []
              })
            }
            noChildrenInfertilityDelete={true}
            onOk={() => this.handleDeleteSelectedPerson()}
            person={(this.state.selectedNode) ? this.state.selectedNode.data.profile : null}
          />
        )}

        {this.state.openPopupBlockerAlert[1] && (
          <ModalPopUpBlockerAlert
            loading={false}
            errorMessages={[]}
            title={this.state.openPopupBlockerAlert[0] === "print" ? "Print" : "Save Image"}
            message={this.state.openPopupBlockerAlert[0] === "print" ? "Please allow popups to print the pedigree." : "Please allow popups to save the pedigree as image."}
            isOpen={this.state.openPopupBlockerAlert[1]}
            onOk={() => this.closePopUpBlockerAlert()}
            person={(this.state.selectedNode) ? this.state.selectedNode.data.profile : null}
          />
        )}

        {this.state.openCannotDeleteModal[1] && (
          <ModalPopUpBlockerAlert
            loading={false}
            errorMessages={[]}
            title="Delete Details"
            message={deleteFailMessage}
            isOpen={this.state.openCannotDeleteModal[1]}
            onOk={() => this.closeCannotDeleteModal()}
            person={(this.state.selectedNode) ? this.state.selectedNode.data.profile : null}
          />
        )}
      </React.Fragment>
    );
  }
}

export default FamilyHistoryContent;
