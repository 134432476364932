import { formatIdtoRkey } from "./helpers";

export const getMaxOverlapCount = (current_node, parent_node, parent_side='paternal') => {
  var max_count = 0;
  var count = 0;
  var parent_rkey = parent_node.rkey;
  var parent_pos = parent_node.pos;
  var current_pos = current_node.pos;
  var cond = parent_side === 'paternal' ? '>' : '<';
  var exp = current_pos.column + cond + parent_pos.column;

  if(eval(exp)) {
    count = parent_pos.column - current_pos.column;
    if(Math.abs(count) > Math.abs(max_count)) max_count = count;
  }

  let { children, partners } = current_node;
  if(children) {
    for(let child of children) {
      if(child.rkey === parent_rkey) continue;
      count = getMaxOverlapCount(child, parent_node, parent_side);
      if(Math.abs(count) > Math.abs(max_count)) max_count = count;
    }
  }

  if(partners) {
    for(let partner of partners) {
      count = getMaxOverlapCount(partner, parent_node, parent_side);
      if(Math.abs(count) > Math.abs(max_count)) max_count = count;
    }
  }

  return max_count;
}


export const sortNodesByLevel = (nodes) => {
  let sort_by_level = Object.values(nodes).sort((a,b) => {
    let ap = a.data.profile;
    let bp = b.data.profile;

    // Ascending
    if(ap.level > bp.level) return 1;
    if(ap.level < bp.level) return -1;
    return 0;
  });

  let sort_by_column = sort_by_level.sort((a,b) => {
    let ap = a.data.profile;
    let bp = b.data.profile;

    // Do not sort if not of the same level
    if(ap.level !== bp.level) return 0;

    // Ascending
    if(a.pos.column > b.pos.column) return 1;
    if(a.pos.column < b.pos.column) return -1;
  });

  return sort_by_column;
};

export const sortNodesByRow = (nodes) => {
  let sort_by_row = Object.values(nodes).sort((a,b) => {

    // Ascending
    if(a.pos.row > b.pos.row) return 1;
    if(a.pos.row < b.pos.row) return -1;
    return 0;
  });

  let sort_by_column = sort_by_row.sort((a,b) => {

    // Do not sort if not of the same row
    if(a.row !== b.row) return 0;

    // Ascending
    if(a.pos.column > b.pos.column) return 1;
    if(a.pos.column < b.pos.column) return -1;
  });

  return sort_by_column;
};

export const toggleNodeSide = (a, b) => {
  if(a.bounds.column_start < b.bounds.column_start) {
    return {side_a: a, side_b: b};
  }

  if(a.bounds.column_start > b.bounds.column_start) {
    return {side_a: b, side_b: a};
  }

  // Same bound
  return {side_a: a, side_b: b};
}

export const sortNodesByColumn = (nodes) => {
  let sort_by_column = nodes.sort((a,b) => {
    // Ascending
    if(a.pos.column > b.pos.column) return 1;
    if(a.pos.column < b.pos.column) return -1;
    return 0;
  });

  return sort_by_column;
}

export const columnOverlapNodes = (nodes) => {
  for(var left=0; left<nodes.length-1; left++) {

    var left_node = nodes[left];
    for(var right=left+1; right < nodes.length; right++) {
      var right_node = nodes[right];




      // Exit immediately if the end of level is met. Check overlaps only if the same level
      // TODO:
      // Check by row or by level. Test Case: 458
      // if(left_node.pos.row !== right_node.pos.row) break;
      if(left_node.data.profile.level !== right_node.data.profile.level) break;

      // Skip if partners. Do not check overlaps. They don't overlap
      let is_partner = isPartner(left_node, right_node);
      if(is_partner) continue;

      let {side_a, side_b} = toggleNodeSide(left_node, right_node);

      let overlap_found = side_a.pos.column >= side_b.pos.column;
      if(overlap_found) {

/*
        // This block is for review

        // Get right most
        if(side_a.partners) {
          let side_a_partners = sortNodesByColumn(side_a.partners);
          if(side_a_partners.length > 0) {
            var side_a_partner = side_a_partners[side_a_partners.length-1];
            if(side_a_partner.pos.column < side_a.pos.column ) {
              side_a = side_a_partner;
            }

          }
        }

        // Get left most
        if(side_b.partners) {
          let side_b_partners = sortNodesByColumn(side_b.partners);
          if(side_b_partners.length > 0){
            var side_b_partner = side_b_partners[0];
            if(side_b_partner.pos.column > side_b.pos.column)
            {
              side_b = side_b_partner;
            }
          }
        }
*/
        return { side_a, side_b};
      }

    }
  }

  return undefined;

}

export const getTreeRkeys = (rkey, nodes) => {
  let tree_rkeys = [];
  let profile = nodes[rkey].data.profile;

  let root_parents = getRootParent(profile, nodes);
  tree_rkeys = [...root_parents];
  for(let rkey of root_parents) {
    tree_rkeys = [
      ...tree_rkeys,
      ...getPartnersAndDescendantsRkey(rkey, nodes)
    ];
  }

  // Unique rkeys
  tree_rkeys = [...new Set(tree_rkeys)];

  return tree_rkeys;
}

const getRootParent = (profile, nodes) => {
  // TODO: Include decendant's partners' ancestor
  let parents = [];
  let {father_id, mother_id} = profile;
  if(father_id === undefined) {
    return [profile.rkey];
  }

  var father_rkey = formatIdtoRkey(father_id);
  var mother_rkey = formatIdtoRkey(mother_id);

  let father_side = getRootParent(nodes[father_rkey].data.profile, nodes);
  let mother_side = getRootParent(nodes[mother_rkey].data.profile, nodes);

  parents = [
    ...parents,
    ...father_side,
    ...mother_side
  ];

  return parents;
}

export const getPartnersAndDescendantsRkey = (rkey, nodes) => {
  let rkeys = [];
  let node = nodes[rkey];
  let { children, partners} = node;

  if(children) {
    for(let child of children) {
      rkeys = [
        ...rkeys,
        child.rkey,
        ...getPartnersAndDescendantsRkey(child.rkey, nodes)
      ];
    }
  }

  if(partners) {
    for(let partner of partners) {
      /*
      var partner_tree_rkeys = [];

      if(partner.node_type.includes('immediate')) {
        // This will track rkeys on another root tree
        partner_tree_rkeys = getTreeRkeys(partner.rkey, nodes);
        // console.log('partner_tree_rkeys',partner_tree_rkeys);
      } else {
        partner_tree_rkeys = getPartnersAndDescendantsRkey(partner.rkey, nodes);
      }

      partner_tree_rkeys = getPartnersAndDescendantsRkey(partner.rkey, nodes);

      rkeys = [
        ...rkeys,
        partner.rkey,
        ...partner_tree_rkeys
      ];
      */

      var partner_tree_rkeys = getPartnersAndDescendantsRkey(partner.rkey, nodes);
      rkeys = [
        ...rkeys,
        partner.rkey,
        ...partner_tree_rkeys
      ];
    }
  }

  return rkeys;
}

export const getIntersectingDescendants = (side_a_rkeys, side_b_rkeys, nodes) => {
  // Find intersection via side a
  for(let a_rkey of side_a_rkeys) {
    let a = nodes[a_rkey];
    if(a.partners === undefined) continue;
    for(var a_partner of a.partners) {
      var b_rkey = side_b_rkeys.find( rkey => rkey === a_partner.rkey);
      if(b_rkey) {
        return {a_rkey, b_rkey};
      }
    }
  }

  // Find intersection via side b
  for(let b_rkey of side_b_rkeys) {
    let b = nodes[b_rkey];
    if(b.partners === undefined) continue;
    for(var b_partner of b.partners) {
      var a_rkey = side_a_rkeys.find(rkey => rkey === b_partner.rkey);
      if(a_rkey) {
        return {a_rkey, b_rkey};
      }
    }
  }

  return undefined;
}

export const getNodeWithRowOverlap = (nodes, processed_node_rkeys) => {
  var nodes_group_by_row = nodesGroupByRow(nodes);
  var node_boundaries = buildNodeBoundaries(nodes);
  var overlaps = undefined;
  var nodes_by_rkey = {};

  for(var node of nodes) nodes_by_rkey[node.rkey]=node;

  for(var node of nodes) {
    if(processed_node_rkeys.includes(node.rkey)) continue;

    var row_nodes = nodes_group_by_row[node.pos.row.toString()];
    overlaps = hasRowOverlap(node, node_boundaries, row_nodes, nodes_by_rkey);



    if(overlaps !== undefined) {
      return overlaps;
    }
  }

  return undefined;
}

const nodesGroupByRow = (nodes) => {
  let group = {};
  for(var node of nodes) {
    var { row } = node.pos;
    var key = row.toString();
    var current_group = group[key] || [];

    current_group.push(node);
    group[key] = current_group;
  }
  return group;
}

const groupNodesByLevel = (nodes) => {
  let group = {};
  for(var node of nodes) {
    var current_group = group[node.data.profile.level] || [];
    current_group.push(node);
    group[node.data.profile.level] = current_group;
  }
  return group;
}

const hasRowOverlap = (node, node_boundaries, row_nodes, nodes_by_rkey) => {
  // let level_to_check = node.data.profile.level;
  // let node_boundary = node_boundaries[level_to_check];
  // let level_to_check = node.data.profile.level;
  let node_boundary = node_boundaries[node.pos.row.toString()];
  var result = undefined;

  result = isBetweenPartnersOrSiblings(node, node_boundary);
  if(result !== undefined) return result;

  result = isBetweenChildAndParent(node, row_nodes, nodes_by_rkey);
  if(result !== undefined) return result;

  return undefined;
}

const isSiblingFullHalfInlaw = (node, siblings_from_other_family) => {
  var sibling_rkeys = siblings_from_other_family.map(member => member.rkey);
  if(sibling_rkeys.includes(node.rkey)) return true;

  var sibling_partner_rkeys = [];
  for(var member of siblings_from_other_family) {
    if(member.partners) {
      var tmp_rkeys = member.partners.map(partner =>  partner.rkey);
      sibling_partner_rkeys=[...sibling_partner_rkeys,...tmp_rkeys];
    }
  }

  if(sibling_partner_rkeys.includes(node.rkey)) return true;

  var node_partner_rkeys = [];
  if(node.partners) node_partner_rkeys = node.partners.map(partner => partner.rkey);

  for(var rkey of sibling_partner_rkeys) {
    if(node_partner_rkeys.includes(rkey)) return true;
  }

  return false;

}

const isBetweenPartnersOrSiblings = (node, node_boundary) => {

  let {rkey} = node.data.profile;
  let {column} = node.pos;
  let { partners, siblings} = node_boundary;

  // Check if inbetween of other partners group
  for(var item of partners) {
    var { boundary, member_rkeys, members} = item;
    if(!member_rkeys.includes(rkey) && column >= boundary.left && column <= boundary.right) {
      var overlaps_with = members[0]; // Get one of the member
      return {node, overlaps_with};
    }
  }

  // Check if in between of other siblings group
  for(var item of siblings) {
    var { boundary, member_rkeys, members} = item;

    if(!member_rkeys.includes(rkey) && column >= boundary.left && column <= boundary.right) {

      if(isSiblingFullHalfInlaw(node, members)) continue;
      var overlaps_with = members[0]; // Get one of the member
      return {node, overlaps_with};
    }
  }

  return undefined;
}

const isPartner = (node, peer) => {
  var node_partner_rkeys = (node.partners || []).map(partner => partner.rkey);
  var peer_partner_rkeys = (peer.partners || []).map(partner => partner.rkey);
  if(node_partner_rkeys.includes(peer.rkey)) return true;
  if(peer_partner_rkeys.includes(node.rkey))  return true;
  return false;
}

const isBetweenChildAndParent = (node, row_nodes, nodes_by_rkey) => {
  // var node_partner_rkeys = (node.partners || []).map(partner => partner.rkey);
  // Exclude self and its partners
  var peer_nodes = row_nodes.filter(peer => {
    var is_self = peer.rkey === node.rkey;
    // var is_partner = node_partner_rkeys.includes(item.rkey);
    var is_partner = isPartner(node, peer);
    return !is_self && !is_partner;
  });

  let me_columns = getParentColumns(node, nodes_by_rkey);
  me_columns = {...me_columns, column: node.pos.column};

  for(var peer of peer_nodes) {
    var {mother_id, father_id} = peer.data.profile;

    // Skip node without parent: root node
    if(mother_id === undefined) continue;

    // Exclude sibling
    if(mother_id === node.data.profile.mother_id) continue;

    let peer_columns = getParentColumns(peer, nodes_by_rkey);
    peer_columns = {...peer_columns, column: peer.pos.column};

    let me_intersect_peer = (
      inBetween(me_columns.column, peer_columns.column, peer_columns.father_column)
      || inBetween(me_columns.column, peer_columns.column, peer_columns.mother_column)
    );

    let peer_intersect_me = (
      inBetween(peer_columns.column, me_columns.column, me_columns.father_column)
      || inBetween(peer_columns.column, me_columns.column, me_columns.mother_column)
    );

    if(me_intersect_peer || peer_intersect_me) {
      // If both intersects, Do not moved immediate nodes
      // Immediate partner, immediate son daughter.
      // Immediate nodes are older nodes. Newer nodes should be moved instead

      if(node.node_type.includes('immediate')) continue;

      var peer_siblings = getSiblings(peer, nodes_by_rkey);
      var is_sibling_in_law = isSiblingFullHalfInlaw(node, peer_siblings);
      if(is_sibling_in_law) continue;

      return {node, overlaps_with: peer}
    }

  }

  return undefined;
}

const getSiblings = (node, nodes) => {
  let { father_id, mother_id } = node.data.profile;
  let siblings = [];
  for(var sibling of Object.values(nodes)) {
    if(sibling.rkey === node.rkey) continue;

    let sibling_father_id = sibling.data.profile.father_id;
    let sibling_by_father = sibling_father_id !== undefined && sibling_father_id === father_id;

    let sibling_mother_id = sibling.data.profile.mother_id;
    let sibling_by_mother = sibling_mother_id !== undefined && sibling_mother_id === mother_id;

    if(sibling_by_father || sibling_by_mother) {
      siblings.push(sibling);
    }
  }

  return siblings;
}

const getParentColumns = (node, nodes_by_rkey) => {
  let columns = {
    father_column: undefined,
    mother_column: undefined,
  }

  var {mother_id, father_id} = node.data.profile;
  if(father_id === undefined) return columns;

  let father = nodes_by_rkey[formatIdtoRkey(father_id)];
  let mother = nodes_by_rkey[formatIdtoRkey(mother_id)];

  columns.father_column = father.pos.column;
  columns.mother_column = mother.pos.column;
  return columns;
}

const inBetween = (target, from, to) => {
  if(from === undefined || to ===  undefined) return false;

  let [start, end] = from <= to ? [from, to] : [to, from];
  return target >= start && target <= end;
}

/*
build levels that groups node by partners and siblings
level[key] = {
  partners: [],
  siblings: []
}
*/
export const buildNodeBoundaries = (nodes) => {

  // Arrange nodes by row
  let group_by_row = {};
  for(let node of nodes) {
    let key = node.pos.row.toString();
    var current = group_by_row[key] || [];
    current.push(node);
    group_by_row[key]=current;
  }

  // Segrate nodes by partners, siblings
  var node_boundaries = {};
  for(let key of Object.keys(group_by_row)) {

    var partners = groupByPartners(group_by_row[key]);
    var siblings = groupBySiblings(group_by_row[key]);
    node_boundaries[key] = {
      row: parseInt(key),
      partners,
      siblings
    };

  }

  return node_boundaries;
}

const groupByPartners = (nodes) => {
  var partners = [];
  var partners_rkey = [];
  for(let node of nodes) {
    if(node.partners && node.partners.length > 0) {
      if(partners_rkey.includes(node.rkey)) continue;
      partners_rkey=[...partners_rkey, node.rkey, ...node.partners.map(partner => partner.rkey)];

      var members = [ node, ...node.partners ];
      var boundary = columnBoundary(members);
      var member_rkeys =  members.map(member => member.rkey);
      partners.push({ boundary, member_rkeys, members });

    }
  }
  return partners;
}


const groupBySiblings = (nodes) => {
  var children = {};

  for(let node of nodes) {
    let { father_id, mother_id } = node.data.profile;
    if(father_id === undefined) continue;

    var key = `${father_id}_${mother_id}`;
    var current_children =  children[key] || [];
    current_children.push(node);
    children[key]=current_children;
  }

  children = Object.values(children);
  children = children.filter(nodes => nodes.length > 1); // Include only children with two or more nodes

  var siblings = [];
  for(var members of children) {
    var member_rkeys =  members.map(member => member.rkey);
    var boundary = columnBoundary(members);
    siblings.push({ boundary, member_rkeys, members });
  }

  return siblings;
}

const columnBoundary = (nodes) => {
  if(nodes.length == 0) return undefined;
  let left = nodes[0].pos.column;
  let right = nodes[0].pos.column;
  for(let x=1; x<nodes.length;x++) {
    var {column} = nodes[x].pos;
    if(column < left) left = column;
    if(column > right) right = column;
  }

  return {left, right};
}

/*
| - - - - - - - - - - - - - - -
| TEMPORARY - OLD CODE, Will be removed soon
| - - - - - - - - - - - - - - -
*/


export const arePartners = (a,b, partners) => {
  let a_rkey = a.data.profile.rkey;
  let b_rkey = b.data.profile.rkey;

  if(Object.keys(partners).includes(a_rkey)) {
    return partners[a_rkey].find(partner => partner.rkey === b_rkey) !== undefined;
  }

  if(Object.keys(partners).includes(b_rkey)) {
    return partners[b_rkey].find(partner => partner.rkey === a_rkey) !== undefined;
  }

  return false;
}

export const overlappingNodes = (nodes, partners) => {
  for(var left=0; left<nodes.length-1; left++) {

    var left_node = nodes[left];
    for(var right=left+1; right < nodes.length; right++) {
      var right_node = nodes[right];

      // Exit immediately if the end of level is met. Check overlaps only if the same level
      if(left_node.data.profile.level !== right_node.data.profile.level) break;

      // Skip if partners. Do not check overlaps. They don't overlap
      let are_partners = arePartners(left_node, right_node, partners);
      if(are_partners) continue;

      let {side_a, side_b} = toggleNodeSide(left_node, right_node);

      let overlap_found = side_a.pos.column >= side_b.pos.column;
      if(overlap_found) {
        return { side_a, side_b};
      }

    }
  }

  return undefined;

}


export const getTreeRkeys_OLD = (rkey, nodes) => {
  let tree_rkeys = [];
  let profile = nodes[rkey].data.profile;

  let root_parents = getRootParent(profile, nodes);
  tree_rkeys = [...root_parents];
  for(let rkey of root_parents) {
    tree_rkeys = [
      ...tree_rkeys,
      ...getPartnersAndDescendantsRkey_OLD(rkey, nodes)
    ];
  }

  // Unique rkeys
  tree_rkeys = [...new Set(tree_rkeys)];

  return tree_rkeys;
}


const getPartnersAndDescendantsRkey_OLD = (rkey, nodes) => {
  let rkeys = [];
  let node = nodes[rkey];
  let { children, partners} = node;

  if(children) {
    for(let child of children) {
      rkeys = [
        ...rkeys,
        child.rkey,
        ...getPartnersAndDescendantsRkey_OLD(child.rkey, nodes)
      ];
    }
  }

  if(partners) {
    for(let partner of partners) {
      rkeys = [
        ...rkeys,
        partner.rkey,
        ...getPartnersAndDescendantsRkey_OLD(partner.rkey, nodes
      )];
    }
  }

  return rkeys;
}



/* END TEMPORARY  - - - - - - */