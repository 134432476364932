import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { forIn, compact, cloneDeep } from 'lodash';
import { radix_bases } from '../../../helpers/helper-number-bases';
import ErrorSummary from '../../error-summary';
import { ageStringToAgeFloat } from '../../../helpers/age-utils'

class ModalRiskMissingData extends Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMessages: []
    };

    this.onClickSave = this.onClickSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.oneAgeChange = this.oneAgeChange.bind(this)
  }

  validateEnteredData() {
    let cloned_state = cloneDeep(this.state)
    delete cloned_state.errorMessages
    let allDiseases = cloneDeep(this.props.pedigreeData.diseases)
    for (var key in cloned_state) {
      if (cloned_state[key]['age'] !== null && cloned_state[key]['age'] !== undefined) {
        let person_diseases = allDiseases[key]
        let person_age = parseInt(cloned_state[key]['age'], radix_bases.base10)
        if(person_diseases !== null && person_diseases !== undefined){
          for (let disease of person_diseases) {
            let age_diagnosed = ageStringToAgeFloat(disease.age_diagnosed)// parseInt(disease.age_diagnosed, radix_bases.base10)
            if (age_diagnosed > person_age) {
              throw new Error("Ages entered should be greater than or equal to person's diseases' diagnosed ages.")
            }
          }
        }
      }
    }
  }

  async onClickSave(event) {
    try{
      //check first for the error scenarios
      this.validateEnteredData()
      let cloned_state = cloneDeep(this.state)
      delete cloned_state.errorMessages
      this.props.onClickSave(cloned_state);
      this.props.onClose();
    }
    catch(error){
      this.setState({ errorMessages: [error.message] })
    }
  }
  oneAgeChange(event){
    let clone = cloneDeep(this.state);
    let rkey = event.currentTarget.getAttribute("data-rkey");
    let value = event.currentTarget.value;
    let name = event.currentTarget.name;
    
    if(name === 'age_diagnosed') {
      let disease_id = event.currentTarget.getAttribute("data-disease-id");
      if(clone[rkey] !== null && clone[rkey] !== undefined) {
        if(clone[rkey]['diseases'] !== null && clone[rkey]['diseases'] !== undefined) {
          let diseases = clone[rkey]['diseases'];
          diseases[disease_id] = value;
          clone[rkey]['diseases'] = diseases;
        } else {
          clone[rkey].diseases = {
            [disease_id]: value
          };
        }
      } else {
        clone[rkey] = {
          diseases: {
            [disease_id]: value
          }
        };
      }
    }else{
      if(clone[rkey] !== null && clone[rkey] !== undefined) {
        clone[rkey][name] = value;
      } else {
        clone[rkey] = {
          [name]: value
        };
      }
    }
    this.setState({ ...clone });
  }

  onChange(event) {
    let clone = cloneDeep(this.state);

    let name = event.currentTarget.name;
    let rkey = event.currentTarget.getAttribute("data-rkey");
    let value = event.currentTarget.value;

   if(name === 'dob') {
      if(clone[rkey] !== null && clone[rkey] !== undefined) {
        clone[rkey]['dob'] = value;
      } else {
        clone[rkey] = {
          dob: value
        };
      }
    } else {
      // yob, ageFirstBirth, ageFirstMenstruation
      if(clone[rkey] !== null && clone[rkey] !== undefined) {
        clone[rkey][name] = parseInt(value, radix_bases.base10);
      } else {
        clone[rkey] = {
          [name]: parseInt(value, radix_bases.base10)
        };
      }
    }

    this.setState({ ...clone });
  }

  render() {
    let onChange = this.onChange;
    let oneAgeChange = this.oneAgeChange

    let requiredDataTableRows = this.props.requiredData.map(function(result, index) {
      let field_name = result.dataField.fieldName;
      if(field_name === 'dob') {
        return (<tr key={index.toString()} role="row">
                  <td>{result.model}</td>
                  <td>{result.relationshipToProband}</td>
                  <td>{result.name}</td>
                  <td>{result.dataField.fieldLabel}</td>
                  <td><input name="dob" onChange={onChange} data-rkey={result.rkey} style={{ lineHeight: 1.42857143 }} type="date" className="form-control" placeholder="MM/DD/YYYY" /></td>
                </tr>);
      } else if(field_name === 'yob') {
        return (<tr key={index.toString()} role="row">
                  <td>{result.model}</td>
                  <td>{result.relationshipToProband}</td>
                  <td>{result.name}</td>
                  <td>{result.dataField.fieldLabel}</td>
                  <td><input name="yob" onChange={onChange} data-rkey={result.rkey} type="text" className="form-control normal-input-text" /></td>
                </tr>);
      } else if(field_name === 'age') {
        return (<tr key={index.toString()} role="row">
                  <td>{result.model}</td>
                  <td>{result.relationshipToProband}</td>
                  <td>{result.name}</td>
                  <td>{result.dataField.fieldLabel}</td>
                  <td><input name="age" onChange={oneAgeChange} data-rkey={result.rkey} type="text" className="form-control normal-input-text" /></td>
                </tr>);
      } else if(field_name === 'age_diagnosed') {
        return (<tr key={index.toString()} role="row">
                  <td>{result.model}</td>
                  <td>{result.relationshipToProband}</td>
                  <td>{result.name}</td>
                  <td>{result.dataField.fieldPayload.disease_name} - {result.dataField.fieldLabel}</td>
                  <td><input name="age_diagnosed" onChange={oneAgeChange} data-rkey={result.rkey} data-disease-id={result.dataField.fieldPayload.id} type="text" className="form-control normal-input-text" /></td>
                </tr>);
      } else if(field_name === 'ageFirstBirth') {
        return (<tr key={index.toString()} role="row">
                  <td>{result.model}</td>
                  <td>{result.relationshipToProband}</td>
                  <td>{result.name}</td>
                  <td>{result.dataField.fieldLabel}</td>
                  <td><input name="ageFirstBirth" onChange={onChange} data-rkey={result.rkey} type="text" className="form-control normal-input-text" /></td>
                </tr>);
      } else if(field_name === 'ageFirstMenstruation') {
        return (<tr key={index.toString()} role="row">
                  <td>{result.model}</td>
                  <td>{result.relationshipToProband}</td>
                  <td>{result.name}</td>
                  <td>{result.dataField.fieldLabel}</td>
                  <td><input name="ageFirstMenstruation" onChange={onChange} data-rkey={result.rkey} type="text" className="form-control normal-input-text" /></td>
                </tr>);
      }
      return null;
    });
    compact(requiredDataTableRows);

    let terminationTableRows = [];
    forIn(this.props.fatalTerminations, function(risk_model, key) {
      for(let i=0; i<risk_model.terminations.length; i++) {
        terminationTableRows.push(
          <tr key={key.concat('-', i.toString())} role="row">
            <td>{risk_model.terminations[i].model}</td>
            <td></td>
            <td></td>
            <td>{risk_model.terminations[i].fieldLabel}</td>
            <td>{risk_model.terminations[i].message}</td>
          </tr>
        );
      }
    });

    return ReactDOM.createPortal(
      <div onClick={() => this.props.onClose()}
        style={{ display: 'block' }}
        className="modal fade in"
        role="dialog"
        aria-labelledby="risk-missingdata"
        id="risk-missingdata">

        <div onClick={(e) => e.stopPropagation()} className="modal-dialog-wide" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <button onClick={() => this.props.onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Risk Models - Missing Data</h4>
            </div>

            <div className="modal-body">
              <div className="list-wrapper">
                <ErrorSummary errorMessages={this.state.errorMessages} />
                <p>Required data is needed to run the following risk models: <br/></p>
                <form className="form-horizontal form-label-left form-custom">
                  <table className="display table-custom responsive no-wrap dataTable">
                    <thead>
                      <tr>
                        <th>Model</th>
                        <th>Relationship</th>
                        <th>First Name</th>
                        <th>Missing Data</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {requiredDataTableRows}
                      {terminationTableRows}
                    </tbody>

                    <tfoot className="hidden">
                      <tr>
                        <th>Model</th>
                        <th>Relationship</th>
                        <th>First Name</th>
                        <th>Missing Data</th>
                        <th></th>
                      </tr>
                    </tfoot>
                  </table>
                </form>
              </div>
            </div>

            <div className="modal-footer">
              <button
                onClick={() => this.props.onClose()}
                type="button" className="btn btn-light-outline no-margin-right" data-dismiss="modal">Cancel</button>
              <button
                onClick={this.onClickSave}
                type="button" className="btn btn-dark">Save</button>
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }

}

export default ModalRiskMissingData;
