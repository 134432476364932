import React, { Component } from 'react';
import { connect } from "react-redux";
import InputAndOr from './input-andor';
import * as static_list_actions from "../../store/static_list/actions";
import genetic_testing_api from "../../api/genetic-testing-api";
import genes_json from '../../assets/json/gene-items.json'
import Dropdown from 'react-dropdown'
import geneResults from '../../assets/json/gene-result.json'
import ControlSelectGene from '../control-select-gene';
let gene_options = [
  {value: 'note-tested', label: 'Not Tested'},
  {value: 'tested', label: 'Tested for any gene'},
  ...genes_json
]

let result_any_gene = [
  {value: 'any', label: 'Any result'},
  {value: 'p', label: 'Pathogenic for any'},
  {value: 'lp', label: 'Likely pathogenic any'},
  {value: 'vus', label: 'VUS for any'},
  {value: 'ln', label: 'Likely benign for any'},
  {value: 'n_ln', label: 'Negative or Likely benign for all'},
  {value: 'n', label: 'Negative for all'},
]

let result_specific_gene = [
  {value: 'any', label: 'Any result'},
  {value: 'p', label: 'Pathogenic'},
  {value: 'lp', label: 'Likely Pathogenic'},
  {value: 'vus', label: 'VUS'},
  {value: 'ln', label: 'Likely benign'},
  {value: 'n', label: 'Negative'},
]

class InputGene extends Component {
  constructor(props) {
    super(props)
    this.state = {
      genetic_testing_options: [],
      gene_id: null,
      gene_name: " ",
      Variant: null
    };
    this.handleChange = this.handleChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    // this.loadGenesFromAPI = this.loadGenesFromAPI.bind(this);
    this.buildGeneDropdown = this.buildGeneDropdown.bind(this);
    this.isGeneUMLS = this.isGeneUMLS.bind(this);
    this.handleVariantChange = this.handleVariantChange.bind(this)
    this.timeout = null;
  }
  async componentDidMount() {
    await this.buildGeneDropdown();
  }

  handleVariantChange(e){
    this.setState({Variant: e.target.value})
    clearTimeout(this.timeout);
    this.timeout = setTimeout(()=> {
      let item = Object.assign({}, this.props.item, {[e.target.name]: e.target.value});
      this.props.onChange(item)

    }, 1000);
  }

  handleChange(e) {
    let item = Object.assign({}, this.props.item, {[e.target.name]: e.target.value})
    this.props.onChange(item)
  }
  
  handleSelectChange(gene, name) {
    let gene_value = (gene !== null) ? gene.value : null;
    let gene_name = (gene !== null) ? gene.label : null;
    let item = Object.assign({}, this.props.item, {[name]: gene_value});

    this.props.onChange(item)
  }

  isGeneUMLS(disease_id) {
    disease_id = disease_id + "";
    const is_from_umls = disease_id.substr(0,1) === "C";
    return is_from_umls
  }
  // async loadGenesFromAPI() {
  //   try {
  //     let genes = this.props.static_list.genetic_testings;
  //     if (genes.length === 0) {
  //       let data = await genetic_testing_api.get_genetic_testing()
  //       genes = data.sort((a, b) => {
  //         if (a.gene < b.gene) return -1;
  //         if (a.gene > b.gene) return 1;
  //         return 0;
  //       });
  //       await this.props.dispatch(static_list_actions.initialize_genetic_testings(genes));
  //     }
  //     this.buildGeneDropdown()
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  getResults(gene) {
    if(gene !== null){
      gene = gene.value
    }
    if (gene === '') return []

    if(gene === 'not-tested') return []

    if(gene === 'Any') return result_any_gene

    return result_specific_gene
  }

  async buildGeneDropdown(){
    let genes = this.props.static_list.genetic_testings
    let proband_genes = this.props.static_list.genetic_testings_umls
    //Still supporting genes from standard list so need to check for gene_id as well and sort after
    let gene_list =[]
    if(proband_genes){
    for(let gene of proband_genes){
      if(gene.gene_id){
        let standardGeneName = genes.find(genes=>genes.id == gene.gene_id)
        gene_list.push(
          { 'value': gene.gene_id, 'label': `${standardGeneName.gene}` }
        )}
        else if(gene.umls_id){
        gene_list.push(
          { 'value': gene.umls_id, 'label': `${gene.umls_name}` }
        ) 
      }
    }

    let uniqueGenes = Array.from(new Set(gene_list.map(a=>a.label))).map(name=>{
      return gene_list.find(a => a.label === name )
    })

    //sort all genes alphabetically
    uniqueGenes = uniqueGenes.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

    if(uniqueGenes.length > 0){
      this.setState({ genetic_testing_options: uniqueGenes })
    }
    }
  }
  render() {
    let gene = null 
    if(this.props.item.gene){
      const gene_id = parseInt(this.props.item.gene, 10)
      let static_selected_gene = this.props.static_list.genetic_testings.find(
        item => item.id === gene_id
      );

    if(static_selected_gene){
      gene = {value: static_selected_gene.id, label: static_selected_gene.gene }
      }
      else if(this.isGeneUMLS(this.props.item.gene)){
        let umls_id = this.props.item.gene
        let static_selected_gene = this.props.static_list.genetic_testings_umls.find(
            item => item.umls_id === umls_id
          );
        if(static_selected_gene){
          gene = {value: static_selected_gene.umls_id, label: static_selected_gene.umls_name};
        }
      }
      else if(this.props.item.gene === 'Any'){
        gene = {value: 'Any', label: 'Tested for any gene'}
      }
      else if(this.props.item.gene === 'not-tested'){
        gene = {value: 'not-tested', label: 'Not Tested'}
      }
      else if(this.props.item.gene === 'unsure'){
        gene = {value: 'unsure', label: 'Unsure'}
      }
    }

    let chosen_result =  'Result'
    let resultOptions = this.getResults(gene)
    for(let result of resultOptions) {
      if(this.props.item.gene !== "" && this.props.item.result && this.props.item.result === result.value){
        chosen_result = result
      }
    }
    let variant_name = this.state.Variant !== null ? this.state.Variant : this.props.item.Variant

    return (
      <React.Fragment >

                  { this.props.itemIndex > 0 &&
                    <InputAndOr
                      value={this.props.item.andOr}
                      onChange={(e) => this.handleChange(e)} />
                  }

                <div className="col-md-4 col-xs-12 no-padding-left">
                  <ControlSelectGene
                    inputProps={{id: `gene-name-dropdown-${this.props.setIndex}-${this.props.itemIndex}`}}
                    options={
                      [{value: 'Any', label:'Tested for any gene'},
                      {value:'not-tested', label:'Not Tested'},
                        ...this.state.genetic_testing_options
                      ]}
                    onChange={(item) => {
                      this.handleSelectChange(item, 'gene')
                    }}  
                    value={gene}
                    placeholder={"Gene"}
                  />
                </div>

                  <div className="col-md-4 col-xs-12 no-padding-left">
                    <Dropdown
                      inputProps={{id: `gene-result-dropdown-${this.props.setIndex}-${this.props.itemIndex}`}}
                      options={[
                        { value: '', label: " " },
                        ...resultOptions
                      ]}
                      onChange={(item) => {
                        this.handleSelectChange(item, 'result')
                      }}
                      value={chosen_result}
                    />
                  </div>

                  <div className="col-md-3 col-xs-12 no-padding-left">
                    <input type="text"
                      className="form-control normal-input-text"
                      value={variant_name}
                      placeholder="Variant"
                      id="Variant"
                      name="Variant"
                      onChange={(e) => this.handleVariantChange(e)}
                      />
                  </div>

                  <div className="col-md-1 col-xs-12 no-padding-left margin-one">
                    {this.props.showDeleteButton &&
                      <a onClick={() => this.props.onDeleteItemClick()}
                        href="#"><i className="fa fa-lg fa-close"></i></a>
                    }

                    {this.props.showAddButton &&
                      <a href="#" onClick={() => this.props.onAddItemClick()}>
                        <i className="fa fa-lg fa-plus"></i>
                      </a>
                    }

                  </div>


      </React.Fragment>
    )
  }
}
const redux_state = state => ({
  static_list: state.static_list
});

const redux_actions = dispatch => ({
  dispatch: action => dispatch(action)
});

// export default InputGene;
export default connect(
  redux_state,
  redux_actions
)(InputGene);