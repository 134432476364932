import React, { Component } from "react";
import { connect } from "react-redux";
import ActivityIndicator from "../components/activity-indicator";
import sdk from '../api/sdk'

interface PedigreeLandingPageProps {
    location: any;
    history: any;
}

interface PedigreeLandingPageState {
    loading: boolean;
}


class PatientPedigreeLandingPage extends Component<PedigreeLandingPageProps, PedigreeLandingPageState> {
    constructor(props: PedigreeLandingPageProps) {
        super(props);
        this.state = {
            loading: true,
        };

        this.navigateToPage = this.navigateToPage.bind(this)

    }

    navigateToPage(path: any) {
        const { from } = this.props.location.state || { from: { pathname: path } };
        this.props.history.replace(from);
    }

    componentDidMount() {
        try {
            window.addEventListener('message', function (event) {
                if (event.data.token && event.data.type == 'web-pedigree-iframe' && event.data.url) {
                    localStorage.setItem('render-patient-pedigree', 'true')
                    localStorage.setItem('patient-pedigree-token', event.data.token)

                    let payload = {
                        token: {
                            accessToken: event.data.token
                        }
                    }
                    sdk.tokens_to_cookie(payload)
                    localStorage.removeItem("patient-pedigree-token");

                    window.location.replace(event.data.url)
                }
            });
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {

        return (
            <div>
                {this.state.loading && (
                    <ActivityIndicator loading={true} pedigree={true} />
                )}
            </div>
        );
    }
}

const redux_state = (state: any) => ({
    session: state.session
});

const redux_actions = (dispatch: any) => ({
    dispatch: (action: any) => dispatch(action)
});

export default connect(redux_state, redux_actions)(PatientPedigreeLandingPage);
