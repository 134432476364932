import React, { Component, useEffect, useState } from 'react';
import { cloneDeep } from "lodash";

import ReactFlow, {
  removeElements,
  addEdge,
  Controls,
  StraightEdge
} from 'react-flow-renderer';

import { FATHER_ANCESTRY_ID, MOTHER_ANCESTRY_ID } from './ancestry-node';
import FloatingStepEdge from './floating-step-edge';
import FloatingStraightEdge from './floating-straight-edge';
import FloatingMultipleBirthEdge from './floating-multiple-birth-edge';
import FloatingStraightEdgeNoPartner from './floating-straight-edge-no-partner'
import PersonNode from './person-node';
import PersonConnector from './person-connector-node';
import AncestryNode from './ancestry-node';
import {
  TwinConnectorNodeName,
  ClientSideNodeTypes,
  ApiNodeTypes,
  PedigreeStylesheet,
  MinZoom,
  MaxZoom,
  DefaultZoom,
  snapGrid,
  multiSelectionKeyCode
} from './pedigree-constants';


const edgeTypes = {
  TopToBottom: FloatingStraightEdge,
  BottomToChild: FloatingStepEdge,
  PersonToTop: FloatingStraightEdge,
  BottomToTwinChild: FloatingMultipleBirthEdge,
  PersonToBottom: FloatingStraightEdgeNoPartner
};

const nodeTypes = {
  person: PersonNode,
  ancestry: AncestryNode,
  default: PersonConnector
};


// NOTE: React-Flow only likes string's as id's for nodes & edges, and React-Flow
// also does not like duplicate id's
class Pedigree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: this.props.nodes,
      reactFlowInstance: null,
    };

    /**********Start class variables***********/
    this.parentMap = this.props.nodeParentMap;
    this.nodeDiseaseColorMap = this.props.nodeDiseaseColorMap;

    this.callbacks = {
      setElements: null,
      showAncestry: null
    };
    /**********End class variables*************/

    this.cloneElements = this.cloneElements.bind(this);
    this.setElements = this.setElements.bind(this);
    this.setParentMap = this.setParentMap.bind(this);
    this.setNodeDiseaseColorMap = this.setNodeDiseaseColorMap.bind(this);
    this.setNodesSelectedCount = this.setNodesSelectedCount.bind(this);
    this.onConnect = this.onConnect.bind(this);
    this.onElementsRemove = this.onElementsRemove.bind(this);
    this.onElementClick = this.onElementClick.bind(this);
    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.onNodeDrag = this.onNodeDrag.bind(this);
    this.onNodeDragStop = this.onNodeDragStop.bind(this);
    this.setIsNodeDragging = this.setIsNodeDragging.bind(this);
    this.showAncestry = this.showAncestry.bind(this);
	  this.getNode = this.getNode.bind(this);
    this.onSelectionDragStop = this.onSelectionDragStop.bind(this);
    this.onNodeDragStart = this.onNodeDragStart.bind(this)
  }

  componentDidMount() {
    console.log(this.props.nodes);
  }

  componentWillUnmount() {}

  cloneElements(els) {
    return cloneDeep(this.state.elements);
  }

  setElements(els) {
    this.setState({elements: els});
  }

  setParentMap(map) {
    this.parentMap = map;
  }

  setNodeDiseaseColorMap(map) {
    this.nodeDiseaseColorMap = map;
  }

  setNodesSelectedCount(count) {
    const els = this.cloneElements();
    for(let i=0; i<els.length; i++) {
      if (els[i].type === ClientSideNodeTypes.PERSON || els[i].nodeType === ApiNodeTypes.TOP || els[i].id.startsWith(TwinConnectorNodeName)) {
        els[i].data.nodesSelectedCount = count;
      }
    }
    this.setElements(els);
  }

  onConnect(params) {
    this.setElements((els) => {
      addEdge({...params}, els)
    });
  }

  onElementsRemove(elementsToRemove) {
    // setElements((els) => {
    //   removeElements(elementsToRemove, els)
    // });
  }

  async onElementClick(event, element) {
    try{
      let render_patient_pedigree = localStorage.getItem('render-patient-pedigree')
      render_patient_pedigree = render_patient_pedigree == 'true'

      //error thrown for readonly users from parent node when clickiing so open sidebar without node name check
      if ((this.props.readOnlyUser || render_patient_pedigree) && 'nodeSelectedCallback' in this.props) {
        return await this.props.nodeSelectedCallback(element);
      }

      //Clicking on the one click add menus is being considered as a node click, so this is a restriction for that
      // event.preventDefault();

      let nodeName1 = event.target.nodeName
      let nodeName2 = event.target.parentNode.nodeName
      let nodeName3 = event.target.parentNode.parentNode.nodeName

      let modal_twin_click = false;

      let topParent = event.target

      while(topParent !== null){
        topParent = topParent.parentNode
        if(topParent && topParent.id){
          if(topParent.id === 'modal-twins'){
            modal_twin_click = true;
          }
        }
      }

      //Make sure clicking the menus doesn't add on node click count
      if(nodeName1 == 'BUTTON' || nodeName2 == 'BUTTON' || nodeName3 == 'BUTTON' || nodeName1 == 'A' || nodeName2 == 'A' || nodeName3 == 'A' || modal_twin_click){
        return;
      }
      if ('nodeSelectedCallback' in this.props) {
        await this.props.nodeSelectedCallback(element);
      }
      await this.addNodeClickCount(element.id);

      // //check if browser is firefox
      // let f = navigator.userAgent.search("Firefox");
      // if(f > -1){
      //   let currentEls = cloneDeep(this.state.reactFlowInstance.getElements())
      //   //After click release, turn draggable property back to true
      //   for (let el of currentEls) {
      //     if (el.id == element.id) {
      //       el.draggable = true;
      //     }
      //   }

      //   this.setState({ elements: currentEls });
      // }
    }
    catch(e){
      console.log(e)
    }
  }

  async resetNodeClickCount(){
    const els = this.cloneElements();
    for(let i=0; i<els.length; i++) {
      if (els[i].type === ClientSideNodeTypes.PERSON || els[i].nodeType === ApiNodeTypes.TOP || els[i].id.startsWith(TwinConnectorNodeName)) {
        delete els[i].data.selected_after_pedigree_load;
        els[i].data.nodeClickCount = 0;
        // await sessionStorage.removeItem('famgenix_nodeClickCount')
        // console.log(sessionStorage.getItem('famgenix_nodeClickCount'))
      }
    }
    await this.setElements(els);
    sessionStorage.removeItem('famgenix_last_selected_node')
  }

  async onSelectionChange(els) {
    await this.resetNodeClickCount();
    if (Array.isArray(els)) {
      this.setNodesSelectedCount(els.length);
      if ('clearNodeSelectionCallback' in this.props && els.length > 1) {
        this.props.clearNodeSelectionCallback();
      }
    } else {
      this.setNodesSelectedCount(0);
      if ('clearNodeSelectionCallback' in this.props) {
        this.props.clearNodeSelectionCallback();
      }
    }
  }

  getNode(els, elementID)
  {
	let result = null;
	for (let i = 0; i < els.length; i++)
	{
		let el = els[i];
		let id = el.id.toString();
		if (id === elementID)
		{
			result = el;
		}
	}
	return result;
  }

  onNodeDragStart(event, node) {
    // let f = navigator.userAgent.search("Firefox");
    // if(f > -1){
    //   let currentEls = cloneDeep(this.state.reactFlowInstance.getElements())
    //   // Clicking a node triggers the onNodeDragStart event and somehow only triggers an issue on firefox
    //   // Set draggable property to false to force it not to drag everytime we click a node
    //   for (let el of currentEls) {
    //     if (el.id == node.id) {
    //       el.draggable = false;
    //     }
    //   }
    //   this.setState({ elements: currentEls }, () => console.log(node.id, currentEls));
    // }
  }

  onNodeDrag(event, node) {
	// console.log("Node Dragging" + node.id);
	// console.log(node);

  // return if Ancestry Node
  if (node.type === ClientSideNodeTypes.ANCESTRY) return;

	let datastore = node.data.datastore;
	let connector = datastore.getSpouseConnector(node.id);
	const els = this.cloneElements();
	 for (let i = 0; i < els.length; i++)
	 {
		 let el = els[i];
     // set node option draggable back to true to be able to drag
    //  let f = navigator.userAgent.search("Firefox");
    //  if(f > -1){
    //   el.draggable = true;
    //  }
		 let id = el.id.toString();
		  if (connector && id === connector.node)
			{
				let spouseList = datastore.getSpouse(connector.node);
				let x = el.position.x;
				let y = el.position.y;
				if (spouseList.length === 2)
				{
					let spouse_a_id = spouseList[0];
					let spouse_a = this.getNode(els,spouse_a_id);

					if (spouse_a_id === node.id)
					{
						spouse_a = node;
					}
					else
					{
						let data = datastore.getNode(spouse_a_id);
            if(!this.props.saved_data){
              spouse_a.position.x = data["x"];
              spouse_a.position.y = data["y"];
            }
					}
					let spouse_b_id = spouseList[1];
					let spouse_b = this.getNode(els,spouse_b_id);
					if (spouse_b_id  == node.id)
					{
						spouse_b = node;
					}
					else
					{
						let data = datastore.getNode(spouse_b_id);
            if(!this.props.saved_data){
						  spouse_b.position.x = data["x"];
						  spouse_b.position.y = data["y"];
            }
					}
					let diff = Math.abs(spouse_b.position.x - spouse_a.position.x);
					let offset = 50;
					let Y_OFFSET = 18;
					let X_WIDTH_LEFT = 30;
					let left = false;
					let spouse = null;
					if (diff < 125)
					{
						diff -= 25;
						diff = Math.max(diff,2);
						offset = diff /2;
					}
					if (spouse_b.position.x > spouse_a.position.x)
					{
						if (spouse_b_id  == node.id)
						{
							spouse = spouse_b;
							left = false;
						}
						else
						{
							spouse = spouse_a;
							left = true;
						}
					}
					else
					{
						if (spouse_b_id  == node.id)
						{
							spouse = spouse_b;
							left = true;
						}
						else
						{
							spouse = spouse_a;
							left = false;
						}
					}
					y = spouse.position.y + Y_OFFSET;
					if (left === true)
					{
						x = spouse.position.x + X_WIDTH_LEFT + offset;
					}
					else
					{
						x = spouse.position.x - offset;
					}
				}
				el.position = {
					x: x,
					y: y
				  };

		  	}

		}
		this.setElements(els);
	// this.setElements(els);

    if (node.type === ClientSideNodeTypes.PERSON && !node.data.isNodeDragging) {
      this.setIsNodeDragging(node.id, true);
    }
  }

  async addNodeClickCount(nodeId) {
    const els = this.cloneElements();
    for(let i=0; i<els.length; i++) {
      if (els[i].id === nodeId) {
        els[i].data.nodeClickCount += 1;
        if(els[i].data.nodeClickCount > 2){
          els[i].data.nodeClickCount = 1
        }
        break;
      }
    }
    await this.setElements(els);
    sessionStorage.setItem('famgenix_last_selected_node', JSON.stringify(nodeId))
  }

  async onNodeDragStop(event, node) {
    if (node.type === ClientSideNodeTypes.PERSON && node.data.isNodeDragging) {
      this.setIsNodeDragging(node.id, false);
    }
    // const els = this.cloneElements();
    // for(let el of els){
    //   if(el.id == node.id.toString()){
    //     el.position.x = node.position.x
    //     el.position.y = node.position.y
    //   }
    // }

    //We can just get the realtime positions of elements to be saved from reactflowinstance
    let currentEls = cloneDeep(this.state.reactFlowInstance.getElements())

    //set these also to make sure there are no differences on current elements state
    for(let el of currentEls) {
      if (el.id == node.id) {
        el.data.isNodeDragging = false;
        if (el.id.startsWith(TwinConnectorNodeName+'-')){
          el.data.nodeClickCount = 2;
        }
        else{
          el.data.nodeClickCount = 1;
        }
        break;
      }
    }

    this.setState({elements: currentEls}, async() => await this.props.saveLayoutData(currentEls));
  }


  async onSelectionDragStop(event, nodes) {
    //We can just get the realtime positions of elements to be saved from reactflowinstance
    let currentEls = cloneDeep(this.state.reactFlowInstance.getElements())
    this.setState({ elements: currentEls }, async() => await this.props.saveLayoutData(currentEls));
  }

  setIsNodeDragging(nodeId, dragging) {
    const els = this.cloneElements();
    for(let i=0; i<els.length; i++) {
      if (els[i].id === nodeId) {
        els[i].data.isNodeDragging = dragging;
        break;
      }
    }
    this.setElements(els);
  }

  showAncestry(showAncestry) {
    const els = this.cloneElements();
    if (showAncestry) {

      for(let i=0; i<els.length; i++) {
        if (els[i].id === FATHER_ANCESTRY_ID || els[i].id === MOTHER_ANCESTRY_ID) {
          els[i].isHidden = false;
        }
      }

    } else {

      for(let i=0; i<els.length; i++) {
        if (els[i].id === FATHER_ANCESTRY_ID || els[i].id === MOTHER_ANCESTRY_ID) {
          els[i].isHidden = true;
        }
      }

    }
    this.setElements(els);
  }

  render() {

    const styles = Object.assign({}, PedigreeStylesheet.reactflowWrapper, {height: this.props.dimensions.height, width: this.props.dimensions.width});

    this.callbacks = {
      setElements: this.setElements,
      showAncestry: this.showAncestry,
      elements: this.state.elements
    };

    return (
      <div id="react-flow-pedigree" style={styles}>
        <ReactFlow
          nodesDraggable={!this.props.readOnlyUser}
          snapToGrid={true}
          snapGrid={snapGrid}
          minZoom={MinZoom}
          maxZoom={MaxZoom}
          defaultZoom={DefaultZoom}
          elements={this.state.elements}
          onElementClick={this.onElementClick}
          onConnect={this.onConnect}
          onElementsRemove={this.onElementsRemove}
          onSelectionChange={this.onSelectionChange}
          onNodeDrag={this.onNodeDrag}
          onNodeDragStop={this.onNodeDragStop}
          onSelectionDragStop={this.onSelectionDragStop}
          onNodeDragStart={this.onNodeDragStart}
          onLoad={(reactFlowInstance) => {
            this.setState({reactFlowInstance})
            this.props.onLoad(reactFlowInstance, this.callbacks)
          }
          }
          onMoveEnd={(flowTransform) => this.props.onMoveEnd(flowTransform)}
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
          multiSelectionKeyCode={multiSelectionKeyCode()}
        />
      </div>
    );
  }
}

export default Pedigree;
