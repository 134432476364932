import React, { Component } from 'react';
import FamilyHistoryContent from './family-history/family-history-content'
import helpers from "../../helpers/index";
import {set_cookie} from "../../helpers/helper-cookies";

import familyApi from "../../api/family-api";
import { connect } from "react-redux";
import Cookie from 'js-cookie'
import refineryApi from '../../api/refinery-api';
import { buildSmartDrawPayload, createUUID } from '../react-flow-pedigree/utils';

class FamilyHistoryTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPedigree: true,
      selectedNode: null,
      latestCenter: null,
      nodeLocations: [
        {

        }
      ]
    }
    this.refreshPedigreeAddParent = this.refreshPedigreeAddParent.bind(this)
    this.refreshPedigree = this.refreshPedigree.bind(this)
    this.refreshPedigreeAddChild = this.refreshPedigreeAddChild.bind(this);
    this.getNodeLocations = this.getNodeLocations.bind(this);
    this.fetchMulti = this.fetchMulti.bind(this);
    this.getParentId = this.getParentId.bind(this);
  }

  async componentDidMount(){

  }

  async getNodeLocations(profiles){
    const data = {
      proband: this.props.patient.patient,
      profile: profiles,
      displayGeneticTesting: Cookie.get('famgenix_pedigree_genetic_testing'),
      showNotes: Cookie.get('famgenix_pedigree_notes'),
      showName: Cookie.get('famgenix_pedigree_view_status'),
      cloned_members: [],
      member_links: [],
      canvas: true
    };
    let payload = buildSmartDrawPayload(data);
    let react_flow_layout = await familyApi.react_flow_pedigree_layout_dom(payload)
    let nodeLocations = react_flow_layout.filter(element => element.data !== null && element.data !== undefined)
    nodeLocations = nodeLocations.filter(element => element.data.label !== null
                                                && element.data.label !== undefined
                                                && element.data.gender !== null
                                                && element.data.gender !== undefined)
    this.setState({nodeLocations});
    return nodeLocations;
  }

  async getNodeLocationsOnRefresh(profile){
    let payload = await this.buildSmartDrawPayload2(profile);

    let react_flow_layout = await familyApi.react_flow_pedigree_layout(payload)

    let nodeLocations = react_flow_layout.filter(element => element.data !== null && element.data !== undefined)
    nodeLocations = nodeLocations.filter(element => element.data.label !== null
                                                && element.data.label !== undefined
                                                && element.data.gender !== null
                                                && element.data.gender !== undefined)
    this.setState({nodeLocations})
    return nodeLocations
  }

  fetchMulti(parentid, relationships){
    relationships = relationships.filter(rs => {
      let couples = rs.couples
      return couples.includes(parentid)
    })
    return [...new Set(relationships.map(item => item.id))];
  }

  getParentId(father_id, mother_id, relationships){
    let parent = relationships.find(rs => rs.father === father_id && rs.mother === mother_id)
    let parent_id = parent.id
    return parent_id
  }

  wrap = (s, w) => s.replace(
    new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, 'g'), '$1\n'
  );

  getGeneticTestingString(profile){
    let content = '';
    let nonNegative = [];
    if (profile !== null) {
      nonNegative = profile.filter(geneTest => geneTest.result !== "n")
    }

    if (nonNegative.length !== 0){
      for(let gene of nonNegative){
        if(gene.result === "p") {
          gene.result = "Pathogenic"
        }
        else if(gene.result === "lp"){
          gene.result = "Likely Pathogenic"
        }
        else if(gene.result === "ln"){
          gene.result = "Likely Benign"
        }
        else if(gene.result === "u"){
          gene.result = "Unsure"
        }
        else if(gene.result === "vus"){
          gene.result = "VUS"
        }
        content += gene.gene + " - " + gene.result + ", "
      }
      content = content.slice(0, -2)
    }
    else if (nonNegative.length === 0 && profile !== null && profile.length !== 0){
      content = "Negative genetic testing"
    }
    else{
      content = '';
    }
    return content;
  }

  async buildSmartDrawPayload2(profile){
    await helpers.delay(.2)
    let people = Object.values(profile)
    let members = [];
    let relationships = [];

    let displayGeneticTesting = Cookie.get('famgenix_pedigree_genetic_testing')
    let displayGeneticTestingBool = displayGeneticTesting == 'true'
    let showNotes = Cookie.get('famgenix_pedigree_notes')
    let showNotesBool = showNotes == 'true'
    let showName = Cookie.get('famgenix_pedigree_view_status') || 'identified'


    if (showName === undefined || showName === null) {
      set_cookie('famgenix_pedigree_view_status', 'identified')
      showName = "identified";
    }

    let showNameBool = showName == 'identified'

    for(let person of people){
      let cause_of_death_subtext = '';
      let diseases_subtext = '';
      let genetic_testing_subtext = '';
      let notes_subtext = '';
      let person_name_subtext = ''

      let subText = '';

      //separate the disease subtexts
      if(person.diseases.length > 0){
        let diseases = person.diseases
        for(let disease of diseases){
          let age = disease.age_diagnosed? disease.age_diagnosed : `?`

          diseases_subtext += `\n${disease.disease_short_name} ${age}`
        }
        diseases_subtext = diseases_subtext.substring(1)
      }

      if(person.cause_of_death){
        cause_of_death_subtext += person.cause_of_death
        subText += cause_of_death_subtext;
      }

      if(person.genetic_testing.length > 0 && displayGeneticTestingBool){
        genetic_testing_subtext += this.getGeneticTestingString(person.genetic_testing)
        subText += person.cause_of_death ? '\n' + genetic_testing_subtext : genetic_testing_subtext
      }

      if(person.note && showNotesBool){
        notes_subtext += person.note
        subText += person.cause_of_death || person.genetic_testing.length > 0 ? '\n' + notes_subtext : notes_subtext
      }

      let name = null;
      if (showNameBool) {
        if (person.first_name && person.age) {
          name = `${person.first_name} ${person.age}`
        }
        else if (person.first_name) {
          name = person.first_name
        }
        else {
          name = person.age
        }
      }
      else if (person.age) {
        name = person.age
      }

      let member = {
        id: person.id,
        name: name,
        gender: person.gender === 'm' ? 'male' : (person.gender === 'f') ? 'female' : 'unknown',
        motherid: person.mother_id ? person.mother_id : null,
        fatherid: person.father_id ? person.father_id : null,
        parentid: null, //this one depends on the relationships object, insert value on this after making relationships object
        multi: null, //this one depends on the parentid, so insert value after getting parentid
        twinID: person.twin_id ? `t-${person.twin_id}` : null,
        subText: subText,
        diseaseList: diseases_subtext
      };

      members.push(member)
    }

    //relationships
    //couples: filter out all profiles that have relationship ids, relationship "id" field = person.relationship_ids
    let peopleWithRelationships = people.filter(person => person.relationship_ids.length > 0)

    for(let person of peopleWithRelationships){
      let personRelationships = person.relationship_ids
      for(let relationship of personRelationships){
        let children = people.filter(person => person.mother_id === relationship.mother_id && person.father_id === relationship.father_id)
        let childrenrs = [];
        for(let child of children){
          if(child.relationship_ids.length > 0){
            for(let childrelationship of child.relationship_ids){
              childrenrs.push(childrelationship.rkey)
            }
          }
          else{
            childrenrs.push(child.id)
          }
        }
        let rs = {
          id: relationship.rkey,
          type: 'couple',
          mother: relationship.mother_id,
          father: relationship.father_id,
          children: childrenrs //included couple ids
        }
        relationships.push(rs)
      }
    }
    relationships = [...new Map(relationships.map(item => [item['id'], item])).values()];
    let copy_relationships = relationships;

    //check for supercouples
    for(let relationship of copy_relationships){
      let sameMotherRelationships = relationships.filter(rs => rs.mother === relationship.mother && relationship.type === 'couple')
      let sameFatherRelationships = relationships.filter(rs => rs.father === relationship.father && relationship.type === 'couple')


      if(sameMotherRelationships.length > 1){
        //TODO: going to want to change this back for future use
        let id = createUUID();
        let couples = [];
        for(let sameMother of sameMotherRelationships){
          couples.push(sameMother.id)
        }
        let rs = {
          id: id,
          type: "supercouple",
          centerid: relationship.mother,
          couples: couples
        }
        relationships.push(rs)
      }

      if(sameFatherRelationships.length > 1){
        let id = createUUID();
        let couples = [];
        for(let sameFather of sameFatherRelationships){
          couples.push(sameFather.id)
        }
        let rs = {
          id: id,
          type: "supercouple",
          centerid: relationship.father,
          couples: couples
        }
        relationships.push(rs)
      }

    }

    let couple_relationships = relationships.filter(rs => rs.type === "couple")
    let super_couple_relationships = relationships.filter(rs => rs.type === "supercouple")

    let super_couple_relationships_unique = [...new Map(super_couple_relationships.map(item => [item['centerid'], item])).values()];

    relationships = couple_relationships.concat(super_couple_relationships_unique)

    for(let member of members){
      if(member.motherid && member.fatherid){
        member.parentid = this.getParentId(member.fatherid, member.motherid, relationships.filter(rs => rs.type === "couple"))
        member.multi = this.fetchMulti(member.parentid, relationships.filter(rs => rs.type === "supercouple"))
      }
      else{
        member.multi = [];
      }
    }


    // for making supercouples children

    // for(let relationship of couple_relationships){
    //   let similars = [];
    //   super_couple_relationships_unique.map(rs => {
    //     let couples = rs.couples;
    //     let children = relationship.children;
    //     couples = couples.filter(couple => {
    //       if(children.includes(couple)){
    //         similars.push(couple)
    //       }
    //       return children.includes(couple)
    //     })
    //     relationship.children.concat(couples)
    //     for(let similar of similars){
    //       relationship.children = relationship.children.filter(rs => rs !== similar)
    //     }
    //   })
    // }

    for(let relationship of couple_relationships){
      let relationshipsAsChildren = relationship.children.filter(child => String(child).startsWith('r-'))

      super_couple_relationships_unique.map(rs => {
        // let intersection = rs.couples.filter(element => relationshipsAsChildren.includes(element))
        let intersection = rs.couples.every(function(item) {
          return relationshipsAsChildren.indexOf(item) !== -1;
        });
        if(intersection){
          if(relationshipsAsChildren.length > 1){
            relationship.children.push(rs.id)
          }
        }
      })

      // if(relationshipsAsChildren.length > 1){
      //   let difference = relationship.children.filter(x => !relationshipsAsChildren.includes(x));
      //   console.log(JSON.parse(JSON.stringify(relationship.children)))
      //   relationship.children = difference
      //   console.log(JSON.parse(JSON.stringify(relationship.children)))
      // }

      const unique_supercouples = [...new Set(super_couple_relationships_unique.map(item => item.id))];
      let superCouplesAsChildren = relationship.children.filter(child => unique_supercouples.includes(child))
      // let superCouplesAsChildren = relationship.children.filter(child => !String(child).startsWith('r-') && typeof child === 'string')
      for(let couple of superCouplesAsChildren){
        let supercouple_item = super_couple_relationships_unique.find(rs => rs.id === couple)

        for(let cp of supercouple_item.couples){
          const index = relationship.children.indexOf(cp);
          if (index > -1) {
            relationship.children.splice(index, 1);
          }
        }
      }

    }

    relationships = couple_relationships.concat(super_couple_relationships_unique)

    let probandCoupleID = null;
    let proband = people.find(person => person.is_proband === true)
    let probandID = proband.id;

    let super_couple_relationship_with_proband = super_couple_relationships_unique.find(rs => rs.centerid === probandID)
    if(super_couple_relationship_with_proband){
      probandCoupleID = super_couple_relationship_with_proband.id
    }
    else{
      let couple_relationships_with_proband = couple_relationships.find(rs => rs.mother === probandID || rs.father === probandID)
      if(couple_relationships_with_proband){
        probandCoupleID = couple_relationships_with_proband.id
      }
      else{
        probandCoupleID = null
      }
    }

    let payload = {
      character_width: 7,
      character_height: 16,
      yscale: 65,
      xscale: 55,
      probandID,
      probandCoupleID,
      members,
      relationships
    }

    return payload;

  }

  async fetchAllJSON(){
    let member_ids = ['1968', '1979', '1986', '1993', '2000', '2011', '2018', '2032', '2047', '2093', '2107',
    '2116', '2147', '2156', '2245', '2254', '2337', '2361', '2397', '2453', '2463', '2470', '2486', '2556', '2579', '2586',
    '2623', '2660', '2691', '2699', '2721', '2766', '2821', '2846', '2853', '2896', '2942', '2961', '2987', '3011', '3037',
    '3096', '3103', '3135', '3149', '3197', '3209', '3236', '3272', '3320', '3395', '3409', '3416', '3498', '3516', '3544',
    '3551', '3614', '3621', '3638', '3653', '3792', '3861', '3877', '3894', '3910', '3961', '4017', '4026', '4037', '4045',
    '4124', '4161', '4183', '4203', '4229', '4268', '4287', '4372', '4390', '4453', '4481', '4512', '4516', '4540', '4565',
    '4599', '4611', '4622', '4657', '4685', '4768', '4799', '4818', '4889', '4901', '4910', '4920', '4978', '4989', '5006',
    '5021', '5054', '5090', '5142', '5185', '5218', '5235', '5242', '5250', '5264', '5272', '5346', '5359', '5390', '5424',
    '5474', '5622', '5630', '5637', '5647', '5664', '5671', '5679', '5695', '5703', '5719', '5750', '5828', '5858', '5900',
    '5925', '5951', '5952', '5980', '5990', '5997', '6004', '6045', '6078', '6142', '6149', '6167', '6211', '6238', '6246',
    '6312', '6424', '6431', '6446', '6465', '6473', '6483', '6514', '6540', '6631', '6681', '6698', '6783', '6790', '6803',
    '6881', '6923', '6930', '6984', '6995', '7015', '7057', '7113', '7130', '7137', '7181', '7239', '7318', '7366', '7398',
    '7434', '7441', '7479', '7483', '7510', '7578', '7620', '7660', '7753', '7762', '7797', '7804', '7870', '7908', '7944',
    '8015', '8032', '8102', '8170', '8179', '8245', '8292', '8337', '8359', '8375', '8383', '8390', '8508', '8520', '8533',
    '8541', '8557', '8564', '8571', '8592', '8655', '8711', '8730', '8737', '8744', '8751', '8854', '8893', '8907', '8914',
    '8947', '9031', '9058', '9077', '9092', '9099', '9151', '9171', '9293', '9306', '9347', '9366', '9377', '9389', '9407',
    '9449', '9470', '9477', '9484', '9495', '9517', '9553', '9572', '9590', '9655', '9800', '9807', '9826', '9835', '9848',
    '9859', '9892', '9899', '9940', '9951', '9962', '10161', '10170', '10189', '10197', '10209', '10222', '10233', '10248',
    '10278', '10332', '10430', '10445', '10456', '10464', '10471', '10478', '10513', '10647', '10672', '10682', '10696', '10925',
    '10937', '10959', '10971', '10986', '10996', '11225', '11259', '11262', '11273', '11286', '11302', '11326', '11333', '11358']
    for(let member_id of member_ids){
      // let redux_tree = await refineryApi.get_redux_tree(member_id)
      let redux_tree = await refineryApi.get_redux_tree_deux(member_id, {"canvas": "1"})
      const objectArray = Object.entries(redux_tree.profiles);
      objectArray.forEach(([key, value]) => {
        if(value.twin_set === null){
          value.twin_type = null;
        }
      });

      redux_tree.profiles = Object.fromEntries(objectArray);

      let toJSONfile = await this.buildSmartDrawPayload2(redux_tree.profiles)
      await this.download(JSON.stringify(toJSONfile), `member-${member_id}.json`, 'text/plain')
    }
  }

  download(content, fileName, contentType) {
    var a = document.createElement("a");
    var file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  async refreshPedigreeAddParent(selected_node=null, latest_center=null) {
    await this.props.fetchProbandTree(this.props.dispatch, this.props.memberid, true, this.props.fetchHiddenDiseaseColors, this.props.session.user.clinician_id, this.props.session.user.organization_id, this.props.fethPedigreeNotes);
    await helpers.delay(0.2);
    await this.setState({showPedigree: false})
    await helpers.delay(.2)
    await this.setState({latestCenter: latest_center})
    let center = window.localStorage.getItem("famgenix_latestcenter");
    let node = window.localStorage.getItem("selectedNode");
    if (center == null || center == undefined){
      this.setState({latestCenter: null})
    }
    else{
      this.setState({latestCenter: JSON.parse(center)})
    }
    if (node == null || node == undefined){
      this.setState({showPedigree: true, selectedNode: null})
    }
    else{
      this.setState({showPedigree: true, selectedNode: JSON.parse(node)})
    }
  }

  async refreshPedigreeAddChild(selected_node=null, latest_center=null){
    await this.props.fetchProbandTree(this.props.dispatch, this.props.memberid, true, this.props.fetchHiddenDiseaseColors, this.props.session.user.clinician_id, this.props.session.user.organization_id,this.props.fethPedigreeNotes);
    await this.setState({showPedigree: false})
    await helpers.delay(.2)
    await this.setState({showPedigree: true, selectedNode: selected_node, latestCenter: latest_center})
  }

  async refreshPedigree(selected_node=null, latest_center=null) {
    await this.props.fetchProbandTree(this.props.dispatch, this.props.memberid, true, this.props.fetchHiddenDiseaseColors, this.props.session.user.clinician_id, this.props.session.user.organization_id,this.props.fethPedigreeNotes);
    await this.setState({showPedigree: false})
    await helpers.delay(.2)
    await this.setState({showPedigree: true, selectedNode: selected_node, latestCenter: latest_center})
  }

  render() {
    return (this.state.showPedigree &&
      <FamilyHistoryContent
        patient={this.props.patient}
        dispatch={this.props.dispatch}
        refreshPedigreeAddChild={this.refreshPedigreeAddChild}
        refreshPedigreeAddParent={this.refreshPedigreeAddParent}
        onPedigreeRefresh={this.refreshPedigree}
        getNodeLocations={(profile) => this.getNodeLocations(profile)}
        latestCenter={this.state.latestCenter}
        selectedNode={this.state.selectedNode}
        memberid={this.props.memberid}
        read_only={this.props.read_only}
        organization_id={this.props.organization_id}
        clinician_id={this.props.clinician_id}
        nodeLocations={this.state.nodeLocations}
      />
    );
  }
}

const mapStateToProps = state => {
  return {...state};
};

export default connect(mapStateToProps)(FamilyHistoryTabs);
