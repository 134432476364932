import React, { Component } from 'react'
import { connect } from "react-redux"
import lodash from 'lodash'


import HealthInputs from './health-inputs'
import * as helper_family_tree from '../../helpers/helper-family-tree'
import FamilyTreePartner from './partner'

import helpers from '../../helpers';
import family_api from '../../api/family-api'
import ErrorSummary from '../error-summary';
import * as model from '../../model-states/m-family-tree';
import * as patient_actions from '../../store/patient/actions';

class FamilyTreeSonDaughter extends Component {

  constructor(props) {
    super(props)
    this.state = {
      errorMessages: [],
      loading: false,

      collapsed: true,
      label: '',
      partners: null,
      sonDaughterRkey: null,

    }

    this.updateFirstName = lodash.debounce(this.updateFirstName, 250)

  }

  componentDidMount() {
    this.setLabel()
    this.setState({sonDaughterRkey: this.props.son_daughter.rkey})
    // this.populatePartnersFromAPI()

  }

  componentDidUpdate(prevProps) {
    this.loadPartnersFromRedux()
  }

  // async populatePartnersFromAPI() {
  //   try {
  //     let spouse_id = this.props.son_daughter.id
  //     if(spouse_id == null) return

  //     // Clear redux before repopulating
  //     this.props.dispatch(patient_actions.delete_partner({ownerRkey: this.props.son_daughter.rkey}))

  //     // Fetch Parter from API
  //     let partners = await family_api.get_member_memberid_partners(spouse_id)
  //     for(var partner of partners) {
  //       // Save to Redux
  //       var partner_from_model = model.createPartner()
  //       var partner_data = Object.assign({}, partner_from_model, partner)

  //       // Save to Redux
  //       // partner_data.rkey = 'member_' + partner_data.id
  //       helper_family_tree.savePartnerToRedux(this.props.dispatch, this.props.son_daughter.rkey, partner_data)
  //       helper_family_tree.saveProfileToRedux(this.props.dispatch, partner_data)
  //     }
  //   } catch (error) {
  //     helpers.logger(error)
  //   }
  // }

  loadPartnersFromRedux() {

    if (this.state.sonDaughterRkey == null) return

    if(!(this.state.sonDaughterRkey in this.props.patient.partners)) return

    let partners =this.props.patient.partners[this.state.sonDaughterRkey]

    if(!lodash.isEqual(this.state.partners, partners)) {
      this.setState({partners})
    }

  }

  setLabel() {
    if(this.props.type === 'unknown') {
      var label = "Child #"
    } else if (this.props.type === 'son') {
      var label = 'Son #'
    } else {
      var label = 'Daughter #'
    }
    label = label + this.props.ordinal
    if (!lodash.isEqual(this.state.label, label)) {
      this.setState({ label})
    }
  }


  async onChangeName(first_name) {
    try {

      let profile = { rkey: this.props.son_daughter.rkey, first_name }
      helper_family_tree.saveProfileToRedux(this.props.dispatch, profile)
      helper_family_tree.saveSonDaughterDetailToRedux(this.props.dispatch, this.props.partnerRkey, profile)

      this.updateFirstName(first_name)
    } catch (error) {
      helpers.logger(error)
    }
  }

  async updateFirstName(first_name) {
    try {
      this.setState({errorMessages: []})

      let member = this.props.son_daughter
      await family_api.patch_member_memberid(member.id, {
        first_name
      })

    } catch (error) {
      this.setState({errorMessages: [error.message]})
    }
  }


  getTotalIndent() {
    return this.props.level + this.props.extra_indent
  }

  onCollapseClick() {

    this.setState({ collapsed: !this.state.collapsed })
    this.clearSelection()

  }

  clearSelection() {
    if (window.getSelection) { window.getSelection().removeAllRanges(); }
    else if (document.selection) { document.selection.empty(); }
  }

  showCollapseButton() {
    return (
        this.props.level == 0 &&
        this.state.partners &&
        this.state.partners.length > 0
    )
  }

  getFirstName() {

    let profile = this.getProfile();
    if(profile == null) return null;

    return profile.first_name;
  }

  getProfile() {
    return helper_family_tree.getProfileFromRedux(this.props.patient, this.props.son_daughter.rkey)
  }

  async onClickDeleteConfirmed() {
    try {

      this.setState({errorMessages: [], loading: true})

      await this.props.deleteMember(this.props.son_daughter)

      helper_family_tree.deleteChildren(this.props.patient, this.props.dispatch, this.props.partnerRkey, this.props.son_daughter.rkey)
      this.setState({ openModalConfirmDelete: false })

    } catch (error) {
      helpers.logger(error)
      this.setState({errorMessages: [error.message]})
    } finally {
      this.setState({loading: false})
    }
  }

  render() {

    let indent = this.getTotalIndent();
    let indent_class="";
    if(indent === 1) {
      indent_class="table-indent";
    } else if (indent === 2) {
      indent_class="table-indent-2-5";
    } else if (indent === 3) {
      indent_class="table-indent-4";
    } else if (indent === 4) {
      indent_class="table-indent-5-5";
    } else if (indent >= 5) {
      indent_class="table-indent-6";
    }

    return <React.Fragment>

      <tr>
        <td>

        </td>
        <td className="td--nowrap">

          <span className={indent_class}>{this.state.label}</span>

        </td>
        <td>
          <input
            onChange={(e) => this.onChangeName(e.target.value)}
            value={this.getFirstName()}
            type="text" className="inputreg form-control normal-input-text" />
        </td>

        <HealthInputs
          for="son_daughter"
          patient={this.props.patient}
          dispatch={this.props.dispatch}
          errorMessages={this.state.errorMessages}
          loading={this.state.loading}
          ownerRkey={this.props.son_daughter.rkey}
          collapsible={this.state.partners && this.state.partners.length > 0}
          collapsed={this.state.collapsed}
          onCollapse={() => this.onCollapseClick() }
          canDelete={true}
          onDeleteConfirmed={async () => await this.onClickDeleteConfirmed() }
          profile={this.getProfile()}
        />

      </tr>


      { this.state.collapsed && this.state.partners &&
        this.state.partners.map((partner,index) => {
          return <FamilyTreePartner
            key={partner.rkey}
            ownerRkey={this.state.sonDaughterRkey}
            level={this.props.level + 1}
            extra_indent={this.props.extra_indent}
            label={'Partner #' + (index + 1) }
            partner={partner}
            hiddenFromParent={!this.state.collapsed}
            owner={this.props.son_daughter}
            parent_side={this.props.parent_side}
            dispatch={this.props.dispatch}
            patient={this.props.patient}
            deleteMember={this.props.deleteMember}
          />
        })
      }

    </React.Fragment>

  }
}

export default FamilyTreeSonDaughter;
