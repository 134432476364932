export default {
  overlap_loop_limit: 100,
  show_grid: false,
  show_person_id: false,

  chart_bg_color_for_printing: '#ffffff',
  chart_bg_color: '#fafafa',
  // chart_bg_color: 'red',
  chart_padding_top: 2,
  chart_padding_left:  3,
  // chart_padding_left:  0,
  chart_padding_right: 8, // Space for popup drawer on the right
  chart_padding_bottom: 2,

  scrollbar_thickness: 6,
  scrollbar_background_color: '#d3d3d3',
  scrollbar_handle_color: '#969696',

  // matrix_box_width: 120,
  matrix_box_width: 55, // person_width should be equal matrix_box_width
  matrix_box_height: 55,
  matrix_col_space: 2,
  matrix_row_space: 2,

  person_width: 35, // person_width should be equal matrix_box_width
  person_space_around: 35,
  column_in_between: 1,

  defaultColor: '#ffffff',
  // defaultColor: '#000000',
  strokeColor: '#bdbdbd',
  fillColor: '#bdbdbd',
  fillColor_1to4: '#0f78c7',
  fillColor_5to8: '#d03e3e',
  fillColor_9to12: '#f2c33b',
  fillColor_14to28: '#4a4a4a',
  fillColor_circle: '#4a4a4a',
  line_color: '#4a4a4a',
  line_spacing: 10,
  line_width: 2,
  trait_fillColor: '#4a4a4a',
  deceased_strokeColor: '#bdbdbd', //'#fff'
  autosomal_strokeColor: '#fff',
  autosomal_fillColor_center: '#bdbdbd',
  autosomal_fillColor: '#bdbdbd',
  adopted_strokeColor: "#bdbdbd",
  miscarriage_fillColor: '#bdbdbd',
  pregnancyTextColor: '#000000',
  pregnancyTerminated_strokeColor: '#fff',
  person_highlight_fillColor: '#66D1BA',
  person_highlight_strokeWidth: 5,

  label_margin_top: 15,
  label_max_char_width: 17,

  // connector_line_color: '#4a4a4a',
  // connector_line_strokeWidth: 0.4,
  connector_line_color: '#bdbdbd',
  connector_line_strokeWidth: 2,
  connector_sibling_line_height_by_row: 1,
  connector_adopted_in_color: '#bdbdbd',
  connector_adopted_in_dash: [7, 5],

  popup_menu_button_width: 20,
  popup_menu_button_height: 20,
  popup_menu_button_margin_top: -7,
  popup_menu_button_margin_right: 10,
  popup_menu_button_gap: 3,

  popup_menu_bg_color: '#ffffff',
  popup_menu_bg_color_hover: '#e0e0e0',
  popup_menu_border_color: '#bdbdbd',
  popup_menu_border_strokeWidth: 0.4,
  popup_menu_padding_top: 3,
  popup_menu_padding_bottom: 5,
  popup_menu_padding_left: 10,
  popup_menu_margin_left: 55,
  popup_menu_margin_right: 55,
  popup_menu_margin_top: 25,
  popup_menu_margin_bottom: 20,

  popup_menu_submenu_margin_left: 5,
  popup_menu_submenu_top_padding: 5,

  popup_menu_title_height: 20,
  popup_menu_title_padding_top: 4,
  popup_menu_title_padding_bottom: 4,
  popup_menu_title_font_weight: 600,
  popup_menu_title_color: '#2d2d2d',
  popup_menu_title_font_size: 12,

  popup_menu_item_width: 130,
  popup_menu_item_height: 20,
  popup_menu_item_padding_top: 4,
  popup_menu_item_font_size: 12,
  popup_menu_item_color: '#4a4a4a',

  shaped_conditions_code_prefix: 'bw',
  shaped_conditions_images_count: 10,

  strokeColorPrinting: '#000000',
}
