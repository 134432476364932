import settings from './settings'
class ContextMenu {
  box = null
  box_sub = null
  arrow =  null
  items = []
  sub_items = []
  border_color = settings.popup_menu_border_color
  bg_color = settings.popup_menu_bg_color
  hover_color = settings.popup_menu_bg_color_hover
  item_text_pad_top = settings.popup_menu_item_padding_top

  constructor(paper) {
    this.paper = paper
  }

  // Context Menu
  draw(option) {
    if('clearAllMultiAddSubMenus' in option){
      option.clearAllMultiAddSubMenus();
    }
    this.clear()

    this.box = this.drawBorder(option)

    var x = option.point.x //- 5
    var y = option.point.y //- 5
    var width = settings.popup_menu_item_width
    var height = settings.popup_menu_item_height

    y = this.drawTitle(x, y, option.title)

    for(var i=0; i<option.items.length; i++){

      var item = option.items[i]
      this.drawMenuItem({
        x: x, y: y, width: width, height: height, option: option,
        ...item
      })
      y += height
    }
  }

  drawTitle = (x, y, title_text) => {
    let height = settings.popup_menu_title_height + settings.popup_menu_title_padding_top + settings.popup_menu_padding_top
    var text_x = x + settings.popup_menu_padding_left
    var text_y = y + (height / 2) + settings.popup_menu_title_padding_top + settings.popup_menu_padding_top
    var title = new this.paper.PointText(new this.paper.Point(text_x, text_y  ))
    title.fillColor = settings.popup_menu_title_color
    title.content = title_text
    title.fontWeight = settings.popup_menu_title_font_weight
    title.fontSize = settings.popup_menu_title_font_size

    this.title = title

    return y + settings.popup_menu_title_height + settings.popup_menu_padding_top + settings.popup_menu_title_padding_top + settings.popup_menu_title_padding_bottom
  }

  drawBorder = (option) => {
    // Draw Border

    let offset = settings.popup_menu_border_strokeWidth // / 2
    var x = option.point.x - offset
    var y = option.point.y - offset
    var width = settings.popup_menu_item_width + offset + offset
    var height = settings.popup_menu_item_height + offset

    let total_height = height * option.items.length
    total_height += settings.popup_menu_padding_top
    total_height += settings.popup_menu_padding_bottom

    if(this.hasTitle(option)) {
      total_height += settings.popup_menu_title_height
      total_height += settings.popup_menu_title_padding_top
      total_height += settings.popup_menu_title_padding_bottom
    }

    return new this.paper.Shape.Rectangle({
      x,
      y,
      width,
      height: total_height,

      strokeWidth: settings.popup_menu_border_strokeWidth,
      strokeColor: this.border_color,
      fillColor: this.bg_color,
    });
  }

  drawItem = (option) => {
    // Reactangle
    var item_bg = new this.paper.Shape.Rectangle({
      x: option.x,
      y: option.y,
      width: option.width,
      height: option.height,
      fillColor: this.bg_color,
    });

    // Text
    var text_x = option.x + settings.popup_menu_padding_left
    var text_y = option.y + (option.height / 2) + this.item_text_pad_top
    var item_text = new this.paper.PointText(new this.paper.Point(text_x, text_y  ))
    item_text.fillColor = settings.popup_menu_item_color
    item_text.content = option.text
    item_text.fontSize = settings.popup_menu_item_font_size

    return {
      item_bg, item_text
    }
  }

  hasSubMenu(option) {
    return 'items' in option
  }

  hasTitle(option) {
    return 'title' in option
  }

  drawSubmenuAddButton(topLeft, menu_text){
    var submenuAdd = new this.paper.Raster('add-multiple')
    submenuAdd.position = topLeft.add(-7,0)
    submenuAdd.scale(0.5);
    // submenuArrow.set({ opacity: 0.65 })
    if(menu_text == 'Son'){
      this.submenuSonAddIcon = submenuAdd
    }
    else if(menu_text == 'Daughter'){
      this.submenuDaughterAddIcon = submenuAdd
    }
    else if(menu_text == 'Brother'){
      this.submenuBrotherAddIcon = submenuAdd
    }
    else if(menu_text == 'Sister'){
      this.submenuSisterAddIcon = submenuAdd
    }
    else if(menu_text == 'Unknown'){
      if(!this.submenuUnknown1){
        this.submenuUnknown1AddIcon = submenuAdd
      }
      else{
        this.submenuUnknown2AddIcon = submenuAdd
      }
    }
  }

  drawMenuItem = (option) => {

    let { item_bg, item_text } = this.drawItem(option)

    item_bg.onMouseEnter = () => {
      document.body.style.cursor = "pointer"
      item_bg.fillColor = this.hover_color
      this.clearSubMenus()
      if(this.hasSubMenu(option)) this.drawSubMenu(item_bg, option)
    }
    item_text.onMouseEnter = () => {
      document.body.style.cursor = "pointer"
      item_bg.fillColor = this.hover_color
      this.clearSubMenus()
      if(this.hasSubMenu(option)) this.drawSubMenu(item_bg, option)
    }

    item_bg.onMouseLeave = () => {
      document.body.style.cursor = "default"
      item_bg.fillColor = this.bg_color
      if(!this.hasSubMenu(option)) this.clearSubMenus()
    }
    item_text.onMouseLeave = () => {
      document.body.style.cursor = "default"
      item_bg.fillColor = this.bg_color
      if(!this.hasSubMenu(option)) this.clearSubMenus()
    }

    item_bg.onClick = () => {
      option.itemClick()
    }
    item_text.onClick = () => {
      option.itemClick()
    }

    if(this.hasSubMenu(option) && (option.text === 'Son' || option.text === 'Daughter' || option.text === 'Unknown' || option.text === 'Brother' || option.text === 'Sister')) {
      let rightCenter = item_bg.bounds.rightCenter
      let topLeft = new this.paper.Point(rightCenter.x, rightCenter.y)
      this.drawSubmenuAddButton(topLeft, option.text)
      item_bg.onMouseEnter = () => {
        document.body.style.cursor = "pointer"
        item_bg.fillColor = this.hover_color
        this.clearSubMenus(option.option.clearAllMultiAddSubMenus)
        option.option.clearAllMultiAddSubMenus();
        if(this.hasSubMenu(option)) this.drawSubMenu(item_bg, option)
      }
      item_text.onMouseEnter = () => {
        document.body.style.cursor = "pointer"
        item_bg.fillColor = this.hover_color
        this.clearSubMenus(option.option.clearAllMultiAddSubMenus)
        option.option.clearAllMultiAddSubMenus();
        if(this.hasSubMenu(option)) this.drawSubMenu(item_bg, option)
      }
    }
    else if(this.hasSubMenu(option)){
      let rightCenter = item_bg.bounds.rightCenter
      let topLeft = new this.paper.Point(rightCenter.x, rightCenter.y)
      this.drawSubmenuArrow(topLeft)
      item_bg.onMouseEnter = () => {
        document.body.style.cursor = "pointer"
        item_bg.fillColor = this.hover_color
        this.clearSubMenus(option.option.clearAllMultiAddSubMenus)
        option.option.clearAllMultiAddSubMenus();
        if(this.hasSubMenu(option)) this.drawSubMenu(item_bg, option)
      }
      item_text.onMouseEnter = () => {
        document.body.style.cursor = "pointer"
        item_bg.fillColor = this.hover_color
        this.clearSubMenus(option.option.clearAllMultiAddSubMenus)
        option.option.clearAllMultiAddSubMenus();
        if(this.hasSubMenu(option)) this.drawSubMenu(item_bg, option)
      }
    }

    // Memory
    this.items.push({
      bg: item_bg,
      text: item_text
    })

  }

  clearWhenOutside = (point) => {
    if(!this.insideContextMenu(point)) {
      this.clear()
    }
  }

  isInside = (point) => {
    if(this.box) {

      let topLeft = this.box.bounds.topLeft
      let bottomRight = this.box.bounds.bottomRight

      let insideWidth = point.x >= topLeft.x && point.x <= bottomRight.x
      let insideHeight = point.y >= topLeft.y && point.y <= bottomRight.y

      return ( insideWidth &&  insideHeight)
    }

    return false
  }

  clear = () => {
    for(var item of this.items) {
      item.bg.remove()
      item.text.remove()
    }

    if(this.box) this.box.remove()
    if(this.title) this.title.remove()

    if(this.arrow) this.arrow.remove()
    if(this.submenuSonAddIcon) this.submenuSonAddIcon.remove()
    if(this.submenuDaughterAddIcon) this.submenuDaughterAddIcon.remove()
    if(this.submenuBrotherAddIcon) this.submenuBrotherAddIcon.remove()
    if(this.submenuSisterAddIcon) this.submenuSisterAddIcon.remove()
    if(this.submenuUnknown1AddIcon) this.submenuUnknown1AddIcon.remove()
    if(this.submenuUnknown2AddIcon) this.submenuUnknown2AddIcon.remove()
    // this.clearMultiAddSubMenus()

    this.clearSubMenus()
  }

  /*
  | - - - - - - - - - - - - - - - -
  |  SUB MENU
  */

  drawSubmenuArrow = (topLeft) => {
    var submenuArrow = new this.paper.Raster('sub_menu_arrow')
    submenuArrow.position = topLeft.add(-7,0)
    submenuArrow.scale(0.8);
    // submenuArrow.set({ opacity: 0.65 })
    this.arrow = submenuArrow
  }

  drawSubMenu(parent, option) {

    if(option.items.length == 0) return

    let topRight = parent.bounds.topRight
    let topLeft = new this.paper.Point(topRight.x + settings.popup_menu_submenu_margin_left, topRight.y)

    this.clearSubMenus()
    if('clearAllMultiAddSubMenus' in option.option){
      option.option.clearAllMultiAddSubMenus()
    }
    // this.clearMultiAddSubMenus()

    option = { ...option, point: topLeft}
    this.box_sub = this.drawBorder(option)
    if('push_to_multi_add_boxes' in option.option){
      option.option.push_to_multi_add_boxes(this.box_sub)
    }

    var x = topLeft.x //- 5
    var y = topLeft.y //- 5
    var width = settings.popup_menu_item_width
    var height = settings.popup_menu_item_height

    y += settings.popup_menu_submenu_top_padding
    for(var i=0; i<option.items.length; i++){
      var item = option.items[i]
      this.drawSubMenuItem({
        x: x, y: y, width: width, height: height, main: option,
        ...item
      })
      y += height
    }
  }

  drawSubMenuItem = (option) => {

    let { item_bg, item_text } = this.drawItem(option)

    item_bg.onMouseEnter = () => {
      document.body.style.cursor = "pointer"
      item_bg.fillColor = this.hover_color
    }
    item_text.onMouseEnter = () => {
      document.body.style.cursor = "pointer"
      item_bg.fillColor = this.hover_color
    }

    item_bg.onMouseLeave = () => {
      document.body.style.cursor = "default"
      item_bg.fillColor = this.bg_color
    }
    item_text.onMouseLeave = () => {
      document.body.style.cursor = "default"
      item_bg.fillColor = this.bg_color
    }

    item_bg.onClick = () => {
      option.itemClick()
    }
    item_text.onClick = () => {
      option.itemClick()
    }

    // Memory
    this.sub_items.push({
      bg: item_bg,
      text: item_text
    })

    option.main.option.push_to_multi_add_submenus({
      bg: item_bg,
      text: item_text
    })

  }

  clearSubMenus = (clearAllMultiAddSubMenus) => {
    for(var item of this.sub_items) {
      item.bg.remove()
      item.text.remove()
    }

    if(this.box_sub) this.box_sub.remove()
  }


}

export default ContextMenu