import lodash from 'lodash'
import settings from './settings'

const fabric = window.fabric
class Scrollbars {
  barWidth = settings.scrollbar_thickness

  verticalContentStep = 0
  verticalScrollBar = null
  bar = null

  horizontalContentStep = 0
  horizontalScrollBar =  null
  horiHandleBar = null

  option = {}
  constructor(option={}) {
    // Add padding due to scrollbar width
    option.contentSizeHeight += this.barWidth
    option.contentSizeWidth += this.barWidth
    this.option = option

    // this.zoom = lodash.debounce(this.zoom, 100)
  }

  draw() {
    this.drawVerticalScrollbar()
    this.drawHorizontalScrollbar()
  }

  drawHorizontalScrollbar() {

    //Calculate Handle Size
    var pedigreeWidth =  this.option.pedigreeWidth + 2
    var pedigreeContentWidth = this.option.contentSizeWidth * this.option.pedigreeZoomValue

    var viewableRatio = pedigreeWidth / pedigreeContentWidth
    var scrollbarArea = pedigreeWidth - this.barWidth
    var handleSize = scrollbarArea * viewableRatio

    //Calculate Content Step
    var scrollContentSpace = pedigreeContentWidth - pedigreeWidth
    var scrollHandleSpace = scrollbarArea - handleSize
    this.horizontalContentStep = scrollContentSpace / scrollHandleSpace

    this.horizontalScrollBar = new fabric.Canvas('horizontal_scrollbar', {
      preserveObjectStacking: true,
      backgroundColor: settings.scrollbar_background_color
    })
    this.horizontalScrollBar.setDimensions({width: this.option.pedigreeWidth + 2 - this.barWidth , height: this.barWidth})

    this.horiHandleBar = new fabric.Rect({
      left: 0,
      top: 0,
      fill: settings.scrollbar_handle_color,
      width: handleSize,
      height: this.barWidth,
      lockMovementY: true,
      hasControls: false,
      hasBorders: false,
    });
    this.horizontalScrollBar.add(this.horiHandleBar);

    let self = this
    this.horiHandleBar.on("moving", function(e) {
      if (self.horiHandleBar.left < 0) self.horiHandleBar.left = 0
      if (self.horiHandleBar.left + self.horiHandleBar.width > self.horizontalScrollBar.width) {
        self.horiHandleBar.left = self.horizontalScrollBar.width - self.horiHandleBar.width
      }
      self.option.onHorizontalScroll(self.horiHandleBar.left * self.horizontalContentStep)
    })
  }


  drawVerticalScrollbar() {

    //Calculate Handle Size
    var pedigreeHeight =  this.option.pedigreeHeight + 2
    var pedigreeContentHeight = this.option.contentSizeHeight * this.option.pedigreeZoomValue

    var viewableRatio = pedigreeHeight / pedigreeContentHeight
    var scrollbarArea = pedigreeHeight - this.barWidth
    var handleSize = scrollbarArea * viewableRatio

    //Calculate Content Step
    var scrollContentSpace = pedigreeContentHeight - pedigreeHeight
    var scrollHandleSpace = scrollbarArea - handleSize
    this.verticalContentStep = scrollContentSpace / scrollHandleSpace

    this.verticalScrollBar = new fabric.Canvas('vertical_scrollbar', {
      preserveObjectStacking: true,
      backgroundColor: settings.scrollbar_background_color
    })
    this.verticalScrollBar.setDimensions({width: this.barWidth , height: pedigreeHeight})

    this.bar = new fabric.Rect({
      left: 0,
      top: 0,
      fill: settings.scrollbar_handle_color,
      width: this.barWidth,
      height: handleSize,
      lockMovementX: true,
      hasControls: false,
      hasBorders: false,
    });
    this.verticalScrollBar.add(this.bar);

    var self = this
    this.bar.on("moving", function(e) {
      if (self.bar.top < 0) self.bar.top = 0
      if ((self.bar.top + self.bar.height + self.barWidth) > self.verticalScrollBar.height) {
        self.bar.top = self.verticalScrollBar.height - self.bar.height - self.barWidth
      }
      self.option.onVerticalScroll(self.bar.top * self.verticalContentStep)
    })

  }

  syncPos(delta) {

    var x_move = delta.x / this.horizontalContentStep
    this.horiHandleBar.left = x_move
    this.horiHandleBar.setCoords()
    this.horizontalScrollBar.renderAll()

    var y_move = delta.y / this.verticalContentStep
    this.bar.top = y_move
    this.bar.setCoords()
    this.verticalScrollBar.renderAll()
  }

  zoom(pedigreeZoomValue) {
    this.option.pedigreeZoomValue = pedigreeZoomValue
    this.updateScrollBars()
  }

  updateScrollBars() {
    this.updateVerticalScrollBar()
    this.updateHorizontalScrollBar()

    this.toggleScrollBars()
  }

  toggleScrollBars() {
    // Show or Hide Vertical Scrollbar
    if(this.bar.height >= (this.verticalScrollBar.height - this.barWidth)) {
      this.bar.visible = false
    } else {
      this.bar.visible = true
    }
    this.bar.setCoords()
    this.verticalScrollBar.renderAll()

    //Show or hide Horizontal Scrollbar
    if(this.horiHandleBar.width >= (this.horizontalScrollBar.width - this.barWidth)) {
      this.horiHandleBar.visible = false
    } else {
      this.horiHandleBar.visible = true
    }
    this.horiHandleBar.setCoords()
    this.horizontalScrollBar.renderAll()
  }

  updateVerticalScrollBar() {

    //Calculate Handle Size
    var pedigreeHeight =  this.option.pedigreeHeight + 2
    var pedigreeContentHeight = this.option.contentSizeHeight * this.option.pedigreeZoomValue

    var viewableRatio = pedigreeHeight / pedigreeContentHeight
    if(viewableRatio > 1) viewableRatio = 1 //Make sure ratio wont exeed 1
    var scrollbarArea = pedigreeHeight - this.barWidth
    var handleSize = scrollbarArea * viewableRatio

    this.bar.set({height: handleSize})

    // when tail goes outside then move up
    var outside = (this.bar.top + handleSize) - scrollbarArea
    if (outside > 0) {
      var new_top = this.bar.top - outside
      this.bar.set({top: new_top})
    }

    // This two make sure the bar is updated
    this.bar.setCoords()
    this.verticalScrollBar.renderAll()

  }

  updateHorizontalScrollBar() {

    //Calculate Handle Size
    var pedigreeWidth =  this.option.pedigreeWidth + 2
    var pedigreeContentWidth = this.option.contentSizeWidth * this.option.pedigreeZoomValue

    var viewableRatio = pedigreeWidth / pedigreeContentWidth
    if(viewableRatio > 1) viewableRatio = 1 //Make sure ratio wont exeed 1
    var scrollbarArea = pedigreeWidth - this.barWidth
    var handleSize = scrollbarArea * viewableRatio

    this.horiHandleBar.set({width: handleSize})

    // when tail goes outside then move left
    var outside = (this.horiHandleBar.left + handleSize) - scrollbarArea
    if (outside > 0) {
      var new_left = this.horiHandleBar.left - outside
      this.horiHandleBar.set({left: new_left})
    }


    // This two make sure the bar is updated
    this.horiHandleBar.setCoords()
    this.horizontalScrollBar.renderAll()

  }

}

export default Scrollbars
