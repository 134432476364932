import React, { useState, Component } from 'react';
import { connect } from "react-redux";
// import NavTop from "../nav-top";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  Controls,
} from 'react-flow-renderer';

import Sidebar from './Sidebar';

import './provider.css';
import "./index.css";


const onLoad = (reactFlowInstance) =>
  console.log('flow loaded:', reactFlowInstance);


const initialElements = [
  {
    id: 'provider-1',
    type: 'input',
    data: { label: 'Node 1' },
    position: { x: 250, y: 5 },
  },
  { id: 'provider-2', data: { label: 'Node 2' }, position: { x: 100, y: 100 } },
  { id: 'provider-3', data: { label: 'Node 3' }, position: { x: 400, y: 100 } },
  { id: 'provider-4', data: { label: 'Node 4' }, position: { x: 400, y: 200 } },
  {
    id: 'provider-e1-2',
    source: 'provider-1',
    target: 'provider-2',
    animated: true,
  },
  { id: 'provider-e1-3', source: 'provider-1', target: 'provider-3' },
];

const setInputData = () =>
{

}


const ProviderFlow = () => {
	
  const [selected, setSelected] = useState("Foo");
  const [inputData, setInputData] = useState(null);
  const [data, setData] = useState({"promandCoupleID": null,"members": [], "relationships": []})
  const [elements, setElements] = useState(initialElements);
  const onConnect = (params) => setElements((els) => addEdge(params, els));
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));
	const onElementClick = (event, element) => {
		console.log('clicked', element);
		setSelected(element);
	}
	const mouseMove = (event, element) => {
		console.log('moved->', element);
	}
  return (
	<React.Fragment>
	{/* <NavTop history={this.props.history} /> */}
	<br></br>
	<br></br>
	<h1 id="probandHeader"> Proband ID </h1>
    <div className="providerflow">
      <ReactFlowProvider>
        <div style={{ height: "1500px", width:"800px"}} className="reactflow-wrapper">
          <ReactFlow
		  	 onNodeDragStop={mouseMove}
            elements={elements}
            onElementClick={onElementClick}
            onConnect={onConnect}
            onElementsRemove={onElementsRemove}
            onLoad={onLoad}
          >
            <Controls />
          </ReactFlow>
        </div>
		
        <Sidebar ned={selected} data={data} setData={setData}/>
		
      </ReactFlowProvider>
    </div>
	<div stlye="position: fixed; bottom: 0;width: 100%;">
		<h3>Input JSON</h3>
		<textarea id="jsonInputData" onChange={setInputData} rows="8" cols="50"></textarea>
		</div>
	</React.Fragment>
  );
};

export default ProviderFlow;