export default class TreeBuilder {
  nodes = {};
  family_tree = {};

  constructor(family_tree = {}) {
    this.family_tree = family_tree;
  }

  get_history(rkey) {
    if(rkey in this.family_tree.history)
      return this.family_tree.history[rkey]
    else
      return null
  }

  get_diseases(rkey) {
    if(rkey in this.family_tree.history_diseases)
      return this.family_tree.history_diseases[rkey]
    else
      return null
  }

  get_gene_tests(rkey) {
    if(rkey in this.family_tree.history_genes)
      return this.family_tree.history_genes[rkey]
    else
      return null
  }

  get_health_data(rkey) {
    return {
      history: this.get_history(rkey),
      history_diseases: this.get_diseases(rkey),
      history_gene_tests: this.get_gene_tests(rkey)
    }
  }

  get_partners(rkey) {
    let partners = this.family_tree.partners

    if(rkey in partners)
      return partners[rkey]

    return []
  }

  get_sons_daughters(rkey) {
    let sons_daughters = this.family_tree.sons_daughters

    if(rkey in sons_daughters)
      return sons_daughters[rkey]

    return []
  }

  rotateLeft(arr, nb) {
    var leftPart = arr.splice(0, nb);
    arr.push(...leftPart);        
  }

  sort_profiles_with_twins(profiles, direction='none') {
    if(direction == 'none')  return profiles;

    let twins = profiles.filter(profile => profile.twin_set !== null)
    if(twins.length == 0) return profiles;

    // All twins to the right
    let sorted_profiles = profiles.sort((a, b) => {
      // if(a.twin_set ==  null || b.twin_set == null) return -1

      if(a.twin_set < b.twin_set) return -1
      if(a.twin_set > b.twin_set) return 1
      return 0;
    })

    // Put all twins to the left
    if(direction == 'left') {
      sorted_profiles = sorted_profiles.reverse()
    }

    // Sort identical twin profiles
    let identical_last_index = 0;
    let fraternal_last_index = 0;
    let fragmented_identical = false;
    let fragmented_fraternal = false;
    let has_identical_twins = false;
    let has_fraternal_twins = false;
    for(var i = 0; i < sorted_profiles.length; i++) {
      var profile = sorted_profiles[i]
      if(profile.twin_type === 'identical' || profile.twin_type === 'fraternal') {
        if(i > 0 && (i - 1) !== identical_last_index) fragmented_identical = true;
        if(i > 0 && (i - 1) !== fraternal_last_index) fragmented_fraternal = true;
        identical_last_index = i
        fraternal_last_index = i
        has_identical_twins= true;
        has_fraternal_twins = true;
      }
    }

    var should_sort_identical = has_identical_twins && fragmented_identical;
    if(should_sort_identical) {
      sorted_profiles = sorted_profiles.sort((a, b) => {
        if(a.twin_set == null && b.twin_set == null) return 0

        // Push identical to leftmost
        if(a.twin_type <  b.twin_type) return 1
        if(a.twin_type > b.twin_type) return -1
        return 0;
      })
    }

    var should_sort_fraternal = has_fraternal_twins && fragmented_fraternal;
    if(should_sort_fraternal) {
      sorted_profiles = sorted_profiles.sort((a, b) => {
        if(a.twin_set == null && b.twin_set == null) return 0

        // Push fraternal to leftmost
        if(a.twin_type <  b.twin_type) return 1
        if(a.twin_type > b.twin_type) return -1
        return 0;
      })
    }

    let proband = sorted_profiles.find(person => person.is_proband === true)
    

    sorted_profiles = Object.values(sorted_profiles)
    let firstProfileTwinSet = sorted_profiles[0].twin_set

    if((has_identical_twins || has_fraternal_twins) && (proband !== undefined && proband !== null)){
      while(firstProfileTwinSet !== proband.twin_set){
        this.rotateLeft(sorted_profiles, 1)
        firstProfileTwinSet = sorted_profiles[0].twin_set
      }
    }

    // if (has_identical_twins && isProbandLast){
    //   sorted_profiles.reverse();
    // }


    return sorted_profiles;

  }
}