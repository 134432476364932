import { createPerson,
  reDrawColoredConditions,
  reDrawShapedConditions,
  updateDiseaseInfo,
  updateDisplayName,
  updateGenderLabel,
  updateGeneticTest,
  clearNode,
  drawLegend,
  drawAncestry,
  legendDragging,
  peopleLabels,
  linesForMaternal,
  linesForPaternal,
  redrawSubtext } from './person'
import ContextMenu from './context-menu'
import MenuButton from './menu-button'

import settings from './settings'
import { getAdoptedOption } from './helpers'
import colors_255 from '../../assets/json/colors-255.json'

import Cookie from 'js-cookie'

class People {

  family_tree = null

  go_left = null
  go_right = null
  persons = []
  paper = null
  selectedPerson = null

  // contextMenu = { items: []}

  contentSize = null // width, height

  menu_button = null
  siblings_menu = null
  partners_menu = null
  parents_menu = null
  children_menu = null
  all_multi_add_sub_menus = []
  multi_add_sub_boxes = []
  // colored_conditions_options = settings.colored_conditions_options

  shaped_conditions_code_prefix = settings.shaped_conditions_code_prefix
  shaped_conditions_images_count = settings.shaped_conditions_images_count

  condition_type = 'color' // shape, color
  printing = false
  options = {}

  constructor(paper, options = {}, family_tree) {
    this.paper = paper

    this.options = options

    this.family_tree = family_tree

    // This is temporary flag to display either colors or shape
    if('condition_type' in options) this.condition_type = options.condition_type
    if('render_for_print' in options && options.render_for_print == true) this.printing = options.render_for_print

    this.go_left = (new this.paper.Point(settings.person_width + settings.person_space_around, 0)).multiply(-1)
    this.go_right = new this.paper.Point(settings.person_width + settings.person_space_around, 0)
    this.go_top = (new this.paper.Point(0, settings.person_width + settings.person_space_around)).multiply(-1)
    this.go_bottom = (new this.paper.Point(0, settings.person_width + settings.person_space_around))
    console.log(this.printing)
    // Initialize Context Menu
    this.context_menu = new ContextMenu(paper)

    this.siblings_menu = new ContextMenu(paper)
    this.partners_menu = new ContextMenu(paper)

    this.parents_menu = new ContextMenu(paper)
    this.children_menu = new ContextMenu(paper)

    this.menu_button = new MenuButton(paper)

    this.contentSize = new this.paper.Size(0,0)

    // Select node
    this.selectNodeFromOption(options);

  }

  selectNodeFromOption({ selectedNode }) {
    let self = this;
    setTimeout(() => {
      if(selectedNode !== undefined) {
        for (var person of this.persons)
          if(person.data.profile.rkey == selectedNode.data.profile.rkey) {
            self.personClick(undefined, person);
          }
      }
    },100);
  }

  calculateContentSize() {
    var max_x = 0
    var max_y = 0
    for (var person of this.persons) {
      // console.log(person.shape.bounds.bottomRight)

      if (person.shape.bounds.bottomRight.x > max_x) {
        max_x = person.shape.bounds.bottomRight.x
      }

      if (person.shape.bounds.bottomRight.y > max_y) {
        max_y = person.shape.bounds.bottomRight.y
      }

    }

    this.contentSize.width = max_x + this.go_right.x
    this.contentSize.height = max_y + this.go_bottom.y

    return this.contentSize
  }

  updatePersonProfile(person, profile) {
    updateDisplayName(person, profile)
    updateGenderLabel(person, profile)
    updateGeneticTest(person, profile)
    // person.labels[0].content = profile.firstName
    person.data = Object.assign({}, person.data, {profile})
    this.persons[person.index-1] = person
  }

  refreshNode(person) {
    let pedigree_person = this.persons[person.index-1];
    clearNode(pedigree_person)

    let index = pedigree_person.index
    let loc = pedigree_person.loc
    let data = pedigree_person.data
    let proband = data.profile.is_proband
    let gender = data.profile.gender
    let node_type = pedigree_person.node_type

    // Futher implement performance and memory optimization

    let updated_person = this.drawPerson({
        index,
        loc,
        gender, proband,
        data, node_type
    })

    this.persons[person.index-1] = updated_person

    this.highlight(updated_person)
  }

  deleteNode(person) {
    let pedigree_person = this.persons[person.index-1];
    clearNode(pedigree_person);
    this.persons[person.index-1] = null;
  }

  redrawSubtext(){
    redrawSubtext();

  }

  updatePersonHealth(person, history_diseases) {

    if(this.condition_type == 'color') {
      let history_diseases_new = history_diseases;
      if(history_diseases_new){
        for(let color of this.family_tree.hidden_disease_colors){
          history_diseases_new = history_diseases_new.filter(disease => {
            if(disease.disease_id && disease.disease_id !== color.disease_id){
              return disease
            }else if( color.umls_id && disease.disease_id !== color.umls_id){
              return disease
            }
          })
        }
      }
      var conditions = this.getDiseaseConditionsByColor(history_diseases_new)
      reDrawColoredConditions(this.paper, person, conditions, this.family_tree.hidden_disease_colors, this.getColor)
    } else {
      var conditions = this.getDiseaseConditionsByShape(history_diseases)
      reDrawShapedConditions(this.paper, person, conditions)
    }

    updateDiseaseInfo(person, history_diseases)

    // Update attached data
    let health = Object.assign({}, person.data.health, { history_diseases })
    person.data = Object.assign({}, person.data, { health })
    this.persons[person.index-1] = person

  }

  getPeopleLabels(){
    return peopleLabels;
  }

  renderLegend(displayLegend, history_diseases, showNotes, pedigreeNotes){
    drawLegend(displayLegend, this.paper, history_diseases, this.getColor, this.family_tree, this.options.onLegendEyeClick, showNotes, pedigreeNotes, this.options.onPedigreeNotesSave)
  }

  isLegendDragging(){
    return legendDragging;
  }

  getLinesForMaternal(){
    return linesForMaternal;
  }

  getLinesForPaternal(){
    return linesForPaternal;
  }

  renderAncestry(displayAncestry, maternal_ancestry, paternal_ancestry, mother_pos, father_pos, most_right_pos, most_left_pos, most_top_y){
    drawAncestry(displayAncestry, maternal_ancestry, paternal_ancestry, mother_pos, father_pos, most_right_pos, most_left_pos, most_top_y, this.paper)
  }

  clear() {
    this.persons = []
    this.paper.project.activeLayer.removeChildren();
    this.clearMenus();
  }

  rc = (min, max) => {
    return Math.floor(Math.random() * (max-min + 1)) + min
  }

  getRandomOptions(option) {

    let random_option = {
      deceased: Math.random() >= 0.80,
      inheritance_pattern: [
        (Math.random() >= 0.90 ? 'autosomal_recessive_inheritance': '') //carrier
      ],
    }

    let non_blood_relative = ('bloodRelative' in option && option.bloodRelative == false)
    if(non_blood_relative == false) {
      random_option.adopted = Math.random() >= 0.5
    }

    if('is_root' in option && option.is_root && Math.random() >= 0.60 ) {
      random_option.pregnancy = { type: ['normal','stillbirth', 'miscarriage', 'terminated'][Math.floor(Math.random() * 4)], }
    }

    return random_option

  }

  getColor(digit, family_tree) {
    let colorId = null
    colorId = digit % 200 // 200 different colors

    let allDiseasesOnPedigree = [];

    let history_diseases = [];

    try{
      history_diseases = Object.entries(this.family_tree.history_diseases)
    }
    catch(error){
      history_diseases = Object.entries(family_tree.history_diseases)
    }

    for(let item of history_diseases){
      let diseases = item[1];
      for(let disease of diseases){
        allDiseasesOnPedigree.push(disease)
      }
    }

    allDiseasesOnPedigree.sort((a, b) => a.disease_id - b.disease_id);

    const key = 'disease_id';

    const uniqueDiseases = [...new Map(allDiseasesOnPedigree.slice().reverse().map(item =>
    [item[key], item])).values()];

    uniqueDiseases.sort((a, b) => a.id - b.id);

    let firstTenDiseases = uniqueDiseases.slice(0, 10)
    let digitOnFirstTen = firstTenDiseases.find(disease => disease.disease_id === digit)

    if(digitOnFirstTen){
      let index = firstTenDiseases.indexOf(digitOnFirstTen)
      switch(index){
        case 0:
          return '#0066cc';
        case 1:
          return '#cc0000';
        case 2:
          return '#009933';
        case 3:
          return '#ffcc00';
        case 4:
          return '#990099';
        case 5:
          return '#ff6600';
        case 6:
          return '#33ccff';
        case 7:
          return '#ff6699';
        case 8:
          return '#00ff66';
        case 9:
          return '#cc99ff';
      }
    }
    else{
      let color = colors_255.find(item => item.colorId === colorId)
      // color id for White or color undefined
      if(colorId === 15 || typeof(color) === 'undefined') {
        return '#9370DB';
      }
      return color.hexString;
    }

  }


  getDiseaseConditionsByColor(history_diseases) {
    if(history_diseases == null) return []

    var colors = []
    for(var item of history_diseases) {
      var color_value = this.getColor(item.disease_id)
      colors.push(color_value)
    }
    return colors
  }

  getDiseaseConditionsByShape(history_diseases) {

    if(history_diseases == null) return []

    let prefix = settings.shaped_conditions_code_prefix // bw
    let count = settings.shaped_conditions_images_count // 10

    //bw-1 to bw-10
    let shape_options = [...Array(count).keys()].map( i => `${prefix}-${i + 1}`)
    let shapes = []
    for(var item of history_diseases) {
      var i = this.getRemainder(item.disease_id)
      var code = shape_options[i-1]
      shapes.push(code)
    }

    return shapes
  }

  drawPersonAt(slot = {}, option = {}) {
    // Prevent double render
    let {rkey} = option.data.profile;
    if(this.isRendered(rkey)) return;
    // - - -

    let loc = new this.paper.Point(slot.x, slot.y)
    let locnew = this.options.nodeLocations.find(node => node.id === option.data.profile.id)
    if(Cookie.get("famgenix_active_smartdraw") == 'flux' && locnew){
      loc = new this.paper.Point(locnew.position.x, locnew.position.y)
    }
    let index = this.persons.length + 1
    let default_option = { loc, index }
    default_option = Object.assign({}, default_option, option)
    let person = this.drawPerson(default_option)
    this.persons.push(person)

  }

  isRendered(rkey) {
    return this.persons.findIndex(person => person.data.profile.rkey === rkey) > -1;
  }

  drawPerson(option ={}) {
    // option.index = this.persons.length + 1

    let default_option = {
      gender: null,
      conditions: [],
      condition_type: this.condition_type, // shape, color
      hidden_disease_colors: this.family_tree.hidden_disease_colors,
      getColor: (digit) => this.getColor(digit, this.family_tree),
      printing: this.printing
    }


    if(this.condition_type == 'color') {
      let history_diseases = option.data.health.history_diseases
      if(history_diseases){
        for(let color of this.family_tree.hidden_disease_colors){
          history_diseases = history_diseases.filter(disease =>{
            if(color.disease_id && disease.disease_id !== color.disease_id){
                return disease
            }else if(color.umls_id && disease.disease_id !== color.umls_id ){
                return disease
              }
            })
          }
        }
      default_option.conditions =this.getDiseaseConditionsByColor(history_diseases)
    } else {
      default_option.conditions = this.getDiseaseConditionsByShape(option.data.health.history_diseases)
    }

    default_option = Object.assign({}, default_option, option)

    // Set adopted
    let profile = option.data.profile;
    let adopted_options = getAdoptedOption(profile);
    if(adopted_options !== undefined) default_option = Object.assign({}, default_option, adopted_options);

    // Set Deceased
    if(profile.is_dead) default_option.deceased = true

    // Clean gender
    if(default_option.gender == null) default_option.gender = 'u'

    // Set carier
    if(profile.carrier) default_option.inheritance_pattern = ['autosomal_recessive_inheritance']

    // Set Pregnancy
    if(profile.pregnancy) default_option.pregnancy = true

    let person = createPerson(this.paper, default_option)

    // Attach additional information
    person.data = option.data
    person.node_type = option.node_type

    // Attached click event
    person.shape.onClick = (event) => {
      this.personClick(event, person)
    }

    // Change to hand cursor
    person.shape.onMouseEnter = function(event) {
      if(document.body.style.cursor != "grab") {
        document.body.style.cursor = "pointer"
      }
    }

    // Revert to default arrow
    person.shape.onMouseLeave = function(event) {
      if(document.body.style.cursor != "grab") {
        document.body.style.cursor = "default"
      }
    }

    return person
  }

  personClick = (event, person) => {
    let personProfile = person.data.profile
    // Temporarily skip other partner of proband's parent
    if(personProfile.rkey == null) return



    if(this.selectedPerson == null ||
      this.selectedPerson.data.profile.rkey != personProfile.rkey) {
        // Click once, highlight the node

        this.selectedPerson = person
        this.clearMenus()
        this.drawMenuButton(person)
        this.clearHighlights()
        this.highlight(person)

        // trigger the callback
        if('onNodeClick' in this.options) {
          this.options.onNodeClick(person)
        }

    } else {
      // Click the same node twice, show the menu
      // If is no children or infertility node, don't draw menus when same node clicked
      if(!personProfile.is_no_children_node && !personProfile.is_infertility_node){
        this.clearMenus()
        this.drawMenus(person)
      }

    }


    // Left Click
    // if (event.event.button == 0) {

    // }

  }

  clearHighlights() {
    for (var person of this.persons) {
      if(person && 'highlight' in person) person.highlight.visible = false
    }
  }

  highlight(person) {
    if('highlight' in person) person.highlight.visible = true
  }

  drawMenuButton(person) {
    let bounds = person.shape.bounds
    let topLeft = bounds.topLeft

    // let total_menu_width = settings.popup_menu_item_width + settings.popup_menu_margin_right
    // topLeft = topLeft.add(total_menu_width * -1, 0)
    topLeft = new this.paper.Point(topLeft.x, topLeft.y)
    let self = this
    let is_no_children_or_infertility_node = person.data.profile.is_no_children_node || person.data.profile.is_infertility_node
    if((is_no_children_or_infertility_node) || (!this.canAddParents(person) && !this.canAddSibling(person) && !this.canAddPartner(person) && !this.canAddChildren(person) && this.options.readOnly === false)){
      this.menu_button.draw({
        point: topLeft,
        onEditButtonClick: (event) => {
          self.clearMenus()
          if('onNodeEditClick' in this.options) {
            this.options.onNodeEditClick(person)
          }
        },
        drawAddButton: false,
        is_no_children_or_infertility_node
      })
    }
    else{
      this.menu_button.draw({
        point: topLeft,
        onAddButtonClick: (event) => {
          self.drawMenus(person)
        },
        onEditButtonClick: (event) => {
          self.clearMenus()
          if('onNodeEditClick' in this.options) {
            this.options.onNodeEditClick(person)
          }
        },
        drawAddButton: true,
        is_no_children_or_infertility_node
      })
    }

  }

  clearMenuButton(){
    this.menu_button.clear();
  }

  drawMenus(person) {
    /* Cannot add parent for this version */

    if(this.canAddParents(person) && this.options.readOnly === false) this.drawParentsMenu(person);
    if(this.canAddSibling(person) && this.options.readOnly === false )this.drawSiblingsMenu(person);
    if(this.canAddPartner(person) && this.options.readOnly === false )this.drawPartnersMenu(person);
    if(this.canAddChildren(person)&& this.options.readOnly === false ) this.drawChildrenMenu(person);

  }

  canAddParents(person) {
    // Member can add parents if it does not have yet one
    let { profile } = person.data;
    let node_type = person.node_type
    let excluded_node_types = [
      'partner',
      'proband_parent_other_partner'
    ];
    return !excluded_node_types.includes(node_type) && profile.hasOwnProperty('father_id') === false;
  }

  canAddSibling(person) {
    let { profile } = person.data;
    let node_type = person.node_type
    let allowed_node_types = [
      'proband',
      'paternal_uncle_aunt',
      'maternal_uncle_aunt',
      'father',
      'mother',
      'proband_sibling',
      'proband_half_sibling',
      'son_daughter',
      'paternal_grandfather',
      'paternal_grandmother',
      'maternal_grandfather',
      'maternal_grandmother',
      'immediate_son_daughter',
      'immediate_partner',
    ]

    if(allowed_node_types.includes(node_type)) {
      return profile.hasOwnProperty('father_id')
    }

    return false;
  }

  canAddPartner(person) {
    let { profile } = person.data;
    let node_type = person.node_type
    let allowed_node_types = [
      'proband',
      'paternal_uncle_aunt',
      'maternal_uncle_aunt',
      'father',
      'mother',
      'proband_sibling',
      'proband_half_sibling',
      'son_daughter',
      'paternal_grandfather',
      'paternal_grandmother',
      'maternal_grandfather',
      'maternal_grandmother',
      'immediate_partner'
    ]

    // return allowed_node_types.includes(node_type);
    //investigate the nodes allowed to add partners
    // return profile.hasOwnProperty('father_id');
    return profile.is_blood_related_to_proband;
  }

  canAddChildren(person) {
    let profile = person.data.profile
    let children = Object.values(this.family_tree.profile)
    children = children.filter(node => node.father_id === profile.id || node.mother_id === profile.id)
    children = children.filter(node => node.is_no_children_node || node.is_infertility_node)
    if(children.length > 0){
      return false;
    }
    let node_type = person.node_type
    let allowed_node_types = [
      'proband_sibling',
      'proband_half_sibling',
      'son_daughter',
      'partner',
      'immediate_partner',
      'immediate_ancestor',
      'immediate_son_daughter'
    ]

    return allowed_node_types.includes(node_type);
  }

  drawSiblingsMenu(person) {
    let bounds = person.shape.bounds
    let topRight = bounds.topRight

    topRight = topRight.add(settings.popup_menu_margin_left, 0)

    let menu_top_right = new this.paper.Point(topRight.x, topRight.y)

    let items = []
    if('menus' in this.options && 'sibling_menu_items' in this.options.menus) {
      // Override item Click and pass person object
      let self = this;
      items = this.options.menus.sibling_menu_items.map(item => {
        var subItems = []
        if('items' in item) {
          subItems = item.items.map(sub => ({
            text: sub.text,
            itemClick: () => {
              self.clearMenus();
              this.clearMenuButton();
              sub.itemClick(person);
            }
          }))
        }

        let menu_item = {}
        menu_item.text = item.text
        if(subItems.length > 0) {
          menu_item.itemClick = () => {
            self.clearMenus();
            item.itemClick(person);
          }
          menu_item.items = subItems
        } else {
          menu_item.itemClick = () => {
            self.clearMenus();
            item.itemClick(person);
          }
        }

        return menu_item;
          
      })

    }

    this.siblings_menu.draw({
      point: menu_top_right,
      title: 'Siblings',
      items: items,
      push_to_multi_add_submenus: (sub_menu) => this.all_multi_add_sub_menus.push(sub_menu),
      push_to_multi_add_boxes: (box) => this.multi_add_sub_boxes.push(box),
      clearAllMultiAddSubMenus: () => this.clearAllMultiAddSubMenus()
    })
  }

  drawPartnersMenu(person) {

    let bounds = person.shape.bounds
    let topLeft = bounds.topLeft
    let topCenter = bounds.topCenter
    let topRight = bounds.topRight


    let total_menu_width = settings.popup_menu_item_width + settings.popup_menu_margin_right
    let half_menu_width = settings.popup_menu_item_width / 2

    topLeft = topLeft.add(total_menu_width * -1, 0)
    topCenter = topCenter.add(half_menu_width * -1, -170)
    topRight = topRight.subtract(half_menu_width * -1, 0)

    let menu_top_left = new this.paper.Point(topLeft.x, topLeft.y)
    let menu_top_center = new this.paper.Point(topCenter.x, topCenter.y)
    let menu_top_right = new this.paper.Point(topRight.x, topRight.y)

    let items = []
    if('menus' in this.options && 'partner_menu_items' in this.options.menus) {
      // Override item Click and pass person object
      let self = this;
      items = this.options.menus.partner_menu_items.map(item => ({
          text: item.text,
          itemClick: () => {
            self.clearMenus();
            this.clearMenuButton();
            item.itemClick(person);
          }
      }))
    }
    if(person.shape.position.x < 247 && this.canAddSibling(person)){
      this.partners_menu.draw({
        point: menu_top_center,
        title: 'Partner',
        items: items
      })
    }
    else if(person.shape.position.x < 247 && this.canAddParents(person)){
      this.partners_menu.draw({
        point: menu_top_right,
        title: 'Partner',
        items: items
      })
    }
    else{
      this.partners_menu.draw({
        point: menu_top_left,
        title: 'Partner',
        items: items
      })
    }
  }

  drawParentsMenu(person) {

    let bounds = person.shape.bounds
    let topCenter = bounds.topCenter

    let parent_menu_height = 77 + settings.popup_menu_margin_bottom
    let half_menu_width = settings.popup_menu_item_width / 2
    topCenter = topCenter.add(half_menu_width * -1, parent_menu_height * -1)

    let menu_top_right = new this.paper.Point(topCenter.x, topCenter.y)

    let items = []
    if('menus' in this.options && 'parent_menu_items' in this.options.menus) {
      // Override item Click and pass person object
      let self = this;
      items = this.options.menus.parent_menu_items.map(item => ({
          text: item.text,
          itemClick: () => {
            self.clearMenus();
            this.clearMenuButton();
            item.itemClick(person);
          }
      }))
    }

    this.parents_menu.draw({
      point: menu_top_right,
      title: 'Parents',
      items: items
    })

    // this.parents_menu.draw({
    //   point: menu_top_right,
    //   title: 'Parents',
    //   items: [
    //     {text: 'Father', itemClick: () => this.onItemClick()},
    //     {text: 'Mother', itemClick: () => this.onItemClick() },
    //   ]
    // })
  }

  clearAllMultiAddSubMenus(){
    for(let sub of this.all_multi_add_sub_menus){
      sub.bg.remove()
      sub.text.remove()
    }

    for(let box of this.multi_add_sub_boxes){
      box.remove();
    }
  }

  drawChildrenMenu(person) {

    let bounds = person.shape.bounds
    let bottomCenter = bounds.bottomCenter

    let half_menu_width = settings.popup_menu_item_width / 2
    bottomCenter = bottomCenter.add(half_menu_width * -1, settings.popup_menu_margin_top)

    let menu_bottom_right = new this.paper.Point(bottomCenter.x, bottomCenter.y)

    let children = Object.values(this.family_tree.profile)
    children = children.filter(node => node.father_id === person.data.profile.id || node.mother_id === person.data.profile.id)

    let items = []
    if('menus' in this.options && 'children_menu_items' in this.options.menus) {

      // Override item Click and pass person object
      let self = this;
      items = this.options.menus.children_menu_items.map(item => {

        var subItems = []
        if('items' in item) {
          subItems = item.items.map(sub => ({
            text: sub.text,
            itemClick: () => {
              self.clearMenus();
              this.clearMenuButton();
              sub.itemClick(person);
            }
          }))
        }

        let menu_item = {}
        menu_item.text = item.text
        if(subItems.length > 0) {
          menu_item.itemClick = () => {
            self.clearMenus();
            item.itemClick(person);
          }
          menu_item.items = subItems
        } else {
          menu_item.itemClick = () => {
            self.clearMenus();
            item.itemClick(person);
          }
        }

        return menu_item;
      })

    }

    if (children.length > 0) {
        items.splice(3, 1);
        items.splice(3, 1);
    }

    this.children_menu.draw({
      point: menu_bottom_right,
      title: 'Children',
      items: items,
      push_to_multi_add_submenus: (sub_menu) => this.all_multi_add_sub_menus.push(sub_menu),
      push_to_multi_add_boxes: (box) => this.multi_add_sub_boxes.push(box),
      clearAllMultiAddSubMenus: () => this.clearAllMultiAddSubMenus()
    })


    // items = [
    //   {text: 'Son', itemClick: () => this.onItemClick() },
    //   {text: 'Daughter', itemClick: () => this.onItemClick() },
    //   {
    //     text: 'Pregnancy', itemClick: () => this.onItemClick(),
    //     items: [
    //       {text: 'Pregnancy', itemClick: () => this.onItemClick() },
    //       {text: 'Stillbirth', itemClick: () => this.onItemClick() },
    //       {text: 'Miscariage', itemClick: () => this.onItemClick() },
    //       {text: 'Termination', itemClick: () => this.onItemClick() },
    //     ]
    //   },
    //   {text: 'Infertility', itemClick: () => this.onItemClick() },
    //   {text: 'No Children (Choice)', itemClick: () => this.onItemClick() },
    // ]
  }

  onItemClick(){
    // this.clearMenus()
  }

  insideSelectedPerson(point) {
    if (this.selectedPerson ==  null) return

    let bounds = this.selectedPerson.shape.bounds
    let topLeft = bounds.topLeft
    let bottomRight = bounds.bottomRight

    let insideWidth = point.x >= topLeft.x && point.x <= bottomRight.x
    let insideHeight = point.y >= topLeft.y && point.y <= bottomRight.y

    return ( insideWidth &&  insideHeight)
  }

  isInside(point) {
    let a = this.siblings_menu.isInside(point)
    let b = this.partners_menu.isInside(point)
    let c = this.parents_menu.isInside(point)
    let d = this.children_menu.isInside(point)
    let e = this.menu_button.isInside(point)
    let f = this.insideSelectedPerson(point)

    return (a || b || c || d || e || f)

  }

  clearMenuWhenOutside(point, dragging) {

    if(this.isInside(point) == false && dragging) {
      return false;
    }
    else if(this.isInside(point) == false && !dragging){
      this.clearMenus()
      this.unselectPerson()
      return true;
    }

    return false;

  }

  clearAll(){
    this.clearMenus();
    this.unselectPerson();
  }

  unselectPerson() {
    this.clearHighlights();
    this.menu_button.clear();
    this.selectedPerson = null;
  }

  clearMenus() {

    this.siblings_menu.clear()
    this.partners_menu.clear()
    this.parents_menu.clear()
    this.children_menu.clear()

  }


}

export default People
