import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.handleClose     = this.handleClose.bind(this);
    this.onClickAway     = this.onClickAway.bind(this);
    this.handleOnkeyDown = this.handleOnkeyDown.bind(this);

    this.modalRef  = React.createRef();
    this.buttonRef = React.createRef();
  }

  componentDidMount() {
    // lock scrolling for accessibility
    document.querySelector("html").classList.toggle("utility-lock-scrolling");

    // set default focus to close button for accessibility
    if(this.buttonRef && this.buttonRef.current) this.buttonRef.current.focus();
  }

  componentWillUnmount() {
    // un-lock scrolling for accessibility
    document.querySelector("html").classList.toggle("utility-lock-scrolling");
  }

  handleClose() {
    this.props.onClose();
  }

  handleOnkeyDown(event) {
    // handle the esc key being pressed down and close the modal for accessibility
    return event.keyCode === 27 && this.handleClose();
  }

  onClickAway(event) {
    // check to see if the click event happened inside the modal or modal-cover
    // close the modal if click was outside the modal for accessibility
    if(this.modalRef && this.modalRef.current && this.modalRef.current.contains(event.target)) return;
    this.handleClose();
  }

  render() {
    const {modalHeaderText, modalBody, role, footerCloseBtnText, showFooterCloseBtn, ariaLabel} = this.props;

    return ReactDOM.createPortal(
      <FocusLock>
        <div className="famhis-modal-cover" aria-modal="true" tabIndex="-1" role={role} aria-label={ariaLabel} onKeyDown={this.handleOnkeyDown} onClick={this.onClickAway}>
          <div className="famhis-modal" ref={this.modalRef}>

            <header className="famhis-modal-header">
              <span className="famhis-modal-header-text">{modalHeaderText}</span>
              <button className="famhis-modal-close-btn utility-element-focus" onClick={this.handleClose} aria-labelledby="close_modal" ref={this.buttonRef}>
                <span id="close_modal" className="utility-hide-visually">Close Modal</span>
                <svg className="famhis-modal-close-icon" viewBox="0 0 40 40">
                  <path d="M 10,10 L 30,30 M 30,10 L 10,30"></path>
                </svg>
              </button>
            </header>

            <section className="famhis-modal-body">
              {modalBody}
            </section>

            <footer className="famhis-modal-footer">
              {showFooterCloseBtn && (
                <button type="button" className="btn btn-light-outline no-margin-right" onClick={this.handleClose}><i className="fa fa-chevron-left"></i>{footerCloseBtnText}</button>
              )}
            </footer>

          </div>
        </div>
      </FocusLock>,
      document.body
    );
  }
}

export default Modal;
