import React,  {useState} from 'react';
import axios from 'axios';
import sdk from '../../api/sdk';

import { useStoreState, useStoreActions } from 'react-flow-renderer';

export default (props) => {
  const selted = props.ned;
  const nodes = useStoreState((store) => store.nodes);
  const [next, setNext] =  useState(1)
  const [fileName, setFile] =  useState("")
  const edges = useStoreState((store) => store.edges);
  const transform = useStoreState((store) => store.transform);
  const setSelectedElements = useStoreActions((actions) => actions.setSelectedElements);
  const addSelectedElements = useStoreActions((actions) => actions.setElements);
  const moveTheNode = useStoreActions((actions) => actions.updateNodePos);
//   const fred = useStoreActions((actions) => onSelectionChange);

  const selectAll = () => {
    setSelectedElements(nodes.map((node) => ({ id: node.id, type: node.type })));
  };

  const load = () =>
  {
	console.log("loading");
	// let newOne  = { id: 'aahhh', data: { label: 'Node 8' }, position: { x: 200, y: 200 } };
	let index = next;
	setNext((next) +1);
	addSelectedElements([]);
	// nodes.concat(newOne);
	nodes.map((node) => console.log(node) );
	// let currentFile = "http://localhost:33000/examples/family-"+index+"-Flow.json"
	let currentFile = "http://localhost:44000/examples/family-"+index+"-Flow.json"
	setFile(currentFile);
	fetch(currentFile).then((res) =>res.json()).then((bob) => addSelectedElements(bob))
  };

  const loadNext = () =>
  {
	console.log("loading Next COH");
	// let index = next;
	// setNext((next) +1);
	addSelectedElements([]);
	// nodes.concat(newOne);
// 	nodes.map((node) => console.log(node) );
// 	// let currentFile = "http://localhost:33000/examples/family-"+index+"-Flow.json"
// 	let currentFile = "http://localhost:33000/examples/family-"+index+"-Flow.json"
// 	setFile(currentFile);
// 	fetch(currentFile).then((res) =>res.json()).then((bob) => addSelectedElements(bob))
//   };
// const loadNextFile = () =>
// {
	let found = false;
	let myNext = next;
	let currentFile = "http://localhost:44000/"+myNext+"-data.json"
	while (found == false)
	{
		console.log("Trying " + currentFile);
		found = fetch(currentFile).then((res) =>
		{
			try{
				const contentType = res.headers.get("content-type");
				if (contentType && contentType.indexOf("application/json") !== -1) {
					res.json().then((bob) =>
					{
						addSelectedElements(bob);
					}
				);
					return true;
				}
				else
				return false;
			}
			catch(ex)
			{
				return false;
			}
			finally{
				return false;
			}
		}).catch((x) => {return false;});
		console.log("found" + found);
		if (!found)
		{
			myNext = myNext + 1;
			currentFile = "http://localhost:44000/"+myNext+"-data.json"
		}
	}

	if (found)
	{
		console.log("get next");
		setNext((next) +1);
	}

}
  const loadFromText = () =>
  {
	let data = document.getElementById("jsonInputData").value;
	data = JSON.parse(data);
	console.log("loading from Text Area" + data);
	let index = next;
	addSelectedElements([]);
	// nodes.concat(newOne);
	// nodes.map((node) => console.log(node) );
	// let currentFile = "http://localhost:33000/examples/family-"+index+"-Flow.json"
	// let currentFile = "http://localhost:33000/examples/family-"+index+"-Flow.json"
	// setFile(currentFile);
	// let data = $("jsonInputData").val


	let url = process.env.REACT_APP_ROOT_API_URL + '/flux/layout/';
    let response = sdk.request('post', url, data);
	response.then((result) =>
	{
		console.log(result);
		addSelectedElements(result.data);
	}
	);
	// axios({
	// 	method: 'post',
	// 	url: 'http://localhost:8000/v1/flux/layout/',
	// 	data: {
	// 	  firstName: 'Finn',
	// 	  lastName: 'Williams'
	// 	}
	//   }).then((response) => console.log(response));


	// fetch(currentFile).then((res) =>res.json()).then((bob) => addSelectedElements(bob))
  };


  const clear = () =>
  {
	// onSelectionChange(nodes.map((node) => console.log(node)));
	console.log("Selected is " + selted);
	// addSelectedElements([]);
  }

//   const getSelectedNode = () =>
//   {
// 	nodes.map((node) =>
// 	{
// 		if(node.select)
// 	} );
//   }
 const addParents = () =>
 {
	let lastClicked = props.ned;
	let middleTop = {
		id: Math.random().toString(),
		data:{label:""},
		style: { width: "1px", "height": "1px"},
		position: { x: lastClicked.position.x , y: lastClicked.position.y-50},
		sourcePosition: 'bottom',
		targetPosition: 'bottom',
	}
	let middle = {
		id: Math.random().toString(),
		data:{label:""},
		style: { width: "3px", "height": "3px"},
		position: { x: lastClicked.position.x , y: lastClicked.position.y -20},
		sourcePosition: 'left',
		targetPosition: 'top',
	}
	let mom = {
		id: Math.random().toString(),
		position: { x: lastClicked.position.x+50 , y: lastClicked.position.y -75},
		data: { label: "Mom" },
		style: { width: "30px", "height": "30px"},
		animated: true,
		sourcePosition: 'top',
		targetPosition: 'left',

	  }
	let dad = {
		id: Math.random().toString(),
		position: { x: lastClicked.position.x-50 , y: lastClicked.position.y -75},
		data: { label: "Dad" },
		style: { width: "30px", "height": "30px", "borderRadius": "20px" },
		animated: true,
		sourcePosition: 'bottom',
		targetPosition: 'right',

	  }
	let e1 = {id:Math.random().toString(),source:middleTop.id,target:dad.id.toString(), type: 'step'};
	let e2 = {id:Math.random().toString(),source:middleTop.id,target:mom.id.toString(), type: 'step'};
	let e3 = {id:Math.random().toString(),source:middle.id,target:lastClicked.id.toString(), type: 'step'};
	let e4 = {id:Math.random().toString(),source:middleTop.id,target:middle.id.toString(), type: 'straight'};
	addSelectedElements([...nodes,...edges, middle,middleTop, mom,dad,e1,e2, e3 ,e4]);
 }
 const loadById = () =>
 {
	let index =  document.getElementById("probandID").value;
	addSelectedElements([]);
	let currentFile = "http://localhost:44000/examples/" +index+ "-data-Flow.json";
	setFile(currentFile);
	fetch(currentFile).then((res) =>res.json()).then((bob) => addSelectedElements(bob));
	document.getElementById("probandHeader").innerText = "Proband ID " + index
 }
 const setProband = () =>
 {
	let current = props.ned;
	props.setData((data) =>
		 {
			//  data["members"].forEach(element => {

			//  });
			 data["promandCoupleID"] = current.id;
			 console.log(data);
			 return data;
		 });
 }

 const createCouple = () =>
 {
	 	console.log("Creating Data")
	 	props.setData((data) =>
		 {
			 let id = Math.random() * 1000;
			 let mom = {
				"id": id,
				"name": "Mom",
				"gender": "female",
				"motherid": null,
				"fatherid": null,
				"parentid": null,
				"multi": []
			  }
			  data["members"].push(mom);

			  id = Math.random() * 1000;
				let dad = {
				"id": id,
				"name": "Dad",
				"gender": "male",
				"motherid": null,
				"fatherid": null,
				"parentid": null,
				"multi": []
			  }
			  data["members"].push(dad);

			  id = Math.random() * 1000;
			 let couple =  {
				"id": id,
				"type": "couple",
				"mother": mom["id"],
				"father": dad["id"],
				"children": []
			  };
			  data["relationships"].push(couple);
			  if (data["promandCoupleID"] == null)
			  {
				data["promandCoupleID"] = mom["id"];
			  }

			console.log(data);
			return data;
		 })
 }

 const moveNode = () =>
 {
	let nodeList = [];

	nodes.map((node) =>
	{

		if (node.id == "provider-3")
		{
			let update = { "id": "provider-3", pos: { "x": node.position.x + 100, "y": 220 }};
			moveTheNode(update);
			console.log(node);
	// 		node.position.x = node.position.x + 100;
	// 		moveTheNode(node);
		}
	// 	// nodeList = [...nodeList,node];

	});
	// console.log(nodeList);
	// addSelectedElements(nodeList, ...edges);
 }


  return (
    <aside>
      <div className="description">
        This is an example of how you can access the internal state outside of the ReactFlow component.
      </div>
      <div className="title">Zoom & pan transform</div>
      <div className="transform">
        [{transform[0].toFixed(2)}, {transform[1].toFixed(2)}, {transform[2].toFixed(2)}]
      </div>
	  <div> Current File name {fileName} </div>
	  <div className="selectall">
        <button onClick={selectAll}>select all nodes</button>
		<button onClick={load}>Load Nodes</button>
	 	<button onClick={loadFromText}> Load JSON data</button>
		<button onClick={clear}> Clear </button>
		<button onClick={addParents}> Add parents to  {props.ned.id}</button>
		<button onClick={createCouple}>Create Couple</button>
		<button onClick={setProband}>Set Proband</button>
		<button onClick={moveNode}> Move Node</button>
		<br></br>

		<button onClick={loadNext}>Next COH</button>
		<br></br>
		<input id="probandID"></input><button onClick={loadById}>Load By ID</button>
		{/* <svg height="210" width="500">
  <line x1="0" y1="0" x2="200" y2="200" style="stroke:rgb(255,0,0);stroke-width:2" />
</svg> */}
      </div>

      <div className="title">Nodes</div>

      {
	    nodes.map((node) => (
        <div key={node.id}>
          Node {node.id} - x: {node.__rf.position.x.toFixed(2)}, y: {node.__rf.position.y.toFixed(2)}
        </div>
      ))}



    </aside>
  );
};
