import settings from './settings'
class MenuButton {

  paper = null
  buttonAddGray = null
  buttonAddTeal = null
  buttonEditGray = null
  buttonEditTeal = null


  constructor(paper) {
    this.paper = paper
  }
  /*
  Draw Menu Icon Button
  */

  draw = (option) => {

    this.clear()

    let topLeft = option.point
    if(option.drawAddButton){
      this.buttonAddGray = this.drawAddButton(topLeft, 'popup_menu_add_gray')
      this.buttonAddTeal = this.drawAddButton(topLeft, 'popup_menu_add_teal')
      this.buttonAddTeal.visible = false
    }


    let top_offset = settings.popup_menu_button_width + settings.popup_menu_button_gap
    topLeft = topLeft.add(0, top_offset)
    if(!option.drawAddButton){
      topLeft = option.point
    }
    //if node is no children or infertlity, make the pencil icon be delete icon
    if(option.is_no_children_or_infertility_node){
      this.buttonEditGray = this.drawAddButton(topLeft, 'popup_menu_delete_gray')
      this.buttonEditTeal = this.drawAddButton(topLeft, 'popup_menu_delete_teal')
      this.buttonEditTeal.visible = false

      this.buttonEditGray.onMouseEnter = (event) => {
        this.buttonEditGray.visible = false
        this.buttonEditTeal.visible = true
        document.body.style.cursor = "pointer"
      }

      this.buttonEditTeal.onMouseLeave = (event) => {
        this.buttonEditGray.visible = true
        this.buttonEditTeal.visible = false
        document.body.style.cursor = "default"
      }

      this.buttonEditTeal.onClick = (event) => {
        if('onEditButtonClick' in option) {
          option.onEditButtonClick(event)
        }
      }
    }
    //else, just draw the pencil icon
    else{
      // this.buttonEditGray = this.drawAddButton(topLeft, 'popup_menu_edit_gray')
      // this.buttonEditTeal = this.drawAddButton(topLeft, 'popup_menu_edit_teal')
      // this.buttonEditTeal.visible = false

      // this.buttonEditGray.onMouseEnter = (event) => {
      //   this.buttonEditGray.visible = false
      //   this.buttonEditTeal.visible = true
      //   document.body.style.cursor = "pointer"
      // }
      //
      // this.buttonEditTeal.onMouseLeave = (event) => {
      //   this.buttonEditGray.visible = true
      //   this.buttonEditTeal.visible = false
      //   document.body.style.cursor = "default"
      // }
      //
      // this.buttonEditTeal.onClick = (event) => {
      //   if('onEditButtonClick' in option) {
      //     option.onEditButtonClick(event)
      //   }
      // }
    }

    if(option.drawAddButton){
      this.buttonAddGray.onMouseEnter = (event) => {
        this.buttonAddGray.visible = false
        this.buttonAddTeal.visible = true
        document.body.style.cursor = "pointer"
      }

      this.buttonAddTeal.onMouseLeave = (event) => {
        this.buttonAddGray.visible = true
        this.buttonAddTeal.visible = false
        document.body.style.cursor = "default"
      }

      this.buttonAddTeal.onClick = (event) => {
        if('onAddButtonClick' in option) {
          option.onAddButtonClick(event)
        }
      }
    }

  }

  isInside = (point) => {
    let add = this.isInsideButton(point, this.buttonAddTeal)
    let edit = this.isInsideButton(point, this.buttonEditTeal)

    return (add || edit)
  }

  isInsideButton = (point, button) => {
    if(button) {

      let topLeft = button.bounds.topLeft
      let bottomRight = button.bounds.bottomRight

      let insideWidth = point.x >= topLeft.x && point.x <= bottomRight.x
      let insideHeight = point.y >= topLeft.y && point.y <= bottomRight.y

      return ( insideWidth &&  insideHeight)
    }

    return false
  }

  clear = () => {
    if(this.buttonAddGray) this.buttonAddGray.remove()
    if(this.buttonAddTeal) this.buttonAddTeal.remove()
    if(this.buttonEditGray) this.buttonEditGray.remove()
    if(this.buttonEditTeal) this.buttonEditTeal.remove()
  }

  drawAddButton = (topLeft, image_id) => {

    let width = settings.popup_menu_button_width
    let height = settings.popup_menu_button_height
    let margin_right = settings.popup_menu_button_margin_right

    var buttonAddGray = new this.paper.Raster(image_id)
    buttonAddGray.size = new this.paper.Size(width, height)

    let left_offset = (width / 2 + margin_right) * -1
    let top_offset = settings.popup_menu_button_margin_top + width / 2
    buttonAddGray.position = topLeft.add(left_offset, top_offset)

    return buttonAddGray

  }

}

export default MenuButton
