import sdk from './sdk';
import { isEqual } from 'lodash';

const get_orgs = async () => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/organization/';
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const get_sub_orgs = async (org_id) =>{
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/organization/get_sub_organizations/';
    let response = await sdk.request('get', url,{id: org_id});

    let data = response.data;
    return data;
  }catch(err){
    throw sdk.parse_error_message(err);
  }
}

const get_org_by_id = async (org_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/organization/${org_id}/`;
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const get_org_surveys = async (clin_id) => {
  let payload = {clinician: clin_id}
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/survey_organization/get_survey_organizations/`;
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const get_org_licenses = async () => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/organization_licenses/';
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const get_clinician_codes = async () => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/organization_clinician_code/';
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const post_new_organization = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/organization/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const save_org_surveys = async (clin_id, survey_ids) => {
  let payload = {clinician_id: clin_id, survey_ids: survey_ids}
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/survey_organization/save_survey_organizations/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const post_new_staff = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/staff/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const validate_staff_username = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/staff/validate_new_username/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const validate_org_code = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/organization/validate_code/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const validate_org_name = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/organization/validate_name/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const post_update_user_profile = async (staff_id, payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/staff/' + staff_id + '/profile/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const post_update_user_password = async (staff_id, payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/staff/' + staff_id + '/change_password/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    if (err.response && err.response.data) {
      if(err.response.status === 422) {
        throw(Error(err.response.data.message));
      }
    } else {
      throw(Error('Something went wrong!'));
    }
  }
}

const get_org_staff_accounts = async (org_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/staff/retrieve_org_staff/';
    let response = await sdk.request('get', url, {id: org_id});

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const patch_organization = async (org_id, payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/organization/${org_id}/`;
    let response = await sdk.request('patch', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const patch_staff_account = async (staff_id, payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/staff/${staff_id}/`;
    let response = await sdk.request('patch', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const delete_staff_account = async (staff_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/staff/${staff_id}/`;
    let response = await sdk.request('delete', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const search_staff = async (org_id, search=null) => {
  try {

    let base_url = process.env.REACT_APP_ROOT_API_URL + `/staff/search_staff/?org_id=${org_id}`;

    let url = base_url;
    if(search) {
      search = search.trim();

      // Search for each term separately
      let terms = search.split(" ");
      terms = terms.filter((term) => {
        if(term) return term;
      });

      let results = [];
      let first = true;
      for(let term of terms) {
        url = base_url + `&search=${term}`;

        let response = await sdk.request('get', url);
        let data = response.data;

        // Compare results - if value exists in current accepted pool and in new set of data, keep. Otherwise, remove
        if(first) {
          results.push(...data);
          first = false;
        } else {
          results = results.filter(element => {
            for(let item of data) {
              if(isEqual(element, item)) return true;
            }
            return false;
          });
        }
      }

      return results;
    } else {
      return null;
    }

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const should_reset_password = async () => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/account/should_reset_password/';
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const get_release_notifications = async () => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/account/get_release_notifications/';
    let response = await sdk.request('get', url, {});

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const update_release_version = async () => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/account/update_release_version/';
    let response = await sdk.request('post', url, {});

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const delete_org = async (org_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/organization/${org_id}/`;
    let response = await sdk.request('delete', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const search_organizations = async (search=null) => {
  try {

    let base_url = process.env.REACT_APP_ROOT_API_URL + '/organization/search_organizations/?';

    let url = base_url;
    if(search) {
      search = search.trim();

      // Search for each term separately
      let terms = search.split(" ");
      terms = terms.filter((term) => {
        if(term) return term;
      });

      let results = [];
      let first = true;
      for(let term of terms) {
        url = base_url + `&search=${term}`;

        let response = await sdk.request('get', url);
        let data = response.data;

        // Compare results - if value exists in current accepted pool and in new set of data, keep. Otherwise, remove
        if(first) {
          results.push(...data);
          first = false;
        } else {
          results = results.filter(element => {
            for(let item of data) {
              if(isEqual(element, item)) return true;
            }
            return false;
          });
        }
      }

      return results;
    } else {
      return null;
    }

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const patch_clinician_code = async (clinician_code_id, payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/organization_clinician_code/${clinician_code_id}/`;
    let response = await sdk.request('patch', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const patch_license = async (license_id, payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/organization_license/${license_id}/`;
    let response = await sdk.request('patch', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const save_new_query_report = async (payload, clinician_id)=> {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/organization_query_report/${clinician_id}/save_new_report/`;
      let response = await sdk.request('post', url, payload);

      let data = response.data;
      return data;

    } catch (err) {
      throw sdk.parse_error_message(err);
    }
  }

  const overwrite_report = async (payload, clinician_id)=> {
    try {
      let url = process.env.REACT_APP_ROOT_API_URL + `/organization_query_report/${clinician_id}/overwrite_report/`;
        let response = await sdk.request('post', url, payload);

        let data = response.data;
        return data;

      } catch (err) {
        throw sdk.parse_error_message(err);
      }
    }

const get_reports = async (org_id, clinician_id) => {
    try {
      let url = process.env.REACT_APP_ROOT_API_URL + `/organization_query_report/${clinician_id}/get_reports/`;
      let response = await sdk.request('get', url, {id: org_id});

      let data = response.data;
      return data;

    } catch (err) {
      throw sdk.parse_error_message(err);
    }
  }

const load_report =  async (reportId, clinician_id) =>{
    try {
      let url = process.env.REACT_APP_ROOT_API_URL + `/organization_query_report/${clinician_id}/load_report/`;
      let response = await sdk.request('get', url, {report: reportId});

      let data = response.data;
      return data;

    } catch (err) {
      throw sdk.parse_error_message(err);
  }
}

const delete_report = async (reportId, clinician_id) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/organization_query_report/${clinician_id}/delete_report/`;
    let response = await sdk.request('post', url, { report: reportId });

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}
const save_default_columns = async (payload, clinician_id)=> {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/organization_query_columns/${clinician_id}/save_default_columns/`;
      let response = await sdk.request('post', url, payload);
      let data = response.data;
      return data;

    } catch (err) {
      throw sdk.parse_error_message(err);
    }
  }

const load_default_columns = async (org_id, clinician_id)=> {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/organization_query_columns/${clinician_id}/load_default_columns/`;
      let response = await sdk.request('get', url, {org_id: org_id});
      let data = response.data;
      return data;

    } catch (err) {
      throw sdk.parse_error_message(err);
    }
}

const get_surveys =  async () =>{
    try {
      let url = process.env.REACT_APP_ROOT_API_URL + `/survey/get_surveys/`;
      let response = await sdk.request('get', url, {});

      let data = response.data;
      return data;

    } catch (err) {
      throw sdk.parse_error_message(err);
  }
}

const get_surveys_with_orgs =  async (payload) =>{
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/survey/get_surveys_with_orgs/`;
    let response = await sdk.request('get', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}



const create_new_survey_copy =  async (payload) =>{
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/survey/copy_survey/`;
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const delete_survey =  async (payload) =>{
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/survey/delete_survey/`;
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const generate_developer_key = async (org_id) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/organization/${org_id}/create_developer_key/`;
    let response = await sdk.request('post', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const get_org_sso = async () => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/organization/organization_sso/`;
    let response = await sdk.request('get', url);
    let data = response.data
    //this will be an array of the sso of the org
    return data
  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const save_license_details = async (payload) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/organization_license/save_license_details/`;
    let response = await sdk.request('patch', url, payload);
    let data = response.data
    return data
  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const get_trial_org = async (org_id) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/organization/get_trial_org/`;
    let response = await sdk.request('get', url, {org_id: org_id});
    let data = response.data
    return data
  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const update_trial_org = async (org_id, expiration_date) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/organization/update_trial_org/`;
    let response = await sdk.request('post', url, {org_id: org_id, expiration_date: expiration_date});
    let data = response.data
    return data
  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const get_trial_staff = async (staff_id) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/organization/get_trial_staff/`;
    let response = await sdk.request('get', url, {staff_id: staff_id});
    let data = response.data
    return data
  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const update_trial_staff = async (staff_id, expiration_date) => {
  try {
    let url = process.env.REACT_APP_ROOT_API_URL + `/organization/update_trial_staff/`;
    let response = await sdk.request('post', url, {staff_id: staff_id, expiration_date: expiration_date});
    let data = response.data
    return data
  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

export default {
  parse_error_message: sdk.parse_error_message,
  get_orgs,
  get_sub_orgs,
  get_org_by_id,
  get_org_surveys,
  get_org_licenses,
  get_clinician_codes,
  post_new_organization,
  save_org_surveys,
  post_new_staff,
  validate_staff_username,
  validate_org_code,
  validate_org_name,
  post_update_user_profile,
  post_update_user_password,
  get_org_staff_accounts,
  patch_organization,
  patch_staff_account,
  delete_staff_account,
  search_staff,
  should_reset_password,
  get_release_notifications,
  update_release_version,
  delete_org,
  search_organizations,
  patch_clinician_code,
  patch_license,
  save_new_query_report,
  get_reports,
  load_report,
  delete_report,
  overwrite_report,
  save_default_columns,
  load_default_columns,
  get_surveys,
  generate_developer_key,
  get_org_sso,
  create_new_survey_copy,
  delete_survey,
  save_license_details,
  get_trial_org,
  update_trial_org,
  get_trial_staff,
  update_trial_staff,
  get_surveys_with_orgs
}
