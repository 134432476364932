import skip_logic_map from '../../assets/json/diseases-skip-logic-map.json';
import { radix_bases } from '../../helpers/helper-number-bases';
import { skipLogicUMLS } from '../../helpers/diseaseUMLSSkipLogic';

let currentSkipLogics = []
let currentMap = {}


function plusMinusLabel (label, item, comma=','){
    if(item == null || item == '' || item == undefined) return ''

    let text = label
    text += (item == 'p' ? '-pos' : '-neg');
    return text;
  }

function  yesNoLabel(label, age=null) {
    let text = ''
    if(age){
      text += label + ' Dx ' + age;
    } else {
      text += label;
    }

    if(text !== '') text += ', ';
    return text;
  }

function getValue(key) {
    if(!(key in currentMap)) return null
    let code = currentMap[key]
    let data = currentSkipLogics.find(item => item.skip_logic_code == code)

    if(typeof(data) === 'undefined') return ''

    return data.answer
  }

function breastSkipLogicText(disease){
    if(disease.skip_logics == null) return null;
    currentSkipLogics = disease.skip_logics
    currentMap = skip_logic_map.breast

    let breast_skip_logic_text = ''

    let triple_negative = getValue('triple_negative');
    let er = getValue('er', );
    let pr = getValue('pr');
    let her2 = getValue('her2');
    let ck14 = getValue('ck14');
    let ck5_6 = getValue('ck5_6');
    let ipsilateral_age = getValue('ipsilateral_age',);
    let bilateral_age = getValue('bilateral_age');
    let lobular = getValue('lobular');

    let er_pr_her2_text = ''
    if(triple_negative == 'y') {
      er_pr_her2_text = `Triple Negative, `
    }else{
      er_pr_her2_text = `${plusMinusLabel('ER', er)} ${plusMinusLabel('PR', pr)} ${plusMinusLabel('HER2', her2, '')}`
    }

    let ipsilateral_label = '';
    if(ipsilateral_age !== null && ipsilateral_age !== 'n') {
      if( !isNaN(parseInt(ipsilateral_age, radix_bases.base10)) && parseInt(ipsilateral_age, radix_bases.base10) === 0) {
        ipsilateral_label = yesNoLabel('Ipsilateral', null);
      } else if(ipsilateral_age) {
        ipsilateral_label = yesNoLabel('Ipsilateral', ipsilateral_age);
      }
    }   
    let bilateral_label = '';
    if(bilateral_age !== null && bilateral_age !== 'n') {
      if( !isNaN(parseInt(bilateral_age, radix_bases.base10)) && parseInt(bilateral_age, radix_bases.base10) === 0) {
        bilateral_label = yesNoLabel('Bilateral', null);
      } else if(bilateral_age){
        bilateral_label = yesNoLabel('Bilateral', bilateral_age);

      }
    }
    if(er_pr_her2_text) breast_skip_logic_text += er_pr_her2_text
    if(ipsilateral_label) breast_skip_logic_text += ipsilateral_label
    if(bilateral_label) breast_skip_logic_text += bilateral_label

    let ck14_text =  `${plusMinusLabel('CK14', ck14)}, `
    let ck56_text = `${plusMinusLabel('CK5/6', ck5_6)}, `

    if(ck14) breast_skip_logic_text += ck14_text
    if(ck5_6) breast_skip_logic_text += ck56_text
    if(lobular == 'y') breast_skip_logic_text += 'Lobular, '

    return breast_skip_logic_text.trim()
}

function colorectalSkipLogicText(disease){
  
  if(disease.skip_logics == null) return null;
 
  currentSkipLogics = disease.skip_logics
  currentMap = skip_logic_map.colorectal

  let colorectal_text = ''

  let more_primary = getValue('more_primary')
  let tumor_location = getValue('tumor_location')
  let mlh1_ihc = getValue('mlh1_ihc')
  let msi = getValue('msi')
  let msh1_ihc = getValue('msh1_ihc')
  let msh2_ihc = getValue('msh2_ihc')
  let msh6_ihc = getValue('msh6_ihc')
  let pms2_ihc = getValue('pms2_ihc')
  let mlh1_hypermethylation = getValue('mlh1_hypermethylation')
  let braf_v600e = getValue('braf_v600e')

  if(more_primary == 'y' && tumor_location){
    colorectal_text += `More than one primary ${tumor_location}, `
  }else if(more_primary == 'y' ){
    colorectal_text += `More than one primary, `
  }else if(tumor_location){
    colorectal_text += `${tumor_location}, `
  }

  if(msi == 'unknown'){
    colorectal_text +=`MSI-${msi} ` 
  }else if(msi)  colorectal_text += `${msi}, `

  if(mlh1_ihc) colorectal_text += `MLH1 IHC-${mlh1_ihc}, `
  if(msh2_ihc) colorectal_text += `MSH2 IHC-${msh2_ihc}, `
  if (msh6_ihc) colorectal_text += `MSH6 IHC-${msh6_ihc}, `;
  if (pms2_ihc) colorectal_text += `PMS2 IHC-${pms2_ihc}, `;
  if(mlh1_hypermethylation) colorectal_text+= `MLH1 Hypermethylation ${mlh1_hypermethylation}, `
  if(braf_v600e) colorectal_text += `${plusMinusLabel('BRAF v600E', braf_v600e)} `

  return colorectal_text
}

function colorectalPolypsSkipLogicText(disease){

  if(disease.skip_logics == null) return null;

  let polypsText = ''

  currentSkipLogics = disease.skip_logics
  currentMap = skip_logic_map.colorectal_polyps
  let moreThanTen = getValue('more_than_ten')
  let hamart = getValue('hamartamatous')
  if(moreThanTen == 'y') polypsText += 'More than 10, '
  if(hamart == 'y') polypsText += 'Hamartomatous, '

  return polypsText
}

function melanomaSkipLogicText(disease){
  if(disease.skip_logics == null) return null;
 
  currentSkipLogics = disease.skip_logics
  currentMap = skip_logic_map.melanoma
  
  let multiple_melanoma = getValue('multiple_melanoma');

  return multiple_melanoma == 'y' ? '(3 or more) ' : ''
}

function hodgkinLymphomaSkipLogicText(disease){

  if(disease.skip_logics == null) return null;
 
  currentSkipLogics = disease.skip_logics
  currentMap = skip_logic_map.hodgkin_lymphoma

  let hodgkin = getValue('hodgkin');   

  return hodgkin == 'y'  ?  'Treated with radiation therapy to the chest, ' : ''

}

function dcisSkipLogicText(disease){

  if(disease.skip_logics == null) return null;
  currentSkipLogics = disease.skip_logics
  currentMap = skip_logic_map.dcis

  let dcis_skip_logi_text = ''

  let triple_negative = getValue('triple_negative');
  let er = getValue('er', );
  let pr = getValue('pr');
  let her2 = getValue('her2');
  let ck14 = getValue('ck14');
  let ck5_6 = getValue('ck5_6');
  let ipsilateral_age = getValue('ipsilateral_age',);
  let bilateral_age = getValue('bilateral_age');

  let er_pr_her2_text = ''
  if(triple_negative == 'y') {
    er_pr_her2_text = `Triple Negative `
  }else{
    er_pr_her2_text = `${plusMinusLabel('ER', er)} ${plusMinusLabel('PR', pr)} ${plusMinusLabel('HER2', her2, '')}`
  }

  let ipsilateral_label = '';
  if(ipsilateral_age !== null && ipsilateral_age !== 'n') {
    if(!isNaN(parseInt(ipsilateral_age, radix_bases.base10)) && parseInt(ipsilateral_age, radix_bases.base10) === 0) {
      ipsilateral_label = yesNoLabel('Ipsilateral', null);
    } else if(ipsilateral_age){
      ipsilateral_label = yesNoLabel('Ipsilateral', ipsilateral_age);
    }
  }   
  let bilateral_label = '';
  if(bilateral_age !== null && bilateral_age !== 'n') {
    if( !isNaN(parseInt(bilateral_age, radix_bases.base10)) && parseInt(bilateral_age, radix_bases.base10) === 0) {
      bilateral_label = yesNoLabel('Bilateral', null);
    } else if(bilateral_age){
      bilateral_label = yesNoLabel('Bilateral', bilateral_age);
    }
  }

  let ck14_text =  plusMinusLabel('CK14', ck14)
  let ck56_text = plusMinusLabel('CK5/6', ck5_6)

  dcis_skip_logi_text = `${er_pr_her2_text} ${ipsilateral_label} ${bilateral_label} ${ck14_text} ${ck56_text}`
  return dcis_skip_logi_text.trim()
}

function pancreaticNeuroEndocrineSkipLogicText(disease){

  if(disease.skip_logics == null) return null;

  currentSkipLogics = disease.skip_logics
  currentMap = skip_logic_map.pancreatic_neuroendocrine

  let pancreatic_neuro = getValue('pancreatic_neuro');    

  return pancreatic_neuro == 'y' ?  'More than one tumor, ': ''
}

function prostateSkipLogicText(disease){

  if(disease.skip_logics == null) return null;

  let prostateSkipLogicText = ''
  currentSkipLogics = disease.skip_logics
  currentMap = skip_logic_map.prostate    

  let metastatic = getValue('metastatic');
  let agressive = getValue('agressive'); 
  let intraductal = getValue('intraductal');

  if(metastatic == 'y') prostateSkipLogicText += 'Metastatic '
  if(agressive == 'y') prostateSkipLogicText += 'Aggressive '
  if(intraductal  == 'y') prostateSkipLogicText += 'Intraductal/ Cribriform '

  return prostateSkipLogicText
 
}

function uterineSkipLogicText(disease){

  if(disease.skip_logics == null) return null;
  currentSkipLogics = disease.skip_logics
  currentMap = skip_logic_map.uterine  

  let uterine_text = ''

  let msi = getValue('msi');
  let mlh1_ihc = getValue('mlh1_ihc');
  let msh2_ihc = getValue('msh2_ihc');
  let msh6 = getValue('msh6');
  let pms2 = getValue('pms2');
  let mhl1_hyper = getValue('mhl1_hyper');


  if(msi) uterine_text+= `MSI-${msi}, `

  if(mlh1_ihc) uterine_text += `MLH1 IHC-${mlh1_ihc}, `
  if(msh2_ihc) uterine_text += `MSH2 IHC-${msh2_ihc}, `
  if(msh6) uterine_text += `MSH6 IHC-${msh6}, `
  if(pms2) uterine_text += `PMS2 IHC-${pms2}, `
  if(mhl1_hyper) uterine_text+= `MLH1 hypermethylation- ${mhl1_hyper}`

  return uterine_text
}

function otherCancerSkipLogicText(disease){
  if(disease.skip_logics == null) return null;
  currentSkipLogics = disease.skip_logics
  currentMap = skip_logic_map.other_cancer
  let type = currentSkipLogics.find(element => {
    return element.skip_logic_code == currentMap["Other Cancer"]
  })
  if (type && type.answer) return type.answer
}

function birthdefectSkipLogicText(disease){
  if(disease.skip_logics == null) return null
  currentSkipLogics = disease.skip_logics
  currentMap = skip_logic_map.birth_defects

  let type = currentSkipLogics.find(element => {
    return element.skip_logic_code == currentMap[element.short_name]
  })
  if (type && type.answer) return type.answer
}


function leukemiaSkipLogicText(disease){
    if(disease.skip_logics == null) return null
    currentSkipLogics = disease.skip_logics;
    currentMap = skip_logic_map.leukemia

    let type = currentSkipLogics.find(element => {
      return element.skip_logic_code == currentMap[element.short_name]
    })
  
    if (type && type.short_name) return type.short_name
 }

function thyroidSkipLogicText(disease){

  if(disease.skip_logics == null) return null;
  let thyroidText = ''
  currentSkipLogics = disease.skip_logics
  currentMap = skip_logic_map.thyroid  
  let medullary = getValue('medullary');  
  let papillary = getValue('papillary');
  if(medullary =='y') thyroidText += 'Medullary, '
  if(papillary == 'y') thyroidText += 'Papillary (cribiform-morular variant) '
  return thyroidText
}

export function skipLogicCheck(disease){
  if(disease.skip_logics== null) return

  let id = disease.disease_code ? disease.disease_code : disease.disease_id

  if(breastSkipCheck(id)) return breastSkipLogicText(disease)
  if(colorectalSkipCheck(id)) return colorectalSkipLogicText(disease)
  if(colorectalPolypsSkipCheck(id)) return colorectalPolypsSkipLogicText(disease)
  if(melanomaSkipCheck(id)) return melanomaSkipLogicText(disease)
  if(hodgkinSkipCheck(id)) return hodgkinLymphomaSkipLogicText(disease)
  if(dcisSkipCheck(id)) return dcisSkipLogicText(disease)
  if(pancreaticNeuroSkipCheck(id)) return pancreaticNeuroEndocrineSkipLogicText(disease)
  if(prostateSkipCheck(id)) return prostateSkipLogicText(disease)
  if(thyroidSkipCheck(id)) return thyroidSkipLogicText(disease)
  if(uterineSkipCheck(id)) return uterineSkipLogicText(disease)
  if(leukemiaSkipCheck(id))return leukemiaSkipLogicText(disease)
  if(birthdefectSkipCheck(id)) return birthdefectSkipLogicText(disease)
  if(id == 'D107') return otherCancerSkipLogicText(disease)

}

export function breastSkipCheck(id){
  return skipLogicUMLS.breastUMLS.includes(id)
}

export function colorectalSkipCheck(id){
  return skipLogicUMLS.colorectalUMLS.includes(id)
}

export function colorectalPolypsSkipCheck(id){
  return skipLogicUMLS.colorectalPolypsUMLS.includes(id)
}

export function melanomaSkipCheck(id){
  return skipLogicUMLS.melanomaUMLS.includes(id)
}

export function hodgkinSkipCheck(id){
  return skipLogicUMLS.hodgkinlymphomaUMLS.includes(id)
}

export function dcisSkipCheck(id){
  return skipLogicUMLS.dcisUMLS.includes(id)
}

export function pancreaticNeuroSkipCheck(id){
  return skipLogicUMLS.pancreaticNeuroendocrineUMLS.includes(id)
}

export function prostateSkipCheck(id){
  return skipLogicUMLS.prostateUMLS.includes(id)
}

export function thyroidSkipCheck(id){
  return skipLogicUMLS.thyroidUMLS.includes(id)
}

export function uterineSkipCheck(id){
  return skipLogicUMLS.uterineUMLS.includes(id)
}

export function leukemiaSkipCheck(id){
  return skipLogicUMLS.leukemiaUMLS.includes(id)
}

export function birthdefectSkipCheck(id){
  return skipLogicUMLS.birthdefectsUMLS.includes(id)
}
