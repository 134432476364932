import React, { Component } from "react";
import { connect } from "react-redux";

import route_helper from "../route-helper";
import helpers from "../helpers/index";
import NavTop from "../components/nav-top";
import PatientPersonalData from "../components/patient-profile/patient-data-tabs/patient-personal-data";
import * as helper_family_tree from "../helpers/helper-family-tree";

import PatientDiseaseView from "../components/patient-disease-view";
import PatientGeneTestView from "../components/patient-gene-test-view";
import ErrorSummary from "../components/error-summary";
import ActivityIndicator from "../components/activity-indicator";
import helper_family_api from "../helpers/helper-family-api";
import family_api from "../api/family-api";
import disease_api from "../api/disease-api";
import { radix_bases } from '../helpers/helper-number-bases';
import { cloneDeep } from "lodash";

import moment from "moment";
import * as patient_actions from "../store/patient/actions";

class PatientPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessages: [],
      diseases: [],
      geneticTestings: [],
    };

    this.onClickSave = this.onClickSave.bind(this);
    this.goToPedigree = this.goToPedigree.bind(this);
  }

  componentDidMount() {
    helpers.setSiteTitle("Enter New Patient");
    helper_family_tree.resetFamilyRedux(this.props.dispatch);
  }

  async validate() {
    this.setState({ validations: null });

    let options = {
      patient_id: {
        labelName: "Patient ID",
        validations: { required: true }
      },
      first_name: {
        labelName: "First Name",
        validations: { required: true }
      },
      gender: {
        labelName: "Sex Assigned at Birth",
        validations: { required: true }
      }
      // phone: {
      //   labelName: 'Phone',
      //   validations: {
      //     required: true,
      //     custom: [{
      //       test: () => { return this.state.phone_valid },
      //       error_text: "* Invalid",
      //       error_summary: "Phone number is invalid",
      //     }]
      //   }
      // }
    };

    let validations = helpers.validateInputs(this.props.patient, options);

    if(this.props.patient.id) {
      let age = moment().diff(this.props.patient.dob, "years");
      let diseases = await disease_api.get_member_id_diseases(this.props.patient.id)

      for(let disease of diseases) {
        if(age !== null
          && age !== undefined
          && age !== ''
          && age < disease.age_diagnosed)
            throw new Error('The current age/age at death cannot be less than the age of diagnosis entered for this person’s disease.')
      }
    }

    this.setState({ errorMessages: validations.summary });
    return validations.isValid;
  }

  buildPayload() {
    let input = this.props.patient;

    let gender = input.gender.toLowerCase()
    // gender = gender === 'u' ? null : gender

    let gender_identity = input.gender_identity
    // gender_identity = gender_identity ? gender_identity.toLowerCase() : 'u'
    // gender_identity = gender_identity == 'u' ? null : gender_identity

    let payload = {
      clinician_id: this.props.clinician_id,
      first_name: input.first_name,
      middle_name: input.middle_name,
      last_name: input.last_name,
      gender: gender,
      patient_id: input.patient_id,
      gender_identity: gender_identity,
      pronoun: input.pronoun === null ? "" : input.pronoun,
      other_pronoun: input.other_pronoun === null ? "" : input.other_pronoun,
      email: input.email,
      phone_number: input.phone_number,
      ethnicity: input.ethnicity === null ? "" : input.ethnicity,
      ashkenazi: input.ashkenazi === true,
      father_adopted: input.father_adopted === true,
      mother_adopted: input.mother_adopted === true,
      adopted_out: input.adopted_out
    };

    if(input.dob && input.dob !== null) {
      let age = moment().diff(input.dob, "years");
      payload['dob'] = input.dob
      payload['age'] = age
      payload['yob'] = moment(input.dob).year();
    } else {
      payload['dob'] = null;
      payload['age'] = null;
      payload['yob'] = null;
    }

    return payload;
  }

  buildRiskFactors() {
    let risk_factors = this.props.tree.risk_factors;

    let payload = {

      height_first_num: 0,
      height_second_num: 0,
      height_metric: 'ft',
      weight: 0,
      weight_metric: 'lb',
      bmi: 0,

      age_at_first_menstruation: null,
      age_at_first_birth: null,
      post_menopausal: null,
      age_at_menopause: null,
      ever_taken_hrt: null,
      hrt_years: null,
      hrt_type: null,
      year_since_last_taken: null,
      intended_use: null,

      breast_density_method: null,
      birads_density: null,
      percentage_density: null,
      ever_had_biopsy: risk_factors.proceduresRiskFactors.hadBreastBiopsy,
      biopsy_number: null,
      benign: risk_factors.proceduresRiskFactors.breastBiopsyBenign,
      hyperplasia: risk_factors.proceduresRiskFactors.breastBiopsyHyperplasia,
      atypical_hyperplasia: risk_factors.proceduresRiskFactors.breastBiopsyAtypicalHyperplasia,
      lcis: risk_factors.proceduresRiskFactors.breastBiopsyLCIS,
      unknown: risk_factors.proceduresRiskFactors.breastBiopsyUnknown,

      hysterectomy: risk_factors.surgeriesRiskFactors.hadHysterectomy,
      hysterectomy_age: null,
      mastectomy: risk_factors.surgeriesRiskFactors.hadMastectomy,
      mastectomy_age: null,
      mastectomy_type: null,
      oophorectomy: risk_factors.surgeriesRiskFactors.hadOophorectomy,
      oophorectomy_age: null,
      oophorectomy_type: null,
      salpingectomy: risk_factors.surgeriesRiskFactors.hadSalpingectomy,
      salpingectomy_age: null,
      tubal_ligation: risk_factors.surgeriesRiskFactors.hadTubalLigation,
      tubal_ligation_age: null
    };

    return payload;
  }

  async saveProband() {
    let payload = this.buildPayload();
    let mother = this.props.patient.mother;
    let father = this.props.patient.father;
    let maternal_ancestry = Array.isArray(mother.ancestry) ? mother.ancestry.map((item) => item.value) : null;
    let paternal_ancestry = Array.isArray(father.ancestry) ? father.ancestry.map((item) => item.value) : null;
    let proband = cloneDeep(this.props.patient);
    var member = null;

    if (proband.id == null) {
      member = await helper_family_api.create_proband(payload, maternal_ancestry, paternal_ancestry);
    } else {
      member = await family_api.patch_member_memberid(proband.id, payload);
    }

    if (member && member.mother && member.father) {
      member.mother.ancestry = mother.ancestry;
      member.father.ancestry = father.ancestry;
    }

        helper_family_tree.savePatientToRedux(this.props.dispatch, {
      ...proband,
      ...member
    });

    helper_family_tree.saveProfileToRedux(this.props.dispatch, {
      rkey: proband.rkey,
      ...member
    });

    return member;
  }

  async onClickSave() {
    try {
      this.setState({ loading: true, errorMessages: [] });
      let valid = await this.validate();
      if (!valid) return;
      const clins = this.props.patient.clinicians;
      let proband = await this.saveProband();
      proband.clinicians = clins;
      await this.saveClinician(proband)

      this.saveDialCode()
      this.props.history.push("/family-tree/" + proband.id);
    } catch (error) {
      helpers.logger(error);
      this.setState({ errorMessages: [error.message] });
    } finally {
      // react sometimes throws an error because this executes after the route redirection
      // need to add this.mounted to the component and check that to see if this should be set
      // use componentDidMount and componentWillUnmount
      this.setState({ loading: false });
    }
  }

  async goToPedigree() {
    try {
      this.setState({ loading: true, errorMessages: [] });

      let valid = await this.validate();
      if (!valid) return;

      const clins = this.props.patient.clinicians;
      let proband = await this.saveProband();
      proband.clinicians = clins;
      await this.saveClinician(proband)
      this.saveDialCode()
      let url = route_helper.pedigree.pedigree_root.replace(":member_id", proband.id) + '/#family-history';
      this.props.history.push(url);
    } catch (error) {
      helpers.logger(error);
      this.setState({ errorMessages: [error.message] });
    } finally {
      this.setState({ loading: false });
    }
  }

  async saveDialCode(){
     var member_saved = await this.memberDataShouldBeSaved()
      if(member_saved && this.props.patient.dial_code !== undefined && this.props.patient.is_proband == true){
        let payload = {
        member_id:this.props.patient.id,
        dial_code: this.props.patient.dial_code
        }
        await family_api.post_member_dial_code(payload);
      }
    }

  getProfile() {
    return helper_family_tree.getProfileFromRedux(
      this.props.tree,
      this.props.patient.rkey
    );
  }

  async memberDataShouldBeSaved() {
    try {
      this.setState({ loading: true, errorMessages: [] });

      let patient_id = this.props.patient.id;
      if (patient_id == null) {
        let valid = await this.validate();
        if (!valid) return false;
        let clins = this.props.patient.clinicians;
        let proband = await this.saveProband();
        proband.clinicians = clins;
        await this.saveClinician(proband)
        let payload = { dataKey: "clinicians", data:  clins };
        this.props.dispatch(patient_actions.save_patient_data(payload));
      }

      return true;
    } catch (error) {
      helpers.logger(error);
      this.setState({ errorMessages: [error.message] });
    } finally {
      this.setState({ loading: false });
    }
  }

  async saveClinician(proband){
    if(!('clinicians' in proband)) return;
    try {
      let payload = {
        clinicians: proband.clinicians,
        proband_id: proband.id
      }
      await family_api.save_family_clinicians(payload)

    } catch (err) {
      console.log(err);
    }

  }

  render() {

    return (
      <React.Fragment>
        <NavTop history={this.props.history} />

        <section className="wow fadeIn section">
          <div className="container">
            <div className="section-header">
              <div className="row vcenter">
                <div className="col-md-6 col-xs-12">
                  <h2>New Patient</h2>
                </div>
                <div className="col-md-4 col-md-offset-2 justify-right hidden" />
              </div>
            </div>

            <PatientPersonalData
              isNewPatient={true}
              saveClinician={async (proband) => await this.saveClinician(proband)}
            />

            <ErrorSummary errorMessages={this.state.errorMessages} />

          <div className="gene-disease-container">
            <PatientDiseaseView
                patientRkey={this.props.patientRkey}
                profile={this.getProfile()}
                onBeforeAddDisease={() => this.memberDataShouldBeSaved()}
              />

              <PatientGeneTestView
                patientRkey={this.props.patientRkey}
                profile={this.getProfile()}
                onBeforeAddGene={() => this.memberDataShouldBeSaved()}
              />
          </div>

            <div className="section-footer">
              <button
                onClick={() => this.onClickSave()}
                disabled={this.state.loading}
                className={
                  "btn btn-dark btn-sm " +
                  (this.state.loading ? "disabled" : "")
                }
              >
                <i className="fa fa-arrow-right text-white" />Save and Continue
              </button>

              <button
                onClick={() => this.goToPedigree()}
                disabled={this.state.loading}
                className={
                  "btn btn-light btn-sm " +
                  (this.state.loading ? "disabled" : "")
                }
              >
                <i className="fa text-white" />Go To Pedigree
              </button>

              <ActivityIndicator loading={this.state.loading} modal={true} />
            </div>
          </div>
        </section>

      </React.Fragment>
    );
  }
}

const redux_state = state => ({
  tree: state.patient,
  patient: state.patient.patient,
  patientRkey: state.patient.patient.rkey,
  clinician_id: state.session.user.clinician_id,
  dial_code: state.session.user.dial_code,
});

const redux_actions = dispatch => ({
  dispatch: action => dispatch(action)
});

export default connect(
  redux_state,
  redux_actions
)(PatientPage);
