import React, { Component } from 'react';
import { connect } from "react-redux";
import InputAndOr from './input-andor';
import disease_api from "../../api/disease-api";
import * as static_list_actions from "../../store/static_list/actions";
import ControlSelectDiseases from '../control-select-diseases';

class InputCancer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disease_options: [],
      other_options: [],
      umls_option: {},
      static_selected_disease: null,
      umlsLoading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    // this.loadDiseasesFromAPI = this.loadDiseasesFromAPI.bind(this);
    this.buildDiseaseDropdown = this.buildDiseaseDropdown.bind(this);
  }

  async componentDidMount() {
    await this.buildDiseaseDropdown()
  }

  async handleChange(e) {
    let item = Object.assign({}, this.props.item, {[e.target.name]: e.target.value})
    let targetID = e.currentTarget.id
    await this.props.onChange(item)
    document.getElementById(targetID).focus();
  }

  handleSelectChange(disease, name) {
    let disease_value = (disease !== null) ? disease.value : null;
    let disease_name = (disease !== null) ? disease.label : null;
    let item = Object.assign({}, this.props.item, {[name]: disease_value});
    const is_from_umls = this.isDiseaseFromUmls(disease.value);
    if(is_from_umls){
      this.setState({umls_option: disease })
    }

    this.props.onChange(item);
  }

  isDiseaseFromUmls(disease_id) {
    disease_id = disease_id + "";
    const is_from_umls = disease_id.substr(0,1) === "C";
    return is_from_umls
  }

  // async loadDiseasesFromAPI() {
  //   try {
  //     let diseases = this.props.static_list.diseases;
  //     if (diseases.length === 0) {
  //       let data = await disease_api.get_disease();
  //       let umls = await disease_api.get_umls_diseases(this.props.session.user.clinician_id)
  //       diseases = data.diseases.sort((a, b) => {
  //         if (a.disease_name < b.disease_name) return -1;
  //         if (a.disease_name > b.disease_name) return 1;
  //         return 0;
  //       });


  //       const uniqueUMLS = Array.from(new Set(umls.umls_diseases.map(a=>a.umls_name))).map(name=>{
  //         return umls.umls_diseases.find(a =>a.umls_name === name)
  //       })

  //       let umls_diseases = uniqueUMLS.sort((a, b) => {
  //         if (a.umls_name < b.umls_name) return -1;
  //         if (a.umls_name > b.umls_name) return 1;
  //         return 0;
  //       })
  //       await this.props.dispatch(static_list_actions.initialize_diseases(diseases));
  //       await this.props.dispatch(static_list_actions.intitialize_umls(umls_diseases))
  //     }
  //     this.buildDiseaseDropdown();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  async buildDiseaseDropdown() {
    // Arrange by group
    let diseases = this.props.static_list.diseases;
    let umlsOptions = []
    let umls_options = this.props.static_list.umls_diseases

    let diseases_by_category = {};
    for (var disease of diseases) {
      if (disease.disease_type in diseases_by_category) {
        diseases_by_category[disease.disease_type].push(disease);
      } else {
        diseases_by_category[disease.disease_type] = [disease];
      }
    }

    //Parse and Format
    let options = []; //[{ value: '', label: ' ' }]
    let other_options = [];
    let first_options = [
      {value: 'any_disease', label: 'Any Disease'},
      {value: 'no_disease', label: 'No Disease'},
    ];

    options.push({
      label: '',
      options: first_options
    });

    other_options = other_options.concat(first_options);

    let all_diseases_by_category = [];

    for (var category_name in diseases_by_category) {
      let fmt_category_name = category_name.replace("-", " ").replace("_", " ").toUpperCase()
      if(fmt_category_name == 'CANCER POLYPS') fmt_category_name = "CANCER/POLYPS"

      let extra_options = [
        {value: 'any_'+category_name, label: 'Any ' + fmt_category_name + ' Condition'},
        {value: 'no_'+category_name, label: 'No ' + fmt_category_name + ' Condition'}
      ];

      other_options = other_options.concat(extra_options);

      const cat_options = diseases_by_category[category_name].map(item => ({
        value: item.id,
        label: item.disease_name
      }));

      //collect all unique diseases of all probands
      all_diseases_by_category = all_diseases_by_category.concat(diseases_by_category[category_name])

      extra_options = extra_options.concat(cat_options);
      options.push({
        label: fmt_category_name,
        options: extra_options
      });
    }

    //Sort the categories
    options = options.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

    let intial_umls = [
      {value: 'any_UMLS', label: 'Any UMLS disease'},
      {value: 'no_UMLS', label: 'No UMLS disease'}
    ]

    other_options =  other_options.concat(intial_umls);

    intial_umls.forEach(umls =>{
      umlsOptions.push({
        value: umls.value ,
        label: umls.label
      })
    })

    for(var umls of umls_options){
      if( umls.umls_id !== null && umls.umls_id !== undefined) {
        umlsOptions.push({
          value: umls.umls_id,
          label: umls.umls_name
        });
      }
    }

    //filter out the other diseases on the umls to avoid duplicates
    umlsOptions = umlsOptions.filter((umls) => !all_diseases_by_category.find((disease) => umls.value === disease.id));

    if (umls_options.length > 0) {
      options.push({
        label: "UMLS",
        options: umlsOptions
      });
    }

    // Add empty option
    // options = [{ value: "", label: " " }].concat(options);
    if (options.length > 1) {
      this.setState({ disease_options: options, other_options: other_options });
    }
  }

  render() {
    let disease = null;
    if(this.props.item.cancerType) {
      const disease_id = parseInt(this.props.item.cancerType, 10);
      let static_selected_disease = this.props.static_list.diseases.find(
        item => item.id === disease_id
      );

      if (static_selected_disease) {
        disease = {value: static_selected_disease.id, label: static_selected_disease.disease_name};
      }
      else{
          static_selected_disease = null;
          const is_from_umls = this.isDiseaseFromUmls(this.props.item.cancerType);
          let umls_id = this.props.item.cancerType
          if (is_from_umls) {
            let static_selected_disease = this.props.static_list.umls_diseases.find(
              item => item.umls_id === umls_id
            );
          if(static_selected_disease){
            disease = {value: static_selected_disease.umls, label: static_selected_disease.umls_name};
          }
      }
    }
      if (disease === null) {
        for(let option of this.state.other_options) {
          if(option.value === this.props.item.cancerType) {
            disease = option;
          }
        }
      }
    }

    const operator_options = [
      {value: 'any_age', label: 'At any age'},
      {value: '<=', label: '≤'},
      {value: '<', label: '<'},
      {value: '=', label: '='},
      {value: '>', label: '>'},
      {value: '>=', label: '≥'},
    ];

    let chosen_operator = null;
    for(var operator of operator_options) {
      if(this.props.item.relationalOperator && this.props.item.relationalOperator === operator.value) {
        chosen_operator = operator;
      }
    }

    const regex = /^no_.*/g;
    let ageDisabled = false;
    let disabled = false;
    if (this.props.item.cancerType && typeof(this.props.item.cancerType) === 'string' && this.props.item.cancerType.search(regex) !== -1) {
      disabled = true;
      ageDisabled = true
    }
    if(this.props.item.relationalOperator == 'any_age'){
      ageDisabled = true
    }

    return (
      <React.Fragment >

        { this.props.itemIndex > 0 &&
          <InputAndOr
            value={this.props.item.andOr}
            onChange={(e) => this.handleChange(e)}
          />
        }

        <div className="col-md-5 col-xs-12 no-padding-left">
          <div className="custom-select">
            <ControlSelectDiseases
              options={this.state.disease_options}
              onChange={disease => this.handleSelectChange(disease, 'cancerType')}
              value={disease}
              placeholder="Disease"
              autofocus={false}
            />
          </div>
        </div>

        <div className="col-md-2 col-xs-12 no-padding-left">
          <div className="custom-select">
            <ControlSelectDiseases
              disabled={disabled}
              options={operator_options}
              onChange={disease => this.handleSelectChange(disease, 'relationalOperator')}
              value={chosen_operator}
              placeholder=""
              autofocus={false}
            />
          </div>
        </div>

        <div className="col-md-2 col-xs-12 no-padding-left">
          <input
            disabled={ageDisabled}
            id={`age-input-cancer-${this.props.setIndex}-${this.props.itemIndex}`}
            name="age"
            value={this.props.item.age}
            onChange={(e) => this.handleChange(e)}
            type="text" className="form-control" placeholder="Age at Dx" />
        </div>

        <div className="col-md-1 col-xs-12 no-padding-left margin-one">

          {this.props.showDeleteButton &&
            <a onClick={() => this.props.onDeleteItemClick()}
              href="#"><i className="fa fa-lg fa-close"></i></a>
          }

          {this.props.showAddButton &&
            <a href="#" onClick={() => this.props.onAddItemClick()}>
              <i className="fa fa-lg fa-plus"></i>
            </a>
          }

        </div>
      </React.Fragment>
    )
  }
}

const redux_state = state => ({
  static_list: state.static_list,
  session: state.session
});

const redux_actions = dispatch => ({
  dispatch: action => dispatch(action)
});

export default connect(
  redux_state,
  redux_actions
)(InputCancer);
