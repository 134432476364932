//@ts-ignore
import { v4 as uuidv4 } from 'uuid';
//@ts-ignore
import {cloneDeep} from 'lodash'

const top_level_p_member_query_variable = {
    inputSet: {
        variableName: "family",
        fieldName: "members",
        expression_type: "variableField"
    },
    the_Variable: {
        name: "member",
        type: "member",
        expression_type: "variable"
    },
    operator: "Exists",
    expression_type: "expression",
    expressionList: []
}

const p_member_disease_qualifier = {
    operator: "Exists",
    expression_type: "expression",
    the_Variable: {
        name: "d",
        type: "disease",
        expression_type: "variable"
    },
    inputSet: {
        variableName: "member",
        fieldName: "diseases",
        expression_type: "variableField"
    },
    expressionList: []
}

const p_member_gene_qualifier : {operator: string, expression_type: string, the_Variable: {name: string, type: string, expression_type: string}, inputSet: {variableName: string, fieldName: string, expression_type: string}, expressionList: []} = {
    operator: "Exists",
    expression_type: "expression",
    the_Variable: {
        name: "g",
        type: "gene",
        expression_type: "variable"
    },
    inputSet: {
        variableName: "member",
        fieldName: "genes",
        expression_type: "variableField"
    },
    expressionList: []
}

const p_member_referral_qualifier : {operator: string, expression_type: string, the_Variable: {name: string, type: string, expression_type: string}, inputSet: {variableName: string, fieldName: string, expression_type: string}, expressionList: []} = {
    operator: "Exists",
    expression_type: "expression",
    the_Variable: {
        name: "r",
        type: "referralcriteria",
        expression_type: "variable"
    },
    inputSet: {
        variableName: "member",
        fieldName: "referralcriteria",
        expression_type: "variableField"
    },
    expressionList: []
}

const disease_filter_item = [
    {
        operator: "=",
        expression_type: "expression",
        expressionList: [
            {
                variableName: "d",
                fieldName: "",
                expression_type: "variableField"
            },
            {
                value: "",
                expression_type: "value"
            }
        ]
    },
    {
        operator: "", //= >= <= etc
        expression_type: "expression",
        expressionList: [
            {
                variableName: "d",
                fieldName: "age_diagnosed",
                expression_type: "variableField"
            },
            {
                value: 0,
                expression_type: "value"
            }
        ]
    }
]

const gene_filter_item = [
    {
        operator: "=",
        expression_type: "expression",
        expressionList: [
            {
                variableName: "g",
                fieldName: "", // either gene_id or umls_id
                expression_type: "variableField"
            },
            {
                value: "",
                expression_type: "value"
            }
        ]
    },
    {
        operator: "=", //= >= <= etc
        expression_type: "expression",
        expressionList: [
            {
                variableName: "g",
                fieldName: "result",
                expression_type: "variableField"
            },
            {
                value: "",
                expression_type: "value"
            }
        ]
    },
    {
        operator: "=", //= >= <= etc
        expression_type: "expression",
        expressionList: [
            {
                variableName: "g",
                fieldName: "variant",
                expression_type: "variableField"
            },
            {
                value: "",
                expression_type: "value"
            }
        ]
    }
]

const referral_criteria_filter_item = {
    operator: "in",
    expression_type: "expression",
    expressionList: [
        {
            value: "",
            expression_type: "value"
        },
        {
            variableName: "r",
            fieldName: "condition_name",
            expression_type: "variableField"
        }
    ]
}

const filter = {
    operator: "", //and or
    expression_type: "expression",
    expressionList: []
}

let multiple_filter_set_level = {
    operator: "", // and or
    expression_type: "expression",
    expressionList: [
    ]
}

const Count_condition = {
    operator: "",
    expression_type: "expression",
    expressionList: [
        {
            operator: "Count",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "member",
                    fieldName: "",
                    expression_type: "variableField"
                }
            ]
        },
        {
            value: 0,
            expression_type: "value"
        }
    ]
}

const Count_condition_with_filter = {
    operator: "",
    expression_type: "expression",
    expressionList: [
        {
            operator: "Count",
            expression_type: "expression",
            expressionList: [
                {
                    operator: "Filter",
                    expression_type: "expression",
                    the_Variable: {
                        name: "d",
                        type: "disease",
                        expression_type: "variable"
                    },
                    inputSet: {
                        variableName: "member",
                        fieldName: "diseases",
                        expression_type: "variableField"
                    },
                    expressionList: [
                        {
                            operator: "=",
                            expression_type: "expression",
                            expressionList: [
                                {
                                    variableName: "d",
                                    fieldName: "code",
                                    expression_type: "variableField"
                                },
                                {
                                    value: "",
                                    expression_type: "value"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            value: 0,
            expression_type: "value"
        }
    ]
}

const Count_condition_subconcept = {
    operator: "",
    expression_type: "expression",
    expressionList: [
        {
            operator: "Count",
            expression_type: "expression",
            expressionList: [
                {
                    operator: "Filter",
                    expression_type: "expression",
                    the_Variable: {
                        name: "d",
                        type: "disease",
                        expression_type: "variable"
                    },
                    inputSet: {
                        variableName: "member",
                        fieldName: "diseases",
                        expression_type: "variableField"
                    },
                    expressionList: [
                        {
                            operator: "SubConcept",
                            expression_type: "expression",
                            expressionList: [
                                {
                                    variableName: "d",
                                    fieldName: "umls_id",
                                    expression_type: "variableField"
                                },
                                {
                                    value: "", // umls id
                                    expression_type: "value"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            value: 0,
            expression_type: "value"
        }
    ]
}

const Count_condition_or_subconcept = {
    operator: "",
    expression_type: "expression",
    expressionList: [
        {
            operator: "Count",
            expression_type: "expression",
            expressionList: [
                {
                    operator: "Filter",
                    expression_type: "expression",
                    the_Variable: {
                        name: "dise",
                        type: "disease",
                        expression_type: "variable"
                    },
                    inputSet: {
                        variableName: "member",
                        fieldName: "diseases",
                        expression_type: "variableField"
                    },
                    expressionList: [
                        {
                            operator: "or",
                            expression_type: "expression",
                            expressionList: []
                        }
                    ]
                }
            ]
        },
        {
            value: 0,
            expression_type: "value"
        }
    ]
}



const Count_condition_for_family_search = {
    operator: "",
    expression_type: "expression",
    expressionList: [
        {
            operator: "Count",
            expression_type: "expression",
            expressionList: [
                {
                    operator: "Filter",
                    expression_type: "expression",
                    the_Variable: {
                        name: "member",
                        type: "member",
                        expression_type: "variable"
                    },
                    inputSet: {
                        variableName: "family",
                        fieldName: "members",
                        expression_type: "variableField"
                    },
                    expressionList: [
                    ]
                }
            ]
        },
        {
            value: 0,
            expression_type: "value"
        }
    ]
}

const family_relationship_filter = {
    operator: "=",
    expression_type: "expression",
    expressionList: [
        {
            variableName: "member",
            fieldName: "", //is_blood_related_to_proband or side
            expression_type: "variableField"
        },
        {
            value: true,
            expression_type: "value"
        }
    ]
}

const maternal_only_filter = {
    operator: "or",
    expression_type: "expression",
    expressionList: [
        {
            operator: "=",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "member",
                    fieldName: "side",
                    expression_type: "variableField"
                },
                {
                    value: "maternal",
                    expression_type: "value"
                }
            ]
        },
        {
            operator: "=",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "member",
                    fieldName: "side",
                    expression_type: "variableField"
                },
                {
                    value: "both",
                    expression_type: "value"
                }
            ]
        }
    ]
}

const paternal_only_filter = {
    operator: "or",
    expression_type: "expression",
    expressionList: [
        {
            operator: "=",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "member",
                    fieldName: "side",
                    expression_type: "variableField"
                },
                {
                    value: "paternal",
                    expression_type: "value"
                }
            ]
        },
        {
            operator: "=",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "member",
                    fieldName: "side",
                    expression_type: "variableField"
                },
                {
                    value: "both",
                    expression_type: "value"
                }
            ]
        }
    ]
}

const same_side_of_the_family_filter = {
    operator: "or",
    expression_type: "expression",
    expressionList: [
        {
            operator: "",
            expression_type: "expression",
            expressionList: [
                {
                    operator: "Count",
                    expression_type: "expression",
                    expressionList: [
                        {
                            operator: "Filter",
                            expression_type: "expression",
                            the_Variable: {
                                name: "x",
                                type: "member",
                                expression_type: "variable"
                            },
                            inputSet: {
                                variableName: "family",
                                fieldName: "members",
                                expression_type: "variableField"
                            },
                            expressionList: [
                                {
                                    operator: "and",
                                    expression_type: "expression",
                                    expressionList: [
                                        {
                                            operator: "and",
                                            expression_type: "expression",
                                            expressionList: [
                                                {
                                                    operator: "=",
                                                    expression_type: "expression",
                                                    expressionList: [
                                                        {
                                                            variableName: "x",
                                                            fieldName: "is_blood_related_to_proband",
                                                            expression_type: "variableField"
                                                        },
                                                        {
                                                            value: true,
                                                            expression_type: "value"
                                                        }
                                                    ]
                                                },
                                                {
                                                    operator: "or",
                                                    expression_type: "expression",
                                                    expressionList: [
                                                        {
                                                            operator: "=",
                                                            expression_type: "expression",
                                                            expressionList: [
                                                                {
                                                                    variableName: "x",
                                                                    fieldName: "side",
                                                                    expression_type: "variableField"
                                                                },
                                                                {
                                                                    value: "paternal",
                                                                    expression_type: "value"
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            operator: "=",
                                                            expression_type: "expression",
                                                            expressionList: [
                                                                {
                                                                    variableName: "x",
                                                                    fieldName: "side",
                                                                    expression_type: "variableField"
                                                                },
                                                                {
                                                                    value: "both",
                                                                    expression_type: "value"
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                            ]
                                        },
                                        {
                                            operator: "and",
                                            expression_type: "expression",
                                            expressionList: []
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    value: 0,
                    expression_type: "value"
                }
            ]
        },
        {
            operator: "",
            expression_type: "expression",
            expressionList: [
                {
                    operator: "Count",
                    expression_type: "expression",
                    expressionList: [
                        {
                            operator: "Filter",
                            expression_type: "expression",
                            the_Variable: {
                                name: "y",
                                type: "member",
                                expression_type: "variable"
                            },
                            inputSet: {
                                variableName: "family",
                                fieldName: "members",
                                expression_type: "variableField"
                            },
                            expressionList: [
                                {
                                    operator: "and",
                                    expression_type: "expression",
                                    expressionList: [
                                        {
                                            operator: "and",
                                            expression_type: "expression",
                                            expressionList: [
                                                {
                                                    operator: "=",
                                                    expression_type: "expression",
                                                    expressionList: [
                                                        {
                                                            variableName: "y",
                                                            fieldName: "is_blood_related_to_proband",
                                                            expression_type: "variableField"
                                                        },
                                                        {
                                                            value: true,
                                                            expression_type: "value"
                                                        }
                                                    ]
                                                },
                                                {
                                                    operator: "or",
                                                    expression_type: "expression",
                                                    expressionList: [
                                                        {
                                                            operator: "=",
                                                            expression_type: "expression",
                                                            expressionList: [
                                                                {
                                                                    variableName: "y",
                                                                    fieldName: "side",
                                                                    expression_type: "variableField"
                                                                },
                                                                {
                                                                    value: "maternal",
                                                                    expression_type: "value"
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            operator: "=",
                                                            expression_type: "expression",
                                                            expressionList: [
                                                                {
                                                                    variableName: "y",
                                                                    fieldName: "side",
                                                                    expression_type: "variableField"
                                                                },
                                                                {
                                                                    value: "both",
                                                                    expression_type: "value"
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                            ]
                                        },
                                        {
                                            operator: "and",
                                            expression_type: "expression",
                                            expressionList: []
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    value: 0,
                    expression_type: "value"
                }
            ]
        }
    ]
}


const Subconcept_operator = {
    operator: "SubConcept",
    expression_type: "expression",
    expressionList: [
        {
            variableName: "dise",
            fieldName: "umls_id",
            expression_type: "variableField"
        },
        {
            value: "", // umls id
            expression_type: "value"
        }
    ]
}

export function buildQueryPayload(filterType: string, filterSets: any, familyFilters: any, umls_genetic_testings: any, umls_diseases: any, cache: boolean){

    let kind = 'proband'

    if(filterType == 'probands'){
        kind = 'proband'
    }
    else if(filterType == 'everyone'){
        kind = 'member'
    }
    else if(filterType == 'families'){
        kind = 'family'
    }

    let top_level_p_member_query_variable_clone = cloneDeep(top_level_p_member_query_variable)

    let is_same_side_of_the_family_filter = kind == 'family' && familyFilters.relationship == 'same_side_of_the_family' && familyFilters.number_of_people_to_meet_criteria.relationalOperator != 'any' && familyFilters.number_of_people_to_meet_criteria.value

    let final_payload = {
        name: `Query-Payload-${uuidv4()}`,
        kind: kind,
        // query: kind == 'member' ? {} : top_level_p_member_query_variable_clone,
        query: kind == 'member' || kind == 'proband' || is_same_side_of_the_family_filter ? {} : top_level_p_member_query_variable_clone,
        cache: cache,
        async: true
    }

    let set_index = 0;
    let family_filter_count_container_exists:boolean = false;
    for (let filter_set of filterSets){

        let filterSets_andOr = filterSets[1] ? filterSets[1].andOr : filter_set.andOr
        let filterItems = filter_set.items

        // let nest_count = kind == 'member' ? 0 : 1;
        let nest_count = kind == 'member' || kind == 'proband' || is_same_side_of_the_family_filter ? 0 : 1;


        // if(kind == 'member'){
        if(kind == 'member' || kind == 'proband'){
            let cloned_multiple_filter_set_level = cloneDeep(multiple_filter_set_level)
            cloned_multiple_filter_set_level.operator = filterSets_andOr
            if (!('expressionList' in final_payload.query)){
                final_payload.query = cloned_multiple_filter_set_level;
            }
            nest_count += 1;
        }
        else{

            if (is_same_side_of_the_family_filter){
                let cloned_same_side_of_the_family_filter = cloneDeep(same_side_of_the_family_filter)

                let operator = familyFilters.number_of_people_to_meet_criteria.relationalOperator
                let meet_criteria_value = familyFilters.number_of_people_to_meet_criteria.value

                cloned_same_side_of_the_family_filter.expressionList[0].operator = operator
                cloned_same_side_of_the_family_filter.expressionList[0].expressionList[1].value = parseInt(meet_criteria_value, 10)
                cloned_same_side_of_the_family_filter.expressionList[1].operator = operator
                cloned_same_side_of_the_family_filter.expressionList[1].expressionList[1].value = parseInt(meet_criteria_value, 10)
                final_payload.query = cloned_same_side_of_the_family_filter;
            }

            else{
                if(filterSets.length > 1){
                    let cloned_multiple_filter_set_level = cloneDeep(multiple_filter_set_level)
                    cloned_multiple_filter_set_level.operator = filterSets_andOr

                    if(final_payload.query.expressionList.length == 0){
                        //@ts-ignore
                        final_payload.query.expressionList.push(cloned_multiple_filter_set_level)
                    }
                    nest_count += 1;
                }
            }
        }


        let disease_field_type_exists = filterItems.find((item: any) => item.fieldType == 'disease')
        let genetic_testing_field_type_exists = filterItems.find((item: any) => item.fieldType == 'genetic_testing')
        let referral_criteria_field_type_exists = filterItems.find((item: any) => item.fieldType == 'referral_criteria')
        // additional field types checks

        let types_existing: string[] = []

        if (disease_field_type_exists){
            types_existing.push('disease')
        }
        if (genetic_testing_field_type_exists){
            types_existing.push('genetic_testing')
        }
        if (referral_criteria_field_type_exists){
            types_existing.push('referral_criteria')
        }

        let types_pushed = 0;
        // for(let type of types_existing){

        //     let no_disease_filters = filterItems.filter((item: any) => item.fieldType == 'disease' && item.cancerType == 'no_disease')
        //     let not_tested_gene_filters = filterItems.filter((item: any) => item.fieldType == 'genetic_testing' && item.gene == 'not-tested')
        //     let none_referral_filters = filterItems.filter((item: any) => item.fieldType == 'referral_criteria' && item.referral == 'none')

        //     if(filterSets.length > 1){
        //         // @ts-ignore
        //         let type_reference = final_payload.query.expressionList[0].expressionList
        //         if(type == 'disease'){
        //             let p_member_disease_qualifier_clone = cloneDeep(p_member_disease_qualifier)
        //             for(let i = 0; i < types_pushed; i++){
        //                 if(type_reference[set_index]){
        //                     type_reference = type_reference[set_index].expressionList
        //                 }
        //                 else{
        //                     type_reference = type_reference[0].expressionList
        //                 }
        //             }

        //             // let new_filter = cloneDeep(filter)
        //             // new_filter.andOr = filterItems[1].andOr ? filterItems[1].andOr : filterItems[0].andOr
        //             // if(no_disease_filters.length > 0){
        //             //     for(let no_disease_filter of no_disease_filters){
        //             //         let Count_condition_no_disease = cloneDeep(Count_condition)
        //             //         Count_condition_no_disease.operator = '='
        //             //         Count_condition_no_disease.expressionList[0].expressionList[0].fieldName = 'diseases'
        //             //         Count_condition_no_disease.expressionList[1].value = 0
        //             //         new_filter.expressionList.push(Count_condition_no_disease)
        //             //     }
        //             //     nest_count += 1;
        //             // }
        //             // @ts-ignore
        //             type_reference.push(p_member_disease_qualifier_clone)
        //             types_pushed += 1;
        //         }
        //         else if(type == 'genetic_testing'){
        //             console.log('type genetic testing')
        //             let p_member_gene_qualifier_clone = cloneDeep(p_member_gene_qualifier)
        //             for(let i = 0; i < types_pushed; i++){
        //                 if(type_reference[set_index]){
        //                     type_reference = type_reference[set_index].expressionList
        //                 }
        //                 else{
        //                     type_reference = type_reference[0].expressionList
        //                 }
        //             }

        //             // @ts-ignore
        //             type_reference.push(p_member_gene_qualifier_clone)
        //             types_pushed += 1;
        //         }
        //         else if(type == 'referral_criteria'){
        //             let p_member_referral_qualifier_clone = cloneDeep(p_member_referral_qualifier)
        //             for(let i = 0; i < types_pushed; i++){
        //                 if(type_reference[set_index]){
        //                     type_reference = type_reference[set_index].expressionList
        //                 }
        //                 else{
        //                     type_reference = type_reference[0].expressionList
        //                 }
        //             }
        //             // @ts-ignore
        //             type_reference.push(p_member_referral_qualifier_clone)
        //             types_pushed += 1;
        //         }
        //         nest_count += 1;
        //     }
        //     else{
        //         // @ts-ignore
        //         let type_reference = final_payload.query.expressionList
        //         if(type == 'disease'){
        //             let p_member_disease_qualifier_clone = cloneDeep(p_member_disease_qualifier)
        //             for(let i = 0; i < types_pushed; i++){
        //                 // @ts-ignore
        //                 type_reference = type_reference[0].expressionList
        //             }
        //             // @ts-ignore
        //             type_reference.push(p_member_disease_qualifier_clone)
        //             types_pushed += 1;
        //         }
        //         else if(type == 'genetic_testing'){
        //             let p_member_gene_qualifier_clone = cloneDeep(p_member_gene_qualifier)
        //             for(let i = 0; i < types_pushed; i++){
        //                 // @ts-ignore
        //                 type_reference = type_reference[0].expressionList
        //             }
        //             // @ts-ignore
        //             type_reference.push(p_member_gene_qualifier_clone)
        //             types_pushed += 1;
        //         }
        //         else if(type == 'referral_criteria'){
        //             let p_member_referral_qualifier_clone = cloneDeep(p_member_referral_qualifier)
        //             for(let i = 0; i < types_pushed; i++){
        //                 // @ts-ignore
        //                 type_reference = type_reference[0].expressionList
        //             }
        //             // @ts-ignore
        //             type_reference.push(p_member_referral_qualifier_clone)
        //             types_pushed += 1;
        //         }
        //         nest_count += 1;
        //     }
        // }

        let multiple_filter_item_container_exists:boolean = false;
        let filter_index = 0;
        for (let filter_item of filterItems){
            let reference = final_payload.query.expressionList
            for(let i = 1; i <= nest_count - 1; i++) {
                // @ts-ignore
                if(reference[set_index]){
                    // @ts-ignore
                    reference = reference[set_index].expressionList
                }
                else if (reference[0]){
                    // @ts-ignore
                    reference = reference[0].expressionList
                }
            }


            let paternal_both_reference = null;
            let maternal_both_reference = null;

            if (is_same_side_of_the_family_filter){
                paternal_both_reference = final_payload.query.expressionList[0].expressionList[0].expressionList[0].expressionList[0].expressionList[1].expressionList
                maternal_both_reference = final_payload.query.expressionList[1].expressionList[0].expressionList[0].expressionList[0].expressionList[1].expressionList
            }

            if(kind == 'family' && familyFilters.number_of_people_to_meet_criteria.relationalOperator != 'any' && familyFilters.number_of_people_to_meet_criteria.value && familyFilters.relationship != 'same_side_of_the_family'){

                if(!family_filter_count_container_exists){
                    let cloned_Count_condition_for_family_search = cloneDeep(Count_condition_for_family_search);
                    cloned_Count_condition_for_family_search.operator = familyFilters.number_of_people_to_meet_criteria.relationalOperator;
                    cloned_Count_condition_for_family_search.expressionList[1].value = parseInt(familyFilters.number_of_people_to_meet_criteria.value, 10);

                    if(filterSets.length > 1){
                        cloned_Count_condition_for_family_search.expressionList[0].expressionList[0].expressionList = cloneDeep(final_payload.query.expressionList)
                        final_payload.query.expressionList = [cloned_Count_condition_for_family_search];
                    }
                    else{
                        reference.push(cloned_Count_condition_for_family_search);
                    }
                    family_filter_count_container_exists = true;
                }

                if(filterSets.length > 1){
                    reference = final_payload.query.expressionList[0].expressionList[0].expressionList[0].expressionList[0].expressionList;
                }
                else{
                    reference = reference[0].expressionList[0].expressionList[0].expressionList;
                }
            }


            //need another condition if there are more than one filter items on a filter set, then we will need a new nest, to combine the filter items
            if(filterItems.length > 1){

                if(!multiple_filter_item_container_exists){
                    let cloned_multiple_filter_set_level = cloneDeep(multiple_filter_set_level)
                    cloned_multiple_filter_set_level.operator = filterItems[1] ? filterItems[1].andOr : filter_item.andOr

                    if(is_same_side_of_the_family_filter){
                        let cloned_multiple_filter_set_level1 = cloneDeep(multiple_filter_set_level)
                        cloned_multiple_filter_set_level1.operator = filterItems[1] ? filterItems[1].andOr : filter_item.andOr
                        let cloned_multiple_filter_set_level2 = cloneDeep(multiple_filter_set_level)
                        cloned_multiple_filter_set_level2.operator = filterItems[1] ? filterItems[1].andOr : filter_item.andOr
                        paternal_both_reference.push(cloned_multiple_filter_set_level1)
                        maternal_both_reference.push(cloned_multiple_filter_set_level2)
                    }
                    else{
                        //@ts-ignore
                        reference.push(cloned_multiple_filter_set_level)
                    }
                }

                if(is_same_side_of_the_family_filter){

                    if(paternal_both_reference[set_index]){
                        // @ts-ignore
                        paternal_both_reference = paternal_both_reference[set_index].expressionList
                    }
                    else{
                        // @ts-ignore
                        paternal_both_reference = paternal_both_reference[0].expressionList
                    }

                    if(maternal_both_reference[set_index]){
                        // @ts-ignore
                        maternal_both_reference = maternal_both_reference[set_index].expressionList
                    }
                    else{
                        // @ts-ignore
                        maternal_both_reference = maternal_both_reference[0].expressionList
                    }

                }
                else{

                    if(reference[set_index]){
                        // @ts-ignore
                        reference = reference[set_index].expressionList
                    }
                    else{
                        // @ts-ignore
                        reference = reference[0].expressionList
                    }

                }

                multiple_filter_item_container_exists = true;
            }


            // setting of qualifiers (exists) for each filter item
            if(filter_item.fieldType == "disease"){
                if(filter_item.cancerType != 'no_disease' && filter_item.cancerType != 'no_UMLS' && filter_item.cancerType != 'no_blood' && filter_item.cancerType != 'no_cancer-polyps' && filter_item.cancerType != 'no_heart' && filter_item.cancerType != 'no_mental-behavioral-neurological' && filter_item.cancerType != 'no_other_conditions'){
                    let p_member_disease_qualifier_clone = cloneDeep(p_member_disease_qualifier)
                    if(is_same_side_of_the_family_filter){
                        let p_member_disease_qualifier_clone1 = cloneDeep(p_member_disease_qualifier)
                        p_member_disease_qualifier_clone1.inputSet.variableName = 'x'
                        let p_member_disease_qualifier_clone2 = cloneDeep(p_member_disease_qualifier)
                        p_member_disease_qualifier_clone2.inputSet.variableName = 'y'

                        paternal_both_reference.push(p_member_disease_qualifier_clone1)
                        maternal_both_reference.push(p_member_disease_qualifier_clone2)
                    }
                    else{
                        reference.push(p_member_disease_qualifier_clone)
                    }
                    let set_or_item = filterItems.length > 1 ? 'item' : 'set'
                    let index = set_or_item == 'set' ? set_index : filter_index

                    if(is_same_side_of_the_family_filter){
                        if(paternal_both_reference[index]){
                            // @ts-ignore
                            paternal_both_reference = paternal_both_reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            paternal_both_reference = paternal_both_reference[0].expressionList
                        }

                        if(maternal_both_reference[index]){
                            // @ts-ignore
                            maternal_both_reference = maternal_both_reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            maternal_both_reference = maternal_both_reference[0].expressionList
                        }
                    }
                    else{
                        if(reference[index]){
                            // @ts-ignore
                            reference = reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            reference = reference[0].expressionList
                        }
                    }
                }
            }
            else if(filter_item.fieldType == "genetic_testing"){
                if(filter_item.gene != 'not-tested'){
                    let p_member_gene_qualifier_clone = cloneDeep(p_member_gene_qualifier)
                    if(is_same_side_of_the_family_filter){
                        let p_member_gene_qualifier_clone1 = cloneDeep(p_member_gene_qualifier)
                        p_member_gene_qualifier_clone1.inputSet.variableName = 'x'
                        let p_member_gene_qualifier_clone2 = cloneDeep(p_member_gene_qualifier)
                        p_member_gene_qualifier_clone2.inputSet.variableName = 'y'

                        paternal_both_reference.push(p_member_gene_qualifier_clone1)
                        maternal_both_reference.push(p_member_gene_qualifier_clone2)
                    }
                    else{
                        reference.push(p_member_gene_qualifier_clone)
                    }
                    let set_or_item = filterItems.length > 1 ? 'item' : 'set'
                    let index = set_or_item == 'set' ? set_index : filter_index

                    if(is_same_side_of_the_family_filter){
                        if(paternal_both_reference[index]){
                            // @ts-ignore
                            paternal_both_reference = paternal_both_reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            paternal_both_reference = paternal_both_reference[0].expressionList
                        }

                        if(maternal_both_reference[index]){
                            // @ts-ignore
                            maternal_both_reference = maternal_both_reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            maternal_both_reference = maternal_both_reference[0].expressionList
                        }
                    }
                    else{
                        if(reference[index]){
                            // @ts-ignore
                            reference = reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            reference = reference[0].expressionList
                        }
                    }
                }
            }
            else if((filter_item.fieldType == "referral_criteria")){
                if(filter_item.referral != 'none'){
                    let p_member_referral_qualifier_clone = cloneDeep(p_member_referral_qualifier)
                    if(is_same_side_of_the_family_filter){
                        let p_member_referral_qualifier_clone1 = cloneDeep(p_member_referral_qualifier)
                        p_member_referral_qualifier_clone1.inputSet.variableName = 'x'
                        let p_member_referral_qualifier_clone2 = cloneDeep(p_member_referral_qualifier)
                        p_member_referral_qualifier_clone2.inputSet.variableName = 'y'

                        paternal_both_reference.push(p_member_referral_qualifier_clone1)
                        maternal_both_reference.push(p_member_referral_qualifier_clone2)
                    }
                    else{
                        reference.push(p_member_referral_qualifier_clone)
                    }
                    let set_or_item = filterItems.length > 1 ? 'item' : 'set'
                    let index = set_or_item == 'set' ? set_index : filter_index

                    if(is_same_side_of_the_family_filter){
                        if(paternal_both_reference[index]){
                            // @ts-ignore
                            paternal_both_reference = paternal_both_reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            paternal_both_reference = paternal_both_reference[0].expressionList
                        }

                        if(maternal_both_reference[index]){
                            // @ts-ignore
                            maternal_both_reference = maternal_both_reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            maternal_both_reference = maternal_both_reference[0].expressionList
                        }
                    }
                    else{
                        if(reference[index]){
                            // @ts-ignore
                            reference = reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            reference = reference[0].expressionList
                        }
                    }
                }
            }

            if(filter_item.fieldType == "disease"){
                let disease_filter_clone = cloneDeep(filter)
                let disease_filter_item_clone = cloneDeep(disease_filter_item)

                let is_umls = umls_diseases.find((disease: any) => disease.umls_id && disease.umls_id == filter_item.cancerType)

                disease_filter_item_clone[0].expressionList[1].value = filter_item.cancerType //filter_item.cancerType
                disease_filter_item_clone[0].expressionList[0].fieldName = is_umls ? 'umls_id' : 'code'

                disease_filter_item_clone[1].operator = filter_item.relationalOperator
                disease_filter_item_clone[1].expressionList[1].value = parseInt(filter_item.age, 10)

                if (filter_item.relationalOperator == 'any_age' || filter_item.cancerType == 'no_disease' || filter_item.cancerType == 'no_UMLS' || filter_item.cancerType == 'no_blood' || filter_item.cancerType == 'no_cancer-polyps' || filter_item.cancerType == 'no_heart' || filter_item.cancerType == 'no_mental-behavioral-neurological' || filter_item.cancerType == 'no_other_conditions'){
                    disease_filter_item_clone.splice(1, 1);
                }

                if(filter_item.cancerType == 'any_disease' || filter_item.cancerType == 'no_disease'){
                    let Count_condition_disease = cloneDeep(Count_condition)
                    Count_condition_disease.operator = filter_item.cancerType == 'any_disease' ? '>' : '='
                    Count_condition_disease.expressionList[0].expressionList[0].fieldName = 'diseases'
                    Count_condition_disease.expressionList[1].value = 0
                    disease_filter_item_clone[0] = Count_condition_disease
                }
                else if (filter_item.cancerType == 'any_UMLS' || filter_item.cancerType == 'no_UMLS'){
                    let Count_condition_umls_clone = cloneDeep(Count_condition_with_filter)
                    Count_condition_umls_clone.operator = filter_item.cancerType == 'any_UMLS' ? '>' : '='
                    // Count_condition_umls_clone.expressionList[0].expressionList[0].fieldName = 'diseases'
                    Count_condition_umls_clone.expressionList[1].value = 0
                    disease_filter_item_clone[0] = Count_condition_umls_clone
                }
                else if (filter_item.cancerType == 'any_blood' || filter_item.cancerType == 'no_blood'){

                    // // Use Subconcept operator, should be a different filter format
                    // let Count_condition_blood_clone = cloneDeep(Count_condition_subconcept)
                    // Count_condition_blood_clone.operator = filter_item.cancerType == 'anybl_ood' ? '>' : '='
                    // Count_condition_blood_clone.expressionList[0].expressionList[0].expressionList[1].value = 'C0018939'
                    // Count_condition_blood_clone.expressionList[1].value = 0
                    // disease_filter_item_clone[0] = Count_condition_blood_clone

                    let Count_condition_blood_clone = cloneDeep(Count_condition_or_subconcept)
                    Count_condition_blood_clone.operator = filter_item.cancerType == 'any_blood' ? '>' : '='

                    let list_of_blood_subconcepts = ['C0018939', 'C0684275', 'C0020445', 'C0149871', 'C0020538', 'C0020443', 'C0034065', 'C0002895', 'C0039730', 'C0042974']

                    for(let item of list_of_blood_subconcepts){
                        let subconcept = cloneDeep(Subconcept_operator)
                        subconcept.expressionList[1].value = item
                        Count_condition_blood_clone.expressionList[0].expressionList[0].expressionList[0].expressionList.push(subconcept)
                    }


                    Count_condition_blood_clone.expressionList[1].value = 0
                    disease_filter_item_clone[0] = Count_condition_blood_clone
                }

                else if (filter_item.cancerType == 'any_cancer-polyps' || filter_item.cancerType == 'no_cancer-polyps'){
                    // Use Subconcept operator, should be a different filter format
                    let Count_condition_cancer_polyps_clone = cloneDeep(Count_condition_subconcept)
                    Count_condition_cancer_polyps_clone.operator = filter_item.cancerType == 'any_cancer-polyps' ? '>' : '='
                    Count_condition_cancer_polyps_clone.expressionList[0].expressionList[0].expressionList[0].expressionList[1].value = 'C0006826'
                    Count_condition_cancer_polyps_clone.expressionList[1].value = 0
                    disease_filter_item_clone[0] = Count_condition_cancer_polyps_clone
                }
                else if (filter_item.cancerType == 'any_heart' || filter_item.cancerType == 'no_heart'){
                    let Count_condition_heart_clone = cloneDeep(Count_condition_subconcept)
                    Count_condition_heart_clone.operator = filter_item.cancerType == 'any_heart' ? '>' : '='
                    Count_condition_heart_clone.expressionList[0].expressionList[0].expressionList[0].expressionList[1].value = 'C0947842'
                    Count_condition_heart_clone.expressionList[1].value = 0
                    disease_filter_item_clone[0] = Count_condition_heart_clone
                }
                else if (filter_item.cancerType == 'any_mental-behavioral-neurological' || filter_item.cancerType == 'no_mental-behavioral-neurological'){
                    let Count_condition_any_mental_behavioral_neurological_clone = cloneDeep(Count_condition_or_subconcept)
                    Count_condition_any_mental_behavioral_neurological_clone.operator = filter_item.cancerType == 'any_mental-behavioral-neurological' ? '>' : '='

                    let list_of_any_mental_behavioral_neurological_subconcepts = ['C0027765', 'C0205494']

                    for(let item of list_of_any_mental_behavioral_neurological_subconcepts){
                        let subconcept = cloneDeep(Subconcept_operator)
                        subconcept.expressionList[1].value = item
                        Count_condition_any_mental_behavioral_neurological_clone.expressionList[0].expressionList[0].expressionList[0].expressionList.push(subconcept)
                    }

                    Count_condition_any_mental_behavioral_neurological_clone.expressionList[1].value = 0
                    disease_filter_item_clone[0] = Count_condition_any_mental_behavioral_neurological_clone
                }
                // else if (filter_item.cancerType == 'any_other_conditions' || filter_item.cancerType == 'no_other_conditions'){
                //     let Count_condition_umls_clone = cloneDeep(Count_condition_umls)
                //     Count_condition_umls_clone.operator = filter_item.cancerType == 'any_other_conditions' ? '>' : '='
                //     Count_condition_umls_clone.expressionList[0].expressionList[0].fieldName = 'diseases'
                //     Count_condition_umls_clone.expressionList[1].value = 0
                //     disease_filter_item_clone[0] = Count_condition_umls_clone
                // }
                // this will always be 'and' operator since it always needs to pass both disease name and age diagnosed conditions
                disease_filter_clone.operator = 'and'
                disease_filter_clone.expressionList = disease_filter_item_clone

                // @ts-ignore
                if(is_same_side_of_the_family_filter){
                    let disease_filter_clone1 = cloneDeep(disease_filter_clone)
                    let disease_filter_clone2 = cloneDeep(disease_filter_clone)
                    paternal_both_reference.push(disease_filter_clone1)
                    maternal_both_reference.push(disease_filter_clone2)

                    paternal_both_reference = paternal_both_reference[0].expressionList
                    maternal_both_reference = maternal_both_reference[0].expressionList
                }
                else{
                    reference.push(disease_filter_clone)
                    reference = reference[0].expressionList
                }


            }
            else if (filter_item.fieldType == "genetic_testing"){
                let gene_filter_clone = cloneDeep(filter)
                let gene_filter_item_clone = cloneDeep(gene_filter_item)

                let is_umls = umls_genetic_testings.find((gene: any) => gene.umls_id && gene.umls_id == filter_item.gene)

                gene_filter_item_clone[0].expressionList[0].fieldName = is_umls ? 'umls_id' : 'gene'
                gene_filter_item_clone[0].expressionList[1].value = filter_item.gene // gene or umls id
                gene_filter_item_clone[1].expressionList[1].value = filter_item.result // result
                gene_filter_item_clone[2].expressionList[1].value = filter_item.Variant ? filter_item.Variant : '' // variant

                if (!filter_item.Variant){
                    gene_filter_item_clone.splice(2, 1);
                }

                if (filter_item.result == "any" || !filter_item.result){
                    gene_filter_item_clone.splice(1, 1);
                }

                if (filter_item.gene == 'Any' || filter_item.gene == 'not-tested'){
                    let Count_condition_any_gene = cloneDeep(Count_condition)
                    Count_condition_any_gene.operator = filter_item.gene == 'Any' ? '>' : '='
                    Count_condition_any_gene.expressionList[0].expressionList[0].fieldName = 'genes'
                    Count_condition_any_gene.expressionList[1].value = 0
                    gene_filter_item_clone[0] = Count_condition_any_gene
                }

                gene_filter_clone.operator = 'and'
                gene_filter_clone.expressionList = gene_filter_item_clone

                // @ts-ignore
                if(is_same_side_of_the_family_filter){
                    let gene_filter_clone1 = cloneDeep(gene_filter_clone)
                    let gene_filter_clone2 = cloneDeep(gene_filter_clone)
                    paternal_both_reference.push(gene_filter_clone1)
                    maternal_both_reference.push(gene_filter_clone2)

                    paternal_both_reference = paternal_both_reference[0].expressionList
                    maternal_both_reference = maternal_both_reference[0].expressionList
                }
                else{
                    reference.push(gene_filter_clone)
                    reference = reference[0].expressionList
                }
            }
            else if (filter_item.fieldType == "referral_criteria"){
                if(!filter_item.referral){
                    continue;
                }
                let referral_criteria_filter_clone = cloneDeep(filter)
                let referral_criteria_filter_item_clone = cloneDeep(referral_criteria_filter_item)

                referral_criteria_filter_item_clone.expressionList[0].value = filter_item.referral;

                if (filter_item.referral == 'any' || filter_item.referral == 'none'){
                    let Count_condition_any_gene = cloneDeep(Count_condition)
                    Count_condition_any_gene.operator = filter_item.referral == 'any' ? '>' : '='
                    Count_condition_any_gene.expressionList[0].expressionList[0].fieldName = 'referralcriteria'
                    Count_condition_any_gene.expressionList[1].value = 0
                    referral_criteria_filter_item_clone = Count_condition_any_gene
                }

                referral_criteria_filter_clone.operator = 'and'
                referral_criteria_filter_clone.expressionList.push(referral_criteria_filter_item_clone)

                // @ts-ignore
                if(is_same_side_of_the_family_filter){
                    let referral_criteria_filter_clone1 = cloneDeep(referral_criteria_filter_clone)
                    let referral_criteria_filter_clone2 = cloneDeep(referral_criteria_filter_clone)
                    paternal_both_reference.push(referral_criteria_filter_clone1)
                    maternal_both_reference.push(referral_criteria_filter_clone2)

                    paternal_both_reference = paternal_both_reference[0].expressionList
                    maternal_both_reference = maternal_both_reference[0].expressionList
                }
                else{
                    reference.push(referral_criteria_filter_clone)
                    reference = reference[0].expressionList
                }
            }

            if (familyFilters.relationship != 'anyone'){
                let cloned_family_relationship_filter = cloneDeep(family_relationship_filter)
                if(familyFilters.relationship == 'blood_relatives'){
                    cloned_family_relationship_filter.expressionList[0].fieldName = 'is_blood_related_to_proband';
                    reference.push(cloned_family_relationship_filter)
                }
                else if(familyFilters.relationship == 'same_side_of_the_family'){

                    let operator = familyFilters.number_of_people_to_meet_criteria.relationalOperator
                    let count_value = familyFilters.number_of_people_to_meet_criteria.value
                    //count needed for this?
                    if(operator == 'any' || !count_value){
                        cloned_family_relationship_filter.expressionList[0].fieldName = 'is_blood_related_to_proband';
                        reference.push(cloned_family_relationship_filter)
                    }
                    else{
                        // cloned_family_relationship_filter.expressionList[0].fieldName = 'is_blood_related_to_proband';
                        // let cloned_family_relationship_filter1 = cloneDeep(cloned_family_relationship_filter)
                        // let cloned_family_relationship_filter2 = cloneDeep(cloned_family_relationship_filter)
                        // paternal_both_reference.push(cloned_family_relationship_filter1);
                        // maternal_both_reference.push(cloned_family_relationship_filter2);

                        // paternal_both_reference = paternal_both_reference[0].expressionList
                        // maternal_both_reference = maternal_both_reference[0].expressionList
                    }
                }
                else if(familyFilters.relationship == 'maternal_only'){
                    let clone_maternal_only_filter = cloneDeep(maternal_only_filter)
                    reference.push(clone_maternal_only_filter)
                }
                else if(familyFilters.relationship == 'paternal_only'){
                    let clone_paternal_only_filter = cloneDeep(paternal_only_filter)
                    reference.push(clone_paternal_only_filter)
                }
            }

            filter_index += 1;
        }

        // if(filterSets.length > 1){
        //     //@ts-ignore
        //     final_payload.query.expressionList[0].expressionList = final_payload.query.expressionList[0].expressionList.filter((exp: any) => exp.operator == "Exists")
        // }

        set_index += 1;
    }

    return final_payload;
}

const sortFilterSet = (filterSets: any) => {
    let sorted = filterSets.sort((a: any, b: any) => {
        if (a.andOr == 'or'){
            return +1
        }
        else {
            return -1
        }
    })

    return sorted;
}


// *Keep this commented off, this might be helpful for when we decide to be more complex on our filters and not forcing and's/or's
// to be the same in a filterset or between filtersets

// export function buildQueryPayload(filterType: string, filterSets: any){
//     console.log(filterType)
//     console.log(filterSets)

//     let final_payload = {
//         name: uuidv4(),
//         kind: filterType,
//         query: top_level_p_member_query_variable
//     }

//     // get ors first
//     let or_filterSets = filterSets.filter((set: any) => set.andOr == 'or')

//     let and_filterSets = filterSets.filter((set: any) => set.andOr == 'and')

//     for (let filter_set of or_filterSets){

//         let filterItems = filter_set.items

//         let disease_field_type_exists = filterItems.find((item: any) => item.fieldType == 'disease')
//         let genetic_testing_field_type_exists = filterItems.find((item: any) => item.fieldType == 'genetic_testing')
//         // other field types checks



//         if(disease_field_type_exists){
//             // @ts-ignore
//             final_payload.query.expressionList.push(p_member_disease_qualifier)
//         }

//         if(genetic_testing_field_type_exists){
//             if(disease_field_type_exists){
//                 // @ts-ignore
//                 final_payload.query.expressionList.expressionList.push(p_member_gene_qualifier)
//             }
//             else{
//                 // @ts-ignore
//                 final_payload.query.expressionList.push(p_member_gene_qualifier)
//             }
//         }

//         for (let filter_item of filterItems){
//         }
//     }

//     return final_payload;
// }
