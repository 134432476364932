import settings from './settings';
import TreeCrawler from "./tree-crawler";
import Smartdraw2_FamilyBuilder from "./smartdraw2_family_builder";
import Smartdraw2_Smartdraw from "./smartdraw2_smartdraw";
import Cookie from 'js-cookie';
class NodeLocator {

  family_tree = {}
  family_nodes = {}

  column_right = 0;
  row_bottom = 0;

  constructor(family_tree = {}) {

    this.family_tree = family_tree

  }
  build() {
    let displayLegend = Cookie.get('famgenix_pedigree_legend')
    let displayLegendBool = displayLegend == 'true'
    
    let column = settings.chart_padding_left - 3 + (displayLegendBool ? 2 : 0);
    let row = settings.chart_padding_top
    let nodes = this.build_family_nodes({column, row})
    if (displayLegendBool && nodes.mostTopLeftNode.bounds.column_start < 2){
      let column_start = nodes.mostTopLeftNode.bounds.column_start
      let columnsToBeAdded = Math.abs(column_start - 2) + 1
      column += columnsToBeAdded;
      nodes = this.build_family_nodes({column, row})
    }
    this.family_nodes = nodes

  }

  getOffset() {
    let { row, column} = this.family_nodes.node_tree.top_left_offset;
    return {
      row,
      column
    };
  }

  getFamilyNodes() {
    return this.family_nodes;
  }

  getColumnCount() {
    let column = this.column_right + settings.column_in_between;
    column += settings.chart_padding_left;
    column += settings.chart_padding_right;
    return column;
  }

  getRowCount() {
    let row = this.row_bottom + settings.matrix_row_space;
    row += settings.chart_padding_top;
    row += settings.chart_padding_bottom;
    return row;
  }

  build_family_nodes(start_pos = {row: 0, column: 8}) {

    let proband = this.family_tree.patient;
    let tree_crawler = new TreeCrawler(this.family_tree);

    let node_tree = tree_crawler.crawl(proband.rkey, proband.level, start_pos);

    if(Cookie.get("famgenix_active_smartdraw") == 'smartdraw'){
      // NEW SMARTDRAW 2.0
      //** var smartdraw2_profiling_start = Date.now();
      let family_builder = new Smartdraw2_FamilyBuilder(node_tree.family_nodes, this.family_tree.profile, proband.id);
    
      // phase 1: put together a list of all individuals
      family_builder.build_individuals_list();
  
      // phase 2: connect the whole family
      family_builder.build_family();
      let proband_w_family = family_builder.get_proband_with_complete_family();
      
      // phase 3: find the most top-left ancestor to start the smartdraw process from
      let smartdraw = new Smartdraw2_Smartdraw(proband_w_family);
      
      smartdraw.find_top_left_ancestor();
      smartdraw.crawl_and_assign_to_levels();
      smartdraw.space_individuals_out();
      // smartdraw.move_siblings_together();
      smartdraw.apply_new_positioning();
  
      //** // measure the time of Smartdraw 2.0 run
      //** var smartdraw2_run_total_time = Date.now() - smartdraw2_profiling_start;
      //** console.log("Time of the Smartdraw2.0 run in miliseconds: "+ smartdraw2_run_total_time +" ("+ (smartdraw2_run_total_time/1000) +" seconds)" );
      // END of THE SMARTDRAW 2.0
    }
    

    let { edges } = node_tree;
    this.column_right = edges.column_right;
    this.row_bottom = edges.row_bottom;

    let adjustment = tree_crawler.move_down_nodes_below_nodes_with_subtexts(this.row_bottom);
    this.row_bottom = adjustment.row_bottom;

    return {
      bounds: {
        column_start: 0,
        column_end: edges.column_right,
        column_count: edges.column_right,
      },
      node_tree,
      mostTopLeftNode: adjustment.mostTopLeftNode
    };
  }

}

export default NodeLocator