import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import route_helper from '../../../route-helper';
import Cookie from 'js-cookie'
import {set_cookie} from "../../../helpers/helper-cookies";

class FamilyHistoryToolbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showActionMenu: false,
      actionMenuHasFocus: false,
      showZoomControl: false,
      zoomMenuHasFocus: false,
      showViewsMenu: false,
      viewMenuHasFocus: false,
      showLegend: false,
      showAncestry: false,
      showGeneticTesting: true,
      showNotes: true,
      showPedigreeNotes: false,
      viewStatus: 'identified',
      showDrawingOptionMenu: false,
      showSubtextFontTestsMenu: false,
      drawingMenuHasFocus: false,
      showSmartdrawOriginal: false,
      showSmartdrawSmartdraw: true,
      showSmartdrawFlux: false,
      showFontMontserrat10: false,
      showFontRoboto11: false,
    }

    this.handleActionMenuClicked = this.handleActionMenuClicked.bind(this)
    this.handleZoomControlClicked = this.handleZoomControlClicked.bind(this)
    this.handleBodyClicked = this.handleBodyClicked.bind(this)
    this.handleViewsMenuClicked = this.handleViewsMenuClicked.bind(this)
    this.toggleLegend =  this.toggleLegend.bind(this)
    this.toggleAncestry = this.toggleAncestry.bind(this)
    this.setMenuFocus = this.setMenuFocus.bind(this);
    this.disbaleReadOnly = this.disbaleReadOnly.bind(this)
    this.handleDrawingOptionMenuClicked = this.handleDrawingOptionMenuClicked.bind(this)
    // this.handleSubtextFontTestsMenuClicked = this.handleSubtextFontTestsMenuClicked.bind(this)
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleBodyClicked);
    let viewStatus = Cookie.get('famgenix_pedigree_view_status') || 'identified'
    this.setState({viewStatus})
    let displayLegend = Cookie.get('famgenix_pedigree_legend')
    if(displayLegend == undefined){
      set_cookie('famgenix_pedigree_legend', 'true')
      displayLegend = 'true'
    }
    let displayLegendBool = displayLegend == 'true'
    this.setState({showLegend: displayLegendBool})
    let displayAncestry = Cookie.get('famgenix_pedigree_ancestry')
    if(displayAncestry == undefined){
      set_cookie('famgenix_pedigree_ancestry', 'true')
      displayAncestry = 'true'
    }
    let displayAncestryBool = displayAncestry == 'true'
    this.setState({showAncestry: displayAncestryBool})
    let displayGeneticTesting = Cookie.get('famgenix_pedigree_genetic_testing')
    if(displayGeneticTesting == undefined){
      set_cookie('famgenix_pedigree_genetic_testing', 'true')
      displayGeneticTesting = 'true'
    }
    let displayGeneticTestingBool = displayGeneticTesting == 'true'
    this.setState({ showGeneticTesting: displayGeneticTestingBool })
    let showNotes = Cookie.get('famgenix_pedigree_notes')
    if(showNotes == undefined){
      set_cookie('famgenix_pedigree_notes', 'true')
      showNotes = 'true'
    }
    let showNotesBool = showNotes == 'true'
    this.setState({ showNotes: showNotesBool })

    let showPedigreeNotes = Cookie.get('famgenix_pedigree_legend_notes')
    if(showPedigreeNotes == undefined){
      set_cookie('famgenix_pedigree_legend_notes', 'true')
      showPedigreeNotes = 'true'
    }
    let showPedigreeNotesBool = showPedigreeNotes == 'true'
    this.setState({ showPedigreeNotes: showPedigreeNotesBool })

    if(Cookie.get('famgenix_active_smartdraw') != undefined && Cookie.get('famgenix_active_smartdraw') != null){
      let showSmartdrawOriginal = Cookie.get('famgenix_active_smartdraw')
      let showSmartdrawOriginalBool = showSmartdrawOriginal == 'original'
      this.setState({ showSmartdrawOriginal: showSmartdrawOriginalBool })
      let showSmartdrawSmartdraw = Cookie.get('famgenix_active_smartdraw')
      let showSmartdrawSmartdrawBool = showSmartdrawSmartdraw == 'smartdraw'
      this.setState({ showSmartdrawSmartdraw: showSmartdrawSmartdrawBool })
      let showSmartdrawFlux = Cookie.get('famgenix_active_smartdraw')
      let showSmartdrawFluxBool = showSmartdrawFlux == 'flux'
      this.setState({ showSmartdrawFlux: showSmartdrawFluxBool })
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleBodyClicked);
  }

  handleBodyClicked(e) {
    let skippedIds = ['zoom-control-panel', 'actions-menu-panel', 'drawing-control-panel', 'views-control-panel'];

    let paths = e.path
    if(typeof(paths) === "undefined") return
    let shouldSkip = paths.filter(item => skippedIds.includes(item.id)).length > 0
    if(shouldSkip) return

    this.hideAllSubMenus();
  }

  async handleActionMenuClicked(e) {
    await this.hideAllSubMenus();
    this.setState({ showActionMenu: true})
  }

  async handleZoomControlClicked(e) {
    await this.hideAllSubMenus();
    this.setState({ showZoomControl: true})
  }

  async handleViewsMenuClicked(e) {
    await this.hideAllSubMenus();
    this.setState({ showViewsMenu: true })
  }

  async hideAllSubMenus() {
    await this.setState({
      showViewsMenu: false,
      showActionMenu: false,
      showZoomControl: false,
      showDrawingOptionMenu: false
    })
  }

  async setMenuFocus(key, val) {
    if(key === 'zoom') {
      this.setState({ zoomMenuHasFocus: val, showZoomControl: val });
    } else if(key === 'action') {
      this.setState({ actionMenuHasFocus: val, showActionMenu: val });
    } else if(key === 'views') {
      this.setState({ viewMenuHasFocus: val, showViewsMenu: val });
    } else if(key === 'drawing_menu') {
      this.setState({ drawingMenuHasFocus: val, showDrawingOptionMenu: val });
    } else if(key === 'subtext_font_tests'){
      this.setState({ subtextFontTestsMenuHasFocus: val, showSubtextFontTestsMenu: val })
    }
  }

  onClickViewItem(viewStatus) {

    viewStatus = (this.state.viewStatus === 'identified') ? 'deidentified' : 'identified'
    this.setState({viewStatus})
    set_cookie('famgenix_pedigree_view_status', viewStatus)
    this.props.onToggleViewClick(viewStatus)
  }

  toggleLegend(showLegend){
    this.setState({showLegend})
    set_cookie('famgenix_pedigree_legend', showLegend)
    this.props.onToggleLegend(showLegend, this.state.showPedigreeNotes)
  }

  togglePedigreeNotes(showPedigreeNotes){
    this.setState({showPedigreeNotes})
    set_cookie('famgenix_pedigree_legend_notes', showPedigreeNotes)
    this.props.onToggleLegend(this.state.showLegend, showPedigreeNotes)
  }

  toggleAncestry(showAncestry){
    this.setState({showAncestry})
    set_cookie('famgenix_pedigree_ancestry', showAncestry)
    this.props.onToggleAncestry(showAncestry)
  }

  toggleGeneticTesting(showGeneticTesting){
    this.setState({showGeneticTesting})
    set_cookie('famgenix_pedigree_genetic_testing', showGeneticTesting)
    this.props.onToggleGeneticTesting()
  }

  toggleNotes(showNotes){
    this.setState({showNotes})
    set_cookie('famgenix_pedigree_notes', showNotes)
    this.props.onToggleGeneticTesting() //refresh
  }

  disbaleReadOnly(){
    if(this.props.readOnlyUser &&this.props.readOnlyUser === true){
      return false
    }
    return true
  }

  async handleDrawingOptionMenuClicked(e) {
    await this.hideAllSubMenus();
    this.setState({ showDrawingOptionMenu: true})
  }

  async handleDrawingOptionSelect(option){
    if(option === 'original'){
      set_cookie("famgenix_active_smartdraw", 'original');
      this.setState({
        showSmartdrawOriginal: true,
        showSmartdrawSmartdraw: false,
        showSmartdrawFlux: false
      })
    }
    else if(option === 'smartdraw'){
      set_cookie("famgenix_active_smartdraw", 'smartdraw');
      this.setState({
        showSmartdrawOriginal: false,
        showSmartdrawSmartdraw: true,
        showSmartdrawFlux: false
      })
    }
    else if(option === 'flux'){
      set_cookie("famgenix_active_smartdraw", 'flux');
      this.setState({
        showSmartdrawOriginal: false,
        showSmartdrawSmartdraw: false,
        showSmartdrawFlux: true
      })
    }
    this.props.onRefresh()
  }

  // async handleSubtextFontTestsMenuClicked(e){
  //   await this.hideAllSubMenus();
  //   this.setState({ showSubtextFontTestsMenu: true })
  // }

  // async handleSubtextFontTestsOptionSelect(font, fontSize){
  //   if(font === 'montserrat' && fontSize === 10){
  //     this.setState({ showFontMontserrat10: true })
  //     this.setState({ showFontRoboto11: false })
  //   }
  //   else if(font === 'roboto' && fontSize === 11){
  //     this.setState({ showFontMontserrat10: false })
  //     this.setState({ showFontRoboto11: true })
  //   }
  //   this.props.fontFontSizeChangeTest(font, fontSize)
  // }

  render() {

    let show_action_menu = this.state.showActionMenu ? 'open' : ''
    let show_zoom_controls = this.state.showZoomControl ? 'open' : ''
    let show_views_menu = this.state.showViewsMenu ? 'open' : ''
    let show_drawing_option_menu = this.state.showDrawingOptionMenu ? 'open' : ''
    let show_subtext_font_tests_menu = this.state.showSubtextFontTestsMenu ? 'open' : ''

    let table_view_url = route_helper.family.family_history.replace(':member_id', this.props.probandMemberId);

    return (
      <div className="patient-fam patient-fam-tools-xxx">
      {this.disbaleReadOnly() &&(
        <ul className="nav nav-pills">
          {/* <li role="presentation"><a href="#"><i className="fa fa-undo"></i> Undo</a></li>
          <li role="presentation"><a href="#"><i className="fa fa-rotate-right"></i> Redo</a></li> */}
          <li id='views-control-panel' role="presentation" className={"dropdown " + show_views_menu} >
            <a onClick={this.handleViewsMenuClicked} className="dropdown-toggle action-menu">
              <i className="fa fa-desktop"></i> Views
            </a>
            <ul
              onMouseEnter={() => this.setMenuFocus('views', true)}
              onMouseLeave={() => this.setMenuFocus('views', false)}
              className="dropdown-menu">
              <li className={(this.state.viewStatus == 'identified') ? 'view-active': ''}>
                <a onClick={() => this.onClickViewItem('identified')}>
                  <i className={(this.state.viewStatus == 'identified')? "fa fa-check" : 'view-unchecked'}></i>
                    Names
                </a>
              </li>
              <li className={this.state.showNotes ? 'view-active': ''}>
                <a onClick={() => {
                    this.toggleNotes(!this.state.showNotes)
                  }}>
                  <i className={this.state.showNotes ? "fa fa-check" : 'view-unchecked'}></i>
                  Notes
                </a>
              </li>
              <li className={this.state.showPedigreeNotes ? 'view-active': ''}>
                <a onClick={() => {
                    this.togglePedigreeNotes(!this.state.showPedigreeNotes)
                  }}>
                  <i className={this.state.showPedigreeNotes ? "fa fa-check" : 'view-unchecked'}></i>
                  Pedigree Notes
                </a>
              </li>
              <li className={this.state.showGeneticTesting ? 'view-active': ''}>
                <a onClick={() => {
                    this.toggleGeneticTesting(!this.state.showGeneticTesting)
                  }}>
                  <i className={this.state.showGeneticTesting ? "fa fa-check" : 'view-unchecked'}></i>
                  Genetic Testing
                </a>
              </li>
              <li className={this.state.showLegend ? 'view-active': ''}>
                <a onClick={() => {
                    this.toggleLegend(!this.state.showLegend)
                  }}>
                  <i className={this.state.showLegend ? "fa fa-check" : 'view-unchecked'}></i>
                  Legend
                </a>
              </li>
              <li className={this.state.showAncestry ? 'view-active': ''}>
                <a onClick={() => {
                    this.toggleAncestry(!this.state.showAncestry)
                  }}>
                  <i className={this.state.showAncestry ? "fa fa-check" : 'view-unchecked'}></i>
                  Ancestry
                </a>
              </li>
              <li>
                <Link to={table_view_url}>
                  <i className='view-unchecked'></i>
                  <span>Table View</span>
                </Link>
              </li>
            </ul>

          </li>
          <li id='actions-menu-panel' role="presentation" className={"dropdown " + show_action_menu} >
            <a onClick={this.handleActionMenuClicked} className="dropdown-toggle action-menu">
              <i className="fa fa-navicon"></i> Actions
            </a>
            <ul
              onMouseEnter={() => this.setMenuFocus('action', true)}
              onMouseLeave={() => this.setMenuFocus('action', false)}
              className="dropdown-menu"
            >
              {/* <li><a href="#">Edit Symbols</a></li> */}
              <li>
                <a onClick={() => this.props.onPrintClick()}>
                  Print Pedigree (Fit to One Page)</a></li>
              <li>
                <a onClick={() => this.props.onPrintMultiplePagesClick()}>
                  Print Pedigree (Split Across Multiple Pages)</a></li>
              <li>
                <a onClick={() => this.props.onSaveImageClick()}>
                  Save as image</a></li>
            </ul>
          </li>

          <li id="zoom-control-panel" role="presentation" className={"dropdown " + show_zoom_controls} >

            <a onClick={this.handleZoomControlClicked} className="dropdown-toggle">
              <i onClick={() => this.props.onZoomOutClick()} className="fa fa-search-minus text-white"></i>
                {this.props.zoomValue}%
              <i onClick={() => this.props.onZoomInClick()} className="fa fa-search-plus text-white"></i>
            </a>

            <ul
              onMouseEnter={() => this.setMenuFocus('zoom', true)}
              onMouseLeave={() => this.setMenuFocus('zoom', false)}
              className="dropdown-menu ">
              <li>
                <div className="zoom-controls">
                  <div className="zoom">
                    <a onClick={() => this.props.onZoomOutClick()}
                    className="pull-left"><i className="fa fa-search-minus text-white no-margin"></i></a>
                    <span>{this.props.zoomValue}%</span>
                    <a onClick={() => this.props.onZoomInClick()}
                    className="pull-right"><i className="fa fa-search-plus text-white no-margin"></i></a>
                  </div>
                  <div className="margin-one">
                    <div className='btn-spacer'>
                      <button
                        onClick={() => this.props.onFitToScreenClick()}
                        type="button" className="btn btn-gray-l btn-small btn-block">Fit to Screen</button>
                    </div>
                    <button
                      onClick={() => this.props.onActualSizeClick()}
                      type="button" className="btn btn-gray-l btn-small btn-block ">Actual Size</button>
                  </div>
                  <label className="checkbox-inline">
                    <input type="checkbox"
                      checked={this.props.alwaysShrink}
                      onChange={(e) => this.props.onShrinkCheck(e)}
                    /> Always Shrink to Fit
							    </label>
                </div>
              </li>
            </ul>

          </li>

          {process.env.REACT_APP_NODE_ENV === 'development' && <li id='drawing-control-panel' role="presentation" className={"dropdown " + show_drawing_option_menu} >
            <a onClick={this.handleDrawingOptionMenuClicked} className="dropdown-toggle action-menu">
              Drawing Option
            </a>
            <ul
              onMouseEnter={() => this.setMenuFocus('drawing_menu', true)}
              onMouseLeave={() => this.setMenuFocus('drawing_menu', false)}
              className="dropdown-menu"
            >
              {/* <li><a href="#">Edit Symbols</a></li> */}
              <li className={this.state.showSmartdrawOriginal ? 'view-active': ''}>
                <a onClick={() => this.handleDrawingOptionSelect('original')}>
                  <i className={this.state.showSmartdrawOriginal ? "fa fa-check" : 'view-unchecked'}></i>
                  Original</a></li>
              <li className={this.state.showSmartdrawSmartdraw ? 'view-active': ''}>
                <a onClick={() => this.handleDrawingOptionSelect('smartdraw')}>
                  <i className={this.state.showSmartdrawSmartdraw ? "fa fa-check" : 'view-unchecked'}></i>
                  Smartdraw</a></li>
              <li className={this.state.showSmartdrawFlux ? 'view-active': ''}>
                <a onClick={() => this.handleDrawingOptionSelect('flux')}>
                  <i className={this.state.showSmartdrawFlux ? "fa fa-check" : 'view-unchecked'}></i>
                  Flux</a></li>
            </ul>
          </li>}

          {/* comment out font tests, might be needed in the future */}
          {/* <li role="presentation" className={"dropdown " + show_subtext_font_tests_menu} >
            <a onClick={this.handleSubtextFontTestsMenuClicked} className="dropdown-toggle action-menu">
              Subtexts Font Tests
            </a>
            <ul
              onMouseEnter={() => this.setMenuFocus('subtext_font_tests', true)}
              onMouseLeave={() => this.setMenuFocus('subtext_font_tests', false)}
              className="dropdown-menu"
            > */}
              {/* <li><a href="#">Edit Symbols</a></li> */}
              {/* <li className={this.state.showFontMontserrat10 ? 'view-active': ''}>
                <a onClick={() => this.handleSubtextFontTestsOptionSelect('montserrat', 10)}>
                  <i className="fa fa-check"></i>
                  Montserrat 10px</a></li>
              <li className={this.state.showFontRoboto11 ? 'view-active': ''}>
                <a onClick={() => this.handleSubtextFontTestsOptionSelect('roboto', 11)}>
                  <i className="fa fa-check"></i>
                  Roboto 11px</a></li> */}
            {/* </ul>
          </li> */}

        </ul>
      )}
       {!this.disbaleReadOnly() &&(
        <ul className='read-zoom'>
          <li id="zoom-control-panel" role="presentation" className={"dropdown " + show_zoom_controls} >

            <a onClick={this.handleZoomControlClicked} className="dropdown-toggle">
              <i onClick={() => this.props.onZoomOutClick()} className="fa fa-search-minus text-white"></i>
                {this.props.zoomValue}%
              <i onClick={() => this.props.onZoomInClick()} className="fa fa-search-plus text-white"></i>
            </a>

            <ul
              onMouseEnter={() => this.setMenuFocus('zoom', true)}
              onMouseLeave={() => this.setMenuFocus('zoom', false)}
              className="dropdown-menu ">
              <li>
                <div className="zoom-controls">
                  <div className="zoom">
                    <a onClick={() => this.props.onZoomOutClick()}
                    className="pull-left"><i className="fa fa-search-minus text-white no-margin"></i></a>
                    <span>{this.props.zoomValue}%</span>
                    <a onClick={() => this.props.onZoomInClick()}
                    className="pull-right"><i className="fa fa-search-plus text-white no-margin"></i></a>
                  </div>
                  <div className="margin-one">
                    <div className='btn-spacer'>
                      <button
                        onClick={() => this.props.onFitToScreenClick()}
                        type="button" className="btn btn-gray-l btn-small btn-block">Fit to Screen</button>
                    </div>
                    <button
                      onClick={() => this.props.onActualSizeClick()}
                      type="button" className="btn btn-gray-l btn-small btn-block ">Actual Size</button>
                  </div>
                  <label className="checkbox-inline">
                    <input type="checkbox"
                      checked={this.props.alwaysShrink}
                      onChange={(e) => this.props.onShrinkCheck(e)}
                    /> Always Shrink to Fit
                  </label>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      )}
      </div>
    );

  }
}

export default FamilyHistoryToolbar
