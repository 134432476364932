/* eslint-disable default-case */
import settings from './settings'
import { getCenter, getXYFromCenter, getXYpad } from "./helpers";
import { Point } from 'paper';
import Cookie from 'js-cookie'
import { PaperScope } from 'paper/dist/paper-core';
import { max } from 'lodash';
import LegendDOM from './legendDOM';
import constants from './constants';

var colorsForLegend = [];
var legendDragging = false;
var linesForMaternal = 1;
var linesForPaternal = 1;
var peopleLabels = [];
var allQuadrantColors = [];
var deceasedLines = []
var arrowProband = []
var ancestry = []

const createPerson = (paper, option) => {
  var option_clean = parseCleanData(option)
  var person = null
  if ('bloodRelative' in option && option.bloodRelative == false) {
    person = createNonBloodRelative(paper, option_clean)
  } else {
    person = createBloodRelative(paper, option_clean)
  }

  return person
}

const parseCleanData = (option) => {
  if (option.gender == null) option.gender = 'u'
  return option
}

const drawLineForMeasuring = (paper, loc) => {
  var line = new paper.Path.Line(loc, new paper.Point(loc.x + 110, loc.y))
  line.style = { strokeColor: settings.deceased_strokeColor, strokeWidth: 2 }
}

const drawLabels = (paper, option, center) => {
  let labels = []
  let data = option.data
  var content = ""

  // Other partner of proband parent has no data yet. they are virtual.   // Need fix
  // var loc = option.loc.add(0, settings.person_width + settings.label_margin_top)
  // var left_margin = (settings.label_max_char_width / 2 + 10) * -1;
  var left_margin = (settings.label_max_char_width / 2) * -1;
  var loc = option.loc.add(left_margin, settings.person_width + settings.label_margin_top)
  //label for name
  labels.push(drawLabel(paper, '', loc))

  //label for cause of death
  loc = loc.add(0, settings.label_margin_top)
  let labelCauseOfDeath = drawLabel(paper, '', loc);
  if(data.profile.is_dead && data.profile.cause_of_death){
    labelCauseOfDeath.justification = 'center';
  }
  labels.push(labelCauseOfDeath)

  //label for disease1
  let diseases = data.health.history_diseases
  let diseases_length = diseases !== null && diseases !== undefined ? diseases.length : 0

  for(let i = 0; i < diseases_length; i++){
    loc = loc.add(0, settings.label_margin_top)
    labels.push(drawLabel(paper, '', loc))
  }


  //label for genetic testing
  loc = loc.add(0, settings.label_margin_top)
  let labelGeneticTesting = drawLabel(paper, '', loc);
  labels.push(labelGeneticTesting)

  //label for notes
  loc = loc.add(0, settings.label_margin_top)
  let labelNotes = drawLabel(paper, '', loc);
  labels.push(labelNotes)


  //label for gender identity
  let toBeAdded = 18.125 * (labels.length + 1) * -1;
  loc = loc.add(0, toBeAdded)
  labels.push(drawLabel(paper, '', loc))


  // drawLineForMeasuring(paper, loc)



  let geneticTestIndex = 1;

  if (data.health.history_diseases !== null && data.health.history_diseases !== undefined) {
    if (data.health.history_diseases.length >= 4) {
      geneticTestIndex = labels.length - 4
    }
    else if (data.health.history_diseases.length <= 3) {
      geneticTestIndex = data.health.history_diseases.length + 1
    }
  }


  let notesIndex = geneticTestIndex + 1;

  let diseaseStartIndex = 1;

  let causeOfDeathIndex = null;

  if(data.profile.is_dead && data.profile.cause_of_death){
    causeOfDeathIndex = 1;
    diseaseStartIndex = 2;
    geneticTestIndex += 1;
    notesIndex += 1;
  }

  if(!data.profile.is_no_children_node && !data.profile.is_infertility_node){
    displayName(data.profile, labels, center)
    if(causeOfDeathIndex){
      displayCauseOfDeath(data.profile.cause_of_death, labels, causeOfDeathIndex, data.health.history_diseases, diseaseStartIndex, paper, center)
    }
    displayDiseaseInfo(data.health.history_diseases, labels, diseaseStartIndex, paper, center)
    let displayGeneticTesting = Cookie.get('famgenix_pedigree_genetic_testing')
    let displayGeneticTestingBool = displayGeneticTesting == 'true'
    if(displayGeneticTestingBool){
      displayGeneticTest(data.health.history_gene_tests, geneticTestIndex, labels, data.health.history_diseases, diseaseStartIndex, paper, center)
    }
    let showNotes = Cookie.get('famgenix_pedigree_notes')
    let showNotesBool = showNotes == 'true'
    if(showNotesBool){
      displayNotes(data.profile.note, notesIndex, labels, data.health.history_diseases, diseaseStartIndex, paper, center)
    }
    displayGenderLabel(data.profile, labels)
  }

  let person_data_labels = {
    rkey: `apimem-${data.profile.id}`,
    labels: labels
  }

  peopleLabels.push(person_data_labels);

  return labels
}

const drawLabel = (paper, content, loc) => {

  // var loc = loc.add(0, settings.person_width + settings.label_margin_top)
  var text = new paper.PointText(loc)
  text.fillColor = 'black'
  text.style = { fontSize: 10 }
  text.font = 'montserrat'
  text.content = content
  return text
}

// const fontFontSizeChangeTest = (font, fontSize) => {
//   for(let people of peopleLabels){
//     for (let label of people.labels){
//       label.font = font
//       label.style = { fontSize: fontSize }
//     }
//   }
// }

const redrawSubtext = () => {
  for(let people of peopleLabels){
    for (let label of people.labels){
      label.strokeColor = settings.strokeColorPrinting 
    }
  }

  for(let arrow of arrowProband){
    arrow.fillColor = '#000000'
    arrow.strokeColor = '#000000'
  }

  for(let line of deceasedLines){
    line.strokeColor = 'black'
  }
  
  for(let label of ancestry){
    label.strokeColor = '#000000'
  }
}

const displayCauseOfDeath = (causeOfDeath, labels, index, diseases, diseaseStartIndex, paper, center) =>{
  paper.PointText.prototype.wordwrap = function (txt, max) {
    var lines = [];
    var space = -1;
    function cut() {
      for (var i = 0; i < txt.length; i++) {
        (txt[i] == ' ') && (space = i);
        if (i >= max) {
          (space == -1 || txt[i] == ' ') && (space = i);
          if (space > 0) { lines.push(txt.slice((txt[0] == ' ' ? 1 : 0), space)); }
          txt = txt.slice(txt[0] == ' ' ? (space + 1) : space);
          space = -1;
          break;
        }
      } check();
    }
    function check() { if (txt.length <= max) { lines.push(txt[0] == ' ' ? txt.slice(1) : txt); txt = ''; } else if (txt.length) { cut(); } return; }
    check();
    return this.content = lines.join('\n');
  }

  let maxDiseaseLength = 0;

  if(diseases){
    for(let disease of diseases){
      let content = disease.disease_short_name
      if(content.length > maxDiseaseLength){
        maxDiseaseLength = content.length
      }
    }
  }

  let maxCharFlux = 20;

  if(maxDiseaseLength >= 15 && maxDiseaseLength <= 20){
    maxCharFlux = 25;
  }
  else if(maxDiseaseLength >= 21){
    maxCharFlux = 30;
  }

  let maxCharCauseOfDeath = Cookie.get("famgenix_active_smartdraw") == 'flux' ? maxCharFlux : 15;

  causeOfDeath = `d. ${causeOfDeath}`

  labels[index].wordwrap(causeOfDeath ? causeOfDeath : "", maxCharCauseOfDeath)
  labels[index].bounds.center.x = center.x
}

const updateNotes = (person, profile) => {
  displayNotes(profile, person.labels)
}

// for displaying notes
const displayNotes = (content, notesIndex, labels, diseases, diseaseStartIndex, paper, center) => {
  paper.PointText.prototype.wordwrap = function (txt, max) {
    var lines = [];
    var space = -1;
    function cut() {
      for (var i = 0; i < txt.length; i++) {
        (txt[i] == ' ') && (space = i);
        if (i >= max) {
          (space == -1 || txt[i] == ' ') && (space = i);
          if (space > 0) { lines.push(txt.slice((txt[0] == ' ' ? 1 : 0), space)); }
          txt = txt.slice(txt[0] == ' ' ? (space + 1) : space);
          space = -1;
          break;
        }
      } check();
    }
    function check() { if (txt.length <= max) { lines.push(txt[0] == ' ' ? txt.slice(1) : txt); txt = ''; } else if (txt.length) { cut(); } return; }
    check();
    return this.content = lines.join('\n');
  }

  let maxDiseaseLength = 0;

  if(diseases){
    for(let disease of diseases){
      let content = disease.disease_short_name
      if(content.length > maxDiseaseLength){
        maxDiseaseLength = content.length
      }
    }
  }

  let maxCharFlux = 20;

  if(maxDiseaseLength >= 15 && maxDiseaseLength <= 20){
    maxCharFlux = 25;
  }
  else if(maxDiseaseLength >= 21){
    maxCharFlux = 30;
  }

  let maxCharNotes = Cookie.get("famgenix_active_smartdraw") == 'flux' ? maxCharFlux : 15;

  labels[notesIndex].wordwrap(content ? content.trim() : "", maxCharNotes)
  labels[notesIndex].justification = 'left'
  if(labels[notesIndex-1]){
    if(labels[notesIndex-1].content){
      labels[notesIndex].bounds.top = labels[notesIndex-1].bounds.bottom + 3
    }
    else{
      labels[notesIndex].bounds.top = labels[notesIndex-1].bounds.top
    }
  }
  labels[notesIndex].bounds.center.x = center.x
}

const updateGeneticTest = (person, profile) => {
  displayGeneticTest(profile, person.labels)
}

// for displaying genetic testing (CLIN-112)
const displayGeneticTest = (profile, index, labels, diseases, diseaseStartIndex, paper, center) => {
  let content = '';
  let nonNegative = [];
  if (profile !== null) {
    nonNegative = profile.filter(geneTest => geneTest.result !== "n")
  }

  if (nonNegative.length !== 0){
    for(let gene of nonNegative){
      if(gene.result === "p") {
        gene.result = "Pathogenic"
      }
      else if(gene.result === "lp"){
        gene.result = "Likely Pathogenic"
      }
      else if(gene.result === "ln"){
        gene.result = "Likely Benign"
      }
      else if(gene.result === "u"){
        gene.result = "Unsure"
      }
      else if(gene.result === "vus"){
        gene.result = "VUS"
      }
      content += gene.gene + " - " + gene.result + ", "
    }
    content = content.slice(0, -2)
  }
  else if (nonNegative.length === 0 && profile !== null && profile.length !== 0){
    content = "Negative genetic testing"
  }
  else{
    content = '';
  }

  paper.PointText.prototype.wordwrap = function (txt, max) {
    var lines = [];
    var space = -1;
    function cut() {
      for (var i = 0; i < txt.length; i++) {
        (txt[i] == ' ') && (space = i);
        if (i >= max) {
          (space == -1 || txt[i] == ' ') && (space = i);
          if (space > 0) { lines.push(txt.slice((txt[0] == ' ' ? 1 : 0), space)); }
          txt = txt.slice(txt[0] == ' ' ? (space + 1) : space);
          space = -1;
          break;
        }
      } check();
    }
    function check() { if (txt.length <= max) { lines.push(txt[0] == ' ' ? txt.slice(1) : txt); txt = ''; } else if (txt.length) { cut(); } return; }
    check();
    return this.content = lines.join('\n');
  }

  let maxDiseaseLength = 0;

  if(diseases){
    for(let disease of diseases){
      let content = disease.disease_short_name
      if(content.length > maxDiseaseLength){
        maxDiseaseLength = content.length
      }
    }
  }

  let maxCharFlux = 20;

  if(maxDiseaseLength >= 15 && maxDiseaseLength <= 20){
    maxCharFlux = 25;
  }
  else if(maxDiseaseLength >= 21){
    maxCharFlux = 30;
  }

  let maxCharGeneTests = Cookie.get("famgenix_active_smartdraw") == 'flux' ? maxCharFlux : 15;

  labels[index].wordwrap(content ? content.trim() : "", maxCharGeneTests)
  labels[index].justification = 'center'
  labels[index].bounds.center.x = center.x
  labels[index].bounds.top = labels[index-1].bounds.bottom + 3

  // labels[index].content = addLeftPadding(content, "name");
}

const updateGenderLabel = (person, profile) => {
  displayGenderLabel(profile, person.labels)
}

//for displaying gender identity
const displayGenderLabel = (profile, labels) => {
  let content = '';
  if (profile.gender != String(profile.gender_identity).charAt(0) && profile.gender_identity != null && profile.gender_identity != "") {
    if (profile.gender == 'm') {
      content = 'AMAB'
    }
    else if (profile.gender == 'f') {
      content = 'AFAB'
    }
    else if (profile.gender == 'u') {
      content = 'AUAB'
    }
  }
  labels[labels.length - 1].content = content;
}

const updateDisplayName = (person, profile) => {
  displayName(profile, person.labels)
}

const displayName = (profile, labels, center) => {
  let content = '';
  let name = ('first_name' in profile) ? profile.first_name : '';
  if (settings.show_person_id) name = profile.rkey.replace('apimem-', '');

  // Hide name, when deindentified view on matrix-people.toggleViewStatus
  let deidentified = localStorage.getItem('deidentified', 'false') === 'true';
  if(!deidentified) content += name;

  if (profile.age && profile.age > 0) {
    if (content !== '') { content += ', ' }
    if (profile.is_dead) {
      content += 'd. ';
    }
    content += profile.age;
  }

  labels[0].content = content
  labels[0].bounds.center.x = center.x
}

const updateDiseaseInfo = (person, history_diseases) => {
  displayDiseaseInfo(history_diseases, person.labels)
}

const addLeftPadding = (content, nameOrDisease) => {
  let max_char_width = settings.label_max_char_width;
  var padding = null;
  var left_padd = null;
  if (content.length < max_char_width && nameOrDisease == 'name') {
    padding = max_char_width - content.length;
    padding = Math.ceil(padding / 2);
    left_padd = '';
    for (let i = 1; i <= padding; i++) left_padd += ' ';
    return left_padd + content;
  }
  else if (content.length > max_char_width && nameOrDisease == 'name') {
    padding = max_char_width - content.length;
    padding = Math.ceil(padding / 2);
    left_padd = '';
    for (let i = -6; i <= padding; i++) left_padd += ' ';
    return left_padd + content;
  }
  else if (content.length == max_char_width && nameOrDisease == 'disease') {
    padding = max_char_width - content.length;
    padding = Math.ceil(padding / 2);
    left_padd = '';
    for (let i = -8; i <= padding; i++) left_padd += ' ';

    return left_padd + content;
  }
  else if (content.length < max_char_width && nameOrDisease == 'disease') {
    padding = max_char_width - content.length;
    padding = Math.ceil(padding / 2);
    left_padd = '';
    for (let i = -6; i <= padding; i++) left_padd += ' ';
    return left_padd + content;
  }
  return content;
}

const wordWrapCounter = (txt, max) => {
  var lines = [];
  var space = -1;
  function cut() {
    for (var i = 0; i < txt.length; i++) {
      (txt[i] == ' ') && (space = i);
      if (i >= max) {
        (space == -1 || txt[i] == ' ') && (space = i);
        if (space > 0) { lines.push(txt.slice((txt[0] == ' ' ? 1 : 0), space)); }
        txt = txt.slice(txt[0] == ' ' ? (space + 1) : space);
        space = -1;
        break;
      }
    } check();
  }
  function check() { if (txt.length <= max) { lines.push(txt[0] == ' ' ? txt.slice(1) : txt); txt = ''; } else if (txt.length) { cut(); } return; }
  check();
  return lines.length;
}

const displayDiseaseInfo = (history_diseases, labels, index, paper, center) => {
  paper.PointText.prototype.wordwrap = function (txt, max) {
    var lines = [];
    var space = -1;
    function cut() {
      for (var i = 0; i < txt.length; i++) {
        (txt[i] == ' ') && (space = i);
        if (i >= max) {
          (space == -1 || txt[i] == ' ') && (space = i);
          if (space > 0) { lines.push(txt.slice((txt[0] == ' ' ? 1 : 0), space)); }
          txt = txt.slice(txt[0] == ' ' ? (space + 1) : space);
          space = -1;
          break;
        }
      } check();
    }
    function check() { if (txt.length <= max) { lines.push(txt[0] == ' ' ? txt.slice(1) : txt); txt = ''; } else if (txt.length) { cut(); } return; }
    check();
    return this.content = lines.join('\n');
  }

  if (history_diseases) {
    let diseases = history_diseases
    let i = index;
    let linesCountPrevious = 1;
    for (var disease of diseases) {
      var content = disease.disease_short_name
      let age_dx = disease.age_diagnosed ? disease.age_diagnosed : "?"
      if (age_dx) content += ' ' + age_dx
      let stringLength = content.trim().length;
      let maxCharDisease = 20;
      let linesCount = wordWrapCounter(content.trim(), maxCharDisease)
      labels[i].wordwrap(content ? content.trim() : "", maxCharDisease)
      labels[i].bounds.center.x = center.x

      labels[i].bounds.top = labels[i-1].bounds.bottom + 0.5
      if(linesCountPrevious > 1){
        labels[i].bounds.top += ((linesCount - 1) * 15)
      }
      i += 1;
      linesCountPrevious = linesCount
    }
  }
}

const createNonBloodRelative = (paper, option) => {
  var person = {
    index: option.index,
    selected: false
  }

  let highlight = getHighlight(paper, option)
  let strokeColor = option.printing ? settings.strokeColorPrinting : settings.fillColor

  var center = getCenter(option);
  var { x_loc, y_loc } = getXYFromCenter(option);
  let labels = drawLabels(paper, option, center)
  if (option.gender != String(option.data.profile.gender_identity).charAt(0) && option.data.profile.gender_identity != null && option.data.profile.gender_identity != "") {
    if (String(option.data.profile.gender_identity).charAt(0) == 'f') {
      var mask = new paper.Path.Circle({
        fillColor: 'white',
        center: center,
        radius: settings.person_width / 2,
        strokeColor: strokeColor
      })

    } else if (option.data.profile.gender_identity == 'non-binary') {
      var unspecified_gender = new paper.Path.Rectangle({
        x: x_loc + 4,
        y: y_loc + 4,
        width: settings.person_width - 8,
        height: settings.person_width - 8,
        strokeColor: strokeColor,
      })
      unspecified_gender.rotate(45)
      mask.style.strokeColor = "#fff"
    } else {
      var mask = new paper.Path.Rectangle({
        fillColor: 'white',
        x: x_loc,
        y: y_loc,
        width: settings.person_width,
        height: settings.person_width,
        strokeColor: strokeColor,
      })
    }
  }
  else {
    if (option.data.profile.is_infertility_node) {
      var mask = new paper.Path.Rectangle({
        fillColor: settings.defaultColor,
        x: x_loc,
        y: y_loc,
        // x: option.loc.x,
        // y: option.loc.y,
        width: settings.person_width,
        height: 4,
        // strokeColor: settings.strokeColor,
        // strokeWidth: 1
      })

      var mask2 = new paper.Path.Rectangle({
        fillColor: settings.defaultColor,
        x: x_loc,
        y: y_loc + 10,
        // x: option.loc.x,
        // y: option.loc.y,
        width: settings.person_width,
        height: 4,
        // strokeColor: settings.strokeColor,
        // strokeWidth: 1
      })


      let group = new paper.Group()
      group.addChild(mask);
      group.addChild(mask2);
      mask = group;

    } else if (option.data.profile.is_no_children_node) {
      var mask = new paper.Path.Rectangle({
        fillColor: settings.defaultColor,
        x: x_loc,
        y: y_loc,
        // x: option.loc.x,
        // y: option.loc.y,
        width: settings.person_width,
        height: 4,
        // strokeColor: settings.strokeColor,
        // strokeWidth: 1
      })
    } else if (option.gender.toLowerCase() == 'f') {
      var mask = new paper.Path.Circle({
        fillColor: 'white',
        center: center,
        radius: settings.person_width / 2,
        strokeColor: strokeColor
      })

    } else if (option.gender.toLowerCase() == "u" || option.gender == null) {
      var mask = new paper.Path.Rectangle({
        fillColor: settings.defaultColor,
        x: x_loc + 4,
        y: y_loc + 4,
        // x: option.loc.x + 4,
        // y: option.loc.y + 4,
        width: settings.person_width - 8,
        height: settings.person_width - 8,
        strokeColor: strokeColor,
        strokeWidth: 1
      })
      mask.rotate(45)


    } else {
      var mask = new paper.Path.Rectangle({
        fillColor: 'white',
        x: x_loc,
        y: y_loc,
        width: settings.person_width,
        height: settings.person_width,
        strokeColor: strokeColor,
      })
    }
  }

  // Node border
  var mask_border = mask.clone()
  mask_border.strokeWidth = 4
  mask_border.strokeColor = strokeColor

  //named
  mask.name = 'mask'
  mask_border.name = 'mask_border'

  var group = new paper.Group()
  group.addChild(mask)
  group.addChild(mask_border);
  group.clipped = true // avoid to show the border color

  if ('conditions' in option && option.conditions.length > 0) {
    drawConditions(paper, group, option)
  }

  if ('inheritance_pattern' in option) {
    drawInheritancePattern(paper, group, option)
  }

  if ('deceased' in option && option.deceased) {
    let deceasedLine = drawDeceased(paper, option.loc)
  }

  if ('adopted' in option && option.adopted) {
    person.adopted_frame = drawAdoptedFrame(paper, group, option)
  }

  person.shape = group
  person.highlight = highlight
  person.labels = labels
  person.loc = option.loc
  return person
}


const createBloodRelative = (paper, option) => {
  var person = {
    index: option.index,
    selected: false
  }
  let strokeColor = option.printing ? settings.strokeColorPrinting : settings.fillColor

  let highlight = getHighlight(paper, option)

  var center = getCenter(option);
  var { x_loc, y_loc } = getXYFromCenter(option);
  let labels = drawLabels(paper, option, center)
  

  if('pregnancy' in option && ('deceased' in option && option.deceased)){
    person = drawPregnancyDeceased(paper, option)
    person.highlight = highlight
    person.labels = labels
    person.loc = option.loc
    return person
  }
  if ('pregnancy' in option) {
    person = drawPregnancy(paper, option)
    person.highlight = highlight
    person.labels = labels
    person.loc = option.loc
    return person
  }

  // var center = option.loc.add(settings.person_width / 2);


  // Create condition masking
  if (option.gender != String(option.data.profile.gender_identity).charAt(0) && option.data.profile.gender_identity != null && option.data.profile.gender_identity != "") {
    if (String(option.data.profile.gender_identity).charAt(0) == 'f') {
      var mask = new paper.Path.Circle({
        fillColor: settings.defaultColor,
        center: center,
        radius: (settings.person_width / 2),
        // strokeColor: settings.strokeColor,
      })
    } else if (option.data.profile.gender_identity == 'non-binary') {
      var mask = new paper.Path.Rectangle({
        fillColor: settings.defaultColor,
        x: x_loc + 4,
        y: y_loc + 4,
        // x: option.loc.x + 4,
        // y: option.loc.y + 4,
        width: settings.person_width - 8,
        height: settings.person_width - 8,
        strokeColor: strokeColor,
        strokeWidth: 1
      })
      mask.rotate(45)
    } else {
      var mask = new paper.Path.Rectangle({
        fillColor: settings.defaultColor,
        x: x_loc,
        y: y_loc,
        // x: option.loc.x,
        // y: option.loc.y,
        width: settings.person_width,
        height: settings.person_width,
        // strokeColor: settings.strokeColor,
        // strokeWidth: 1
      })
    }
  }
  else {
    if (option.data.profile.is_infertility_node) {
      var mask = new paper.Path.Rectangle({
        fillColor: settings.defaultColor,
        x: x_loc,
        y: y_loc,
        // x: option.loc.x,
        // y: option.loc.y,
        width: settings.person_width,
        height: 4,
        // strokeColor: settings.strokeColor,
        // strokeWidth: 1
      })

      var mask2 = new paper.Path.Rectangle({
        fillColor: settings.defaultColor,
        x: x_loc,
        y: y_loc + 10,
        // x: option.loc.x,
        // y: option.loc.y,
        width: settings.person_width,
        height: 4,
        // strokeColor: settings.strokeColor,
        // strokeWidth: 1
      })


      let group = new paper.Group()
      group.addChild(mask);
      group.addChild(mask2);
      mask = group;

    } else if (option.data.profile.is_no_children_node) {
      var mask = new paper.Path.Rectangle({
        fillColor: settings.defaultColor,
        x: x_loc,
        y: y_loc,
        // x: option.loc.x,
        // y: option.loc.y,
        width: settings.person_width,
        height: 4,
        // strokeColor: settings.strokeColor,
        // strokeWidth: 1
      })
    } else if (option.gender.toLowerCase() == 'f') {
      var mask = new paper.Path.Circle({
        fillColor: settings.defaultColor,
        center: center,
        radius: (settings.person_width / 2),
        // strokeColor: settings.strokeColor,
      })
    } else if (option.gender.toLowerCase() == 'u') {
      var mask = new paper.Path.Rectangle({
        fillColor: settings.defaultColor,
        x: x_loc + 4,
        y: y_loc + 4,
        // x: option.loc.x + 4,
        // y: option.loc.y + 4,
        width: settings.person_width - 8,
        height: settings.person_width - 8,
        strokeColor: strokeColor,
        strokeWidth: 1
      })
      mask.rotate(45)
    } else {
      var mask = new paper.Path.Rectangle({
        fillColor: settings.defaultColor,
        x: x_loc,
        y: y_loc,
        // x: option.loc.x,
        // y: option.loc.y,
        width: settings.person_width,
        height: settings.person_width,
        // strokeColor: settings.strokeColor,
        // strokeWidth: 1
      })
    }
  }

  let { profile } = option.data;
  if (profile.is_proband) {
    drawProbandArrow(paper, option.loc)
  }


  // if ('proband' in option && option.proband) {
  //   drawProbandArrow(paper, option.loc)
  // }

  // Node border
  var mask_border = mask.clone()
  mask_border.strokeWidth = 4
  mask_border.strokeColor = strokeColor

  //named
  mask.name = 'mask'
  mask_border.name = 'mask_border'

  var group = new paper.Group()
  group.addChild(mask)
  group.addChild(mask_border);
  group.clipped = true // avoid to show the border color

  if ('conditions' in option && option.conditions.length > 0) {
    drawConditions(paper, group, option)
  }

  if ('inheritance_pattern' in option) {
    drawInheritancePattern(paper, group, option)
  }

  if ('deceased' in option && option.deceased) {
    let deceasedLine = drawDeceased(paper, option.loc)

  }

  if ('adopted' in option && option.adopted) {
    person.adopted_frame = drawAdoptedFrame(paper, group, option)
  }

  person.shape = group
  person.highlight = highlight
  person.labels = labels
  person.loc = option.loc
  return person

}

const getHighlight = (paper, option) => {
  // var center = option.loc.add(settings.person_width / 2)
  var center = getCenter(option);
  var { x_loc, y_loc } = getXYFromCenter(option);

  var highlight = null
  var isAdopted = ('adopted' in option && option.adopted)

  if (option.gender != String(option.data.profile.gender_identity).charAt(0) && option.data.profile.gender_identity != null && option.data.profile.gender_identity != "") {
    if (String(option.data.profile.gender_identity).charAt(0) == 'f' && isAdopted == false) {
      highlight = new paper.Path.Circle({
        visible: false,
        fillColor: settings.person_highlight_fillColor,
        center: center,
        radius: (settings.person_width / 2) + settings.person_highlight_strokeWidth
      })
    } else {
      let highlight_strokeWidth = settings.person_highlight_strokeWidth
      let width = settings.person_width + (highlight_strokeWidth * 2)
      let height = settings.person_width + (highlight_strokeWidth * 2)
      // let x = option.loc.x - highlight_strokeWidth
      // let y = option.loc.y - highlight_strokeWidth
      let x = x_loc - highlight_strokeWidth;
      let y = y_loc - highlight_strokeWidth;
      if (option.data.profile.gender_identity == 'non-binary') {
        width -= 8
        height -= 8
        x += 4
        y += 4
      }

      highlight = new paper.Path.Rectangle({
        visible: false,
        fillColor: settings.person_highlight_fillColor,
        x, y,
        width, height
      })
      if (option.data.profile.gender_identity == 'non-binary') highlight.rotate(45)
    }
  }
  else {
    if (option.data.profile.is_infertility_node) {
      let highlight_strokeWidth = settings.person_highlight_strokeWidth
      let width = settings.person_width + (highlight_strokeWidth * 2)
      let height = 24
      let x = x_loc - highlight_strokeWidth;
      let y = y_loc - highlight_strokeWidth;

      highlight = new paper.Path.Rectangle({
        visible: false,
        fillColor: settings.person_highlight_fillColor,
        x, y,
        width, height
      })

    } else if (option.data.profile.is_no_children_node) {
      let highlight_strokeWidth = settings.person_highlight_strokeWidth
      let width = settings.person_width + (highlight_strokeWidth * 2)
      let height = 14
      let x = x_loc - highlight_strokeWidth;
      let y = y_loc - highlight_strokeWidth;

      highlight = new paper.Path.Rectangle({
        visible: false,
        fillColor: settings.person_highlight_fillColor,
        x, y,
        width, height
      })

    } else if (option.gender.toLowerCase() == 'f' && isAdopted == false) {
      highlight = new paper.Path.Circle({
        visible: false,
        fillColor: settings.person_highlight_fillColor,
        center: center,
        radius: (settings.person_width / 2) + settings.person_highlight_strokeWidth
      })
    } else {
      let highlight_strokeWidth = settings.person_highlight_strokeWidth
      let width = settings.person_width + (highlight_strokeWidth * 2)
      let height = settings.person_width + (highlight_strokeWidth * 2)
      // let x = option.loc.x - highlight_strokeWidth
      // let y = option.loc.y - highlight_strokeWidth
      let x = x_loc - highlight_strokeWidth;
      let y = y_loc - highlight_strokeWidth;
      if (option.gender.toLowerCase() == "u") {
        width -= 8
        height -= 8
        x += 4
        y += 4
      }

      highlight = new paper.Path.Rectangle({
        visible: false,
        fillColor: settings.person_highlight_fillColor,
        x, y,
        width, height
      })
      if (option.gender.toLowerCase() == 'u') highlight.rotate(45)
    }
  }

  return highlight
}

const drawPregnancy = (paper, option) => {
  let type = option.pregnancy.type

  if (type == "stillbirth")
    return drawPregnancyStillBirth(paper, option)
  else if (type == 'miscarriage')
    return drawPregnancyMiscarriage(paper, option)
  else if (type == 'terminated')
    return drawPregnancyTerminated(paper, option)
  else
    return drawPregnancyNormal(paper, option)

}

const drawPregnancyNormal = (paper, option) => {
  var person = {
    index: option.index,
    selected: false
  }

  let strokeColor = option.printing ? settings.strokeColorPrinting : settings.strokeColor

  // var center = option.loc.add(settings.person_width / 2)
  var center = getCenter(option);
  var { x_loc, y_loc } = getXYFromCenter(option);

  if (option.gender.toLowerCase() == 'f') {
    var mask = new paper.Path.Circle({
      fillColor: 'white',
      center: center,
      radius: settings.person_width / 2,
    })
  } else {
    let width = settings.person_width
    let height = settings.person_width
    // let x = option.loc.x
    // let y = option.loc.y
    let x = x_loc;
    let y = y_loc;
    if (option.gender.toLowerCase() == "u") {
      width -= 8
      height -= 8
      x += 4
      y += 4
    }
    var mask = new paper.Path.Rectangle({
      fillColor: 'white',
      x, y,
      width, height
    })
  }

  var mask_border = mask.clone()
  mask_border.strokeWidth = 2
  mask_border.strokeColor = strokeColor
  if (option.gender.toLowerCase() == "u") {
    mask_border.rotate(45)
    mask.rotate(45)
  }

  var group = new paper.Group()
  group.addChild(mask)
  group.addChild(mask_border)
  group.clipped = true

  var text = new paper.PointText(center.add(-5, 5))
  text.fillColor = settings.pregnancyTextColor
  text.content = 'P'
  text.style = { fontSize: 14, fontWeight: 'bold' }
  group.addChild(text)

  person.shape = group
  return person

}
const drawPregnancyDeceased = (paper, option) => {
  var person = {
    index: option.index,
    selected: false
  }

  let strokeColor = option.printing ? settings.deceased_strokeColor_printing : settings.deceased_strokeColor
  // var center = option.loc.add(settings.person_width / 2)
  var center = getCenter(option);
  var { x_loc, y_loc } = getXYFromCenter(option);

  if (option.gender.toLowerCase() == 'f') {
    var mask = new paper.Path.Circle({
      fillColor: 'white',
      center: center,
      radius: settings.person_width / 2,
    })
  } else {
    let width = settings.person_width
    let height = settings.person_width
    // let x = option.loc.x
    // let y = option.loc.y
    let x = x_loc;
    let y = y_loc;
    if (option.gender.toLowerCase() == "u") {
      width -= 8
      height -= 8
      x += 4
      y += 4
  }

  var group = new paper.Group()
  group.addChild(mask)
  group.clipped = true

  var mask = new paper.Path.Rectangle({
      fillColor: 'white',
      x, y,
      width, height
    })
  }

  var mask_border = mask.clone()
  mask_border.strokeWidth = 2
  mask_border.strokeColor = strokeColor
  if (option.gender.toLowerCase() == "u") {
    mask_border.rotate(45)
    mask.rotate(45)
  }

  var group = new paper.Group()
  group.addChild(mask)
  group.addChild(mask_border)
  group.clipped = true

  var text = new paper.PointText(center.add(-5, 5))
  text.fillColor = settings.pregnancyTextColor
  text.content = 'P'
  text.style = { fontSize: 14, fontWeight: 'bold' }
  group.addChild(text)

  var { x_pad, y_pad } = getXYpad();
  let height = settings.person_width;
  let center_x = option.loc.add(settings.person_width / 2 + x_pad, 0);
  let line = new paper.Path.Line(center_x.add(0, -7), center_x.add(0, height + 7))
  line.style = { strokeColor: strokeColor, strokeWidth: 2 }
  line.rotate(45)


  person.shape = group
  return person
}

const drawPregnancyStillBirth = (paper, option) => {
  var person = {
    index: option.index,
    selected: false
  }
  let strokeColor = option.printing ? settings.strokeColorPrinting : settings.deceased_strokeColor

  // var center = option.loc.add(settings.person_width / 2)
  var center = getCenter(option);
  var { x_loc, y_loc } = getXYFromCenter(option);

  if (option.gender.toLowerCase() == 'f') {
    var mask = new paper.Path.Circle({
      fillColor: 'white',
      center: center,
      radius: settings.person_width / 2,
    })
  } else {
    var mask = new paper.Path.Rectangle({
      fillColor: 'white',
      // x: option.loc.x,
      // y: option.loc.y,
      x: x_loc,
      y: y_loc,
      width: settings.person_width,
      height: settings.person_width,
    })
  }

  var group = new paper.Group()
  group.addChild(mask)
  group.clipped = true

  if (option.gender.toLowerCase() == "u") {
    var unspecified_gender = new paper.Path.Rectangle({
      // x: option.loc.x + 4,
      // y: option.loc.y + 4,
      x: x_loc + 4,
      y: y_loc + 4,
      width: settings.person_width - 8,
      height: settings.person_width - 8,
      fillColor: settings.fillColor,
    })
    unspecified_gender.rotate(45)
    group.addChild(unspecified_gender)
  } else {
    var square = new paper.Path.Rectangle({
      fillColor: settings.fillColor,
      // x: option.loc.x,
      // y: option.loc.y,
      x: x_loc,
      y: y_loc,
      width: settings.person_width,
      height: settings.person_width,
    })
    group.addChild(square)
  }

  var text = new paper.PointText(center.add(-10, 5))
  text.fillColor = 'white'
  text.content = 'SB'
  text.style = { fontSize: 14, fontWeight: 'bold' }
  group.addChild(text)

  let height = settings.person_width
  let center_x = option.loc.add(settings.person_width / 2, 0)
  let line = new paper.Path.Line(center_x.add(0, -7), center_x.add(0, height + 7))
  line.style = { strokeColor: strokeColor, strokeWidth: 2 }
  line.rotate(45)
  group.addChild(line)

  person.shape = group
  return person

}

const drawPregnancyMiscarriage = (paper, option) => {
  var person = {
    index: option.index,
    selected: false
  }

  let strokeColor = option.printing ? settings.strokeColorPrinting : settings.miscarriage_fillColor

  var { x_loc, y_loc } = getXYFromCenter(option);

  var mask = new paper.Path.Rectangle({
    fillColor: 'white',
    // x: option.loc.x,
    // y: option.loc.y,
    x: x_loc,
    y: y_loc,
    width: settings.person_width,
    height: settings.person_width,
    // strokeColor: settings.fillColor,
  })
  var group = new paper.Group()
  group.addChild(mask)
  group.clipped = true


  // Start: Draw Triangle
  let triangle = new paper.Path()
  triangle.style = { fillColor: strokeColor }

  let center_x = option.loc.add(settings.person_width / 2, 0)
  triangle.add(center_x)

  let bottom_right = option.loc.add([settings.person_width])
  triangle.add(bottom_right)

  let bottom_left = option.loc.add([0, settings.person_width])
  triangle.add(bottom_left)

  triangle.closed = true
  // End: Draw Triangle

  group.addChild(triangle)

  person.shape = group
  return person

}

const drawPregnancyTerminated = (paper, option) => {
  var person = {
    index: option.index,
    selected: false
  }
  var { x_loc, y_loc } = getXYFromCenter(option);

  let strokeColor = option.printing ? settings.strokeColorPrinting : settings.pregnancyTerminated_strokeColor

  var mask = new paper.Path.Rectangle({
    fillColor: 'white',
    x: x_loc,
    y: y_loc,
    width: settings.person_width,
    height: settings.person_width,
    // strokeColor: settings.fillColor,
  })
  var group = new paper.Group()
  group.addChild(mask)
  group.clipped = true


  // Start: Draw Triangle
  let triangle = new paper.Path()
  triangle.style = { fillColor: settings.miscarriage_fillColor }

  let center_x = option.loc.add(settings.person_width / 2, 0)
  triangle.add(center_x)

  let bottom_right = option.loc.add([settings.person_width])
  triangle.add(bottom_right)

  let bottom_left = option.loc.add([0, settings.person_width])
  triangle.add(bottom_left)

  triangle.closed = true
  // End: Draw Triangle

  let line = new paper.Path()
  line.style = { strokeColor: strokeColor, strokeWidth: 2 }
  line.add(option.loc.add(0, settings.person_width))
  line.add(option.loc.add(settings.person_width, 0))

  group.addChild(triangle)
  group.addChild(line)

  person.shape = group
  return person

}

// Start: Draw Inheritance Pattern
const drawInheritancePattern = (paper, group, option) => {
  let inheritance_pattern = option.inheritance_pattern

  // Carrier
  if (inheritance_pattern.indexOf('autosomal_recessive_inheritance') != -1) {
    let recess = drawAutosomalRecessive(paper, group, option)
    group.addChild(recess)
  }

}

const drawProbandArrow = (paper, topLeft) => {
  var { x_pad, y_pad } = getXYpad();
  let height = settings.person_width;
  let center = settings.person_width / 2 + x_pad;
  var sides = 3;
  var radius = 10;

  var probandArrow = new paper.Path.RegularPolygon(topLeft.add([-8, (settings.person_width+12)]), sides, radius);
  let path = new paper.Path.Line(topLeft.add([(height-57), 57]), topLeft.add([-9, (height + 10)]))

  path.style = { strokeColor: 'grey', strokeWidth: 4 }
  probandArrow.fillColor = 'grey';
  probandArrow.rotate(45)
  probandArrow.scale(1.1)
  arrowProband.push(path)
  arrowProband.push(probandArrow)
}

const drawDeceased = (paper, topLeft) => {
  var { x_pad, y_pad } = getXYpad();
  let height = settings.person_width;
  let center = settings.person_width / 2 + x_pad;
  let line = new paper.Path.Line(topLeft.add(center, -12), topLeft.add(center, height+12))
  line.style = { strokeColor: settings.deceased_strokeColor, strokeWidth: 2 }
  line.rotate(45)
  deceasedLines.push(line)
  return line
}

const drawAutosomalRecessive = (paper, group, option) => {
  var center = getCenter(option);
  var width = settings.person_width * 0.40
  let fillColor = option.printing ?  settings.autosomal_fillColor_center_printing : settings.autosomal_fillColor_center
  // Always circle
  var recessive = new paper.Path.Circle({
    fillColor: fillColor,
    center: center,
    radius: width / 2,
  })

  return recessive
}

const drawAdoptedFrame = (paper, group, option) => {
  let { x_pad, y_pad } = getXYpad();
  let strokeColor = option.printing ?settings.strokeColorPrinting : settings.adopted_strokeColor
  let white_break = 5

  let left_frame = new paper.Path()
  left_frame.style = { strokeColor: strokeColor, strokeWidth: 1 }

  var half_width = settings.person_width / 2;
  var hori_bar_width = half_width - white_break

  var left_pos = option.loc.clone()
  // left_pos = left_pos.add([hori_bar_width,0])
  left_pos = left_pos.add([hori_bar_width + x_pad, y_pad]);

  // Top
  left_frame.add(left_pos)
  left_pos = left_pos.subtract([hori_bar_width, 0])
  left_frame.add(left_pos)

  // Side
  left_pos = left_pos.add([0, settings.person_width])
  left_frame.add(left_pos)

  // Bottom
  left_pos = left_pos.add([hori_bar_width, 0])
  left_frame.add(left_pos)

  let right_frame = left_frame.clone()
  right_frame.scale(-1, 1)
  right_frame.position = right_frame.position.add([half_width + white_break, 0])



  group.children['mask'].scale(.85)
  group.children['mask_border'].scale(.85)
  return { left_frame, right_frame };
}

// End: Draw Inheritance Pattern

const drawConditions = (paper, group, option) => {
  if (!('condition_type' in option)) return

  if (option.condition_type == 'shape') {
    drawShapedConditions(paper, group, option)
  } else {
    drawColoredConditions(paper, group, option)
  }
}

// Start Draw Color Condition
const drawColoredConditions = (paper, group, option, hidden_disease_colors, getColor) => {
  let colors = option.conditions
  if (colors.length > 4) colors = colors.slice(0, 4)

  let boxes = drawQuadrantBoxes(paper, group, option.loc, colors)
  allQuadrantColors.push(boxes)
  if(hidden_disease_colors && getColor){
    updateBoxColors(colors, boxes, hidden_disease_colors, getColor)
  }
  else{
    updateBoxColors(colors, boxes, option.hidden_disease_colors, option.getColor)
  }
  reDrawBorder(paper, option)
}

const reDrawBorder = (paper, option) => {
  let strokeColor = option.printing ? settings.strokeColorPrinting : settings.strokeColor
  var center = getCenter(option);
  var { x_loc, y_loc } = getXYFromCenter(option);
  if (option.gender != String(option.data.profile.gender_identity).charAt(0) && option.data.profile.gender_identity != null && option.data.profile.gender_identity != "") {
    if (String(option.data.profile.gender_identity).charAt(0) == 'f') {
      new paper.Path.Circle({
        center: center,
        radius: settings.person_width / 2 - 1,
        strokeColor: strokeColor,
        strokeWidth: 2,
      })
      new paper.Path.Circle({
        center: center,
        radius: settings.person_width / 2 - 1,
        strokeColor: strokeColor,
        strokeWidth: 2,
      })

    } else if (option.data.profile.gender_identity == 'non-binary') {
      var unspecified_gender = new paper.Path.Rectangle({
        x: x_loc + 5,
        y: y_loc + 5,
        width: settings.person_width - 10,
        height: settings.person_width - 10,
        strokeColor: strokeColor,
        strokeWidth: 2,
      })
      unspecified_gender.rotate(45)
    } else {
      new paper.Path.Rectangle({
        x: x_loc + 1,
        y: y_loc + 1,
        width: settings.person_width - 2,
        height: settings.person_width - 2,
        strokeColor: strokeColor,
        strokeWidth: 2,
      })
    }
  }
  else {
    if (option.gender.toLowerCase() === 'f') {
      new paper.Path.Circle({
        center: center,
        radius: settings.person_width / 2 - 1,
        strokeColor: settings.chart_bg_color,
        strokeWidth: 2,
      })
      new paper.Path.Circle({
        center: center,
        radius: settings.person_width / 2 - 1,
        strokeColor: strokeColor,
        strokeWidth: 2,
      })

    } else if (option.gender.toLowerCase() === "u") {
      var unspecified_gender = new paper.Path.Rectangle({
        x: x_loc + 5,
        y: y_loc + 5,
        width: settings.person_width - 10,
        height: settings.person_width - 10,
        strokeColor: strokeColor,
        strokeWidth: 2,
      })
      unspecified_gender.rotate(45)
    } else {
      new paper.Path.Rectangle({
        x: x_loc + 1,
        y: y_loc + 1,
        width: settings.person_width - 2,
        height: settings.person_width - 2,
        strokeColor: strokeColor,
        strokeWidth: 2,
      })
    }
  }
}

const updateBoxColors = (colors, boxes, hidden_disease_colors, getColor) => {

  if (colors.length > 4) colors = colors.slice(0, 4)
  for (let color of colors) {
    colorsForLegend.push(color)
  }

  for(let color of hidden_disease_colors){
    if(color.disease_id){
      colorsForLegend.push(getColor(color.disease_id))
    }else{
      colorsForLegend.push(getColor(color.umls_id))
    }
  }
  switch (colors.length) {
    case 1: // 1 color
      boxes.box_1.fillColor = colors[0]
      boxes.box_1.strokeColor = colors[0]
      boxes.box_2.fillColor = colors[0]
      boxes.box_2.strokeColor = colors[0]
      boxes.box_3.fillColor = colors[0]
      boxes.box_3.strokeColor = colors[0]
      boxes.box_3.bounds.left = boxes.box_1.bounds.left
      boxes.box_4.fillColor = colors[0]
      boxes.box_4.strokeColor = colors[0]
      boxes.box_4.bounds.left = boxes.box_2.bounds.left
      break;
    case 2: // 2 colors
      boxes.box_1.fillColor = colors[0]
      boxes.box_1.strokeColor = colors[0]
      boxes.box_2.fillColor = colors[0]
      boxes.box_2.strokeColor = colors[0]
      boxes.box_3.fillColor = colors[1]
      boxes.box_3.strokeColor = colors[1]
      boxes.box_3.bounds.left = boxes.box_1.bounds.left
      boxes.box_3.bounds.y = boxes.box_3.bounds.y + 0.5
      boxes.box_4.fillColor = colors[1]
      boxes.box_4.strokeColor = colors[1]
      boxes.box_4.bounds.left = boxes.box_2.bounds.left
      boxes.box_4.bounds.y = boxes.box_3.bounds.y
      break;
    case 3: // 3 colors
      boxes.box_1.fillColor = colors[0]
      boxes.box_1.strokeColor = colors[0]
      boxes.box_2.fillColor = colors[0]
      boxes.box_2.strokeColor = colors[0]
      boxes.box_2.y = boxes.box_1.y
      boxes.box_3.fillColor = colors[1]
      boxes.box_3.strokeColor = colors[1]
      boxes.box_3.bounds.x = boxes.box_1.bounds.x
      boxes.box_4.fillColor = colors[2]
      boxes.box_4.strokeColor = colors[2]
      boxes.box_4.bounds.x = boxes.box_2.bounds.x + 0.5
      boxes.box_4.bounds.y = boxes.box_3.bounds.y
      break;
    case 4: // 4 colors
      boxes.box_1.fillColor = colors[0]
      boxes.box_1.strokeColor = colors[0]
      boxes.box_2.fillColor = colors[1]
      boxes.box_2.strokeColor = colors[1]
      boxes.box_2.bounds.x = boxes.box_2.bounds.x + 0.5
      boxes.box_2.bounds.y = boxes.box_1.bounds.y
      boxes.box_3.fillColor = colors[2]
      boxes.box_3.strokeColor = colors[2]
      boxes.box_3.bounds.y = boxes.box_3.bounds.y + 0.5
      boxes.box_3.bounds.x = boxes.box_1.bounds.x
      boxes.box_4.fillColor = colors[3]
      boxes.box_4.strokeColor = colors[3]
      boxes.box_4.bounds.x = boxes.box_2.bounds.x
      boxes.box_4.bounds.y = boxes.box_3.bounds.y
  }
}

const reDrawColoredConditions = (paper, person, conditions, hidden_disease_colors, getColor) => {
  let children = person.shape.children
  while (children.length > 1) {
    children[children.length - 1].remove()
  }

  drawColoredConditions(paper, person.shape, {
    condition_type: 'color',
    loc: person.loc,
    conditions,
  }, hidden_disease_colors, getColor
  )

}

const drawQuadrantBoxes = (paper, group, topLeft, colors) => {

  if (colors.length > 4) colors = colors.slice(0, 4)

  let { x_pad, y_pad } = getXYpad();
  let topLeftAdjusted = topLeft.add([x_pad, y_pad]);

  var box_width = settings.person_width / 2
  var box_1_loc = topLeftAdjusted
  var box_2_loc = box_1_loc.add([box_width, 0])
  var box_3_loc = box_2_loc.add([0, box_width])
  var box_4_loc = box_3_loc.subtract([box_width, 0])

  var box_1 = new paper.Shape.Rectangle({
    fillColor: settings.defaultColor,
    x: box_1_loc.x,
    y: box_1_loc.y,
    width: box_width,
    height: box_width,
  });

  var box_2 = new paper.Shape.Rectangle({
    fillColor: settings.defaultColor,
    x: box_2_loc.x,
    y: box_2_loc.y,
    width: box_width,
    height: box_width,
  });

  var box_3 = new paper.Shape.Rectangle({
    fillColor: settings.defaultColor,
    x: box_3_loc.x,
    y: box_3_loc.y,
    width: box_width,
    height: box_width,
  });

  var box_4 = new paper.Shape.Rectangle({
    fillColor: settings.defaultColor,
    x: box_4_loc.x,
    y: box_4_loc.y,
    width: box_width,
    height: box_width,
  });

  group.addChild(box_1)
  group.addChild(box_2)
  group.addChild(box_3)
  group.addChild(box_4)

  return {
    box_1,
    box_2,
    box_3,
    box_4
  }
}

// End Draw Color Condition

// Start Draw Shape Condition

const reDrawShapedConditions = (paper, person, conditions) => {
  let children = person.shape.children
  while (children.length > 1) {
    children[children.length - 1].remove()
  }

  drawShapedConditions(paper, person.shape, {
    condition_type: 'shape',
    loc: person.loc,
    conditions
  })

}

const drawShapedConditions = (paper, group, option) => {
  console.log("shaped");
  let boxes = getShapeBoxes(option.loc)

  let shapes = option.conditions
  if (shapes.length > 4) shapes = shapes.slice(0, 3)
  switch (shapes.length) {
    case 1:
      drawShapedItem(paper, group, boxes.whole, shapes[0])
      break;
    case 2:
      drawShapedItem(paper, group, boxes.top_half, shapes[0])
      drawShapedItem(paper, group, boxes.bottom_half, shapes[1])
      break;
    case 3:
      drawShapedItem(paper, group, boxes.top_half, shapes[0])
      drawShapedItem(paper, group, boxes.box_3, shapes[1])
      drawShapedItem(paper, group, boxes.box_4, shapes[2])
      break;
    case 4:
      drawShapedItem(paper, group, boxes.box_1, shapes[0])
      drawShapedItem(paper, group, boxes.box_2, shapes[1])
      drawShapedItem(paper, group, boxes.box_3, shapes[2])
      drawShapedItem(paper, group, boxes.box_4, shapes[3])
    default:
      drawQuadrantBoxes(paper, group, option.loc)
  }

}

const drawShapedItem = (paper, group, bounds, code) => {

  var shapedItem = new paper.Raster(code)
  shapedItem.position = bounds.center
  shapedItem.size = new paper.Size(bounds.width, bounds.height)
  group.addChild(shapedItem)

}

const getShapeBoxes = (topLeft) => {
  var box_width = settings.person_width / 2
  var box_half = box_width / 2
  var box_1_loc = topLeft
  var box_2_loc = box_1_loc.add([box_width, 0])
  var box_3_loc = box_2_loc.add([0, box_width])
  var box_4_loc = box_3_loc.subtract([box_width, 0])

  var top_half_loc = topLeft
  var bottom_half_loc = topLeft.add([0, box_width])

  var box_1 = {
    topLeft: box_1_loc,
    center: box_1_loc.add([box_half, box_half]),
    width: box_width,
    height: box_width,
  }

  var box_2 = {
    topLeft: box_2_loc,
    center: box_2_loc.add([box_half, box_half]),
    width: box_width,
    height: box_width
  }

  var box_3 = {
    topLeft: box_3_loc,
    center: box_3_loc.add([box_half, box_half]),
    width: box_width,
    height: box_width
  }

  var box_4 = {
    topLeft: box_4_loc,
    center: box_4_loc.add([box_half, box_half]),
    width: box_width,
    height: box_width,
  }

  var top_half = {
    topLeft: top_half_loc,
    center: top_half_loc.add([box_width, box_half]),
    width: box_width * 2,
    height: box_width,
  }

  var bottom_half = {
    topLeft: bottom_half_loc,
    center: bottom_half_loc.add([box_width, box_half]),
    width: box_width * 2,
    height: box_width,
  }

  var whole = {
    topLeft: topLeft,
    center: topLeft.add([box_width, box_width]),
    width: box_width * 2,
    height: box_width * 2,
  }

  return {
    whole,
    top_half,
    bottom_half,
    box_1,
    box_2,
    box_3: box_4,
    box_4: box_3,
  }

}

// Draw Pedigree legend for diseases
const drawLegend = (displayLegend, paper, history_diseases, getColor, family_tree, onLegendEyeClick, showNotes, pedigreeNotes, onPedigreeNotesSave) => {
  const parentNode = document.getElementById('paper-wrapper');
  if (displayLegend || showNotes) {

    // remove current DOM legend if its on the DOM tree already
    const legend_container = document.getElementById(constants.LEGEND_DOM_CONTAINER_ELEMENT);
    if (legend_container) parentNode.removeChild(legend_container);

    const top_banners_height = parentNode.offsetTop;
    const extra_space = 40;

    let top = top_banners_height + extra_space;
    let left = extra_space;

    let savedCenter = JSON.parse(window.localStorage.getItem("famgenix_legend_center"));
    if (savedCenter != null && savedCenter != undefined) {
      top = savedCenter[0];
      left = savedCenter[1];
    }

    const options = {
      parentNode: parentNode,
      left: left,
      top: top,
      height: 140,
      width: 220,
      showDiseases: displayLegend,
      showNotes: showNotes,
      historyDiseases: Object.values(history_diseases),
      hiddenDiseaseColors: family_tree.hidden_disease_colors,
      legendColors: colorsForLegend,
      pedigreeNotes: pedigreeNotes,
      toggleDisease: (disease_id, visible) => {
        onLegendEyeClick(disease_id, visible)
      },
      savePedigreeNotes: (pedigreeNotes) =>{
        onPedigreeNotesSave(pedigreeNotes)
      },
      onClose: () => {
        console.log("Closing Legend Callback");
      }
    };

    const legendDOM = new LegendDOM(options);
  } else {
    // remove current DOM legend if its on the DOM tree already
    const legend_container = document.getElementById(constants.LEGEND_DOM_CONTAINER_ELEMENT);
    if (legend_container) parentNode.removeChild(legend_container);
  }
  ////////
  // let legend = new paper.Path.Rectangle(new paper.Point(100, 100), new paper.Point(285, 225));
  //
  // function roundCorners(path, radius) {
  //   var segments = path.segments.slice(0);
  //   path.removeSegments();
  //
  //   for (var i = 0, l = segments.length; i < l; i++) {
  //     var curPoint = segments[i].point;
  //     var nextPoint = segments[i + 1 == l ? 0 : i + 1].point;
  //     var prevPoint = segments[i - 1 < 0 ? segments.length - 1 : i - 1].point;
  //     var nextDelta = curPoint.subtract(nextPoint);
  //     var prevDelta = curPoint.subtract(prevPoint);
  //
  //     nextDelta.length = radius;
  //     prevDelta.length = radius;
  //
  //     path.add(
  //       new paper.Segment(
  //         curPoint.subtract(prevDelta),
  //         null,
  //         prevDelta.divide(2)
  //       )
  //     );
  //
  //     path.add(
  //       new paper.Segment(
  //         curPoint.subtract(nextDelta),
  //         nextDelta.divide(2),
  //         null
  //       )
  //     );
  //   }
  //   path.closed = true;
  //   return path;
  // }
  //
  // if (displayLegend) {
  //   legend.fillColor = '#ffffff';
  //   legend.strokeColor = '#d7d7d7';
  //   let arr1 = Object.values(history_diseases)
  //   let diseases = [];
  //
  //   //Push all diseases into diseases array
  //   for (let number of arr1) {
  //     for (let i = 0; i < number.length; i++) {
  //       diseases.push(number[i])
  //     }
  //   }
  //
  //
  //
  //   //Make sure there is no repetition of the same disease
  //   var uniqueDiseases = [];
  //   var map = new Map();
  //   for (const item of diseases) {
  //     if (!map.has(item.disease_id)) {
  //       map.set(item.disease_id, true);    // set any value to Map
  //       uniqueDiseases.push({
  //         disease_id: item.disease_id,
  //         disease_name: item.disease_name,
  //         disease_short_name: item.disease_short_name,
  //         color: getColor(item.disease_id, family_tree)
  //       });
  //     }
  //   }
  //
  //   var finalDiseases = [];
  //   var finalDiseases1 = [];
  //
  //   //Make sure to only display legends for what colors are displayed in the pedigree (For when a node exceeds 4 diseases)
  //   for (let colorleg of colorsForLegend) {
  //     finalDiseases = uniqueDiseases.filter(disease => disease.color == colorleg)
  //     finalDiseases1.push(finalDiseases[0])
  //   }
  //
  //   //Clean up array
  //   var finalDiseases2 = finalDiseases1.filter(disease => disease != undefined)
  //
  //   //Make sure no repetition of colors
  //   var uniqueDiseasesFinal = []
  //   if (finalDiseases2.length > 0) {
  //     var map1 = new Map();
  //     for (const item of finalDiseases2) {
  //       if (!map1.has(item.disease_id)) {
  //         map1.set(item.disease_id, true);    // set any value to Map
  //         uniqueDiseasesFinal.push({
  //           disease_id: item.disease_id,
  //           disease_name: item.disease_name,
  //           disease_short_name: item.disease_short_name,
  //           color: getColor(item.disease_id, family_tree)
  //         });
  //       }
  //     }
  //   }
  //
  //   //dynamic size of legend container
  //   if (uniqueDiseasesFinal.length >= 5) {
  //     legend.firstSegment.point = new paper.Point(100, 225 + ((uniqueDiseasesFinal.length - 4) * 25))
  //     legend.lastSegment.point = new paper.Point(285, 225 + ((uniqueDiseasesFinal.length - 4) * 25))
  //   }
  //
  //   //implement rounded corners
  //   legend = roundCorners(legend, 4)
  //
  //   //alphabetically arrange display
  //   uniqueDiseasesFinal.sort(function (a, b) {
  //     if (a.disease_name < b.disease_name) { return -1; }
  //     if (a.disease_name > b.disease_name) { return 1; }
  //     return 0;
  //   })
  //
  //   var legendGroup = new paper.Group([legend])
  //   //render legend
  //   if (uniqueDiseasesFinal.length === 0) {
  //     var noDiseasesToDisplay = new paper.PointText()
  //     noDiseasesToDisplay.fillColor = '#333333'
  //     noDiseasesToDisplay.font = 'montserrat'
  //     noDiseasesToDisplay.style = { fontSize: 12 }
  //     noDiseasesToDisplay.content = 'No diseases to display'
  //     noDiseasesToDisplay.setPosition(125, 115)
  //     noDiseasesToDisplay.bounds.left = 110
  //     legendGroup.addChild(noDiseasesToDisplay)
  //   }
  //
  //   for (let i = 0; i < uniqueDiseasesFinal.length; i++) {
  //     var legendItem = new paper.Path.Rectangle(new paper.Point(118, 83), new paper.Point(132, 98));
  //     legendItem.fillColor = uniqueDiseasesFinal[i].color
  //     legendItem.setPosition(130, legendItem.position.y + ((i + 1) * 25))
  //     var diseaseName = new paper.PointText()
  //     diseaseName.fillColor = '#333333'
  //     diseaseName.font = 'montserrat'
  //     diseaseName.style = { fontSize: 12 }
  //     diseaseName.content = uniqueDiseasesFinal[i].disease_name.length >= 16 ? uniqueDiseasesFinal[i].disease_name.slice(0, 16) : uniqueDiseasesFinal[i].disease_name
  //     diseaseName.setPosition(125, legendItem.position.y)
  //     diseaseName.bounds.left = 140
  //
  //     //draw here the eye and slashed eye icons
  //     var eyeUnslashed = new paper.Raster('legend-eye')
  //     eyeUnslashed.position.x = legendItem.position.x - 20
  //     eyeUnslashed.position.y = legendItem.position.y
  //     eyeUnslashed.size = new paper.Size(15, 15)
  //
  //     var eyeSlashed = new paper.Raster('legend-eye-slash')
  //     eyeSlashed.position.x = legendItem.position.x - 20
  //     eyeSlashed.position.y = legendItem.position.y
  //     eyeSlashed.size = new paper.Size(15, 15)
  //
  //     let family_hidden_disease_colors = family_tree.hidden_disease_colors;
  //     family_hidden_disease_colors = family_hidden_disease_colors.filter(disease => {
  //       if(disease.disease_id && disease.disease_id  === uniqueDiseasesFinal[i].disease_id){
  //         return disease
  //       } else if(disease.umls_id && uniqueDiseasesFinal[i].disease_id == disease.umls_id){
  //         return disease
  //       }
  //     })
  //
  //     if(family_hidden_disease_colors.length > 0){
  //       eyeSlashed.visible = true;
  //       eyeUnslashed.visible = false;
  //     }
  //     else{
  //       eyeSlashed.visible = false;
  //       eyeUnslashed.visible = true;
  //     }
  //
  //     legendGroup.addChild(legendItem)
  //     legendGroup.addChild(diseaseName)
  //     legendGroup.addChild(eyeUnslashed)
  //     legendGroup.addChild(eyeSlashed)
  //
  //     eyeUnslashed.onMouseDown = (event) => {
  //       let hidden_disease_colors = family_tree.hidden_disease_colors;
  //       hidden_disease_colors = hidden_disease_colors.filter(disease => {
  //         if(disease.disease_id && disease.disease_id === uniqueDiseasesFinal[i].disease_id){
  //           return disease
  //         }
  //          else if( disease.umls_id && uniqueDiseasesFinal[i].disease_id == disease.umls_id){
  //            return disease
  //          }
  //       })
  //       if(hidden_disease_colors.length > 0){
  //         onLegendEyeClick(uniqueDiseasesFinal[i].disease_id, false)
  //       }
  //       else{
  //         onLegendEyeClick(uniqueDiseasesFinal[i].disease_id, true)
  //       }
  //     }
  //
  //     eyeSlashed.onMouseDown = (event) => {
  //       let hidden_disease_colors = family_tree.hidden_disease_colors;
  //       hidden_disease_colors = hidden_disease_colors.filter(disease => {
  //         if(disease.disease_id && disease.disease_id === uniqueDiseasesFinal[i].disease_id){
  //           return disease
  //         }
  //          else if( disease.umls_id && uniqueDiseasesFinal[i].disease_id == disease.umls_id){
  //            return disease
  //          }
  //       })
  //       if(hidden_disease_colors.length > 0){
  //         onLegendEyeClick(uniqueDiseasesFinal[i].disease_id, false)
  //       }
  //       else{
  //         onLegendEyeClick(uniqueDiseasesFinal[i].disease_id, true)
  //       }
  //     }
  //
  //
  //   }
  //
  //   legendGroup.setPosition(legendGroup.position.x - 35, legendGroup.position.y - 35)
  //
  //   let savedCenter = JSON.parse(window.localStorage.getItem("famgenix_legend_center"))
  //
  //   if (savedCenter != null && savedCenter != undefined) {
  //     legendGroup.bounds.setCenter(new Point(savedCenter[1], savedCenter[2]))
  //   }
  //
  //   //mouse events
  //   legendGroup.onMouseDown = (event) => {
  //     document.body.style.cursor = "grab"
  //     legendDragging = true;
  //   }
  //
  //   legendGroup.onMouseEnter = (event) => {
  //     document.body.style.cursor = "pointer"
  //   }
  //
  //   legendGroup.onMouseLeave = (event) => {
  //     document.body.style.cursor = "default"
  //   }
  //
  //   legendGroup.onMouseDrag = (event) => {
  //     legendGroup.position = legendGroup.position.add(event.delta);
  //   }
  //
  //   legendGroup.onMouseUp = (event) => {
  //     legendDragging = false;
  //     console.log(JSON.stringify(legendGroup.bounds.center))
  //     window.localStorage.setItem("famgenix_legend_center", JSON.stringify(legendGroup.bounds.center))
  //   }
  //
  // }
  // else {
  //   legend.remove();
  // }
}

const drawAncestry = (displayAncestry, maternal_ancestry, paternal_ancestry, mother_pos, father_pos, most_right_pos, most_left_pos, most_top_y, paper) => {
  var maternalancestry = new paper.PointText()
  var paternalancestry = new paper.PointText()

  paper.PointText.prototype.wordwrap = function (txt, max, MorP) {
    var lines = [];
    var space = -1;
    function cut() {
      for (var i = 0; i < txt.length; i++) {
        (txt[i] == ' ') && (space = i);
        if (i >= max) {
          (space == -1 || txt[i] == ' ') && (space = i);
          if (space > 0) { lines.push(txt.slice((txt[0] == ' ' ? 1 : 0), space)); }
          txt = txt.slice(txt[0] == ' ' ? (space + 1) : space);
          space = -1;
          break;
        }
      } check();
    }
    function check() { if (txt.length <= max) { lines.push(txt[0] == ' ' ? txt.slice(1) : txt); txt = ''; } else if (txt.length) { cut(); } return; }
    check();
    if(MorP === 'maternal'){
      linesForMaternal = lines.length;
    }
    else{
      linesForPaternal = lines.length;
    }
    return this.content = lines.join('\n');
  }

  if (displayAncestry) {
    let maternal_ancestry_labels = []
    let paternal_ancestry_labels = []

    if (maternal_ancestry != null) {
      maternal_ancestry.map(item => maternal_ancestry_labels.push(item.label))
    }
    if (paternal_ancestry != null) {
      paternal_ancestry.map(item => paternal_ancestry_labels.push(item.label))
    }

    let maxCharMaternal = Math.floor(Math.abs(mother_pos.x - most_right_pos.x) * (9/55))
    let maxCharPaternal = Math.floor(Math.abs(father_pos.x - most_left_pos.x) * (9/55))

    if(maxCharMaternal === 0) maxCharMaternal = 7;
    if(maxCharPaternal === 0) maxCharPaternal = 7;

    maternal_ancestry_labels = String(maternal_ancestry_labels).split(',').join(', ')
    paternal_ancestry_labels = String(paternal_ancestry_labels).split(',').join(', ')

    //draw maternal ancestry
    maternalancestry.fillColor = '#333333'
    maternalancestry.font = 'montserrat'
    maternalancestry.style = { fontSize: 12 }
    // maternalancestry.content = maternal_ancestry_labels.length !== 0 ? String(maternal_ancestry_labels) : "Unknown"
    maternalancestry.wordwrap(maternal_ancestry_labels.length !== 0 ? String(maternal_ancestry_labels) : "Unknown", maxCharMaternal, 'maternal')
    ancestry.push(maternalancestry)

    //draw paternal ancestry
    paternalancestry.fillColor = '#333333'
    paternalancestry.font = 'montserrat'
    paternalancestry.style = { fontSize: 12 }
    // paternalancestry.content = paternal_ancestry_labels.length !== 0 ? String(paternal_ancestry_labels) : "Unknownfeafeafaefaefaefaefaefaefeafaefeafaeeaf"
    paternalancestry.wordwrap(paternal_ancestry_labels.length !== 0 ? String(paternal_ancestry_labels) : "Unknown", maxCharPaternal, 'paternal')
    ancestry.push(paternalancestry)

    maternalancestry.setPosition(((mother_pos.x + most_right_pos.x) / 2), most_top_y-40)
    paternalancestry.setPosition(((father_pos.x + most_left_pos.x) / 2), most_top_y-40)


    var items = paper.project.getItems({
      position: function(value) {
        return value.y !== paternalancestry.position.y;
      }
    });

    let maxTop = Math.max(paternalancestry.bounds.top, maternalancestry.bounds.top)

    items[0].bounds.top = maxTop;

    paternalancestry.setPosition(((father_pos.x + most_left_pos.x) / 2), items[0].bounds.top + 10)
    maternalancestry.setPosition(((mother_pos.x + most_right_pos.x) / 2), paternalancestry.position.y)

    Math.max(paternalancestry.bounds.top, maternalancestry.bounds.top)
    items[0].bounds.top = maxTop;

    // console.log(items[0])

  }
  else {
    maternalancestry.remove();
    paternalancestry.remove();
  }
}

// End Draw Shape Condition
const clearNode = (person) => {
  removeAdoptedFrame(person)
  removeLabels(person)
  removeHighlight(person)
}

const removeAdoptedFrame = (person) => {
  if ('adopted_frame' in person) {
    let { right_frame, left_frame } = person.adopted_frame;
    right_frame.remove();
    left_frame.remove();
  }
}

const removeLabels = (person) => {
  for (var label of person.labels) {
    if (label) label.remove()
  }
}

const removeHighlight = (person) => {
  if ('highlight' in person) person.highlight.remove()
}

export {
  redrawSubtext,
  createPerson,
  reDrawColoredConditions,
  reDrawShapedConditions,
  updateDisplayName,
  updateDiseaseInfo,
  updateGenderLabel,
  updateGeneticTest,
  updateNotes,
  clearNode,
  drawLegend,
  drawAncestry,
  peopleLabels,
  legendDragging,
  linesForMaternal,
  linesForPaternal
}
