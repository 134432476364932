import { head, setWith, update } from "lodash";
import constants from "../../../library/pedigree/constants";
import uniqid from 'uniqid';
import { risk_factors } from "../../../store/patient/default";

class ArchiveDataTableDom {

  constructor(options = {}) {
    this.options = options;
    this.resizing = false
    this.buildTable = this.buildTable.bind(this);
    this.createContainer = this.createContainer.bind(this);
    this.createCloseButton = this.createCloseButton.bind(this);
    this.createHeader = this.createHeader.bind(this);
    this.createTable = this.createTable.bind(this);
    this.dragElement = this.dragElement.bind(this);
    this.initResizeElement = this.initResizeElement.bind(this);
    this.setResizingFocus = this.setResizingFocus.bind(this);
    this.getResizingFocus = this.getResizingFocus.bind(this);
    this.buildTable();
  }

  buildTable() {
    this.createContainer();
    this.createCloseButton();
    this.createHeader();
    this.createTable();
    this.dragElement(this.container_element);
    this.initResizeElement()
  }

  setResizingFocus(resizing) {
    this.resizing = resizing;
  }

  getResizingFocus() {
    return this.resizing;
  }

  updateCloseButtonPosition(value) {
    let position = document.querySelector(`.close-${this.options.tableKey}`);
    position.style.transform = "translateX(" + value + "px)";
  }

  reestablishResizerPositionsAfterScroll(value) {
    const positions = [
      `resizer-right-${this.options.tableKey}`, 
      `resizer-left-${this.options.tableKey}`, 
      `resizer-top-${this.options.tableKey}`, 
      `resizer-bottom-${this.options.tableKey}`, 
      `resizer-both-${this.options.tableKey}`
    ];
    const header = document.querySelector(`.header-${this.options.tableKey}`)
    header.style.transform =  "translateX(" + value + "px)";
  
    positions.forEach(position => {
      const element = document.getElementById(position);
      element.style.transform = "translateX(" + value + "px)";
    });
  }
  

  createContainer() {
    // create archive table container element
    this.container_element = document.createElement('div');
    this.container_element.setAttribute('class', 'archive-data-table');
    this.container_element.classList.add(`${this.options.tableKey}`)
    this.container_element.setAttribute('id', constants.ARCHIVE_DATA_TABLE);
    this.container_element.addEventListener('mouseenter', () => {
      document.body.style.cursor = "move";
    });

    this.container_element.addEventListener('scroll', () => {
      let scrollPosition = this.container_element.scrollLeft;
      this.updateCloseButtonPosition(scrollPosition)
      this.reestablishResizerPositionsAfterScroll(scrollPosition)
    });
  
    this.container_element.addEventListener('mouseleave', () => {
      document.body.style.cursor = "default";
    });

    const tableWidth = this.options.savedWidth !== null ? this.options.savedWidth : this.options.width
    const tableHeight = this.options.savedHeight !== null ? this.options.savedHeight : this.options.height

    if(this.options.inset &&  this.options.inset !== null &&
        this.options.inset !== undefined &&  this.options.inset.trim() !== ""){

      const inset = `inset:${this.options.inset};`;
      const height = `height:${tableHeight}px;`;
      const width = `width:${tableWidth}px;`;

      const style_string = inset + height + width;
      this.container_element.setAttribute('style', style_string);
    } else {
      const bottom = `bottom:${this.options.bottom}px;`;
      const right = `right:${this.options.right}px;`;
      const height = `height:${tableHeight}px;`;
      const width = `width:${tableWidth}px;`;

      const style_string = bottom + right + height + width;
      this.container_element.setAttribute('style', style_string);
    }

    this.options.parentNode.appendChild(this.container_element);
  }

  createCloseButton() {
    this.close = document.createElement('span');
    this.close.setAttribute('class', 'fa fa-close');
    this.close.classList.add(`close-${this.options.tableKey}`)
    this.close.setAttribute('id', 'archive-table-close');

    this.close.addEventListener('click', () => {
      this.options.onClose(this.options.tableKey)
    });

    this.container_element.appendChild(this.close);
  }

  createHeader() {
    let headerElement = document.createElement('h4');
    headerElement.setAttribute('class','modal-title text-white text-capitalize');
    headerElement.classList.add(`header-${this.options.tableKey}`)
    headerElement.setAttribute('id', 'archive-table-header');

    let text = this.options.tableName;
    let node = document.createTextNode(text);
    headerElement.appendChild(node);

    this.container_element.appendChild(headerElement);
  }

  createTable() {
    let dataTable = document.createElement('table');
    dataTable.setAttribute('id', 'archive-table');

    let dataHead = document.createElement('thead');
    dataHead.setAttribute('id', 'archive-data-table-head');

    let dataBody = document.createElement('tbody');
    dataBody.setAttribute('id', 'archive-data-table-body');

    let headerRow = document.createElement('tr');
    headerRow.setAttribute('id', 'archive-headers');

    const table_data = this.options.tableData;
    const saveHeaders = this.options.setTableHeaders
    let row_number = 1;
    let tableHeaders = []
    if(table_data && Object.keys(table_data).length > 0) {

      for (const key in table_data) {
        const row = table_data[key];
        if (row_number === 1) {
          // create header row
          let dataRow = document.createElement('tr');

          for (const [field_key, field_value] of Object.entries(row)) {
            let field_name = field_key;
            if(field_name == 'globalid' || field_name == 'rrn') {
              continue
            }
            if (this.options.progenyFieldName[field_key]) {
              field_name = this.options.progenyFieldName[field_key];
              tableHeaders.push(field_name)
            }

            const th = document.createElement('th');
            th.setAttribute('id', 'archive-table-rows-headers');
            th.innerHTML = field_name;
            headerRow.appendChild(th);

            const td = document.createElement('td');
            td.setAttribute('id', 'archive-table-rows-data');
            td.innerHTML = field_value;
            dataRow.appendChild(td);
          }

          saveHeaders(tableHeaders)
          dataHead.appendChild(headerRow);
          dataBody.appendChild(dataRow);

        } else {
          let dataRow = document.createElement('tr');
          for (const [field_key, field_value] of Object.entries(row)) {
            if(field_key == 'globalid' || field_key == 'rrn') {
              continue
            }
            const td = document.createElement('td');
            td.setAttribute('id', 'archive-table-rows-data');
            td.innerHTML = field_value;
            dataRow.appendChild(td);
          }
          dataBody.appendChild(dataRow);

        }

        row_number += 1
      }
    }else{

      this.options.previousHeaders.forEach(header=>{
        const th = document.createElement('th');
        th.setAttribute('id', 'archive-table-rows-headers');
        th.innerHTML = header;
        headerRow.appendChild(th);
      })

      dataHead.appendChild(headerRow);
    }

    dataTable.appendChild(dataHead);
    dataTable.appendChild(dataBody);
    this.container_element.appendChild(dataTable);
  }



  dragElement(elmnt) {
    let savePosition = this.options.setTablePosition;
    let table_key = this.options.tableKey
    let dragging = this.getResizingFocus
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    elmnt.onmousedown = dragMouseDown;

    function dragMouseDown(e) {

      e = e || window.event;
      e.preventDefault();

      document.body.style.cursor = "grab";

      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;

      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      if(dragging()) return
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;

      // set the element's new position:
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }

    function closeDragElement(e) {
      if(dragging()) return
      let position = document.querySelector(`.${table_key}`).style.inset
      const coordinates = position
      savePosition(coordinates);

      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
      document.body.style.cursor = "move";

    }
  }

   initResizeElement() {
    var popups = document.getElementsByClassName(`${this.options.tableKey}`);
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    let resizeElement = this.setResizingFocus
    let saveWidth = this.options.setTableWidth;
    let savePosition = this.options.setTablePosition;


 

    var element = null;
    var startX, startY, startWidth, startHeight, original_x, original_y;
    var minHeight = this.options.height
    for (var i = 0; i < popups.length; i++) {
      var p = popups[i];

      var right = document.createElement("div");
      right.className = "resizer-right";
      right.id = `resizer-right-${this.options.tableKey}`
      p.appendChild(right);
      right.addEventListener("mousedown", initDrag, false);
      right.parentPopup = p;

      var bottom = document.createElement("div");
      bottom.className = "resizer-bottom";
      bottom.id = `resizer-bottom-${this.options.tableKey}`
      p.appendChild(bottom);
      bottom.addEventListener("mousedown", initDrag, false);
      bottom.parentPopup = p;

      var both = document.createElement("div");
      both.className = "resizer-both";
      both.id = `resizer-both-${this.options.tableKey}`
      p.appendChild(both);
      both.addEventListener("mousedown", initDrag, false);
      both.parentPopup = p

      var left = document.createElement("div");
      left.className = "resizer-left";
      left.id = `resizer-left-${this.options.tableKey}`
      p.appendChild(left);
      left.addEventListener("mousedown", initLeftDrag, false);
      left.parentPopup = p;

      var top = document.createElement("div");
      top.className = "resizer-top";
      top.id = `resizer-top-${this.options.tableKey}`
      p.appendChild(top);
      top.addEventListener("mousedown", initTopDrag, false);
      top.parentPopup = p;

    }



    function initDrag(e) {
      element = this.parentPopup;
      startX = e.clientX;
      startY = e.clientY;
      startWidth = parseInt(
        document.defaultView.getComputedStyle(element).width,
        10
      );
      startHeight = parseInt(
        document.defaultView.getComputedStyle(element).height,
        10
      );
      document.documentElement.addEventListener("mousemove", doDrag, false);
      document.documentElement.addEventListener("mouseup", stopDrag, false);
    }

    

    function initLeftDrag(e) {
      element = this.parentPopup;
      startX = e.clientX;
      startY = e.clientY;
      original_x = element.getBoundingClientRect().left;
      startWidth = parseInt(
        document.defaultView.getComputedStyle(element).width,
        10
      );
      startHeight = parseInt(
        document.defaultView.getComputedStyle(element).height,
        10
      );
      document.documentElement.addEventListener("mousemove", doLeftDrag, false);
      document.documentElement.addEventListener("mouseup", stopDrag, false);
    }

    function initTopDrag(e){
      element = this.parentPopup;
      startX = e.clientX;
      startY = e.clientY;
      original_y = element.getBoundingClientRect().top;
      original_x = element.getBoundingClientRect().left;

      startWidth = parseInt(
        document.defaultView.getComputedStyle(element).width,
        10
      );
      startHeight = parseInt(
        document.defaultView.getComputedStyle(element).height,
        10
      );
      document.documentElement.addEventListener("mousemove", doTopDrag, false);
      document.documentElement.addEventListener("mouseup", stopDrag, false);

    }


    function doTopDrag(e){
      resizeElement(true)
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      let top = startHeight - (e.clientY - startY)

      // set the element's new position:
      element.style.width = startWidth - (e.clientX - startX) + "px";
      element.style.left = original_x + (e.clientX - startX) + 'px'


      //check to make sure the user isnt trying to make the table smaller than the min-height or else will start to drag around
      // due to setting the top property
      if(minHeight >= top) return
      element.style.height = top + "px";
      element.style.top = startY + (e.clientY - startY) + "px";
    }

    function doLeftDrag(e){
      resizeElement(true)
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;

      // set the element's new position:
      element.style.width = startWidth - (e.clientX - startX) + "px";
      element.style.left = original_x + (e.clientX - startX) + 'px'
      element.style.height = startHeight + e.clientY - startY + "px";
    }

    function doDrag(e) {
      resizeElement(true)
      element.style.width = startWidth + e.clientX - startX + "px";
      element.style.height = startHeight + e.clientY - startY + "px";
    }

    function stopDrag() {
      resizeElement(false)

      document.documentElement.removeEventListener("mousemove", doLeftDrag, false);
      document.documentElement.removeEventListener("mousemove", doDrag, false);
      document.documentElement.removeEventListener("mouseup", stopDrag, false);
      document.documentElement.removeEventListener("mousemove", doTopDrag, false);

      let position = document.getElementById('archive-data-table')
      let height = position.style.height.slice(0, position.style.height.length - 2)
      let width =  position.style.width.slice(0, position.style.width.length - 2)
      let new_coordinates = {
        'height': height,
        'width': width
      }
      savePosition(position.style.inset)
      saveWidth(new_coordinates)
    }
  }

}
export default ArchiveDataTableDom;
