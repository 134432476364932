import settings from './settings'
import paper from 'paper'

class Matrix {

  offset = { row: 0, column: 0};
  show_grid = settings.show_grid;

  max_level = ''

  /* Dynamic vertically. Depends on how many levels */
  max_rows = 8

  /* Dynamic horizontally. Depends on how many persons per level */
  max_cols = 0

  /* Defines the gap or space between person or box */
  col_margin = 10
  row_margin = 10

  /* This will hold matrix item */
  box_height = settings.matrix_box_height
  box_width = settings.matrix_box_width

  box = {
    x: 0,
    y: 0,
    centerX: 0,
    centerY: 0,
    width: 0,
    height: 0
  }

  the_matrix = []

  build(max_cols = 0, max_rows = 8, offset={row: 0, column: 0}) {

    // Apply offset
    this.offset = offset;

    this.max_cols = max_cols;
    this.max_rows = max_rows;

    this.initEmptyMatrix()
    this.generateMatrixLocations()
    if(this.show_grid) this.showGrid()

  }

  getWidth(){
    return this.max_cols * this.box_width
  }

  getHeight(){
    return this.max_rows * this.box_height
  }

  getMaxCols() {
    return this.max_cols
  }

  getMaxRows() {
    return this.max_rows
  }

  getMaxLevel() {
    return this.max_level
  }

  getSlotAt(row=0, column = 0,  even_column = true) {

    let _row = row + this.offset.row;
    let _column = column + this.offset.column;
    let leftToPedigreeEdgeCols = this.max_cols - settings.chart_padding_right - settings.chart_padding_left
    _row += settings.chart_padding_top;
    // _column += leftToPedigreeEdgeCols >= 25 ? settings.chart_padding_left : settings.chart_padding_left + 1;
    _column += settings.chart_padding_left;


    if (_row < 0 || _row > this.max_rows - 1) throw new Error('Row is invalid')
    if (_column < 0 || _column > this.max_cols - 1) throw new Error('Column is invalid')

    let slot = this.the_matrix[_row][_column]
    return slot;
  }

  getSlotWidth() {
    return this.box_width;
  }

  getSlotHalfWidth() {
    return Math.floor(this.box_width / 2)
  }

  getCenterSlot() {
    let row = parseInt(this.max_rows / 2)
    let column = parseInt(this.max_cols / 2)

    return this.the_matrix[row - 1][column - 1]
  }

  getNextSlotFrom(sourceSlot) {
    let row = sourceSlot.row
    let column = sourceSlot.column + 1

    if (column < 0 || column > this.max_cols - 1) throw new Error('Column is invalid')
    return this.getSlotAt(row, column)
  }

  getSlotsFromLevel(row = 0) {
    return this.the_matrix[row]
  }

  showGrid() {
    for(var row = 0; row < this.max_rows; row++) {
      for(var col = 0; col < this.max_cols; col++) {
        var matrix_box = this.the_matrix[row][col]
        new paper.Path.Rectangle({
          x: matrix_box.x,
          y: matrix_box.y,
          width: matrix_box.width,
          height: matrix_box.height,
          strokeColor: "#efebeb",
          strokeWidth: 0.5
        })

        var col_text = `${col} (${col - this.offset.column})`;
        new paper.PointText({
          point: [matrix_box.x + 2,  matrix_box.y - matrix_box.height + 12],
          content: col_text,
          fillColor: '#ccc',
          fontSize: 12
        });

      }
    }
  }

  initEmptyMatrix() {

    var rows = this.max_rows // rows
    var cols = this.max_cols
    var box = this.box

    this.the_matrix = Array(rows).fill().map(()=>Array(cols).fill(box));
  }

  generateMatrixLocations() {

    var y = 0;
    var x = 0;
    var box_width_half = parseInt(this.box_width / 2)
    var box_height_half = parseInt(this.box_height / 2)
    for(var row = 0; row < this.max_rows; row++){
      x = 0

      for(var col = 0; col < this.max_cols; col++) {
        var box = {
          row, column: col,
          x, y,
          centerX: x + box_width_half,
          centerY: y + box_height_half,
          width: this.box_width,
          height: this.box_height,
        }
        this.the_matrix[row][col] = box

        x += this.box_width
      }

      y += this.box_height
    }

  }

}

export default Matrix